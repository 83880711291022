import { Validators } from 'cla-formrenderer';
import _ from 'lodash';

import * as EVENT from '@utilities/constants/triggerKeys';
import { setConditionalFieldValidation, setEntityNotApplicable } from '@components/formRenderer/triggers';

const setCountrySchedC = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name[0].groups[3].fields[5].isVisible = event === EVENT.KEY_UNITED_STATES;
    name[0].groups[3].fields[6].isVisible = event === EVENT.KEY_CANADA;
    name[0].groups[3].fields[7].isVisible = event !== EVENT.KEY_CANADA && event !== EVENT.KEY_UNITED_STATES ;

    setFormSections(name);
};

const setFileAllFormsSchedC = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    name[0].groups[6].isVisible = event === EVENT.KEY_YES;
    setFormSections(name);
};

const showGovernmentGrantsDetailsSchedC = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    name[0].groups[8].isVisible = event === EVENT.KEY_YES;
    setFormSections(name);
};

const setIFDSCGEN12Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[3].fields[0].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[3].fields[0].validations = null;
        name[0].groups[3].fields[0].error = false;
        name[0].groups[3].fields[0].errorMessage = null;
    } 

    setFormSections(name);
};

const setIFDSCGEN13Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[3].fields[2].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[3].fields[2].validations = null;
        name[0].groups[3].fields[2].error = false;
        name[0].groups[3].fields[2].errorMessage = null;
    } 

    setFormSections(name);
};

const setIFDSCGEN1Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[3].fields[3].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[3].fields[3].validations = null;
        name[0].groups[3].fields[3].error = false;
        name[0].groups[3].fields[3].errorMessage = null;
    } 

    setFormSections(name);
};

const setIFDSCGEN21Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[5].fields[1].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[5].fields[1].validations = null;
        name[0].groups[5].fields[1].error = false;
        name[0].groups[5].fields[1].errorMessage = null;
    } 

    setFormSections(name);
};

const setIFDSCGEN31Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() === 'Yes') {
        name[0].groups[6].fields[1].validations = { ...Validators.required() };
    } 
    if (event?.trim() !== 'Yes') {
        name[0].groups[6].fields[1].validations = null;
        name[0].groups[6].fields[1].error = false;
        name[0].groups[6].fields[1].errorMessage = null;
    } 
    
    setFormSections(name);
};

const setRequiredFromIFDS88298 = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    if (name[7].groups[3].lineItems[1][1].default !== '0') {
        name[7].groups[1].fields[1].validations = { ...Validators.required() };
        name[7].groups[1].fields[1].error = false;
        name[7].groups[1].fields[1].errorMessage = null;
    }
   
    setFormSections(name);
};

const setBusUseHomeBusIncomeRequired = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '' || event?.trim() !== '0') {
        name[7].groups[0].fields[1].validations = { ...Validators.required() }; 
    } 

    if (event?.trim() === '' || event?.trim() === '0') {
        name[7].groups[0].fields[1].validations = null;
        name[7].groups[0].fields[1].error = false;
        name[7].groups[0].fields[1].errorMessage = null;
    } 

    setFormSections(name);
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'setCountrySchedC':
            setCountrySchedC(event, options);
        break;
        case 'setFileAllFormsSchedC':
            setFileAllFormsSchedC(event, options);
        break;
        case 'setIFDSCGEN12Required':
            setIFDSCGEN12Required(event, options);
        break;
        case 'setIFDSCGEN13Required':
            setIFDSCGEN13Required(event, options);
        break;
        case 'setIFDSCGEN1Required':
            setIFDSCGEN1Required(event, options);
        break;
        case 'setIFDSCGEN21Required':
            setIFDSCGEN21Required(event, options);
        break;
        case 'setIFDSCGEN31Required':
            setIFDSCGEN31Required(event, options);
        break;
        case 'setRequiredFromIFDS88298':
            setRequiredFromIFDS88298(event, options);
        break;
        case 'setBusUseHomeBusIncomeRequired':
            setBusUseHomeBusIncomeRequired(event, options)
        break;
        case 'setEntityNotApplicable':
            setEntityNotApplicable(event, options)
        break;
        case 'showGovernmentGrantsDetailsSchedC': 
            showGovernmentGrantsDetailsSchedC(event, options);
        break;
    default:
        break;
    }
};

const setEndingBeginningInventory = (options) => {
    const { section, group } = options;
   
    let requiredLineItem = null;
    requiredLineItem = _.get(section, 'groups[1].lineItems[0][1]', null);
    const isNewEntity = _.get(section, 'isNewEntity', false);
    const title =_.get(section, 'title', '');
    
    if(title === 'Cost of Goods Sold') {
        const lineItems =_.get(group, 'lineItems', []);
        const fields = _.get(group, 'fields', []);
        const isExists = _.some(fields, (field) => field.name === 'IFDSCCOG.8');
        
        if(isExists) {
            const footerLabels = _.get(group, 'lineItemDetails.footerLabels[3]', {});
            const defaultValue = _.get(requiredLineItem, 'default', '');
            const footerLabelValue = _.get(footerLabels, 'label', '');
            const amount = Number(defaultValue.substring(1).trim('').replace(',', '')) || 0; 
            const existingAmount = Number(footerLabelValue.substring(1).trim('').replace(',', ''));
            const requiredField = group.lineItems[0] && group.lineItems[0][2]; 

            if(!isNewEntity && requiredField) {
                _.set(lineItems, '[0][2].type', 'label', '');
                _.set(lineItems, '[0][2].label', defaultValue, '');
                if (!requiredField.loaded) {
                    _.set(group, 'lineItemDetails.footerLabels[3].label', `$${(amount+existingAmount).toLocaleString()}`);
                    requiredField.loaded = true;
                } 
                requiredField.default = defaultValue;
                requiredField.type = 'label';
            }
        }
    }

};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) { 
        case 'setConditionalFieldValidation':
            setConditionalFieldValidation(options);
        break;
        case 'setEndingBeginningInventory':
            setEndingBeginningInventory(options);
        break;
    default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};