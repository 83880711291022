import { NOT_APPLICABLE } from '@utilities/constants/strings';
import { MenuItem } from 'react-md';

function NotApplicableMenuItem(props) {
    const {
        renderCard,
        cardClass,
        cardStyle,
        isOnClickEnabled,
        onCardClick
    } = props;

    return renderCard
        ? (
            <MenuItem
                key='header-na-option'
                className={cardClass}
                style={cardStyle}
                data-testid="header-na-option"
                disabled={!isOnClickEnabled}
                onClick={() => {
                    onCardClick();
                }}
            > {NOT_APPLICABLE}
            </MenuItem>
        )
        : <></>;
}

export default NotApplicableMenuItem;