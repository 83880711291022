// common modules
import { useState } from "react";
import { Button } from 'react-md';
import {
	Dialog,
	DialogHeader,
	DialogTitle,
	DialogContent,
	DialogFooter,
} from "@react-md/dialog";
import {
	Form,
	TextFieldWithMessage,
	useNumberField,
	useTextField
} from "@react-md/form";

// custom modules
import ClientSelectField from '@components/adminDashboard/clientSelectField/index.js';
import GroupSelectField from '@components/adminDashboard/groupSelectField/index.js';

function CreateOrganizerDialog(props) {
	const { visible, onClose, onCreate } = props;
	const [year, yearProps] = useNumberField({
        id: 'organizer-year',
        defaultValue: new Date().getFullYear() - 1,
        min: new Date().getFullYear() - 2,
        max: 9999,
        required: true
    });
    const [clientId, setClientId] = useState('');
    const [groups, setGroups] = useState('');
    // const [signerId, setSignerId] = useState('');
    // const [inChargeId, setInChargeId] = useState('');
	const [appInstanceId, appInstanceIdProps] = useTextField({
		id: 'organizer-appInstanceId',
		defaultValue: '',
		required: true
	});
	const [instanceLibraryId, instanceLibraryIdProps] = useTextField({
		id: 'organizer-instanceLibraryId',
		defaultValue: '',
		required: false
	});
	const [clientSiteId, clientSiteIdProps] = useTextField({
		id: 'organizer-clientSiteId',
		defaultValue: '',
		required: false
	});
	const [projectSiteId, projectSiteIdProps] = useTextField({
		id: 'organizer-projectSiteId',
		defaultValue: '',
		required: false
	});

	return (
		<Dialog
			id="organizer-create-dialog"
			role="alertdialog"
			visible={visible}
			onRequestClose={() => { }}
			aria-labelledby="organizer-create-dialog-title"
			data-testid="organizer-create-dialog-title"
		>
			<DialogHeader>
				<DialogTitle>Create Organizer</DialogTitle>
			</DialogHeader>
			<DialogContent>
				<Form>
					<ClientSelectField value={clientId} setValue={setClientId} />
                    <TextFieldWithMessage
                        {...yearProps}
                        label="Year"
                        placeholder="Year"
                    />
                    <GroupSelectField value={groups} setValue={setGroups} />
					<TextFieldWithMessage
						{...appInstanceIdProps}
						label="App Instance ID"
						placeholder="App Instance ID"
					/>
					<TextFieldWithMessage
						{...instanceLibraryIdProps}
						label="Instance Library ID"
						placeholder="Instance Library ID"
					/>
					<TextFieldWithMessage
						{...clientSiteIdProps}
						label="Client Site ID"
						placeholder="Client Site ID"
					/>
					<TextFieldWithMessage
						{...projectSiteIdProps}
						label="Project Site ID"
						placeholder="Project Site ID"
					/>
				</Form>
			</DialogContent>
			<DialogFooter>
			<Button id="organizer-create-dialog-create" onClick={() => { onCreate({ clientId, year, groups, instanceLibraryId, clientSiteId, projectSiteId, appInstanceId }) }} disabled={!clientId || !year }>
					Create
				</Button>
				<Button id="organizer-create-dialog-close" onClick={onClose}>
					Close
				</Button>
			</DialogFooter>
		</Dialog>
	);
}

export default CreateOrganizerDialog;