import { taxReturnStatuses, taxReturnUserStatus } from '@utilities/constants/taxReturn';
import { taxReturnStatusIsValid } from '@utilities/services/taxReturnService';
import { GridCell } from 'react-md';

const convertTaxReturnStatusText = (taxReturnStatus) => {
    if (!taxReturnStatusIsValid(taxReturnStatus)) return taxReturnStatuses.notApplicable;

    return taxReturnStatus;
};

const TaxReturnStatusGridText = (props) => {
    const { taxReturnStatusValue, propertyName, index, colSpan } = props;
    const taxReturnStatusText = convertTaxReturnStatusText(taxReturnStatusValue);
    let textClass = 'taxReturnStatusNotSent';

    switch (taxReturnStatusValue) {
        case taxReturnUserStatus.sent:
            textClass = 'taxReturnStatusSent';
            break;

        case taxReturnUserStatus.signed:
            textClass = 'taxReturnStatusSigned';
            break;

        case taxReturnUserStatus.declined:
            textClass = 'taxReturnStatusDeclined';
            break;
    }

    return (
        <GridCell
            colSpan={colSpan}
            className={`email-dialog-font ${textClass}`}
            data-testid={`esignature-status-${propertyName}-${index}`}>
            {taxReturnStatusText}
        </GridCell>
    );
}

export default TaxReturnStatusGridText;