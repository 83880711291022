export const handleFormColumns = (field, width, colspan) => {
    switch (true) {
        // If screen width < 768 set mobile column settings
        case (width < 768 && !!field.colPhone):
            return field.colPhone;
        // If screen width >= 768 and < 1025 set tablet column settings
        case (width < 1025 && !!field.colTablet):
            return field.colTablet;
        // If screen width >= 1025 and < 1440 set laptop column settings
        case (width < 1440 && !!field.colLaptop):
            return field.colLaptop;
        default:
            return colspan || field.col;
    }
}