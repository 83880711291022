const sortLineItems = (clonedLineItems) => {
  let compensationArr = [];
  let officerArr = [];
  let normalIndividualArr = [];
  clonedLineItems.forEach(entity => {
    if (entity[2].compTotal > 0) {
      compensationArr.push(entity);
    } else if (entity[2].isOfficer === 'Yes') {
      officerArr.push(entity);
    } else {
      normalIndividualArr.push(entity);
    }
  });
  const sortByTotalCompensation = (entityA, entityB) => {
    return entityB[2].compTotal - entityA[2].compTotal;;
  }
  const sortByAlphabet = (entityA, entityB) => {
    return entityA[0].fieldName > entityB[0].fieldName ? 1 : -1;
  }
  compensationArr.sort(sortByTotalCompensation);
  officerArr.sort(sortByAlphabet);
  normalIndividualArr.sort(sortByAlphabet);
  clonedLineItems = [...compensationArr, ...officerArr, ...normalIndividualArr];
  return clonedLineItems;
}

const sortEntities = (clonedEntities) => {
  let compensationArr = [];
  let officerArr = [];
  let normalIndividualArr = [];
  clonedEntities.forEach(entity => {
    let entityAtotal = 0;
    entity.sections[2].groups.forEach(groupA => {
      if (groupA.fields[1].value && groupA.isVisible === true) {
        entityAtotal = entityAtotal + Number(groupA.fields[1].value);
      }
    });
    if (entityAtotal > 0) {
      entity.sections[2].groups.compTotal = entityAtotal;
      compensationArr.push(entity);
    } else if (entity.sections[0].groups[0].fields[3].value === 'Yes') {
      officerArr.push(entity);
    } else {
      normalIndividualArr.push(entity);
    }
  })
  const sortByTotalCompensation = (entityA, entityB) => {
    return entityB.sections[2].groups.compTotal - entityA.sections[2].groups.compTotal;
  }
  const sortByAlphabet = (entityA, entityB) => {
    return entityA.sections[0].groups[0].fields[0].value > entityB.sections[0].groups[0].fields[0].value ? 1 : -1;
  }
  compensationArr.sort(sortByTotalCompensation);
  officerArr.sort(sortByAlphabet);
  normalIndividualArr.sort(sortByAlphabet);
  clonedEntities = [...compensationArr, ...officerArr, ...normalIndividualArr];
  return clonedEntities;
}
export {
  sortLineItems,
  sortEntities
}