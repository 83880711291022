import { Validators } from 'cla-formrenderer';
import {
    HIGHER_EDUCATION_1098T_UPLOAD,
    HIGHER_EDUCATION_TUITION_PLAN_CONT_UPLOAD,
    HIGHER_EDUCATION_1099Q_UPLOAD,
} from '@utilities/constants/strings';

const educationFn = (year) => {
    const sections = [
        [
            {
                title: 'Beneficiary Details',
                isVisible: true,
                sectionId: 1,
                isCollapsed: true,
                isDenseRow: true,
                groups: [
                    {
                        groupId: 1,
                        fields: [
                            {
                                label: 'Name',
                                name: 'designatedBeneficiary',
                                type: 'freeText',
                                placeholder: 'Name of Beneficiary',
                                prior: true,
                                col: 3,
                                logicFunction: [{ trigger: 'setBeneficiarySummary' }]
                            },
                            {
                                label: 'SSN',
                                name: 'beneficiarySsn',
                                type: 'ssn',
                                placeholder: 'Enter SSN',
                                prior: true,
                                col: 2,
                                overRideText: 'On File',
                                isMaskOnBlur: true,
                                validations: {
                                    ...Validators.ssn()
                                },
                                logicFunction: [{ trigger: 'setBeneficiarySummary' }]
                            },
                            {
                                label: 'Account #',
                                name: 'tuitionAcctNumber',
                                type: 'number',
                                placeholder: 'Account Number',
                                prior: true,
                                col: 2,
                                isMaskOnBlur: true
                            },
                            {
                                label: 'Account Owner',
                                name: 'tuitionAcctOwner',
                                type: 'select',
                                lookup: 'PAYER_TSO',
                                placeholder: 'Select',
                                prior: true, col: 2
                            },
                            {
                                label: 'State Sponsor',
                                name: 'tuitionStateSponsor',
                                type: 'select',
                                lookup: 'STATES',
                                placeholder: 'Select',
                                prior: true,
                                col: 3
                            },

                        ]
                    }
                ]
            },
        ]
    ];

    const lineItemGroup = [
        {
            title: 'Tuition, Fees, and Qualified Expenses for Higher Education Credit',
            shortTitle: 'Tuition, Fees, and Qualified Expenses',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        //TODO Student Name First Name/MI Axcess Mapping: IFDSTUIT.25
                        //TODO Student Name Last Name Axcess Mapping: IFDSTUIT.8
                        //TODO Payment recvd Axcess Mapping: IFDSTUIT.2
                        { label: 'Please provide Form 1098-T or other receipts for higher education expenses paid in 2021:', type: 'label', class: 'groupDetails2' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    fields: [
                        { label: 'T/S/D', wrapLabel: 'Taxpayer / Spouse / Dependent', name: 'IFDSTUIT.13', type: 'select', lookup: 'TUITION_PAYER', placeholder: 'Select', prior: true, logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSTUIT.2',] }] },
                        { label: 'First Four Years', wrapLabel: 'First Four Years of Postsecondary Education?', name: 'IFDSTUIA.20', type: 'select', placeholder: 'Select' },
                        { label: '', name: '', type: 'label' },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSTUIT.2', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSTUIT.2', type: 'money', isTotal: true, placeholder: '0' }, // required field  if line is present
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse / Dependent', smallScreenLabel: 'Qualified Education Expenses', col: 2, colTablet: 1, },
                            { label: 'First Four Years of Postsecondary Education?', col: 2, colTablet: 2, },
                            { col: 3, colTablet: 0, },
                            { label: '2020 Qualified Education Expenses', col: 2, align: 'right', colTablet: 2, },
                            { label: '2021 Qualified Education Expenses', col: 2, align: 'right', colTablet: 2, },
                            { label: '1098-T', col: 1, align: 'noPaddingLeft', colTablet: 1, },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Tuition, Fees, And Qualified Expenses', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3, },
                            { type: 'totalMoney', name: 'IFDSTUIT.2', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSTUIT.2', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: HIGHER_EDUCATION_1098T_UPLOAD, viewSectionId: 0 } // reqired field if line is present
                        ]
                    }
                }
            ]
        },
        {
            title: 'Qualified Tuition Plan Contributions (529 Plans, Not Prepaid Tuition Plans)',
            shortTitle: 'Qualified Tuition Plan Contributions',
            isVisible: true,
            sectionId: 2,
            hasDenseRow: true,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        {
                            label: 'T/S',
                            wrapLabel: 'Taxpayer / Spouse',
                            name: 'taxPayerSpouse',
                            type: 'select',
                            lookup: 'PAYER',
                            placeholder: 'Select',
                            prior: true
                        },
                        {
                            label: '',
                            name: 'beneficiarySummary',
                            type: 'label',
                            styles: { whiteSpace: 'pre-line', paddingTop: 0, }
                        },
                        { label: '', name: '', type: 'label' },
                        {
                            label: 'View Details',
                            name: 'additionalInfo',
                            type: 'viewButton',
                            viewSectionId: 100,
                        },
                        { label: '', name: '', type: 'label' },
                        {
                            label: '$ 0',
                            name: 'tuitionPYContri',
                            type: 'label',
                            isTotal: true,
                            wrapLabel: '2019 Amount',
                            prior: true
                        },
                        {
                            label: 'Amount',
                            wrapLabel: '2020 Amount',
                            name: 'tuitionCYContri',
                            type: 'money',
                            isTotal: true,
                            placeholder: '0'
                        },
                        { type: 'lineButtons' }

                    ],
                    lineItems: [],
                    lineSections: sections[0],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            {
                                label: 'Taxpayer / Spouse',
                                smallScreenLabel: 'Qualified Contributions',
                                col: 2,
                                colTablet: 1,
                                styles: { paddingLeft: '45px' }
                            },
                            {
                                label: 'Beneficiary Summary',
                                col: 2,
                                colTablet: 2,
                                styles: { paddingLeft: '45px' }
                            },
                            { col: 1, colTablet: 0 },
                            {
                                label: 'Beneficiary Details',
                                col: 1,
                                colTablet: 1,
                                align: 'left',
                                styles: { paddingLeft: '20px' }
                            },
                            { col: 1, colTablet: 0 },
                            {
                                label: '2020 Amount Contributed',
                                col: 2,
                                colTablet: 1,
                                align: 'right',
                                styles: { paddingRight: '8px' }
                            },
                            {
                                label: '2021 Amount Contributed',
                                col: 2,
                                colTablet: 2,
                                align: 'right',
                                styles: { paddingRight: '11px' }
                            },
                            {
                                label: 'Contribution Summary',
                                col: 1,
                                colTablet: 1,
                                styles: { paddingLeft: '8px' }
                            },

                        ],
                        footerLabels: [
                            {
                                type: 'buttons',
                                col: 12,
                                buttonTitle: 'Additional Qualified Tuition Plan Contributions',
                                icon: 'add'
                            },
                            { type: 'label', label: 'Total', col: 7, colTablet: 6, },
                            {
                                type: 'totalMoney',
                                name: 'tuitionPYContri',
                                col: 2,
                                colTablet: 1,
                                year: 'prior',
                                label: '$ 0'
                            },
                            {
                                type: 'totalMoney',
                                name: 'tuitionCYContri',
                                col: 2,
                                colTablet: 1,
                                year: 'current',
                                label: '$ 0'
                            },
                        ],
                        lineButtons: [
                            {
                                button: 'uploadNa',
                                uploadSectionName: HIGHER_EDUCATION_TUITION_PLAN_CONT_UPLOAD,
                                viewSectionId: 0
                            }
                        ]
                    }
                }
            ]
        },
        {
            title: 'Payments from Qualified Tuition Education Programs (Form 1099-Q)',
            shortTitle: 'Qualified Tuition Education Programs',
            isVisible: true,
            sectionId: 3,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSCESA.3', type: 'select', lookup: 'PAYER', placeholder: 'Select', prior: true },
                        { label: 'Payer', wrapLabel: 'Payer\'s Name', name: 'IFDSCESA.2', type: 'freeText', placeholder: 'Payer Name', prior: true },
                        { label: 'Recipient', wrapLabel: 'Recipient\'s Name', name: 'IFDSCESA.32', type: 'freeText', placeholder: 'Recipient Name', prior: true },
                        {
                            label: 'SSN', wrapLabel: 'SSN', name: 'IFDSCESA.31', type: 'ssn', placeholder: 'Enter SSN', prior: true, overRideText: 'On File', isMaskOnBlur: true, validations: {
                                ...Validators.ssn(),
                            },
                        },
                        { label: '$ 0', wrapLabel: '2019 Distributions', type: 'label', name: 'IFDSCESA.10', isTotal: true, default: '$ 0', prior: true, },
                        { label: '', name: '', type: 'label' },
                        { type: 'lineButtons' }

                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Qualified Payments', col: 2, colTablet: 2, },
                            { label: 'Payer\'s Name', col: 2, colTablet: 1, },
                            { label: 'Recipient\'s Name', col: 2, colTablet: 1, },
                            { label: 'SSN', col: 2, colTablet: 1, },
                            { label: '2020 Distributions', col: 2, colTablet: 2, align: 'right' },
                            { col: 1, colTablet: 0, },
                            { label: '1099-Q', col: 1, colTablet: 1, align: 'noPaddingLeft' },

                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Payments From Qualified Tuition Education Programs (Form 1099-Q)', icon: 'add' },
                            { type: 'label', col: 8, colTablet: 5, label: 'Total' },
                            { type: 'totalMoney', name: 'IFDSCESA.10', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: HIGHER_EDUCATION_1099Q_UPLOAD, viewSectionId: 0 }
                        ]
                    },
                },
                {
                    groupId: 2,
                    hasDivider: true,
                    isSingleColumn: true,
                    fields: [
                        { label: 'Were distributions used 100% for qualified expenses?', name: 'distributionsFullyQualifiedExpensesLabel', type: 'label', col: 10 },
                        { label: '', name: 'distributionsFullyQualifiedExpenses', type: 'radio', lookup: 'YESNO_RADIO', col: 2, triggered: 'setIsDistributionsFullyQualifiedExpenses', placeholder: 'Select' },
                    ]
                },
                {
                    groupId: 3,
                    isVisible: false,
                    isSingleColumn: true,
                    fields: [
                        { label: ' Amount not used for qualified education expenses', name: 'unqualifiedEducationExpenseAmountLabel', type: 'label', col: 10 },
                        { label: 'Amount', name: 'unqualifiedEducationExpenseAmount', type: 'money', col: 2, }
                    ]
                },
            ]
        },
    ];

    return lineItemGroup;
};

export default educationFn;