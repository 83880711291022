import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import EntityRenderer from '@components/entityRenderer/index';
import getProviderFormSection from './providerFormSection';
// import getYear from '@utilities/helpers/getYear';
import getDependentOptions from '@utilities/helpers/getDependentOptions';
import { triggeredEvent, sectionFieldLogic } from './providerLogicTrigger';

function NewProviderEntity(props) {
    const { isEntityVisible, isLineSection, lineItem, isFormExpandable, isExportForm, year } = props;
    const [sectionState, setSectionState] = useState('');
    const { REDUX, selectState } = usePageFramework();
    // const year = getYear(selectState);
    const dependentArray = selectState(REDUX.DEPENDENTS);
    const priorYearData = selectState(REDUX.PRIOR_YEAR_DATA);

    useEffect(() => {
       const dependentOptions = getDependentOptions(dependentArray, priorYearData);
       const sections = getProviderFormSection({ isFormExpandable, isLineSection, year, dependentOptions, isSelect: year?.current >= 2024 });
       setSectionState(sections);
        // eslint-disable-next-line
    }, []);

    const axcessIdentifiers = [
        { section: 'Credits', id: '2441 - Child and Dependent Care Expenses' },
    ];

    // This is used when a worksheet contains subWorksheets that should be split into entities
    const groupedEntityIdentifiers = [
        {
            main: 'General',
            subWorksheet: 'Persons or Organizations Who Provided the Care'
        }
    ] 

    // using formName={REDUX.PROVIDER} for NewProviderEntity use
    return (
        !sectionState || !isEntityVisible ?  <></> : <EntityRenderer isFormExpandable={isFormExpandable} isFormPresent={true} isFormSection={true} sections={sectionState} key="NewProviderEntity" formName={REDUX.PROVIDER}
        sectionFieldLogic={sectionFieldLogic} identifiers={axcessIdentifiers} groupedEntityIdentifiers={groupedEntityIdentifiers}
            isLineSection={isLineSection} lineItemCount={lineItem || 0} triggeredEvent={triggeredEvent} isExportForm={isExportForm}
            
        />
    );
}

export default NewProviderEntity;