/* eslint-disable no-unused-vars */
import React from 'react';
import YearField from '../YearField';

function YearPicker(props) {
    return (
        <>
            <YearField
                {...props}
            />
        </>
    );
}

export default YearPicker;