import { useEffect, useState } from 'react';
import {
  DialogContent,
  Divider,
  Button,
} from 'react-md';
import { Grid, GridCell } from '@react-md/utils';
import {
  ChevronRightSVGIcon,
} from '@react-md/material-icons';
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as IMG from '@utilities/constants/images';
import './welcomeIntro.scss';
import useAdaptive from "@utilities/hooks/useAdaptive";
import { DropDownMenuComponent } from '../entryComponents/dropdownMenuComponent';

function EntryWelcomeIntro(props) {
  const { moveAgreement } = props;
  const { selectState, REDUX } = usePageFramework();

  const taxpayerName = selectState(REDUX.TAXPAYER_NAME);
  const organizerId = selectState(REDUX.ORGANIZER_ID);
  const hasMultipleReturns = selectState(REDUX.HAS_MULTIPLE_RETURNS);
  const clientOrganizers = selectState(REDUX.CLIENT_ORGANIZERS);
  const [isDisabled, setIsDisabled] = useState(!taxpayerName);
  const { isMobile, isTablet, isLaptopOrDesktop } = useAdaptive();

  const styles = {
    backgroundImage: `url(${IMG.ENTRY_EXPERIENCE_TOP_LAYER}), url(${IMG.ENTRY_EXPERIENCE_MIDDLE_LAYER}), url(${IMG.ENTRY_EXPERIENCE_BOTTOM_LAYER}), url(${IMG.CLA_WELCOME_BG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center, center, center, 265px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };
  const mobileStyles = {
    backgroundColor: "#2E334E",
    backgroundImage: "linear-gradient(88deg, #2e334e 91.5%, #7dd2d3 92%)",
    backgroundRepeat: "no-repeat",
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  };
  const tabletStyles = {
    backgroundImage: `url(${IMG.WELCOME_TOP_LAYER_TABLET}), url(${IMG.CLA_WELCOME_BG})`,
    backgroundSize: 'cover',
    backgroundRepeat: "no-repeat",
    backgroundPosition: '-200px, 180px',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  };

  let colspan = 12;

  useEffect(() => {
    setIsDisabled(!taxpayerName);
  }, [taxpayerName]);

  return (
    <>
      <DialogContent style={isMobile ? mobileStyles : isTablet ? tabletStyles : styles}>
        <Grid className={'welcomeGrid'}>
          <GridCell colSpan={12} ><img src={isMobile ? IMG.CLA_LOGO_MOBILE : IMG.CLA_WHITE_LOGO} alt="CLA logo" className={isMobile ? "mobileClaLogo" : null} /></GridCell>
          <GridCell colSpan={8} style={{ marginTop: 20, justify: 'flex', justifyContent: 'flexStart' }}>
            {isMobile || isTablet ? <div className={"entryExperienceMobileHeaderText"}>Welcome to 1040 Exchange</div> :
              <div className={"entryExperienceHeaderText"}>Welcome to the 1040 Exchange</div>}
            <Divider className={"entryExperienceDivider"} />
          </GridCell>
          {!hasMultipleReturns && (
            <GridCell colSpan={12}>
              <p className="entryExperienceText"
                style={isMobile ?
                  {
                    display: "flex",
                    fontSize: "18px",
                  } :
                  {
                    display: "flex",
                    lineHeight: "24px",
                    fontSize: "21px",
                    marginTop: "30px",
                    fontWeight: "400"
                  }}
                onClick={!isLaptopOrDesktop ? () => {
                  moveAgreement(organizerId);
                } : undefined}
              >
                Provide my Tax Information
                <Button
                  onClick={() => {
                    moveAgreement(organizerId);
                  }}
                  id="icon-button-chevron"
                  data-testid="current-year-tax-info-link"
                  buttonType="icon"
                  theme="clear"
                  style={{ backgroundColor: 'white', width: 25, height: 25, marginLeft: "10px" }}
                >
                  <ChevronRightSVGIcon />
                </Button>
              </p>
            </GridCell>
          )}
          {hasMultipleReturns && (
            <>
              {' '}
              <GridCell colSpan={12}>
                <p
                  className="multiInfoText"
                >
                  I would like to provide information for:
                </p>
              </GridCell>
              <GridCell
                className="dashboardSubMenuDropdownEntry"
                colSpan={colspan}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: '-27px',
                }}
              >
                {/* <UserAccount /> */}
                <DropDownMenuComponent organizers={clientOrganizers} isEntry={true} />
              </GridCell>
              <GridCell colSpan={12}>
                <Button
                  data-testid="entryNextButtonId"
                  themeType="contained"
                  className="ContinueButton"
                  style={{
                    color: isDisabled ? '#979797' : '#2E334E',
                  }}
                  onClick={() => {
                    moveAgreement(organizerId);
                  }}
                  disabled={isDisabled}
                >
                  Continue
                </Button>
              </GridCell>

            </>
          )}
        </Grid>
      </DialogContent>
    </>
  );
}

export default EntryWelcomeIntro;
