import React, { useState, useEffect } from "react";
import SubSectionHeader from "../../subSectionHeader/index";
import BuildFormGroups from "../buildGroups";
import FormBody from "./formBody/index";
import { getTotalByYear } from "../../../utils/getYear";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { frameworkSetter } from "../../../hooks/usePageFramework";
import { PRIOR, CURRENT } from "../../../constants/years";

const Collapse = (props) => {
  const {
    groups,
    title,
    collapseClass,
    index,
    section,
    sectionClass,
    fns,
    allSections,
    isFormLocked,
    isFormPresent,
    screenSize,
    triggered,
    hasLineItemSection,
    entityIndex,
    isFormExpandable,
    parentFormName,
    lastDenseRowSection,
    isScheduleFormSection,
    bookmarkTitle,
    sectionFieldLogic,
    parentGroup,
    isEntryInterview,
    bondID,
    parentIndex
  } = props;
  const { dispatch, ACTION, selectState, REDUX } = frameworkSetter.usePageFramework();
  const isFormNA = selectState(REDUX.IS_CURRENT_FORM_NA);
  const parentExpandState = selectState(
    isFormPresent && parentFormName ? "vehicleExpand" : null
  );
  const parentExpanded =
    parentExpandState && parentFormName
      ? parentExpandState[parentFormName]
      : null;
  const [collapsed, setCollapsed] = useState(
    section.isExpanded ||
      parentExpanded ||
      (!isFormPresent && index === 0) ||
      section.isDefaultExpanded
      ? false
      : true
  );
  const [isSubSecCollapsed, setIsSubSecCollapsed] = useState(true);
  const [denseRowCollapsed, setDenseRowCollapsed] = useState(index !== 0);
  
  useEffect(() => {
    if (isFormPresent && parentFormName && !isFormExpandable)
      setCollapsed(!parentExpanded);
  }, [parentExpanded, isFormPresent, parentFormName]);
  
  useEffect(() => {
    if (isScheduleFormSection === title || bookmarkTitle === title)
      setCollapsed(false);
    else if (index === 0 && !collapsed) setCollapsed(false);
    else if (section.isDefaultExpanded) setCollapsed(false);
    else setCollapsed(true);
  }, [isScheduleFormSection, bookmarkTitle, title]);

  useEffect(() => {
    if (index >= 0 && isFormNA) setCollapsed(true);
    else if (index === 0 && !isFormNA && section.title !== "Vehicle Expenses")
      setCollapsed(false);
  }, [isFormNA]);

  let labels = groups.props.section?.groups?.find(
    (item) =>
      item.lineItemDetails && item.lineItemDetails.footerLabels.length > 0
  );
  const marginBottom =
    !collapsed && isSubSecCollapsed && labels === undefined
      ? { marginBottom: "22px" }
      : null;

  const styles = (label) => (label.length > 20 ? { width: "174.1px" } : null);

  const { width } = useWindowDimensions();

  useEffect(() => {
    setDenseRowCollapsed(section.isCollapsed);
  }, [section.isCollapsed]);
  useEffect(() => {
    if (parentGroup?.currentH2 === title) {
        setDenseRowCollapsed(false);
    }
    if (index === 0 && !denseRowCollapsed) {
      if (parentGroup?.hasTableManagerTabs && !parentGroup?.isH2TabsHidden) return;
      setDenseRowCollapsed(false)
    }
  }, [parentGroup]);

  const totals = (labelText, amount) => {
    const label = `${amount.year} ${
      labelText?.label ? labelText?.label : "Total:"
    }`;
    return (
      <>
        <div
          className="headerTotalLabel"
          style={styles(label)}
        >
          <div>
            <p>{labelText?.label === "Total" ? `${label}:` : `${label}`}</p>
          </div>
          <div>
            <p>${amount.label.toLocaleString()}</p>
          </div>
        </div>
      </>
    );
  };

  const headers = () => {
    if (section.isDenseRow && section.headers) {
      return section.headers.map((header, index) => {
        return (
          header.isVisible && 
          <div key={index}>
              <span>{header.label} </span> 
              <span className='headerAmounts'>
                {
                  !header.amount ? `$ 0` : header.amount
                }
              </span>
            </div>
        )
      });
    }
  };

  const renderHeaderTotal = () => {
    labels = groups.props.section?.groups?.find(
      (item) =>
        item.lineItemDetails && item.lineItemDetails.footerLabels.length > 0
    );
    let amounts = [];
    let totalAmounts = [];
    let prior, current = {};

    const subSections = groups.props.section.subSections;

    // Used to identify if H2's total should be added
    // to other H2's or H1 within the same collapsible form
    const found =
      subSections?.length === 1 &&
      subSections.find((item) => item.isShowSubSecTotalOnMainSec === false);
    // when the section props: isTotalDisplay === false, return null, which won't display Totals
    if(subSections?.length > 0 && groups.props.section.isTotalDisplay === false){
      return null
    }

    if (subSections && !found) {
      const filtered = !allSections
        ? []
        : allSections
            .map((data) => {
              const found = section.subSections.find(
                (subSection) =>
                  subSection.subSectionId === data.sectionId &&
                  subSection.isShowSubSecTotalOnMainSec === undefined
              );

              const subSectionData =
                found && allSections.find((sec) => sec.sectionId === found.subSectionId);

              labels =
                subSectionData &&
                subSectionData.groups &&
                subSectionData.groups.find(
                  (item) =>
                    item.lineItemDetails &&
                    item.lineItemDetails.footerLabels.length > 0
                );

              return labels;
            })
            .filter((item) => item);

      amounts = filtered
        .map((item) => item.lineItemDetails)
        .map((item) =>
          item.footerLabels.filter(
            (footerLabel) => footerLabel.type === "totalMoney"
          )
        );

      prior = getTotalByYear(amounts, "prior");
      current = getTotalByYear(amounts, "current");
      totalAmounts = [prior, current];

      return totalAmounts.map(
        (total, amtIndex) =>
          total && (
            <div
              className="headerTotalLabel"
              key={`headerTotalLabel-${index}-${amtIndex}`}
              style={styles(total.label)}
            >
              <div>
                <p>{total.year} Total:</p>
              </div>
              <div>
                <p>${total.label.toLocaleString()}</p>
              </div>
            </div>
          )
      );
    } else if (labels) {
      const labelTitle = ["Cost or other Basis", "Dist. Amount"];
      labels = labels.lineItemDetails?.footerLabels;

      const mainH1FooterLabels = groups.props.section.groups.filter(
        (item) =>
          item.lineItemDetails && item.lineItemDetails.footerLabels.length > 0
      );

      amounts = mainH1FooterLabels
        .map((item) => item.lineItemDetails)
        .map((item) =>
          item.footerLabels.filter(
            (footerLabel) =>
              footerLabel.type === "totalMoney" &&
              !labelTitle.includes(footerLabel.labelText)
          )
        )
        .filter((item) => item.length > 0);

      // Set year manually if there are 2 H1's
      if (amounts.length > 1) {
        prior = getTotalByYear(amounts, "prior");
        current = getTotalByYear(amounts, "current");
        totalAmounts = [prior, current].filter((item) => item);
      } else {
        totalAmounts = amounts
          .flat()
          .map((item) => getTotalByYear(item, item.year));
      }

      if (totalAmounts) {
        const labelText = labels?.find((item) => item.type === "label");
        // if theres only 1 header total with a 2020 year then
        // render it with a 2nd header with an empty data to
        // fix alignments of header totals when collapsed along
        // with other collapsed sections within the same form
        if (totalAmounts.length === 1 && totalAmounts[0].year === PRIOR) {
          const amount = totalAmounts[0];

          return (
            <>
              {totals(labelText, amount)}
              <div className="headerTotalLabel" key={index}></div>
            </>
          );
        } else if (
          totalAmounts.length === 1 &&
          (totalAmounts[0].year === CURRENT || totalAmounts[0].year === "")
        ) {
          const amount = totalAmounts[0];

          return (
            <>
              <div className="headerTotalLabel" key={index}></div>
              {totals(labelText, amount)}
            </>
          );
        }
        return totalAmounts.map((item) => {
          return totals(labelText, item);
        });
      }
    }
  };

  const handleClick = () => {
    section.isExpanded = !section.isExpanded;
    setCollapsed(!collapsed);

    if (isFormPresent && parentFormName) {
      dispatch(ACTION.setExpandedSubFormState(parentFormName, collapsed)); // Tie to collapse button?
    }
  };

  const renderSections = (isCollapsed) => {
    if (!section.subSections) return groups;

    const filtered = allSections.map((data, sectionIndex) => {
      const found = section.subSections.find(
        (subSection) => subSection.subSectionId === data.sectionId
      );
      const subSectionData =
        found &&
        allSections.find((sec) => sec.sectionId === found.subSectionId);
      const isSectionVisible = subSectionData
        ? typeof subSectionData.isVisible === "undefined"
          ? true
          : typeof subSectionData.isVisible === "function"
          ? subSectionData.isVisible()
          : subSectionData.isVisible
        : false;

      if (!isSectionVisible) return null;

      const subSectionGroups = (
        <BuildFormGroups
          section={subSectionData}
          saveForm={fns.saveForm}
          sectionIndex={sectionIndex}
          isFormLocked={isFormLocked}
          triggered={triggered}
          sectionFieldLogic={sectionFieldLogic}
          sectionClass={sectionClass}
          screenSize={screenSize}
          entityIndex={entityIndex}
          setUniqueEntityFieldValue={fns.setUniqueEntityFieldValue}
          showSharedEntityDialog={fns.showSharedEntityDialog}
          updateEntityUniqueValue={fns.updateEntityUniqueValue}
          allSections={allSections}
        />
      );

      return (
        subSectionData && (
          <SubSectionHeader
            key={`subSection-${found.subSectionId}-${sectionIndex}`}
            section={subSectionData}
            sectionClass={sectionClass}
            groups={subSectionGroups}
            isSubSecCollapsed={isCollapsed}
            setIsSubSecCollapsed={setIsSubSecCollapsed}
            renderHeaderTotal={renderHeaderTotal}
            hasLineItemSection={hasLineItemSection}
            denseRowCollapsed={denseRowCollapsed}
            setDenseRowCollapsed={setDenseRowCollapsed}
          />
        )
      );
    });

    return (
      <div>
        <div>{groups}</div>
        <div>{filtered}</div>
      </div>
    );
  };

  return (
    !section.isSubSection && (
      <FormBody
        key={`collapse-${section.title}-${section.sectionId}}`}
        sectionType="section"
        title={title}
        collapseClass={collapseClass || null}
        isFooterAvailable={
          labels && labels.lineItemDetails.footerLabels.length > 0
        }
        collapsed={collapsed}
        marginBottom={marginBottom}
        renderHeaderTotal={renderHeaderTotal}
        handleClick={handleClick}
        denseRowCollapsed={denseRowCollapsed}
        renderSections={renderSections}
        hasLineItemSection={hasLineItemSection}
        width={width}
        isFormExpandable={isFormExpandable}
        setDenseRowCollapsed={setDenseRowCollapsed}
        lastDenseRowSection={lastDenseRowSection}
        isDenseRow={section.isDenseRow}
        isFormNA={isFormNA}
        hasOrgs={section.hasOrgs}
        groups={section.groups}
        denseRowHeaders={headers}
        isSectionHeaderHidden={section.isSectionHeaderHidden}
        isEntryInterview={isEntryInterview}
        bondID={bondID}
        parentGroup={parentGroup}
        parentIndex={parentIndex}
        hasNoLineItem={section.hasNoLineItem}
        allSections={allSections}
      />
    )
  );
};

export default Collapse;
