import _ from 'lodash';
import * as INPUT_STATE from "@utilities/constants/inputState";
import * as AXCESS_KEYS from "@utilities/constants/axcessKeys";
import * as EVENT from "@utilities/constants/triggerKeys";
import moneyFormatter, {
  noDollarFormatter,
  parseMoneyToNumber
} from "@utilities/moneyFormatter";

const onlyFirstLineItem = (group, options) => {
  if (!group) return;

  const { lineItems } = group;

  if (!lineItems || !_.isArray(lineItems) || !lineItems.length) return;

  group.lineItems.splice(1);
};

const setProviderTypeVisibility = (group, options) => {
  if (!group) return;

  const { lineItems } = group;

  if (!lineItems || !_.isArray(lineItems) || !lineItems.length) return;

  group.lineItems.forEach((lineItem, index) => {
    const providerField = lineItem.find(field => field.name === 'providerType')?.default;
    if (providerField === EVENT.EVENT_KEY_SSN) {
      const ssnField = lineItem.find(field => field.name === 'ssn');
      if (ssnField) ssnField.isVisible = true;
    }
    if (providerField === EVENT.EVENT_KEY_EIN) {
      const einField = lineItem.find(field => field.name === 'ein');
      if (einField) einField.isVisible = true;
    }
  });
}

const hsaTablePopulate = (group, metadata) => {
  const sumOnColumns = metadata?.sumOnColumns || [];

  const sumHolder = {
    [AXCESS_KEYS.TAXPAYER_KEY]: {},
    [AXCESS_KEYS.SPOUSE_KEY]: {}
  };
  const rows = _.cloneDeep(group?.lineItems) || [];
  const filterForValue = (valueTS) => (row) =>
    row.some((column) => column?.default === valueTS);
  const sumOnRow = (valueTS) => (row) => {
    sumOnColumns.forEach((columnKey) => {
      const foundColumn = row.find((x) => x.name === columnKey);
      if (foundColumn && foundColumn.default) {
        if (sumHolder[valueTS]?.[columnKey]) {
          sumHolder[valueTS][columnKey] += parseMoneyToNumber(
            foundColumn.default
          );
        } else {
          sumHolder[valueTS][columnKey] = parseMoneyToNumber(
            foundColumn.default
          );
        }
      }
    });
  };

  // Build new row with first column value
  const buildNewRow = (valueTS) => {
    const row = _.cloneDeep(group.fields);
    row[0].default = valueTS;
    row[0].inputState = INPUT_STATE.PRIOR_INPUT;

    row.forEach((column) => {
      if (_.has(sumHolder[valueTS], column.name)) {
        column.inputState = INPUT_STATE.PRIOR_INPUT;
        column.default =
          column.isMoney === false
            ? noDollarFormatter(sumHolder[valueTS][column.name])
            : moneyFormatter(sumHolder[valueTS][column.name]);
      }
    });

    return row;
  };

  // Grab first available Taxpayer and Spouse row
  rows
    .filter(filterForValue(AXCESS_KEYS.TAXPAYER_KEY))
    .forEach(sumOnRow(AXCESS_KEYS.TAXPAYER_KEY));
  rows
    .filter(filterForValue(AXCESS_KEYS.SPOUSE_KEY))
    .forEach(sumOnRow(AXCESS_KEYS.SPOUSE_KEY));

  const results = [];
  // Add the taxpayer row first, and then spouse row. Build row if not available
  results.push(buildNewRow(AXCESS_KEYS.TAXPAYER_KEY));
  results.push(buildNewRow(AXCESS_KEYS.SPOUSE_KEY));

  group.lineItems = results;
};

// define post populate functions
const postPopulateFunctions = {
  onlyFirstLineItem,
  hsaTablePopulate,
  setProviderTypeVisibility,
};

// define handler functions
const returnFn = ([x]) => x;

const getPostPopulateFunction = (functionName) => {
  return functionName && postPopulateFunctions[functionName]
    ? postPopulateFunctions[functionName] : returnFn;
};

export {
  getPostPopulateFunction,

  // exported for testing purposes
  postPopulateFunctions,
  onlyFirstLineItem,
  setProviderTypeVisibility,
};