// common modules
import { useState, useEffect } from 'react';
import {
	Grid,
	Dialog,
	GridCell,
	Divider,
	Form,
	Button,
	TextIconSpacing,
	FontIcon,
	FileInput,
	useToggle,
	DropdownMenu,
	MenuItem
} from 'react-md';
import moment from 'moment';

// custom modules
import usePageFramework from '@utilities/hooks/usePageFramework';
import RenameDocumentDialog from '@components/dialog/RenameDocumentDialog';
import WarningDialog from '@components/dialog/warningDialog';
import { ACCEPTED_FILE_TYPES_1040 } from '@utilities/constants/document';
import { renderTiffImage } from '@utilities/files/convertImageToPdf';
import NewFileStatus from '@components/newFile/newFileStatus';
import '@components/documentManager/DocumentManagerUploadList.css';

const DocumentManagerUploadList = ({ uploadList, categories, formKey, sectionKey, isUnassigned = false, isCollapsed = true, isAllDocuments = false }) => {
	const {
		REDUX,
		selectState,
		onFileUpload,
		onFileRemoval,
		onFilePreview,
		downloadFile,
		buildDocumentPreview,
		moveFile
	} = usePageFramework();
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
	const isAdmin = selectState(REDUX.IS_ADMIN);
	const isInternalUser = (isPractitioner || isAdmin) ? true : false;
	const [file, setFile] = useState(null);
	const [source, setSource] = useState(null);
	const globalUploadList = selectState(REDUX.UPLOAD_LIST);
	const [currentDocument, setCurrentDocument] = useState(null);
	const [previewVisible, showPreview, hidePreview] = useToggle(false);
	const [deleteDialogVisible, showDeleteDialog, hideDeleteDialog] = useToggle(false);
	const [moveFileValue, setMoveFileValue] = useState(null);
	const [visibleMoveWarningDialog, showMoveWarningDialog, hideMoveWarningDialog] = useToggle(false);
	const questionText = 'Are you sure you want to permanently delete this file?';
	const moveWarningMsg = 'This document is moving to the selected section. Are you sure?';
	const isLocked = selectState(REDUX.LOCK_FORMS) || false;
	const isFutureYear = selectState(REDUX.IS_FUTURE_YEAR);

	const [documents, setDocuments] = useState({
		uploads: [],
		rowsToDisplay: 10,
		expanded: false
	});
	let showMoreBtnActiveStyles = {};
	let showLessBtnActiveStyles = {};
	if (documents.uploads) {
		showMoreBtnActiveStyles = documents.uploads.length > documents.rowsToDisplay ?
			{ color: '#2e334e' } : {};
		showLessBtnActiveStyles = documents.uploads.length > 10 &&
			documents.rowsToDisplay > 10 ?
			{ color: '#2e334e' } : {};
	}

	useEffect(() => {
		setDocuments((prevState) => {
			const sortedUploads = [...uploadList].sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
			return {
				...prevState,
				uploads: sortedUploads,
				rowsToDisplay: sortedUploads.length === 10 ? 10 : prevState.rowsToDisplay
			};
		});
	}, [uploadList]);

	const [index, setIndex] = useState(null);
	const [showRenameDialog, setShowRenameDialog] = useState(false);

	const onUpload = (event) => onFileUpload(event, null, sectionKey, globalUploadList, { updateState: () => { } }, formKey);

	const moveOptions = categories?.filter((item) => !(item.title === 'All Documents' || item.title === 'Unassigned Files')).map((cat) => {
		let formSections = [];
		if (cat.cards.length > 0) {
			formSections = cat.cards.map((card) => {
				return { title: card.title, key: card.key, section: card.uploadSectionName };
			});
		}
		return { sectiontitle: cat.title, formSections: formSections };
	}) ?? [];

	let activeCategories = moveOptions;
	const dropdownMenu = (uploadId) => (
		<DropdownMenu
			key={uploadId}
			id="nested-dropdown-menus"
			className={`moveBtn ${isLocked ? 'disabled-true' : 'disabled-false'}`}
			disableDropdownIcon={true}
			buttonChildren="Move"
			disabled={isLocked}
		>
			<MenuItem onClick={(e) => e.stopPropagation()}>
				<span className='moveFileText'>Select Destination</span>
			</MenuItem>
			{
				activeCategories.map((cat) => {
					if (cat.formSections) {
						return (
							<DropdownMenu
								buttonChildren={cat.sectiontitle}
								id={`nested-dropdown-menus-1-${cat.sectiontitle}`}
							>
								{cat.formSections.map((formsection) => (
									<MenuItem
										key={formsection.title}
										onClick={() => {
											setMoveFileValue({ uploadId, key: formsection.key, section: formsection.section });
											setCurrentDocument(document);
											showMoveWarningDialog();
										}}
									>
										{formsection.title}
									</MenuItem>
								))}
							</DropdownMenu>
						);
					}
					return <MenuItem key={cat.sectiontitle} disabled={isLocked}>{cat.sectiontitle}</MenuItem>;
				})
			}
		</DropdownMenu>
	);

	const handlePreview = (document) => {
		if (document.id === file?.id && source) {
			showPreview();
			return;
		}
		if ((document.extension.includes('tiff') || document.extension.includes('tif'))) {
			onFilePreview(document, { responseType: 'arraybuffer' })?.then((arrayBuffer) => {
				let imageBase64 = renderTiffImage(arrayBuffer, document);
				setSource(imageBase64);
				setFile(document);
				showPreview();
			});
		} else {
			onFilePreview(document).then((source) => {
				setSource(source);
				setFile(document);
				showPreview();
			});
		}
	};
	const handleDelete = (document) => {
		setCurrentDocument(document);
		showDeleteDialog();
	};

	const handleDownload = (document) => async (evt) => {
		evt.preventDefault();
		await downloadFile(document);
	};

	const handleShowMore = () => {
		if (documents.uploads.length > documents.rowsToDisplay) {
			setDocuments((prevState) => {
				prevState.rowsToDisplay = prevState.rowsToDisplay + 10;
				prevState.expanded = true;
				return { ...prevState };
			});
		}
	};

	const handleShowLess = () => {
		setDocuments((prevState) => {
			if (prevState.rowsToDisplay > 10) prevState.rowsToDisplay -= 10;
			prevState.expanded = false;
			return { ...prevState };
		});
	};

	const handleRename = (document, docIndex) => {
		setShowRenameDialog(!showRenameDialog);
		setIndex(docIndex);
		setFile(document);
	};

	const handleMove = async (uploadId, key, section, document) => {
		await moveFile(uploadId, key, section);
		hideMoveWarningDialog();
		setCurrentDocument(document);
		setMoveFileValue(null);
	};

	const handleRemove = (event) => {
		onFileRemoval(event, currentDocument);
		hideDeleteDialog();
	};

	return (!isCollapsed && documents?.uploads?.length > 0 ?
		<GridCell
			colSpan={12}
			id={`doc-manager-upload-container-${sectionKey}`}
			data-testid={`doc-manager-upload-container`}
			className='docManagerUploadsContainer'
			style={isUnassigned ? { padding: '19px 20px' } : {}}
		>
			<Dialog
				id={`upload-viewer-modal`}
				visible={previewVisible}
				onRequestClose={() => { }}
				modal={true}
				aria-labelledby="upload viewer modal"
				className='viewerContainer'
			>
				<div >
					<Button className='fileViewerCLose' id="dialog-close" onClick={() => { setFile(''); hidePreview(); }} first="true" last="true" buttonType='icon'>
						<FontIcon>close</FontIcon>
					</Button>
				</div>
				<h3 className='fileViewerFileName'>{file?.name}</h3>
				<a className='fileViewerDownload' href={'#'} target="_blank" rel="noreferrer" onClick={handleDownload(file)}>Download</a>
				{file && buildDocumentPreview(source, file)}
			</Dialog>
			{documents.uploads.slice(0, documents.rowsToDisplay).map((document, docIndex) => (
				<><Grid
					key={docIndex}
					className='uploadsGrid'
					data-testid={`doc-manager-upload`}>
					<GridCell
						colSpan={isAllDocuments ? 3 : isUnassigned ? 4 : 6}
						className='docManagerCell uploadsText fileNameContainer'
						data-testid={`docManagerCell-name`}
					>
						<div data-testid={`docManagerCell-name-text`} className='docManagerCell-name-text'>{document.name}</div>
						<div
							data-testid={`docManagerCell-rename-btn`}
							onClick={() => handleRename(document, docIndex)}>
							<p
								data-testid={`docManagerCell-rename-btn-text`}
								className='renameFile'>(Rename)</p>
						</div>
						{isInternalUser && document.isNew
							? <NewFileStatus className='newFileTagSpace' onClick={handleDownload(document)} />
							: null}
					</GridCell>
					{isAllDocuments && <GridCell
						colSpan={2}
						className='docManagerCell uploadsText'
						style={!isAllDocuments && (isUnassigned ? { marginLeft: '75px' } : { marginLeft: '97px' })}
						data-testid={`docManagerCell-uploadedOn`}
					>
						{document.sectionName}
					</GridCell>}
					<GridCell
						colSpan={(isAllDocuments || isUnassigned) ? 3 : 2}
						className='docManagerCell uploadsText'
						data-testid={`docManagerCell-uploadedOn`}
						style={isAllDocuments ? { marginLeft: '100px' } : (isUnassigned ? { marginLeft: '97px' } : {})}
					>
						{moment(new Date(document.createdOn)).format('MM/DD/YY')}
					</GridCell>
					<GridCell
						colSpan={2}
						className='docManagerCell uploadsText'
						style={isUnassigned ? { margin: 0 } : { marginLeft: '10px' }}
						data-testid={`docManagerCell-downloadedOn`}
					>
						{isInternalUser ? document.downloaded_on && document.downloadedBy && moment(new Date(document.downloaded_on)).format('MM/DD/YY') : ''}
					</GridCell>
					<GridCell
						colSpan={isUnassigned ? 3 : 2}
						className='docManagerCell uploadsActionBtnsContainer'
						style={isUnassigned ? { marginLeft: '7px' } : {}}
						data-testid={`docManagerCell-actions`}
					>
						{isUnassigned && !isFutureYear &&
							dropdownMenu(document.upload_id)
						}
						<WarningDialog
							questionText={moveWarningMsg}
							onConfirmClick={async () => await handleMove(moveFileValue.uploadId, moveFileValue.key, moveFileValue.section, document)}
							visible={visibleMoveWarningDialog}
							onCancelWarning={hideMoveWarningDialog}>
						</WarningDialog>

						{isUnassigned && !isFutureYear && <span>|</span>}
						<Button
							className='uploadsActionBtns'
							onClick={() => handlePreview(document)}
							data-testid={`uploadsActionBtns-preview`}>
							Preview
						</Button>
						<span>|</span>
						<Button
							className='uploadsActionBtns'
							onClick={handleDownload(document)}
							data-testid={`uploadsActionBtns-download`}>
							Download
						</Button>
						<span>|</span>

						<Button disabled={isLocked} className='uploadsActionBtns' data-testid={`uploadsActionBtns-delete`} key={docIndex} onClick={() => handleDelete(document)}>Delete</Button>
					</GridCell>
					<GridCell colSpan={12}>
						<Divider />
					</GridCell>
				</Grid>

				</>
			))}
			{showDeleteDialog && <WarningDialog
				questionText={questionText}
				onConfirmClick={(event) => handleRemove(event)}

				visible={deleteDialogVisible}
				onCancelWarning={hideDeleteDialog}
			>
			</WarningDialog>}
			<GridCell colSpan={12} style={{ marginTop: '-10px', display: 'flex', justifyContent: 'space-between' }}>
				<div>
					<Form data-testid={`add-file-button`}>
						<FileInput
							className="lineItemButton"
							style={{ float: 'left' }}
							theme='clear'
							themeType='flat'
							id={`docManagerUploads-${sectionKey}`}
							onChange={onUpload}
							buttonType={'text'}
							disableIconSpacing={true}
							multiple={true}
							icon={<TextIconSpacing
								icon={
									<FontIcon className="addIcon">
										add
									</FontIcon>
								}>
							</TextIconSpacing>}
							accept={ACCEPTED_FILE_TYPES_1040}
						>
							Add File
						</FileInput>
					</Form>
				</div>
				<div
					className='showMoreLessContainer'
					data-testid={`show-more-less-container`}>
					<Button
						className='showMoreLess'
						onClick={handleShowMore}
						style={showMoreBtnActiveStyles}
						data-testid={`show-more-btn`}
					>
						Show more
					</Button>
					<span>|</span>
					<Button
						className='showMoreLess'
						onClick={handleShowLess}
						style={showLessBtnActiveStyles}
						data-testid={`show-less-btn`}
					>
						Show less
					</Button>
				</div>
			</GridCell>
			<RenameDocumentDialog
				showRenameDialog={showRenameDialog}
				setShowRenameDialog={setShowRenameDialog}
				index={index}
				file={file}
			/>
		</GridCell> : <></>
	);
};

export default DocumentManagerUploadList;