import moment from 'moment';
import { MM_DD_YYYY, YYYY_MM_DD } from '@utilities/constants/dateFormats';

const parseToISODate = (value) => {
    if (!value) return value;

    const date = moment(value, [ MM_DD_YYYY, YYYY_MM_DD ]);

    return date?.isValid() ? date.format(YYYY_MM_DD) : '';
};

const parseToUSDate = (value) => {
    if (!value) return value;

    const date = moment(value, [ MM_DD_YYYY, YYYY_MM_DD ]);

    return date?.isValid() ? date.format(MM_DD_YYYY) : '';
};

export {
    parseToISODate,
    parseToUSDate,
};