import _ from "lodash";
import * as INPUT_STATE from "../constants/inputState";
import * as AXCESS_KEYS from "../constants/axcessKeys";
import * as TRIGGER_KEYS from "../constants/triggerKeys";
import * as LOOKUPS from "../constants/lookupInfo";
import { parseToUSDate } from "../components/formRenderer/fields/utils/formatDate";
import moneyFormatter, {
  noDollarFormatter,
  parseMoneyToNumber,
} from "./moneyFormatter";

const zipPostalPull = (values) => {
  const [foreignCountry, zipCode, postalCode] = values;

  if (foreignCountry) return postalCode;
  if (!foreignCountry) return zipCode;
  return null;
};

const foreignCountryPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry] = axcessValues;

  if (!foreignCountry) return TRIGGER_KEYS.EVENT_KEY_UNITED_STATES;
  if (foreignCountry === TRIGGER_KEYS.EVENT_KEY_EMPTY)
    return TRIGGER_KEYS.EVENT_KEY_UNITED_STATES;
  if (foreignCountry.toLowerCase() === TRIGGER_KEYS.EVENT_KEY_NA)
    return TRIGGER_KEYS.EVENT_KEY_UNITED_STATES;

  return foreignCountry;
};

const americanStatesPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, state] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (country === TRIGGER_KEYS.EVENT_KEY_UNITED_STATES) return state;
  return null;
};

const canadianProvincePull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, province] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (country === TRIGGER_KEYS.EVENT_KEY_CANADA) return province;
  return null;
};

const mexicanStatesPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, state] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (country === TRIGGER_KEYS.EVENT_KEY_MEXICO) return state;
  return null;
};

const foreignProvincePull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, province] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (
    country !== TRIGGER_KEYS.EVENT_KEY_CANADA &&
    country !== TRIGGER_KEYS.EVENT_KEY_UNITED_STATES
  )
    return province;
  return null;
};

const childCareExpensesPull = (values) => {
  const filterValid = (x) => x && _.toSafeInteger(x) !== 0;
  if (!values || !_.isArray(values)) return null;

  const hasCareExpense = values.filter(filterValid).length ? true : false;

  return hasCareExpense ? AXCESS_KEYS.AXCESS_KEY_YES : null;
};

const datePull = (value) => parseToUSDate(value);

const employerIdPull = (value) => {
  if (!value) return null;
  return value.replace("-", "");
};

const jointToTaxpayerPull = (value) => {
  if (value === AXCESS_KEYS.JOINT_KEY) return AXCESS_KEYS.TAXPAYER_KEY;
  return value;
};

const checkboxPull = (value) =>
  value === AXCESS_KEYS.AXCESS_KEY_X ? true : false;
const checkboxYNPull = (value) =>
  value === AXCESS_KEYS.AXCESS_KEY_YES ? true : false;
const checkboxToYNPull = (value) =>
  value === AXCESS_KEYS.AXCESS_KEY_X ? "Yes" : "No";
const selectYNPull = (value) => {
  switch (value) {
    case AXCESS_KEYS.AXCESS_KEY_Y:
      return AXCESS_KEYS.AXCESS_KEY_YES;
    case AXCESS_KEYS.AXCESS_KEY_N:
      return AXCESS_KEYS.AXCESS_KEY_NO;
    default:
      return "";
  }
};
const withdrawalPull = (value) =>
  value === AXCESS_KEYS.AXCESS_KEY_BOTH ? AXCESS_KEYS.EXCHANGE_KEY_BOTH : value;
const maritalStatusPull = (value) => {
  const SINGLE = [TRIGGER_KEYS.EVENT_KEY_1, TRIGGER_KEYS.EVENT_KEY_3];
  const MARRIED = [
    TRIGGER_KEYS.EVENT_KEY_2,
    TRIGGER_KEYS.EVENT_KEY_5,
    TRIGGER_KEYS.EVENT_KEY_6,
    TRIGGER_KEYS.EVENT_KEY_7,
  ];
  const WIDOWED = [TRIGGER_KEYS.EVENT_KEY_4];

  if (SINGLE.includes(value)) {
    return TRIGGER_KEYS.EVENT_KEY_SINGLE;
  } else if (MARRIED.includes(value)) {
    return TRIGGER_KEYS.EVENT_KEY_MARRIED;
  } else if (WIDOWED.includes(value)) {
    return TRIGGER_KEYS.EVENT_KEY_WIDOWED;
  }

  return value;
};

const foreignAccountTypePull = (values) => {
  if (!values) return null;
  const [foreignAccount] = values;

  if (foreignAccount) return foreignAccount;
  return AXCESS_KEYS.FOREIGN_ACC_TYPE_OTHER;
};

const foreignAssetsIdTypePull = (values) => {
  if (!values) return null;
  const [idType] = values;

  switch (idType) {
    case AXCESS_KEYS.FOREIGN_ASSETS_ID_TYPE_FOREIGN:
      return TRIGGER_KEYS.EVENT_KEY_FOREIGN;
    case AXCESS_KEYS.FOREIGN_ASSETS_ID_TYPE_EIN:
      return TRIGGER_KEYS.EVENT_KEY_EIN;
    case AXCESS_KEYS.FOREIGN_ASSETS_ID_TYPE_SSN:
      return TRIGGER_KEYS.EVENT_KEY_SSN;
    default:
      return null;
  }
};

const translateFunctions = {
  date: datePull,
  checkbox: checkboxPull,
  YNcheckbox: checkboxYNPull,
  checkboxYN: checkboxToYNPull,
  selectYN: selectYNPull,
  electronicWithdrawal: withdrawalPull,
  maritalStatus: maritalStatusPull,
  employerId: employerIdPull,
  jointToTaxpayer: jointToTaxpayerPull,
  // Define other functions for translation when pulling prior year data
};

const translatePullData = (axcessTranslate, value) => {
  if (!axcessTranslate || !translateFunctions[axcessTranslate]) return value;

  return translateFunctions[axcessTranslate](value);
};

const stateLocalIncomeRefundPull = (values) => {
  if (!_.isArray(values)) {
    return null;
  }

  const tsj = stateLocalTsjPull(values);
  const taxpayerRefund = _.toSafeInteger(values[9]);
  const spouseRefund = _.toSafeInteger(values[23]);

  if (tsj === AXCESS_KEYS.JOINT_KEY) return `${taxpayerRefund + spouseRefund}`;
  return `${taxpayerRefund}`;
};

const stateLocalTsjPull = (values) => {
  const filterValid = (x) => x && _.toSafeInteger(x) !== 0;

  if (!_.isArray(values)) {
    return AXCESS_KEYS.TAXPAYER_KEY;
  }

  const maritalStatus = values[0];
  const hasJointValues = values.slice(1, 15).filter(filterValid).length
    ? true
    : false;
  const hasSpouseValues = values.slice(15).filter(filterValid).length
    ? true
    : false;

  if (
    maritalStatus === TRIGGER_KEYS.EVENT_KEY_2 &&
    hasJointValues &&
    !hasSpouseValues
  )
    return AXCESS_KEYS.JOINT_KEY;
  if (
    maritalStatus === TRIGGER_KEYS.EVENT_KEY_2 &&
    hasJointValues &&
    hasSpouseValues
  )
    return AXCESS_KEYS.JOINT_KEY;
  return AXCESS_KEYS.TAXPAYER_KEY;

  // Taxpayer or Joint is 1 - 14
  // Spouse 15 - 28
  // If filing status on return is married filing joint and just a value under taxpayer or joint, mark it joint and using the value from just taxpayer column
  // If filing status on return is married filing joint and value under taxpayer or joint AND spouse, mark it joint and use the value from taxpayer and spouse combined
  // If filing status on return is anything else, mark it taxpayer and use just taxpayer value
};

const stateLocalIncomeCityPull = (values) => {
  if (!values && !_.isArray(values)) return null;
  if (values[1] === "city" && values[0]) return values[0];
  return null;
};

const stateLocalIncomeStatePull = (values) => {
  if (!values && !_.isArray(values)) return null;
  if (values[1] === "state" && values[0]) return values[0];
  return null;
};

const unemploymentCompTSJPull = (values) => {
  const [miscZero, cerGovZero] = values;

  if (miscZero) return miscZero;
  if (cerGovZero) return cerGovZero;

  return "T";
};

const unemploymentCompDescPull = (values) => {
  const [miscFive, cerGovFive] = values;

  if (miscFive) return miscFive;
  if (cerGovFive) return cerGovFive;

  return null;
};

const iraTypePull = (axcessValues) => {
  if (!axcessValues) return null;

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));

  const [pyContributions, cyContributions, rothContributions] = amounts;

  if (pyContributions || cyContributions)
    return AXCESS_KEYS.AXCESS_KEY_TRADITIONAL;
  if (rothContributions) return AXCESS_KEYS.AXCESS_KEY_ROTH;

  return null;
};

const iraAmountPull = (axcessValues) => {
  const iraType = iraTypePull(axcessValues);

  if (!iraType) return null;

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const [pyContributions, cyContributions, rothContributions] = amounts;

  switch (iraType) {
    case AXCESS_KEYS.AXCESS_KEY_TRADITIONAL:
      return `${pyContributions + cyContributions}`;
    case AXCESS_KEYS.AXCESS_KEY_ROTH:
      return `${rothContributions}`;
    default:
      return null;
  }
};

const sepTypePull = (axcessValues) => {
  if (!axcessValues) return null;

  const [benCB, sepCB, simCB, ...amounts] = axcessValues;
  const numAmounts = amounts.map((x) => _.toSafeInteger(x));
  const [ben1, ben2, ben3, sep1, sep2, sim1, sim2] = numAmounts;

  if (benCB) return AXCESS_KEYS.AXCESS_KEY_BENEFIT;
  if (sepCB) return AXCESS_KEYS.AXCESS_KEY_SEP;
  if (simCB) return AXCESS_KEYS.AXCESS_KEY_SIMPLE;

  if (ben1 || ben2 || ben3) return AXCESS_KEYS.AXCESS_KEY_BENEFIT;
  if (sep1 || sep2) return AXCESS_KEYS.AXCESS_KEY_SEP;
  if (sim1 || sim2) return AXCESS_KEYS.AXCESS_KEY_SIMPLE;

  return null;
};

const sepAmountPull = (axcessValues) => {
  const sepType = sepTypePull(axcessValues);

  if (!sepType)
    return `${_.toSafeInteger(axcessValues[axcessValues.length - 1])}` || null;

  const [benCB, sepCB, simCB, ...amounts] = axcessValues;
  const numAmounts = amounts.map((x) => _.toSafeInteger(x));
  const [ben1, ben2, ben3, sep1, sep2, sim1, sim2] = numAmounts;

  switch (sepType) {
    case AXCESS_KEYS.AXCESS_KEY_BENEFIT:
      if (!ben1 && !ben2 && !ben3)
        return `${numAmounts[numAmounts.length - 1]}` || null;
      return `${ben1 + ben2 + ben3}`;
    case AXCESS_KEYS.AXCESS_KEY_SEP:
      if (!sep1 && !sep2) return `${numAmounts[numAmounts.length - 1]}` || null;
      return `${sep1 + sep2}`;
    case AXCESS_KEYS.AXCESS_KEY_SIMPLE:
      if (!sim1 && !sim2) return `${numAmounts[numAmounts.length - 1]}` || null;
      return `${sim1 + sim2}`;
    default:
      return `${numAmounts[numAmounts.length - 1]}` || null;
  }
};

const farmCropInsurancePull = (axcessValues) => {
  if (!axcessValues) return "0";

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const [val1, val2] = amounts;

  const cropInsuranceAmt = (val1 || 0) - (val2 || 0);
  return cropInsuranceAmt < 0 ? "0" : `${cropInsuranceAmt}`;
};

const farmGasFuelPull = (axcessValues) => {
  if (!axcessValues) return "0";

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const [fuelAmt] = [amounts.reduce((a, b) => a + b, 0)];

  return `${fuelAmt || "0"}`;
};

const anyValidPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues.find((x) => x) || null;
};

const accountTypePull = (axcessValues) => {
  if (!axcessValues) return LOOKUPS.ACCOUNT_TYPE[1].value;

  if (
    _.toUpper(axcessValues).includes(
      _.toUpper(AXCESS_KEYS.EXCHANGE_KEY_CHECKING)
    )
  ) {
    return LOOKUPS.ACCOUNT_TYPE[1].value;
  }

  if (
    _.toUpper(axcessValues).includes(
      _.toUpper(AXCESS_KEYS.EXCHANGE_KEY_SAVINGS)
    )
  ) {
    return LOOKUPS.ACCOUNT_TYPE[2].value;
  }

  return LOOKUPS.ACCOUNT_TYPE[1].value;
};

const sumAllPull = (axcessValues) => {
  if (!axcessValues || !_.isArray) return null;

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const amount = amounts.reduce((prev, curr) => prev + curr, 0);

  return `${amount}`;
};

const vehicleOtherMilesPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const miles = axcessValues.map((x) => _.toSafeInteger(x));
  const otherMilesAmount = miles[0] - miles[1] - miles[2];
  return otherMilesAmount > 0 ? `${otherMilesAmount}` : null;
};

const foreignAssetsBankInfoLabelPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  let bankName = axcessValues[0] || "";
  const maskedAccountNumber =
    ("" + axcessValues[1]).slice(0, -4).replace(/./g, "X") +
    ("" + axcessValues[1]).slice(-4);
  let accountNumber = axcessValues[1] ? `Acct #: ${maskedAccountNumber}` : "";

  if (bankName.length > 20) {
    bankName = `${bankName.substring(0, 17)}...`;
  }

  if (accountNumber.length > 20) {
    accountNumber = `${accountNumber.substring(0, 17)}...`;
  }

  if (!bankName && !accountNumber) return null;
  return `${bankName}\n${accountNumber}`;
};

const foreignAssetsAccountHeldJointlyOther = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const ownershipType = axcessValues[0];
  if (ownershipType === AXCESS_KEYS.FOREIGN_ASSETS_JOINT_OWNERSHIP_OTHER) {
    return true;
  }

  return null;
};

const foreignIncomeHomeAddressPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const addresses = axcessValues.filter((x) => x?.trim());

  return addresses.length > 0 ? addresses.join(", ") : "";
};

const foreignIncomeHomeOccupantPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const addresses = axcessValues
    .map((row) => {
      if (!row || !_.isArray(row)) return null;

      return row
        .map((x) => x?.trim())
        .filter((x) => x)
        .join(" ");
    })
    .filter((x) => x && x.length);

  return addresses.length > 0 ? addresses : [];
};

const dependentsSummaryPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [relation, ...names] = axcessValues;

  // Base relationship status on lookup
  const relationship =
    LOOKUPS?.RELATIONSHIP?.find(
      (x) => x.value === relation && relation
    )?.name?.trim() ?? "";
  let name = names
    ?.filter((x) => x && _.isString(x) && x?.trim())
    ?.map((x) => x?.trim())
    ?.join(" ");

  return `${name}\n${relationship}`;
};

const returnFn = ([x]) => x;

const pyFunctions = {
  iraTypePull,
  iraAmountPull,
  sepTypePull,
  sepAmountPull,
  anyValidPull,
  unemploymentCompTSJPull,
  unemploymentCompDescPull,
  farmCropInsurancePull,
  farmGasFuelPull,
  stateLocalIncomeCityPull,
  stateLocalIncomeStatePull,
  stateLocalTsjPull,
  stateLocalIncomeRefundPull,
  sumAllPull,
  vehicleOtherMilesPull,
  foreignAssetsBankInfoLabelPull,
  foreignAssetsAccountHeldJointlyOther,
  accountTypePull,
  foreignIncomeHomeAddressPull,
  foreignIncomeHomeOccupantPull,
  dependentsSummaryPull,
};

const getPYCalculationFunction = (functionName) => {
  return functionName && pyFunctions[functionName]
    ? pyFunctions[functionName]
    : returnFn;
};

const filterPYByIdentifier = (identifier) => (priorYearData) => {
  if (
    !identifier?.section ||
    !identifier?.id ||
    !priorYearData?.section ||
    !priorYearData?.id
  )
    return false;

  return (
    priorYearData.section === identifier.section &&
    priorYearData.id.startsWith(identifier.id)
  );
};

const filerPYByControl = (controls) => (priorYearData) => {
  if (!controls?.entityId) return true;

  // TODO: set other control properties such as sheetId
  return controls.entityId === priorYearData?.entityId;
};

const findEntityNameFields = (sections) => {
  const entityPriorities = [];

  sections?.forEach((section) => {
    section?.groups?.forEach((group) => {
      group?.fields
        ?.filter((x) => x.isEntityName === true)
        ?.forEach((entityNameField) => {
          entityPriorities.push({
            name: entityNameField?.name || "",
            priority: entityNameField?.entityNamePriority || 0,
            groupId: group.groupId,
            sectionId: section.sectionId,
          });
        });
    });
  });

  return entityPriorities.sort((a, b) => b.priority - a.priority);
};

const matchToLookupOptions = (foundValue, lookup, priorCompareOptions) => {
  const compareAllLookup = priorCompareOptions?.compareAllLookup ? true : false;
  const compareFn = compareAllLookup ? allCompare : simpleValueCompare;
  const lookups = LOOKUPS[lookup] || LOOKUPS.YESNO;
  const lookupItem = lookups.find((x) => compareFn(foundValue)(x));

  return lookupItem ? lookupItem.value : "";
};

const findFromPYData = (priorYearEntity, field, fieldName) => {
  const axcessPull = field && field.axcess?.pull;

  const key = axcessPull?.fields?.[0] || field?.name || fieldName;
  let foundPYData = null;

  priorYearEntity?.forEach((entitySection) => {
    entitySection?.data?.forEach((worksheetSection) => {
      if (foundPYData) return;
      const foundField = worksheetSection?.fields?.find(hasMatchingPYKey(key));
      const foundLine = worksheetSection?.lineItems?.find((line) =>
        line?.find(hasMatchingPYKey(key))
      );

      if (foundField) {
        foundPYData = foundField.value || null;
      } else if (foundLine && !foundPYData) {
        const foundLineField = foundLine?.find(hasMatchingPYKey(key));
        if (foundLineField) {
          foundPYData = foundLineField.value || null;
        }
      }
    });
  });

  const foundValue = axcessPull?.fn([foundPYData]) || foundPYData;

  if (field?.type === "select") {
    return matchToLookupOptions(
      foundValue,
      field.lookup,
      field?.priorCompareOptions
    );
  }

  return foundValue;
};

const findFromData = (worksheetSection, key, searchType) => {
  if (searchType === "fields") {
    return [findFieldInLine(worksheetSection?.fields, key)].filter((x) => x);
  } else if (searchType === "lineItems") {
    const a = worksheetSection?.lineItems
      ?.map((line) => {
        return line.find((x) => x.key === key);
      })
      .filter((x) => x);
    return a;
  }

  return [];
};

const findSection = (sections, sectionToFind) => {
  return sections?.find((x) => x.name === sectionToFind);
};

const findFieldInLine = (field, key) => {
  return field?.find(hasMatchingPYKey(key));
};

const hasMatchingPYKey = (key) => (field) => {
  return field?.key === key;
};

const simpleValueCompare = (value) => (compareObj) =>
  simpleCompare(value)(compareObj?.value);
const allCompare = (value) => (compareObj) => {
  return Object.keys(compareObj).some((compareKey) => {
    if (_.isArray(compareObj[compareKey])) {
      return compareObj[compareKey].some(simpleCompare(value));
    }
    return simpleCompare(value)(compareObj[compareKey]);
  });
};

const simpleCompare = (value) => (compareValue) => compareValue === value;

const findMergeData = (priorYearData, mergeDataIdentifiers) => {
  const foundMergeData = mergeDataIdentifiers.map((mergeDataIdentifier) => {
    const filteredMergeData = priorYearData.filter(
      filterPYByIdentifier(mergeDataIdentifier)
    );
    const foundPYData = findFromPYData(filteredMergeData, {
      name: mergeDataIdentifier.key,
    });

    return !_.isNil(foundPYData)
      ? { key: mergeDataIdentifier.key, value: foundPYData }
      : null;
  });

  return foundMergeData;
};

const getPullFieldValues = (priorYearData) => (key) => {
  const foundPriorYearItem = priorYearData?.find(hasMatchingPYKey(key));
  return foundPriorYearItem?.value || null;
};

const hsaTablePopulate = (group, metadata) => {
  const sumOnColumns = metadata?.sumOnColumns || [];

  const sumHolder = {
    [AXCESS_KEYS.TAXPAYER_KEY]: {},
    [AXCESS_KEYS.SPOUSE_KEY]: {},
  };
  const rows = _.cloneDeep(group?.lineItems) || [];
  const filterForValue = (valueTS) => (row) =>
    row.some((column) => column?.default === valueTS);
  const sumOnRow = (valueTS) => (row) => {
    sumOnColumns.forEach((columnKey) => {
      const foundColumn = row.find((x) => x.name === columnKey);
      if (foundColumn && foundColumn.default) {
        if (sumHolder[valueTS]?.[columnKey]) {
          sumHolder[valueTS][columnKey] += parseMoneyToNumber(
            foundColumn.default
          );
        } else {
          sumHolder[valueTS][columnKey] = parseMoneyToNumber(
            foundColumn.default
          );
        }
      }
    });
  };

  // Build new row with first column value
  const buildNewRow = (valueTS) => {
    const row = _.cloneDeep(group.fields);
    row[0].default = valueTS;
    row[0].inputState = INPUT_STATE.PRIOR_INPUT;

    row.forEach((column) => {
      if (_.has(sumHolder[valueTS], column.name)) {
        column.inputState = INPUT_STATE.PRIOR_INPUT;
        column.default =
          column.isMoney === false
            ? noDollarFormatter(sumHolder[valueTS][column.name])
            : moneyFormatter(sumHolder[valueTS][column.name]);
      }
    });

    return row;
  };

  // Grab first available Taxpayer and Spouse row
  rows
    .filter(filterForValue(AXCESS_KEYS.TAXPAYER_KEY))
    .forEach(sumOnRow(AXCESS_KEYS.TAXPAYER_KEY));
  rows
    .filter(filterForValue(AXCESS_KEYS.SPOUSE_KEY))
    .forEach(sumOnRow(AXCESS_KEYS.SPOUSE_KEY));

  const results = [];
  // Add the taxpayer row first, and then spouse row. Build row if not available
  results.push(buildNewRow(AXCESS_KEYS.TAXPAYER_KEY));
  results.push(buildNewRow(AXCESS_KEYS.SPOUSE_KEY));

  group.lineItems = results;
};

const getFieldObject = (currentFormSections, fieldLocation) => {
  if (!currentFormSections || !fieldLocation) return null;

  const section = currentFormSections?.find(
    (section) => section.sectionId === fieldLocation.sectionId
  );
  const group = section?.groups?.find(
    (group) => group.groupId === fieldLocation.groupId
  );
  const field = group?.fields.find(
    (field) => field.name === fieldLocation.name
  );

  return field;
};

export {
  foreignCountryPull,
  foreignAccountTypePull,
  americanStatesPull,
  canadianProvincePull,
  mexicanStatesPull,
  foreignProvincePull,
  datePull,
  jointToTaxpayerPull,
  checkboxPull,
  checkboxToYNPull,
  checkboxYNPull,
  withdrawalPull,
  maritalStatusPull,
  foreignAssetsIdTypePull,
  translateFunctions,
  translatePullData,
  iraTypePull,
  accountTypePull,
  unemploymentCompDescPull,
  unemploymentCompTSJPull,
  stateLocalIncomeCityPull,
  stateLocalIncomeStatePull,
  stateLocalTsjPull,
  stateLocalIncomeRefundPull,
  iraAmountPull,
  sepTypePull,
  sepAmountPull,
  farmCropInsurancePull,
  farmGasFuelPull,
  anyValidPull,
  sumAllPull,
  vehicleOtherMilesPull,
  foreignAssetsBankInfoLabelPull,
  foreignAssetsAccountHeldJointlyOther,
  foreignIncomeHomeAddressPull,
  foreignIncomeHomeOccupantPull,
  dependentsSummaryPull,
  pyFunctions,
  getPYCalculationFunction,
  filterPYByIdentifier,
  filerPYByControl,
  findEntityNameFields,
  findFromPYData,
  findSection,
  findFromData,
  childCareExpensesPull,
  simpleValueCompare,
  allCompare,
  matchToLookupOptions,
  zipPostalPull,
  findMergeData,
  hasMatchingPYKey,
  getPullFieldValues,
  hsaTablePopulate,
  getFieldObject,
};
