import { useState, useEffect } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '@components/formRenderer';
import { sectionFieldLogic, triggeredEvent } from './vehicleEnergyLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import vehicleEnergy2021 from './2021';
import vehicleEnergy2022 from './2022';
import vehicleEnergy2023 from './2023';
import vehicleEnergy2024 from './2024';
import * as NAV from '@utilities/constants/navigation';

function VehicleEnergy(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.VEHICLEENERGY);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: vehicleEnergy2021(year),
            2022: vehicleEnergy2022(year),
            2023: vehicleEnergy2023(year),
            2024: vehicleEnergy2024(year),
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setSectionState(formSections);
        // eslint-disable-next-line
    }, []);

    return (
        <FormRenderer
            sections={sectionState}
            key="vehicleEnergyForm"
            formName={REDUX.ENERGY_EFFICIENT}
            sectionFieldLogic={sectionFieldLogic}
            triggeredEvent={triggeredEvent}
            isExportForm={isExportForm}
        />
    );
}

export default VehicleEnergy;
