import { setConditionalFieldValidation } from '@components/formRenderer/triggers';
import { validateDate } from '../taxPayments/helpers/taxPaymentsValidators';

const setHasSelectedStateTaxPayment = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    name[0].groups[2].isVisible = event !== ' ' ? true : false;
    name[1].isVisible = event !== ' ' ? true : false;
    
    setFormSections(name);
};

const validateStateTaxDatePaid = (options) => {
    const { field } = options;

    validateDate(field)
}

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'setHasSelectedStateTaxPayment':
            setHasSelectedStateTaxPayment(event, options);
        break;
    default:
        break;
    }
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) { 
        case 'setConditionalFieldValidation':
            setConditionalFieldValidation(options);
        break;
        case 'validateStateTaxDatePaid':
            validateStateTaxDatePaid(options);
        break;
    default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};