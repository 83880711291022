import * as STRING from '@utilities/constants/strings.js';

function InvalidFilesMessage(props) {
    const { invalidFiles } = props;
    return (
        <>
            <h3>{STRING.INVALID_FILES_MESSAGE_H3}</h3>
            <h4>
                {STRING.INVALID_FILES_MESSAGE_H4} <br />
                {STRING.INVALID_CHARS}
            </h4>
            <div className='invalidFilesContainer'
                style={{
                    border: "solid",
                    borderWidth: "1px",
                    borderRadius: "2px",
                    backgroundColor: "#F7F7F7",
                    maxHeight: "20vh",
                    overflow: "auto",
                    textAlign: "left",
                    paddingLeft: "10px",
                    wordBreak: "break-all"
                }}
            >
                {
                    invalidFiles.map((file, idx) => <li key={idx} id={idx}>{file}</li>)
                }
            </div>
        </>
    );
}

export default InvalidFilesMessage;