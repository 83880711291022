/**
 * 
 * @param {string} url 
 * @param {callback} callback 
 * @param {number} timeout - Amount of milliseconds that the initialization should be delayed, defaults to 0
 */
const initialize = (url, callback, timeout = 0) => {
  const w = window;
  const d = document;

  const l = () => {
    setTimeout(() => {
      const id = 'snCsmEcScript';
      let existing = d.getElementById(id);

      if (!existing) {
        const s = d.createElement('script');
        s.id = id;
        s.type = 'text/javascript';
        s.async = true;
        s.src = url;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);

        s.addEventListener('load', () => {
          callback();
          s.removeEventListener('load', callback, false);
        });
      }
    }, timeout);
  };

  if (document.readyState === 'complete') {
    l();
  } else {
    w.addEventListener('load', l, false);
  }
};

export default initialize;