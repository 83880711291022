import * as EVENT from '@utilities/constants/triggerKeys';
import * as LOOKUPS from '@utilities/constants/lookupInfo';
import fieldDataSetter from "@utilities/helpers/getFieldData";

const setChangeCountryForeignAssetsInformation = (event, options) => {
    const { sections, formSections, setFormSections, inputState } = options;
    const name = formSections ? formSections : sections;

    const isUnitedStates = event === EVENT.KEY_UNITED_STATES;
    const isCanada = event === EVENT.KEY_CANADA;
    const isMexico = event === EVENT.KEY_MEXICO;
    const isOther = !isUnitedStates && !isCanada && !isMexico;

    if (name[1].groups[0]) {
        // state
        name[1].groups[0].fields[6].isVisible = isUnitedStates;
    
        // Province
        name[1].groups[0].fields[8].isVisible = isCanada;
        
        // State/Prov
        name[1].groups[0].fields[7].isVisible = isOther;

        // Mexico State
        name[1].groups[0].fields[9].isVisible = isMexico;

        name[1].groups[0].fields[3].default = event;
        const prevState = name[1].groups[0].fields[3].inputState || 0;
        name[1].groups[0].fields[3].inputState = (prevState & inputState);
        setFormSections(name);
    }
}; 

const setForeignAccountJointOwnerID = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name[2].groups[0].fields[4].isVisible = event === EVENT.EVENT_KEY_SSN_ITIN;
    name[2].groups[0].fields[5].isVisible = event === EVENT.KEY_EIN;
    name[2].groups[0].fields[3].isVisible = event !== EVENT.EVENT_KEY_SSN_ITIN && event !== EVENT.EVENT_KEY_EIN ;

    name[2].groups[0].fields[2].default = event;
    setFormSections(name);
};

const setChangeCountryForeignAssets = (event, options) => {
    const { sections, formSections, setFormSections, inputState } = options;
    const name = formSections ? formSections : sections;

    const isUnitedStates = event === EVENT.KEY_UNITED_STATES;
    const isCanada = event === EVENT.KEY_CANADA;
    const isMexico = event === EVENT.KEY_MEXICO;
    const isOther = !isUnitedStates && !isCanada && !isMexico;

    if (name[2].groups[2]) {
        // state
        name[2].groups[2].fields[3].isVisible = isUnitedStates;
    
        // Province
        name[2].groups[2].fields[5].isVisible = isCanada;
        
        // State/Prov
        name[2].groups[2].fields[4].isVisible = isOther;

        // Mexico State
        name[2].groups[2].fields[6].isVisible = isMexico;

        name[2].groups[2].fields[0].default = event;
        const prevState = name[2].groups[0].fields[0].inputState || 0;
        name[2].groups[2].fields[0].inputState = (prevState & inputState);
        setFormSections(name);
    }
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
    case 'setChangeCountryForeignAssetsInformation':
        setChangeCountryForeignAssetsInformation(event, options);
        break;
    case 'setForeignAccountJointOwnerID':
        setForeignAccountJointOwnerID(event, options);
        break;
    case 'setChangeCountryForeignAssets':
        setChangeCountryForeignAssets(event, options);
        break;
    default:
        break;
    }
};

const setBankInfoLabel = (options) => {
    const { group, parentGroup, parentIndex } = options;
    let bankName = '';
    let accountNumber = '';
    const inputState = [];
    group.fields.forEach((groupField) => {
        if(groupField.inputState && (groupField.name === 'IFDSFBAR.3' || groupField.name === 'IFDSFBAR.2')){
            inputState.push(groupField.inputState);
        }
        if (groupField.name === 'IFDSFBAR.3') {
            bankName = groupField.default || '';
        }
        if (groupField.name === 'IFDSFBAR.2') {
            const maskedAccountNumber = ('' + groupField.default).slice(0, -4).replace(/./g, 'X') + ('' + groupField.default).slice(-4);
            accountNumber = groupField.default && groupField.default !== ' ' ? `Acct #: ${maskedAccountNumber}` : '';
        }
    });

    if (bankName.length > 20) {
        bankName = `${bankName.substring(0, 17)}...`;
    }

    if (accountNumber.length > 20) {
        accountNumber = `${accountNumber.substring(0, 17)}...`;
    }

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === 'foreignAccountInfo') {
            lineItemField.default = `${bankName}\n${accountNumber}`;
            if(inputState.length > 0){
                lineItemField.inputState = inputState[0];
            }
        }
    });
};

const syncUpDenseRowFieldValue = (options) => {
    const { field, parentGroup, parentIndex } = options;
    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === field.name) {
            lineItemField.inputState = fieldDataSetter.inputState;
            lineItemField.default = field.default;
        }
    });
};

const setForeignCurrencySymbolLis = (options) => {
    const { field, parentGroup, parentIndex, section } = options;
    
    if (field.lookup === 'FOREIGN_CURRENCIES') {
        let groupCurrency = '$';
        groupCurrency = getGroupCurrency(field);
        section.groups[5].currencySymbol = groupCurrency;
        parentGroup.lineItems[parentIndex][0].currencySymbol = groupCurrency;
    }
};

const syncDownDenseRowFieldValue = (options) => {
    const { group, field, index } = options;
    
    group.entities[index].sections.forEach((lineSection) => {
        lineSection.groups.forEach((lineSectionGroup) => {
            lineSectionGroup.fields.forEach((lineSectionGroupField) => {
                if (lineSectionGroupField.name === field.name) {
                    lineSectionGroupField.inputState = fieldDataSetter.inputState;
                    lineSectionGroupField.default = field.default;
                }
            })
        })
    });
};

const getGroupCurrency = (field) => {
    const lookup = LOOKUPS[field.lookup];
    const lookupValue = lookup.find((option) => option.value === field.default);
    return lookupValue ? lookupValue['symbol'] : '$';
};

const setForeignCurrencySymbol = (options) => {
    const { group, field, index, } = options;

    if (field.lookup === 'FOREIGN_CURRENCIES') {
        let groupCurrency = '$';
        groupCurrency = getGroupCurrency(field);
        group.lineItems[index][0].currencySymbol = groupCurrency;
        group.entities[index].sections[1].groups[4].currencySymbol = groupCurrency;
    }
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
    case 'setBankInfoLabel':
        setBankInfoLabel(options);
        break;
    case 'syncUpDenseRowFieldValue':
        syncUpDenseRowFieldValue(options);
        break;
    case 'setForeignCurrencySymbolLis':
        setForeignCurrencySymbolLis(options);
        break;
    case 'syncDownDenseRowFieldValue':
        syncDownDenseRowFieldValue(options);
        break;
    case 'setForeignCurrencySymbol':
        setForeignCurrencySymbol(options);
        break;
    default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};