import { COMPLETED, DOES_NOT_APPLY } from '@utilities/constants/cardStatus';
import { COMPLETED_TEXT, SUBMIT_TEXT } from '@utilities/constants/strings';
import { MenuItem } from 'react-md';

function SubmitToClaMenuItem(props) {
    const {
        hasValidTaxReturnStatus,
        isDashboard,
        statusId,
        setCompletedOnClick,
        showSubmitDialog
    } = props;

    const menuItemText = isDashboard ? SUBMIT_TEXT : COMPLETED_TEXT;
    const cardClass = `cardMenuItems ${!isDashboard && statusId === COMPLETED ? 'selected' : ''}`;
    const onCardClick = () => {
        if (isDashboard) {
            showSubmitDialog();
        } else if (statusId !== DOES_NOT_APPLY) {
            setCompletedOnClick();
        }
    }

    // if has valid tax return, should not render menu item
    return !hasValidTaxReturnStatus
        ? (
            <MenuItem
                key='header-complete-option'
                data-testid="header-complete-option"
                className={cardClass}
                onClick={onCardClick}
            >
                {menuItemText}
            </MenuItem>
        )
        : <></>;
}

export default SubmitToClaMenuItem;