import { currentYear } from '@utilities/hooks/usePageFramework';

const setOrganizationNameLabel = (options) => {
    const { group, parentGroup, parentIndex } = options;
    let organizationName = '';
    let Purchased = '';

    group.fields.forEach((groupField) => {
        if (groupField.name === 'IFDS5ORL.6') {
            organizationName = groupField.default ? `${groupField.default}` : '';
        }
        if (groupField.name === 'IFDS5ORL.15') {
            Purchased = groupField.default ? `Contributed on ${groupField.default}` : '';
        }
    });

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === 'organizationNameLabel') {
            lineItemField.default = `${organizationName}\n${Purchased}`;
        }
    });
};

const validateCurrentYear =  (options) => {
    const { field: date } = options;
    
    const dateOfContribution = new Date(date.default);
    if(date?.default && dateOfContribution.getFullYear()!==currentYear) {
        date.error = true;
        date.errorMessage = `Year can only be ${currentYear}`;             
    }
}

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) { 
        case 'setOrganizationNameLabel':
            setOrganizationNameLabel(options);
        break;
        case 'validateCurrentYear':
            validateCurrentYear(options);
        break;
        default:
        break;
    }
};

export {
    sectionFieldLogic
};