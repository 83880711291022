import PropTypes from 'prop-types';
import { GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { MoreVertSVGIcon } from '@react-md/material-icons';
import { DropdownMenu, MenuItem } from 'react-md';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { BELOW_INNER_RIGHT_ANCHOR } from '@react-md/utils';

const CustomToolbar = ({ setFilterButtonEl, filterHighlight, isTileActive, handleExportToCSV, orgQuery, isFilterActive }) => {
  const {
    dispatch,
    ACTION,
  } = usePageFramework();

  return (
    <GridToolbarContainer data-testid='custom-toolbar-container' style={{display: 'flex'}}>
      <GridToolbarFilterButton
        data-testid='custom-toolbar-button'
        ref={setFilterButtonEl}
        componentsProps={{
          button: {
            startIcon: (
              <FilterAltIcon className={filterHighlight} data-testid='custom-toolbar-icon' />
            )
          }
        }}
      />

      <DropdownMenu
        id='custom-toolbar-kabob'
        data-testid='custom-toolbar-kabob'
        buttonType="icon"
        buttonChildren={<MoreVertSVGIcon className="dropDownMenu moreIconBtn" />}
        anchor={BELOW_INNER_RIGHT_ANCHOR}
        style={{
          color: '#ffffff',
          display: 'inline-flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: 'auto',
        }}
        
      >
        <MenuItem 
        className='exportMenuItem'
        onClick={() => {
          if(isTileActive || isFilterActive) {
          handleExportToCSV(dispatch, ACTION, orgQuery)}
        }
        }
        style={{
          color: !isTileActive && !isFilterActive ? '#979797' : '#000000',
          opacity: !isTileActive && !isFilterActive ? 0.75 : 1,
        }}
        >
          Export to CSV
        </MenuItem>
      </DropdownMenu>
    </GridToolbarContainer>

  );
};

CustomToolbar.propTypes = {
  setFilterButtonEl: PropTypes.func.isRequired,
};

export default CustomToolbar;
