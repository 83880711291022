// common modules
import { Button, Select } from 'react-md';
import { useState, useEffect } from "react";
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
  DialogFooter,
} from "@react-md/dialog";
import {
  Form,
  TextField,
  FormMessage,
  Checkbox,
} from "@react-md/form";

// custom modules
import '@components/restfulTable/CreateResourceDialog.css';

function CreateResourceDialog(props) {
  const { visible, resourceName, resourceProps, onCancel, onConfirm } = props;
  const [data, setData] = useState({});
  const changeFieldValue = (event, col) => {
    if (col.type === "dropDown") {
      setData((prev) => ({ ...prev, [col.key]: event }));
    } else if (col.type === "datetimepicker") {
      setData((prev) => ({ ...prev, [col.key]: event.target.value }));
    } else {
      const { value, checked } = event.target;
      setData((prev) => ({ ...prev, [col.key]: col.type === 'boolean' ? checked : value }));
    }
  };
  const hasMissingData = resourceProps.filter((prop) => prop.required).map((prop) => !data[prop.key]).some(Boolean);

  useEffect(() => {
    setData({});
  }, [visible])

  const buildFields = (col) => {
    let ctrl = null;
    switch (col?.type) {
      case "boolean":
        ctrl = <div key={`checkbox-field-${col.key}-container`}>
          <Checkbox
            id={`checkbox-field-${col.key}`}
            key={`checkbox-field-${col.key}`}
            name={`checkbox-field-${col.key}`}
            label={col.text}
            checked={data[col.key] === true || data[col.key] === 'true' ? true : false}
            onChange={(event) => changeFieldValue(event, col)}
          />
          <FormMessage id={`text-field-${col.key}-message`} error>{col.create.required && !data[col.key] ? 'This field is required' : ''}</FormMessage>
        </div>;
        break;
      case "dropDown":
        ctrl = <div key={`dropdown-field-${col.key}-container`}>
          <Select
            options={col.options}
            id={`select-field-${col.key}`}
            key={`select-field-${col.key}`}
            name={`select-field-${col.key}`}
            value={data[col.key]}
            label={col.text}
            onChange={(event) => changeFieldValue(event, col)} />
          <FormMessage id={`select-field-${col.key}-message`} error>{col.create.required && !data[col.key] ? 'This field is required' : ''}</FormMessage>
        </div>;
        break;
      case "datetimepicker":
        ctrl = <div key={`datepicker-field-${col.key}-container`} style={{ position: "relative" }}>
          <TextField
            id={`date-field-${col.key}`}
            name={`date-field-${col.key}`}
            value={data[col.key]}
            onChange={(event) => changeFieldValue(event, col)}
            type="datetime-local"
            placeholder={`Please select ${col.text}`}
            label={col.text}
            required={col.create.required ? true : false}
            disabled={col.create.readOnly ? true : false}
          />
          <FormMessage id={`date-field-${col.key}-message`} error>{col.create.required && !data[col.key] ? 'This field is required' : ''}</FormMessage>
        </div>
        break;
      default:
        ctrl = <div key={`text-field-${col.key}-container`}>
          <TextField
            id={`text-field-${col.key}`}
            key={`text-field-${col.key}`}
            type="text"
            label={col.text}
            value={data[col.key]}
            required={col.create.required ? true : false}
            onChange={(event) => changeFieldValue(event, col)}
            disabled={col.create.readOnly ? true : false} />
          <FormMessage id={`text-field-${col.key}-message`} error>{col.create.required && !data[col.key] ? 'This field is required' : ''}</FormMessage>
        </div>;
        break;
    }
    return ctrl;
  }

  return (
    <Dialog
      id="create-resource-dialog"
      role="alertdialog"
      visible={visible}
      onRequestClose={() => { }}
      aria-labelledby="create-resource-dialog-title"
      data-testid="create-resource-dialog-title"
    >
      <DialogHeader>
        <DialogTitle>{`Create ${resourceName}`}</DialogTitle>
      </DialogHeader>
      <DialogContent>
        <Form>
          {resourceProps.filter((props) => props.create.show).map((col) => buildFields(col))}
        </Form>
      </DialogContent>
      <DialogFooter>
        <Button id="dialog-submit" onClick={() => onConfirm(data)} disabled={hasMissingData}>Create</Button>
        <Button id="dialog-close" onClick={() => onCancel()}>Close</Button>
      </DialogFooter>
    </Dialog>
  );
}

export default CreateResourceDialog;