import { useState } from 'react';
import { TextField, FontIcon, AutoComplete } from 'react-md';
// import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const SearchField = (props) => {
    const { placeholder, options, onSearchSelect } = props;
    const [ focus, setFocus ] = useState(false);

    const inActiveStyles = {background: 'none', borderBottom: '0.5px solid #fff', width: '200px', height: '27px', };
    const activeStyles = {background: '#fff', width: '200px', height: '27px', };
    // const options1 = [{id: 0, name: 'Sasa Smythe', title:'Mom'}, {id: 1, name: 'Bob Holland', title:'Painter'}, {id: 0, name: 'Tom Smith', title:'Doctor'}, {id: 0, name: 'John Smith', title:'Nurse'},];
    // const optionsMd = [{label: <div><span className='searchName'>Smith Sasa</span><span className='searchName searchTitle'> - Mother</span></div>, 
    // value: 'Smith Sasa', entityindex: 0 },
    // {label: <div><span className='searchName'>Smith Ashira</span><span className='searchName searchTitle'> - Mother</span></div>, 
    // value: 'Smith Ashira', entityindex: 1 },
    // {label: <div><span className='searchName'>Smith Zane</span><span className='searchName searchTitle'> - Mother</span></div>,
    // value: 'Smith Zane', entityindex: 2},
    // {label: <div><span className='searchName'>Smith Joey</span><span className='searchName searchTitle'> - Mother Father</span></div>, 
    // value: 'Smith Joey', entityindex: 3 }];
    const sortNameAscending = (optionA, optionB) => optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase());
    const filteredOptions = options.filter(option => option?.label).sort(sortNameAscending);
    // const formatResult = (item) => {
    //     return (
    //       <>
    //         <span className='searchName'>{item.name}</span>
    //         <span className='searchName searchTitle'> - {item.title}</span>
    //       </>
    //     )
    //   }

    const handleSearchSelect = (autoCompleteData) => {
        onSearchSelect(autoCompleteData);
        setFocus(false);
    };

    return (
        <div>{ !focus ? 
            <TextField 
                id={'table-manager-searchfield'}
                className={'tableManagerSearchField'}
                style={inActiveStyles}
                theme={'underline'} 
                placeholder={placeholder} 
                inline
                dense
                onFocus={()=>{setFocus(true);}}
                rightChildren={<FontIcon className={'tableManagerSearchLens'}> search </FontIcon>}
                data-testid='search-field-inactive'
            /> :
            <AutoComplete
                id="object-dataset-3"
                placeholder={'Search here'}
                className={'autoCompleteField'}
                style={activeStyles}
                listboxClassName={'searchOption'}
                rightChildren={<FontIcon className={'tableManagerSearchLens'} style={{color: '#4b4b4b'}}> search </FontIcon>}
                data={filteredOptions}
                // onBlur={()=>setFocus(false)}
                onAutoComplete={handleSearchSelect}
                autoFocus
                labelKey="label"
                valueKey="value"
                data-testid='search-field-active'
            />}
            {/* // <div className='searchWrapper'> */}
            {/* //     <ReactSearchAutocomplete  */}
            {/* //         id='org-manager-search'
            //         placeholder={'Search here'}
            //         items={options1}
            //         autoFocus
            //         formatResult={formatResult}
            //         showIcon={false}
            //         showNoResultsText={'No matching records found'}
            //         fuseOptions={{ minMatchCharLength: 2 }}
            //         styling={{ */}
            {/* //         height: "27px",
            //         border: "1px solid #7dd2d3",
            //         borderRadius: "3px",
            //         backgroundColor: "#fff",
            //         boxShadow: "none",
            //         hoverBackgroundColor: "none",
            //         color: "#4b4b4b",
            //         fontSize: "12px",
            //         fontFamily: "museo-sans",
            //         iconColor: "#4b4b4b",
            //         lineColor: "#4b4b4b",
            //         placeholderColor: "darkgreen",
            //         clearIconMargin: "3px 8px 0 0",
            //         zIndex: 4,
            //         }}
            //     /> </div>} */}
        </div>
    );
};

export default SearchField;
