import { Validators } from 'cla-formrenderer';
import {
    OTHER_INCOME_TAX_REFUNDS_UPLOAD,
    OTHER_INCOME_UNEMPLOYMENT_UPLOAD,
    OTHER_INCOME_GAMBLING_WINNINGS_UPLOAD,
    OTHER_INCOME_GAMBLING_LOSSES_UPLOAD,
    OTHER_INCOME_ALIMONY_UPLOAD
} from '@utilities/constants/strings';

const miscIncomeFn = (year) => {
    const sections = [
        {
            title: 'State And Local Income Tax Refunds (Form 1099-G)',
            shortTitle: 'State And Local Income Tax Refunds',
            isVisible: true,
            collapseClass: 'collapsibleForm',
            sectionId: 1,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Government'],
                            fieldPrefixes: ['G20632'],
                            fieldIdentifiers: ['G20632.14', 'G20632.15', 'G20632.43', 'IFDSGEN.2'],
                            mergeWithOtherData: [
                                { section: 'General', id: 'Basic Data', worksheet: 'General', key: 'IFDSGEN.2' },
                            ],
                            mergeWithContextData: [
                                { key: 'stateLocalIncomeTaxYear', value: '2020' }, // TODO: Grab year from global state
                            ],
                            lineFilterType: 'any',
                            lineFilters: [
                                { keys: ['G20632.43'], filterType: 'inGroupNotZero' },
                            ],
                        }
                    },
                    fields: [
                        {
                            label: 'T/S/J', name: 'stateLocalTsj', type: 'select', lookup: 'PAYER', placeholder: 'Select', prior: true,
                            pullCalculation: {
                                keyIdentifiers: [
                                    'IFDSGEN.2', // 0 Marital status
                                    'G20632.34', // 1 Taxpayer or Joint values start
                                    'G20632.37', // 2
                                    'G20632.35', // 3
                                    'G20632.83', // 4
                                    'G20632.38', // 5
                                    'G20632.40', // 6
                                    'G20632.41', // 7
                                    'G20632.66', // 8
                                    'G20632.43', // 9
                                    'G20632.44', // 10
                                    'G20632.45', // 11
                                    'G20632.46', // 12
                                    'G20632.47', // 13
                                    'G20632.48', // 14 Taxpayer or Joint values end
                                    'G20632.36', // 15 Spouse values start
                                    'G20632.9', //  16 
                                    'G20632.10', // 17
                                    'G20632.84', // 18
                                    'G20632.39', // 19
                                    'G20632.11', // 20
                                    'G20632.57', // 21
                                    'G20632.65', // 22
                                    'G20632.56', // 23
                                    'G20632.81', // 24
                                    'G20632.59', // 25
                                    'G20632.60', // 26
                                    'G20632.61', // 27
                                    'G20632.62', // 28 Spouse values end
                                ],
                                fn: 'stateLocalTsjPull'
                            }
                        },
                        {
                            label: 'State', name: 'stateLocalIncomeState', type: 'select', lookup: 'STATES', priorCompareOptions: { compareAllLookup: true }, placeholder: 'State', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['G20632.14', 'G20632.15'],
                                fn: 'stateLocalIncomeStatePull'
                            }
                        },
                        {
                            label: 'City', name: 'stateLocalIncomeCity', type: 'freeText', placeholder: 'City', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['G20632.14', 'G20632.15'],
                                fn: 'stateLocalIncomeCityPull'
                            }
                        },
                        { label: 'Tax Year', name: 'stateLocalIncomeTaxYear', type: 'year', placeholder: ' Select', prior: true, validations: { ...Validators.year() } },
                        {
                            label: 'Amount', name: 'stateLocalIncomeRefund', type: 'money', isTotal: true, placeholder: '0', prior: true, showToFooter: true,
                            pullCalculation: {
                                keyIdentifiers: [
                                    'IFDSGEN.2', // 0 Marital status
                                    'G20632.34', // 1 Taxpayer or Joint values start
                                    'G20632.37', // 2
                                    'G20632.35', // 3
                                    'G20632.83', // 4
                                    'G20632.38', // 5
                                    'G20632.40', // 6
                                    'G20632.41', // 7
                                    'G20632.66', // 8
                                    'G20632.43', // 9
                                    'G20632.44', // 10
                                    'G20632.45', // 11
                                    'G20632.46', // 12
                                    'G20632.47', // 13
                                    'G20632.48', // 14 Taxpayer or Joint values end
                                    'G20632.36', // 15 Spouse values start
                                    'G20632.9', //  16 
                                    'G20632.10', // 17
                                    'G20632.84', // 18
                                    'G20632.39', // 19
                                    'G20632.11', // 20
                                    'G20632.57', // 21
                                    'G20632.65', // 22
                                    'G20632.56', // 23
                                    'G20632.81', // 24
                                    'G20632.59', // 25
                                    'G20632.60', // 26
                                    'G20632.61', // 27
                                    'G20632.62', // 28 Spouse values end
                                ],
                                fn: 'stateLocalIncomeRefundPull'
                            }
                        },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', col: 2, colTablet: 2 },
                            { label: 'State', col: 2, colTablet: 2 },
                            { label: 'City', col: 3, colTablet: 1 },
                            { label: 'Tax Year', col: 2, colTablet: 1 },
                            { label: 'Refund Amount', col: 2, colTablet: 1, align: 'right' },
                            { label: 'IRS 1099-G', col: 1, colTablet: 1, align: 'left' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional State And Local Income Tax Refunds (Form 1099-G)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 6, colTablet: 6, },
                            { type: 'totalMoney', name: 'stateLocalIncomeRefund', col: 5, colTablet: 1, label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_INCOME_TAX_REFUNDS_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Other Income / Unemployment Compensation (Form 1099-MISC, 1099-NEC, 1099-G)',
            shortTitle: 'Other Income',
            isVisible: true,
            collapseClass: 'collapsibleForm',
            sectionId: 2,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Certain Government Payments (IRS 1099-G)', 'Miscellaneous Income (IRS 1099-MISC)'],
                            fieldIdentifiers: ['IFDSMISC.0', 'IFDSMISC.4', 'IFDSMISC.6', 'IFDSMISC.9', 'IFDSMISC.15', 'IFDS109G.0', 'IFDS109G.5', 'IFDS109G.14'],
                            lineFilterType: 'omitAny',
                            lineFilters: [
                                { key: 'IFDSMISC.41', filterType: 'containsKey' },
                                { key: 'IFDSMISC.42', filterType: 'containsKey' },
                                { key: 'IFDSMISC.43', filterType: 'containsKey' },
                            ]
                        },
                    },
                    fields: [
                        {
                            label: 'T/S/J', name: 'IFDSMISC.0', type: 'select', lookup: 'PAYER', placeholder: 'Select', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSMISC.0', 'IFDS109G.0'],
                                fn: 'unemploymentCompTSJPull'
                            }
                        },
                        {
                            label: 'Description', name: 'IFDSMISC.5', type: 'freeText', placeholder: 'Enter Description', prior: true, logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSMISC'] }],
                            pullCalculation: {
                                keyIdentifiers: ['IFDSMISC.5', 'IFDS109G.5'],
                                fn: 'unemploymentCompDescPull'
                            }
                        },
                        {
                            label: '$ 0', name: 'IFDSMISC.9', type: 'label', isTotal: true, prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSMISC.4', 'IFDSMISC.6', 'IFDSMISC.9', 'IFDSMISC.15', 'IFDS109G.4'],
                                fn: 'sumAllPull',
                            }
                        },
                        { label: 'Amount', name: 'C-IFDSMISC', type: 'money', isTotal: true, placeholder: '0', }, // required -  If Nature/Source if present                            
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', col: 2, colTablet: 2 },
                            { label: 'Description', col: 5, colTablet: 2 },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 1, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: 'Financial Documents', col: 1, colTablet: 1, align: 'left' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Income/Unemployment Compensation (Form 1099-MISC, 1099-NEC, 1099-G)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 4, },
                            { type: 'totalMoney', name: 'IFDSMISC.9', col: 2, colTablet: 1, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSMISC', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_INCOME_UNEMPLOYMENT_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Gambling Winnings (Form W-2G)',
            shortTitle: 'Gambling Winnings',
            isVisible: true,
            collapseClass: 'collapsibleForm',
            sectionId: 3,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { label: 'T/S', name: 'IFDSOIGW.2', type: 'select', lookup: 'PAYER_TS', placeholder: 'Select', prior: true },
                        { label: 'Payer', name: 'IFDSOIGW.0', type: 'freeText', placeholder: 'Enter Name', prior: true, logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSOIGW.51'] }] },
                        { label: '$ 0', name: 'IFDSOIGW.51', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', name: 'C-IFDSOIGW.51', type: 'money', isTotal: true, placeholder: '0', },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', col: 2, colTablet: 2, },
                            { label: 'Payer', col: 5, colTablet: 2, },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 1, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: 'W-2G', col: 1, colTablet: 1, align: 'left' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Gambling Winnings (Form W-2G)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 4, },
                            { type: 'totalMoney', name: 'IFDSOIGW.51', col: 2, colTablet: 1, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSOIGW.51', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_INCOME_GAMBLING_WINNINGS_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Gambling Losses',
            isVisible: true,
            sectionId: 4,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { label: 'T/S/J', name: 'IFDSIDMI.56', type: 'select', lookup: 'PAYER', placeholder: 'Select', prior: true },
                        { label: '', name: '', type: 'label' },
                        { label: '$ 0', name: 'IFDSIDMI.59', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', name: 'C-IFDSIDMI.59', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', col: 2, colTablet: 2, },
                            { col: 6, colTablet: 2, },
                            { label: `${year.prior} Amount`, col: 1, colTablet: 1, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Gambling Losses', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 4, },
                            { type: 'totalMoney', name: 'IFDSIDMI.59', col: 2, colTablet: 1, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSIDMI.59', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_INCOME_GAMBLING_LOSSES_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Alimony Received',
            isVisible: true,
            sectionId: 5,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { label: 'T/S', name: 'IFDSOIAR.0', type: 'select', lookup: 'PAYER_TS', placeholder: 'Select', prior: true },
                        { label: 'Date', name: 'IFDSOIAR.17', type: 'date', prior: true, axcessTranslate: 'date', labelFormat: 'date' },
                        { label: '', name: '', type: 'label' },
                        { label: '$ 0', name: 'IFDSOIAR.7', type: 'label', isTotal: true, prior: true },
                        {
                            label: 'Amount', name: 'C-IFDSOIAR.7', type: 'money', isTotal: true, placeholder: '0',
                            logicFunction: [
                                { trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSOIAR.17'] }
                            ]
                        },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Select', smallScreenLabel: 'Alimony Received Details', col: 2, colTablet: 2, },
                            { label: 'Date of Divorce or Separation Agreement', col: 2, colTablet: 2, },
                            { col: 4, colTablet: 0, },
                            { label: `${year.prior} Amount`, col: 1, colTablet: 1, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Alimony Received', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 4, },
                            { type: 'totalMoney', name: 'IFDSOIAR.7', col: 2, colTablet: 1, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSOIAR.7', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_INCOME_ALIMONY_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        }
    ];

    return sections;
};

export default miscIncomeFn;