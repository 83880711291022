import {
	CHARITABLE_CASH_CONT_UPLOAD,
	CHARITABLE_NONCASH_CONT_UPLOAD,
	CHARITABLE_STOCK_CONT_UPLOAD,
	CHARITABLE_VOLUNTEER_MILES_UPLOAD
} from '@utilities/constants/strings';
const charitableFn = (year) => {

	const sections = [
		{
			title: 'Cash Contributions',
			collapseClass: 'collapsibleForm',
			isVisible: true,
			sectionId: 1,
			groups: [
				{
					groupId: 1,
					groupType: 'details',
					fields: [
						{ label: 'You are required to retain a receipt/written communication from the charity that includes the name of the charity, and the date and amount of the contribution, if over $250. If your donation was less than $250, a copy of your bank record (canceled check/bank statement/credit card statement) is sufficient documentation.', type: 'label' },
					]
				},
				{
					groupId: 2,
					groupType: 'details',
					fields: [
						{ label: 'Donations made to political organizations, political candidates, or specific individuals are not tax deductible.', type: 'label' },
					]
				},
				{
					groupId: 3,
					groupType: 'details',
					fields: [
						{ label: 'Please contact your CLA professional if you have any questions in determining what is deductible.', type: 'label' },
					]
				},
				{
					groupType: 'lineItem',
					uploadType: 'drawer',
					groupId: 4,
					axcessGroup: {
						pull: {
							fromSections: ['Contributions'],
							fieldPrefixes: ['IFDSIDCO', 'IFDSIDOC'],
							lineFilterType: 'omitAny',
							lineFilters: [
								{ key: 'IFDSIDOC.31', filterType: 'containsAny', filterData: ['2', '4', '6'] },
								{ key: 'IFDSIDCO.20', filterType: 'containsKey' },
							],
						},
					},
					fields: [
						//TODO Who Donated Axcess Field ID: IFDSIDCO.1
						{
							label: 'Organization Name', name: 'IFDSIDOC.30', type: 'freeText', placeholder: 'Other Contributions', prior: true,
							pullCalculation: {
								keyIdentifiers: ['IFDSIDOC.30'],
								fn: 'anyValidPull',
								defaultIfEmpty: 'Other Charitable Organizations',
							}
						},
						{ label: '', name: '', type: 'label' },
						{
							label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSIDOC.32', type: 'label', isTotal: true, prior: true,
							pullCalculation: {
								keyIdentifiers: ['IFDSIDCO.0', 'IFDSIDCO.10', 'IFDSIDCO.45', 'IFDSIDCO.56', 'IFDSIDCO.31', 'IFDSIDCO.67', 'IFDSIDOC.32'],
								fn: 'anyValidPull',
							}
						},
						{ label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSIDOC.32', type: 'money', isTotal: true, placeholder: '0' },
						{ type: 'lineButtons' }
					],
					lineItems: [],
					entities: [],
					currentEntity: -1,
					lineItemDetails: {
						headerLabels: [
							{ label: 'Organization Name', smallScreenLabel: 'Cash Contributions Details', col: 3, colTablet: 3, colPhone: 3, align: 'left' },
							{ col: 4, colTablet: 0 },
							{ label: '2020 Amount', col: 2, colTablet: 2, colPhone: 1, align: 'right' },
							{ label: '2021 Amount', col: 2, colTablet: 2, colPhone: 3, align: 'right' },
							{ label: 'Receipts', col: 1, colTablet: 1, colPhone: 1, align: 'noPaddingLeft' }
						],
						footerLabels: [
							{ type: 'buttons', col: 12, buttonTitle: 'Additional Cash Contribution', icon: 'add' },
							{ type: 'label', label: 'Total', col: 7, colTablet: 3, },
							{ type: 'totalMoney', name: 'IFDSIDOC.32', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
							{ type: 'totalMoney', name: 'C-IFDSIDOC.32', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
						],
						lineButtons: [
							{ button: 'uploadNa', uploadSectionName: CHARITABLE_CASH_CONT_UPLOAD },
						]
					}
				}
			]
		},
		{
			title: 'Non-Cash Contributions',
			collapseClass: 'collapsibleForm',
			isVisible: true,
			sectionId: 2,
			groups: [
				{
					groupId: 1,
					groupType: 'details',
					fields: [
						{ label: 'If total non-cash contributions are greater than $500, please provide the name of each charity, dates of contributions, the description of items donated, and your original cost basis (e.g. purchase price).', type: 'label' },
					]
				},
				{
					groupType: 'lineItem',
					uploadType: 'drawer',
					groupId: 3,
					axcessGroup: {
						pull: {
							fromSections: ['Contributions of $5000 or Less Per Item or Group', 'Contributions'],
							fieldPrefixes: ['IFDS5ORL', 'IFDSIDOC'],
							lineFilterType: 'omitAny',
							lineFilters: [
								{ key: 'IFDSIDOC.31', filterType: 'containsAny', filterData: ['1', '3', '5', '7', '8', '9', '10', '11'] },
								{ key: 'IFDSIDOC.31', filterType: 'notInKeyGroup', filterData: ['IFDSIDOC'] },
								{ key: 'IFDS5ORL.3', filterType: 'containsAny', filterData: ['Capital gain - 30% limit', 'Noncash - 20% limit'] },
							],
						},
					},
					fields: [
						//TODO TSJ Axcess Field:IFDS5ORL.0
						{
							label: 'Name', wrapLabel: 'Organization Name', name: 'IFDS5ORL.6', type: 'freeText', placeholder: 'Other Contribution', maxLength: 30, prior: true,
							pullCalculation: {
								keyIdentifiers: ['IFDS5ORL.6', 'IFDSIDOC.30'],
								fn: 'anyValidPull',
							}
						},
						{ label: 'Description', wrapLabel: 'What Was Donated?', name: 'IFDS5ORL.13', type: 'freeText', placeholder: 'Enter Item', maxLength: 57 },
						{ label: 'Date', name: 'IFDS5ORL.15', type: 'date', placeholder: 'mm/dd/yyyy', axcessTranslate: 'date' },
						{ label: 'Amount', wrapLabel: 'Original Cost', name: 'IFDS5ORL.16', type: 'money', placeholder: 'Enter Cost', isTotal: true },
						{
							label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDS5ORL.18', type: 'label', isTotal: true, prior: true,
							pullCalculation: {
								keyIdentifiers: ['IFDS5ORL.18', 'IFDSIDOC.32'],
								fn: 'anyValidPull',
							}
						},
						{ label: 'Amount', wrapLabel: '2020 Contribution (Fair Market Value)', name: 'C-IFDS5ORL.18', type: 'money', isTotal: true, placeholder: '0', maxLength: 17 },
						{ type: 'lineButtons' }
					],
					lineItems: [],
					entities: [],
					currentEntity: -1,
					lineItemDetails: {
						headerLabels: [
							{ label: 'Organization Name', smallScreenLabel: 'Non-Cash Contributions Details', col: 3, colTablet: 2, align: 'left' },
							{ label: 'What Was Donated?', col: 2, colTablet: 1, align: 'left' },
							{ label: 'Date of Contribution', col: 2, colTablet: 1, align: 'left' },
							{ label: 'Original Cost', col: 1, colTablet: 1, align: 'right' },
							{ label: '2020 Amount', col: 1, colTablet: 1, align: 'right' },
							{ label: '2021 Contribution (Fair Market Value)', col: 2, colTablet: 1, align: 'right' },
							{ label: 'Receipts', col: 1, colTablet: 1, align: 'noPaddingLeft' }
						],
						footerLabels: [
							{ type: 'buttons', col: 12, buttonTitle: 'Additional Non-Cash Contribution', icon: 'add' },
							{ type: 'label', label: 'Total', col: 8, colTablet: 5, },
							{ type: 'totalMoney', name: 'IFDS5ORL.18', col: 1, colTablet: 1, year: 'prior', label: '$ 0' },
							{ type: 'totalMoney', name: 'C-IFDS5ORL.18', col: 2, colTablet: 1, year: 'current', label: '$ 0' },
						],
						lineButtons: [
							{ button: 'uploadNa', uploadSectionName: CHARITABLE_NONCASH_CONT_UPLOAD, viewSectionId: 0 },
						]
					}
				}
			]
		},
		{
			title: 'Stock Contributions',
			isVisible: true,
			sectionId: 3,
			groups: [
				{
					groupId: 1,
					groupType: 'details',
					fields: [

					]
				},
				{
					groupType: 'lineItem',
					uploadType: 'drawer',
					groupId: 3,
					fields: [
						{ label: 'Organization Name', name: 'IFDS5ORL.6', type: 'freeText', placeholder: 'Other Contributions' },
						{ label: 'Name', name: 'IFDS5ORL.13', type: 'freeText', placeholder: 'Stock Name' },
						{ label: '#', wrapLabel: '# of Shares', name: 'numberOfShares', type: 'number', placeholder: '#' },
						{ label: 'Date of Contribution', name: 'IFDS5ORL.15', type: 'date', placeholder: 'mm/dd/yyyy', axcessTranslate: 'date' },
						{ label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDS5ORL.18', type: 'money', isTotal: true, placeholder: '0' },
						{ type: 'lineButtons' }
					],
					lineItems: [],
					entities: [],
					currentEntity: -1,
					lineItemDetails: {
						headerLabels: [
							{ label: 'Organization Name', smallScreenLabel: 'Stock Contributions Details', col: 3, colTablet: 2, align: 'left' },
							{ label: 'Stock Name', col: 2, colTablet: 1, align: 'left' },
							{ label: 'Number of Shares', col: 2, colTablet: 1, align: 'left' },
							{ label: 'Date of Contribution', col: 2, colTablet: 2, align: 'left' },
							{ label: '2021 Contribution (Fair Market Value)', col: 2, colTablet: 1, align: 'right' },
							{ label: 'Receipts', col: 1, colTablet: 1, align: 'noPaddingLeft' }
						],
						footerLabels: [
							{ type: 'buttons', col: 12, buttonTitle: 'Additional Stock Contribution', icon: 'add' },
							{ type: 'label', label: 'Total', col: 9, colTablet: 5, },
							{ type: 'totalMoney', name: 'C-IFDS5ORL.18', col: 2, colTablet: 2, year: 'current', label: '$ 0' },

						],
						lineButtons: [
							{ button: 'uploadNa', uploadSectionName: CHARITABLE_STOCK_CONT_UPLOAD, viewSectionId: 0 },
						]
					}
				}
			]
		},
		{
			title: 'Charitable / Volunteer Miles',
			isVisible: true,
			sectionId: 4,
			groups: [
				{
					groupId: 1,
					groupType: 'details',
					fields: [

					]
				},
				{
					groupType: 'lineItem',
					uploadType: 'drawer',
					groupId: 3,
					fields: [
						{ label: 'Number of Miles', name: 'charitableVolunteerMiles', type: 'label', placeholder: 'Number of Miles', prior: true },
						{ label: '', name: '', type: 'label' },
						{ label: ' 0', wrapLabel: '2019 Miles', name: 'IFDSIDCO.20', type: 'label', isTotal: true, isMoney: false, prior: true },
						{ label: 'Miles', wrapLabel: '2020 Miles', name: 'C-IFDSIDCO.20', type: 'total', isTotal: true, isMoney: false, placeholder: '0' },
						{ type: 'lineButtons' }
					],
					lineItems: [],
					entities: [],
					currentEntity: -1,
					lineItemDetails: {
						headerLabels: [
							{ label: '', smallScreenLabel: 'Charitable Miles Details', col: 2, colTablet: 3, align: 'right' },
							{ col: 5, colTablet: 0 },
							{ label: '2020 Miles', col: 2, colTablet: 2, align: 'right' },
							{ label: '2021 Miles', col: 2, colTablet: 2, align: 'right' },
							{ label: 'Receipts', col: 1, colTablet: 1, align: 'noPaddingLeft' }
						],
						footerLabels: [
							// { type: 'label', label: 'Total', col: 7, colTablet: 3, },
							// { type: 'total', name: 'IFDSIDCO.20', col: 2, colTablet: 2, year: 'prior', label: ' 0' },
							// { type: 'total', name: 'C-IFDSIDCO.20', col: 2, colTablet: 2, year: 'current', label: ' 0' },
						],
						lineButtons: [
							{ button: 'uploadNa', uploadSectionName: CHARITABLE_VOLUNTEER_MILES_UPLOAD, viewSectionId: 0 },
						]
					}
				}
			]
		},
	];

	return sections;
};

export default charitableFn;