import { GridCell } from 'react-md';
import { handleFormColumns } from '../../../utils/handleFormColumns.js';

const LineItemHeader = ({ item, width, index }) => {
    if (width < 1025 && item.colTablet === 0) return <></>;

    const headerCol = handleFormColumns(item, width, item.col);
    const headerLabel = item?.label ?? '';

    return (
        headerCol != 0 &&
        <GridCell
            key={`lbl-${index}-d`}
            colSpan={headerCol}
            className={
                item.align == 'right' ? 'headerLabelsRight' :
                    item.align == 'left' ? 'headerLabelsLeft' :
                        item.align == 'noPaddingLeft' ? 'headerLabelsButtonLeft' :
                            'headerLabelsLeft'
            }
            style={item.styles}
        >
            {headerLabel}
        </GridCell>
    );
};

export default LineItemHeader;