export const RefundsPaymentsGroupIds = Object.freeze({
    SelectAllApply: 2,
    BankInformation: 11,
    BankInformationVerification: 9,
    BankInformationConfirmation: 10
});

export const BankAccountInfoFieldDetails = Object.freeze([
    { label: 'Bank Name', name: 'IFDSDIRD.3' },
    { label: 'Routing No', name: 'IFDSDIRD.4' },
    { label: 'Account No', name: 'IFDSDIRD.5' },
    { label: 'Account Type', name: 'IFDSDIRD.13' }
]);

export const ifdsdird2FieldName = 'IFDSDIRD.2';
export const taxPreparerOptionFieldName = 'taxPreparerOption';