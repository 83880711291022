import { taxReturnStatuses } from '@utilities/constants/taxReturn';

const convertTaxReturnStatusText = (taxReturnStatus) => {
  if (!taxReturnStatus || taxReturnStatus === "undefined") return '';


  return taxReturnStatus;
};

const TaxReturnStatusText = (props) => {
  const { taxReturnStatusValue } = props;
  const taxReturnStatusText = convertTaxReturnStatusText(taxReturnStatusValue);
  let taxReturnStatusTextClassName = 'taxReturnStatusNotSent';

  switch (taxReturnStatusValue) {
    case taxReturnStatuses.sent:
    case taxReturnStatuses.partial:
      taxReturnStatusTextClassName = 'taxReturnStatusSent';
      break;

    case taxReturnStatuses.signed:
      taxReturnStatusTextClassName = 'taxReturnStatusSigned';
      break;

    case taxReturnStatuses.declined:
      taxReturnStatusTextClassName = 'taxReturnStatusDeclined';
      break;
  }

  return (
    <div key="organizer-list-row-cell-eSignature">
      <div className={taxReturnStatusTextClassName}>{taxReturnStatusText}</div>
    </div>
  );
}

export default TaxReturnStatusText;