import { combineReducers } from 'redux-immutable';
import { globalReducer} from './global';

export default function createReducer(asyncReducers) {
    return combineReducers({
        global: globalReducer,
        ...asyncReducers,
    });
}

