import * as CARDSTATUS from '@utilities/constants/cardStatus';
import { getClientDetails } from '../organizerExport/organizerRenderer/components/utils/getClientDetails';
import usePageFramework from '@utilities/hooks/usePageFramework';
import NotesIndex from './organizerRenderer/components/notes';
import { getCardProp } from '@utilities/helpers/getCardProp';

function ExportIndex(props) {
    const { REDUX, selectState } = usePageFramework();
    const year = selectState(REDUX.YEAR) || 2022;
    // Should all forms show or just the filled ones?
    // const filteredCards = props.index.filter(card => card.statusId !== CARDSTATUS.NOT_STARTED || card.isRequired);
    const setFormStatus = (form) => {
        form.statusId = form.statusId === CARDSTATUS.REQUIRED ? CARDSTATUS.NOT_STARTED : form.statusId;
        const cardRequiredText = form.isRequired ? 'Required' : 'Optional';
        return `${cardRequiredText} - ${CARDSTATUS.STATUS_TITLE[form.statusId]}`;
    };

    const clientName = getClientDetails().clientName;

    return (
        <>
            <div className="grid-container">
                <div className="grid-child" style={{ width: 139, height: 31 }}></div>
                <div className="grid-child">
                    <h2>{`${year} Index`}</h2>
                </div>
                <div className="grid-child clientDetailsContainer">
                    <span>{getClientDetails().clientName}</span>&nbsp;
                    <span>{getClientDetails().clientNumber}</span>
                </div>
            </div>
            <div className="tableHeader">
                <h4>Form</h4>
                <h4>Status</h4>
            </div>
            <hr />
            <div className='orgTable'>
                {props.index.map((card, index) => (
                    <div key={index}>
                        <div>{getCardProp(card.formName, 'title')}</div>
                        <div>{setFormStatus(card)}</div>
                    </div>
                ))}
            </div>
             <NotesIndex forms={props.index} clientName={clientName}/>
        </>
    )
}

export default ExportIndex;