import { Grid } from 'react-md';

function MultiSelectBlue (props) {
    const { field } = props;
    
    if (!field) return (null);

    return (
        <>
            <div className="formCheckboxesHeaderContainer">
                <p className="multiSelectText1">Select all that apply:</p>
            </div>
            <Grid className="multiSelectBlue" columns={1}>
                {field}
            </Grid>
        </>
    );
}

export default MultiSelectBlue;