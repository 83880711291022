import { FixedDialog } from "@react-md/dialog";

const BondIDFixedDialog = ({ fixedTo, visible, hide, issuerName, issuedDate }) => {

    return (
        <FixedDialog
            id="bondIdFixedDialog"
            visible={visible}
            aria-labelledby="fixed-dialog-1-title"
            onRequestClose={hide}
            fixedTo={fixedTo}
            options={{ xMargin: -15, yMargin: -50 }}
        >
            <span className="issuerDetails">
                Issuer: {issuerName} <br />
                Issue date: {issuedDate} <br />
            </span>
        </FixedDialog>
    );
}

export default BondIDFixedDialog;