import React, { useState } from 'react';
import { Divider, ExpansionPanel } from 'react-md';

import usePageFramework from '@utilities/hooks/usePageFramework';
import { setTaxpayerDetails } from "@utilities/helpers/setTaxPayerDetails";

function ExpandedReturnDropdown({ setVisible }) {
  const { ACTION, dispatch, selectState, REDUX, history, updateUploadList, setEntityFormState, clearFormState } = usePageFramework();
  const clientOrganizers = selectState(REDUX.CLIENT_ORGANIZERS);
  const currentTaxPayer = selectState(REDUX.TAXPAYER_NAME);
  const isClient = selectState(REDUX.IS_CLIENT);
  const [expanded, setExpanded] = useState(false);
  const isEntry = false;

  return (
    <ExpansionPanel
      id="single-panel-own-props"
      expanded={expanded}
      onExpandClick={() => setExpanded(!expanded)}
      header={
        <div className="returnExpenderHeader">
          <span className='currentReturnHeader'>
            {currentTaxPayer ? 'Current Return:' : ''}
          </span>
          <span className='currentReturnValue'>
            {currentTaxPayer ? currentTaxPayer : 'Select Return'}
          </span>
        </div>}
      expanderIcon={
        <svg
          data-testid="expand-svg-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="15"
          fill="none"
          viewBox="0 0 27 17"
          style={{ marginTop: '20px' }}
        >
          <path
            stroke={"#2E334E"}
            strokeLinecap="round"
            strokeWidth="2.5"
            d="M2 2l11.502 12.938L25 2"
          ></path>
        </svg>
      }
    >
      <div className="expandedMenu" >
        {clientOrganizers?.map((organizer) => (
          <div
            key={organizer?.id}
            onClick={() => {
              setVisible(false);
              setTaxpayerDetails({
                isEntry,
                currentTaxPayer,
                organizer,
                ACTION,
                dispatch,
                history,
                updateUploadList,
                setEntityFormState,
                clearFormState,
                isClient,
              });
            }}
          >
            <div className='mobileMenuName'>{organizer?.taxpayer?.displayName}</div>
            <div className='mobileMenuDetail'>
              <span className='mobileMenuDetailLabel'>Return ID:</span>
              <span className='mobileMenuDetailValue'>{' '}{organizer?.priorYearReturnId}</span>
            </div>
            {organizer?.spouse?.displayName && (
              <div className='mobileMenuDetail'>
                <span className='mobileMenuDetailLabel'>Spouse:</span>
                <span className='mobileMenuDetailValue'>{' '}{organizer?.spouse?.displayName}</span>
              </div>
            )}
            <Divider className='menuReturnDivider' />
          </div>
        ))}
      </div>
    </ExpansionPanel>
  );
}

export default ExpandedReturnDropdown;