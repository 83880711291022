import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import * as NAV from '@utilities/constants/navigation';
import { cleanState } from '@utilities/serviceWorker';

// This page will clean cached data and remove services workers on every visit
const CacheBreak = () => {
  const [clearedCache, setClearedCache] = useState(false);

  useEffect(() => {
    if (!clearedCache) {
      cleanState().finally(() => {
        setClearedCache(true);
      });
    }
  }, [clearedCache]);

  return clearedCache
    ? (<Redirect to={NAV.DEFAULT} />)
    : (<></>);
};

export default CacheBreak;