import OrganizerViewUploadDialog from '@components/OrganizerViewUploadDialog';
import usePageFramework from '@utilities/hooks/usePageFramework';
import moment from 'moment';
import React from 'react';
import nextId from 'react-id-generator';
import { Divider, Grid, GridCell, LinearProgress } from 'react-md';
import * as IMG from '@utilities/constants/images';

const DocumentListItemMobile = ({ file, idx, array, useType, getScreenCol }) => {
	const { selectState, REDUX, onFileRemoval } = usePageFramework();
	const isProgressVisible = selectState(REDUX.UPLOAD_PROGRESS_VISIBLE);

    return (
        <div className="uploadItem">
            <Grid className="fileNameRow">
                <GridCell colSpan={10}>
                    <OrganizerViewUploadDialog
                        key={`organizer-view-upload-dialog-${file.id}`}
                        id={nextId()}
                        className="fileViewDetails"
                        blobFile={file}
                        fileName={file.name}
                    />
                </GridCell>

                {
                    useType && useType === 'readOnly'
                        ? <></>
                        : <img src={IMG.DELETE_ICON} className="deleteIcon" onClick={() => onFileRemoval(null, file)} />
                }
            </Grid>
            <Grid className="secondaryText">
                <GridCell colSpan={4}>Date Uploaded: {moment(new Date(file.createdOn)).format('MMM DD, YYYY') || 'None'}</GridCell>
            </Grid>
            <Grid>
                <GridCell colSpan={getScreenCol(12)}>
                    {isProgressVisible && idx === array.length - 1 ?
                        <LinearProgress
                            id={`upload-linear-progress-${file.id}`}
                            barStyle={{ backgroundColor: '#76d2d3' }}
                            className='uploadLinearProgress'
                            min={0}
                            max={100}
                            value={null}
                        /> : <Divider />}
                </GridCell>
            </Grid>
        </div>
)};

export default DocumentListItemMobile;