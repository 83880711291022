const checkGroup = (group) => {

    let errorCount = 0

    if (group.lineItems) {
        group.lineItems.forEach((lineItem) => {
            if (lineItem[0].notApplicable !== true || lineItem[0].notApplicable === undefined){
                lineItem.forEach((field) => {
                    if (field.isVisible !== false && field.error === true) {
                        errorCount++;
                    }
                });
            }
        });
    }
    if (group.fields) {
        group.fields.forEach((field) => {
            if (field.isVisible !== false && field.error === true) {
                errorCount++;
            }
        });
    }
    //for dense row line sections / entities
    if (group.entities && group.entities.length) {
        group.entities.forEach((entity, index) => {
            if (group.lineItems[index][0].notApplicable !== true || group.lineItems[index][0].notApplicable === undefined){
                if (entity.sections && entity.sections.length) {
                    entity.sections.forEach((section) => {
                        if (section.isVisible !== false && section.groups && section.groups.length) {
                            section.groups.forEach((group) => {
                                if (group.isVisible !== false) {
                                    errorCount += checkGroup(group);
                                }
                            });
                        }
                    })
                }
            } 
        });
    }
    return errorCount;
}

export default checkGroup;