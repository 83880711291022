import { Validators } from 'cla-formrenderer';
import * as EVENT from '@utilities/constants/triggerKeys';

const setConditionalFieldValidation = (options) => {
    // Required properties: requiredFields
    // Optional properties: multirequiredFields(bool), comparisonValue(string)
    const { group, field, index, logic } = options;
    const groupItems = group.lineItems?.length > 0 ? group.lineItems[index] : group.fields;

    groupItems.forEach((lineItem) => {
        logic?.requiredFields.forEach((requiredField)=> {
            if (lineItem.name === requiredField || lineItem.preGroupName === requiredField) {
                // to handle preset field validations
                let fieldValidations;
                if(lineItem.type === 'ssn'){
                    fieldValidations = lineItem.validations || { ...Validators.ssn() };
                }else{
                    fieldValidations = lineItem.validations || {};
                }
                const requiredValidation = { ...Validators.required() };
                const withRequiredValidation = Object.assign(fieldValidations, requiredValidation);
                if (logic?.comparisonValue) {
                    if (field?.default?.trim() === logic?.comparisonValue.trim()) {
                        lineItem.validations = withRequiredValidation;
                    } 
                    if (field?.default?.trim() !== logic?.comparisonValue.trim()) {
                        delete fieldValidations.required
                        lineItem.validations = fieldValidations;
                        lineItem.error = false;
                        lineItem.errorMessage = null;
                    }
                } else if (lineItem.preGroupName) {
                    if (field?.default?.trim() !== '$0' && field?.default?.trim() !== '0') {
                        lineItem.validations = withRequiredValidation;
                    } 
                       else {
                        delete fieldValidations.required
                        lineItem.validations = null;
                        lineItem.error = false;
                        lineItem.errorMessage = null;
                        lineItem.validations = fieldValidations;
                    }
                } else {
                    if (field?.default?.trim() !== '' || (field.type === 'money' && field?.default?.trim() !== '0')) {
                        lineItem.validations = withRequiredValidation;
                    } 
                    if (field?.default?.trim() === '' || (field.type === 'money' && field?.default?.trim() === '0' )) {
                        delete fieldValidations.required
                        lineItem.validations = null;
                        lineItem.error = false;
                        lineItem.errorMessage = null;
                        lineItem.validations = fieldValidations;
                    }
                }
            }
        });
    }); 
};

const setEntityNotApplicable = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    name.forEach(section => {
        section.isEntityNa = event === EVENT.KEY_NO;
    })
    
    setFormSections(name);
};

export {
    setConditionalFieldValidation,
    setEntityNotApplicable
};