// common modules
import React, { useEffect } from 'react';
import { List, ListItem, ListSubheader } from '@react-md/list';
import { Link } from 'react-router-dom';

// custom modules
import './AdminDashboard.css';
import * as NAV from '@utilities/constants/navigation';

function AdminDashboard(props) {
	useEffect(() => {
	}, []);

	const navItems = [
		{ to: NAV.MANAGE_CLIENTS, label: 'Clients' },
		{ to: NAV.MANAGE_GROUPS, label: 'Groups' },
		{ to: NAV.MANAGE_ORGANIZERS, label: 'Organizers' },
		{ to: NAV.MANAGE_USERS, label: 'Users' },
		{ to: NAV.MANAGE_IN_CHARGE, label: 'In-Charge' },
		{ to: NAV.MANAGE_NOTIFICATIONS, label: 'Notifications' },
		{ to: NAV.WELCOME_EMAIL_INVITES, label: 'Welcome Email Invites'}
	];

	return (
		<div className="pracDashboardSize admin-dashboard-container">
			<List className="admin-dashboard-manage-list">
				<ListSubheader>Manage</ListSubheader>
				{
					navItems.map(({ to, label }, index) => (
						<ListItem key={`admin-dashboard-manage-nav-item-${index}`} className="admin-dashboard-manage-nav-item">
							<Link to={to} className="admin-dashboard-manage-nav-link">{label}</Link>
						</ListItem>
					))
				}
			</List>
		</div>
	);
}

export default AdminDashboard;