import usePageFramework from '@utilities/hooks/usePageFramework';
import './showLastFormSave.css';
import moment from 'moment';
import NavBarFooter from '@components/NavFooter';
import NavBarFooterMobile from '@components/NavFooter/mobile';
import useAdaptive from '@utilities/hooks/useAdaptive';
import { DASHBOARD, DOCUMENT_MANAGER, DOCUSIGN_EMBEDDED_SIGNING, PRACTITIONERS_DASHBOARD } from '@utilities/constants/navigation';

const excludedPaths = [
    DASHBOARD,
    DOCUMENT_MANAGER,
    PRACTITIONERS_DASHBOARD,
    DOCUSIGN_EMBEDDED_SIGNING
];

const ShowLastFormSave = ({ className, isNavBar }) => {
    const {
        lastSaveFormTime,
        location,
    } = usePageFramework();
    let lastFormChangeTime = lastSaveFormTime;

    const { isLaptopOrDesktop } = useAdaptive();

    if (lastFormChangeTime) {
        lastFormChangeTime = moment(lastFormChangeTime).format("MM/DD/YYYY - hh:mm a");
    }

    // if user is not on the excluded paths and has last form change, show the navbar
    const isShowNavBar = excludedPaths.every(path => !location.pathname?.includes(path)) && lastFormChangeTime;

    return isShowNavBar
        ? (
            <div className={className} >
                { isNavBar && (isLaptopOrDesktop ? <NavBarFooter/> : <NavBarFooterMobile />) }
                <span className="navbarLastSaveLabel"> Data last saved on: </span>
                <span className="navbarLastSaveValue">{lastFormChangeTime || null}</span>
            </div>
        )
        : <></>;
}
export default ShowLastFormSave;