//Bulk Upload Titles and subTitles
export const WAGES_BULK_UPLOAD_TITLE = 'Please upload your summary of Wages for the year and/or complete the table below.';
export const WAGES_BULK_UPLOAD_SUBTITLE = 'Business income…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet.';
export const CHARITY_CONTRIBUTIONS_BULK_UPLOAD_TITLE = 'Please upload your summary of charitable contributions for the year and/or complete the table below.';
export const CHARITY_CONTRIBUTIONS_BULK_UPLOAD_SUBTITLE = 'Contributions made from a donor-advised fund (DAF)and/orcontributions made directly from an IRA (QCD) should not be included here as a charitable contribution.';
export const BUSINESS_INCOME_BULK_UPLOAD_TITLE = 'Please upload your summary of Business Income for the year and/or complete the table below.';
export const BUSINESS_INCOME_BULK_UPLOAD_SUBTITLE = 'Business income…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet.';
export const FARM_INCOME_BULK_UPLOAD_TITLE = 'Please upload your summary of Farm Income for the year and/or complete the table below.';
export const FARM_INCOME_BULK_UPLOAD_SUBTITLE = 'Farm income…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet.';
export const RENTAL_INCOME_BULK_UPLOAD_TITLE = 'Please upload your summary of Rental Income for the year and/or complete the table below.';
export const RENTAL_INCOME_BULK_UPLOAD_SUBTITLE = 'Rental income…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet.';
export const PASSTHROUGH_INCOME_BULK_UPLOAD_TITLE = 'Please upload your summary of Passthrough Income for the year and/or complete the table below.';
export const PASSTHROUGH_INCOME_BULK_UPLOAD_SUBTITLE = 'Passthrough income…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet.';
export const MEDICAL_EXPENSE_BULK_UPLOAD_TITLE = 'Please upload your summary of Medical Expenses for the year and / or complete the table below.';
export const MEDICAL_EXPENSE_BULK_UPLOAD_SUBTITLE = '';

//inlineUploadButton sectionName for file uploading on azure blob storage
export const FEDERAL_2020_TAX_PAYMENTS_UPLOAD = '2020FederalTaxPaymentsUpload';
export const STATE_2020_TAX_PAYMENTS_UPLOAD = '2020StateTaxPaymentsUpload';

export const WAGES_W2_UPLOAD = 'W2Upload';
export const INVESTMENT_INCOME_1099INT_UPLOAD = 'investmentIncome1099INT1099OIDUpload';
export const INVESTMENT_INCOME_NO1099INT_UPLOAD = 'investmentIncomeNo1099Upload';
export const INVESTMENT_INCOME_1099DIV_UPLOAD = 'investmentIncome1099DIVidendUpload';
export const INVESTMENT_INCOME_BROKERAGE_UPLOAD = 'investmentIncomeBrokerageStatementConsolidated1099Upload';
export const INVESTMENT_INCOME_OTHERS_UPLOAD = 'investmentIncomeOtherTypesUpload';
export const RETIREMENT_INCOME_SOCIAL_SECURITY_UPLOAD = 'retirementIncomeSocialSecurityBenefitsUpload';
export const RETIREMENT_INCOME_RETIREMENT_PLAN_UPLOAD = 'retirementIncomeRetirementPlanDistributionsUpload';
export const OTHER_INCOME_TAX_REFUNDS_UPLOAD = 'otherIncomeStateAndLocalIncomeTaxRefundsIRS1099GUpload';

export const OTHER_INCOME_UNEMPLOYMENT_UPLOAD = 'otherIncomeUnemploymentCompensationUpload';
export const OTHER_INCOME_GAMBLING_WINNINGS_UPLOAD = 'otherIncomeGamblingWinningsW2GUpload';
export const OTHER_INCOME_GAMBLING_LOSSES_UPLOAD = 'otherIncomeGamblingLossesUpload';
export const OTHER_INCOME_ALIMONY_UPLOAD = 'otherIncomeAlimonyReceivedUpload';
export const PROPERTY_TAXES_PAID_UPLOAD = 'propertTaxesPaidUpload';
export const MORTGAGE_INTEREST_FIN_INST_UPLOAD = 'mortgageInterestPaidToFinancialInstitutionsUpload';
export const MORTGAGE_INTEREST_OTHER_MORTGAGE_UPLOAD = 'mortgageInterestPaidNotToFinancialInstitutionsUpload';
export const MORTGAGE_INTEREST_INVESTMENT_INT_UPLOAD = 'mortgageInterestInvestmentInterestUpload';
export const RETIREMENT_CONT_INDIVIDUAL_ACCOUNTS_UPLOAD = 'retirementContributionsIndividualAccountsUpload';
export const RETIREMENT_CONT_SELF_EMPLOYED_UPLOAD = 'retirementContributionsSelfEmployedPlansUpload';
export const DEPENDENT_CARE_UPLOAD = 'childDependentCareCreditUpload';
export const HIGHER_EDUCATION_1098T_UPLOAD = 'higherEducationTuitionFeesAndQualifiedExpenses1098TUpload';

export const HIGHER_EDUCATION_TUITION_PLAN_CONT_UPLOAD = 'higherEducationQualifiedTuitionContribution529PlansUpload';
export const HIGHER_EDUCATION_1099Q_UPLOAD = 'higherEducationPaymentsIRS1099QUpload';
export const ENERGY_EFFICIENT_RESIDENTIAL_PROPERTY_UPLOAD = 'energyEfficientResidentialPropertyUpload';
export const ENERGY_EFFICIENT_RENEWABLE_ENERGY_UPLOAD = 'energyEfficientResidentialRenewableEnergyTaxUpload';
export const ENERGY_EFFICIENT_ALT_VEHICLE_UPLOAD = 'energyEfficientMotorVehicleUpload';
export const HOUSEHOLD_EMPLOYMENT_TAXES_UPLOAD = 'householdEmploymentTaxesUpload';
export const STATE_UNEMPLOYMENT_UPLOAD = 'stateUnemploymentOrInsuranceContributionsUpload';

export const CHARITABLE_CASH_CONT_UPLOAD = 'charitableCashContributionsReceiptsUpload';
export const CHARITABLE_NONCASH_CONT_UPLOAD = 'charitableNonCashContributionsReceiptsUpload';
export const CHARITABLE_STOCK_CONT_UPLOAD = 'charitableStockContributionsReceiptsUpload';
export const CHARITABLE_VOLUNTEER_MILES_UPLOAD = 'charitableVolunteerMilesReceiptsUpload';

export const OTHER_DEDUCTIONS_EDUCATOR_UPLOAD = 'otherDeductionsEducatorExpenseUpload';
export const OTHER_DEDUCTIONS_HSA_CONT_UPLOAD = 'otherDeductionsHSAContributions5948SAUpload';
export const OTHER_DEDUCTIONS_HSA_DIST_UPLOAD = 'otherDeductionsHSADistributions1099SAUpload';
export const OTHER_DEDUCTIONS_STUDENT_LOAN_UPLOAD = 'otherDeductionsStudentLoan1098EUpload';
export const OTHER_DEDUCTIONS_ALIMONY_PAID_UPLOAD = 'otherDeductionsAlimonyPaidUpload';
export const OTHER_DEDUCTIONS_AF_MILES_UPLOAD = 'otherDeductionsArmedForcesMovingMilesUpload';
export const OTHER_DEDUCTIONS_AF_MOVING_UPLOAD = 'otherDeductionsArmedForcesMovingExpensesUpload';
export const OTHER_DEDUCTIONS_EMPLOYEE_BUSINESS_EXPENSE_UPLOAD = 'otherDeductionsEmployeeBusinessExpenseUpload';

export const SCHEDC_EQUIPMENT_UPLOAD = 'scheduleCBusinessEquipmentandPropertyUpload';
export const SCHEDE_1099MISC_1099K_UPLOAD = 'scheduleEProperty1099Misc1099KFormsUpload';
export const SCHEDE_EQUIPMENT_UPLOAD = 'scheduleERentalEquipmentandPropertyUpload';
export const SCHEDF_EQUIPMENT_UPLOAD = 'scheduleFFarmEquipmentandPropertyUpload';

export const FOREIGN_ASSETS_PENSION_UPLOAD = 'foreignAssetsNonUsPensionUpload';
export const FOREIGN_ASSETS_ACCOUNTS_UPLOAD = 'foreignAssetsBankAccountUpload';
export const FOREIGN_ASSETS_INVESTMENTS_UPLOAD = 'foreignAssetsInvestmentUpload';
export const FOREIGN_ASSETS_LIFE_INSURANCE_UPLOAD = 'foreignAssetsLifeInsuranceUpload';
export const FOREIGN_ASSETS_ENTITY_OWNERSHIP_UPLOAD = 'foreignAssetsEntityOwnershipUpload';

export const FOREIGN_INCOME_TRAVEL_WORKDAY_UPLOAD = 'foreignIncomeTravelWorkdayUpload';
export const FOREIGN_EMPLOYMENT_UPLOAD = 'foreignIncomeEmploymentUpload';
export const FOREIGN_HOUSING_UPLOAD = 'foreignIncomeHousingUpload';
export const FOREIGN_SEPARATE_HOME_INFO_UPLOAD = 'foreignSeparateHomeInfoUpload';
export const FOREIGN_OCCUPANT_DETAILS_UPLOAD = 'foreignOccupantDetailsUpload';
export const FOREIGN_INCOME_ADD_ANOTHER_ACCOUNT = 'Additional Foreign Account';
export const FOREIGN_INCOME_TAXES_UPLOAD = 'foreignIncomeTaxesUpload';

export const NOT_AVAILABLE_LABEL = 'N/A';

export const TAX_TYPE = 'TAX_TYPE';
export const CLA_ENTERED = 'CLA Entered';
export const PRIOR_YEAR = 'Prior Year';
export const OPTIONAL = 'Optional';

export const TAX_TYPE_ADDITIONAL = '1';
export const TAX_TYPE_ESTIMATED = '5';

export const NAME_AB = 'Alberta';
export const NAME_BC = 'British Columbia';
export const NAME_MB = 'Manitoba';
export const NAME_NB = 'New Brunswick';
export const NAME_NL = 'Newfoundland and Labrador';
export const NAME_NS = 'Nova Scotia';
export const NAME_NT = 'Northwest Territories';
export const NAME_NU = 'Nunavut';
export const NAME_ON = 'Ontario';
export const NAME_PE = 'Prince Edward Island';
export const NAME_QC = 'Québec';
export const NAME_SK = 'Saskatchewan';
export const NAME_YT = 'Yukon';

export const ACCOUNT_TYPE_CHECKING = 'Checking';
export const ACCOUNT_TYPE_SAVINGS = 'Savings';

export const RETIREMENT_TYPE_SIMPLE = 'SIMPLE';
export const RETIREMENT_TYPE_SEP = 'SEP IRA';
export const RETIREMENT_TYPE_BENEFIT = 'Defined Benefit Plan';

export const ENTRY_EXP_WAGES = 'Earned Wages (W-2)';
export const ENTRY_EXP_INVESTMENT = 'Received Investment Income';
export const ENTRY_EXP_K1 = 'Received a K-1';
export const ENTRY_EXP_MISC_INCOME = 'Had Other Sources of Income';
export const ENTRY_EXP_RETIREMENT_INCOME = 'Received Retirement Income';
export const ENTRY_EXP_SCHEDC = 'Had Business Income (Schedule C)';
export const ENTRY_EXP_SCHEDE = 'Owned Rental Property (Schedule E)';
export const ENTRY_EXP_SCHEDF = 'Owned a Farm (Schedule F)';
export const ENTRY_EXP_FOREIGN_INCOME = 'Earned Foreign Income';
export const ENTRY_EXP_FOREIGN_ASSETS = 'Held Foreign Assets';

export const ENTRY_EXP_MEDICAL_EXPENSES = 'Paid Medical Expenses';
export const ENTRY_EXP_PROPERTY_TAX = 'Paid Property Tax';
export const ENTRY_EXP_MORTGAGE_INTEREST = 'Paid Mortgage or Other Interest';
export const ENTRY_EXP_RETIREMENT_CONTRIBUTION = 'Contributed to a Retirement Account';
export const ENTRY_EXP_CHARITABLE = 'Supported Charitable Organizations';
export const ENTRY_EXP_CHILD_CARE = 'Paid for Child/Dependent Care';
export const ENTRY_EXP_HIGHER_EDUCATION = 'Paid Higher Education';
export const ENTRY_EXP_ENERGY_EFFICIENT = 'Made an Energy Efficient Purchase';
export const ENTRY_EXP_HOUSEHOLD = 'Paid Household Employees';
export const ENTRY_EXP_OTHER_DEDUCTIONS = 'Had Other Deductions';    

export const ENTRY_EXP_INVESTMENT_PLANNING = 'Investment Planning';
export const ENTRY_EXP_FINANCIAL_PLANNING = 'Tax and Financial Planning';
export const ENTRY_EXP_INSURANCE_PLANNING = 'Insurance Planning';
export const ENTRY_EXP_OWNER_TRANSITION = 'Owner Transition Services';    

export const DROP_DOWN_DEFAULT_VALUE = ' ';
export const DROP_DOWN_DEFAULT_NAME = ' ';

export const FOREIGN_ACC_TYPE_OTHER = 'Other';
export const FOREIGN_ACC_TYPE_BANK = 'Bank';
export const FOREIGN_ACC_TYPE_SECURITIES = 'Securities';

export const DASHBOARD_KEY = 'clientDashboard';
export const DASHBOARD_SECTION_NAME = 'dashboardBulkUploads';
export const DASHBOARD_FILE_UPLOADS = 'File Uploads';
export const DASHBOARD_UPLOAD_SUBTITLE = 'Choose a file or drag it here.';
export const QUICK_UPLOAD_TITLE = 'Quick Upload';
export const QUICK_UPLOAD_SUBTITLE = 'In a hurry, drag and drop your files here to organize later.';