const childCareFn = (year) => {
  const sections = [
    {
      title: 'Provider Expenses',
      sectionId: 7,
      sectionType: 'newProviderEntity',
      collapseClass: 'collapsibleForm',
      isVisible: true,
      entities: [],
    },
  ];

  return sections;
};

export default childCareFn;