import { useGridApiContext } from '@mui/x-data-grid-pro';

import NotesPanel from '@components/NotesPanel.js';
import { useEffect, useState } from 'react';
import api from '@utilities/claApi';

function CustomNotesPanel(props) {
    const { row, changeData } = props;
    const [notes, setNotes] = useState([])
    
    const apiRef = useGridApiContext();
    const toggle = () => apiRef.current.toggleDetailPanel(row.id);
		
    useEffect (() => {
		api.get(`/organizers/${row.orgId}/notes`).then((response) => {
            return response.data.results;
		}).then((notes) => {
            setNotes(notes)
        }).catch((err) => {
            console.error(err);
        })
    }, [row.orgId])

    return (
        <div data-testid='pracdash-columnheader' key={`notes-row-key-${row.id}`}>
            <NotesPanel changeData={changeData} organizerId={row.orgId} clientName={row.name} notes={notes} toggle={toggle}></NotesPanel>
        </div>
    );
}

export default CustomNotesPanel;