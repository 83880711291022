import { TextArea } from 'react-md';
import { useField } from '../fields/useField';
import { renderLabel } from '../fields/utils/isRequired';
import { isFieldDisabled } from '../fields/utils/isDisabled';

function TextAreaBox(props) {
    const { field, colspan, fns, isFormLocked, section, parentGroup, parentIndex, group, index } = props;
    const { state, handleBlur, handleChange } = useField(props);
    const priorYearChanged = field.priorYearValue !== state.value;
    const fieldClass = field.class || null;

    return (
        <TextArea
            className={field.priorYearValue && priorYearChanged ? `${fieldClass} cla-prior-year-data-edited` : !field.priorYearValue && state.value?.trim() !== '' ? `${fieldClass} cla-prior-year-data-edited` : fieldClass}
            areaClassName={!priorYearChanged ? 'cla-prior-year-data' : null}
            data-testid='text-area'
            id={`text-${section.title}-${field.name}-${index}`}
            name={field.name}
            key={`text-${field.name}-${index}`}
            placeholder={field.placeholder || field.label}
            onBlur={handleBlur}
            rows={field.rows || 4}
            value={state.value}
            onChange={handleChange}
            disabled={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section)}
            animate={false}
            label={renderLabel(field)}
            maxRows={field.maxRows || 4}
            maxLength={field.maxLength || 900}
        />
    );
}

export default TextAreaBox;
