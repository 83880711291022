import { STATE_2020_TAX_PAYMENTS_UPLOAD } from '@utilities/constants/strings';

const stateTaxFn = (year) => (formState) => {
    const sections = [
        {
            title: `${year.current} State Quarterly Payments Made`,
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 1,
            entitiesList: formState,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        {
                            label: `Your state estimates below only reflect vouchers delivered with your final tax return. Please list any additional amounts
                            paid. If estimates were paid to other taxing authorities, please provide details.`,
                            type: 'label',
                            isMarkup: true
                        },
                    ]
                },
                {
                    groupId: 2,
                    fields: [
                        { label: 'State', name: 'selecedStateLabel', type: 'label', col: 1, class: 'labelState' },
                        {
                            label: 'State', name: 'IFDSSEST.27', type: 'select', col: 2, lookup: 'STATES_TAX_PAYMENT_CODES', triggered: 'setHasSelectedStateTaxPayment', isEntityName: true, prior: true, priorCompareOptions: { compareAllLookup: true },
                            axcess: {
                                pull: {
                                    fields: ['G20632.14'],
                                    fn: 'anyValidPull'
                                }
                            },
                        }
                    ],
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 3,
                    isVisible: false,
                    fields: [
                        { label: 'Quarter', name: 'stateTaxesQuarter', type: 'label' },
                        { label: '$ 0', wrapLabel: 'Calculated Amount', name: 'stateTaxesCalculatedAmount', type: 'label', isTotal: true },
                        { label: 'Due Date', name: 'stateTaxesDueDate', type: 'date', axcessTranslate: 'date', isDefaultDate: true, },
                        { label: 'Amount', wrapLabel: '2020 Amount Paid', name: 'stateTaxesCyAmountPaid', type: 'money', isTotal: true },
                        { 
                            label: 'Date Paid', name: 'stateTaxesDatePaid', type: 'date', 
                            axcessTranslate: 'date', 
                            year: year.current,
                            logicFunction: [
                                { trigger: 'setConditionalFieldValidation', requiredFields: ['stateTaxesCyAmountPaid'] },
                                { trigger: 'validateStateTaxDatePaid' }
                            ]
                        },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    prePopulate: [
                        { label: 'Carryover from Prior Year', prePopulateAugments: [{ name: 'G20632.66', sameValue: true }, { name: 'stateTaxDueDate', currentModifier: { preGroupName: 'stateTaxDueDateCO' } }, { name: 'G20632.66', sameValue: true }, { name: 'stateCarryOverDate' }], lineOptions: { staticLine: true } },
                        {
                            label: '1st Quarter', prePopulateAugments: [
                                {
                                    name: 'G20632.49',
                                    // Please not that the keys are ordered and inline with the values in /src/utilities/helpers/getStateTaxSections.js. If a new key is added here, kindly updated the values in /src/utilities/helpers/getStateTaxSections.js.
                                    calculateKeys: [
                                        'G20632.14',    // 0: State name
                                        'G20632.49',    // 1: State Tax Value
                                        'IALSESOR.19',  // 2: Alabama
                                        'IALSESOR.41',  // 3: Alabama
                                        'IAZSESOR.24',  // 4: Arizona
                                        'IARSESOR.22',  // 5: Arkansas
                                        'IARSESOR.23',  // 6: Arkansas
                                        'ICASESOR.28',  // 7: California 
                                        'ICOSESOR.18',  // 8: Colorado 
                                        'ICTSESOR.24',  // 9: Connecticut 
                                        'IDESESOR.40',  // 10: Delaware 
                                        'IDESESOR.41',  // 11: Delaware 
                                        'IDCSEST.27',   // 12: District of Columbia 
                                        'IGASESOR.20',  // 13: Georgia 
                                        'IHISESOR.21',  // 14: Hawaii 
                                        'IIDSESOR.19',  // 15: Idaho 
                                        'IILSESOR.18',  // 16: Illinois 
                                        'IINSESOR.22',  // 17: Indiana 
                                        'IIASESOR.23',  // 18: Iowa 
                                        'IKSSESOR.18',  // 19: Kansas 
                                        'IKYSESOR.40',  // 20: Kentucky 
                                        'IKYSESOR.41',  // 21: Kentucky 
                                        'ILASESOR.21',  // 22: Louisiana 
                                        'IMESESOR.22',  // 23: Maine 
                                        'IMDSESOR.26',  // 24: Maryland 
                                        'IMASEST.21',   // 25: Massachusetts 
                                        'IMISESOR.18',  // 26: Michigan 
                                        'IMNSESOR.25',  // 27: Minnesota 
                                        'IMSSESOR.19',  // 28: Mississippi 
                                        'IMOSESOR.28',  // 29: Missouri 
                                        'IMTSESOR.34',  // 30: Montana 
                                        'INESESOR.22',  // 31: Nebraska 
                                        'INHSDPES.14',  // 32: New Hampshire 
                                        'INJSESOR.25',  // 33: New Jersey 
                                        'INMSEST.21',   // 34: New Mexico 
                                        'INYSESOR.29',  // 35: New York 
                                        'INCSESOR.19',  // 36: North Carolina 
                                        'INDSESOR.20',  // 37: North Dakota 
                                        'IOHSESOR.20',  // 38: Ohio 
                                        'IOKSESOR.23',  // 39: Okhlahoma 
                                        'IORSESOR.24',  // 40: Oregon 
                                        'IPASESOR.31',  // 41: Pennsylvania 
                                        'IRISESOR.15',  // 42: Rhode Island 
                                        'ISCSESOR.20',  // 43: South Carolina 
                                        'IUTSESOR.13',  // 44: Utah 
                                        'IVTSESOR.1',   // 45: Vermont 
                                        'IVASESOR.39',  // 46: Virginia 
                                        'IVASESOR.40',  // 47: Virginia 
                                        'IWVSESOR.13',  // 48: West Virginia 
                                        'IWISESOR.21'   // 49: Wisconsin 
                                    ],
                                    calculateFn: 'nonZeroStatePaymentPull',
                                    priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSSEST.3', 'C-IFDSSEST.8'] },] }
                                },
                                {
                                    name: 'G20632.6',
                                    calculateKeys: ['G20632.6'],
                                    calculateFn: 'anyValidPull',
                                    defaultDueDate: true,
                                    currentModifier: { preGroupName: 'stateTaxDueDateQ1' }
                                },
                                { name: 'IFDSSEST.3' },
                                { name: 'IFDSSEST.8' }
                            ]
                        },
                        {
                            label: '2nd Quarter', prePopulateAugments: [
                                {
                                    name: 'G20632.50',
                                    calculateKeys: ['G20632.50'],
                                    calculateFn: 'anyValidPull',
                                    priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSSEST.4', 'C-IFDSSEST.9'] },] }
                                },
                                {
                                    name: 'stateTaxDueDate',
                                    calculateKeys: ['G20632.7'],
                                    calculateFn: 'anyValidPull',
                                    defaultDueDate: true,
                                    currentModifier: { preGroupName: 'stateTaxDueDateQ2' }
                                },
                                { name: 'IFDSSEST.4' },
                                { name: 'IFDSSEST.9' }
                            ]
                        },
                        {
                            label: '3rd Quarter', prePopulateAugments: [
                                {
                                    name: 'G20632.51',
                                    calculateKeys: ['G20632.51'],
                                    calculateFn: 'anyValidPull',
                                    priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSSEST.5', 'C-IFDSSEST.10'] },] }
                                },
                                {
                                    name: 'stateTaxDueDate',
                                    calculateKeys: ['G20632.8'],
                                    calculateFn: 'anyValidPull',
                                    defaultDueDate: true,
                                    currentModifier: { preGroupName: 'stateTaxDueDateQ3' }
                                },
                                { name: 'IFDSSEST.5' },
                                { name: 'IFDSSEST.10' }
                            ]
                        },
                        {
                            label: '4th Quarter', prePopulateAugments: [
                                {
                                    name: 'G20632.52',
                                    calculateKeys: ['G20632.52'],
                                    calculateFn: 'anyValidPull',
                                    priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSSEST.6', 'C-IFDSSEST.11'] },] }
                                },
                                {
                                    name: 'stateTaxDueDate',
                                    calculateKeys: ['G20632.5'],
                                    calculateFn: 'anyValidPull',
                                    defaultDueDate: true,
                                    currentModifier: { preGroupName: 'stateTaxDueDateQ4' }
                                },
                                { name: 'IFDSSEST.6' },
                                { name: 'IFDSSEST.11' }
                            ]
                        },
                    ],
                    prePopulateIndexes: [{ priorIndex: 1 }, { currentIndex: 2 }, { currentIndex: 3 }, { currentIndex: 4 }],
                    prePopulateControls: ['label', 'label', 'label', 'label'],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Quarter', smallScreenLabel: 'Payments Details', col: 3, colTablet: 1, },
                            { label: 'Calculated Amount', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Due Date', col: 2, colTablet: 2, },
                            { label: `${year.current} Amount Paid`, col: 2, colTablet: 1, align: 'right' },
                            { label: 'Date Paid', col: 2, colTablet: 2, },
                            { label: 'Payment Confirmation', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'label', label: 'Total', col: 3, colTablet: 1, },
                            { type: 'totalMoney', name: 'stateTaxesCalculatedAmount', col: 2, colTablet: 1, label: '$ 0' },
                            { type: 'totalMoney', name: 'stateTaxesCyAmountPaid', col: 4, colTablet: 3, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: STATE_2020_TAX_PAYMENTS_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                }
            ]
        },
        {
            title: `${year.current} State Tax Extension Payments Made`,
            shortTitle: 'State Tax Extension Payments',
            isVisible: false,
            sectionId: 2,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { name: 'stateExtTaxQuarter', type: 'label', class: 'labelRequired' },
                        {
                            label: 'Amount',
                            wrapLabel: `${year.current} Amount Paid`,
                            name: 'stateExtTaxCyAmountPaid',
                            type: 'money',
                            logicFunction: [
                                { trigger: 'setConditionalFieldValidation', requiredFields: ['stateExtTaxDatePaid'] },
                                { trigger: 'setFederalAmountAndDatePaidValidation' },
                            ],
                            isTotal: true,
                        },
                        {
                            label: 'Date Paid', name: 'stateExtTaxDatePaid', type: 'date',
                            logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['stateExtTaxCyAmountPaid'] }, { trigger: 'setFederalAmountAndDatePaidValidation' }],
                            axcessTranslate: 'date', class: 'labelPriorData',
                        },
                    ],
                    lineItems: [],
                    prePopulate: [
                        {
                            label: 'Extension Payments', prePopulateAugments: [
                                { name: 'stateExtTaxCyAmountPaidQ1' },
                                { name: 'stateExtTaxDatePaidQ1' }
                            ]
                        },
                    ],
                    prePopulateIndexes: [{ currentIndex: 1 }, { currentIndex: 2 }],
                    prePopulateControls: ['label', 'money', 'date'],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Quarter', smallScreenLabel: 'Payments Details', col: 7, colTablet: 1, },
                            { label: `${year.current} Amount Paid`, col: 2, align: 'right', colTablet: 1, },
                            { label: 'Date Paid', col: 2, colTablet: 2, },
                        ],
                        footerLabels: [
                            { type: 'totalMoney', name: 'stateExtTaxCyAmountPaid', col: 9, colTablet: 9, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'none' },
                        ]
                    }
                }
            ],
        },
    ];

    return sections;
};

export default stateTaxFn;