// common modules
import { Button } from 'react-md';
import { useState, useEffect } from "react";
import {
	Dialog,
	DialogHeader,
	DialogTitle,
	DialogContent,
	DialogFooter,
} from "@react-md/dialog";
import {
	Form,
	Checkbox,
	useChecked,
} from "@react-md/form";
import api from '@utilities/claApi';

// custom modules
import GroupSelectField from '@components/adminDashboard/groupSelectField/index.js';

function EditOrganizerDialog(props) {
	const { visible, targetOrganizer, onCancel, onConfirm } = props;
	// eslint-disable-next-line
	const [lockedState, lockedEvt, setLockedState] = useChecked(false);
	// eslint-disable-next-line
	const [isLoadingGroups, loadingGroupsEvt, setIsLoadingGroups] = useChecked(true);
	// eslint-disable-next-line
	const [hasErrorGroups, errorGroupsEvt, setHasErrorGroups] = useChecked(false);
	const [assignedGroups, setAssignedGroups] = useState([]);
	const [hasChangedLocked, setHasChangedLocked] = useState(false);
	const [hasChangedGroups, setHasChangedGroups] = useState(false);

	const submitButtonDisabled = (!hasChangedLocked && !hasChangedGroups) || isLoadingGroups || hasErrorGroups;

	useEffect(() => {
		if (!visible) {
			return;
		}
		
		setLockedState(targetOrganizer?.locked ?? false);
		setHasChangedLocked(false);
		// eslint-disable-next-line
	}, [targetOrganizer, visible]);

	useEffect(() => {
		if (!visible) {
			return;
		}

		setIsLoadingGroups(true);
		setHasErrorGroups(false);
		setHasChangedGroups(false);

		if (!targetOrganizer?.id) {
			setHasErrorGroups(true);
			return;
		};

		api.get(`organizers/${targetOrganizer.id}/groups`).then((response) => {
			return response.data;
		}).then((results) => {
			const assignedGroups = results.groups.map((x) => x.id);
			setAssignedGroups(assignedGroups);
		}).catch((err) => {
			console.error('An error while grabbing assigned groups has occurred');
			console.error(err);
			setHasErrorGroups(true);
		}).finally(() => {
			setIsLoadingGroups(false);
		});
		// eslint-disable-next-line
	}, [targetOrganizer, visible]);

	const handleLockedStateChange = (event) => {
		const value = event.target.checked;

		setLockedState(value);
		setHasChangedLocked(true);
	};

	const handleSetGroups = (groups) => {
		setAssignedGroups(groups);
		setHasChangedGroups(true);
	};

	return (
		<Dialog
			id="edit-resource-dialog"
			role="alertdialog"
			visible={visible}
			onRequestClose={() => { }}
			aria-labelledby="edit-resource-dialog-title"
			data-testid="edit-resource-dialog-title"
		>
			<DialogHeader>
				<DialogTitle>{`Edit Organizer`}</DialogTitle>
			</DialogHeader>
			<DialogContent>
				<Form>
					{ isLoadingGroups && <>Loading assigned organizer groups...</> }
					{ hasErrorGroups && <>Error loading assigned groups...</> }
					{
						!isLoadingGroups && !hasErrorGroups &&
							<GroupSelectField value={assignedGroups} setValue={handleSetGroups}/>
					}
					{
						!isLoadingGroups && !hasErrorGroups &&
							<>{`${assignedGroups.length} group(s) are selected`}</>
					}
					<Checkbox
						id={`checkbox-field-locked`}
						key={`checkbox-field-locked`}
						name={`checkbox-field-locked`}
						label={'Locked'}
						checked={lockedState}
						onChange={handleLockedStateChange}
					/>
				</Form>
			</DialogContent>
			<DialogFooter>
				<Button id="dialog-submit" onClick={() => onConfirm({ lockedState, hasChangedLocked, assignedGroups, hasChangedGroups })} disabled={submitButtonDisabled}>Save</Button>
				<Button id="dialog-close" onClick={() => onCancel()}>Close</Button>
			</DialogFooter>
		</Dialog>
	);
}

export default EditOrganizerDialog;