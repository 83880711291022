import { Validators } from 'cla-formrenderer';
import {
    OTHER_DEDUCTIONS_EDUCATOR_UPLOAD,
    OTHER_DEDUCTIONS_HSA_CONT_UPLOAD,
    OTHER_DEDUCTIONS_HSA_DIST_UPLOAD,
    OTHER_DEDUCTIONS_STUDENT_LOAN_UPLOAD,
    OTHER_DEDUCTIONS_ALIMONY_PAID_UPLOAD,
    OTHER_DEDUCTIONS_AF_MOVING_UPLOAD,
    OTHER_DEDUCTIONS_EMPLOYEE_BUSINESS_EXPENSE_UPLOAD,
    OTHER_DEDUCTIONS_AF_MILES_UPLOAD,
} from '@utilities/constants/strings';

const otherDeductionsFn = (year) => {
    const sections = [
        {
            title: 'Adjustments to Income',
            isVisible: true,
            sectionId: 1,
            groups: [],
            subSections: [
                { subSectionId: 2 },
                { subSectionId: 3 },
                { subSectionId: 4 }
            ]
        },
        {
            title: 'Educator Expenses',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 2,
            isSubSection: true,
            isSubSectionExpanded: true,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'If you were an eligible K-12 teacher, instructor, counselor, principal, or aide, you may deduct up to $250 ($500 if filing jointly and both spouses were eligible educators) for qualified expenses used in the classroom.', type: 'label', class: 'subSeCDetails' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer/Spouse', name: 'IFDSODEE.3', type: 'select', lookup: 'PAYER_TS', prior: true },
                        { label: ' ', name: '', type: 'label' },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSODEE.0', type: 'label', isTotal: true, prior: true },
                        {
                            label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSODEE.0', type: 'money', isTotal: true, placeholder: '0', validations: {
                                ...Validators.maxValue(250),
                            }
                        },
                        { type: 'lineButtons' }

                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Expense Details', col: 2, colTablet: 3 },
                            { col: 5, colTablet: 0, },
                            { label: '2020 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Educator Expense', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDSODEE.0', col: 2, colTablet: 2, year: 'prior', label: '$ 0', isTotal: true, },
                            { type: 'totalMoney', name: 'C-IFDSODEE.0', col: 2, colTablet: 2, year: 'current', label: '$ 0', isTotal: true, isSubSectionTotal: true },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_EDUCATOR_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                }
            ]
        },
        {
            title: 'Health Savings Account - Contributions',
            isVisible: true,
            sectionId: 3,
            isSubSection: true,
            isSubSectionExpanded: false,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    hasSubSectionTotalDisplay: true,
                    logicFunction: [{ trigger: 'setHSASpouseRow' }],
                    axcessGroup: {
                        pull: {
                            fromSections: ['General'],
                            fieldPrefixes: ['IFDS889G', 'IFDS889C'],
                            lineFilterType: 'any',
                            lineFilters: [
                                { key: 'IFDS889G.4', filterType: 'containsAny', filterData: ['T', 'S'] },
                            ],
                            postPopulateFn: 'hsaTablePopulate',
                            postPopulateMetadata: {
                                sumOnColumns: ['IFDS889C.4'],
                            },
                        },
                    },
                    fields: [
                        { label: 'Select', wrapLabel: 'Taxpayer / Spouse', name: 'IFDS889G.4', type: 'select', lookup: 'PAYER', prior: true },
                        { label: 'Contributions made during year (excluding any amounts contributed via payroll through your employer)', type: 'label' },
                        { label: '$ 0', name: 'IFDS889C.4', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', name: 'C-IFDS889C.4', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' },

                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Distributions Details', col: 2, colTablet: 1 },
                            { label: 'Description', col: 5, colTablet: 1 },
                            { label: '2020 Amount', col: 2, colTablet: 1, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft', },

                        ],
                        footerLabels: [
                            { type: 'label', label: 'Total', col: 7, colTablet: 4 },
                            { type: 'totalMoney', name: 'IFDS889C.4', col: 2, colTablet: 1, year: 'prior', label: '$ 0', isTotal: true },
                            { type: 'totalMoney', name: 'C-IFDS889C.4', col: 2, colTablet: 2, year: 'current', label: '$ 0', isTotal: true, isSubSectionTotal: true },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_HSA_CONT_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                },
            ]
        },
        {
            title: 'Health Savings Account - Distributions',
            isVisible: true,
            sectionId: 4,
            isSubSection: true,
            isSubSectionExpanded: false,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    hasSubSectionTotalDisplay: true,
                    logicFunction: [{ trigger: 'setHSASpouseRow' }],
                    axcessGroup: {
                        pull: {
                            fromSections: ['General', 'Distributions From an HSA or MSA (IRS 1099-SA)'],
                            fieldPrefixes: ['IFDS889G', 'IFDS889D', 'IFDSDIST'],
                            lineFilterType: 'any',
                            lineFilters: [
                                { key: 'IFDS889G.4', filterType: 'containsAny', filterData: ['T', 'S'] },
                                { key: 'IFDSDIST.3', filterType: 'containsAny', filterData: ['T', 'S'] },
                            ],
                            postPopulateFn: 'hsaTablePopulate',
                            postPopulateMetadata: {
                                sumOnColumns: ['IFDS889D.0'],
                            },
                        },
                    },
                    fields: [
                        {
                            label: 'Select', wrapLabel: 'Taxpayer / Spouse', name: 'IFDS889G.4', type: 'select', lookup: 'PAYER', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDS889G.4', 'IFDSDIST.3'],
                                fn: 'anyValidPull',
                            }
                        },
                        { label: 'Y/N', name: 'isAllHsaDistForUnreimBursedMedicalExpenses', type: 'select', lookup: 'YESNO', col: 2, triggered: 'setIsAllHsaDistForUnreimBursedMedicalExpenses' },
                        { label: 'Amount', name: 'amountNotUsedForUnreimbursedMedicalExpenses', type: 'money', isTotal: true, col: 2, isDisabled: undefined }, // isVisible: false
                        {
                            label: '$ 0', name: 'IFDS889D.0', type: 'label', isTotal: true, prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDS889D.0', 'IFDSDIST.39'],
                                fn: 'anyValidPull',
                            }
                        },
                        { label: 'Amount', name: 'C-IFDS889D.0', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' },

                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Distributions Details', col: 2, colTablet: 1 },
                            { label: 'Were all distributions for unreimbursed medical expenses?', col: 3, colTablet: 2, align: 'left' },
                            { label: 'Amount not used for unreimbursed medical expenses?', col: 2, colTablet: 1, align: 'right' },
                            { label: '2020 Amount', col: 2, colTablet: 1, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'label', label: 'Total', col: 7, colTablet: 4 },
                            { type: 'totalMoney', name: 'IFDS889D.0', col: 2, colTablet: 1, year: 'prior', label: '$ 0', isTotal: true },
                            { type: 'totalMoney', name: 'C-IFDS889D.0', col: 2, colTablet: 2, year: 'current', label: '$ 0', isTotal: true, isSubSectionTotal: true },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_HSA_DIST_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                },
            ]
        },
        {
            title: 'Student Loan Interest Deduction (Form 1098-E)',
            shortTitle: 'Student Loan Interest Deduction',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 5,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSSINT.13', type: 'select', lookup: 'PAYER_TS', prior: true, axcessTranslate: 'jointToTaxpayer' },
                        { label: 'Lender', name: 'IFDSSINT.17', type: 'freeText', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSSINT.2', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSSINT.2', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Interest Deduction Details', col: 2, colTablet: 2 },
                            { label: 'Lender', col: 5, colTablet: 2 },
                            { label: '2020 Amount', col: 2, colTablet: 1, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: '1098-E', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Student Loan Deduction', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 4 },
                            { type: 'totalMoney', name: 'IFDSSINT.2', col: 2, colTablet: 1, year: 'prior', label: '$ 0', isTotal: true },
                            { type: 'totalMoney', name: 'C-IFDSSINT.2', col: 2, colTablet: 2, year: 'current', label: '$ 0', isTotal: true },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_STUDENT_LOAN_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                }
            ]
        },
        {
            title: 'Additional Adjustments To Income',
            isVisible: true,
            sectionId: 6,
            groups: [],
            subSections: [
                { subSectionId: 7 },
                { subSectionId: 8 },
                { subSectionId: 9 }
            ]
        },
        {
            title: 'Alimony Paid',
            isVisible: true,
            sectionId: 7,
            isSubSection: true,
            isSubSectionExpanded: true,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSALPD.0', type: 'select', lookup: 'PAYER_TS', prior: true },
                        { label: 'Name', name: 'IFDSALPD.5', type: 'freeText', prior: true, logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSALPD.17', 'IFDSALPD.6'] }] },
                        {
                            label: 'SSN', wrapLabel: 'Recipient\'s SSN', name: 'IFDSALPD.6', type: 'ssn', prior: true, overRideText: 'On File', axcessTranslate: 'omitOnOverride', isMaskOnBlur: true, validations: {
                                ...Validators.ssn(),
                            },
                        },
                        { label: 'Date', name: 'IFDSALPD.17', type: 'date', placeholder: 'Select Date', prior: true, axcessTranslate: 'date', labelFormat: 'date' },
                        { label: '$ 0', name: 'IFDSALPD.7', type: 'label', isTotal: true, wrapLabel: '2019 Amount', prior: true },
                        { label: 'Amount', name: 'C-IFDSALPD.7', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Alimony Payment Details', col: 2, colTablet: 2 },
                            { label: 'Recipient\'s Name', col: 1, colTablet: 1 },
                            { label: 'Recipient\'s SSN', col: 2, colTablet: 1 },
                            { label: 'Date of Divorce or Separation Agreement', col: 2, colTablet: 1 },
                            { label: '2020 Amount', col: 2, colTablet: 1, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Alimony Payment', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 5 },
                            { type: 'totalMoney', name: 'IFDSALPD.7', col: 2, colTablet: 1, year: 'prior', label: '$ 0', isTotal: true },
                            { type: 'totalMoney', name: 'C-IFDSALPD.7', col: 2, colTablet: 1, year: 'current', label: '$ 0', isTotal: true, isSubSectionTotal: true },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_ALIMONY_PAID_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Armed Forces Moving Expenses',
            isVisible: true,
            sectionId: 8,
            isSubSection: true,
            isSubSectionExpanded: false,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { name: 'mileage', type: 'label', label: 'Miles from old home to new workplace' },
                        { label: ' ', name: '', type: 'label' },
                        // {  label: '0', name: 'armedForcesMovingMileagePy', type: 'label', isTotal: true, isMoney: false },
                        { label: 'Miles', name: 'armedForcesMovingMileageCy', type: 'total', isTotal: true, isMoney: false, placeholder: '0' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Mileage', smallScreenLabel: 'Moving Expense Details', col: 7, colTablet: 3, },
                            { col: 2, colTablet: 2 },
                            // { label: '2019 Miles', col: 2, colTablet: 2, align: 'right' },
                            { label: '2021 Miles', col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_AF_MILES_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        { label: 'Transportation Expense', name: 'transportationExpense', type: 'label', },
                        { label: 'Amount', name: 'armedForcesTranspoExpenseCyAmount', type: 'money', isTotal: true, isMoney: true, placeholder: '0' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    prePopulate: [
                        { label: 'Cost of transportation of household goods', prePopulateAugments: [{ name: 'transpoCost' }] },
                        { label: 'Cost of travel and lodging (does not include meals or auto expenses)', prePopulateAugments: [{ name: 'travelLodgingCost' }] },
                        { label: 'Automobile expenses (gasoline, oil, etc.)', prePopulateAugments: [{ name: 'autoExpense' }] },
                    ],
                    prePopulateIndexes: [{ currentIndex: 2 }],
                    prePopulateControls: ['label', 'number'],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Transportation Expense', smallScreenLabel: 'Transportation Expense Details', col: 9, colTablet: 3 },
                            //{ col: 2, colTablet: 2 },
                            { label: '2021 Amount', col: 2, colTablet: 2 },
                            { label: 'Actions', col: 1, colTablet: 1 }
                        ],
                        footerLabels: [
                            { type: 'label', label: 'Total', col: 9, colTablet: 5 },
                            { type: 'totalMoney', name: 'armedForcesTranspoExpenseCyAmount', col: 2, colTablet: 2, year: 'current', label: '$ 0', isSubSectionTotal: true },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_AF_MOVING_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                },
            ]
        },
        {
            title: 'Employee Business Expense',
            isVisible: true,
            sectionId: 9,
            isSubSection: true,
            isSubSectionExpanded: false,
            groups: [
                {
                    groupId: 1,
                    groupType: 'blueBox',
                    isSingleColumn: true,
                    fields: [
                        { label: 'Please select an occupation and enter any applicable expense(s) in the table below.', type: 'checkboxLabel', col: 12 },
                        { label: 'Performing artist', name: 'IsPerfArtist', type: 'checkbox', col: 12 },
                        { label: 'Free-basis state or local government official', name: 'IslocalgovtOfficial', type: 'checkbox', col: 12 },
                        { label: 'Handicapped employee', type: 'checkbox', name: 'IsHandicapped', col: 12 },
                        { label: 'Armed forces reservist', type: 'checkbox', name: 'IsArmedForces', col: 12 },
                        { label: 'None of the above (deductions applicable in some states)', type: 'checkbox', name: 'IsNoneOfTheAbove', col: 12, isOddOne: true },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 3,
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        { label: 'Description', name: 'IFDSSBEI.0', type: 'freeText', prior: true },
                        { label: ' ', name: '', type: 'label' },
                        // {  label: '$ 0', name: 'IFDSSBEI.1', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', name: 'C-IFDSSBEI.1', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Description', smallScreenLabel: 'Non-Cash Contributions Details', col: 7, colTablet: 3, },
                            { col: 2, colTablet: 2 },
                            // { label: '2019 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Employee Business Expense', icon: 'add' },
                            { type: 'label', label: 'Total', col: 9, colTablet: 5 },
                            // { type: 'totalMoney', name: 'IFDSSBEI.1', col: 2, colTablet: 2, year: 'prior', label: '$ 0', isTotal: true },
                            { type: 'totalMoney', name: 'C-IFDSSBEI.1', col: 2, colTablet: 2, year: 'current', label: '$ 0', isTotal: true, isSubSectionTotal: true },

                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_EMPLOYEE_BUSINESS_EXPENSE_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                }
            ]
        }
    ];

    return sections;
};

export default otherDeductionsFn;