/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import * as NAV from '@utilities/constants/navigation';
import { sectionFieldLogic, triggeredEvent } from './aboutYouLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import aboutYou2021 from './2021';
import aboutYou2022 from './2022';
import aboutYou2023 from './2023';
import aboutYou2024 from './2024';

function AboutYou(props) {
  const {isExportForm} = props;
  const {updateCard, REDUX, selectState} = usePageFramework(NAV.ABOUT_YOU);
  const [sectionState, setSectionState] = useState('');
  const year = getYear(selectState);

  useEffect(() => {
    if (!isExportForm) updateCard();

    const formTemplatesByYear = {
      2021: aboutYou2021(year),
      2022: aboutYou2022(year),
      2023: aboutYou2023(year),
      2024: aboutYou2024(year)
    };

    const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

    setSectionState(formSections);
    // eslint-disable-next-line
  }, []);

  const axcessIdentifiers = [
    {section: 'General', id: 'Basic Data'},
  ];

  return (
    <FormRendererHelper
      sections={sectionState}
      key="aboutYouForm"
      formName={REDUX.ABOUT_YOU}
      identifiers={axcessIdentifiers}
      triggeredEvent={triggeredEvent}
      sectionFieldLogic={sectionFieldLogic}
      isExportForm={isExportForm}
    />
  );
}

export default AboutYou;
