import checkGroup from "./checkGroup";

const checkSection = (section) => {
    
    let formErrors = 0

    if (section && section.isVisible !== false) {

        if (section.groups) {
            section.groups.forEach((group) => {
                if (group.isVisible !== false) {
                    formErrors += checkGroup(group, formErrors);
                }
            });
        } else {
            formErrors += checkGroup(section, formErrors);
        }
    }
    return formErrors;
}

export default checkSection;