import { setConditionalFieldValidation } from '@components/formRenderer/triggers';

const setDateErrorMsg = (options) => {
	const { field } = options;
	var dateStr = field.default.split('/');
	var maxDateStr = field.maxDate.split('-');
	if (parseInt(dateStr[2]) < 1950 || parseInt(dateStr[2]) > parseInt(maxDateStr[2])) {
		field.error = true;
		field.errorMessage = `Year must be between 01/01/1950 and 12/31/${maxDateStr[2]}`;
	}
	else {
		field.validations = null;
		field.error = false;
		field.errorMessage = null;

	}
};

const yearValidator = (options) => {
	const { field } = options;

	const defaultDate = field.default
	const minDate = field.minDate;
	const maxDate = field.maxDate

	const validateYear = (taxYear, minDate, maxDate)=>{
		
		const maxYear = maxDate.split('/')[2];
		const minYear =  minDate.split('/')[2];

		if(taxYear && taxYear !== ' '){
			if(taxYear < minYear){
				field.error = true
				field.errorMessage = `Year must be ${minYear} or later`
			}else if(taxYear > maxYear){
				field.error = true
				field.errorMessage = `Year must be ${maxYear} or earlier`
			}else{
				field.error = false
				field.errorMessage = ""
			}
		}   
	}

	validateYear(defaultDate, minDate, maxDate)

}

const sectionFieldLogic = (logicFunction, options) => {
	switch (logicFunction) {
		case 'setConditionalFieldValidation':
			setConditionalFieldValidation(options);
			break;
		case 'setDateErrorMsg':
			setDateErrorMsg(options);
			break;
		case 'yearValidator':
			yearValidator(options);
			break;
		default:
			break;
	}
};

export {
	sectionFieldLogic,
};