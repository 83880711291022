import { SCREEN_BREAKPOINTS } from "../constants/screenSizes";
import useWindowDimensions from "./useWindowDimensions";
import { useIsMobile } from './useIsMobile';

/**
 * Custom hook to determine the adaptive state of the application based on screen width.
 *
 * @returns {Object} An object containing boolean values indicating the current screen size category.
 */
const useAdaptive = () => {
    const { width } = useWindowDimensions();
    const { isMobile } = useIsMobile();

    return {
        /**
         * Indicates if the screen width is less than the tablet breakpoint.
         * @type {boolean}
         */
        isMobile: width < SCREEN_BREAKPOINTS.TABLET,

        /**
         * Indicates if the screen width is between the tablet and laptop breakpoints.
         * @type {boolean}
         */
        isTablet: width >= SCREEN_BREAKPOINTS.TABLET && width < SCREEN_BREAKPOINTS.LAPTOP,

        /**
         * Indicates if the screen width is between the laptop and desktop breakpoints.
         * @type {boolean}
         */
        isLaptop: width >= SCREEN_BREAKPOINTS.LAPTOP && width < SCREEN_BREAKPOINTS.DESKTOP,

        /**
         * Indicates if the screen width is at least the laptop breakpoint.
         * @type {boolean}
         */
        isLaptopOrDesktop: width >= SCREEN_BREAKPOINTS.LAPTOP,

        /**
         * Indicates if the screen width is at least the desktop breakpoint.
         * @type {boolean}
         */
        isDesktop: width >= SCREEN_BREAKPOINTS.DESKTOP,

        /**
         * Indicates if the device is not a mobile device.
         * @type {boolean}
         */
        isDesktopDevice: !isMobile,
    }
};

export default useAdaptive;