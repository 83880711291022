import _ from 'lodash';
import { hasMatchingPYKey, translatePullData } from '@utilities/axcessTaxPull';
import moneyFormatter, { noDollarFormatter } from '../moneyFormatter';
import { setFieldValue, triggerFieldFn } from '@utilities/populatePriorData/populateFields';

const populateLineItem = (priorYearData, fieldTrigger) => (group, lineItemTotals) => {
	const newColumns = [];
	let hasData = false;

	Array.from(group.fields).forEach((column) => {
		const { prior, name, overRideText, axcessTranslate, isTotal, isMoney } = column;
		const columnCopy = _.cloneDeep(column);

		if (prior && name) {
			const foundPriorYearItem = priorYearData?.find(hasMatchingPYKey(name));

			// prior year value was found
			if (foundPriorYearItem) {
				const { value: axcessFieldValue } = foundPriorYearItem;

				hasData = true;

				const translatedValue = overRideText ? overRideText : translatePullData(axcessTranslate, axcessFieldValue);

				setFieldValue(columnCopy, translatedValue);

				triggerFieldFn(columnCopy, fieldTrigger);

				if (isTotal) {
					const foundLineTotalItem = !lineItemTotals.length ? null : lineItemTotals.find(x => x.totalName === name);
					const columnTotal = _.toSafeInteger(columnCopy.default);

					if (!foundLineTotalItem) {
						const lineTotalItem = { totals: columnTotal, totalName: name };
						lineItemTotals.push(lineTotalItem);
					} else {
						foundLineTotalItem.totals += columnTotal;
					}

					const numericValue = isMoney === false ? noDollarFormatter(columnTotal) : moneyFormatter(columnTotal);
					setFieldValue(columnCopy, numericValue);
				}
			}
		}

		columnCopy.hideLabel = true;
		newColumns.push(columnCopy);
	});

	return { newColumns, hasData };
};

export default populateLineItem;