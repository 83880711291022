import { Validators } from 'cla-formrenderer';
import {
    SCHEDE_EQUIPMENT_UPLOAD,
} from '@utilities/constants/strings';

const rentalIncomeFn = (year) => {
    const sections = [
        {
            title: 'Rental Property Information',
            isVisible: true,
            sectionId: 1,
            barcodeName: 'barcordeRentalPropertyInformation',
            subSections: [
                { subSectionId: 2 }
            ],
            groups: [
                {
                    groupId: 1,
                    isVisible: true,
                    class: 'defaultGroup',
                    fields: [
                        { label: 'Taxpayer/Spouse/Joint', name: 'IFDSEHDR.1', type: 'select', lookup: 'PAYER', col: 6, placeholder: 'select', prior: true, isPriorEditable: true, },
                        {
                            label: 'Property Type', name: 'IFDSEHDR.0', type: 'freeText', maxLength: 76, col: 6, placeholder: 'Property Type', prior: true, isPriorEditable: true,
                            logicFunction: [
                                { trigger: 'setIFDSEGEN1Required', isDifferentGroup: true },  //street address
                                { trigger: 'setIFDSEGEN15Required', isDifferentGroup: true }, //city
                                { trigger: 'setIFDSEGEN17Required', isDifferentGroup: true }, //zip postal
                                { trigger: 'setIFDSEGEN21Required', isDifferentGroup: true }, //$600 question                                                                                                                                                         vy{trigger: 'setIFDSEHDR1Required', isDifferentGroup: true}, //tsj owner
                                { trigger: 'setIFDSEGEN19Required', isDifferentGroup: true }, //country
                            ]
                        },
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Address', type: 'label', class: 'labelWithDivider' },
                    ]
                },
                {
                    groupId: 3,
                    groupType: 'blueDivider'
                },
                {
                    groupId: 4,
                    isVisible: true,
                    fields: [
                        {
                            label: 'Street address', name: 'IFDSEGEN.1', type: 'freeText', maxLength: 35, col: 6, isEntityName: true, entityNamePriority: 1, placeholder: 'Street Address', prior: true, isPriorEditable: true,
                            logicFunction: [
                                // {trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSEGEN.15', 'IFDSEGEN.17']}, 
                                { trigger: 'setIFDSEHDR0Required', isDifferentGroup: true }, //property type
                                // {trigger: 'setIFDSEGEN21Required', isDifferentGroup: true},
                            ]
                        },
                        { label: 'Apt #', name: 'apartmentNumber', type: 'freeText', col: 3, placeholder: '###', prior: true, isPriorEditable: true },
                        { label: 'City', name: 'IFDSEGEN.15', type: 'freeText', maxLength: 50, col: 3, placeholder: 'City', prior: true, isPriorEditable: true },
                        { label: 'Zip/Postal', name: 'IFDSEGEN.17', type: 'zippostal', col: 4, placeholder: ' Zip/Postal', prior: true, isPriorEditable: true },
                        {
                            label: 'Country', name: 'IFDSEGEN.19', type: 'select', lookup: 'FOREIGN_COUNTRIES', col: 4, triggered: 'setCountrySchedE', placeholder: 'Select Country', default: 'US', prior: true, isPriorEditable: true, axcessTranslate: 'foreignCountry',
                            axcess: {
                                pull: {
                                    fields: ['IFDSEGEN.19'],
                                    fn: 'foreignCountryPull',
                                }
                            }
                        },
                        { label: 'State', name: 'IFDSEGEN.16', type: 'select', lookup: 'STATES', col: 4, placeholder: 'Select', prior: true, isVisible: true, validations: { ...Validators.required() }, isPriorEditable: true },
                        {
                            label: 'Province', name: 'IFDSEGEN.18', maxLength: 17, type: 'select', lookup: 'PROVINCES_TRUNC', col: 4, placeholder: 'Enter Province', prior: true, priorCompareOptions: { compareAllLookup: true }, isVisible: false, validations: { ...Validators.required() }, isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSEGEN.19', 'IFDSEGEN.18'],
                                    fn: 'canadianProvincePull',
                                }
                            },
                        },
                        { // This field is intentionally named as IFDSEGEN.118 to prevent duplicate field names
                            label: 'State/Province', name: 'IFDSEGEN.118', type: 'freeText', maxLength: 17, col: 4, placeholder: 'Enter Province', prior: true, isVisible: false, validations: { ...Validators.required() }, isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSEGEN.19', 'IFDSEGEN.18'],
                                    fn: 'foreignProvincePull',
                                }
                            },
                        },
                    ]
                },
                {
                    groupId: 7,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Is this rental property still active? Answering No will inactivate this rental property.', name: 'rentalActiveLabel', type: 'label', col: 10 },
                        { label: 'Yes/No', name: 'rentalActive', type: 'select', lookup: 'YESNO_DONTKNOW', col: 2, triggered: 'setEntityNotApplicable', isActiveEntity: true }
                    ]
                },
                {
                    groupId: 5,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        //TODO: should be current date year 
                        { label: `Did you pay any non-employee individuals or business more than $600 during ${year.current}?`, name: 'payNonEmployeeLabel', type: 'label', col: 10 },
                        {
                            label: 'Yes/No', name: 'IFDSEGEN.21', type: 'select', lookup: 'YESNO', col: 2, axcessTranslate: 'validStatement', triggered: 'setFileAllFormsSchedE',
                            logicFunction: [
                                { trigger: 'setIFDSEGEN22Required', isDifferentGroup: true },
                            ]
                        }
                    ]
                },
                {
                    groupId: 6,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'If you answered \'Yes\' to the above question, did you file all required information tax forms (i.e. 1099-MISC)?', name: 'payNonEmployee2Label', type: 'label', col: 10 },
                        { label: 'Yes/No', name: 'IFDSEGEN.22', type: 'select', lookup: 'YESNO', col: 2, placeholder: 'Select', axcessTranslate: 'validStatement' },
                    ]
                },
                {
                    groupId: 8,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        {
                            label: 'Did you receive any government assistance programs such as grants, employment credits, otherwise?',
                            name: 'governmentGrantsSchedELabel',
                            type: 'label',
                            col: 10
                        },
                        {
                            label: 'Yes/No',
                            name: 'governmentGrantsSchedE',
                            type: 'select',
                            lookup: 'YESNO',
                            col: 2,
                            triggered: 'showGovernmentGrantsDetailsSchedE'
                        },
                    ]
                },
                {
                    groupId: 9,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        {
                            label: 'Please provide detail of what was received.',
                            name: 'governmentGrantsDetailsSchedELabel',
                            type: 'label',
                            col: 9,
                            class: 'labelTitleTextAreaIndented',
                        },
                        {
                            label: '',
                            name: 'governmentGrantsDetailsSchedE',
                            type: 'textarea',
                            col: 12,
                            maxRows: 3,
                            class: 'fieldTextAreaIndented',
                        },
                    ]
                },
                // {
                //     groupType: 'lineItem',
                //     groupId: 5,
                //     fields: [
                //         { label: 'Did you pay any non-employee individuals or business more than $600 during 2019?', name: 'payNonEmployeeLabel', type: 'label', placeholder: 'Select' },
                //         { label: '', name: 'payNonEmployee', type: 'radio', lookup: 'YESNO', placeholder: 'Select' },
                //     ],
                //     lineItems: [],
                //     prePopulate: [
                //         { label: 'Did you pay any non-employee individuals or business more than $600 during 2019?'},
                //         { label: 'If you answered \'Yes\' to the above question, did you file all required information tax forms (i.e. 1099-MISC)?' }
                //     ],
                //     prePopulateControls: ['label', ],
                //     lineItemDetails: {
                //         headerLabels: [
                //             { label: '', col: 10, align: 'left' },
                //             { label: '', col: 2, align: 'right' },
                //         ],
                //         footerLabels: [],
                //         lineButtons: []
                //     }
                // },
            ]
        },
        {
            title: 'Rental Property Details',
            isVisible: true,
            isSubSection: true,
            sectionId: 2,
            groups: [
                {
                    groupId: 1,
                    hasSubSectionTotalDisplay: true,
                    isVisible: true,
                    fields: [
                        { label: 'Ownership percentage if not 100%:', name: 'propertyOwnerPercentageLabel', type: 'label', col: 6, class: 'labelRequired' },
                        { label: 'Percentage', name: 'IFDSEGEN.8', type: 'percent', col: 6, placeholder: 'Enter percentage', prior: false, validations: { ...Validators.maxValue(100), } },
                        { label: 'Enter the number of days available for rent:', name: 'daysForRentLabel', type: 'label', col: 6, class: 'labelRequired' },
                        { label: '# of days', name: 'IFDSEGEN.25', type: 'number', col: 6, placeholder: 'Enter # of days', prior: true, isPriorEditable: true, maxLength: 3, validations: { ...Validators.maxValue(365), } },
                        { label: 'Enter the number of days used for personal use:', name: 'daysForPersonalUseLabel', type: 'label', col: 6, class: 'labelRequired' },
                        { label: '# of days', name: 'daysForPersonalUse', type: 'number', col: 6, placeholder: 'Enter # of days', maxLength: 3, validations: { ...Validators.maxValue(365), }, },
                        { label: 'Enter the calendar date first available for rent:', name: 'dateFirstAvailableLabel', type: 'label', col: 6, class: 'labelRequired' },
                        { label: 'Date', name: 'dateFirstAvailable', type: 'date', col: 6, axcessTranslate: 'date' },
                    ]
                },
            ]
        },
        {
            title: 'Rental Property Income',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 3,
            barcodeName: 'barcodeRentalPropertyIncome',
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Please provide any 1099-MISC/NEC forms that you received. If you received other income not reported to you on these forms, please include the payer and income below.', type: 'label' },
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'If you would like to provide additional income information by source or payer, please do so.  Otherwise, go ahead and enter your total income on one line.', type: 'label', class: 'groupDetails2' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 3,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Income', 'Miscellaneous Information (IRS 1099-MISC)'],
                            fieldPrefixes: ['IFDSEINC', 'IFDSMISC'],
                            mergeWithOtherData: [
                                { section: 'Income', id: 'Sch E, p 1 - Rent and Royalty', worksheet: 'General', key: 'IFDSEHDR.0' },
                            ],
                            lineSplitterType: 'splitRemoveExactRows',
                            lineSplitters: [
                                {
                                    splitOnFields: ['IFDSMISC.4', 'IFDSMISC.6', 'IFDSMISC.5'],
                                    splits: [
                                        { removeFields: ['IFDSMISC.6'], mustContain: ['IFDSMISC.5', 'IFDSMISC.4', 'IFDSEHDR.0', 'IFDSMISC.43'] },
                                        { removeFields: ['IFDSMISC.4'], mustContain: ['IFDSMISC.5', 'IFDSMISC.6', 'IFDSEHDR.0', 'IFDSMISC.43'] },
                                    ],
                                }
                            ],
                            lineFilterType: 'any',
                            lineFilters: [
                                { key: 'IFDSEINC.2', filterType: 'containsKey' },
                                { key: 'IFDSEINC.3', filterType: 'containsKey' },
                                {
                                    keys: ['IFDSMISC.43', 'IFDSEHDR.0'],
                                    includesFields: ['IFDSMISC.5', 'IFDSMISC.4', 'IFDSMISC.6'],
                                    filterType: 'keysWithMatchingValue',
                                },
                            ],
                        }
                    },
                    fields: [
                        {
                            label: 'Description', name: 'IFDSEINC.2', type: 'freeText', maxLength: 76, placeholder: 'Type of Form',
                            prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSEINC.2', 'IFDSMISC.5'],
                                fn: 'anyValidPull',
                            }
                        },
                        {
                            label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSEINC.3',
                            type: 'label', isTotal: true, prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSEINC.3', 'IFDSMISC.4', 'IFDSMISC.6'],
                                fn: 'sumAllPull',
                            },
                            PYList: {
                                0: 'Income',
                                1: 'Misc',
                                2: 'Misc'
                            }
                        },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSEINC.3', type: 'money', isTotal: true, placeholder: '0' },
                    ],
                    lineItems: [],
                    prePopulate: [
                        { label: 'Rental', prePopulateAugments: [{ name: 'IFDSEINC.0', currentModifiers: { logicFunction: [{ trigger: 'setRentalIncomeType' }] } }] }, // Axcess ID: IFDSEINC.0
                        { label: 'Royalty', prePopulateAugments: [{ name: 'IFDSEINC.1', currentModifiers: { logicFunction: [{ trigger: 'setRoyaltyIncomeType' }] } }] }, // Axcess ID: IFDSEINC.1
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Income Source', smallScreenLabel: 'Income Source Details', col: 8, colTablet: 4, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional 1099-MISC / 1099-K FORMS', icon: 'add' },
                            { type: 'label', label: 'Total', col: 8, colTablet: 4 },
                            { type: 'totalMoney', name: 'IFDSEINC.3', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSEINC.3', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [{ button: 'more' }]
                    }
                },
            ]
        },
        {
            title: 'Rental Property Expenses',
            isVisible: true,
            sectionId: 4,
            barcodeName: 'barcodeRentalPropertyExpenses',
            subSections: [
                { subSectionId: 5, isShowSubSecTotalOnMainSec: false },
            ],
            groups: [
                {
                    groupType: 'lineItem',
                    groupId: 3,
                    fields: [
                        { label: 'Type of Expense', name: 'IFDSEEXP.53', type: 'freeText', maxLength: 76, class: 'labelRequired', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSEEXP.54', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSEEXP.54', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    prePopulate: [
                        { label: 'Advertising ', prePopulateAugments: [{ name: 'IFDSEEXP.0' }] }, // Axcess ID: IFDSEEXP.0
                        { label: 'Auto and travel', prePopulateAugments: [{ name: 'IFDSEEXP.3' }] }, // IFDSEEXP.3
                        { label: 'Cleaning and maintenance', prePopulateAugments: [{ name: 'IFDSEEXP.6' }] }, // IFDSEEXP.6
                        // TODO Expenses.Advertising Axcess ID: IFDSEEXP.9
                        { label: 'Commissions', prePopulateAugments: [{ name: 'IFDSEEXP.9' }] },
                        { label: 'Insurance', prePopulateAugments: [{ name: 'IFDSEEXP.12' }] }, // IFDSEEXP.12
                        { label: 'Legal and other professional fees', prePopulateAugments: [{ name: 'IFDSEEXP.15' }] }, // IFDSEEXP.15
                        { label: 'Management fees', prePopulateAugments: [{ name: 'IFDSEEXP.18' }] }, // IFDSEEXP.18
                        { label: 'Mortgage interest paid to banks, etc.', prePopulateAugments: [{ name: 'IFDSEEXP.21' }] }, //IFDSEEXP.21
                        { label: 'Mortgage interest paid to individuals', prePopulateAugments: [{ name: 'IFDSEEXP.23' }] }, // IFDSEEXP.23
                        { label: 'Other interest', prePopulateAugments: [{ name: 'IFDSEEXP.25' }] }, // IFDSEEXP.25
                        { label: 'Repairs', prePopulateAugments: [{ name: 'IFDSEEXP.30' }] }, // IFDSEEXP.30
                        { label: 'Supplies', prePopulateAugments: [{ name: 'IFDSEEXP.33' }] }, // IFDSEEXP.33
                        { label: 'Taxes', prePopulateAugments: [{ name: 'IFDSEEXP.36' }] }, // IFDSEEXP.36
                        { label: 'Utilities', prePopulateAugments: [{ name: 'IFDSEEXP.38' }] }, // IFDSEEXP.38
                        { label: 'Dependent care benefits', prePopulateAugments: [{ name: 'IFDSEEXP.82' }] }, // IFDSEEXP.82
                        { label: 'Employee benefits', prePopulateAugments: [{ name: 'IFDSEEXP.84' }] }, // IFDSEEXP.84
                        // TODO Add section(s) Other expenses Description Axcess ID: IFDSEEXP.53
                        //{label: 'Other', name: 'IFDSEEXP.54'}, // IFDSEEXP.54


                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Expense Type', smallScreenLabel: 'Expenses Details', col: 7, colTablet: 3, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Add Rental Property Expense', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDSEEXP.54', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSEEXP.54', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                },
            ]
        },
        {
            title: 'Rental Equipment and Property (See Next Section for Rental Vehicles)',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            isSubSection: true,
            sectionId: 5,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'For new assets or assets that you no longer have, you can upload supporting documentation here.', type: 'label', class: 'subSeCDetails' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Depreciation and Amortization (Form 4562)'],
                            fieldPrefixes: ['IFDSDEPR'],
                            lineFilterType: 'omitAny',
                            lineFilters: [
                                { key: 'IFDSDEPR.48', filterType: 'containsAnyValue' }
                            ],
                        },
                    },
                    fields: [
                        { label: 'Description', name: 'IFDSDEPR.1', type: 'freeText', maxLength: 76, placeholder: 'Description', prior: true, logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSDEPR.18', 'IFDSDEPR.25', 'haveTheItem'] }], },
                        { label: 'Date Acquired', name: 'IFDSDEPR.18', type: 'date', prior: true, axcessTranslate: 'date', labelFormat: 'date' },
                        { label: 'Cost', name: 'IFDSDEPR.25', type: 'money', isTotal: true, hideFooter: true, prior: true, axcessTranslate: 'parseMoneyValue', },
                        {
                            label: 'Y/N', wrapLabel: 'Do you still have the asset?',
                            name: 'haveTheItem', type: 'select',
                            lookup: 'YESNO',
                            placeholder: 'Select',
                            logicFunction: [
                                { trigger: 'setConditionalFieldValidation', comparisonValue: 'No', requiredFields: ['IFDSDEPR.48', 'IFDSBPCT.24'] },
                                { trigger: 'setConditionalFieldValidation', comparisonValue: 'Yes', requiredFields: ['IFDSDEPR.1'] },
                            ]
                        }, // required - Unless no asset/z
                        { label: 'Date Sold', name: 'IFDSDEPR.48', type: 'date', axcessTranslate: 'shortDate', }, // required field - If above is anything but "Still in use" 
                        { label: 'Sale Price', name: 'IFDSBPCT.24', type: 'money', isTotal: true, hideFooter: true, }, // required field - If above is anything but "Still in use"
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Description', smallScreenLabel: 'Rental Equipment and Property Details', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Date Acquired', col: 2, colTablet: 1, align: 'left' },
                            { label: 'Cost', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Do you still have the asset?', col: 1, colTablet: 1, align: 'left' },
                            { label: 'Date Sold', col: 2, colTablet: 1, align: 'left' },
                            { label: 'Sale Price', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Invoice / Receipt', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Rental Equipment and Property', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'upload', uploadSectionName: SCHEDE_EQUIPMENT_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        // {  
        //     title: 'Vehicle Expenses',
        //     sectionId: 6,
        //     sectionType: 'vehicleEntity',
        //     isVisible: true,
        //     entities: [],
        //     colMapping: [
        //         { name: 'b', entityName: 'IFDSEDPR.12'},
        //         { name: 'c', entityName: 'IFDSEDPR.4'}, // required field - if they have autos
        //         { name: 'd', entityName: 'IFDSEDPH.0'}, // required field - if they have autos
        //         { name: 'e', entityName: 'IFDSEDOI.2'},
        //         { name: 'i', entityName: 'IFDSEDOI.0'},
        //         { name: 'j', entityName: 'IFDSEDOI.1'},
        //         { name: 'k', entityName: 'IFDSEDAE.5'},
        //         { name: 'l', entityName: 'IFDSEDAE.6'},
        //         { name: 'm', entityName: 'IFDSEDAE.7'},
        //         { name: 'q', entityName: 'IFDSELPQ.6'},
        //         { name: 'r', entityName: 'IFDSELPQ.1'}
        //     ]
        // },
        {
            title: 'Vehicle Expenses',
            sectionId: 6,
            sectionType: 'newVehicleEntity',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            entities: [],
        },
    ];

    return sections;
};

export default rentalIncomeFn;