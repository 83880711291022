import { FileInput, Form } from 'react-md';
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as IMG from '@utilities/constants/images';
import { ACCEPTED_FILE_TYPES_1040 } from '@utilities/constants/document';
function InlineUploadButton(props) {
    //states: primary, rollover, inactive, complete
    const { className, height, uploadSectionName, index, onClick, entityIndex } = props;
    const { onFileUpload, selectState, REDUX } = usePageFramework();
    const uploadList = selectState(REDUX.UPLOAD_LIST);
    const sectionName = entityIndex ? `${entityIndex}-${index}-${uploadSectionName}` : `${index}-${uploadSectionName}`;
    const onChange = (event) => onFileUpload(event, null, sectionName, uploadList, { updateState: onClick });

    return (
        <Form>
            <FileInput className={className}
                disabled
                data-testid='inline-upload-button'
                id={`lineUpload-${sectionName}`}
                onChange={onChange}
                theme='clear'
                themeType='flat'
                buttonType='text'
                multiple={true}
                accept={ACCEPTED_FILE_TYPES_1040}
                icon={<img height={height} alt="File upload button" src={IMG.uploadIconPrimary} />}>
            </FileInput>
        </Form>
    );
}

export default InlineUploadButton;