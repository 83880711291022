const medicalFn = (year) => {
    const sections = [
        {
            title: 'Medical Insurance Premiums',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: ' Do not include Medicare premiums or premiums deducted in computing taxable wages reported on a W-2.', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 2,
                    fields: [
                        { label: 'Medical Insurance Premium', name: 'medicalInsurancePremium', type: 'label', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'priorTotal', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'preGroupTotal2', type: 'money', isTotal: true, placeholder: '0' },
                    ],
                    lineItems: [],
                    prePopulate: [
                        {
                            label: 'Total health, dental, and vision insurance premiums paid',
                            prePopulateAugments: [
                                {
                                    name: 'IFDSIDMD.9',
                                    calculateKeys: ['IFDSIDMD.9', 'IFDSCGEN.16', 'IFDSFGEN.16', 'IFDS104A.11', 'IFDSPRTG.381'],
                                    calculateFn: 'sumAllPull',
                                    totalAllRows: true
                                }
                            ],
                            singleRow: true,
                        },
                        {
                            label: 'Taxpayer long-term care insurance premiums paid (deductible amounts may be limited; please enter the entire amount of premiums paid)',
                            prePopulateAugments: [{ name: 'IFDSIDMD.18', totalAllRows: true }],
                            singleRow: true,
                        },
                        {
                            label: 'Spouse long-term care insurance premiums paid (deductible amounts may be limited; please enter the entire amount of premiums paid)',
                            prePopulateAugments: [{ name: 'IFDSIDMD.25', totalAllRows: true }],
                            singleRow: true,
                        },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItemDetails: {
                        headerLabels: [
                            { smallScreenLabel: 'Insurance Premiums Details', col: 8, colTablet: 4, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                        ],
                        footerLabels: [
                            { type: 'label', label: 'Total', col: 8, colTablet: 4, },
                            { type: 'totalMoney', name: 'priorTotal', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'preGroupTotal2', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: []
                    }
                },
            ]
        },
        {
            title: 'Deductions',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 2,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Please consider the limits on deducting medical expenses before spending considerable time gathering medical receipts. If you itemize your deductions, you may be able to deduct only the qualified medical expenses that exceed 7.5% of your adjusted gross income.', type: 'label' },
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'details',
                    class: 'priorMedExpenseDetailsContainer',
                    fields: [
                        {
                            label: '',
                            name: 'IFDSIMP.60',
                            type: 'label',
                            prior: true,
                            isPriorEditable: false,
                            axcess: {
                                pull: {
                                    fields: ['IFDSIMP.60'],
                                    fn: 'medicalLabelPull',
                                }
                            }
                        },
                    ]
                },
                {
                    groupId: 3,
                    class: 'deductionsGroup',
                    fields: [
                        { label: 'Do you want to provide out-of-pocket medical expenses?', name: 'provideMedicalExpensesLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'provideMedicalExpenses', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, triggered: 'setIsProvidingMedicalExpenses' },
                    ]
                },
                {
                    groupId: 4,
                    groupType: 'details',
                    isVisible: false,
                    fields: [
                        { label: 'Medical and Dental Expenses (Out-of-Pocket)', type: 'label', class: 'labelTextSubTitle' },
                    ]
                },
                {
                    groupId: 5,
                    groupType: 'details',
                    isVisible: false,
                    fields: [
                        { label: 'Do not include Medicare premiums deducted from your Social Security payments or premiums deducted in computing taxable wages reported on a W-2.  Also do not include amounts paid by HSA, FSA, or reimbursed by your insurance company.', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 6,
                    isVisible: false,
                    isLastItem: true,
                    fields: [
                        { label: 'Number of miles traveled for medical care', name: 'milesTraveledMedicalCare', type: 'label', },
                        { label: '0', wrapLabel: '2019 Miles', name: 'IFDSIDMD.48', type: 'label', isMoney: false, isTotal: true, prior: true },
                        { label: 'Miles', wrapLabel: '2020 Miles', name: 'C-IFDSIDMD.48', type: 'total', isMoney: false, isTotal: true, placeholder: '0' },
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { col: 8, colTablet: 4, align: 'left' },
                            { label: `${year.prior} Miles`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Miles`, col: 2, colTablet: 2, align: 'right' },
                        ],
                        footerLabels: [],
                        lineButtons: []
                    }
                },
                {
                    groupType: 'lineItem',
                    groupId: 7,
                    isVisible: false,
                    fields: [
                        //TODO Adjusted Gross Income (AGI) From PY Axcess Field ID: IFDSIMP.60
                        { label: '', name: 'outOfPocketMedicalDentalExpense', type: 'label', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'priorTotal', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'preGroupTotal1', type: 'money', isTotal: true, placeholder: '0' },
                    ],
                    lineItems: [],
                    prePopulate: [
                        { label: 'Prescription medicine and drugs', prePopulateAugments: [{ name: 'IFDSIDMD.0', totalAllRows: true }], singleRow: true },
                        { label: 'Long-term care expenses', prePopulateAugments: [{ name: 'IFDSIDMD.32', totalAllRows: true }], singleRow: true },
                        { label: 'Lodging for medical care', prePopulateAugments: [{ name: 'IFDSIDMD.56', totalAllRows: true }], singleRow: true },
                        { label: 'Doctors, dentists, etc.', prePopulateAugments: [{ name: 'IFDSIDMD.64', totalAllRows: true }], singleRow: true },
                        { label: 'Hospitals', prePopulateAugments: [{ name: 'IFDSIDMD.73', totalAllRows: true }], singleRow: true },
                        { label: 'Lab fees', prePopulateAugments: [{ name: 'IFDSIDMD.82', totalAllRows: true }], singleRow: true },
                        { label: 'Eyeglasses and contacts', prePopulateAugments: [{ name: 'IFDSIDMD.90', totalAllRows: true }], singleRow: true }
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItemDetails: {
                        headerLabels: [
                            { smallScreenLabel: 'Expense Details', col: 8, colTablet: 4, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                        ],
                        footerLabels: [
                            { type: 'label', label: 'Total', col: 8, colTablet: 4, },
                            { type: 'totalMoney', name: 'priorTotal', col: 2, colTablet: 2, year: 'prior', label: '$ 0', },
                            { type: 'totalMoney', name: 'preGroupTotal1', col: 2, colTablet: 2, year: 'current', label: '$ 0', isSubSectionTotal: true },
                        ],
                        lineButtons: [
                        ]
                    }
                }
            ]
        },
        {
            title: 'Other Medical Expenses',
            isVisible: true,
            sectionId: 3,
            groups: [
                {
                    groupType: 'lineItem',
                    groupId: 1,
                    fields: [
                        //TODO TSJ Owner Axcess ID: IFDSIDMO.109
                        { name: 'IFDSIDMO.106', type: 'freeText', prior: true, label: 'Description', maxLength: 75 },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSIDMO.108', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSIDMO.108', type: 'money', isTotal: true, placeholder: '0' },
                    ],

                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Description', smallScreenLabel: 'Expense Details', col: 8, colTablet: 4, },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Medical Expenses', icon: 'add' },
                            { type: 'label', label: '', col: 8, colTablet: 4, },
                            { type: 'totalMoney', name: 'IFDSIDMO.108', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSIDMO.108', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],

                    }
                }
            ],
        },
    ];

    return sections;
}

export default medicalFn;