/* eslint-disable no-unused-vars */
import 'react-datetime/css/react-datetime.css';
import DateTime from 'react-datetime';
import moment from 'moment';
import { FormMessage, TextField, Tooltipped, HoverModeProvider, } from 'react-md';
import { useField } from '../useField';
import { getHintText } from '../utils/getHintText';
import { isFieldDisabled } from '../utils/isDisabled';
import { validateField } from '../utils/validateField';
import { adjustLabels } from '../utils/adjustLabels';
import { useEffect, useState, useRef } from 'react';
import { renderLabel } from '../utils/isRequired';
import { isError } from '../utils/isError';

function YearPicker(props) {
    const { field, index, group, isFormLocked, fns, section, parentGroup, parentIndex, grandParentGroup} = props;
    const { state, updateBlurState, handleFocus } = useField(props);
    const valued = state.value && state.value.length > 0 ? true : false;
    const hintText = getHintText(state, valued, field);
    const priorYearChanged = field.priorYearValue !== state.value;
    const [labelClassName, setLabelClassName] = useState('');
    const ref = useRef(null);
    
    useEffect(() => {
        adjustLabels(ref, section, setLabelClassName);
    }, []);
    
    const validYear = (value) => {
        const currentYear = moment();
        return value.isBefore(currentYear);
    };

    const blurHandler = (momentYear) => {
        // Validate field
        let value = typeof momentYear !== 'string' ? momentYear.format('YYYY') : momentYear;
        const errorMessage = field.validations ? validateField(value, field) : null;
        updateBlurState(state.value, errorMessage ? true : false, errorMessage);    

        if (field.logicFunction) {
            field.logicFunction.forEach((logic) => {
                logic.isDifferentGroup ? fns.triggered(logic.trigger, field.default, {}) :
                    fns.sectionFieldLogic(logic?.trigger, {group, field, index, logic, parentGroup, parentIndex, section, fns});
            });
        }

        if (field.isSharedEntityValue) fns.showSharedEntityDialog();

        fns.saveForm(field);
       
    };

    const changeHandler = (momentYear) => {
        let value = typeof momentYear !== 'string' ? momentYear.format('YYYY') 
            : momentYear.replace(/[^0-9]/g, ''); //transform non-digit/number input value
        if (value !== state.value) {
            // Update value and reset error state
            value =  value.length < 5 ? value : state.value;
            updateBlurState(value, false, '');
        }
    };

    const blurFromField = () => {
        if (state.value === '') {
            updateBlurState(' ', false, '');
        }
    }; 

    const renderInput = (props) => {
        return (
            <div key={`cell-${field.name}`}>
                <HoverModeProvider>
                    <Tooltipped id={`hover-${section.title}-${field.name}-${index}`} tooltip={state.value} position='above' key={`hover-${field.name}-${index}`} className='pracDashOnHover'>
                        <div className={labelClassName}>
                            {isError(field)}
                            <TextField 
                                {...props}
                                ref={ref}
                                className={field.priorYearValue && priorYearChanged ? 'cla-prior-year-data-edited' : !field.priorYearValue && state.value.trim() !== '' ? 'cla-prior-year-data-edited' : null}
                                inputClassName={!priorYearChanged ? 'cla-prior-year-data' : null}
                                id={`yearPicker-${section.title}-${field.name}-${index}`}
                                name={field.name}
                                key={`yearPicker-${field.name}-${index}`}
                                type='text'
                                label={renderLabel(field)}
                                error={state.error}
                                placeholder={field.placeholder}
                                onFocus={handleFocus}
                                // maxLength={4}
                                disabled={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section)}
                                required={field.required ? true : false}
                                onKeyDown={(e) =>["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                            />
                        </div>
                    </Tooltipped>
                </HoverModeProvider>
                <FormMessage 
                    id={`${field.id}-error-tooltip`}
                    error={state.error}
                >
                    {hintText}
                </FormMessage> 
            </div>
        );
    };

    const inputProps = {
        value: state.value,
        autoComplete: 'off',
        onFocus: handleFocus,
        onBlur: blurFromField,
    };

    return (
        <>
            <DateTime
                dateFormat="YYYY" 
                timeFormat={false}
                inputProps={inputProps}
                renderInput={renderInput}
                closeOnSelect={true}
                closeOnTab={false}
                isValidDate={validYear}
                onChange={changeHandler}
                onClose={blurHandler}
            />
        </>
    );
}

export default YearPicker;