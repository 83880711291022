import { useState } from 'react';
import { DropdownMenu } from 'react-md';
import { MoreVertSVGIcon } from '@react-md/material-icons';
import nextId from 'react-id-generator';
import { frameworkSetter } from '../../../hooks/usePageFramework';
import InlineUploadButton from '../buttons/uploadButtons/inlineUploadButton/index';
import InlineNotApplicableButton from '../buttons/notApplicableButton/index';
import InlineRemoveButton from '../buttons/removeButton';
import InlineClearButton from '../buttons/clearButton';
import ViewButton from '../viewButton';
import { find } from 'lodash';

function LineItemButtons(props) {
    const { group, index, fns, isFormLocked, parentGroup, parentIndex, section, formSections, lineItemSectionName, allSections } = props;
    const { selectState, REDUX, } = frameworkSetter.usePageFramework();
    const uploadList = selectState(REDUX.UPLOAD_LIST);
    const groupLineItem = group.lineItems[index] ? group.lineItems[index][0] : null;
    const [ naState, setNAState] = useState(groupLineItem?.notApplicable);
   
    // TODO: check if will error on 1040 upload button
    if (!group.lineItemDetails.lineButtons ) return (null);

    const lineButtons = group.lineItemDetails.lineButtons;
    const lineUploadSectionName = lineButtons[0]?.uploadSectionName;
    const filteredList = uploadList?.filter(x => x?.section?.toLowerCase().trim() === `${lineItemSectionName?.toLowerCase().trim()}`);
    const idGen = nextId();

    const items = [];

    const setLineDisabled = () => {
        setNAState(!naState);
        groupLineItem.notApplicable = !naState;
        group.currentEntity = -1;
        if (groupLineItem.notApplicable) {
          if (group?.groupType === "lineItem") {
            const groupValLine = group?.lineItems?.[index];
            if (groupValLine) {
              groupValLine.forEach((field) => {
                if (field?.error) {
                    field.error = false;
                    field.previousError = true;
                    field.preErrMsg = field.errorMessage;
                    delete field.errorMessage;
                  }
              });
            }
            if (group?.lineSections) {
              const groupVal = group?.entities?.[index];
              if (groupVal) {
                groupVal?.sections.forEach((val) => {
                  val?.groups?.forEach((group) => {
                    group?.fields.forEach((field) => {
                      if (field?.error && field?.required) {
                        field.previousError = true;
                        field.preErrMsg = field.errorMessage;
                        field.error = false;
                        delete field.errorMessage;
                      }
                    });
                  });
                });
              }
            }
          }
        }else if (groupLineItem.notApplicable ===  false) {
            if (group?.groupType === "lineItem") {
                const groupValLine = group?.lineItems?.[index];
                if (groupValLine) {
                  groupValLine.forEach((field) => {
                    if (field?.previousError) {
                        field.error = field.previousError;
                        field.errorMessage = field.preErrMsg;
                        delete field.previousError;
                        delete field.preErrMsg;
                      }
                  });
                }
                if (group?.lineSections) {
                  const groupVal = group?.entities?.[index];
                  if (groupVal) {
                    groupVal?.sections.forEach((val) => {
                      val?.groups?.forEach((group) => {
                        group?.fields.forEach((field) => {
                            if (field?.previousError && field?.required) {
                                field.error = field.previousError;
                                field.errorMessage = field.preErrMsg;
                                delete field.previousError;
                                delete field.preErrMsg;
                              }
                        });
                      });
                    });
                  }
                }
              }
        }
        fns.saveForm(groupLineItem);
    };

    const setLineUploaded = () => {
        groupLineItem.uploaded = true;
        groupLineItem.drawerVisible = false;
        group.currentEntity = -1;
        fns.saveForm(groupLineItem);
    };

    const removeLineItem = () => {
        const removedLineItem = group.lineItems[index]
        const removedEntity = group.entities[index]
        group.entities?.splice(index, 1);
        group.lineItems.splice(index, 1);
        // if prior year data
        if (removedEntity?.sections[0]?.groups[0]?.fields[1]?.priorYearValue) {
            removedEntity.sections[0].groups[0].fields[1].default = "PLEASE DELETE";
            // update Title to "PLEASE DELETE"
            removedLineItem[0].default = removedLineItem[0].default.replace(/Title:.*/, "Title: PLEASE DELETE");
            // move to the last
            group.lineItems.push(removedLineItem);
            group.entities.push(removedEntity);
            group.hasHiddenDeletedItem = true;
        }

        group.currentEntity = -1;

        if (formSections) {
            formSections.forEach((section, idx) => {
                if (idx > 1 && section.groups?.[1]?.groupType === 'bondsItem') {
                    section.groups[1].bonds.splice(index, 1);
                }
            })
        }
        const removeBtn = find(group?.lineItemDetails?.lineButtons, { button: 'remove' });
        if (removeBtn?.logicFunction) {
            removeBtn?.logicFunction.forEach((logic) => {
                if (logic.isDifferentGroup) {
                    fns.triggered(logic.trigger, index, group);
                } else if (fns.sectionFieldLogic) {
                    fns.sectionFieldLogic(logic.trigger, { parentIndex: index, parentGroup: group, allSections, fns });
                }
            });
        }
        fns.saveForm();
    };

    const clearLineItem = () => {
        group.currentEntity = -1;
        const clearBtn = find(group?.lineItemDetails?.lineButtons, {button: 'clear'});

        group.lineItems[index].forEach(lineItemField => {
            if (clearBtn.retainFieldName && lineItemField.name !== clearBtn.retainFieldName) {
                if (lineItemField.default) {
                    lineItemField.default = lineItemField.type === 'label' ? lineItemField.label : '';
                }
            }
        });

        if (group.entities) {
            group.entities[index].sections.forEach(section => section.groups.forEach(group => {
                group.fields.forEach(groupField => {
                    if (groupField.default) {
                        groupField.default = groupField.type === 'label' ? groupField.label : '';
                    }
                });
            }));
        }

        if (clearBtn?.logicFunction) {
            clearBtn.logicFunction.forEach((logic) => {
                logic.isDifferentGroup && fns.triggered ? fns.triggered(logic.trigger, field.default, null) :
                fns.sectionFieldLogic && fns.sectionFieldLogic(logic?.trigger, {group, index, logic, parentGroup, parentIndex, section, fns});
            });
        }

        fns.saveForm();
    };

    const buttons = lineButtons.map((button) => {
        switch (button.button) {
        case 'upload':
            return (
                <div key={`ivbgc-${index}`} className="formLineButton" data-testid='upload-kebab-line-buttons'>
                    <div className="formLineActionsBtn">
                        {filteredList?.length ?
                            <ViewButton key={`ivb-${index}`} viewSectionId={button.viewSectionId} fns={fns} group={group} index={index} buttonType='uploadList' isFormLocked={isFormLocked}/> :
                            <InlineUploadButton key={`iub-${index}`} group={group} uploadSectionName={lineUploadSectionName} className='lineUploadButton1' index={index} onClick={setLineUploaded} isFormLocked={isFormLocked} lineItemSectionName={lineItemSectionName}/>}
                    </div>
                    <div className="formLineMoreBtn">
                        {/* ellipses are set to hidden on css for now */}
                        <DropdownMenu key={`dpmenu-${index}`} id={idGen} items={items} buttonType="icon" className="moreIcon2" disabled={isFormLocked} data-testid='upload-kebab'>
                            <MoreVertSVGIcon />
                        </DropdownMenu> 
                    </div>
                </div>
            );
        case 'uploadOnly':
            return (
                <div key={`ivbgc-${index}`} className="formLineButton" data-testid='upload-line-button'>
                    <div className="formLineActionsBtn">
                        {filteredList?.length ?
                            <ViewButton key={`ivb-${index}`} viewSectionId={button.viewSectionId} fns={fns} group={group} index={index} buttonType='uploadList' isFormLocked={isFormLocked}/> :
                            <InlineUploadButton key={`iub-${index}`} group={group} uploadSectionName={lineUploadSectionName} className='lineUploadButton1' index={index} onClick={setLineUploaded} isFormLocked={isFormLocked} lineItemSectionName={lineItemSectionName}/>}
                    </div>
                </div>
            );
        case 'na':
            return (
                <div key={`ivbgc-${index}`} className="formLineActionsBtn" data-testid='na-kebab-line-buttons'>
                    <InlineNotApplicableButton key={`inab-${index}`} onClick={setLineDisabled} group={group} index={index} className='lineUploadButton1' isFormLocked={isFormLocked}/>
                    {/* ellipses are set to hidden on css for now */}
                    <DropdownMenu key={`dpmenu-${index}`} id={idGen} items={[]} buttonType="icon" className="moreIcon2" disabled={isFormLocked} data-testid='na-kebab'>
                        <MoreVertSVGIcon />
                    </DropdownMenu>
                </div>
            );
        case 'uploadNa':
            return (
                <div key={`ivbgc-${index}`} className="formLineButton" data-testid='upload-na-kebab-line-buttons'>
                    <div className="formLineActionsBtn">
                        {filteredList?.length ?
                            <ViewButton key={`ivb-${index}`} viewSectionId={button.viewSectionId} fns={fns} group={group} index={index} buttonType='uploadList' isFormLocked={isFormLocked}/> :
                            <InlineUploadButton key={`iub-${index}`} group={group} uploadSectionName={lineUploadSectionName} className='lineUploadButton1' index={index} onClick={setLineUploaded} isFormLocked={isFormLocked} lineItemSectionName={lineItemSectionName}/>}
                        <InlineNotApplicableButton key={`inab-${index}`} className="lineNaButton" onClick={setLineDisabled} group={group} index={index} isFormLocked={isFormLocked}/>
                    </div>
                    <div className="formLineMoreBtn">
                        {/* ellipses are set to hidden on css for now */}
                        <DropdownMenu id={idGen} key={`dpmenu-${index}`} items={items} buttonType="icon" className="moreIcon2" disabled={isFormLocked} data-testid='upload-na-kebab'>
                            <MoreVertSVGIcon />
                        </DropdownMenu>
                    </div>
                </div>
            ); 
        case 'more':
            return (
                <div key={`ivbgc-${index}`} className="formLineButton3" data-testid='kebab-line-button'>
                    {/* ellipses are set to hidden on css for now */}
                    <DropdownMenu id={idGen} key={`dpmenu-${index}`} items={items} buttonType="icon" className="moreIcon1" disabled={isFormLocked} data-testid='more-kebab'>
                        <MoreVertSVGIcon />
                    </DropdownMenu>
                </div>
            ); 
        case 'moreLineItemGroup':
            return (
                <div key={`ivbgc-${index}`} className="formLineButton3" data-testid='kebab-line-item-button'>
                    {/* ellipses are set to hidden on css for now */}
                    <DropdownMenu id={idGen} key={`dpmenu-${index}`} items={items} buttonType="icon" className="moreIcon1" disabled={isFormLocked} data-testid='more-line-kebab'>
                        <MoreVertSVGIcon />
                    </DropdownMenu>
                </div>
            ); 
        case 'remove':
            return (
                <div key={`ivbgc-${index}`} className="formLineActionsBtn" data-testid='remove-line-button'>
                    <InlineRemoveButton key={`irb-${index}`} onClick={removeLineItem} group={group} index={index} isFormLocked={isFormLocked}/>
                </div>
            );
        case 'clear':
            return (
                <div key={`ivbgc-${index}`} className="formLineActionsBtn" data-testid='remove-clear-button'>
                    <InlineClearButton key={`irb-${index}`} onClick={clearLineItem} group={group} index={index} isFormLocked={isFormLocked}/>
                </div>
            );
        default:
            return null;
        }
    });

    return buttons;
}

export default LineItemButtons;
