import { useState } from 'react';
import { Button } from 'react-md';
import * as IMG from '../../../../constants/images';

function InlineNotApplicableButton(props) {
    const { className, onClick, height, group, index, isFormLocked } = props;
    // const naIconSecondary = 'icons/lineNaNormal.svg';
    // const naIconCompleted = 'icons/lineNaActive.svg';

    const [isLineNA, setIsLineNA] = useState(group.lineItems[index][0].notApplicable);

    const setOnClick = () => {
        setIsLineNA(!isLineNA);
        onClick();
    };

    return (
        <Button className={className}
            data-testid='btn-inline-na'
            disabled={isFormLocked}
            onClick={setOnClick} >
            <img height={height} alt={ isLineNA  ? 'Line item not applicable button active' : 
                'Line item not applicable button inactive' } 
            src={ isLineNA  ? IMG.naIconCompleted :
                IMG.naIconSecondary } />
        </Button>
    );
}

export default InlineNotApplicableButton;