import { find, findIndex } from 'lodash';
import * as STRING from '@utilities/constants/strings';
/**
 * getDependentOptions function is used to form dependent list as option. If dependent is present
 * in the redux, it uses dependentArray otherwise it checks and take prior data if available
 * 
 * @param {*} dependentArray receiving saved dependent array from redux
 * @param {*} priorYearData receiving prior year data from redux
 * @returns array object containing dependent full name as options
 */
const getDependentOptions = (dependentArray, priorYearData) => {
  const lineItemsList = dependentArray?.[0]?.groups?.[0]?.lineItems;
  const entityList = dependentArray?.[0]?.groups?.[0]?.entities;
  const dependentOptions = [];
  if (lineItemsList?.length > 0) {
    lineItemsList.forEach((obj, index) => {
      if (obj?.[3]?.value !== STRING.NO_LONGER_DEPENDENT && entityList?.[index]) {
        const firstName = entityList[index]?.sections?.[0]?.groups?.[0]?.fields?.[0]?.value?.trim();
        const initial = entityList[index]?.sections?.[0]?.groups?.[0]?.fields?.[1]?.value?.trim() || "";
        const lastName = entityList[index]?.sections?.[0]?.groups?.[0]?.fields?.[2]?.value?.trim();
    
        // Construct the full name only if at least one name part exists
        if (firstName || initial || lastName) {
          const data = `${firstName} ${initial ? initial + " " : ""}${lastName}`.trim();
          dependentOptions.push({ value: data, name: data });
        }
      }
    });
  } else if (priorYearData?.length > 0) {
    const priorIndex = findIndex(priorYearData, {
      section: STRING.GENERAL,
      id: STRING.BASIC_DATA,
    });
    if (priorIndex > -1) {
      const section = priorYearData[priorIndex];
      if (section?.data?.length > 0) {
        const dependentSection = find(section?.data, { name: STRING.DEPENDENTS });
        if (dependentSection?.lineItems?.length > 0) {
          dependentSection.lineItems.forEach((lineItem) => {
            const fullName = lineItem
              .filter((item) => {
                return /IFDSDEP.(0|1|2)$/gm.test(item.key);
              })
              .map((item) => item?.value?.toUpperCase())
              .join(" ");
            dependentOptions.push({ value: fullName, name: fullName });
          });
        }
      }
    }
  } else {
    dependentOptions.push({ value: "", name: "" });
  }
  return dependentOptions;
};

export default getDependentOptions;
