import { Button, DialogFooter } from 'react-md';
import usePageFramework from '@utilities/hooks/usePageFramework';
import React from 'react';

function NotesFooterMobile(props) {
    const { characterCount, isNotesChangedAndUnsaved, handleOnCancel,
        saveFunction, isNewNote
    } = props;
    const { REDUX, selectState } = usePageFramework();
    const isFormLocked = selectState(REDUX.LOCK_FORMS);

    return (
        <DialogFooter className='notesFooterMobile'>
            <div className='notesFooterTextMobile'>
                <p>Saving this note will create a new version.</p>
                <p>{998 - characterCount} characters remaining.</p>
            </div>
            <div className='notesFooterActionsMobile'>
                <Button
                    className='formNotesCancel'
                    id="notes-cancel"
                    theme="primary"
                    themeType="outline"
                    onClick={handleOnCancel}
                    disabled={isFormLocked}
                >
                    Cancel
                </Button>
                <Button
                    id="notes-save"
                    theme="primary"
                    themeType="contained"
                    className="formNotesSave"
                    onClick={saveFunction}
                    disabled={!isNotesChangedAndUnsaved || isFormLocked}
                >
                    {isNewNote ? 'Save' : 'Update'}
                </Button>
            </div>
        </DialogFooter>
    );
}

export default NotesFooterMobile;