import { CircularProgress } from "@react-md/progress";

import * as NAV from '@utilities/constants/navigation';
import * as REDUX from '@utilities/constants/redux';

import ClientDashboard from '@views/dashboard/clientDashboard';
import Dashboard from "@views/dashboard";
import PractitionerDashboard from '@views/PractitionerDashboard.js';

import EntryExperienceModal from "@components/entryExperience/entryModal";
import AboutYou from '@views/personalAndOther/aboutYou';
import AboutYourYear from '@views/personalAndOther/aboutYourYear';
import Dependents from '@views/personalAndOther/dependents';
import RefundPayment from '@views/personalAndOther/refundPayments';
import TaxPayments from '@views/personalAndOther/taxPayments';
import ForeignAssets from '@views/personalAndOther/foreignAssets';

import Wages from '@views/income/wages';
import Income from '@views/income/investmentIncome/';
import SocialSercurityRetirement from '@views/income/socialSecurityRetirement';
import BusinessIncome from '@views/income/businessIncome';
import FarmIncome from '@views/income/farmIncome';
import RentalIncome from '@views/income/rentalIncome';
import PassthroughIncome from '@views/income/passthroughIncome';
import MiscellaneousIncome from '@views/income/miscellaneousIncome';
import ForeignIncome from '@views/income/foreignIncome';
import MobileLimitedExperience from '@views/income/mobileLimitedExperience';

import RetirementContributions from '@views/deductions/retirementContributions';
import MedicalExpenses from '@views/deductions/medicalExpenses';
import PropertyAndOtherStateTaxes from '@views/deductions/propertyAndOtherStateTaxes';
import MortgageInvestmentInterest from '@views/deductions/mortgageInvestmentInterest';
import CharitableContributions from '@views/deductions/charitableContributions';
import ChildCare from '@views/deductions/childCare';
import Tuition from '@views/deductions/higherEducation';
import VehicleEnergy from '@views/deductions/vehicleEnergy';
import HouseholdEmploymentTaxes from '@views/deductions/householdEmploymentTaxes';
import OtherDeductions from '@views/deductions/otherDeductions';
import OrganizerExport from '@views/organizerExport';

import AdminDashboard from '@views/admin/adminDashboard';
import ManageClients from '@views/admin/manageClients';
import ManageOrganizers from '@views/admin/manageOrganizers';
import ManageGroups from '@views/admin/manageGroups';
import ManageInCharge from '@views/admin/manageInCharge';
import ManageUsers from '@views/admin/manageUsers';
import ManageOrganizerStatus from "@views/admin/manageOrganizerStatus";
import PriorYearDataPull from "@views/admin/priorYearDataPull";
import ManageNotifications from '@views/admin/manageNotifications';
import ManageEmailInvites from '@views/admin/manageEmailInvites';

import OrganizerDetails from '@views/admin/organizerDetails';
import NoActiveClient from "@views/dashboard/noActiveClientPage";

import DocumentManager from '@views/DocumentManager';
import CacheBreak from '@views/cachebreak';
import Home from "@views/home";
import ChqChecklistDownload from "@views/chqDownloadChecklist";
import DocuSignEmbeddedSigning from "@views/docusignEmbeddedSigning";

const Loading = () => <CircularProgress id="loading-progress" />;

const getRoutes = (isAuthenicated, isPractitioner, isAdmin, isClient, activeReturn, isDesktopDevice, isLaptopOrDesktop, authorizedIds ) => {
	// GROWTH: Handle strict page access with all three roles including client


	// If the account is disabled, only allow access to the one page.
	if (!isAuthenicated) {
		return [
			{ label: 'Home', to: NAV.DEFAULT, componentObject: Loading }
		];
	}

	const isShowPlug = !isDesktopDevice && !isLaptopOrDesktop
	
	const defaultHome = isPractitioner || isAdmin ? PractitionerDashboard : Dashboard;

	const pages = [
		{ to: NAV.DASHBOARD, componentObject: isClient ? Dashboard : ClientDashboard},
		{ to: NAV.CLIENT_DASHBOARD, componentObject: ClientDashboard},
		{ to: NAV.ENTRY_INTERVIEW, componentObject: EntryExperienceModal },
		{ to: NAV.ABOUT_YOU, componentObject: AboutYou },
		{ to: NAV.ABOUT_YOUR_YEAR, componentObject: AboutYourYear },
		{ to: NAV.WAGES, componentObject: Wages },
		{ to: NAV.DEPENDENTS, componentObject: Dependents },
		{ to: NAV.RETIREMENT_INCOME, componentObject: SocialSercurityRetirement },
		{ to: NAV.MISCELLANEOUS_INCOME, componentObject: MiscellaneousIncome },
		{ to: NAV.MORTGAGE_INVESTMENT_INTEREST, componentObject: MortgageInvestmentInterest },
		{ to: NAV.CHARITABLE_CONTRIBUTIONS, componentObject: CharitableContributions },
		{ to: NAV.VEHICLEENERGY, componentObject: VehicleEnergy },
		{ to: NAV.HOUSEHOLDEMPLOYMENTTAXES, componentObject: HouseholdEmploymentTaxes },
		{ to: NAV.CHILDCARE, componentObject: ChildCare },
		{ to: NAV.PROPERTYANDOTHERSTATETAXES, componentObject: PropertyAndOtherStateTaxes },
		{ to: NAV.MEDICALEXPENSES, componentObject: MedicalExpenses },
		{ to: NAV.RETIREMENT_CONTRIBUTIONS, componentObject: RetirementContributions },
		{ to: NAV.REFUNDTAXPAYMENT, componentObject: RefundPayment },
		{ to: NAV.INCOME, componentObject: Income },
		{ to: NAV.OTHERDEDUCTIONS, componentObject: OtherDeductions },
		{ to: NAV.BUSINESS_INCOME, componentObject: isShowPlug ? (props) => <MobileLimitedExperience {...props} customProp={{formName: REDUX.BUSINESS_INCOME, formNav: NAV.BUSINESS_INCOME}} />  : BusinessIncome },
		{ to: NAV.TUITION, componentObject: Tuition },
		{ to: NAV.FARM_INCOME, componentObject: isShowPlug ?  (props) => <MobileLimitedExperience {...props} customProp={{formName: REDUX.FARM_INCOME, formNav: NAV.FARM_INCOME}} /> : FarmIncome },
		{ to: NAV.RENTAL_INCOME, componentObject: isShowPlug ?  (props) => <MobileLimitedExperience {...props} customProp={{formName: REDUX.RENTAL_INCOME, formNav: NAV.RENTAL_INCOME}} /> : RentalIncome }, 
		{ to: NAV.PASSTHROUGH_INCOME, componentObject: PassthroughIncome },
		{ to: NAV.TAX_PAYMENTS, componentObject: TaxPayments },
		{ to: NAV.FOREIGN_ASSETS, componentObject: ForeignAssets },
		{ to: NAV.FOREIGN_INCOME, componentObject: ForeignIncome },
		{ to: NAV.DOCUMENT_MANAGER, componentObject: DocumentManager },
		// { to: `${NAV.DOCUMENT_MANAGER}/:id`, componentObject: DocumentManager },
		// { to: `${NAV.DOCUMENT_MANAGER}/:id/:additional`, componentObject: DocumentManager },
		{ to: `${NAV.CHQ_CHECKLIST_DOWNLOAD}/:orgId`, componentObject: ChqChecklistDownload, isCustomRoute: true },
		{ to: `${NAV.DOCUSIGN_EMBEDDED_SIGNING}`, componentObject: DocuSignEmbeddedSigning, isCustomRoute: true }
	];

	const adminPages = [
		{ to: NAV.DEFAULT, componentObject: defaultHome },
		{ to: NAV.PRACTITIONERS_DASHBOARD, componentObject: PractitionerDashboard },
		{ to: NAV.ADMIN_DASHBOARD, componentObject: AdminDashboard },
		{ to: NAV.MANAGE_CLIENTS, componentObject: ManageClients },
		{ to: NAV.MANAGE_ORGANIZERS, componentObject: ManageOrganizers },
		{ to: NAV.MANAGE_GROUPS, componentObject: ManageGroups },
		{ to: NAV.MANAGE_IN_CHARGE, componentObject: ManageInCharge },
		{ to: NAV.MANAGE_USERS, componentObject: ManageUsers },
		{ to: `${NAV.MANAGE_ORGANIZERS}/:id`, componentObject: OrganizerDetails },
		{ to: `${NAV.ORGANIZER_EXPORT}/:orgId`, componentObject: OrganizerExport },
		{ to: NAV.MANAGE_ORGANIZER_STATUS, componentObject: ManageOrganizerStatus },
		{ to: NAV.PRIOR_YEAR_DATA_PULL, componentObject: PriorYearDataPull },
		{ to: NAV.MANAGE_NOTIFICATIONS, componentObject: ManageNotifications },
		{ to: NAV.WELCOME_EMAIL_INVITES, componentObject: ManageEmailInvites }
	];

	const practitionerPages = [
		{ to: NAV.DEFAULT, componentObject: defaultHome },
		{ to: NAV.PRACTITIONERS_DASHBOARD, componentObject: PractitionerDashboard },
		{ to: `${NAV.ORGANIZER_EXPORT}/:orgId`, componentObject: OrganizerExport },
	];

	const clientPages = [
		{ to: NAV.DEFAULT, componentObject: defaultHome },
		{ to: `${NAV.ORGANIZER_EXPORT}`, componentObject: OrganizerExport, isExportForm: true },
		{ to: `${NAV.CLIENT_DASHBOARD}`, componentObject: ClientDashboard },
	];

	if (!activeReturn && !authorizedIds?.length) {
		// GROWTH: Handle No client selected for practitioners vs no data for clients in separate component pages
		// const NoDataComponent = isClient ? ErrorLoadingData : NoActiveClient;
		const NoDataComponent = NoActiveClient;

		pages.forEach(page => {
			if (!page.isCustomRoute) {
				page.componentObject = NoDataComponent;
			}
		});

		clientPages.forEach(clientPage => {
			if (!clientPage.isExportForm) {
				if (!clientPage.isChecklistDownloadRoute) {
					clientPage.componentObject = NoDataComponent;
				}
			};
		});
	}

	const routes = [
		...pages,
		...(isClient ? clientPages : []),
		...(isAdmin ? adminPages : []),
		...(isPractitioner ? practitionerPages : []),
		{ to: NAV.HOME, componentObject: Home },
		{ to: NAV.CACHE_BREAK, componentObject: CacheBreak },
	];

	return routes;
};

export {
	getRoutes
};