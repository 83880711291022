import api from '@utilities/claApi';

const handleDownloadClick = async (organizerId, filename) => {
    try {
        const blobFile = await api.get(`/organizers/${organizerId}/checklist-download`,
            { responseType: 'blob' }).then((response) => response.data);
        const url = URL.createObjectURL(blobFile);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        setTimeout(function () {
            document.body.removeChild(a);
            URL.revokeObjectURL(blobFile);
        }, 1000);
    } catch (error) {
        console.error(error);
    }
};

const getChecklist = async (organizerId) => {
    let checklist = null;
    try {
        await api.get(`/organizers/${organizerId}/checklist`)
            .then((response) => checklist = response.data)
            .catch((err) => console.error(err));
    } catch (error) {
        console.error(error);
    }
    return checklist;
}

const getChecklistEmailRecipients = async (organizerId) => {
    const recipients = [];
    try {
        await api.get(`/organizers/${organizerId}/checklist-users`)
            .then((response) => {
                recipients.push(...response.data);
            })
            .catch((error) => {
                console.log(error, 'error');
            });
    } catch (error) {
        console.error(error);
    }

    return recipients.map(user => {
        return {
            ...user,
            checklistLastSent: user.checklistLastSent ?? null,
            checklistSentBy: user.checklistSentBy ?? null
        }
    });
}

const handleEmailChecklistClick = async (organizerId, recipientIds) => {
    let result = false;
    try {
        await api.post(`/organizers/${organizerId}/email-checklist`, { recipientIds })
            .then((response) => {
                result = true;
            })
            .catch((error) => {
            })
    } catch (error) {
    }
    return result;
}

export {
    handleDownloadClick,
    getChecklist,
    handleEmailChecklistClick,
    getChecklistEmailRecipients
}