import usePageFramework from '@utilities/hooks/usePageFramework';
import { useEffect, useState } from 'react';

export const UserAccount = ({ width, height, fontSize, fontWeight, backgroundColor, color }) => {
    const { selectState, REDUX } = usePageFramework();
    const taxpayerName = selectState(REDUX.TAXPAYER_NAME);

    const [initials, setInitials] = useState('');

    useEffect(() => {
        if (taxpayerName) getInitials();
    }, [taxpayerName]);

    const getInitials = () => {
        const initials = taxpayerName.split(' ').slice(0, 2).map(part => part[0].toUpperCase()).join('');
        setInitials(initials);
        return initials;
    };

    return (
        <>
            {initials && initials !== "" ? (
                <div data-testid="userAccount"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: width || '45px',
                        height: height || '45px',
                        fontSize: fontSize || '21px',
                        fontWeight: fontWeight || '400',
                        padding: '6px',
                        backgroundColor: backgroundColor || '#FFFFFF',
                        borderRadius: '50%',
                        marginRight: '16px',
                    }}
                >
                    <p
                        style={{
                            color: color || '#2E334E',
                            fontSize: fontSize || '21px',
                            fontWeight: fontWeight || '400',
                            padding: '0px',
                            margin: '0px',
                        }}
                    >
                        {initials}
                    </p>
                </div>
            ) : (
                <div data-testid="userAccountSvg"
                    style={{
                        backgroundColor: '#2E334E',
                        padding: '0px',
                        marging: '0px',
                        width: '46px',
                        height: '46px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '14px',
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="45"
                        height="45"
                        fill="none"
                        viewBox="0 0 45 45"
                    >
                        <circle
                            cx="22.5"
                            cy="22.5"
                            r="21.5"
                            stroke="#fff"
                            strokeWidth="2"
                        ></circle>
                        <circle cx="22" cy="16" r="6.5" fill="#fff" stroke="#fff"></circle>
                        <g clipPath="url(#clip0_11299_38247)">
                            <mask
                                id="mask0_11299_38247"
                                style={{ maskType: "alpha" }}
                                width="33"
                                height="33"
                                x="6"
                                y="27"
                                maskUnits="userSpaceOnUse"
                            >
                                <circle cx="22.5" cy="43.5" r="16" fill="#fff" stroke="#fff"></circle>
                            </mask>
                            <g mask="url(#mask0_11299_38247)">
                                <circle
                                    cx="22.499"
                                    cy="22.699"
                                    r="20.214"
                                    fill="#fff"
                                    stroke="#fff"
                                    strokeWidth="2"
                                ></circle>
                            </g>
                        </g>
                        <defs>
                            <clipPath id="clip0_11299_38247">
                                <path
                                    fill="#fff"
                                    d="M0 0H33V18H0z"
                                    transform="translate(6 27)"
                                ></path>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            )}
        </>
    );
};