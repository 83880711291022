import { GridCell } from 'react-md';

function DetailsLabel(props) {
    const { item, index } = props;
    const isVisible = item.isVisible === undefined || item.isVisible;

    return (
        isVisible ? 
            <GridCell 
                key={`lbl-${index}`} 
                colSpan={item.col || 12} className={item.class || 'groupDetails'}
            >
                { item.isMarkup ? <span dangerouslySetInnerHTML={{ __html: item.label}}></span> : item.label }
            </GridCell> : 
            null
    );
}

export default DetailsLabel;