import { findFromPYData, getPYCalculationFunction } from "@utilities/axcessTaxPull";

/*
    Reusing findFromPYData but merging values from multiple axcess keys
*/
const findMultiFieldsFromPYData = (priorYearEntity, field, fieldName) => {
    const axcessPull = field && field.axcess?.pull;
    const fieldNameKey = field?.name ? [field.name] : null;
    const providedNameKey = fieldName ? [fieldName] : null;

    const keys = axcessPull?.fields || fieldNameKey || providedNameKey;

    if (!keys || !keys.length) return null;

    const foundValues = keys.map(key => findFromPYData(priorYearEntity, undefined, key));

    if (!foundValues || !foundValues.length) return null;

    const transformedValue = getPYCalculationFunction(axcessPull?.fn)(foundValues) || foundValues.join(' ');
    return transformedValue?.trim();
};

export {
    findMultiFieldsFromPYData,
};