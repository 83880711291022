import { Button, useToggle } from 'react-md';
import * as IMG from '@utilities/constants/images';
import WarningDialog from '../../../dialogBox/warningDialog';

function InlineClearButton(props) {
    const { className, onClick, group, isFormLocked } = props;
    const rowType = group?.entityPlaceHolder?.toLowerCase() || 'record';
    const subtitleText = '';
    const questionText = `Are you sure you want to permanently clear this ${rowType}?`;
    const confirmText = '';

    const [visibleWarningDialog, showWarningDialog, hideWarningDialog] = useToggle(false);

    const clearIcon = <Button className={className}
        data-testid='btn-inline-clear'
        disabled={isFormLocked}
        onClick={showWarningDialog} >
        <img alt='line item clear icon' src={IMG.lineClearEvent} />
    </Button>;

    const confirmClear = () => {
        onClick();
        hideWarningDialog();
    };

    return (
        <WarningDialog subtitleText={subtitleText} questionText={questionText} confirmText={confirmText} onConfirmClick={confirmClear}
            buttonElement={clearIcon}
            visible={visibleWarningDialog} onCancelWarning={hideWarningDialog}>
        </WarningDialog>
    );
}

export default InlineClearButton;