import { Grid } from 'react-md';

function DropdownGroup (props) {
    const { field } = props;
    
    if (!field) return (null);

    return (
        <>
            <div data-testid="formChkContainerId" className="formCheckboxesHeaderContainer">
                <p data-testid="GrpTextId" className="multiSelectText1">Select all that apply:</p>
            </div>
            <Grid data-testid="dropdownGrpId" className="dropdownGroup">
                {field}
            </Grid>
        </>
    );
}

export default DropdownGroup;