export const taxReturnStatuses = Object.freeze({
    notApplicable: "N/A",
    notSent: "Not Sent",
    sent: "Sent",
    partial: "Partial",
    declined: "Declined",
    signed: "Signed"
});

export const taxReturnUserStatus = Object.freeze({
    sent: "Sent",
    signed: "Signed",
    declined: "Declined"
});