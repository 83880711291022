import React from "react";
// import Multiselect from 'multiselect-react-dropdown';
// import { isFieldRequired } from '../fields/utils/isRequired';
// import { isFieldDisabled } from '../fields/utils/isDisabled';
// import { useField } from '../fields/useField';
// import { useState } from 'react';
// import * as LOOKUPS from '@utilities/constants/lookupInfo';

function MultiSelectDropdown(props) {
  // const { field, fns, group, index, section, parentGroup, parentIndex } = props;
  const { field, index } = props;

  // const [selectedValue, setSelectedValue] = useState(field.selectedValue);
  // const requiredField = isFieldRequired(field);
  // const { updateBlurState } = useField(props);

  // const [options, setOptions] = useState(() => {
  //   const initialState = LOOKUPS[field.lookup] || LOOKUPS.YESNO;
  //   return initialState;
  // });

  // const updateSelection = (selectedList, selectedItem) => {
  //   setSelectedValue(selectedList);
  //   if ((selectedItem === 'Select' || selectedItem === ' ') && requiredField) {
  //     updateBlurState(selectedList, true, 'Required');
  //   } else {
  //     updateBlurState(selectedList, false, '');
  //   }

  //   if (field.triggered) {
  //     if (typeof (field.triggered) === 'string') {
  //       fns.triggered(field.triggered, selectedList, props);
  //     } else {
  //       field.triggered(selectedList);
  //     }
  //   }
  //   if (field.logicFunction) {
  //     field.logicFunction.forEach((logic) => {
  //       logic.isDifferentGroup ? fns.triggered(logic.trigger, field.default, selectedList) :
  //         fns.sectionFieldLogic(logic?.trigger, { group, field, index, logic, parentGroup, parentIndex, section, fns });
  //     });
  //   }
  //   fns.saveForm(field);
  // }

  // const onSelect = (selectedList, selectedItem) => {
  //   updateSelection(selectedList, selectedItem);
  // }

  // const onRemove = (selectedList, selectedItem) => {
  //   updateSelection(selectedList, selectedItem);
  // }

  return (
    <div className="multiSelectDrop-parent-div" key={`key-multiSelectDrop-${field.name}-${index}`}>
      <label className="multiSelectDrop-label">{field.placeHolder}</label>
      {/* <Multiselect
        key={`multiSelectDrop-${field.name}-${index}`}
        id={`multiSelectDrop-${field.name}-${index}`}
        data-testid='multiSelectDrop-down-field'
        showArrow={field.showArrow}
        singleSelect={field.singleSelect}
        options={options}
        placeholder={field.placeHolder}
        isObject={field.isObject}
        selectedValues={state.value}
        disablePreSelectedValues={field.disablePreSelectedValues}
        disable={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section)}
        selectionLimit={field.selectionLimit ? field.selectionLimit : -1}
        closeIcon={field.closeIcon ? field.closeIcon : 'circle'}
        showCheckbox={field.showCheckbox}
        displayValue={field.displayValue}
        groupBy={field.groupBy}
        onSelect={onSelect}
        onRemove={onRemove}
        avoidHighlightFirstOption={true}
        style={{
          chips: {
            background: '#1e2133',
            fontWeight: '300',
          },
          multiselectContainer: {
            color: '#4b4b4b',
            fontFamily: 'museo-sans',
            fontSize: '13px',
            fontWeight: 'bold',
          },
        }}
      /> */}
    </div>
  );
}

export default MultiSelectDropdown;