import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import EntityRenderer from '@components/entityRenderer';
import { triggeredEvent, sectionFieldLogic } from './rentalIncomeLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import rentalIncome2021 from './2021';
import rentalIncome2022 from './2022';
import rentalIncome2023 from './2023';
import rentalIncome2024 from './2024';
import * as NAV from '@utilities/constants/navigation';

let sections = [];

export const getRentalIncomeSections = () => {
    return sections;
};

function RentalIncome(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.RENTAL_INCOME);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: rentalIncome2021(year),
            2022: rentalIncome2022(year),
            2023: rentalIncome2023(year),
            2024: rentalIncome2024(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);
        sections = formSections;
        setSectionState(formSections);
        // eslint-disable-next-line
    }, []);

    const axcessIdentifiers = [
        { section: 'Income', id: 'Sch E, p 1 - Rent and Royalty' },
    ];

    const spreadIdentifiers = [
        { section: 'Income', id: 'Other Income (1099-G, 1099-K, 1099-MISC, 1099-NEC, W-2G)' },
    ];

    const entityFilter = {
		type: 'any',
		filters: [
			{ key: 'IFDSEGEN.11', filterType: 'linesNotContainsKey' },
		],
	};

    return (
        !sectionState ? <></> :
            <EntityRenderer sections={sectionState} key="rentalIncomeForm" formName={REDUX.RENTAL_INCOME} identifiers={axcessIdentifiers} spreadIdentifiers={spreadIdentifiers}
                triggeredEvent={triggeredEvent} sectionFieldLogic={sectionFieldLogic} isExportForm={isExportForm} entityFilter={entityFilter}/>
    );
}

export default RentalIncome;