// common modules
import {
	Button, Divider, Form, FileInput,
	Grid,
	GridCell
} from 'react-md';
import { useState, useEffect } from 'react';
import { AddSVGIcon, RemoveSVGIcon } from '@react-md/material-icons';

// custom modules
import usePageFramework from '@utilities/hooks/usePageFramework';
import DocumentManagerUploadList from '@components/documentManager/DocumentManagerUploadList';
import DocumentManagerEntity from '@components/documentManager/DocumentManagerEntity';
import { ACCEPTED_FILE_TYPES_1040 } from '@utilities/constants/document';

const DocumentManagerForm = ({ form, index, isLast }) => {
	const {
		selectState,
		REDUX,
		onFileUpload,
	} = usePageFramework();
	const uploadList = selectState(REDUX.UPLOAD_LIST);
	const [isCollapsed, setIsCollapsed] = useState(true);
	const [fileCount, setFileCount] = useState(0);
	const disabled = form.showBulkEntityUpload || (fileCount > 0) ? false : true;

	useEffect(() => {
		let files = 0;

		if (form.showBulkEntityUpload) {
			form.entities.forEach((entity) => {
				files += entity.uploads.length;
			});
		} else {
			files = form?.uploads?.length || 0;

			if (files === 0) {
				setIsCollapsed(true);
			}
		}

		setFileCount(files);
    // eslint-disable-next-line
	}, [uploadList]);

	const onUpload = (event) => onFileUpload(event, null, form.showBulkUpload ? form.bulkUpload.sectionName : form.uploadSectionName, uploadList, { updateState: () => { } }, form.key);

	return (
		<Grid key={form.key} id={index} className='expandableFieldsContainer'>
			<Grid className='denseLineItemRow' style={{ marginTop: '-20px' }}>
				<GridCell colSpan={12} className='denseRowContainer'>
					<div className='denseRowBtnContainer' style={{ margin: '5px 13px 0 0' }} id={`doc-manager-form-section-collapse-btn-container-${index}`}>
						<Button
							className='denseRowBtn'
							buttonType='icon'
							id={`doc-manager-form-collapse-btn-${index}`}
							onClick={() => setIsCollapsed(!isCollapsed)}
							disabled={disabled}
							disableRipple
						>
							{
								isCollapsed ? <AddSVGIcon className='denseRowBtn' /> :
									<RemoveSVGIcon className='denseRowBtn' />
							}
						</Button>

					</div>
					<Grid style={{ width: '100%' }}>
						<GridCell colSpan={4} className='docManagerCell formName'>{form.title}</GridCell>
						<GridCell colSpan={1} className='docManagerCell fileCountCellText'>
							{fileCount > 0 && fileCount}
						</GridCell>
						<GridCell colSpan={2} className='docManagerCell'></GridCell>
						<GridCell colSpan={3} className='docManagerCell'>
						</GridCell>
						<GridCell colSpan={2} className='docManagerCell'>
							{
								form.showBulkEntityUpload ?
									<div
										className='docManagerActionBtns docManagerActionsPadding'
										onClick={() => setIsCollapsed(!isCollapsed)}
									>
										{isCollapsed ? 'View Details' : 'Hide Details'}
									</div> :
									<div className={'docManagerActionBtns'} style={{ marginTop: '-10px' }}>
										<Form>
											<FileInput
												className="expandFormBtn docManagerActionsPadding"
												id={`documentManager-${form.showBulkUpload ? form.bulkUpload.sectionName : form.uploadSectionName}`}
												onChange={onUpload}
												buttonType={'text'}
												theme='clear'
												themeType='flat'
												icon={null}
												multiple={true}
												accept={ACCEPTED_FILE_TYPES_1040}>
												Add Files
											</FileInput>
										</Form>
									</div>
							}
						</GridCell>
						{
							form.showBulkEntityUpload ?
								<GridCell colSpan={12}>
									{!isCollapsed && form.entities.map((entity) => (
										<Grid key={`doc-manager-entity-${entity.uploadSectionName}`} className='expandableEntityUploadsContainer' style={{ marginTop: '10px' }}>
											<DocumentManagerEntity entity={entity} formKey={form.key} isLast={true} />
										</Grid>
									))}
									<div className="docManagerEntityConnectorLine"></div>
								</GridCell> :
								<DocumentManagerUploadList
									key={`doc-manager-upload-list-${form.key}`}
									formKey={form.key}
									sectionKey={form.showBulkUpload ? form.bulkUpload.sectionName : form.uploadSectionName}
									uploadList={form.uploads}
									isUnassigned={false}
									isCollapsed={isCollapsed}
								/>
						}
						{form.isFormEntity ? <></> :
							<GridCell colSpan={12}>
								<Divider className='lineItemRowDivider' />
							</GridCell>}
					</Grid>
				</GridCell>
			</Grid >
			{
				isLast && !isCollapsed && form.uploads && form.uploads.length > 0 &&
				<div className="denseRowDocManagerExpandCircleIcon"></div>
			}
		</Grid >
	);
};

export default DocumentManagerForm;