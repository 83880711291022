import _ from 'lodash';

import FormRenderer from '../index';
import usePageFramework from '@utilities/hooks/usePageFramework';
// import SaveCancelButton from './saveCancelButton';

function LineItemSection(props) {
    const { 
        entities, 
        id, 
        parentFns, 
        parentGroup, 
        parentIndex, 
        hasLineItemSection, 
        sectionIndex, 
        groupIndex, 
        renderEntity,
        grandParentGroup,
        groupItemIndex,
        isFormLocked,
        formName,
        // setIsAddingNewEntity 
    } = props;
    // const { card } = usePageFramework();
    const { sections, identifiers } = entities;
    const formLineSectionKey = `${formName}-0-${parentIndex}`;
    // const isSaveSuccess = selectState(REDUX.IS_SAVE_SUCCESS);
    let currentSection = _.cloneDeep(sections);
    // if (parentGroup.currentViewField === 100) {
    //     currentSection = _.cloneDeep(sections);
    // } else {
    //     currentSection.push(sections[parentGroup.currentViewField]);
    // }
    
    const subSectionSaveForm = (field, nextTabId, childFormSectionData) => {
        if (parentFns) {
            parentFns.saveForm(
                field, 
                nextTabId, 
                Object.assign({}, childFormSectionData, { parentIndex, sectionIndex, groupIndex, groupItemIndex })
            );
        }
    };
    
    return (
        <>
            {/* <SaveCancelButton parentGroup={parentGroup} className='saveCancelTop' setIsAddingNewEntity={setIsAddingNewEntity} isSaveSuccess={isSaveSuccess}/> */}
            <FormRenderer 
                sections={currentSection} 
                key={`lineItemSection-${id}`} 
                identifiers={identifiers} 
                parentFns={{ saveForm: subSectionSaveForm }} 
                parentGroup={parentGroup} 
                parentIndex={parentIndex} 
                hasLineItemSection={hasLineItemSection} 
                passedPriorData={true} 
                isLineItemSection={true} 
                formName={formLineSectionKey}
                usePageFramework={usePageFramework}
                renderEntity={renderEntity}
                saveForm={subSectionSaveForm}
                sectionFieldLogic={parentFns.sectionFieldLogic}
                triggered={parentFns.triggered}
                grandParentGroup={grandParentGroup}
                isFormDisabled={isFormLocked}
                containerStyles={parentGroup.lineSectionStyles}
            />
            {/* <SaveCancelButton parentGroup={parentGroup} className='saveCancelBottom' setIsAddingNewEntity={setIsAddingNewEntity} isSaveSuccess={isSaveSuccess}/> */}
        </>
    );
}

export default LineItemSection;
