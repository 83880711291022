import { useState, useEffect } from 'react';
import { Button, Dialog } from 'react-md';
import nextId from 'react-id-generator';
import _ from 'lodash';

// custom modules
import * as IMG from '@utilities/constants/images.js';
import '@components/dialog/warningDialog/WarningDialog.css';
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as STR from '@utilities/constants/strings'

function UploadWarningDialog(props) {
    const { visible, dupFiles, uploadProps, uploadList } = props;
    const { dispatch, ACTION, onFileRemoval, uploadFiles } = usePageFramework();
    const [currentDupFileIndex, setCurrentDupFileIndex] = useState(0);
    const isMultiUpload = uploadProps?.files?.length > 1;
    let updatedList = _.cloneDeep(uploadList);
    const [skippedFiles, setSkippedFiles] = useState([])

    const onCancelWarning = () => {
        dispatch(ACTION.setUploadWarningVisible(false));
        dispatch(ACTION.setDuplicateUploadFilesSkipped([]));
    };

    const onSkipWarning = () => {
        //remove the skipped duplicate file on the files to upload
        if (dupFiles[currentDupFileIndex]) {
            dupFiles[currentDupFileIndex].isSkip = true;
        }

        const skippedFilesList = [...skippedFiles, dupFiles[currentDupFileIndex].name];

        setSkippedFiles(skippedFilesList);

        dispatch(ACTION.setDuplicateUploadFilesSkipped(skippedFilesList));

        uploadProps?.files?.forEach((doc) => {
            if (doc.name === dupFiles[currentDupFileIndex].name) doc.isSkip = true;
        });

        setCurrentDupFileIndex(currentDupFileIndex + 1);

    };

    const onReplaceWarning = () => {
        if (dupFiles[currentDupFileIndex]) {
            dupFiles[currentDupFileIndex].isReplaced = true;
        }

        updatedList?.forEach((doc) => {
            if (doc.name === dupFiles[currentDupFileIndex]?.name) doc.isReplaced = true;
        });

        uploadProps?.files?.forEach((doc) => {
            if (doc.name === dupFiles[currentDupFileIndex]?.name) doc.isReplaced = true;
        });

        setCurrentDupFileIndex(currentDupFileIndex + 1);
    };

    useEffect(() => {
        if (currentDupFileIndex === dupFiles.length && dupFiles.length) {
            const replacedFiles = dupFiles.filter((dup) => dup.isReplaced) || []; // replace = remove, then upload
            const filesToUpload = uploadProps?.files?.filter((upload) => !upload.isSkip); //files that are not skipped
            const filesNotReplaced = updatedList?.filter((upload) => !upload.isReplaced); // files that are not to be replaced

            // Remove all files that need to be replaced
            const removeFilePromises = replacedFiles.map((replacedFile) => {
                return onFileRemoval(null, replacedFile, replacedFiles);
            });

            // Upload all files except for skipped files
            Promise.all(removeFilePromises).then(() => {
                uploadFiles(filesToUpload, uploadProps?.section, uploadProps?.fn, uploadProps?.form, filesNotReplaced);
            });

            // Dismiss the dialog
            dispatch(ACTION.setUploadWarningVisible(false));
            dispatch(ACTION.setDuplicateUploadFiles(null));
            dispatch(ACTION.setUploadsProps(null));
    
            setCurrentDupFileIndex(currentDupFileIndex + 1);
        }
    }, [currentDupFileIndex, dupFiles, uploadProps]);

    useEffect(() => {
        setCurrentDupFileIndex(0);
        setSkippedFiles([]);
    }, [visible]);

    return (dupFiles?.length ?
        <Dialog
            id={`warning-dialog-${nextId()}`}
            role="alertdialog"
            visible={visible}
            onRequestClose={() => { }}
            aria-labelledby="dialog-title"
            className="WarningDialogContainer"
            data-testid="upload-warning-dialog"
        >
            <div className='WarningDialogInnerContainer' >
                <div className='WarningSignContainer'>
                    <img className="warningDialogIcon" width="24" height="20" alt="Warning icon" src={IMG.WARNING_ICON}></img>
                    <div className="SuccessDialogTitle"> {STR.WARNING} </div>
                </div>
                <div className="WarningDialogTextContainer">
                    <div className="WarningDialogText1"> A file named <span style={{ color: '#2d3c59' }}>{`"${dupFiles[currentDupFileIndex]?.name}"` || ''}</span> already exists in this location.  Would you like to replace the existing file? </div>
                </div>
                <div className="warningDialogButton">
                    <Button className="warningDialogSkip" data-testid="dialog-cancel" id="dialog-cancel" onClick={onCancelWarning} > {isMultiUpload ? STR.CANCEL_ALL : STR.CANCEL} </Button>
                    {isMultiUpload && <Button className="warningDialogSkip" data-testid="dialog-skip" id="dialog-skip" onClick={onSkipWarning} > {STR.SKIP} </Button>}
                    <Button className="warningDialogCancel" style={{ fontSize: '11px' }} data-testid="dialog-replace" id="dialog-replace" onClick={onReplaceWarning} > {STR.REPLACE} </Button>
                </div>
            </div>
        </Dialog> : null
    );
}

export default UploadWarningDialog;