import DocViewer, {
    JPGRenderer,
    PNGRenderer,
    TXTRenderer,
    CSVRenderer,
    GIFRenderer,
    VideoRenderer,
    TIFFRenderer,
} from '@cyntler/react-doc-viewer';
import NoRenderer from '@components/documentPreview/noRenderer';
import PDFDocumentPreview from '@components/documentPreview/pdf';
// import MSDocumentPreview from '@components/documentPreview/msdoc';
import '@components/documentPreview/documentPreview.css'

const useDocumentPreview = () => {
    const renderers = [
        JPGRenderer,
        PNGRenderer,
        TXTRenderer,
        CSVRenderer,
        GIFRenderer,
        VideoRenderer,
        PDFDocumentPreview,
        TIFFRenderer,
        // GROWTH: Determine a good method to display a preview for word documents
        // MSDocumentPreview,
    ];

    const buildDocumentPreview = (urlSource, imageFile) => {
        const documents = [];

        if (urlSource) {
          if((imageFile.extension.includes('tiff') || imageFile.extension.includes('tif'))){
            documents.push({uri: urlSource});
          }else{
            documents.push({uri: URL.createObjectURL(urlSource)});
          } 
        }

        return (
            <DocViewer
                documents={documents}
                pluginRenderers={renderers}
                config={
                    {
                        header: { disableHeader: true, disableFileName: true },
                        noRenderer: {
                            overrideComponent: NoRenderer,
                        }
                    }
                }
            />
        );
    };

    return {
        buildDocumentPreview,
    };
};

export default useDocumentPreview;