import {
	Button
} from 'react-md';

const EntryModalPrevNextIconButtons = ({ setCurrentStep, stepIndex, saveStep, navigateToPreviousPage }) => {

	const handleClick = (page) => {
		if (page === 'prev') {
			if (stepIndex === 0) {
				navigateToPreviousPage(); // Navigate to the previous page outside the steps
			} else {
				setCurrentStep(prevState => prevState - 1); // Navigate to the previous step
			}
		} else if (page === 'next') {
			setCurrentStep(prevState => prevState === 3 ? prevState : prevState + 1); // Navigate to the next step
		} else if (page === 'getStarted') {
			saveStep(); // Save the current step
		}
	};

	const renderButton = (isDisabled) => {
		return (
			<>
				<div>
					<Button
						data-testid="entryBackButtonId"
						themeType="outline"
						className="entryBackButton"
						onClick={() => handleClick('prev')}
						disabled={isDisabled}>
						Back
					</Button>
				</div>
				<div>
					<Button
						data-testid="entryNextButtonId"
						themeType="contained"
						className="entryNextButton"
						onClick={stepIndex === 2 ? () => handleClick('getStarted') : () => handleClick('next')}>
						{stepIndex === 2 ? 'Get Started' : 'Next'}
					</Button>
				</div>
			</>
		);
	};

	let entrySideButtons = null;

	switch (stepIndex) {
		case 0:
			entrySideButtons = renderButton(false); // Enable Back button for stepIndex 0 to go back to the previous page
			break;
		case 1:
		case 2:
			entrySideButtons = renderButton(false); // Enable Back button for steps 1 and 2
			break;
		default:
			entrySideButtons = null;
			break;
	}

	return (
		<div className="entryExperienceGridPrevNextContainer">
			{entrySideButtons}
		</div>
	);
};

export default EntryModalPrevNextIconButtons;
