import { AddSVGIcon, RemoveSVGIcon } from '@react-md/material-icons';

const DenseRowBtn = ({ group, index, setCurrentEntity, parentGroup, setParentCurrentEntity, status }) => {
    const handleDenseRowClick = () => {
        if (group.lineItems && group.lineItems?.length) {
            group.lineItems[index][0].drawerVisible = false;
        }
        
        if (group.isSummaryRow) {
            parentGroup.currentEntity = parentGroup.currentEntity === -2 ? -1 : -2;
            setParentCurrentEntity(parentGroup.currentEntity);
        } 

        group.currentEntity = group.currentEntity === index ? -1 : index;
        group.currentViewField = 100;
        setCurrentEntity(group.currentEntity);
    };

    return (
        <div className="denseRowBtnContainer" style={{ margin: '10px 0 0' }} id={index}>
            {
                status ? 
                    <RemoveSVGIcon className="denseRowBtn" onClick={() => handleDenseRowClick()}/> : 
                    <AddSVGIcon className="denseRowBtn" onClick={() => handleDenseRowClick()}/> 
                    
            }
        </div>
    );
};

export default DenseRowBtn;