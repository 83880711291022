export const formatSsn = (newValue) => {
    let display = newValue.length === 9 ? ('' + newValue).slice(0, -4).replace(/./g, 'X') + ('' + newValue).slice(-4) : newValue;
   
    if (display.length >= 4) {
        display = display.slice(0, 3) + '-' + display.slice(3);
    }
    
    if (display.length >= 7) {
        display = display.slice(0, 6) + '-' + display.slice(6);
    }
    return display;
};

// export const TransformMoney = (currentValue, newValue) => {
//     let display = newValue.replace(/[^0-9.]/g, '');

//     const firstDotOccurance = display.indexOf('.');
    
//     // Trims from the second occurance of '.'
//     if (firstDotOccurance !== -1) {
//         const cutoffIndex = display.indexOf('.', firstDotOccurance + 1);
//         if (cutoffIndex !== -1 ){
//             display = display.slice(0, cutoffIndex);
//         }
//     }

//     const subString = firstDotOccurance === -1 ? display.length : firstDotOccurance + 3;
    
//     return display.substr(0, subString).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
// };

export const TransformTelephone = (currentValue, newValue) => {
    // If characters are being removed, do not re-insert formatting values
    if (newValue.length < currentValue.length) {
        return newValue;
    }

    // Numbers only 
    let display = newValue.replace(/[^0-9]/g, '');

    // Format (608)123-4567
    if (display.length >= 1) {
        display = '(' + display.slice(0, 13) ;
    }

    if (display.length >= 4) {
        display = display.slice(0, 4) + ')' + display.slice(4);
    }

    if (display.length >= 8) {
        display = display.slice(0, 8) + '-' + display.slice(8);
    }

    // Length of 13
    return display.substr(0, 13);
};

export const TransformZip = (currentValue, newValue) => {
    // Numbers only
    let display = newValue.replace(/[^0-9]/g, '');

    if (display.length >= 6) {
        display = display.slice(0, 5) + '-' + display.slice(5);
    }

    return display.substr(0, 11);
};

export const TransformIrsPin = (currentValue, newValue) => {
    // Numbers only - max of 6 characters
    return newValue.replace(/[^0-9]/g, '').substr(0, 6);
};

export const TransformEin = (currentValue, newValue) => {
    let _value = TransformNumber(currentValue, newValue).substr(0, 9);

    if (_value.length >= 2) {
        return _value.slice(0, 2) + '-' + _value.slice(2);
    }

    return _value;
};

export const TransformNumber = (currentValue, newValue) => {
    return newValue.replace(/[^0-9]/g, '');
};

export const TransformDriversLicense = (currentValue, newValue) => {
    // Each US State has their own format that may include numbers or letters
    return newValue.replace(/[^0-9A-Za-z]/g, '');
};

export const TransformName = (currentValue, newValue) => {
    return newValue.replace(/[^A-Za-z '-]/g,'');
};

export const TransformAlphaNumeric = (currentValue, newValue) => {
    return newValue.replace(/[^0-9A-Za-z ]/g, '');
};

export const TransformVin = (currentValue, newValue) => {
    return newValue.replace(/[^0-9A-Za-z]/g, '');
};

export const TransformMapper = {
    'alphanumeric': TransformAlphaNumeric,
    'accountNumber': TransformVin,
    'driversLicense': TransformDriversLicense,
    'ein': TransformEin,
    'irspin': TransformIrsPin,
    'name': TransformName, 
    'number': TransformNumber,
    'telephone': TransformTelephone,
    'vin': TransformVin,
    'year': TransformNumber,
    'zip': TransformZip,
    'zippostal': TransformZip,
};
