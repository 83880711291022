import { Card, Divider } from 'react-md';
import './dashboardCard.css';

// This is a dumb component for a dashboard card
// No business logic should be in this component
// This component should only be responsible for rendering the card

const DashboardCardBase = ({ cardTitle,
    cardSubtitle,
    cardIcon,
    cardProgressLabel,
    onCardClick,
    cardStyle,
    cardProgressLabelStyle }) => {

    const handleCardClick = async () => {
        if (onCardClick) {
            onCardClick();
        }
    }

    return (
        <Card className={'cardContainer taxReturnCard'} onClick={handleCardClick} style={cardStyle}>
            <div className="cardContent">
                <div className="cardTitle">
                    <div className="cardIconContainer">
                        <img className="overHangImg" alt={`Icon for ${cardTitle} dashboard card`} src={cardIcon} />
                    </div>
                    <div className="cardTitleContainer">
                        <p>{cardTitle}</p>
                        <div className='cardSubtitle'>{cardSubtitle}</div>
                    </div>
                </div>
            </div>
            <div className="cardStatusContainer">
                <Divider />
                <div className="cardProgressLabelContainer">
                    <div>
                        <p style={cardProgressLabelStyle}>{cardProgressLabel}</p>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default DashboardCardBase;
