const passthroughFn = (year) => {
    const sections = [
        {
            title: 'Unreimbursed Business Expenses',
            isVisible: true,
            isOptionalSectionMerge: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    fields: [
                        { label: 'Confirm that all unreimbursed business expenses are:', name: 'confirm0', type: 'label', col: 12 },

                        { label: '1. Ordinary and necessary', name: 'confirm1Label', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'confirm1', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, wasCheckbox: true }, 

                        { label: '2. Not reimbursable by my employer/partnership (i.e. I would NOT have been reimbursed had I submitted this expense for reimbursement)', name: 'confirm2Label', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'confirm2', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, wasCheckbox: true },

                        { label: '3. Written documentation/contemporaneous records have been maintained and are available upon request', name: 'confirm3Label', type: 'label', col: 9 },
                        {
                            label: 'Yes/No', name: 'confirm3', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, wasCheckbox: true
                        }
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'lineSection',
                    fields: [
                        { label: 'Type of Expense', name: 'IFDSSBEI.0', type: 'freeText', class: 'labelRequired', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSSBEI.1', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSSBEI.1', type: 'money', isTotal: true, },
                        { type: 'lineButtons' }
                    ],
                    prePopulate: [
                        { label: 'Parking Fees & Tolls', prePopulateAugments: [{ name: 'IFDSSBEI.14' }] },
                        { label: 'Meals', prePopulateAugments: [{ name: 'IFDSSBEI.5' }] },
                        { label: 'Travel', prePopulateAugments: [{ name: 'IFDSSBEI.12' }] },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Expense Type', smallScreenLabel: 'Expenses Details', col: 7, colTablet: 3, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Expense Type', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDSSBEI.1', col: 2, colTablet: 2, label: '$ 0', year: 'prior' },
                            { type: 'totalMoney', name: 'C-IFDSSBEI.1', col: 2, colTablet: 2, label: '$ 0', year: 'current' },
                        ],
                        lineButtons: [
                            { button: 'moreLineItemGroup' },
                        ]
                    },
                },
            ]
        },
        {
            title: 'Vehicle Expenses',
            sectionId: 2,
            sectionType: 'newVehicleEntity',
            isVisible: true,
            isLineSection: true,
            isFormExpandable: true,
            isOptionalSectionMerge: true,
            entities: [],
        },
    ];

    const lineItemGroup = [
        {
            title: 'Passthrough Information',
            isVisible: true,
            sectionId: 1,
            groups: [
                // BH. field name does not exist yet IFDSFIDE.3 (Estate or Trust name) required if PY info existed
                // field does not exist yet IFDSPRTE.7(Corporation's name, address, city, state and ZIP code) required if PY info existed
                // field does not exist yet IFDSPRTE.7(Partnership's name, address, city, state and ZIP code) required if PY info existed
                {
                    groupId: 1,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    axcessGroup: {
                        pull: {
                            fromSections: ['General',],
                            fieldIdentifiers: ['IFDSPRTE.0', 'IFDSPRTE.7', 'IFDSSCPE.0', 'IFDSSCPE.7', 'IFDSFIDE.0', 'IFDSFIDE.3'],
                        },
                    },
                    fields: [
                        {
                            label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSPRTE.0', type: 'select', lookup: 'PAYER_TS', placeholder: 'Select', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSPRTE.0', 'IFDSSCPE.0', 'IFDSFIDE.0'],
                                fn: 'anyValidTSPull',
                            }
                        },
                        {
                            label: 'Passthrough Name', name: 'IFDSPRTE.7', type: 'freeText', placeholder: 'Enter Name', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSPRTE.7', 'IFDSSCPE.7', 'IFDSFIDE.3'],
                                fn: 'anyValidPull',
                            }
                        },
                        { label: 'Details', wrapLabel: 'View Unreimbursed Expenses Details', type: 'viewButton', buttonType: 'checkboxAndDetails', anchor: '', name: 'hasUnreimbursedExpenses', viewSectionId: 0, prior: true, isPriorEditable: true },
                        { label: 'Details', wrapLabel: 'View Vehicle Expenses Details', type: 'viewButton', buttonType: 'checkboxAndDetails', anchor: '', name: 'hasVehicleExpenses', viewSectionId: 1, prior: true, isPriorEditable: true },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    lineSections: sections,
                    entities: [],
                    entityIdentifiers: [
                        {
                            checkboxButtonName: 'hasUnreimbursedExpenses',
                            identifiers: [
                                {
                                    section: 'Deductions', id: '2106', matchType: 'first',
                                    matchOnKey: 'IFDSSBEG.10', matchOnSection: 'General', matchOnType: 'lineItems',
                                    matchToSection: 'General', matchToKey: 'IFDSPRTE.7', matchToType: 'fields',
                                    setSectionAsSubSection: 'Business Income and Expenses', checkWithSection: 'Business Income and Expenses',
                                },
                                {
                                    section: 'Deductions', id: '2106', matchType: 'first',
                                    matchOnKey: 'IFDSSBEG.10', matchOnSection: 'General', matchOnType: 'lineItems',
                                    matchToSection: 'General', matchToKey: 'IFDSSCPE.7', matchToType: 'fields',
                                    setSectionAsSubSection: 'Business Income and Expenses', checkWithSection: 'Business Income and Expenses',
                                },
                                {
                                    section: 'Deductions', id: '2106', matchType: 'first',
                                    matchOnKey: 'IFDSSBEG.10', matchOnSection: 'General', matchOnType: 'lineItems',
                                    matchToSection: 'General', matchToKey: 'IFDSFIDE.3', matchToType: 'fields',
                                    setSectionAsSubSection: 'Business Income and Expenses', checkWithSection: 'Business Income and Expenses',
                                },
                            ]
                        },
                        {
                            checkboxButtonName: 'hasVehicleExpenses',
                            identifiers: [
                                {
                                    section: 'Deductions', id: '2106', matchType: 'first',
                                    matchOnKey: 'IFDSSBEG.10', matchOnSection: 'General', matchOnType: 'lineItems',
                                    matchToSection: 'General', matchToKey: 'IFDSPRTE.7', matchToType: 'fields',
                                    checkWithSection: 'Listed Property Information',
                                },
                                {
                                    section: 'Deductions', id: '2106', matchType: 'first',
                                    matchOnKey: 'IFDSSBEG.10', matchOnSection: 'General', matchOnType: 'lineItems',
                                    matchToSection: 'General', matchToKey: 'IFDSSCPE.7', matchToType: 'fields',
                                    checkWithSection: 'Listed Property Information',
                                },
                                {
                                    section: 'Deductions', id: '2106', matchType: 'first',
                                    matchOnKey: 'IFDSSBEG.10', matchOnSection: 'General', matchOnType: 'lineItems',
                                    matchToSection: 'General', matchToKey: 'IFDSFIDE.3', matchToType: 'fields',
                                    checkWithSection: 'Listed Property Information',
                                },
                            ]
                        }
                    ],
                    subWorkSheets: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Passthrough Income Details', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Passthrough Name', col: 3, colTablet: 2, align: 'left' },
                            { label: 'Has Unreimbursed Expenses?', col: 3, colTablet: 1, align: 'left' },
                            { label: 'Has Vehicle Expenses?', col: 3, colTablet: 2, align: 'left' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Passthrough Income (K-1)', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: 'k1Uploads', viewSectionId: 2 },
                        ],
                    }
                }
            ]
        }
    ];

    return lineItemGroup;
};

export default passthroughFn;