import { Validators } from 'cla-formrenderer';

import * as EVENT from '@utilities/constants/triggerKeys';

const setChangeCountry = (event, options) => {
    const { sections, formSections, setFormSections, inputState } = options;
    const name = formSections ? formSections : sections;

    name[1].groups[0].fields[4].isVisible = event === EVENT.EVENT_KEY_UNITED_STATES;
    name[1].groups[0].fields[6].lookup = event === EVENT.EVENT_KEY_CANADA ? EVENT.EVENT_KEY_PROVINCES : EVENT.EVENT_KEY_STATES;
    name[1].groups[0].fields[6].isVisible = event === EVENT.EVENT_KEY_CANADA;
    name[1].groups[0].fields[5].isVisible = event !== EVENT.EVENT_KEY_CANADA && event !== EVENT.EVENT_KEY_UNITED_STATES ;


    //name[1].groups[0].fields[6].isVisible = event != EVENT_KEY_UNITED_STATES;
   // name[1].groups[0].fields[7].isVisible = event != EVENT_KEY_UNITED_STATES;

    name[1].groups[0].fields[3].default = event;
    const prevState = name[1].groups[0].fields[3].inputState || 0;
    name[1].groups[0].fields[3].inputState = (prevState & inputState);
    setFormSections(name);
};

const setIsMarried = (event, options) => {
    const { sections, formSections, setFormSections, inputState } = options;
    const name = formSections ? formSections : sections;
    // Array containing the event keys that match values indicating a married status
    const eventKeys = [ EVENT.EVENT_KEY_MARRIED ];

    name[2].isVisible = eventKeys.includes(event);
    name[0].groups[5].fields[1].default = event;
    const prevState = name[0].groups[4].fields[1].inputState || 0;

    name[0].groups[4].fields[1].inputState = (prevState & inputState);
    setFormSections(name);
};

const setHasIrsPinPayer = (event, options) => {
    const { sections, formSections, setFormSections, inputState } = options;
    const name = formSections ? formSections : sections;
    
    name[0].groups[4].fields[3].isVisible = event === EVENT.EVENT_KEY_YES;
    name[0].groups[4].fields[2].isVisible = event === EVENT.EVENT_KEY_YES;
    name[0].groups[4].fields[1].default = event;
    const prevState = name[0].groups[4].fields[1].inputState || 0;
    name[0].groups[4].fields[1].inputState = (prevState & inputState);
    
    setFormSections(name);
};

const setHasIrsPinSpouse = (event, options) => {
    const { sections, formSections, setFormSections, inputState } = options;
    const name = formSections ? formSections : sections;
    
    name[2].groups[4].fields[3].isVisible = event === EVENT.EVENT_KEY_YES;
    name[2].groups[4].fields[2].isVisible = event === EVENT.EVENT_KEY_YES;
    name[2].groups[4].fields[1].default = event;
    const prevState = name[2].groups[4].fields[1].inputState || 0;
    name[2].groups[4].fields[1].inputState = (prevState & inputState);
    
    setFormSections(name);
};

const setIFDSGEN19Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[1].groups[0].fields[0].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[1].groups[0].fields[0].validations = null;
        name[1].groups[0].fields[0].error = false;
        name[1].groups[0].fields[0].errorMessage = null;
    }

    setFormSections(name);
}

const setIFDSGEN21Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[1].groups[0].fields[2].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[1].groups[0].fields[2].validations = null;
        name[1].groups[0].fields[2].error = false;
        name[1].groups[0].fields[2].errorMessage = null;
    }

    setFormSections(name);
}

const setIFDSGEN22Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[1].groups[0].fields[4].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[1].groups[0].fields[4].validations = null;
        name[1].groups[0].fields[4].error = false;
        name[1].groups[0].fields[4].errorMessage = null;
    }

    setFormSections(name);
}

const setIFDSGEN23Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[1].groups[0].fields[7].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[1].groups[0].fields[7].validations = null;
        name[1].groups[0].fields[7].error = false;
        name[1].groups[0].fields[7].errorMessage = null;
    }

    setFormSections(name);
}

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
    case 'setChangeCountry':
        setChangeCountry(event, options);
        break;
    case 'setIsMarried':
        setIsMarried(event, options);
        break;
    case 'setHasIrsPinPayer':
        setHasIrsPinPayer(event, options);
        break;
    case 'setHasIrsPinSpouse':
        setHasIrsPinSpouse(event, options);
        break;
    case 'setIFDSGEN19Required':
        setIFDSGEN19Required(event, options);
        break;
    case 'setIFDSGEN21Required':
        setIFDSGEN21Required(event, options);
        break;
    case 'setIFDSGEN22Required':
        setIFDSGEN22Required(event, options);
        break;
    case 'setIFDSGEN23Required':
        setIFDSGEN23Required(event, options);
        break;
    default:
        break;
    }
};

const setMaritalStatus = (options) => {
    const { field, fns } = options;
    fns.setMaritalStatus(field.default);
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
    case 'setMaritalStatus':
        setMaritalStatus(options);
        break;
    default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};