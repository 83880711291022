import React from 'react'
import { getClientDetails } from '../utils/getClientDetails';
import './styles.scss';


function ErrorCodesPage({ orgExportErrors }) {
    return (
        <div className="pagebreak">
            <div className="grid-container">
                <div className="grid-child" style={{ width: 139 }}></div>
                <div className="grid-child">
                    <h2>Error Codes</h2>
                </div>
                <div className="grid-child clientDetailsContainer">
                    <span>{getClientDetails().clientName}</span>&nbsp;
                    <span>{getClientDetails().clientNumber}</span>
                </div>
            </div>
            <div className="tableHeader">
                <h4>Error</h4>
                <h4>Code</h4>
            </div>
            <hr />
            <div className="errorCodeDataTableBody">
                {
                    orgExportErrors.map((error, index) => {
                        if (index === 0) {
                            if (error.length <= 0) {
                                return null
                            }
                            return <div key={`Req`}>
                                <p>{error}</p>
                                <p>{`Req`}</p>
                            </div>
                        }
                        return <div key={`Err${index}`}>
                            <p>{error}</p>
                            <p>{`Err${index}`}</p>
                        </div>
                    })
                }
            </div>
        </div>
    )
}
export default ErrorCodesPage