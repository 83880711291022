const dependentsFn = (year) => {
    const sections = [
        [
            {
                title: 'Dependents Details',
                isVisible: true,
                sectionId: 4,
                isCollapsed: true,
                isDenseRow: true,
                groups: [
                    {
                        groupId: 1,
                        fields: [
                            {
                                label: 'First Name',
                                name: 'IFDSDEP.0',
                                type: 'freeText',
                                placeholder: 'Enter Name',
                                prior: true,
                                maxLength: 38,
                                isPriorEditable: true,
                                logicFunction: [
                                    {
                                        trigger: 'setConditionalFieldValidation',
                                        requiredFields: ['IFDSDEP.2', 'IFDSDEP.5', 'IFDSDEP.4', 'IFDSDEP.7']
                                    },
                                    { trigger: 'setDependentsSummaryLabel' }
                                ]
                            },
                            {
                                label: 'Initial',
                                name: 'IFDSDEP.1',
                                type: 'freeText',
                                placeholder: 'Enter Name',
                                prior: true,
                                maxLength: 1,
                                isPriorEditable: true,
                                logicFunction: [{ trigger: 'setDependentsSummaryLabel' }]
                            },
                            {
                                label: 'Last Name',
                                name: 'IFDSDEP.2',
                                type: 'freeText',
                                placeholder: 'Enter Name',
                                prior: true,
                                maxLength: 25,
                                isPriorEditable: true,
                                logicFunction: [{ trigger: 'setDependentsSummaryLabel' }]
                            },
                            {
                                label: 'Relation',
                                wrapLabel: 'Relation',
                                name: 'IFDSDEP.5',
                                type: 'select',
                                lookup: 'RELATIONSHIP',
                                placeholder: 'Enter Relationship',
                                prior: true,
                                isPriorEditable: true,
                                logicFunction: [{ trigger: 'setDependentsSummaryLabel' }]
                            },
                            {
                                label: 'SSN',
                                name: 'IFDSDEP.4',
                                type: 'ssn',
                                placeholder: 'Enter SSN',
                                prior: true,
                                overRideText: 'On File',
                                axcessTranslate: 'omitOnOverride',
                                isMaskOnBlur: true,
                            },
                            {
                                label: 'Birthdate',
                                name: 'IFDSDEP.7',
                                type: 'date',
                                placeholder: 'Enter Date',
                                prior: true,
                                maxDate: 'today',
                                axcessTranslate: 'date',
                                isPriorEditable: true,
                            },
                        ],
                    }
                ],
            },
        ]
    ];

    const lineItemGroup = [
        {
            title: 'Dependents',
            isVisible: true,
            sectionId: 1,
            hasDenseRow: true,
            groups: [
                {
                    groupId: 1,
                    groupType: 'lineItem',
                    axcessGroup: {
                        pull: {
                            fromSections: ['Dependents'],
                            fieldPrefixes: ['IFDSDEP'],
                            lineFilterType: 'omitAny',
                            lineFilters: [
                                {
                                    key: 'IFDSDEP.8', filterType: 'containsKey'
                                },
                            ],
                        },
                        
                    },
                    fields: [
                        {
                            label: '',
                            name: 'dependentSummary',
                            type: 'label',
                            styles: { whiteSpace: 'pre-line', paddingTop: 0, },
                            prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSDEP.5', 'IFDSDEP.0', 'IFDSDEP.1', 'IFDSDEP.2'],
                                fn: 'dependentsSummaryPull',
                            }
                        },
                        {
                            label: 'View Details',
                            name: 'additionalInfo',
                            type: 'viewButton',
                            viewSectionId: 100,
                        },
                        { label: '', name: '', type: 'label', },
                        {
                            label: 'Status',
                            name: 'status',
                            type: 'select',
                            lookup: 'DEP_STATUS',
                            placeholder: 'Select Status',
                            prior: true,
                            isPriorEditable: true
                        },
                        { label: '', name: '', type: 'label', },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    lineSections: sections[0],
                    entities: [],
                    currentEntity: -1,
                    subWorkSheets: [],
                    entityIdentifiers: [
                        {
                            axcessDataType: 'PassDataToChild',
                        },
                    ],
                    lineItemDetails: {
                        headerLabels: [
                            {
                                label: 'Dependent Summary',
                                smallScreenLabel: 'Dependent Information',
                                col: 3,
                                colTablet: 2,
                                colPhone: 2,
                                styles: { paddingLeft: '45px' }
                            },
                            {
                                label: 'Dependent Details',
                                col: 1,
                                colTablet: 1,
                                colPhone: 1,
                                align: 'left',
                                styles: { paddingLeft: '20px' }
                            },
                            { col: 1, colTablet: 0, },
                            {
                                label: 'Status',
                                col: 3,
                                colTablet: 3,
                                colPhone: 2,
                                styles: { paddingLeft: '35px' }
                            },
                            { col: 3, colTablet: 1, },
                            {
                                label: '',
                                col: 1,
                                colTablet: 1,
                                colPhone: 1,
                                styles: { paddingLeft: '8px' }
                            },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Dependent', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                },
                {
                    groupId: 2,
                    isSingleColumn: true,
                    groupType: 'dropdownGroup',
                    fields: [
                        {
                            label: 'My dependent(s) had investment income over $1,100.',
                            name: 'd1',
                            type: 'label',
                            col: 9
                        },
                        { label: 'Yes/No', name: 'd1Select', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },
                        { label: 'My dependent(s) had wages over $4,200.', name: 'd2', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'd2Select', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },
                        {
                            label: 'I paid for dependent care (day care, camp, afterschool) for my child or other dependent.',
                            name: 'd3',
                            type: 'label',
                            col: 9
                        },
                        // Changed from checkbox to select
                        // TODO: Verify if axcess pull functionality is working
                        {
                            label: 'Yes/No',
                            name: 'd3Select',
                            type: 'select',
                            lookup: 'YESNO_DONTKNOW',
                            col: 3,
                            prior: true,
                            isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCARE.5', 'IFDSCARE.6', 'IFDSQUAL.1'],
                                    fn: 'childCareExpensesPull',
                                }
                            }
                        },
                    ]
                }
            ]
        },
    ];

    return lineItemGroup;
};
export default dependentsFn;