function formatStatus(status) {
  // Return empty string if status is not a string or is null/undefined
  if (!status || typeof status !== 'string') {
    return '';
  }

  if (status.startsWith('Active')) {
    return 'Active';
  } else if (status.startsWith('Inactive')) {
    return 'Inactive';
  }

  return '';
}

export {
  formatStatus
}