import React, { useState, useEffect } from "react";
import { AppSizeListener } from "react-md";
import _ from "lodash";
import { frameworkSetter } from "../../hooks/usePageFramework";
import { lookupSetter } from "../../constants/lookupInfo";
import BookmarkMenu from "./../../components/bookmarkMenu";
import Collapse from "./collapsibleForm/index";
import BuildFormGroups from "./buildGroups";
import FormUploads from "./formUploads";
import { ErrorBoundary } from "react-error-boundary";
import updateTotals from '../../utils/updateTotals';
import { yearSetter } from '../../utils/getYear';
// import { triggeredEvent } from '../../components/formRenderer/triggers/index';

function FormRenderer(props) {
  const {
    sections,
    formName,
    parentFns,
    passFromEntity,
    hasLineItemSection,
    entityScrollTab,
    entityIndex,
    setUniqueEntityFieldValue,
    showSharedEntityDialog,
    isFormPresent,
    isFormExpandable,
    isLineItemSection,
    parentFormName,
    parentGroup,
    parentIndex,
    isScheduleFormSection,
    usePageFramework,
    renderEntity,
    triggered,
    formSections,
    setFormSections,
    saveForm,
    isFormNA,
    isFormDisabled,
    sectionFieldLogic,
    setHasLoadingError,
    setIsLoaded,
    mappedSections,
    lookups,
    grandParentGroup,
    containerStyles,
    isEntryInterview,
    bookmarkTitle,
    setBookmarkTitle,
    year,
    allSections
  } = props;
  const { screenSize, card } = usePageFramework(); // replace this imported function with the usePageFramework prop
  frameworkSetter.framework = usePageFramework;
  lookupSetter.lookups = lookups;
  yearSetter.year = year;
  const [denseRowSection, setDenseRowSection] = useState(sections);
  const [footerTotals, setFooterTotals] = useState([]);
  const visibleSections = sections.length ? sections?.filter(sec => sec?.isVisible) : [];
  const lastDenseRowSection = visibleSections[visibleSections?.length - 1];
  let isDenseRowNA = false;

  useEffect(() => {
    setDenseRowSection(sections); // Update if provided sections has changed
  }, [sections, footerTotals]);

  // const triggered = (trigger, event, {triggeredSections, triggeredFormSections, triggeredSetFormSections}) => {
  //   triggeredEvent(trigger, event, 
  //       {   sections: triggeredSections || sections, 
  //           formSections: triggeredFormSections || formSections, 
  //           setFormSections: triggeredSetFormSections || setFormSections, 
  //           // inputState,  
  //       }
  //   );
  // };

  useEffect(() => {
    if (parentGroup && parentGroup.summaryList) {
      updateTotals(parentGroup.summaryList.sections, setFooterTotals);
    } 
    
    if (grandParentGroup && grandParentGroup.summaryList) {
      updateTotals(grandParentGroup.summaryList.sections, setFooterTotals);
    }

    updateTotals(denseRowSection, setFooterTotals);
  }, [parentGroup, grandParentGroup, denseRowSection]);

  const save = (field, nextTabId, childFormSectionData) => {
    saveForm(field, nextTabId, childFormSectionData, denseRowSection, setDenseRowSection);
    if (parentFns) {
          updateTotals(denseRowSection, setFooterTotals);
          parentFns.saveForm(field, nextTabId, { childFormSections: denseRowSection });
      }
  };

  const LineItemSectiontriggered = (trigger, event, triggeredFormSection) => {
    triggered ? triggered(
      trigger, 
      event, 
      { 
        triggeredSections: sections, 
        triggeredFormSections: triggeredFormSection || denseRowSection, 
        triggeredSetFormSections: triggeredFormSection ? null : setDenseRowSection,
      }
    ) : sectionFieldLogic(trigger, 
      { 
        event: event,
        triggeredSections: sections, 
        triggeredFormSections: triggeredFormSection || denseRowSection, 
        triggeredSetFormSections: triggeredFormSection ? null : setDenseRowSection,
      });
};

const showSummaryViewHeaders = (summaryGroup, section) => {
    const visibleGroupItems = summaryGroup
      .map(group => group.groupItems?.map(item => item
          ?.filter(group => group.isVisible))
          .filter(items => items?.length > 0))
      .filter(items => items?.length > 0);
    
    if (section.title !== 'Compensation Details' && visibleGroupItems.length > 0) {
        section.headers?.forEach(header => header.isVisible = true);
    }
}

  const buildSections = () => {
    if (mappedSections) return mappedSections;
    else if (!mappedSections && denseRowSection) {
      return denseRowSection.map((section, index) => {
        isDenseRowNA =
          section?.isDenseRow &&
          parentGroup?.lineItems?.length &&
          parentGroup?.lineItems[parentIndex]
            ? parentGroup.lineItems[parentIndex][0]?.notApplicable
            : false;

        if (section?.sectionType) {
          return renderEntity(section, formName);
        }

        const isSectionVisible =
          typeof section?.isVisible === "undefined"
            ? true
            : typeof section?.isVisible === "function"
            ? section?.isVisible()
            : section?.isVisible;
        let sectionClass = "normalSection";

        if (!isSectionVisible) return null;

        if (section?.groups?.length === 0) {
          sectionClass = "";
        }

        const groups = section ? (
          <BuildFormGroups
            key={`build-form-groups-key-${section.sectionId}`}
            section={section}
            screenSize={screenSize}
            saveForm={save}
            isFormLocked={isFormDisabled}
            triggered={LineItemSectiontriggered}
            sectionClass={sectionClass}
            sectionIndex={index}
            entityScrollTab={entityScrollTab}
            entityIndex={entityIndex}
            setUniqueEntityFieldValue={setUniqueEntityFieldValue}
            showSharedEntityDialog={showSharedEntityDialog}
            parentGroup={parentGroup}
            parentIndex={parentIndex}
            isDenseRowNA={isDenseRowNA}
            sectionFieldLogic={sectionFieldLogic}
            renderEntity={renderEntity}
            setFormSections={setFormSections}
            grandParentGroup={grandParentGroup}
            allSections={allSections || sections}
          />
        ) : null;

        const key = new Date().getTime();
        let summaryGroup = section?.groups.filter(group => group.groupType === 'groupItem');
        if (summaryGroup) showSummaryViewHeaders(summaryGroup, section);
          
        return section ? (
          <React.Fragment key={`section-collapse-key-${section.sectionId}`}>
            <Collapse
              key={index}
              title={section.title}
              collapseClass={section.collapseClass || null}
              index={index}
              groups={groups}
              allSections={allSections || denseRowSection}
              section={section}
              sectionClass={sectionClass}
              fns={{
                save,
                setUniqueEntityFieldValue,
                showSharedEntityDialog,
              }}
              isFormPresent={isFormPresent}
              isFormLocked={isFormDisabled}
              screenSize={screenSize}
              triggered={LineItemSectiontriggered}
              sectionFieldLogic={sectionFieldLogic}
              hasLineItemSection={hasLineItemSection}
              entityIndex={entityIndex}
              isFormExpandable={isFormExpandable}
              lastDenseRowSection={lastDenseRowSection}
              parentFormName={parentFormName}
              isScheduleFormSection={isScheduleFormSection}
              bookmarkTitle={bookmarkTitle}
              parentGroup={parentGroup}
              bondID={section.bondID}
              parentIndex={parentIndex}
            />
          </React.Fragment>
        ) : null;
      });
    } else return null;
  }

  const resetErrorBoundary = () => {
    if (setHasLoadingError) {
      setHasLoadingError(true);
    }
    setIsLoaded(false);
  };

  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    console.log(error);
    resetErrorBoundary();
    return <></>;
  };

  return (
    <AppSizeListener>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={resetErrorBoundary}
      >
        <div>
          {!hasLineItemSection ? (
            <div>
              <BookmarkMenu
                sections={formSections}
                setFormSections={setFormSections}
                passFromEntity={passFromEntity}
                setBookmarkTitle={setBookmarkTitle}
              />
            </div>
          ) : (
            <></>
          )}
          {card?.showBulkEntityUpload && !isFormPresent ? (
            <FormUploads isWithinForm={true} entityIndex={entityIndex} isFormDisabled={isFormDisabled || formSections[0]?.isEntityNa}/>
          ) : (
            <></>
          )}
          {isFormPresent ? (
            buildSections()
          ) : (
            <div
              className={
                (isFormNA || isDenseRowNA
                  ? "formBuilderContainer notApplicableForm"
                  : isEntryInterview ? '' : "formBuilderContainer") +
                (isLineItemSection ? " isSectionVisible" : "")
              }
              style={containerStyles}
            >
              {isFormNA && <div className="watermark">Not Applicable</div>}
              {buildSections()}
            </div>
          )}
        </div>
      </ErrorBoundary>
    </AppSizeListener>
  );
}

export default FormRenderer;
