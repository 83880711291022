export function required(message = 'Required') {
    return {
        required: {
            message
        }
    };
}

export function email(message = 'Must be a valid email address!') {
    return {
        email: {
            message
        }
    };
}

export function minLength(length, message = `Must be at least ${length} characters.`) {
    return {
        minLength: {
            length,
            message
        }
    };
}

export function maxLength(length, message = `Must be ${length} character or no charaacters.`) {
    return {
        maxLength: {
            length,
            message
        }
    };
}

export function minDate(date, message) {
    const dateStart = date ? date === 'today' ? new Date() : new Date(date) : new Date('01-01-1900');

    return {
        minDate: {
            date,
            message: message ? message : `Must be ${dateStart.toLocaleDateString()} or later.`
        }
    };
}

export function maxDate(date, message) {
    const dateEnd = date ? date === 'today' ? new Date() : new Date(date) : new Date('12-31-2099');

    return {
        maxDate: {
            date,
            message: message ? message : `Must be ${dateEnd.toLocaleDateString()} or earlier.`
        } 
    };  
}

export function pattern(pattern, message) {
    return {
        pattern: {
            pattern,
            message
        }
    };
}

export function year(message = 'Must be from 1950 to current.') {
    return {
        year: {
            message
        }
    };
}

export function ssn(message = 'Must be 9 digits.') {
    return {
        ssn: {
            message
        }
    };
}

export function fixLength(length, message = `Must be ${length} characters.`) {
    return {
        fixLength: {
            length,
            message
        }
    };
}

export function ein(message = 'Must be 9 digits.') {
    return {
        ein: {
            message
        }
    };
}

export function maxValue(value, message = `Maximum value that can be entered is ${value}`) {
    return {
        maxValue: {
            value,
            message
        }
    };
}

export function isRoutingNumber(message = 'Invalid check digit, or only 9 digits starting with 01-12 or 21-32') {
    return {
        routingNumber: {
            message
        }
    };
}