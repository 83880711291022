import { cloneDeep } from 'lodash';
import { Validators } from 'cla-formrenderer';

import { setConditionalFieldValidation } from '@components/formRenderer/triggers';
import * as EVENT from '@utilities/constants/triggerKeys';

import { RefundsPaymentsGroupIds, BankAccountInfoFieldDetails, ifdsdird2FieldName, taxPreparerOptionFieldName } from './refundPayments.config';

let hasPYdata = false;
let isRequiresChange = false;

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'setConditionalFieldValidation':
            setConditionalFieldValidation(options);
            break;

        case 'showBankingInfoQuestion':
            showBankingInfoQuestion(options);
            break;

        case 'confirmRoutingNumberValidation':
            confirmRoutingNumberValidation(options);
            break;

        case 'confirmAccountNumberValidation':
            confirmAccountNumberValidation(options);
            break;

        default:
            break;
    }
};

const showBankingInfoQuestion = (options) => {
    const { group, section } = options;
    if (group.lineItems.length) {
        hasPYdata = group.lineItems[0]?.some(field => field.priorYearValue === field.default);
        const bankConfirmationGroup = section.groups.find(x => x.groupId === RefundsPaymentsGroupIds.BankInformationConfirmation);
        if (bankConfirmationGroup) {
            bankConfirmationGroup.isVisible = hasPYdata;
            const confirmRequiresChangeValue = bankConfirmationGroup?.fields.find(x => x.name === 'bankingInfo')?.default;
            if (confirmRequiresChangeValue === EVENT.KEY_REQUIRES_CHANGE) {
                isRequiresChange = true;
            }
        }

    } else {
        hasPYdata = false;
    }

};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'isConfirmRequiresChange':
            isConfirmRequiresChange(event, options);
            break;

        case 'isUseDirectDepositOptionChanged':
            isUseDirectDepositOptionChanged(event, options);
            break;

        case 'isTaxPreparerOptionChanged':
            isTaxPreparerOptionChanged(event, options);
            break;

        default:
            break;
    }
};

const isUseDirectDepositOptionChanged = (event, options) => {
    const { sections, formSections } = options;
    if (!sections || !formSections) return;
    const form = formSections ? formSections : sections;
    const foundGroup = form[0].groups.find(x => x.groupId === RefundsPaymentsGroupIds.SelectAllApply);
    const taxPreparerOptionValue = foundGroup?.fields.find(field => field.name === taxPreparerOptionFieldName).default;
    changeBankAccountIsRequiredState(options, event, taxPreparerOptionValue);
}

const isTaxPreparerOptionChanged = (event, options) => {
    const { sections, formSections } = options;
    if (!sections || !formSections) return;
    const form = formSections ? formSections : sections;
    const foundGroup = form[0].groups.find(x => x.groupId === RefundsPaymentsGroupIds.SelectAllApply);
    const IFDSDIRD2Value = foundGroup?.fields.find(field => field.name === ifdsdird2FieldName).default;
    changeBankAccountIsRequiredState(options, IFDSDIRD2Value, event);
}

const isBankAccountInfoRequired = (IFDSDIRD2, taxPreparerOption) => {
    const IFDSDIRD2Value = IFDSDIRD2.trim();
    const taxPreparerOptionValue = taxPreparerOption.trim();
    if (IFDSDIRD2Value === '' || taxPreparerOptionValue === '') {
        return true;
    }
    return IFDSDIRD2Value === EVENT.EVENT_KEY_YES || taxPreparerOptionValue === EVENT.EVENT_KEY_YES;
}

const applyBankAcctInfoValidation = (bankInfoformComponents, isRequired) => {
    bankInfoformComponents.forEach(x => {
        x.required = isRequired;

        if (x.validations && x.validations.hasOwnProperty('required')) {
            delete x.validations.required;
            if (Object.keys(x.validations).length === 0)
                x.validations = null;
        }

        if (isRequired) {
            x.validations = {
                ...x.validations,
                ...Validators.required()
            };
        }

        if (x.error && !isRequired) {
            x.error = false;
            x.errorMessage = ''
        }
    });
}

const changeBankAccountIsRequiredState = (options, IFDSDIRD2, taxPreparerOption) => {
    const { sections, formSections, setFormSections } = options;
    const form = formSections ? formSections : sections;
    const isRequired = isBankAccountInfoRequired(IFDSDIRD2, taxPreparerOption);
    if(!(form && form.length && form[0].groups)){
        return;
    }
    const bankInfoForm = form[0].groups.find(x => x.groupId === RefundsPaymentsGroupIds.BankInformation);
    const bankAcctInfoGroupLineItems = bankInfoForm.lineItems;
    const editableLineItem = bankAcctInfoGroupLineItems[bankAcctInfoGroupLineItems.length - 1];
    const bankInfoformComponents = BankAccountInfoFieldDetails
        .map(field => editableLineItem.find(x => x.name === field.name));

    applyBankAcctInfoValidation(bankInfoformComponents, isRequired);

    setFormSections(form);
}

const isConfirmRequiresChange = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    const confirmed = event === EVENT.KEY_CONFIRMED;
    const blank = event === ' ';
    const requiresChange = event === EVENT.KEY_REQUIRES_CHANGE;

    if (confirmed || blank) {
        name[0].groups[2].lineItems[0][0].notApplicable = false;
        if (name[0].groups[2].lineItems.length === 2) {
            name[0].groups[2].lineItems.pop();
        } else if (name[0].groups[2].lineItems.length > 2) {
            name[0].groups[2].lineItems.splice(1, name[0].groups[2].lineItems.length - 1);
        }
        isRequiresChange = false;
    }

    if (requiresChange) {
        const bankAcctInfoGroupLineItems = name[0].groups[2].lineItems;
        if (bankAcctInfoGroupLineItems.length === 1) {
            bankAcctInfoGroupLineItems[0][0].notApplicable = true;
            bankAcctInfoGroupLineItems[0][1].error = false;
            bankAcctInfoGroupLineItems[0][2].error = false;
            const newRow = cloneDeep(name[0].groups[2].fields);
            bankAcctInfoGroupLineItems.push(newRow);
        }
        else if (bankAcctInfoGroupLineItems.length > 2) {
            bankAcctInfoGroupLineItems[0][0].notApplicable = true;
            bankAcctInfoGroupLineItems[0][1].error = false;
            bankAcctInfoGroupLineItems[0][2].error = false;
            bankAcctInfoGroupLineItems.splice(1, name[0].groups[2].lineItems.length - 2);
        }
        const editableLineItem = bankAcctInfoGroupLineItems[bankAcctInfoGroupLineItems.length - 1];
        
        if (editableLineItem) {
            const selectionGroupFields = name[0].groups[1].fields;
            const ifdsdird2Value = selectionGroupFields.find(field => field.name === ifdsdird2FieldName).default;
            const taxPreparerOptionValue = selectionGroupFields.find(field => field.name === taxPreparerOptionFieldName).default;
            
            if (ifdsdird2Value && taxPreparerOptionValue) {
                const isBankAcctInfoRequired = isBankAccountInfoRequired(ifdsdird2Value, taxPreparerOptionValue);
                const bankInfoformComponents = BankAccountInfoFieldDetails.map(field => editableLineItem.find(y => y.name === field.name));
                applyBankAcctInfoValidation(bankInfoformComponents, isBankAcctInfoRequired);
            }
        }

        isRequiresChange = true;
    }
    confirmAccountNumberValidation(options);
    confirmRoutingNumberValidation(options);

    setFormSections(name);
};

const confirmRoutingNumberValidation = (options) => {
    let { section, formSections } = options;
    if (formSections){
        section = formSections[0];
    }

    if (!(section && section.groups)) {
        return;
    }

    const bankInformationGroup = section.groups.find(x => x.groupId === RefundsPaymentsGroupIds.BankInformation);
    const isLineItemExist = bankInformationGroup?.lineItems.length;

    if (isLineItemExist) {
        const editableLineItem = bankInformationGroup?.lineItems.length - 1;
        const editableBankInfoLineId = bankInformationGroup?.lineItems.length && hasPYdata && isRequiresChange ? editableLineItem : 0;
        const routingNumberField = bankInformationGroup?.lineItems[editableBankInfoLineId].find(lineItem => lineItem.name === 'IFDSDIRD.4');

        const bankInformationVerificationGroup = section.groups.find(x => x.groupId === RefundsPaymentsGroupIds.BankInformationVerification);
        const verifyRoutingNumberField = bankInformationVerificationGroup?.fields.find(x => x.name === 'VerifyRoutingNo');

        if (routingNumberField?.default === verifyRoutingNumberField?.default || routingNumberField?.notApplicable) {
            if (!routingNumberField.errorMessage) {
                routingNumberField.error = false;
                routingNumberField.errorMessage = "";
            }

            verifyRoutingNumberField.error = false;
        } else {
            routingNumberField.error = true;

            verifyRoutingNumberField.error = true;
            verifyRoutingNumberField.errorMessage = "Routing No does not match.";
        }
    }
}

const confirmAccountNumberValidation = (options) => {
    let { section, formSections } = options;
    if (formSections){
        section = formSections[0];
    }

    if (!(section && section.groups)) {
        return;
    }

    const bankInformationGroup = section.groups.find(x => x.groupId === RefundsPaymentsGroupIds.BankInformation);
    const isLineItemExist = bankInformationGroup?.lineItems.length;

    if (isLineItemExist) {
        const editableLineItem = bankInformationGroup?.lineItems.length - 1;
        const editableBankInfoLineId = bankInformationGroup?.lineItems.length && hasPYdata && isRequiresChange ? editableLineItem : 0;
        const accountNumberField = bankInformationGroup?.lineItems[editableBankInfoLineId].find(x => x.name === 'IFDSDIRD.5');

        const bankInformationVerificationGroup = section.groups.find(x => x.groupId === RefundsPaymentsGroupIds.BankInformationVerification);
        const verifyAccountNumberField = bankInformationVerificationGroup?.fields.find(x => x.name === 'VerifyAccountNo');

        if (accountNumberField?.default === verifyAccountNumberField?.default) {
            if (!accountNumberField.errorMessage) {
                accountNumberField.error = false;
                accountNumberField.errorMessage = "";
            }

            verifyAccountNumberField.error = false;
        } else {
            accountNumberField.error = true;

            verifyAccountNumberField.error = true;
            verifyAccountNumberField.errorMessage = "Account No does not match.";
        }
    }
}

export {
    sectionFieldLogic,
    triggeredEvent
};