import { useState, useEffect} from 'react';
import { Checkbox, Grid, GridCell } from 'react-md';
// import { isFieldDisabled } from '../fields/utils/isDisabled';
import { renderLabel } from '../fields/utils/isRequired';

function MultiSelect(props) {
    const { field, colspan, fns, index, group, parentGroup , parentIndex, section, grandParentGroup} = props;
    const [currentField, setCurrentField] = useState(field);
    
    let count = 0;
    const checkboxLabels = group.fields
        ?.filter(item => item.type === 'checkboxLabel').length;
        
    if (checkboxLabels < 1)
        count = field.type === 'checkbox' ? `${index + 1}.` : '';
    else {
        // const filtered = group.fields.filter(item => item.type === 'checkbox');
        // const index = filtered.indexOf(field);
        count = '';
    }
    
    useEffect(()=>{
        setCurrentField(field);
    },[field]);
    
    if (!field) return (null);

    const isSingleColumn = field.isSingleColumn;
    const isBlueBox = field.isBlueBox;
    const defaultChecked = currentField && currentField.default ? 'defaultChecked' : null;
    const style = field.name === 'confirm2' ? {marginBottom: '10px'} : null;

    const onClicked = (event) => {

        field.default = event.target.checked;
        if(field.default){
            group.fields.forEach((singleField, index) =>{
                //eslint-disable-next-line
                if(field.isOddOne && !singleField.isOddOne || !field.isOddOne && singleField.isOddOne){
                    singleField.disabled = true;
                }else{
                    singleField.disabled = false;
                }
            });
        }else{
            group.fields.forEach((singleField, index) =>{
                singleField.disabled = false;
            });
        }
        setCurrentField(prevState => {
            prevState.default = event.target.checked;
            
            return {...prevState};
        });

        const triggeredSections = section.isDenseRow ? null : {};

        if (field.triggered && !parentGroup?.isSummaryRow) {
            if (typeof (field.triggered) === 'string') {
                fns.triggered(field.triggered, field.default, triggeredSections);
            } else {
                field.triggered(field.default);
            }
        }

        if (field.logicFunction && !parentGroup?.isSummaryRow) {
            field.logicFunction.forEach((logic) => {
                logic.isDifferentGroup ? fns.triggered(logic.trigger, field.default, triggeredSections) :
                    fns.sectionFieldLogic(logic?.trigger, {group, field, index, logic, parentGroup, parentIndex, section, fns});
            });
        }

        //for syncing summary view changes to individual view 
        if (parentGroup?.isSummaryRow && grandParentGroup) {
            grandParentGroup.entities[index]?.sections
                ?.find(entitySection => entitySection.title === section.title)
                ?.groups.forEach((entityGroup) => {
                    entityGroup.fields.forEach((entityField) => {
                        if (entityField.name === field.name) {
                            entityField.default = field.default;
                            if (entityField.logicFunction) {
                                entityField.logicFunction.forEach((logic) => {
                                    logic.isDifferentGroup ? fns.triggered(logic.trigger, entityField.default, grandParentGroup.entities[index]?.sections) :
                                        fns.sectionFieldLogic(logic?.trigger, {group, field: entityField, index, logic, parentGroup: grandParentGroup, parentIndex: index, section, fns});
                                });
                            }
                            if (entityField.triggered) {
                                fns.triggered(entityField.triggered, entityField.default, grandParentGroup.entities[index]?.sections);
                            }
                        }
                    })
                });
        }

        if (parentGroup?.bonds) {
            parentGroup.bonds[parentIndex].sections
                ?.find(bondSection => bondSection.title === section.title)
                ?.groups.forEach(bondGroup => {
                    bondGroup.fields.forEach(bondField => {
                        if (bondField.name === field.name) {
                            bondField.default = field.default;
                            if (bondField.logicFunction) {
                                bondField.logicFunction.forEach(logic => {
                                    logic.isDifferentGroup ? fns.triggered(
                                        logic.trigger,
                                        bondField.default,
                                        parentGroup.bonds[parentIndex]?.sections
                                    ) : fns.sectionFieldLogic(
                                        logic?.trigger,
                                        {
                                            group,
                                            field: bondField,
                                            index,
                                            logic,
                                            parentGroup,
                                            parentIndex,
                                            section,
                                            fns
                                        }
                                    )
                                })
                            }
                        }
                    })
                });
        }
        
        fns.saveForm(currentField);
    };

    // const handleDisable = (isFormLocked, field, group, index, parentGroup, parentIndex, section) => {
    //     return field.disabled || isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section);
    // }

    const getCheckboxLabel = () => {
        if (field.name === 'confirm2') {
            // Extract texts between parenthesis
            const subText = field.label
                .substring( field.label.indexOf('('), field.label.indexOf(')') + 1 );
            const label = field.label.replace(subText, '');
            
            return (
                <label 
                    className={
                        isBlueBox ? 'multiSelectText2' : 'multiSelectSingleText'
                    }
                >
                    {
                        defaultChecked ?
                            <strong>{count} {label} </strong> : 
                            field.type === 'checkboxLabel' ? 
                                <p className="checkboxLabel">{label} </p> :
                                `${count} ${label}` 
                    }
                    <div 
                        className="labelSubText"
                    >
                        {subText}
                    </div>
                </label>
            );
        } else {
            return (
                <label className={isBlueBox ? 'multiSelectText2' : 'multiSelectSingleText'}>
                    {
                        defaultChecked ?
                            <strong>{count} {field.label} </strong> : 
                            field.type === 'checkboxLabel' ? 
                                <p className="checkboxLabel">{field.label} </p> :
                                `${count} ${field.label}` 
                    }
                    
                </label>
            );
        }
    };

    const checkbox = (
        field.isFieldCheckbox ?
            <GridCell colSpan={2}>
                <Checkbox
                    id={`chk-${field.name}`}
                    key={`chk-${field.name}`}
                    name={field.name}
                    label={renderLabel(field)}
                    onChange={(event) => onClicked(event)}
                    checked= {field.default}
                    className={field.class || 'lineCheckbox'}
                    disabled
                />
            </GridCell>
            :
            <Grid 
                className={isBlueBox ? 'multiSelectLine' : 'multiSelectSingle'}
                style={style}
            >
                <GridCell colSpan={colspan} className="gridCellCheckBoxContainer">
                    {getCheckboxLabel()}
                    {
                        field.type === 'checkbox' ?
                            <Checkbox
                                id={`chk-${field.name}`}
                                key={`chk-${field.name}`}
                                name={field.name}
                                onClick={(event) => onClicked(event)}
                                defaultChecked={defaultChecked}
                                className="multiSelectCheckbox"
                                disabled
                            /> : ''
                    }
                </GridCell>
                {/* <GridCell colSpan={12}>
                    <div className= {isBlueBox ? 'multiSelectBlueDivider' : ''}></div>
                </GridCell> */}
            </Grid>
    );

    if (isSingleColumn) return checkbox;

    return (<GridCell colSpan={colspan} key={`cell-${field.name}`}>{checkbox}</GridCell>);
}

export default MultiSelect;