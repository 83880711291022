import { useState, useEffect } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import EntityRenderer from '@components/entityRenderer/index';
import { triggeredEvent, sectionFieldLogic } from './businessIncomeLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import * as NAV from '@utilities/constants/navigation';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import businessIncome2021 from './2021';
import businessIncome2022 from './2022';
import businessIncome2023 from './2023';
import businessIncome2024 from './2024';

let sections = [];

export const getBusinessIncomeSections = () => {
    return sections;
};

function BusinessIncome(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.BUSINESS_INCOME);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: businessIncome2021(year),
            2022: businessIncome2022(year),
            2023: businessIncome2023(year),
            2024: businessIncome2024(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);
        sections = formSections;
        setSectionState(formSections);
        // eslint-disable-next-line
    }, []);

    const axcessIdentifiers = [
        { section: 'Income', id: 'Sch C' },
    ];

    const spreadIdentifiers = [
        { section: 'Income', id: 'Other Income (1099-G, 1099-K, 1099-MISC, 1099-NEC, W-2G)' },
    ];

    return (
        !sectionState ? <></> :
            <EntityRenderer sections={sectionState} key="businessIncomeForm" formName={REDUX.BUSINESS_INCOME} identifiers={axcessIdentifiers} spreadIdentifiers={spreadIdentifiers} triggeredEvent={triggeredEvent} sectionFieldLogic={sectionFieldLogic}
                isExportForm={isExportForm}
            />
    );
}

export default BusinessIncome;