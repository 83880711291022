import { Validators } from 'cla-formrenderer';
import {
    MORTGAGE_INTEREST_FIN_INST_UPLOAD,
    MORTGAGE_INTEREST_OTHER_MORTGAGE_UPLOAD,
    MORTGAGE_INTEREST_INVESTMENT_INT_UPLOAD,
} from '@utilities/constants/strings';

const mortgageFn = (year) => {
    const notToFinancialMortgageDenseSection = [
        {
            title: 'Lender Summary',
            sectionId: 1,
            isVisible: true,
            isCollapsed: true,
            isDenseRow: true,
            groups: [
                {
                    groupId: 1,
                    isVisible: true,
                    fields: [
                        { 
                            name: 'IFDSIDHM.0', 
                            type: 'freeText', 
                            prior: true, 
                            label: 'Mortgage Lender', 
                            maxLength: 27, 
                            col: 3, 
                            logicFunction: [{ trigger: 'setMortgageInfoLabel', }], 
                            isPriorEditable: true
                        },
                        {
                            label: 'Identification Type', name: 'mortgageLenderIdType', type: 'select', lookup: 'STATES_ID_TYPE', triggered: 'setMortgageLenderIdField', col: 2,
                            logicFunction: [{ trigger: 'setMortgageInfoLabel', }], prior: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSIDHM.5', 'IFDSIDHM.1'],
                                    fn: 'mortgageIdTypePull',
                                }
                            }
                        },
                        {
                            name: 'IFDSIDHM.1SSN', type: 'ssn', prior: true, label: 'SSN', overRideText: 'ON FILE', maxLength: 9, axcessTranslate: 'omitOnOverride', isMaskOnBlur: true,
                            validations: { ...Validators.ssn() }, col: 2, isVisible: false, logicFunction: [{ trigger: 'setMortgageInfoLabel', }],
                            axcess: {
                                pull: {
                                    fields: ['IFDSIDHM.5', 'IFDSIDHM.1'],
                                    fn: 'mortgageSSNIdPull',
                                }
                            }
                        },
                        {
                            name: 'IFDSIDHM.1EIN', type: 'ein', prior: true, label: 'EIN', overRideText: 'ON FILE', maxLength: 9, axcessTranslate: 'omitOnOverride', isMaskOnBlur: true,
                            validations: { ...Validators.ein() }, col: 2, isVisible: false, logicFunction: [{ trigger: 'setMortgageInfoLabel', }],
                            axcess: {
                                pull: {
                                    fields: ['IFDSIDHM.5', 'IFDSIDHM.1'],
                                    fn: 'mortgageEINIdPull',
                                }
                            }
                        },
                    ]
                },
                {
                    groupId: 2,
                    isVisible: true,
                    fields: [
                        { label: 'Country', name: 'IFDSIDHM.19', type: 'select', lookup: 'FOREIGN_COUNTRIES', default: 'US', prior: true, triggered: 'setMortgageLenderCountry', col: 3, axcessTranslate: 'foreignCountry', isPriorEditable: true, },
                        { label: 'Street Address', name: 'IFDSIDHM.2', type: 'freeText', prior: true, maxLength: 36, col: 3, isPriorEditable: true, },
                        { label: 'City', name: 'IFDSIDHM.12', type: 'freeText', placeholder: 'Enter City', prior: true, maxLength: 35, col: 2, isPriorEditable: true, },
                        { label: 'State', name: 'IFDSIDHM.13', type: 'select', lookup: 'STATES', placeholder: 'Select State', prior: true, col: 2, isPriorEditable: true, },
                        { label: 'Province / State', name: 'IFDSIDHM.23', type: 'freeText', isVisible: false, col: 2, prior: true, isPriorEditable: true, },
                        { label: 'Zip', wrapLabel: 'Zip Code', name: 'IFDSIDHM.14', type: 'zippostal', placeholder: 'Enter Zip Code', prior: true, col: 2, isPriorEditable: true, },
                    ]
                },
            ],
        },
    ];

    const sections = [
        {
            title: 'General Mortgage Information',
            shortTitle: 'General Mortgage',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    fields: [
                        // TODO: This question needs to change and reflect the current tax year
                        { label: `Did you refinance your home mortgage during ${year.current}?`, name: 'generalMortgage', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'genMortgageSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Is the outstanding mortgage balance on your first and/or second residences greater than $750,000?', name: 'generalMortgageLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'generalMortgageSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, triggered: 'setMortgageBalanceLabels' },
                        { label: `Balance of mortgage on January 1st, ${year.current} `, name: 'generalMortgageLabel1', type: 'label', col: 9, isVisible: false },
                        { label: 'Amount', name: 'genMortgageAmount', type: 'money', isTotal: true, col: 3, isVisible: false },
                        { label: `Balance of mortgage on December 31st, ${year.current} `, name: 'generalMortgageLabel2', type: 'label', col: 9, isVisible: false },
                        { label: 'Amount', name: 'genMortgageAmount2', type: 'money', isTotal: true, col: 3, isVisible: false },

                        { label: 'Do you have a home equity loan?', name: 'generalMortgageQuestion', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'generalMortgageSelect1', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, triggered: 'setHomeEquityLoanLabels' },
                        { label: `Balance of home equity loan on January 1st, ${year.current}`, name: 'generalMortgageLabel3', type: 'label', col: 9, isVisible: false },
                        { label: 'Amount', name: 'genEquityAmount1', type: 'money', isTotal: true, col: 3, isVisible: false },
                        { label: `Balance of home equity loan on December 31st, ${year.current} `, name: 'generalMortgageLabel4', type: 'label', col: 9, isVisible: false },
                        { label: 'Amount', name: 'genEquityAmount2', type: 'money', isTotal: true, col: 3, isVisible: false },
                    ],
                },
            ],
        },
        {
            title: 'Home Mortgage Interest Paid to Financial Institutions',
            shortTitle: 'Home Mortgage Interest',
            isVisible: true,
            collapseClass: 'collapsibleForm',
            sectionId: 2,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupId: 1,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    axcessGroup: {
                        pull: {
                            fromSections: [
                                'Mortgage Interest (IRS 1098-MIS)',
                                'Home Mortgage Interest Paid to a Financial Institution and Deductible Points'
                            ],
                            fieldPrefixes: ['IFDS1098', 'IFDSIDHI'],
                            lineFilterType: 'omitAny',
                            lineFilters: [
                                { key: 'IFDSIDHI.10', filterType: 'containsKey' },
                            ],
                        },
                    },
                    fields: [
                        {
                            label: 'Mortgage Lender', name: 'IFDS1098.5', type: 'freeText', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDS1098.5'],
                                fn: 'anyValidPull',
                                defaultIfEmpty: 'Other Lender',
                            }
                        },
                        { label: 'Yes/No', name: 'mortgageInterestHomeEquityLine', type: 'select', lookup: 'YESNO', wrapLabel: 'Home Equity Line?' },
                        {
                            label: '$ 0', name: 'IFDS1098.14', type: 'label', isTotal: true, wrapLabel: '2019 Amount', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDS1098.14', 'IFDSIDHI.0'],
                                fn: 'anyValidPull',
                            }
                        },
                        { label: 'Amount', name: 'C-IFDS1098.14', type: 'money', isTotal: true, wrapLabel: '2020 Amount' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Mortgage Lender', smallScreenLabel: 'Home Mortgage Details', col: 5, colTablet: 2 },
                            { label: 'Home Equity Line?', col: 2, colTablet: 1 },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: 'Form 1098', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Home Mortgage Interest Paid to Financial Institutions', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3, },
                            { type: 'totalMoney', name: 'IFDS1098.14', year: 'prior', col: 2, label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDS1098.14', year: 'current', col: 2, label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: MORTGAGE_INTEREST_FIN_INST_UPLOAD, viewSectionId: 0 },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Other Mortgage Interest Paid (Not to a Financial Institution)',
            shortTitle: 'Other Mortgage Interest Paid',
            isVisible: true,
            sectionId: 3,
            hasDenseRow: true,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Other Home Mortgage Interest Paid'],
                            fieldPrefixes: ['IFDSIDHM'],
                        }
                    },
                    fields: [
                        {
                            label: '', name: 'lenderSummary', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, }, prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSIDHM.0', 'IFDSIDHM.5', 'IFDSIDHM.1'],
                                fn: 'mortgageLenderSummaryPull',
                            }
                        },
                        { label: 'Details', name: 'mortgageInterestAdditionalInfo', type: 'viewButton', viewSectionId: 100, },
                        { label: '', name: '', type: 'label', },
                        { name: 'IFDSIDHM.4', type: 'label', isTotal: true, label: '$ 0', prior: true },
                        { name: 'C-IFDSIDHM.4', type: 'money', isTotal: true, label: 'Amount' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    lineSections: notToFinancialMortgageDenseSection,
                    entities: [],
                    currentEntity: -1,
                    subWorkSheets: [],
                    entityIdentifiers: [
                        {
                            axcessDataType: 'PassDataToChild',
                        },
                    ],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Lender Summary', col: 3, colTablet: 2, align: 'left', styles: { paddingLeft: '44px' } },
                            { label: 'Lender Details', col: 1, colTablet: 1, align: 'left', styles: { paddingLeft: '21px' } },
                            { col: 3, colTablet: 0, },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right', styles: { paddingRight: '7px' } },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right', styles: { paddingRight: '13px' } },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft', styles: { paddingLeft: '8px' } },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Mortgage Interest Paid (Not to a Financial Institution)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 4, },
                            { type: 'totalMoney', name: 'IFDSIDHM.4', col: 2, colTablet: 1, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSIDHM.4', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: MORTGAGE_INTEREST_OTHER_MORTGAGE_UPLOAD, viewSectionId: 0 },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Investment Interest (Interest Paid on Funds Used to Purchase Taxable Investments)',
            shortTitle: 'Investment Interest',
            isVisible: true,
            sectionId: 4,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Investment and Other Interest'],
                            fieldPrefixes: ['IFDSIDII'],
                            lineFilterType: 'omitAny',
                            lineFilters: [
                                { key: 'IFDSIDII.2', filterType: 'containsAny', filterData: ['Investment interest carryover'] },
                            ],
                        },
                    },
                    fields: [
                        { name: 'IFDSIDII.1', type: 'freeText', prior: true, label: 'Paid To', maxLength: 76},
                        { name: 'IFDSIDII.3', type: 'label', isTotal: true, label: '$ 0', wrapLabel: '2019 Amount', prior: true },
                        { name: 'C-IFDSIDII.3', type: 'money', isTotal: true, label: 'Amount', wrapLabel: '2020 Amount' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Paid To', smallScreenLabel: 'Investment Interest Details', col: 6, colTablet: 3, },
                            { label: `${year.prior} Amount`, col: 3, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Investment Interest (Interest Paid on Funds Used to Purchase Taxable Investments)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 6, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDSIDII.3', col: 3, colTablet: 2, year: 'prior', label: '$ 0', isTotal: true },
                            { type: 'totalMoney', name: 'C-IFDSIDII.3', col: 2, colTablet: 2, year: 'current', label: '$ 0', isTotal: true },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: MORTGAGE_INTEREST_INVESTMENT_INT_UPLOAD, viewSectionId: 0 },
                        ],
                    },
                },
            ],
        },
    ];

    return sections;
};

export default mortgageFn;