import _ from 'lodash';
import { filterPYByIdentifier, findFromData, findSection, getPYCalculationFunction, hasMatchingKeyInField, hasMatchingKeyInLineItems, hasMatchingPYKey, translatePullData } from '@utilities/axcessTaxPull.js';
import { filterPYFromIdentifiers, isMatchingEntity } from '@utilities/populatePriorData/parsePriorEntityData.js';

const MATCH_TYPE_FIRST = 'first';
const businessVehicleTemplate = { templateName: 'Business Vehicle', fieldIdTranslations: 'businessIncome', section: 'Income', id: 'Sch C - Business', worksheet: 'Auto Information, Depreciation, and Listed Property Questions' };
const rentalVehicleTemplate = { templateName: 'Rental Vehicle', fieldIdTranslations: 'rentalIncome', section: 'Income', id: 'Sch E, p 1 - Rent and Royalty', worksheet: 'Auto Information, Depreciation, and Listed Property Questions' };
const rentalTemplate = { templateName: 'Rental Income', fieldIdTranslations: 'rentalIncome', section: 'Income', id: 'Sch E, p 1 - Rent and Royalty', worksheet: 'General' }
const farmVehicleTemplate = { templateName: 'Farm Vehicle', fieldIdTranslations: 'farmIncome', section: 'Income', id: 'Sch F / 4835 - Farm', worksheet: 'Auto Information, Depreciation, and Listed Property Questions' };
const passthoughVehicleTemplate = { templateName: 'Passthrough Vehicle', fieldIdTranslations: 'passthroughIncome', section: 'Income', id: 'Sch C - Business', worksheet: 'Listed Property Information', matchWorksheet: 'Depreciation and Amortization (Form 4562)' };

const passthroughIncomes = [
    { section: 'Income', id: 'Sch E, p 2 - Partnership' },
    { section: 'Income', id: 'Sch E, p 2 - S Corporation' },
    { section: 'Income', id: 'Sch E, p 2 - Fiduciary Passthrough (K-1 1041)'},
];

const matchIdentifiers = [
    {
        section: 'Deductions', id: '2106', matchType: 'first',
        matchOnKey: 'IFDSSBEG.10', matchOnSection: 'General', matchOnType: 'lineItems',
        matchToSection: 'General', matchToKey: 'IFDSPRTE.7', matchToType: 'fields',
        matchToFilter: 'IFDSPRTG.249'
    },
    {
        section: 'Deductions', id: '2106', matchType: 'first',
        matchOnKey: 'IFDSSBEG.10', matchOnSection: 'General', matchOnType: 'lineItems',
        matchToSection: 'General', matchToKey: 'IFDSSCPE.7', matchToType: 'fields',
        matchToFilter: 'IFDSSCPG.433'
    },
    {
        section: 'Deductions', id: '2106', matchType: 'first',
        matchOnKey: 'IFDSSBEG.10', matchOnSection: 'General', matchOnType: 'lineItems',
        matchToSection: 'General', matchToKey: 'IFDSFIDE.3', matchToType: 'fields',
        matchToFilter: 'IFDSFIDG.223'
    },
];

const matchPassthroughEntities = (priorYearData) => (passthroughEntity) => {
    const passthroughVehicles = [];

    let foundMatchingEntity = false;
    matchIdentifiers.forEach((matchIdentifier) => {
        const {
            matchToKey,
            matchToSection,
            matchToType,
            matchType,
            isEntity,
            matchToFilter,
            ...matchOnProps
        } = matchIdentifier;

        // check if passthrough entity has a value for the 100% disposition field
        const hasPassthroughDispositionValue = passthroughEntity?.data?.find(y => hasMatchingKeyInField(y, matchToFilter) || hasMatchingKeyInLineItems(y, matchToFilter));

        if (hasPassthroughDispositionValue) return;

        const matcherPYEntities = priorYearData?.filter(filterPYByIdentifier(matchOnProps));

        const primarySection = findSection(passthroughEntity?.data, matchToSection);
        const primaryDataSetsToMatch = findFromData(primarySection, matchToKey, matchToType);
        
        primaryDataSetsToMatch?.forEach((primaryDataSetToMatch) =>  {
            const { value: primaryValueToMatch } = primaryDataSetToMatch;

            if (!primaryValueToMatch) return;

            if (matchType === MATCH_TYPE_FIRST) {
                const matchingEntity = matcherPYEntities.find(isMatchingEntity(primaryValueToMatch, matchOnProps));
                
                // If a matching passthrough is found, match vehicles to properties by asset number
                if (matchingEntity && !foundMatchingEntity) {
                    foundMatchingEntity = true;
                    const vehicles = matchingEntity.data.filter(x => x.name === passthoughVehicleTemplate.worksheet);
                    const matchPropertyData = matchingEntity.data.find(x => x.name === passthoughVehicleTemplate.matchWorksheet);

                    passthroughVehicles.push(...Array.from(vehicles).map(mergePassthroughVehicleData(matchPropertyData)));
                }
            }
        });

    });
    
    return passthroughVehicles;
};

const mergePassthroughVehicleData = (matchPropertyData) => (vehicle) => {
    const vehicleCopy = _.cloneDeep(vehicle);

    if (!matchPropertyData) return vehicleCopy;
    const VEHICLE_ASSET_FIELD_ID = 'IFDSSBDI.0';
    const PROPERTY_ASSET_FIELD_ID = 'IFDSDEPR.0';
    const vehicleAssetNumber = vehicleCopy?.fields?.find(hasMatchingPYKey(VEHICLE_ASSET_FIELD_ID))?.value;
    const assetMatch = matchPropertyData?.lineItems?.find(x => x.some(y => y.key === PROPERTY_ASSET_FIELD_ID && y.value === vehicleAssetNumber));

    if (assetMatch) {
        // A matching asset number property was found, import description and cost fields
        const importKeys = [ 'IFDSDEPR.0', 'IFDSDEPR.1', 'IFDSDEPR.25' ];
        
        importKeys.forEach((importKey) => {
            const foundKey = assetMatch.find(hasMatchingPYKey(importKey));
            
            if (foundKey) {
                vehicleCopy?.fields?.push(_.cloneDeep(foundKey));
            }
        });
    }
     
    return vehicleCopy;
};

const filterWorksheetsForVehicleData = (template) => (worksheet) => {
    if (!worksheet || !template) return false;

    const { section, id, data } = worksheet;
    const { section: templateSection, id: templateId, worksheet: templateWorksheet } = template;

    if (section !== templateSection || id !== templateId) return false;

    const vehicleData = data?.find(x => x.name === templateWorksheet)?.lineItems?.length;

    return vehicleData ? true : false;
};

const filterWorksheetsForRentalIncomeData = (template) => (worksheet) => {
    if (!worksheet || !template) return false;

    const { section, id, data } = worksheet;
    const { section: templateSection, id: templateId, worksheet: templateWorksheet } = template;

    if (section !== templateSection || id !== templateId) return false;

    const rentalData = data?.find(x => x.name === templateWorksheet)?.lineItems?.length || data?.find(x => x.name === templateWorksheet)?.fields?.length;

    return rentalData ? true : false;
};

const transformVehicleGridTo1040 = (translationType) => (vehicleGridData) => {
    const GRID_TRANSFORMATIONS = {
        businessIncome: {},
        rentalIncome: {},
        farmIncome: {},
        passthroughIncome: {
            'IFDSSBOA.0': 'vehicleExpenses',
            'IFDSSBOA.1': 'otherVehicleExpenses',
        },
    };

    if (!vehicleGridData) return null;

    const clonedVehicleGridData = _.cloneDeep(vehicleGridData);
    const transformations = GRID_TRANSFORMATIONS[translationType] || {};

    const transformedVehicleGrid = clonedVehicleGridData.map((gridRow) => {
        const transformedGridRow = gridRow.map((cell) => {
            const { key, value } = cell;

            const transformedCell = {
                key: transformations[key] || key,
                value,
            };

            return transformedCell;
        });

        return transformedGridRow;
    });

    return transformedVehicleGrid;
};

const transformVehicleTo1040 = (translationType) => (vehicleData) => {
    const TRANSFORMATIONS = {
        businessIncome: {
            // Vehicle Information
            'vehicleName': { fields: [ 'IFDSCDPR.0' ] },
            'vehicleCost': { fields: [ 'IFDSCDPR.9' ] },
            'vehicleFairMarket': { fields: [ 'IFDSCDAE.8' ] },

            // Vehicle Miles
            'vehicleMilesDropDown.1': { fields: [ 'IFDSCDOI.0' ] },
            'vehicleMilesDropDown.2': { fields: [ 'IFDSCDOI.1' ] },

            'vehicleMiles.1': { fields: [ 'IFDSCDAE.2' ], uniqueName: 'priorTotalMilesForThisBusiness' },
            'vehicleMiles.2': { fields: [ 'IFDSCDAE.3' ], uniqueName: 'priorCommutingMiles' },
            'vehicleMiles.4': { fields: [ 'IFDSCDAE.1' ], uniqueName: 'priorTotalMiles' },

            // Vehicle Expenses
            'vehicleExpenses.0': { fields: [ 'IFDSCDAE.5' ] },
            'vehicleExpenses.1': { fields: [ 'IFDSCDAE.6' ] },
            'vehicleExpenses.2': { fields: [ 'IFDSCDAE.7' ] },
            'vehicleExpenses.3': { fields: [ 'IFDSCDAE.9' ] },
        },
        rentalIncome: {
            // Vehicle Information
            'vehicleName': { fields: [ 'IFDSEDPR.4' ] },
            'vehicleCost': { fields: [ 'IFDSEDPR.12' ] },
            'vehicleFairMarket': { fields: [ 'IFDSEDAE.8' ] },

            // Vehicle Miles
            'vehicleMilesDropDown.1': { fields: [ 'IFDSEDOI.0' ] },
            'vehicleMilesDropDown.2': { fields: [ 'IFDSEDOI.1' ] },

            'vehicleMiles.1': { fields: [ 'IFDSEDAE.2' ], uniqueName: 'priorTotalMilesForThisBusiness' },
            'vehicleMiles.2': { fields: [ 'IFDSEDAE.3' ], uniqueName: 'priorCommutingMiles' },
            'vehicleMiles.4': { fields: [ 'IFDSEDAE.1' ], uniqueName: 'priorTotalMiles' },

            // Vehicle Expenses
            'vehicleExpenses.0': { fields: [ 'IFDSEDAE.5' ] },
            'vehicleExpenses.1': { fields: [ 'IFDSEDAE.6' ] },
            'vehicleExpenses.2': { fields: [ 'IFDSEDAE.7' ] },
            'vehicleExpenses.3': { fields: [ 'IFDSEDAE.9' ] },
        },
        farmIncome: {
            // Vehicle Information
            'vehicleName': { fields: [ 'IFDSFDPR.1' ] },
            'vehicleCost': { fields: [ 'IFDSFDPR.6' ] },
            'vehicleFairMarket': { fields: [ 'IFDSFDAE.3' ] },

            // Vehicle Miles
            'vehicleMilesDropDown.1': { fields: [ 'IFDSFDOI.2' ] },
            'vehicleMilesDropDown.2': { fields: [ 'IFDSFDOI.0' ] },

            'vehicleMiles.1': { fields: [ 'IFDSFDAE.1' ], uniqueName: 'priorTotalMilesForThisBusiness' },
            'vehicleMiles.2': { fields: [ 'IFDSFDAE.10' ], uniqueName: 'priorCommutingMiles' },
            'vehicleMiles.4': { fields: [ 'IFDSFDAE.8' ], uniqueName: 'priorTotalMiles' },

            // Vehicle Expenses
            'vehicleExpenses.0': { fields: [ 'IFDSFDAE.5' ] },
            'vehicleExpenses.1': { fields: [ 'IFDSFDAE.7' ] },
            'vehicleExpenses.2': { fields: [ 'IFDSFDAE.4' ] },
            'vehicleExpenses.3': { fields: [ 'IFDSFDAE.0' ] },
        },
        passthroughIncome: {
            // Vehicle Information
            'vehicleName': { fields: ['IFDSDEPR.1'] }, // vehicle Name
            'vehicleCost': { fields: ['IFDSDEPR.25'] }, // Vehicle Cost
            'axcessAssetNumber': { fields: ['IFDSDEPR.0'], uniqueName: 'axcessAssetNumber' }, // Vehicle Cost
            // Vehicle description will be removed
            'vehicleFairMarket': { fields: ['IFDSSBAE.8'] }, // Fair market value

            // Vehicle Miles
            'vehicleMilesDropDown.1': { // Another vehicle available for personal use
                fields: [ 'IFDSSBDI.8' ], translateFn: 'selectYN',
            },
            'vehicleMilesDropDown.2': { // Off-duty hours
                fields: [ 'IFDSSBDI.9' ], translateFn: 'selectYN',
            },
            'vehicleMiles.1': { fields: [ 'IFDSSBDI.4' ], uniqueName: 'priorTotalMilesForThisBusiness' }, // Total business miles
            'vehicleMiles.2': { fields: [ 'IFDSSBDI.6' ], uniqueName: 'priorCommutingMiles' }, // Total commuting miles
            'vehicleMiles.4': { fields: [ 'IFDSSBDI.3' ], uniqueName: 'priorTotalMiles' }, // Total miles driven

            // Vehicle Expenses
            'vehicleExpenses.0': { // Gasoline, oil, repair, ect
                fields: [ 'IFDSSBAE.1', 'IFDSSBAE.2', 'IFDSSBAE.3' ],
                calculationFn: 'sumAllPull',
            },
            'vehicleExpenses.1': { fields: [ 'IFDSSBAE.4' ] }, // Interest
            'vehicleExpenses.2': { fields: [ 'IFDSSBAE.5' ] }, // Taxes
            'vehicleExpenses.3': { fields: [ 'IFDSSBAE.9' ] }, // Vehicle rental leases
        },
    };

    if (!vehicleData) return [];
    const clonedVehicleData = _.cloneDeep(vehicleData);
    const transformations = TRANSFORMATIONS[translationType] || {};

    const transformedVehicleWorksheet = Object.keys(transformations).map((transformKey) => {
        const fieldTransformation = transformations[transformKey];
        
        if (!fieldTransformation) return null;
        
        const { fields, calculationFn, translateFn, uniqueName } = fieldTransformation;

        const fieldValues = fields.map((fieldId) => {
            return clonedVehicleData.find(x => x.key === fieldId)?.value;
        });

        const transformValue = getPYCalculationFunction(calculationFn)(fieldValues);

        if (!_.isNil(transformValue)) {
            const transformedVehicleData = {
                key: transformKey,
                value: translatePullData(translateFn, transformValue),
            };

            if (uniqueName) {
                transformedVehicleData.uniqueName = uniqueName;
            }

            return transformedVehicleData;
        }

        return null;
    }).filter(x => x);

    return transformedVehicleWorksheet;
};

const buildVehicleWorksheet = (vehicleTemplate, translatedVehicleData, transformedVehicleGridData, vehicleNumber, entityId) => {
    const { fieldIdTranslations, worksheet, templateName } = vehicleTemplate;

    const dataWorksheet = {
        name: worksheet,
        fields: translatedVehicleData,
    };
    
    if (transformedVehicleGridData) {
        dataWorksheet.lineItems = transformedVehicleGridData;
        // populateOptions for vehicles defined and used in populateOptions.js
        dataWorksheet.populateOptions = { groupField: true };
    }

    const vehicleWorksheet = {
        section: 'Vehicle',
        id: 'Vehicle',
        entityId: `${vehicleNumber + 1}`,
        vehicleNumber: `${vehicleNumber}`,
        sourceEntityId: `${entityId}`,
        sourceKey: fieldIdTranslations,
        source: templateName,
        data: [ dataWorksheet ],
    };

    return vehicleWorksheet;
};

const convertVehiclePageToVehicleWorksheet = (entityId) => (passthroughVehicle) => {
    if (!entityId || !passthroughVehicle) return null;
    const { fields, lineItems, name } = passthroughVehicle;

    const vehicleWorksheet = {
        data: [
            {
                lineItems: [ fields ],
                gridLineItems: lineItems,
                name: name,
            }
        ],
        entityId: entityId,
        name: name,
    };

    return vehicleWorksheet;
};

const preProcessVehicleWorksheets = (worksheetData) => {
    const vehicleWorksheets = [];

    // Filter and find vehicle worksheets from each schedule
    const businessVehicleWorksheets = Array.from(worksheetData).filter(filterWorksheetsForVehicleData(businessVehicleTemplate));
    let rentalVehicleWorksheets;

    // Grab rental worksheet to check for 100% disposition value
    const rentalWorksheets = Array.from(worksheetData).filter(filterWorksheetsForRentalIncomeData(rentalTemplate));

	const hasRentalDispositionValue = rentalWorksheets && rentalWorksheets.find(x => x && x.data.find(y => hasMatchingKeyInField(y, 'IFDSEGEN.11') || hasMatchingKeyInLineItems(y, 'IFDSEGEN.11')));
    
    // remove rental income worksheet if 100% disposition value
    if (!hasRentalDispositionValue) {
        rentalVehicleWorksheets = Array.from(worksheetData).filter(filterWorksheetsForVehicleData(rentalVehicleTemplate));
    } 

    const farmVehicleWorksheets = Array.from(worksheetData).filter(filterWorksheetsForVehicleData(farmVehicleTemplate));

    // Filter and find vehicle worksheets from matching passthroughs
    const passthroughEntities = passthroughIncomes.reduce(filterPYFromIdentifiers(worksheetData), []);

    const passthroughVehicleMap = passthroughEntities.map(matchPassthroughEntities(worksheetData));

    const generateVehiclesFromWorksheet = (vehicleTemplate) => (worksheet) => {
        const { entityId, data } = worksheet;
        const { worksheet: templateWorksheet, fieldIdTranslations } = vehicleTemplate;
        const vehicles = data?.find(x => x.name === templateWorksheet)?.lineItems;
        const vehicles2 = data?.find(x => x.name === templateWorksheet)?.gridLineItems;

        if (!vehicles || !vehicles.length) return;

        Array.from(vehicles).forEach((vehicle) => {
            let transformedVehicleGridData = null;
            
            if (vehicles2) {
                transformedVehicleGridData = transformVehicleGridTo1040(fieldIdTranslations)(vehicles2);
            }

            const transformedVehicleData = transformVehicleTo1040(fieldIdTranslations)(vehicle);
            const vehicleWorksheet = buildVehicleWorksheet(vehicleTemplate, transformedVehicleData, transformedVehicleGridData, vehicleWorksheets.length, entityId);
            vehicleWorksheets.push(vehicleWorksheet);
        });
    };

    // generate vehicle worksheets from each schedule
    Array.from(businessVehicleWorksheets)?.forEach(generateVehiclesFromWorksheet(businessVehicleTemplate));
    if (rentalVehicleWorksheets) {
        Array.from(rentalVehicleWorksheets).forEach(generateVehiclesFromWorksheet(rentalVehicleTemplate));
    } 
    Array.from(farmVehicleWorksheets).forEach(generateVehiclesFromWorksheet(farmVehicleTemplate));
    
    // Generate vehicle worksheets from matching passthroughs
    Array.from(passthroughVehicleMap).forEach((passthroughVehicles, entityId) => {
        passthroughVehicles
            .map(convertVehiclePageToVehicleWorksheet(entityId + 1))
            .filter(x => x)
            .forEach(generateVehiclesFromWorksheet(passthoughVehicleTemplate));
    });
    return vehicleWorksheets;
};

export default preProcessVehicleWorksheets;

export {
    preProcessVehicleWorksheets,

    // Exported for testing
    matchPassthroughEntities,
    mergePassthroughVehicleData,
    filterWorksheetsForVehicleData,
    transformVehicleGridTo1040,
    transformVehicleTo1040,
    buildVehicleWorksheet,
    convertVehiclePageToVehicleWorksheet,

    businessVehicleTemplate,
    rentalVehicleTemplate,
    farmVehicleTemplate,
    passthoughVehicleTemplate,
};