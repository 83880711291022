import { setConditionalFieldValidation } from '@components/formRenderer/triggers';
import { Validators } from 'cla-formrenderer';
import { validateDate } from './helpers/taxPaymentsValidators.js';


const setAmountAndDatePaidValidation = (options) => {
    const { group, index } = options;
    const groupItems = group.lineItems?.length > 0 ? group.lineItems[index] : group.fields;
    const calculatedAmount = groupItems[1].default?.replace('$', '') || '0';
    const amountPaid = groupItems[3].default?.replace('$', '') || '0';
    const datePaid = groupItems[4].default || '';

    if (calculatedAmount !== '0') {
        amountPaid === '0' ? setValidations(3, groupItems) : clearValidations(3, groupItems);
        datePaid === '' ? setValidations(4, groupItems) : clearValidations(4, groupItems);
    }
    else {
        (amountPaid !== '0' && datePaid === '') ? setValidations(4, groupItems) : clearValidations(4, groupItems);
        (datePaid !== '' && datePaid !== "N/A" && amountPaid === '0') ? setValidations(3, groupItems) : clearValidations(3, groupItems);
    }
}

const setFederalAmountAndDatePaidValidation = (options) => {
    const { group, index } = options;
    const groupItems = group.lineItems?.length > 0 ? group.lineItems[index] : group.fields;
    const amountPaid = groupItems?.[1]?.default?.replace('$', '') || '0';
    const datePaid = groupItems?.[2]?.default || '';

    (amountPaid !== '0' && datePaid === '') ? setValidations(2, groupItems) : clearValidations(2, groupItems);
    (datePaid !== '' && datePaid !== "N/A" && amountPaid === '0') ? setValidations(1, groupItems) : clearValidations(1, groupItems);
}

const setValidations = (index, groupItems) => {
    groupItems[index].validations = { ...Validators.required() };
    groupItems[index].error = true;
    groupItems[index].errorMessage = 'Required';
}
const clearValidations = (index, groupItems) => {
    groupItems[index].errorMessage = null;
    groupItems[index].error = false;
    if(groupItems[index].validations){
}
}

const validateDatePaid = (options) =>{
    const { field: date } = options;
    
    validateDate(date)
}

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'setConditionalFieldValidation':
            setConditionalFieldValidation(options);
            break;
        case 'setAmountAndDatePaidValidation':
            setAmountAndDatePaidValidation(options);
            break;
        case 'setFederalAmountAndDatePaidValidation':
            setFederalAmountAndDatePaidValidation(options);
            break;
        case 'validateDatePaid':
            validateDatePaid(options);
            break;
        default:
            break;
    }
};

export {
    sectionFieldLogic,
};