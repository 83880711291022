import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '@components/formRenderer';
import * as NAV from '@utilities/constants/navigation';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import retirement2021 from './2021';
import retirement2022 from './2022';
import retirement2023 from './2023';
import retirement2024 from './2024';

function RetirementContributions(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.RETIREMENT_CONTRIBUTIONS);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: retirement2021(year),
            2022: retirement2022(year),
            2023: retirement2023(year),
            2024: retirement2024(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setSectionState(formSections);
        // eslint-disable-next-line    
    }, []);


    const axcessIdentifiers = [
        { section: 'Deductions', id: 'Keogh' },
        { section: 'Income', id: 'IRAs' },
    ];

    return (
        <FormRenderer
            sections={sectionState}
            key="retirementContributionsForm"
            formName={REDUX.RETIREMENT_CONTRIBUTIONS}
            identifiers={axcessIdentifiers}
            isExportForm={isExportForm}
        />
    );
}

export default RetirementContributions;
