import { Button, Dialog } from 'react-md';
import nextId from 'react-id-generator';
import * as IMG from '../../../constants/images';

function WarningDialog(props){
    const { buttonElement, 
        onCancelWarning,
        onConfirmClick,
        visible,
        subtitleText, questionText, confirmText } = props;

    return(
        <>
            {buttonElement}
            <Dialog
                id={`warning-dialog${nextId()}`}
                role="alertdialog"
                visible={visible}
                onRequestClose={()=> {}}
                aria-labelledby="dialog-title"
                className="WarningDialogContainer"
                data-testid="warning-dialog"
            >
                <div className='WarningDialogInnerContainer' >
                    <div className='WarningSignContainer'>
                        <img className="warningDialogIcon" width="30" height="30" alt="Warning icon" src={IMG.warningIcon}></img>
                        <div className="SuccessDialogTitle"> WARNING </div>
                    </div>
                    <div className="WarningDialogTextContainer">
                        <div className="WarningDialogText1"> {subtitleText}</div>
                        <div className="WarningDialogText1"> {questionText}</div>
                        <div className="WarningDialogText1"> {confirmText}</div>
                    </div>
                    <div className="warningDialogButton">
                        <Button className="warningDialogCancel" data-testid="dialog-cancel" id="dialog-cancel" onClick={onCancelWarning} > CANCEL </Button>
                        <Button className="warningDialogConfirm" data-testid="dialog-confirm" id="dialog-confirm" onClick={onConfirmClick} > CONFIRM </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default WarningDialog;