import { Validators } from 'cla-formrenderer';
import { SCHEDC_EQUIPMENT_UPLOAD } from '@utilities/constants/strings';

const businessIncomeFn = (year) => {
    const sections = [
        {
            title: 'Business Information',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 1,
            barcodeName: 'barcodeBusinessInformation',
            groups: [
                {
                    groupId: 1,
                    isVisible: true,
                    class: 'defaultGroup',
                    fields: [
                        {
                            label: 'Name of Business', name: 'IFDSCHDR.4', type: 'freeText', maxLength: 60, col: 6, isEntityName: true, placeholder: 'Enter Business Name', prior: true, isPriorEditable: true,
                            logicFunction: [
                                { trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSCGEN.5'] },
                                { trigger: 'setIFDSCGEN12Required', isDifferentGroup: true },
                                { trigger: 'setIFDSCGEN13Required', isDifferentGroup: true },
                                { trigger: 'setIFDSCGEN1Required', isDifferentGroup: true }
                            ]
                        },
                        {
                            label: 'Principal Business/Profession', name: 'IFDSCGEN.5', type: 'freeText', col: 6, placeholder: 'Enter Profession', maxLength: 25, isPriorEditable: true,
                            prior: true,
                            logicFunction: [
                                { trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSCHDR.3'] },
                                { trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSCHDR.4'] },
                                { trigger: 'setIFDSCGEN21Required', isDifferentGroup: true }
                            ]
                        },
                        { label: 'Taxpayer/Spouse/Joint', name: 'IFDSCHDR.3', type: 'select', lookup: 'PAYER', col: 6, placeholder: 'select', prior: true, isPriorEditable: true, logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSCGEN.5'] }] },
                        {
                            label: 'Employer ID (if applicable)', name: 'IFDSCGEN.22', type: 'ein', col: 6, placeholder: 'Enter Employer ID#', axcessTranslate: 'employerId', isPriorEditable: true,
                            prior: true, validations: {
                                ...Validators.ein(),
                            }
                        }
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Address (if different than primary address)', type: 'label', class: 'labelWithDivider' },
                    ]
                },
                {
                    groupId: 3,
                    groupType: 'blueDivider'
                },
                {
                    groupId: 4,
                    isVisible: true,
                    fields: [
                        { label: 'Street address', name: 'IFDSCGEN.12', type: 'freeText', maxLength: 49,  col: 6, placeholder: 'Enter Address', prior: true, isPriorEditable: true },
                        { label: 'Apt #', name: 'apartmentNumber', type: 'freeText', maxLength: 34, col: 3, placeholder: '###', isPriorEditable: true },
                        { label: 'City', name: 'IFDSCGEN.13', type: 'freeText', maxLength: 34, col: 3, placeholder: 'Enter City', prior: true, isPriorEditable: true },
                        { label: 'Zip/Postal', name: 'IFDSCGEN.1', type: 'zippostal', col: 4, placeholder: 'Enter Zip/Postal', prior: true, isPriorEditable: true },
                        {
                            label: 'Country', name: 'IFDSCGEN.4', type: 'select', lookup: 'FOREIGN_COUNTRIES', col: 4, triggered: 'setCountrySchedC', prior: true, placeholder: 'Select Country', axcessTranslate: 'foreignCountry', default: 'US',
                            axcess: {
                                pull: {
                                    fields: ['IFDSCGEN.4'],
                                    fn: 'foreignCountryPull',
                                }
                            },
                            logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSCGEN.119'] }],
                            isPriorEditable: true
                        },
                        { label: 'State', name: 'IFDSCGEN.0', type: 'select', lookup: 'STATES', col: 4, placeholder: 'Select Prov/State/County', prior: true, isVisible: true, validations: { ...Validators.required() }, isPriorEditable: true },
                        {
                            label: 'Province', name: 'IFDSCGEN.19', type: 'select', lookup: 'PROVINCES', col: 4, placeholder: 'Enter Province', prior: true, priorCompareOptions: { compareAllLookup: true }, isVisible: false, validations: { ...Validators.required() }, isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCGEN.4', 'IFDSCGEN.19'],
                                    fn: 'canadianProvincePull',
                                }
                            },
                        },
                        {
                            label: 'State/Province', name: 'IFDSCGEN.119', type: 'freeText', maxLength: 50, col: 4, placeholder: 'Enter Province', prior: true, isVisible: false, isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCGEN.4', 'IFDSCGEN.19'],
                                    fn: 'foreignProvincePull',
                                }
                            },
                        },
                    ]
                },
                {
                    groupId: 8,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Is this business still active? Answering No will inactivate this business.', name: 'businessActiveLabel', type: 'label', col: 10 },
                        { label: 'Yes/No', name: 'businessActive', type: 'select', lookup: 'YESNO_DONTKNOW', col: 2, triggered: 'setEntityNotApplicable', isActiveEntity: true }
                    ]
                },
                {
                    groupId: 5,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        //TODO: should be current date year 
                        { label: `Did you pay any non-employee individuals or business more than $600 during ${year.current}?`, name: 'payNonEmployeeLabel', type: 'label', col: 10 },
                        {
                            label: 'Yes/No', name: 'IFDSCGEN.21', type: 'select', lookup: 'YESNO', col: 2, axcessTranslate: 'validStatement', triggered: 'setFileAllFormsSchedC',
                            logicFunction: [
                                { trigger: 'setIFDSCGEN31Required', isDifferentGroup: true }
                            ]
                        },
                    ]
                },
                {
                    groupId: 6,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'If you answered \'Yes\' to the above question, did you file all required information tax forms (i.e. 1099-MISC)?', name: 'payNonEmployee2Label', type: 'label', col: 10 },
                        { label: 'Yes/No', name: 'IFDSCGEN.31', type: 'select', lookup: 'YESNO', col: 2, placeholder: 'Select', axcessTranslate: 'validStatement', },
                    ]
                },
                {
                    groupId: 9,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        {
                            label: 'Did you receive any government assistance programs such as grants, employment credits, otherwise?',
                            name: 'governmentGrantsSchedCLabel',
                            type: 'label',
                            col: 10
                        },
                        {
                            label: 'Yes/No',
                            name: 'governmentGrantsSchedC',
                            type: 'select',
                            lookup: 'YESNO',
                            col: 2,
                            triggered: 'showGovernmentGrantsDetailsSchedC'
                        },
                    ]
                },
                {
                    groupId: 10,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        {
                            label: 'Please provide detail of what was received.',
                            name: 'governmentGrantsDetailsSchedCLabel',
                            type: 'label',
                            col: 9,
                            class: 'labelTitleTextAreaIndented',
                        },
                        {
                            label: '',
                            name: 'governmentGrantsDetailsSchedC',
                            type: 'textarea',
                            col: 12,
                            maxRows: 3,
                            class: 'fieldTextAreaIndented',
                        },
                    ]
                },
                {
                    groupId: 7,
                    groupType: 'details',
                    fields: [
                        { label: 'To provide this business\'s income and expenses, please either upload files above or enter information below.  If you uploaded files above, you do not need to complete the business income and expenses sections below.', type: 'label', class: 'groupDetails3' },
                    ]
                },
                // {
                //     groupId: 7,     
                //     isSingleColumn: true,
                //     groupType: 'blueBox',
                //     fields: [
                //         { label: 'I would like to provide files for CLA\'s use in reporting (insert business name from above) income and expenses.', name: 'addName1', type: 'checkbox', col: 12 }, // TODO: required field - Why would we require a checkbox? - ZE
                //         { label: 'I would like to provide (insert business name from above) income expenses by completing the table below.', name: 'addName2', type: 'checkbox', col: 12 }
                //     ]
                // }
            ]
        },
        {
            title: 'Business Income',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 2,
            barcodeName: 'barcodeBusinessIncome',
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Please provide any 1099-MISC/NEC forms that you received. If you received other income not reported to you on these forms, please include the payer and income below.', type: 'label' },
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'If you would like to provide additional income information by source or payer, please do so.  Otherwise, go ahead and enter your total income on one line.', type: 'label', class: 'groupDetails2' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 3,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Income and Cost of Goods Sold', 'Miscellaneous Information (IRS 1099-MISC)', 'Nonemployee Compensation (IRS 1099-NEC)'],
                            fieldPrefixes: ['IFDSCCOG', 'IFDSMISC', 'IFDSNEC'],
                            mergeWithOtherData: [
                                { section: 'Income', id: 'Sch C', worksheet: 'General', key: 'IFDSCHDR.4' },
                            ],
                            lineFilterType: 'any',
                            lineFilters: [
                                { key: 'IFDSCCOG.12', filterType: 'containsKey' },
                                { key: 'IFDSCCOG.13', filterType: 'containsKey' },
                                {
                                    keys: ['IFDSMISC.41', 'IFDSCHDR.4'],
                                    includesFields: ['IFDSMISC.4', 'IFDSMISC.5', 'IFDSMISC.6', 'IFDSMISC.9', 'IFDSMISC.14', 'IFDSMISC.25'],
                                    filterType: 'keysWithMatchingValue',
                                },
                                {
                                    keys: ['IFDSNEC.6', 'IFDSCHDR.4'],
                                    includesFields: ['IFDSNEC.15', 'IFDSNEC.5'],
                                    filterType: 'keysWithMatchingValue',
                                },
                            ],
                        }
                    },
                    fields: [
                        {
                            label: 'Income Source', name: 'incomeSource', type: 'freeText', maxLength: 76, placeholder: 'Enter Source of Income', prior: true, class: 'labelRequired',
                            pullCalculation: {
                                keyIdentifiers: ['IFDSNEC.5', 'IFDSCCOG.12', 'IFDSMISC.5'],
                                fn: 'anyValidPull',
                                defaultIfEmpty: 'Other Income'
                            },
                        },
                        {
                            label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSCCOG.0', type: 'label', isTotal: true, prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSCCOG.13', 'IFDSMISC.4', 'IFDSMISC.6', 'IFDSMISC.9', 'IFDSMISC.14', 'IFDSMISC.25', 'IFDSNEC.15'],
                                fn: 'sumAllPull',
                            },
                            PYList: {
                                0: 'Sch C',
                                1: 'Misc',
                                2: 'Misc',
                                3: 'Misc',
                                4: 'Misc',
                                5: 'Misc',
                                6: 'NEC'
                            }
                        },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSCCOG.0', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    prePopulate: [
                        {
                            label: 'Receipts or sales - general',
                            prePopulateAugments: [{ name: 'receiptsSales', calculateKeys: ['IFDSCCOG.0'], calculateFn: 'anyValidPull', },]
                        }
                    ],
                    prePopulateIndexes: [{ priorIndex: 1 }],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Income Source', smallScreenLabel: 'Income Details', col: 7, colTablet: 3, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Income Source', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDSCCOG.0', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSCCOG.0', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Business Expenses',
            isVisible: true,
            sectionId: 3,
            barcodeName: 'barcodeBusinessExpenses',
            subSections: [
                { subSectionId: 4 },
                { subSectionId: 5 },
                { subSectionId: 6, isShowSubSecTotalOnMainSec: false }
            ],
            groups: [
                /*{
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Please provide any (enter form name here) forms that you received. If you received other business expenses not reported to you on these forms, please include the payer and expense below.', type: 'label'},
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: 
                        { label: 'If you would like to provide additional business expenses information by source or Payer, please do so.  Otherwise, go ahead and enter your Total Expense on one line.', type: 'label'},
                    ]
                }*/
            ]
        },
        {
            title: 'Cost of Goods Sold',
            isVisible: true,
            isSubSection: true,
            sectionId: 4,
            logicFunction: [{ trigger: 'setEndingBeginningInventory' }],
            groups: [
                {
                    groupType: 'lineItem',
                    groupId: 1,
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        { label: 'Cost of Goods Sold', name: 'IFDSCCOG.7', type: 'freeText', maxLength: 76, class: 'labelRequired', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSCCOG.8', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSCCOG.8', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    prePopulate: [
                        { label: 'Beginning Inventory', prePopulateAugments: [{ name: 'IFDSCCOG.2' }] },
                        { label: 'Purchases', prePopulateAugments: [{ name: 'IFDSCCOG.3' }] },
                        { label: 'Cost of Labor', prePopulateAugments: [{ name: 'IFDSCCOG.4' }] },
                        { label: 'Materials and Supplies', prePopulateAugments: [{ name: 'IFDSCCOG.5' }] },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Cost of Goods Sold', smallScreenLabel: 'Expenses Details', col: 7, colTablet: 3, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Cost of Good Sold', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDSCCOG.8', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSCCOG.8', col: 2, colTablet: 2, year: 'current', label: '$ 0', isSubSectionTotal: true },
                        ],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                },
                {
                    groupType: 'lineItem',
                    groupId: 2,
                    isShowHeader: false,
                    footerClass: 'noTotalDisplay',
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        { label: '', name: 'endingInventory', type: 'label', class: 'labelRequired' },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'endingInventoryPrior', type: 'label', isTotal: true, prior: true },
                        { label: 'Ending Inventory', wrapLabel: '2020 Amount', name: 'endingInventoryCurrent', type: 'money', isTotal: true },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    prePopulate: [
                        { label: 'Ending Inventory', prePopulateAugments: [{ name: 'IFDSCCOG.10' }] },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItemDetails: {
                        headerLabels: [
                            { label: '', col: 7, colTablet: 3, align: 'left' },
                            { label: '', col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Expenses',
            isVisible: true,
            isSubSection: true,
            sectionId: 5,
            groups: [
                {
                    groupType: 'lineItem',
                    groupId: 1,
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        { label: 'Type of Expense', name: 'IFDSCEXP.28', type: 'freeText', maxLength: 76, class: 'labelRequired', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSCEXP.32', maxLength: 76, type: 'label', isTotal: true, prior: true},
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSCEXP.32', maxLength: 18, type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    prePopulate: [
                        { label: 'Advertising', prePopulateAugments: [{ name: 'IFDSCEXP.0' }] },
                        { label: 'Car and truck expenses', prePopulateAugments: [{ name: 'IFDSCEXP.2' }] },
                        { label: 'Parking fees and tolls ', prePopulateAugments: [{ name: 'IFDSCEXP.3' }] },
                        { label: 'Commission and fees ', prePopulateAugments: [{ name: 'IFDSCEXP.4' }] },
                        { label: 'Contract labor', prePopulateAugments: [{ name: 'IFDSCEXP.1' }] },
                        { label: 'Employee benefit programs and health insurance other than pension and profit-sharing plans', prePopulateAugments: [{ name: 'IFDSCEXP.7' }] },
                        { label: 'Insurance other than health', prePopulateAugments: [{ name: 'IFDSCEXP.8' }] },
                        { label: 'Interest - mortgage paid to banks, etc.', prePopulateAugments: [{ name: 'IFDSCEXP.9' }] },
                        { label: 'Interest - other', prePopulateAugments: [{ name: 'IFDSCEXP.10' }] },
                        { label: 'Legal and professional fees', prePopulateAugments: [{ name: 'IFDSCEXP.11' }] },
                        { label: 'Office expense', prePopulateAugments: [{ name: 'IFDSCEXP.12' }] },
                        { label: 'Pension and profit-sharing plans', prePopulateAugments: [{ name: 'IFDSCEXP.13' }] },
                        { label: 'Rent or lease - vehicle, machines and equipment', prePopulateAugments: [{ name: 'IFDSCEXP.14' }] },
                        { label: 'Rent or lease - other business property', prePopulateAugments: [{ name: 'IFDSCEXP.15' }] },
                        { label: 'Repairs and maintenance ', prePopulateAugments: [{ name: 'IFDSCEXP.16' }] },
                        { label: 'Supplies not included in cost of goods sold', prePopulateAugments: [{ name: 'IFDSCEXP.17' }] },
                        { label: 'Taxes and licenses', prePopulateAugments: [{ name: 'IFDSCEXP.18' }] },
                        { label: 'Travel', prePopulateAugments: [{ name: 'IFDSCEXP.19' }] },
                        { label: 'Meals', prePopulateAugments: [{ name: 'IFDSCEXP.20' }] },
                        { label: 'Entertainment (deductible only on some state returns)', prePopulateAugments: [{ name: 'IFDSCEXP.26' }] },
                        { label: 'Utilities', prePopulateAugments: [{ name: 'IFDSCEXP.23' }] },
                        { label: 'Wages', prePopulateAugments: [{ name: 'IFDSCEXP.24' }] },
                        { label: 'Dependent care benefits', prePopulateAugments: [{ name: 'IFDSCEXP.38' }] },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Expense Type', smallScreenLabel: 'Expenses Details', col: 7, colTablet: 3, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Add Business Expense', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDSCEXP.32', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSCEXP.32', col: 2, colTablet: 2, year: 'current', label: '$ 0', isSubSectionTotal: true },
                        ],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Business Equipment and Property',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            isSubSection: true,
            sectionId: 6,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'See next section for business vehicles', type: 'label', class: 'subSeCDetails' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Depreciation and Amortization (Form 4562)'],
                            fieldPrefixes: ['IFDSDEPR'],
                            lineFilterType: 'omitAny',
                            lineFilters: [
                                { key: 'IFDSDEPR.48', filterType: 'containsAnyValue' }
                            ],
                        },
                    },
                    fields: [
                        { label: 'Description', name: 'IFDSDEPR.1', type: 'freeText', maxLength: 76, placeholder: 'Enter Placeholder', prior: true, logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSDEPR.18', 'IFDSDEPR.25', 'haveTheItem'] }] }, // required field unless no asset
                        { label: 'Date Acquired', name: 'IFDSDEPR.18', type: 'date', prior: true, axcessTranslate: 'date', labelFormat: 'date' }, // required field unless no asset
                        { label: 'Cost', name: 'IFDSDEPR.25', type: 'money', isTotal: true, hideFooter: true, prior: true, axcessTranslate: 'parseMoneyValue', }, // required field unless no asset
                        { label: 'Y/N', wrapLabel: 'Do you still have the asset?', name: 'haveTheItem', type: 'select', lookup: 'YESNO', placeholder: 'Select', logicFunction: [{ trigger: 'setConditionalFieldValidation', comparisonValue: 'No', requiredFields: ['IFDSDEPR.48', 'IFDSBPCT.24', 'IFDSDEPR.1'] }, { trigger: 'setConditionalFieldValidation', comparisonValue: 'Yes', requiredFields: ['IFDSDEPR.1'] }] }, // required field unless no asset
                        { label: 'Date Sold', name: 'IFDSDEPR.48', type: 'date', axcessTranslate: 'shortDate' }, // required field if above is anything but "Still in use" 
                        { label: 'Sale Price', name: 'IFDSBPCT.24', type: 'money', isTotal: true, hideFooter: true, }, // required field if above is anything but "Still in use" 
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Description', smallScreenLabel: 'Equipment and Property Details', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Date Acquired', col: 2, colTablet: 1, align: 'left' },
                            { label: 'Cost', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Do you still have asset?', col: 1, colTablet: 1, align: 'left' },
                            { label: 'Date Sold', col: 2, colTablet: 1, align: 'left' },
                            { label: 'Sale Price', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Invoice / Receipt', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Business Equipment and Property', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'upload', uploadSectionName: SCHEDC_EQUIPMENT_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        // {  
        //     title: 'Vehicle Expenses',
        //     sectionId: 7,
        //     sectionType: 'vehicleEntity',
        //     isVisible: true,
        //     entities: [],
        //     colMapping: [
        //         { name: 'b', entityName: 'IFDSCDPR.9'},
        //         { name: 'f', entityName: 'IFDSEDAE.2'},
        //         { name: 'i', entityName: 'IFDSCDOI.0'},
        //         { name: 'j', entityName: 'IFDSCDOI.1'},
        //         { name: 'k', entityName: 'IFDSCDAE.5'},
        //         { name: 'l', entityName: 'IFDSCDAE.6'},
        //         { name: 'm', entityName: 'IFDSCDAE.7'},
        //         { name: 'q', entityName: 'IFDSCLPQ.0'},
        //         { name: 'r', entityName: 'IFDSCLPQ.1'}
        //     ]
        // },
        {
            title: 'Vehicle Expenses',
            sectionId: 7,
            sectionType: 'newVehicleEntity',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            entities: [],
        },
        {
            title: 'Business Use of Home',
            isVisible: true,
            sectionId: 8,
            barcodeName: 'barcodeBusinessUseofHomeScheduleC',
            subSections: [
                { subSectionId: 9 }
            ],
            groups: [
                {
                    groupId: 1,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'During the current year, did you begin using part of your home exclusively for use in this business?', name: 'payNonEmployeeLabel', type: 'label', col: 10 },
                        { label: 'Yes/No', name: 'beginUsingHomeForBusiness', type: 'select', lookup: 'YESNO', col: 2, placeholder: 'Select' },
                    ]
                },
                {
                    groupId: 2,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: `I would like to use the simplified method in ${year.current} ($5 deduction per square foot of the home that is used exclusively for business purposes)`, name: 'payNonEmployee1Label', type: 'label', col: 10 },
                        { label: 'Yes/No', name: 'beginUsingHomeForBusiness1', type: 'select', lookup: 'YESNO_DONTKNOW', col: 2, placeholder: 'Select' },
                    ]
                },
                {
                    groupId: 3,
                    isVisible: true,
                    hasDivider: false,
                    fields: [
                        { label: `In ${year.current}, were improvements made to the home and/or home office?`, name: 'payNonEmployee2Label', type: 'label', col: 10 },
                        { label: 'Yes/No', name: 'madeImprovementsToHome', type: 'select', lookup: 'YESNO', col: 2, placeholder: 'Select', logicFunction: [{ trigger: 'setRequiredFromIFDS88298', isDifferentGroup: true }] } //required if IFDS8829.8 is not 0
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 4,
                    fields: [
                        { label: '', name: 'expenses', type: 'label', class: 'labelRequired' },
                        { label: '0', wrapLabel: '2019 SQ FT', name: 'PySqft', type: 'label', isTotal: true, isMoney: false },
                        { label: 'SQ FT', wrapLabel: '2020 SQ FT', name: 'sqft', type: 'total', isTotal: true, placeholder: '0', isMoney: false },
                        { type: 'lineButtons' }
                    ],
                    prePopulate: [
                        { label: 'Total square footage of home', prePopulateAugments: [{ name: 'IFDS8829.9', sameValue: true, currentModifiers: { isPriorEditable: true, axcessTranslate: 'parseMoneyValue'} }], validations: { ...Validators.required() }, isMoney: false,  },
                        { label: 'Square footage of home used exclusively for business', prePopulateAugments: [{ name: 'IFDS8829.8', sameValue: true, currentModifiers: { logicFunction: [{ trigger: 'setBusUseHomeBusIncomeRequired', isDifferentGroup: true }], isPriorEditable: true, axcessTranslate: 'parseMoneyValue' } }], isMoney: false, },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { smallScreenLabel: 'Square Footage of Home Details', col: 7, colTablet: 3, align: 'left' },
                            { label: `${year.prior} Sq Footage`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Sq Footage`, col: 2, colTablet: 2, align: 'right', },
                            { label: '', col: 1, colTablet: 1, align: 'left', },
                        ],
                        footerLabels: [],
                        lineButtons: [
                            { button: 'more' }
                        ]
                    }
                },
            ]
        },
        {
            title: 'Home Expenses',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            isSubSection: true,
            sectionId: 9,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Please provide the full amount of the following expense types associated with operating your home that is used in your business (do not apply a business use % to your amounts provided).', type: 'label', class: 'subSeCDetails' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 2,
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        { label: 'EXPENSE TYPE', name: 'IFDS8829.45', type: 'freeText', maxLength: 76, class: 'labelRequired', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDS8829.48', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDS8829.48', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    prePopulate: [
                        { label: 'Mortgage Interest', prePopulateAugments: [{ name: 'IFDS8829.32' }] }, // IFDS8829.31; IFDS8829.32
                        { label: 'Real Estate Taxes', prePopulateAugments: [{ name: 'IFDS8829.36' }] }, // IFDS8829.35; IFDS8829.36
                        { label: 'Insurance', prePopulateAugments: [{ name: 'IFDS8829.40' }] }, // IFDS8829.39; IFDS8829.40
                        { label: 'Repairs & Maintenance', prePopulateAugments: [{ name: 'IFDS8829.42' }] }, // IFDS8829.41; IFDS8829.42
                        { label: 'Utilities', prePopulateAugments: [{ name: 'IFDS8829.44' }] }, // IFDS8829.43; IFDS8829.44
                        { label: 'Rent', prePopulateAugments: [{ name: 'IFDS8829.78' }] }, // IFDS8829.77; IFDS8829.78
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'EXPENSE TYPE', smallScreenLabel: 'Home Expenses Details', col: 7, colTablet: 3, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Home Expenses', icon: 'add' },
                            { type: 'label', label: 'Total for this Home:', col: 7, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDS8829.48', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDS8829.48', col: 2, colTablet: 2, year: 'current', label: '$ 0', isSubSectionTotal: true },
                        ],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                }
            ]
        },
    ];

    return sections;
};

export default businessIncomeFn;