import { useState } from 'react';
import { Checkbox, useToggle, Button } from 'react-md';

// custom modules
import usePageFramework from '@utilities/hooks/usePageFramework';
import WarningDialog from '@components/dialog/warningDialog';
import api from '@utilities/claApi';
import * as IN_PREP_STRINGS from '@utilities/constants/inPrepLock';
import * as PUSH_STATUS from '@utilities/constants/pushStatus';
import usePolling from '@utilities/hooks/usePolling';
import lastUserActivityDate from '@utilities/helpers/lastUserActivityDate';
import { deriveOrganizerActivity } from '@utilities/organizerData';
import { deriveActivity, deriveStatus } from '@utilities/organizerData/deriveOrganizerData';
import { formValuesChecker } from '@components/organizerExport/organizerRenderer/components/utils/exportFormSections';

function InPrepCheckbox(props) {
  const { index, data, changeData, disabled } = props;
  const { dispatch, ACTION, selectState, clearFormState, history, NAV } = usePageFramework();
  const [isChecked, setIsChecked] = useState(data?.locked ?? false);
  const [visibleWarningDialog, showWarningDialog, hideWarningDialog] = useToggle(false);
  const clientFirstName = data?.client?.name ?? '';
  const lastUserActivityOn = lastUserActivityDate(selectState, true);

  const pollErrorFn = () => {
    hideProgressDialog();
    showErrorDialog();
    setIsChecked(false);
  };
  //eslint-disable-next-line
  const { isPolling, stopPolling, startPolling } = usePolling({ pollErrorFn });

  const handleCheckboxClick = (event) => {
    event.target.checked = !event.target.checked;
    event.stopPropagation();
    showWarningDialog();
  };

  const onConfirmWarning = () => {
    onInPrepConfirm();
    hideWarningDialog();
  };

  const setChangeData = () => {
    const mockOrganizer = {
      locked: true,
      lastUserActivityOn,
      updatedOn: new Date(),
    };
    changeData(
      data.id,
      [
        { column: 'locked_on', value: new Date() },
        { column: 'locked', value: true },
        { column: 'lastUserActivityOn', value: lastUserActivityOn },
        { column: 'customStatus', value: deriveStatus(mockOrganizer) },
        { column: 'customDate', value: deriveActivity(mockOrganizer) },
      ]
    );
  };

  const showProgressDialog = () => {
    dispatch(ACTION.setProgressText(IN_PREP_STRINGS.IN_PROGRESS_TEXT));
    dispatch(ACTION.setProgressVisible(true));
  };

  const hideProgressDialog = () => {
    dispatch(ACTION.setProgressText(''));
    dispatch(ACTION.setProgressVisible(false));
  };

  const showErrorDialog = (message) => {
    dispatch(ACTION.setShowCustomDialog(true));
    dispatch(ACTION.setCustomDialogTitle(IN_PREP_STRINGS.ERROR_TITLE));

    if (message) {
      dispatch(ACTION.setCustomDialogMsg(message));
    } else {
      dispatch(ACTION.setCustomDialogMsg(IN_PREP_STRINGS.ERROR_TEXT(clientFirstName)));
    }
  };

  const lockOrganizer = () => {
    const json = {
      locked: true,
      lastUserActivityOn
    };

    const derivedOrganizerData = deriveOrganizerActivity(json, new Date());
    const payload = Object.assign({}, json, derivedOrganizerData);

    return api.put(`organizers/${data.id}`, payload);
  };

  const createPollFn = (pushStatusId) => async () => {
    return api.get(`organizers/${data.id}/push/${pushStatusId}`).then((res) => {
      return res.data;
    }).then(async (pushResult) => {
      const isValidStatus = (status) => PUSH_STATUS.PUSH_STATUSES.includes(status);

      if (!isValidStatus(pushResult?.status)) {
        pollErrorFn();
        return false;
      }

      switch (pushResult.status) {
        case PUSH_STATUS.SUCCESS:
          await lockOrganizer();
          setChangeData();
          setIsChecked(true);
          hideProgressDialog();
          return false;
        case PUSH_STATUS.FAIL:
          // NEED TO UNCOMMENT WHEN MERGING TO MAIN !!!
          hideProgressDialog();
          showErrorDialog(pushResult?.summary);
          setIsChecked(false);
          return false;

          // START !!! NEED TO COMMENT WHEN MERGING TO MAIN !!! 
          // hideProgressDialog();
          // setChangeData();
          // setIsChecked(true);
          // await lockOrganizer();
          // return false;
          // END !!! NEED TO COMMENT WHEN MERGING TO MAIN !!! 
        default:
          return true;
      }
    });
  };

  const onInPrepConfirm = () => {
    showProgressDialog();
    setIsChecked(true);

    api.post(`organizers/${data.id}/push`, { returnType: 'I', returnMustExist: true }).then((res) => {
      return res.data;
    }).then((data) => {
      const pushResultStatusId = data.id;
      const fn = createPollFn(pushResultStatusId);

      startPolling(fn);
    }).catch((error) => {
      console.error(error);
    });
  };

  const handlePdfButtonOnClick = () => {
    clearFormState();
    history.push(`${NAV.ORGANIZER_EXPORT}/${data.id}`);
    formValuesChecker.val = [];
    window.scrollTo(0, 0);
    dispatch(ACTION.setProgressText('Loading Client Data...'));
    dispatch(ACTION.setProgressVisible(true));
    dispatch(ACTION.setCurrentCardKey(null));
  };

  const pdfExportButton = () => {
    return (
      data?.locked && <Button
          id={`pdf-button-${index}`}
          key={`pdf-button-${index}`}
        className='viewButtonActive'
        onClick={handlePdfButtonOnClick}
        > 
        PDF Export
        </Button>
    );
};


    return (
    <>
        <Checkbox
            data-testid={`inPrep-chkbox-${index}`}
            id={`inPrep-chkbox-${index}`}
            key={`inPrep-chkbox-${index}`}
            onClick={handleCheckboxClick}
            checked={isChecked}
            disabled={isChecked || disabled}
            onChange={() => { }}
            label={pdfExportButton()}
        value={isChecked}
        />
      <WarningDialog
        subtitleText={IN_PREP_STRINGS.SUBTITLE_TEXT(clientFirstName)}
        questionText={IN_PREP_STRINGS.QUESTION_TEXT(clientFirstName)}
        confirmText={IN_PREP_STRINGS.CONFIRM_TEXT}
        onConfirmClick={onConfirmWarning}
        visible={visibleWarningDialog}
        onCancelWarning={hideWarningDialog}
      />
    </>
  );
}

export default InPrepCheckbox;