import { ExpansionPanel, TextArea, Button, DialogFooter, useToggle } from 'react-md';
import { useState, useRef, useEffect } from 'react';
import _ from 'lodash';

// custom modules
import WarningDialog from '@components/dialog/warningDialog';
import SuccessDialog from '@components/dialog/successDialog';
import api from '@utilities/claApi';
import {
    CLOSE_SUBTITLE_TEXT,
    CLOSE_QUESTION_TEXT,
    CLOSE_CONFIRM_TEXT,
    SAVE_IN_PROGRESS_TEXT,
    SAVE_SUCCESSFUL_TEXT,
    SAVE_ERROR_TEXT,
    PRAC_DASH_NOTE_KEY,
} from '@utilities/constants/notes.js';

function NotesPanel(props) {
    const { organizerId, clientName, notes, changeData, toggle } = props;
    const [expanded, setExpanded] = useState(false);
    const [savingText, setSavingText] = useState(SAVE_IN_PROGRESS_TEXT);
    const [visibleSuccessDialog, showSuccessDialog, hideSuccessDialog] = useToggle(false);
    const [visibleWarningDialog, showWarningDialog, hideWarningDialog] = useToggle(false);

    const DEFAULT_NOTE = { value: '' };
    const textAreaRef = useRef();
    const [currentformNote, setCurrentformNote] = useState(DEFAULT_NOTE.value);
    const [savedNotes, setSavedNotes] = useState([DEFAULT_NOTE]);

    useEffect(() => {
        // On prac dash component state changes, update note component
        // GROWTH: API call to get note data rather than passing note data from parent
        const targetNote = notes?.find(x => x.key === PRAC_DASH_NOTE_KEY) || notes?.find(x => !x.key) || DEFAULT_NOTE;

        setCurrentformNote(targetNote.value);
        setSavedNotes([targetNote])
        setExpanded(true);
        // eslint-disable-next-line
    }, [notes]);

    const note = savedNotes[0];

    const saveUpstream = (noteId, noteData) => {
        // Update changes to note component to prac dash component state
        const clonedNotes = _.cloneDeep(notes);
        const targetNote = clonedNotes.find(x => x.id === noteId);
        const noteDoc = Object.assign({}, { id: noteId }, noteData);

        if (targetNote) {
            Object.assign(targetNote, noteDoc);
        } else {
            clonedNotes.push(noteDoc);
        }

        const delta = [
            {
                column: 'notes',
                value: clonedNotes,
            }
        ];

        setSavedNotes([noteDoc]);
        changeData(organizerId, delta);
    };

    const onClose = () => {
        savingText === SAVE_SUCCESSFUL_TEXT ? setCurrentformNote(textAreaRef.current.value) : setCurrentformNote(savedNotes[0].value);
        hideSuccessDialog();
    };

    const onConfirmClose = () => {
        savingText === SAVE_SUCCESSFUL_TEXT ? setCurrentformNote(textAreaRef.current.value) : setCurrentformNote(savedNotes[0].value);
        hideWarningDialog();
        toggle();
        setSavingText(SAVE_IN_PROGRESS_TEXT);
    };

    const onCancel = () => {
        if (typeof (textAreaRef.current) !== 'undefined' && textAreaRef.current && textAreaRef.current.value !== currentformNote) {
            setCurrentformNote(textAreaRef.current.value);
            showWarningDialog();
        } else {
            onConfirmClose();
        }
    };

    const onSave = () => {
        setSavingText(SAVE_IN_PROGRESS_TEXT);
        showSuccessDialog();
        const value = textAreaRef.current.value;
        const noteData = { value, key: PRAC_DASH_NOTE_KEY };

        if (!note.id) {
            const newNoteData = Object.assign({}, noteData);

            // if we do not have an existing note, we need to create one
            api.post(`/organizers/${organizerId}/notes`, newNoteData).then((response) => { 
                return response.data.id;
            }).then((noteId) => {
                // save was successful
                setCurrentformNote(value);
                setSavingText(SAVE_SUCCESSFUL_TEXT);
                saveUpstream(noteId, noteData);
            }).catch((error) => {
                setSavingText(SAVE_ERROR_TEXT);
                console.log(error);
            })
        } else {
            api.put(`/organizers/${organizerId}/notes/${note.id}`, noteData).then((response) => {
                // save was successful
                setCurrentformNote(value);
                setSavingText(SAVE_SUCCESSFUL_TEXT);
                saveUpstream(note.id, noteData);
            }).catch((error) => {
                setSavingText(SAVE_ERROR_TEXT);
                console.log(error);
            });
        }
    };
    const buildHeader = () => {
        const notesPreview = currentformNote.length >= 75 ? `${currentformNote.substr(0, 75)}...` : currentformNote;
        return (
            expanded ? <div className='notesTitleExpanded'>Notes:</div> :
                <>
                    <div className='notesTitleCollapsedContainer'>
                        <div className='notesTitleCollapsed'> Notes:</div>
                    </div>
                    <div className='notesPreviewCollapsed'> {notesPreview} </div>
                </>
        );
    };

    return (
        <ExpansionPanel
            data-testid='notes-panel'
            id={`notes-panel-${organizerId}`}
            key={`notes-key-panel-${organizerId}`}
            className={'pracDashNotes'}
            expanded={expanded}
            expanderIcon={<div></div>}
            header={buildHeader()}
        >
            <div className='notesExpandedLine'></div>
            <TextArea
                data-testid='notes-panel-text-area'
                className={'notesTextArea'}
                id={'prac-dash-notes-textArea'}
                key={'prac-dash-notes-textArea'}
                ref={textAreaRef}
                rows={7}
                maxRows={10}
                maxLength={998}
                defaultValue={typeof (textAreaRef.current) !== 'undefined' && textAreaRef.current && textAreaRef.current.value ? textAreaRef.current.value : currentformNote}
                placeholder={'Please enter your notes here...'}
                animate={false}
            />
            <DialogFooter className='notesFooter'>
                <div className='notesFooterText'> Saving this note will create a new version.</div>
                <div>
                    {typeof (textAreaRef.current) !== 'undefined' && textAreaRef.current && textAreaRef.current.value !== note.value ?
                        <WarningDialog className='formNotesCancel' subtitleText={CLOSE_SUBTITLE_TEXT(clientName)} questionText={CLOSE_QUESTION_TEXT} confirmText={CLOSE_CONFIRM_TEXT} onConfirmClick={onConfirmClose}
                            buttonElement={<Button className='formNotesCancel' onClick={showWarningDialog}> CANCEL </Button>}
                            visible={visibleWarningDialog} onCancelWarning={hideWarningDialog}>
                        </WarningDialog> :
                        <Button className='formNotesCancel' id="notes-cancel" onClick={onCancel}>
                            CANCEL
                        </Button>
                    }
                    <SuccessDialog className='formNotesSaveActive' subtitleText={savingText} onClick={onSave} onCloseClick={onClose}
                        buttonElement={!note.value ? 'SAVE' : 'UPDATE'}
                        visible={visibleSuccessDialog}>
                    </SuccessDialog>
                </div>
            </DialogFooter>
        </ExpansionPanel>
    );
}

export default NotesPanel;