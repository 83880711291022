import React, { useEffect } from "react";

import usePageFramework from "@utilities/hooks/usePageFramework";
import OrganizerUploads from '@components/OrganizerUploads';
import DashboardSubMenu from '@components/header/dashboardSubMenu';
import DashboardGrid from '@components/dashboard/dashboardGrid';
import EntryExperienceModal from '@components/entryExperience/entryModal';
import useAdaptive from "@utilities/hooks/useAdaptive";

const ClientDashboard = () => {
    const { dispatch, selectState, REDUX, ACTION } = usePageFramework();
    const dashboard = selectState(REDUX.DASHBOARD);
    const { isDesktopDevice, isDesktop } = useAdaptive();

    const categories = dashboard ? dashboard.filter((category) => category.title !== 'Opportunity Services') : [];

    useEffect(() => {
        dispatch(ACTION.setEntryModalOpen(true));
    }, []);

    return (
        <>
            <OrganizerUploads style={{ top: '30px' }} />
            <>

                <div className="dashboardSize">
                    {(isDesktopDevice || isDesktop) && <DashboardSubMenu />}
                    <DashboardGrid categories={categories} dashboardVersion={2} />
                </div>
            </>
          <EntryExperienceModal/>
        </>
    );
};

export default ClientDashboard;
