import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import EntityRenderer from '@components/entityRenderer/index';
import getVehicleFormSection from './vehicleFormSection';
import { triggeredEvent, sectionFieldLogic } from './vehicleLogicTrigger';

function NewVehicleEntity(props) {
    const { parentFormName, isEntityVisible, isLineSection, lineItem, isFormExpandable, isParentEntityNA, year, isExportForm } = props;
    const [sectionState, setSectionState] = useState('');
    const { REDUX } = usePageFramework();
    const [barcodeName, setBarcodeName] = useState('');

    useEffect(() => {
        if (parentFormName.startsWith(REDUX.BUSINESS_INCOME)) setBarcodeName('barcodeVehicleExpensesScheduleC');
        else if (parentFormName.startsWith(REDUX.RENTAL_INCOME)) setBarcodeName('barcodeVehicleExpensesScheduleE');
        else if (parentFormName.startsWith(REDUX.FARM_INCOME)) setBarcodeName('barcodeVehicleExpensesScheduleF');
    }, 
    //eslint-disable-next-line
    [parentFormName]);

    useEffect(() => {
        const sections = getVehicleFormSection({ isFormExpandable, isLineSection, year, barcodeName: barcodeName });

        setSectionState(sections);
        // eslint-disable-next-line
    }, []);

    return (
        !sectionState || !isEntityVisible ?  <></> : <EntityRenderer isFormExpandable={isFormExpandable} isFormPresent={true} isFormSection={true} sections={sectionState} key="NewVehicleEntity" formName={REDUX.VEHICLE} iscomingFromVehicleEntity parentFormName={parentFormName} 
            isLineSection={isLineSection} lineItemCount={lineItem || 0} triggeredEvent={triggeredEvent} sectionFieldLogic={sectionFieldLogic} isParentEntityNA={isParentEntityNA} isExportForm={isExportForm} barcodeName={barcodeName}
        />
    );
}

export default NewVehicleEntity;