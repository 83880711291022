/* eslint-disable no-unused-vars */
import {
	GridCell, Grid, DropdownMenu, Divider, useToggle, Button, FileInput,
	Form, FontIcon, TextIconSpacing
} from 'react-md';
import React from 'react';
// import * as IMG from '../../../../../constants/images';
import * as IMG from '../../../../src/constants/images';
import { frameworkSetter } from '../../../hooks/usePageFramework';
import InlineUploadButton from '../buttons/uploadButtons/inlineUploadButton/index';

import nextId from 'react-id-generator';
import BlobFileViewer from '../../dialogBox/blobFileViewer/index';
import moment from 'moment';
import { MoreVertSVGIcon } from '@react-md/material-icons';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useAdaptive from '../../../hooks/useAdaptive';
import { ACCEPTED_FILE_TYPES } from '../../../../src/constants/document';
import { SCREEN_BREAKPOINTS } from '../../../constants/screenSizes';

import './uploadFieldMobile.css';

function UploadField(props) {
	// eslint-disable-next-line no-unused-vars
	const { field, index, isFormLocked, fns, drawerStyle } = props;
	const sectionName = field.sectionName;
	const { selectState, REDUX, onFileRemoval, onFileUpload } = frameworkSetter.usePageFramework();
	const [visibleViewBtn, showViewBtn, hideViewBtn] = useToggle(false);
	const { isLaptopOrDesktop } = useAdaptive();
	const uploadList = selectState(REDUX.UPLOAD_LIST);

	const filteredList = uploadList?.filter(x => x?.section?.toLowerCase().trim() === sectionName.toLowerCase().trim());
	
	const setLineUploaded = () => {
		fns.saveForm(field);
	};
	const onChange = (event) => onFileUpload(event, null, sectionName, filteredList, { updateState: setLineUploaded });
	const onUploadClicked = () => {
		if (visibleViewBtn) {
			hideViewBtn();
		}
		else {
			showViewBtn();
		}
		fns.saveForm();
	}
	const buildUploadListButton = () => {
		const buttonIcon = isLaptopOrDesktop
			? (visibleViewBtn ? IMG.hideUploadsIcon : IMG.viewUploadsIcon)
			: (IMG.viewUploadsMobileIcon);
		return (
			<Button className={`lineUploadButton2 ${!isLaptopOrDesktop ? ' lineUploadButton2Mobile' : ''}`} onClick={onUploadClicked} >
				<img alt={visibleViewBtn ? 'Hide uploaded files button' : 'View uploaded files button'}
					src={buttonIcon ?? ''} />
				{ !isLaptopOrDesktop && <span>{visibleViewBtn ? 'Hide' : 'View'} Files</span> }
			</Button>
		);
	};
	const { width } = useWindowDimensions();
	const getScreenCol = (col) => {
		const colTab = col === 10 ? 6 : col === 6 ? 3 : col === 3 ? 2 : col === 2 ? 1 : col;
		return width < 1025 ? colTab : col;
	};
	const lineUploadItems = (file) => {
		return [
			{
				children: 'Remove File',
				onClick: (event) => onFileRemoval(event, file),
				className: 'removeUpload'
			}
		];
	};
	// const isLineUploadDrawer = true;
	const style = drawerStyle ? drawerStyle : 'uploadDrawerContainer';
	const isLineUploadDrawer = style === 'lineUploadDrawerContainer';


	const uploadedFileList = filteredList.map((file, idx) =>
		<React.Fragment key={idx}>
			<Grid key={`${sectionName}-${idx}`} id={`${sectionName}-${nextId()}`} className='uploadDrawerRow'>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
				<GridCell className='fileName' colSpan={isLaptopOrDesktop ? getScreenCol(6) : 11 }>
					<BlobFileViewer
						key={`blobFileViewer-${idx}`}
						id={nextId()}
						className="fileViewDetails"
						blobFile={file}
						fileName={file.name}
					/>
				</GridCell>
				{
					!isLaptopOrDesktop && <GridCell colSpan={1} className='fileName alignRight'>
						<img
							src={IMG.deleteIcon}
							className="deleteIcon"
							onClick={(event) => onFileRemoval(event, file)}
						/>
					</GridCell>
				}
				{isLaptopOrDesktop && <GridCell className='fileName' colSpan={getScreenCol(isLineUploadDrawer ? 2 : 3)}>
					{file.uploaded_by || 'None'}
				</GridCell>}
				{
					isLaptopOrDesktop
						? <GridCell className='fileName' colSpan={getScreenCol(isLineUploadDrawer ? 1 : 2)}>
							  {moment(new Date(file.createdOn)).format('MMM DD, YYYY') || 'None'}
						  </GridCell>
						: <GridCell className='fileName' colSpan={12}>
							  Date Uploaded: {moment(new Date(file.createdOn)).format('MMM DD, YYYY') || 'None'}
						  </GridCell>
				}
				{
					isLaptopOrDesktop && <GridCell colSpan={1} className="fileList">
						<DropdownMenu
							disabled={isFormLocked}
							key={`dpmenu-${idx}`}
							id={nextId()}
							items={lineUploadItems(file)}
							buttonType="icon"
						>
							<MoreVertSVGIcon />
						</DropdownMenu>
					</GridCell>
				}
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
			</Grid>
			<Grid>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>} 

				{/* {closeButton} */}
				<GridCell colSpan={12}>
					<Divider />
				</GridCell>
			</Grid>
		</React.Fragment>
	);

	const inlineUploadButton = () => <InlineUploadButton
		key={`iub-${index}`}
		className={`lineUploadButton1${!isLaptopOrDesktop ? ' lineUploadButton1Mobile' : ''}`}
		index={index}
		onClick={setLineUploaded}
		isFormLocked={isFormLocked}
		lineItemSectionName={sectionName}
		isMobileView={!isLaptopOrDesktop}
	/>

	const colsText = width < SCREEN_BREAKPOINTS.TABLET ? 12 : 9;
	const colsButton = width < SCREEN_BREAKPOINTS.TABLET ? 12 : 3;

	return (
		<Grid className={`${visibleViewBtn ? 'uploadDrawerOpen' : 'uploadDrawerClosed'}${!isLaptopOrDesktop ? ' uploadFieldMobile' : ''}`}>
			<GridCell colSpan={colsText} className='labelText'> {field.label} </GridCell>
			<GridCell colSpan={colsButton} key={`cell-${field.name}`}>
				<div
					key={`ivbgc-${index}`}
					className={`formLineButton${!isLaptopOrDesktop ? ' formLineButtonMobile' : ''}`}
					style={{float: "right"}}
					data-testid='upload-kebab-line-buttons'
				>
					<div className="formLineActionsBtn">
						{filteredList?.length ? buildUploadListButton() : inlineUploadButton()}
					</div>
				</div>
			</GridCell>

			{uploadedFileList.length > 0 && visibleViewBtn && <GridCell colSpan={12} key={`cell-${field.name}`} className='uploadListWrapper'>
				<Grid className='uploadDrawerHeaderContainer'>
					<GridCell colSpan={12}><Divider /></GridCell>
					<GridCell colSpan={6} className="headerLabelsLeft">
						File Name
					</GridCell>
					<GridCell colSpan={3} className="uploadHeaderheaderLabelsLeft">
						Uploaded By
					</GridCell>
					<GridCell colSpan={3} className="uploadHeaderheaderLabelsLeft">
						Date Uploaded
					</GridCell>
					<GridCell colSpan={1}></GridCell>
					<GridCell colSpan={1}></GridCell>
					<GridCell colSpan={12}><Divider /></GridCell>
				</Grid>
				{uploadedFileList}
				{/* <GridCell colSpan={12}><Divider /></GridCell> */}
				<GridCell colSpan={6}>
					<Form>
						<FileInput
							disabled={isFormLocked}
							style={isFormLocked ? { cursor: 'default' } : {}}
							theme='clear'
							themeType='flat'
							id={sectionName}
							onChange={onChange}
							buttonType={'text'}
							disableIconSpacing={true}
							multiple={true}
							icon={<TextIconSpacing
								icon={
									<FontIcon className="addIcon">
										add
									</FontIcon>
								}>
							</TextIconSpacing>}
							className={'addMoreFile'}
							accept={ACCEPTED_FILE_TYPES}
						>
							Upload More Files
						</FileInput>
					</Form>
				</GridCell>
			</GridCell >}

		</Grid>

	);
}

export default UploadField;