
// common modules
import React, { useEffect, useState } from 'react';
import { ExpandMoreSVGIcon, ExpandLessSVGIcon } from '@react-md/material-icons';
import {
    GridCell,
    Grid,
    Divider,
    Button,
    TextIconSpacing
} from 'react-md';
import useAdaptive from "@utilities/hooks/useAdaptive";

// custom modules
import { getSwimlaneDetails, taxReturnStatusIsValid } from '@utilities/services/taxReturnService';
import TaxReturnCard from '@components/dashboard/dashboardCard/taxReturnCard';
import { SWIMLANE_CARD_COUNT_LABEL, SWIMLANE_CARD_COUNT_LABEL_PLURAL } from '@utilities/constants/strings';
import { taxReturnStatuses } from '@utilities/constants/taxReturn';

const _SupportedDashboardVersions = [2];

function TaxReturnSwimlane(props) {
    const {
        dashboardVersion,
        taxReturnStatus,
        taxYear,
        cardsPerRow,
        isDesktop,
        isAdmin,
        isDesktopDevice,
        organizerId
    } = props;
    const { isLaptop, isMobile, isTablet } = useAdaptive();

    const [isViewMore, setIsViewMore] = useState(true);
    const [showSwimlaneButton, setShowSwimlaneButton] = useState(false);

    const swimlaneDetails = getSwimlaneDetails(taxYear, taxReturnStatus,organizerId);
    
    const enableViewToggle = swimlaneDetails.cards.length > cardsPerRow;

    const onViewToggleClick = () => {
        if (enableViewToggle) {
            // toggle view more will only be available if there are more than cardsPerRow cards is exceeded.
            setIsViewMore(!isViewMore);
        }
    }

    const filteredCards = isViewMore
        ? swimlaneDetails?.cards
        : swimlaneDetails?.cards.slice(0, cardsPerRow);
   
    useEffect(() => {
        if (taxReturnStatus === taxReturnStatuses.signed) {
            setIsViewMore(false);
        }
    }, [taxReturnStatus]);

    useEffect(() => {
        const showSwimlaneButton = (isDesktop || isAdmin || isDesktopDevice) && swimlaneDetails?.cards?.length > cardsPerRow;

        setShowSwimlaneButton(showSwimlaneButton);
    }, [isDesktop, isAdmin, isDesktopDevice, cardsPerRow, swimlaneDetails]);

    const isValidTaxReturnStatus = taxReturnStatusIsValid(taxReturnStatus) && taxReturnStatus !== taxReturnStatuses.declined;

    return taxYear && isValidTaxReturnStatus && _SupportedDashboardVersions.some(v => v === dashboardVersion)
        ? (
            <GridCell key={`category-taxReturn`} colSpan={12} className="taxReturnSwimLane categoryCell" data-testid={'category-taxReturn'}>
                <GridCell key={`categoryTitle-taxReturn`} colSpan={12} className="dashboardCategoriesHeader">
                    <div className="dashboardCardHeaderTitle">
                        <h1 className="dashHeaderTitle">
                            {swimlaneDetails.title} {showSwimlaneButton &&<span>{swimlaneDetails.cards.length} {swimlaneDetails.cards.length > 1 ? SWIMLANE_CARD_COUNT_LABEL_PLURAL : SWIMLANE_CARD_COUNT_LABEL}</span>}
                        </h1>
                    </div>
                    {showSwimlaneButton && <div className={`viewAllBtn`}>
                        <Button
                            id="combined-button-2"
                            className={`viewAllCards ${!enableViewToggle ? 'notClickable' : ''}`}
                            onClick={onViewToggleClick}
                            disabled={!enableViewToggle}
                        >
                            <TextIconSpacing
                                icon={isViewMore
                                    ? <ExpandLessSVGIcon className="expandCardsButton" />
                                    : <ExpandMoreSVGIcon className="expandCardsButton" />
                                }
                                iconAfter
                            >
                                {`View ${isViewMore ? 'Less' : 'All'}`}
                            </TextIconSpacing>
                        </Button>
                    </div>}
                </GridCell>
                <GridCell colSpan={12} id="dashboardCategoryDivider">
                    <Divider />
                </GridCell>
                <Grid className="dashboardGridListCategory">
                    {
                        filteredCards.map((card, index) => {
                            return (
                            <GridCell key={`category-card-${index}`} className="cardGridCell" colSpan={isMobile ? 12 : isTablet ? 4 : isLaptop ? 3 : 2 }>
                                <TaxReturnCard card={card} taxReturnStatus={taxReturnStatus} organizerId={organizerId}/>
                            </GridCell>
                            );
                        })
                    }
                </Grid>
            </GridCell>
        )
        : <></>;
}

export default TaxReturnSwimlane;