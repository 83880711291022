import {
    RETIREMENT_CONT_INDIVIDUAL_ACCOUNTS_UPLOAD,
    RETIREMENT_CONT_SELF_EMPLOYED_UPLOAD,
} from '@utilities/constants/strings';

const retirementFn = (year) => {
    const sections = [
        {
            title: 'Individual Retirement Accounts (IRA)',
            shortTitle: 'Individual Retirement Accounts',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    axcessGroup: {
                        pull: {
                            fromSections: ['IRA Information'],
                            fieldIdentifiers: ['IFDS8606.1', 'IFDS8606.0', 'IFDS8606.17', 'IFDS8606.18', 'IFDS8606.19'],
                            lineSplitterType: 'splitRemove',
                            lineSplitters: [
                                ['IFDS8606.19'],
                                ['IFDS8606.17', 'IFDS8606.18'],
                            ],
                            lineFilterType: 'any',
                            lineFilters: [
                                { keys: ['IFDS8606.17', 'IFDS8606.18'], filterType: 'inGroupNotZero' },
                                { keys: ['IFDS8606.19'], filterType: 'inGroupNotZero' },
                            ],
                        },
                    },
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDS8606.1', type: 'select', lookup: 'PAYER', prior: true },
                        { label: 'Institution', name: 'IFDS8606.0', type: 'freeText', prior: true },
                        {
                            label: 'Type', name: 'individualRetirementAccountsType', type: 'select', lookup: 'IRA', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDS8606.17', 'IFDS8606.18', 'IFDS8606.19'],
                                fn: 'iraTypePull',
                            }
                        },
                        //If amount in: IFDS8606.17 or  IFDS8606.18 = Traditional, IFDS8606.19 = Roth
                        {
                            label: '$ 0', wrapLabel: '2019 Amount', name: 'individualRetirementAccountsPyAmount', type: 'label', isTotal: true, prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDS8606.17', 'IFDS8606.18', 'IFDS8606.19'],
                                fn: 'iraAmountPull',
                            }
                        },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'individualRetirementAccountsAmount', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Retirement Account Details', col: 2, colTablet: 2, align: 'left' },
                            { label: 'My Account is with...', col: 3, colTablet: 1, align: 'left' },
                            { label: 'Type', col: 2, colTablet: 2, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 1, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 1, align: 'right' },
                            { label: 'Contribution Info / Form 5498', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Individual Retirement Account (IRA)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 5, },
                            { type: 'totalMoney', name: 'individualRetirementAccountsPyAmount', col: 2, colTablet: 1, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'individualRetirementAccountsAmount', col: 2, colTablet: 1, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: RETIREMENT_CONT_INDIVIDUAL_ACCOUNTS_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                },
            ],
        },
        {
            title: 'Self-Employed Retirement Plans (SIMPLE/SEP/Defined Benefit Plan)',
            shortTitle: 'Self-Employed Retirement Plans',
            isVisible: true,
            sectionId: 2,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Identification Data'],
                            fieldPrefixes: ['IFDSKSSI'],
                            fieldIdentifiers: [
                                'IFDSKSSI.0',
                                'IFDSKSSI.4', 'IFDSKSSI.11', 'IFDSKSSI.12', 'IFDSKSSI.16', // Defined Benefit
                                'IFDSKSSI.5', 'IFDSKSSI.13', 'IFDSKSSI.17', // SEP
                                'IFDSKSSI.6', 'IFDSKSSI.14', 'IFDSKSSI.18', // SIMPLE
                                'IFDSCH1.20',
                            ],
                            lineSplitterType: 'splitInclude',
                            lineSplitters: [
                                {
                                    splitOnFields: ['IFDSKSSI.4', 'IFDSKSSI.11', 'IFDSKSSI.12', 'IFDSKSSI.16'],
                                    includeFields: ['IFDSKSSI.0', 'IFDSKSSI.4', 'IFDSKSSI.11', 'IFDSKSSI.12', 'IFDSKSSI.16', 'IFDSCH1.20']
                                }, // Defined Benefit Amounts
                                {
                                    splitOnFields: ['IFDSKSSI.5', 'IFDSKSSI.13', 'IFDSKSSI.17'],
                                    includeFields: ['IFDSKSSI.0', 'IFDSKSSI.5', 'IFDSKSSI.13', 'IFDSKSSI.17', 'IFDSCH1.20']
                                }, // SEP
                                {
                                    splitOnFields: ['IFDSKSSI.6', 'IFDSKSSI.14', 'IFDSKSSI.18'],
                                    includeFields: ['IFDSKSSI.0', 'IFDSKSSI.6', 'IFDSKSSI.14', 'IFDSKSSI.18', 'IFDSCH1.20']
                                }  // Simple
                            ],
                            mergeWithOtherData: [
                                { section: 'Schedule 1 - Additional Income and Adjustments to Income', id: 'Government', worksheet: 'Government', key: 'IFDSCH1.20' },
                            ]
                        },
                    },
                    fields: [
                        {
                            label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSKSSI.0', type: 'select', lookup: 'PAYER', prior: true,
                            pullCalculation: { defaultIfEmpty: 'T' }
                        },
                        { label: 'Institution', name: 'selfEmployedtAccountsInstitution', type: 'freeText' },
                        {
                            label: 'Type', name: 'selfEmployedRetirementAccountsType', type: 'select', lookup: 'SELF_EMPLOYED_RETIREMENT_PLANS', prior: true,
                            pullCalculation: {
                                keyIdentifiers: [
                                    'IFDSKSSI.4', 'IFDSKSSI.5', 'IFDSKSSI.6', // Checkboxes
                                    'IFDSKSSI.11', 'IFDSKSSI.12', 'IFDSKSSI.16', // Defined Benefit Amounts
                                    'IFDSKSSI.13', 'IFDSKSSI.17', // SEP
                                    'IFDSKSSI.14', 'IFDSKSSI.18', // Simple
                                ],
                                fn: 'sepTypePull',
                            }
                        },
                        {
                            label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSKSSI.18', type: 'label', isTotal: true, prior: true,
                            pullCalculation: {
                                keyIdentifiers: [
                                    'IFDSKSSI.4', 'IFDSKSSI.5', 'IFDSKSSI.6', // Checkboxes
                                    'IFDSKSSI.11', 'IFDSKSSI.12', 'IFDSKSSI.16', // Defined Benefit Amounts
                                    'IFDSKSSI.13', 'IFDSKSSI.17', // SEP
                                    'IFDSKSSI.14', 'IFDSKSSI.18', // Simple
                                    'IFDSCH1.20',
                                ],
                                fn: 'sepAmountPull',
                            }
                        },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSKSSI.18', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Retirement Plan Details', col: 2, colTablet: 2, align: 'left' },
                            { label: 'My Account is with...', col: 3, colTablet: 1, align: 'left' },
                            { label: 'Type', col: 2, colTablet: 2, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 1, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 1, align: 'right' },
                            { label: 'Contribution Info / Form 5498', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Self-Employed Retirement Plan (SIMPLE/SEP/Defined Benefit Plan)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 5, },
                            { type: 'totalMoney', name: 'IFDSKSSI.18', col: 2, colTablet: 1, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSKSSI.18', col: 2, colTablet: 1, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: RETIREMENT_CONT_SELF_EMPLOYED_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                },
            ]
        },
        {
            title: 'Other Retirement Contributions',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 3,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Please explain below any additional retirement contributions:', type: 'label' },
                    ]
                },
                {
                    groupId: 2,
                    fields: [
                        { label: 'Start typing to provide additional information', name: 'retirementContributionsAdditionalInfo', type: 'textarea', col: 12 }
                    ]
                }
            ]
        }
    ];

    return sections;
};

export default retirementFn;