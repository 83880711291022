import { Validators } from 'cla-formrenderer';
import { setConditionalFieldValidation } from '@components/formRenderer/triggers';
import * as EVENT from '@utilities/constants/triggerKeys';
const setCyMilesRequired = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[1].groups[0].lineItems.forEach((lineItem) => {
            lineItem[2].validations = { ...Validators.required() };
        });
    } 
    if (event?.trim() === '') {
        name[1].groups[0].lineItems.forEach((lineItem) => {
            lineItem[2].validations = null;   
            lineItem[2].error = false;
            lineItem[2].errorMessage = null;
        });
    } 

    setFormSections(name);
};

const setMilesHaveAnotherVehicleRequired = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[8].fields[1].validations = { ...Validators.required() };       
    } 

    if (event?.trim() === '') {
        name[0].groups[8].fields[1].validations = null;
        name[0].groups[8].fields[1].error = false;
        name[0].groups[8].fields[1].errorMessage = null;
    } 

    setFormSections(name);
};

const setMilesAvailableOffDutyRequired = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[9].fields[1].validations = { ...Validators.required() }; 
    } 

    if (event?.trim() === '') {
        name[0].groups[9].fields[1].validations = null;
        name[0].groups[9].fields[1].error = false;
        name[0].groups[9].fields[1].errorMessage = null;
    } 

    setFormSections(name);
};

const showVehExpSupEvidence = (options) => {
    const { section, field, fns } = options;
    section.groups[5].isVisible = field.default === EVENT.KEY_YES;
    fns.updateEntityUniqueValue({group: section.groups[5], field: field});
};

const showVehExpBusEvidence = (options) => {
    const { section, field, fns } = options;
    section.groups[7].isVisible = field.default === EVENT.KEY_YES;
    fns.updateEntityUniqueValue({group: section.groups[7], field: field});
};

const showVehFairMarketValue = (options) => {
    const { section, field } = options;
    section.groups[2].hasDivider =  field.default !== EVENT.KEY_YES;
    section.groups[3].isVisible = field.default === EVENT.KEY_YES;
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'setCyMilesRequired':
            setCyMilesRequired(event, options);
        break;
        case 'setMilesHaveAnotherVehicleRequired':
            setMilesHaveAnotherVehicleRequired(event, options);
        break;
        case 'setMilesAvailableOffDutyRequired':
            setMilesAvailableOffDutyRequired(event, options);
        break;
    default:
        break;
    }
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) { 
        case 'setConditionalFieldValidation':
            setConditionalFieldValidation(options);
        break;
        case 'showVehExpSupEvidence':
            showVehExpSupEvidence(options);
        break;
        case 'showVehExpBusEvidence':
            showVehExpBusEvidence(options);
        break;
        case 'showVehFairMarketValue':
            showVehFairMarketValue(options);
        break;
    default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};