
const vehicleEntityNotApplicableChecker = (globalState, currentFormKeys, vehicleEntitiesAndParentEntities) => {

    const disabledRowIndexes = [];

    let notApplicable = false;

    let hasVehicleEntity = false;

    currentFormKeys.forEach((formKey) => {

        const formSections = globalState.get(formKey);

        if (formSections && formSections !== null) {
            formSections.forEach((section) => {

                if (section && section.isVisible !== false) {

                    if (section.groups) {
                        section.groups.forEach((group) => {
                            if (group.isVisible !== false) {

                                if (group.lineItems) {

                                    if (group.entities) {
                                        group.entities.forEach((entity) => {

                                            if (entity.sections) {
                                                entity.sections.forEach((entitySection) => {
                                                    if (entitySection.sectionType && entitySection.sectionType === 'newVehicleEntity') {

                                                        hasVehicleEntity = true;

                                                    }
                                                })
                                            }
                                        })
                                    }

                                    group.lineItems.forEach((lineItem, index) => {

                                        if (lineItem[0].notApplicable === true) {

                                            notApplicable = true;

                                            if (hasVehicleEntity) {
                                                disabledRowIndexes.push(index);
                                            }
                                        }
                                    })


                                }

                            }
                        })
                    }

                }


            })
        }



    })

    function filterVehiclesNotDisabled(vehicleEntity, disabledRowIndex) {

        const vehicleEntitiesNotDisabled = [];

        for (const key in vehicleEntity) {
            const parentEntityKeys = vehicleEntity[key].parentEntityKeys;

            let allKeysDisabled = true;

            for (const key of parentEntityKeys) {

                // Extract the last number from the key
                const lastDashIndex = key.lastIndexOf('-');
                if (lastDashIndex !== -1) {
                    const lastNumber = parseInt(key.substring(lastDashIndex + 1), 10);

                    //if not all parentEntities of the vehicleEntity are disabled then the vehicleEntity will still have its errors read
                    if (!disabledRowIndex.includes(lastNumber)) {
                        allKeysDisabled = false;
                        break;
                    }
                }
            }

            if (!allKeysDisabled) {
                vehicleEntitiesNotDisabled.push(key);
            }
        }

        return vehicleEntitiesNotDisabled;
    }

    const vehicleEntitiesNotDisabled = filterVehiclesNotDisabled(vehicleEntitiesAndParentEntities, disabledRowIndexes);

    if (hasVehicleEntity) {
        return vehicleEntitiesNotDisabled;
    } else {
        return [];
    }

}

export default vehicleEntityNotApplicableChecker;