const fieldDataSetter = {
  val: {},
  /**
     * @param {null} value
     */
  set inputState(value) {
    this.val = value;
  },
  //eslint-disable-next-line
  get inputState() {
    if (this.val) {
      return this.val;
    }
  }
}

export default fieldDataSetter;