import { Validators } from 'cla-formrenderer';
import {
    ENERGY_EFFICIENT_RESIDENTIAL_PROPERTY_UPLOAD,
    ENERGY_EFFICIENT_RENEWABLE_ENERGY_UPLOAD,
    ENERGY_EFFICIENT_ALT_VEHICLE_UPLOAD,
} from '@utilities/constants/strings';

const vehicleEnergyFn = (year) => {
    const sections = [
        [
            {
                title: 'Vehicle Summary',
                isVisible: true,
                sectionId: 1,
                isCollapsed: true,
                isDenseRow: true,
                groups: [
                    {
                        groupId: 1,
                        fields: [
                            {
                                label: 'Year',
                                name: 'alternativeMotorYear',
                                type: 'year',
                                validations: { ...Validators.year() },
                                logicFunction: [{ trigger: 'setVehicleSummaryLabel' }]
                            },
                            {
                                label: 'Make',
                                name: 'alternativeMotorMake',
                                type: 'freeText',
                                logicFunction: [{ trigger: 'setVehicleSummaryLabel' }]
                            },
                            { label: 'Model', name: 'alternativeMotorModel', type: 'freeText' },
                            { label: 'VIN', name: 'alternativeMotorVIN', type: 'vin' },
                        ],
                    }
                ]
            },
        ]
    ];

    const lineItemGroup = [
        {
            title: 'Residential Energy Property Credit',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: `Please provide more information about any energy efficient improvements made to your home in ${year.current}.`, type: 'label', class: 'groupDetails2' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    fields: [
                        { label: 'Energy Efficiency Improvements', name: 'residentialEnergyCreditImprovement', type: 'select', lookup: 'ENERGY_IMPROVEMENTS' },
                        { label: '', name: '', type: 'label' },
                        { label: 'Cost', name: 'residentialEnergyCreditCost', type: 'money', isTotal: true },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Equipment or Energy Efficiency Improvements', smallScreenLabel: 'Credit Details', col: 5, colTablet: 3 },
                            { col: 4, colTablet: 2 },
                            { label: 'Cost (Do Not Include Labor or Installation)', col: 2, colTablet: 2, align: 'right' },
                            { label: 'Manufacturer\'s Certification Statement and Purchase Invoice', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Residential Energy Property Credit', icon: 'add' },
                            { type: 'label', label: 'Total', col: 9, colTablet: 5 },
                            { type: 'totalMoney', name: 'residentialEnergyCreditCost', col: 2, colTablet: 2, label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: ENERGY_EFFICIENT_RESIDENTIAL_PROPERTY_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                },
                {
                    groupId: 3,
                    isVisible: true,
                    hasDivider: false,
                    fields: [
                        { label: 'Have you taken a residential energy credit in the past?', name: 'pastResidentialEnergyCreditLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'pastResidentialEnergyCredit', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, triggered: 'setHasPastResidentialEnergyCredit' },
                    ]
                },
                {
                    groupId: 4,
                    isSingleColumn: true,
                    isVisible: false,
                    fields: [
                        { label: ' Total amount of residential energy credit that you have taken in the past.', name: 'pastResidentialEnergyCreditAmountLabel', type: 'label', col: 10 },
                        { label: '', name: 'pastResidentialEnergyCreditAmount', type: 'money', isTotal: true, col: 2, }
                    ]
                },
            ]
        },
        {
            title: 'Residential Renewable Energy Tax Credit',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 2,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: `Please provide more information about any renewable energy efficient purchases and installations made to your home in ${year.current}.`, type: 'label', class: 'groupDetails2' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    fields: [
                        { label: 'Renewable Energy Property', name: 'residentialRenewableCreditImprovement', type: 'select', lookup: 'ENERGY_RENEWABLE' },
                        { label: '', name: '', type: 'label' },
                        { label: 'Cost', name: 'residentialRenewableCreditCost', type: 'money', isTotal: true },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Renewable Energy Property', smallScreenLabel: 'Credit Details', col: 5, colTablet: 3 },
                            { col: 4, colTablet: 2 },
                            { label: 'Cost (Include Labor and Installation)', col: 2, colTablet: 2, align: 'right' },
                            { label: 'Manufacturer\'s Certification Statement and Purchase Invoice', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Residential Renewable Energy Tax Credits', icon: 'add' },
                            { type: 'label', label: 'Total', col: 9, colTablet: 5 },
                            { type: 'totalMoney', name: 'residentialRenewableCreditCost', col: 2, colTablet: 2, label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: ENERGY_EFFICIENT_RENEWABLE_ENERGY_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                }
            ]
        },
        {
            title: 'Alternative Motor Vehicle Credit',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 3,
            hasDenseRow: true,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        {
                            label: 'Please provide additional information about your hybrid/electric vehicle purchase.',
                            type: 'label',
                            class: 'groupDetails2'
                        },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    fields: [
                        {
                            label: '',
                            name: 'vehicleSummary',
                            type: 'label',
                            styles: { wordBreak: 'break-word', whiteSpace: 'pre-line', paddingTop: 0 }
                        },
                        {
                            label: 'View Details',
                            name: 'additionalInfo',
                            type: 'viewButton',
                            viewSectionId: 100,
                            styles: { margin: '-12px 0 0 -50px' }
                        },
                        {
                            label: 'Type',
                            wrapLabel: 'Type',
                            name: 'alternativeMotorType',
                            type: 'select',
                            lookup: 'ENERGY_ALTERNATIVES'
                        },
                        {
                            label: 'Date',
                            wrapLabel: 'Date Purchased',
                            name: 'alternativeMotorDatePurchased',
                            type: 'date',
                            axcessTranslate: 'date'
                        },
                        {
                            label: 'Cost',
                            name: 'alternativeMotorCost',
                            type: 'money',
                            isTotal: true
                        },
                        {
                            label: 'Use',
                            wrapLabel: 'Type of Use',
                            name: 'alternativeMotorTypeOfUse',
                            type: 'select',
                            lookup: 'TYPE_OF_USE'
                        },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    lineSections: sections[0],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            {
                                label: 'Vehicle Summary',
                                col: 2,
                                colTablet: 2,
                                styles: { paddingLeft: '45px' }
                            },
                            {
                                label: 'Vehicle Details',
                                col: 1,
                                colTablet: 1,
                                align: 'right',
                                styles: { paddingRight: '27px', whiteSpace: 'nowrap' }
                            },
                            {
                                label: 'Alternative Motor/Plug-In Electric',
                                smallScreenLabel: 'Credit Details',
                                col: 2,
                                colTablet: 1,
                                styles: { paddingLeft: '23px', whiteSpace: 'nowrap' }
                            },
                            {
                                label: 'Date Purchased',
                                col: 2,
                                colTablet: 1,
                                styles: { paddingLeft: '33px' }
                            },
                            { label: 'Cost', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Type of Use', col: 2, colTablet: 2, },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            {
                                type: 'buttons',
                                col: 12,
                                buttonTitle: 'Additional Alternative Motor Vehicle Credit',
                                icon: 'add'
                            },
                            { type: 'label', label: 'Total', col: 7, colTablet: 5, },
                            {
                                type: 'totalMoney',
                                name: 'alternativeMotorCost',
                                col: 2,
                                colTablet: 1,
                                year: 'current',
                                label: '$ 0'
                            },
                        ],
                        lineButtons: [
                            {
                                button: 'uploadNa',
                                uploadSectionName: ENERGY_EFFICIENT_ALT_VEHICLE_UPLOAD,
                                viewSectionId: 0
                            }
                        ]
                    }
                }
            ]
        },
    ];

    return lineItemGroup;
};

export default vehicleEnergyFn;