import { setConditionalFieldValidation } from '@components/formRenderer/triggers';
import { getDropDownValue } from '@utilities/helpers/getDropdownValue';
const setIsAllHsaDistForUnreimBursedMedicalExpenses = (event, options) => {
	// TODO: this function is to be used for disabling the entry Based on HSA selection
	// const { sections, formSections, setFormSections, inputState } = options;
	// const name = formSections ? formSections : sections;

	// name[3].groups[0].fields[4].isDisabled = event === EVENT_KEY_YES;
	// console.log(name);
	//  name[3].groups[0].fields[3].default = event;
	//  const prevState = name[3].groups[0].fields[4].inputState || 0;
	//  name[3].groups[0].fields[4].inputState = prevState + (prevState & inputState);

	// setFormSections(name);

};

//   ***function if setting the visibility instead of disabling the field
// const setIsAllHsaDistForUnreimBursedMedicalExpenses = (event, options ) => {
//     const { sections, formSections, setFormSections, inputState } = options;
//     const name = formSections ? formSections : sections;

//     name[3].groups[0].fields[5].isVisible = event === EVENT_KEY_NO;
//     name[3].groups[0].fields[4].default = event;
//     const prevState = name[3].groups[0].fields[4].inputState || 0;
//     name[3].groups[0].fields[4].inputState = prevState + (prevState & inputState);

//     setFormSections(name);

// };

const triggeredEvent = (trigger, event, options) => {
	switch (trigger) {
		case 'setIsAllHsaDistForUnreimBursedMedicalExpenses':
			setIsAllHsaDistForUnreimBursedMedicalExpenses(event, options);
			break;
		default:
			break;
	}
};

const setOtherDeductionsLabel = (options) => {
	const { group, parentGroup, parentIndex, field } = options;
	let name = '';
	const details = group.fields.filter(field => field.type !== 'ssn' && field.type !== 'date' && field.type !== 'freeText');
	const nameDropDown = details.pop();

	if (nameDropDown && nameDropDown.default) {
		name = getDropDownValue(field);
	}

	parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
		if (lineItemField.name === 'Spouse') {
			lineItemField.default = `${name}`;
		}
	});
};

const setHSASpouseRow = (options) => {
	const { group, clientMaritalStatus } = options;
	if (clientMaritalStatus && group?.lineItems && group?.lineItems[1]) {
		group.lineItems[1].isVisible = clientMaritalStatus === 'Married';
	}
};

const setDateValidation = (options) => {
	const { field } = options;
	var dateStr = field.default.split('/');
	var maxDateStr = field.maxDate.split('/');
	if (parseInt(dateStr[2]) < 1950 || parseInt(dateStr[2]) > parseInt(maxDateStr[2])) {
		field.error = true;
		field.errorMessage = `Year must be between 01/01/1950 and 12/31/${maxDateStr[2]}`;
	}
	else {
		field.validations = null;
		field.error = false;
		field.errorMessage = null;
	}
};

const sectionFieldLogic = (logicFunction, options) => {
	switch (logicFunction) {
		case 'setConditionalFieldValidation':
			setConditionalFieldValidation(options);
			break;
		case 'setOtherDeductionsLabel':
			setOtherDeductionsLabel(options);
			break;
		case 'setHSASpouseRow':
			setHSASpouseRow(options);
			break;
		case 'setDateValidation':
			setDateValidation(options);
			break;

		default:
			break;
	}
};

export {
	sectionFieldLogic,
	triggeredEvent
};