const aboutYourFn = () => {
    const sections = [
        {
            title: 'Tell Us',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    groupType: 'lineItem',
                    fields: [
                        { label: 'In 2021, did you (or your spouse):', type: 'label', class: 'labelLeft' },

                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Please select all that apply:', col: 12, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [],
                    }
                },
                {
                    groupId: 2,
                    fields: [
                        { label: 'Pay for any health insurance that was not reported on your W-2', name: 'aboutYourYearIsHealthInsuranceLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearIsHealthInsuranceSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Have any changes in your health insurance policies', name: 'aboutYourYearIsPolicyChangesLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearIsPolicyChangesSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Receive an advance credit for insurance obtained via the Marketplace (health insurance exchange)', name: 'aboutYourYearIsReceiveAdvanceCredit', type: 'label', col: 9 },
                        {
                            label: 'Receive an advance credit for insurance obtained via the Marketplace (health insurance exchange) \n     Please upload a copy of form 1095-A',
                            name: 'aboutYourYearIsReceiveAdvanceCreditSuhas',
                            type: 'label',
                            col: 9,
                            isVisible: false,
                            class: 'advanceCreditLabel labelText'
                        },
                        { label: 'Yes/No', name: 'aboutYourYearIsReceiveAdvanceInsurance', type: 'select', lookup: 'YESNO_DONTKNOW', triggered: 'setAdvanceCreditText', col: 3 },

                        // { label: 'Please upload a copy of Form 1095-A', name: 'aboutYourYearAdvanceCreditText', type: 'label', col: 9 , isVisible:false, isDenseRowNA: true},

                        { label: 'Start or sell a business', name: 'aboutYourYearIsStartBusinessLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearIsStartBusinessSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Have any debts canceled, forgiven, or modified', name: 'aboutYourYearIsHaveAnyDebtsLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearIsHaveAnyDebts', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Buy, sell, or exchange real estate (either personal or rental)', name: 'aboutYourYearIsBuyOrSellHouseLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearIsBuyOrSellHouseSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Have someone in your household pass away', name: 'aboutYourYearIsHouseholdPassingLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearIsHouseholdPassingSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Receive deferred, retirement, or severance compensation', name: 'aboutYourYearReceivedefferedLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearReceivedeffered', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Add a household employee and pay them more than $1,000 in any quarter or $2,100 annually', name: 'aboutYourYearIsHouseholdEmployeeLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearIsHouseholdEmployeeSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Make a gift (cash or non-cash) in excess of $15,000 to a single person', name: 'aboutYourYearIsGiftLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearIsGiftSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Make gift(s) of any amount to a trust', name: 'aboutYourYearIsMakeGiftLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearIsMakeGiftSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Receive any IRS or State notices', name: 'aboutYourYearIsNoticesLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearIsNoticesSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                    ]
                },
                // { // TODO: Need subheading
                //     groupId: 3,
                //     groupType: 'lineItem',
                //     fields: [
                //         { label: 'In 2020, did you (or your spouse):', type: 'label', class: 'labelLeft' },
                //     ],
                //     lineItems: [],
                //     lineItemDetails: {
                //         headerLabels: [],
                //         footerLabels: [],
                //     } 
                // },
                // {
                //     groupId: 4,
                //     isSingleColumn: true,
                //     hasDivider: false,
                //     fields: [
                //         { label: 'Work outside of the United States or receive pay from a foreign person or company', name: 'aboutYourYearIsForeignPay', type: 'checkbox', col: 12 },
                //         { label: 'Have a foreign financial account or financial assets (or signature authority over an account)', name: 'aboutYourYearIsForeignAssets', type: 'checkbox', col: 12 },
                //     ]
                // },
                {
                    groupId: 3,
                    groupType: 'lineItem',
                    fields: [
                        { label: 'COVID-Related Questions - In 2021, did you (or your spouse):', type: 'label', class: 'labelLeft' },
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [],
                        footerLabels: [],
                    }
                },
                {
                    groupId: 4,
                    fields: [
                        { label: 'Receive any Economic Impact Payments (stimulus payments)', name: 'covidRelatedQuestion1', type: 'label', col: 9 },
                        { label: 'Receive any Economic Impact Payments (stimulus payments) \n    Please upload a copy of IRS Letter 6475', name: 'covidRelatedText1', type: 'label', col: 9, isVisible: false, class: 'advanceCreditLabel labelText' },
                        { label: 'Yes/No', name: 'covidRelatedAnswer1', type: 'select', lookup: 'YESNO_DONTKNOW', triggered: 'setStimulusPaymentText', col: 3 },

                        { label: 'Receive any Advance Child Tax Payments (July - December)', name: 'covidRelatedQuestion2', type: 'label', col: 9 },
                        { label: 'Receive any Advance Child Tax Payments (July - December) \n    Please upload a copy of IRS Letter 6419', name: 'covidRelatedText2', type: 'label', col: 9, isVisible: false, class: 'advanceCreditLabel labelText' },
                        { label: 'Yes/No', name: 'covidRelatedAnswer2', type: 'select', lookup: 'YESNO_DONTKNOW', triggered: 'setChildTaxPaymentText', col: 3 },

                        { label: 'Receive any assistance from government programs such as PPP, employment/payroll credits, EIDL, etc. for your business', name: 'covidRelatedQuestion3', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'covidRelatedAnswer3', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },
                    ]
                },
            ]
        },
        {
            title: 'Cryptocurrency',
            isVisible: true,
            sectionId: 4,
            groups: [
                {
                    groupId: 4,
                    fields: [
                        { label: 'In 2021, did you (or your spouse):', type: 'label', class: 'labelLeft' },
                    ]
                },
                {
                    groupId: 1,
                    fields: [
                        { label: 'Buy, sell, or exchange Bitcoin or other virtual currencies', name: 'aboutYourYearIsExchangeVirtualCurrencyLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearIsExchangeVirtualCurrencySelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Own a cryptocurrency wallet', name: 'aboutYourYearOwnCryptoWalletLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearOwnCryptoWallet', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, triggered: 'showCryptoPlatformList' },

                        { label: 'Please list the platforms you use to hold cryptocurrency', name: 'cryptoPlatformListLabel', type: 'label', col: 9, class: 'labelTitleTextAreaIndented', isVisible: false },
                        { label: '', name: 'cryptoPlatformList', type: 'textarea', col: 12, maxRows: 3, class: 'fieldTextAreaIndented', isVisible: false },

                        {
                            label: 'Have any cryptocurrency transactions that you believe may not have been reported on your prior year tax return (for example, you purchased, sold, or exchanged virtual currency but were not provided with an account statement or similar tax document from the company administering your cryptocurrency account)',
                            name: 'aboutYourYearCryptoNotReportedLabel', type: 'label', col: 9, styles: { paddingTop: 0 }
                        },
                        { label: 'Yes/No', name: 'aboutYourYearCryptoNotReported', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },
                    ]
                },
                {
                    groupId: 2,
                    fields: [
                        { label: 'At any point during the year did you:', type: 'label', class: 'labelLeftSingleLine', name: 'aboutYourYearCryptoQuestionLabel', styles: { margin: '20px 0 0 0' } },
                    ]
                },
                {
                    groupId: 3,
                    fields: [
                        { label: 'Earn cryptocurrency through mining, staking or similar activities', name: 'aboutYourYearCryptoEarnLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearCryptoEarn', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Buy or sell initial coin offerings (ICO), nonfungible tokens (NFT), stable coins, or governance tokens', name: 'aboutYourYearCryptoBuySellLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearCryptoBuySell', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Hold cryptocurrency in a retirement account (e.g., IRA, 401(k), etc.)', name: 'aboutYourYearCryptoHoldLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearCryptoHold', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Own at least 10% ownership in a legal entity that has cryptocurrency transactions', name: 'aboutYourYearCryptoOwnLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearCryptoOwn', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                    ]
                }
            ]
        },
        {
            title: 'Foreign Matters',
            isVisible: true,
            collapseClass: 'collapsibleForm',
            sectionId: 2,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        {
                            label: `It is important for you to identify and provide account statements for any ownership or signature authority over a foreign bank account 
                                or other foreign financial assets which includes but is not limited to foreign: stocks, mutual funds, partnerships, bonds, retirement 
                                accounts, pensions, estates, trusts, annuities, swaps and derivatives. 
                                Failure to disclose penalties can be significant, starting at <u>$10,000</u> 
                                and can be upwards of 50% of the value of the asset.
                            `,
                            type: 'label',
                            isMarkup: true
                        },
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        {
                            label: 'Note that these rules do not apply to foreign investments held by U.S. mutual funds.',
                            type: 'label',
                            class: 'groupDetails2'
                        },
                    ]
                },
                {
                    groupId: 3,
                    fields: [
                        { label: 'During this year, have you performed work outside of the U.S. or paid non-US income taxes?', name: 'aboutYourYearForeignMattersLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearForeignMattersSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 }, // If Yes then the Foreign Income form should be required to be completed
                    ]
                },
                {
                    groupId: 4,
                    groupType: 'details',
                    fields: [
                        { label: 'During this year, did you own, or have the ability to authorize transactions for, any non-US:', type: 'label', class: 'labelLeftSingleLine' },
                    ]
                },
                {
                    groupId: 5,
                    fields: [
                        { label: 'Financial account', name: 'aboutYourYearFinancialAccountLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearFinancialAccountSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Directly-held investment or mutual fund', name: 'aboutYourYearDirectlyHeldLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearDirectlyHeldSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Entity', name: 'aboutYourYearEntityLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearEntitySelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Pension', name: 'aboutYourYearPensionLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearPensionSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Life insurance policy', name: 'aboutYourYearLifeInsuranceLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearLifeInsuranceSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Trust', name: 'aboutYourYearTrustLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearTrustSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Real estate', name: 'aboutYourYearRealEstateLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'aboutYourYearrealEstateSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },
                    ]
                }
            ]
        },
        {
            title: 'Additional Information',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            isDefaultExpanded: true,
            sectionId: 3,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', type: 'label', class: 'labelLeftLight' },
                    ]
                },
                {
                    groupId: 1,
                    fields: [
                        { label: 'Start typing to provide additional information', name: 'aboutYourYearAdditionalInfo', type: 'textarea', col: 12 }
                    ]
                }
            ]
        },
    ];

    return sections;
};

export default aboutYourFn;