// common modules
import React, { useState, useEffect } from 'react';
import { NativeSelect, FormMessage } from "@react-md/form";
import _ from 'lodash';

// custom modules
import './clientSelectField.css';
import api from '@utilities/claApi';

function ClientSelectField(props) {
	const { value = '', setValue } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [clients, setClients] = useState([]);

	const getResourceSet = (resourceUri, limit, offset) => {
		return api.get(`${resourceUri}?limit=${limit}&offset=${offset}`)
			.then(response => {
				return response.data;
			});
	};

	const loadClientsList = async () => {
		const limit = 250;
		let offset = 0;
		const clientList = [];
		const resourceUri = 'clients';

		getResourceSet(resourceUri, limit, offset).then(async (response) => {
			const { results, total } = response;
			clientList.push(...results);

			while (offset < total) {
				offset += limit;
				const nextPageResults = await getResourceSet(resourceUri, limit, offset);
				clientList.push(...nextPageResults.results);
			}

			const sortedClientList = _.orderBy(clientList, 'name', 'asc');

			setClients(sortedClientList);
			setIsLoading(false);
		}).catch((err) => {
			console.log(err);
			setHasError(true);
		}).finally(() => {
			setIsLoading(false);
		});
	};

	useEffect(() => {
		loadClientsList();
    // eslint-disable-next-line  
	}, []);

	const getFieldMessage = () => {
		if (isLoading) return 'Loading clients...';
		if (hasError) return 'Error loading clients';
		return '';
	};

	return (
		<>
			<NativeSelect
				id="client-select-field"
				data-testid={'admin-client-select'}
				name="select"
				label="Client"
				required={true}
				value={value}
				onChange={(event) => setValue(event.currentTarget.value)}
			>
				<option value="" disabled hidden />
				{clients.map((client) => (
					<option key={client.id} value={client.id}>
						{`${client?.name || ''} (${client?.number || ''})`}
					</option>
				))}
			</NativeSelect>
			<FormMessage
				id='client-select-message'
				data-testid={'client-select-message'}
				error={hasError}
			>
				{getFieldMessage()}
			</FormMessage>
		</>

	);
}

export default ClientSelectField;