// common modules
import { Button } from 'react-md';
import {
    Dialog,
    DialogHeader,
    DialogTitle,
    DialogContent,
    DialogFooter,
} from "@react-md/dialog";

// custom modules
import '@components/restfulTable/DeleteResourceDialog.css';

function DeleteResourceDialog(props) {
    const { visible, resourceName, onCancel, onConfirm } = props;

    return (
        <Dialog
            id="delete-resource-dialog"
            role="alertdialog"
            visible={visible}
            onRequestClose={() => { }}
            aria-labelledby="delete-resource-dialog-title"
            data-testid="delete-resource-dialog-title">
            <DialogHeader>
                <DialogTitle>{`Delete ${resourceName}`}</DialogTitle>
            </DialogHeader>
            <DialogContent>Are you sure you want to delete this?</DialogContent>
            <DialogFooter>
                <Button id="delete-dialog-submit" onClick={() => onConfirm()}>Confirm</Button>
                <Button id="delete-dialog-close" onClick={() => onCancel()}>Cancel</Button>
            </DialogFooter>
        </Dialog>
    );
}

export default DeleteResourceDialog;