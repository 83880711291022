export const adjustLabels = (ref, section, setLabelClassName, type = '') => {
	const formLineRowContainer = document
		.getElementById(section.title)
		.querySelectorAll('.formLineRowContainer .rmd-grid__cell');
		
	if (type === 'select') {
		let label = ref.current.querySelector('.rmd-label');
		if (label && label.offsetHeight > 16) {
			setLabelClassName('dropdownFieldContainer');
		}
	}
	
	formLineRowContainer.forEach((item) => {
		let label = item.querySelector('.rmd-label');
		let field = item.querySelector(`[name="${ref.current.name}"]`);
		if (label && field) {
			if (label.offsetHeight > 16) {
				setLabelClassName('fieldContainer');
			}
		}
	});
}