// common modules
import {
	Button,
	Dialog,
	FontIcon,
	TextField
} from 'react-md';
import { useEffect, useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';

// custom modules
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as STRING from '@utilities/constants/strings.js';
import { INVALID_CHARACTER, ACCEPTED_FILE_TYPES_PATTERN } from '@utilities/constants/document';

const RenameDocumentDialog = ({ showRenameDialog, setShowRenameDialog, index, file }) => {
	const { onFileRename } = usePageFramework();
	const [fileName, setFileName] = useState('');
	const [isError, setIsError] = useState(false);
	const tooltip = useRef();

	useEffect(() => {
		if (file) {
			setFileName(file.name.replace(ACCEPTED_FILE_TYPES_PATTERN, ''));
		}
	}, [file]);

	useEffect(() => {
		// Show specific tooltip manually
		ReactTooltip.show(tooltip.current);
	}, [isError]);

	const handleChange = (e) => {
		if (e.target.value.match(INVALID_CHARACTER) !== null) {
			setIsError(true);
		} else {
			setIsError(false);
		}

		setFileName(e.target.value);
	};

	const handleClose = (fileName) => {
		setShowRenameDialog(!showRenameDialog);
		setFileName(fileName.replace(ACCEPTED_FILE_TYPES_PATTERN, ''));
		setIsError(false);
	};

	const handleClick = () => {
		if (file) {
			onFileRename(file, `${fileName}.${file.extension}`).then((file) => {
				setShowRenameDialog(false);
			});
		}
	};

	return (file &&
		<Dialog
			id={`rename-${index}`}
			onRequestClose={() => { }}
			visible={showRenameDialog}
			modal={true}
			aria-labelledby='delete-dialog'
			className='deleteDialogContainer'
		>
			<div style={{ paddingBottom: '20px' }}>
				<Button
					className='closeDialogBtn'
					id='dialog-close'
					onClick={() => handleClose(file.name)}
					buttonType='icon'
					theme='clear'
					themeType='outline'
				>
					<FontIcon className='closeDialogText'>close</FontIcon>
				</Button>
			</div>
			<div className='fileDeleteTextContainer'>
				<p className='fileDeleteText'>Enter new file name:</p>
				{isError && <ReactTooltip id='fileRenameError' type='error'>
					<span>
						{STRING.INVALID_FILES_MESSAGE_RENAME}
					</span>
				</ReactTooltip>}
				<TextField
					data-tip
					data-for='fileRenameError'
					data-event='focus'
					id='renameFileField'
					placeholder='Enter new file name'
					value={fileName}
					onChange={handleChange}
					error={isError}
					ref={tooltip}
				/>
			</div>
			<Button
				theme='clear'
				themeType={isError ? 'outline' : 'contained'}
				className='docManagerDeleteBtn'
				onClick={handleClick}
				disabled={isError}
			>
				Submit
			</Button>
		</Dialog>
	);
};

export default RenameDocumentDialog;
