const POPULATE_OPTIONS_LOOKUP = {
    'Bona Fide Residence Test': { groupField: true, flattenData: true },
    'Listed Property Information': { groupField: true }, // Vehicles
};

const getPopulateOptions = (name) => {
    return POPULATE_OPTIONS_LOOKUP[name] || {};
};

export {
    getPopulateOptions,
    POPULATE_OPTIONS_LOOKUP,
};