import * as STRING from '@utilities/constants/strings.js';
import { ACCEPTED_FILE_TYPES_1040 } from '@utilities/constants/document';
import {
	Grid,
	GridCell,
	Divider,
} from 'react-md';
import useAdaptive from '@utilities/hooks/useAdaptive';

function MultipleFileErrorsMessage(props) {
	const { filesLength, extensionErrorFiles, invalidFiles, contentAndTypeMismatchFiles, duplicateFilesSkipped, unknownErrorFiles } = props;

	const errorsAmount = extensionErrorFiles.length + invalidFiles.length + contentAndTypeMismatchFiles.length + duplicateFilesSkipped.length + unknownErrorFiles.length;

	const allFileErrors = [];

	const { isLaptopOrDesktop } = useAdaptive();

	extensionErrorFiles.map((file) => {
		allFileErrors.push({
			fileName: file,
			errorMessage: `Incorrect File Extension. Accepted file types: ${ACCEPTED_FILE_TYPES_1040}`
		})
	})

	invalidFiles.map((file) => {
		allFileErrors.push({
			fileName: file,
			errorMessage: `Incorrect Characters. ${STRING.INVALID_FILES_MESSAGE_RENAME}`
		})
	})

	contentAndTypeMismatchFiles.map((file) => {
		allFileErrors.push({
			fileName: file,
			errorMessage: 'File content does not match the file type.'
		})
	})

	duplicateFilesSkipped.map((file) => {
		allFileErrors.push({
			fileName: file,
			errorMessage: 'Duplicate File Name'
		})
	})

	unknownErrorFiles.map((file) => {
		allFileErrors.push({
			fileName: file,
			errorMessage: 'Unknown Error. If the error persists, please contact us.'
		})
	})

	return (
		<>
			<h4 id='multipleUploadMessage' style={{ textAlign: 'left' }}>
				{errorsAmount} of {filesLength} were uploaded unsuccessfully. The following files were not accepted. Please correct the errors listed below and try again.
			</h4>

			<div className='docManagerGridContainer' style={{ paddingBottom: '20px', position: 'relative' }}>
				<Grid className='docManagerHeaderContainer' style={{ color: '#212121' }}>
					<GridCell colSpan={6} className='docManagerCell docManagerHeaderText' style={{ textAlign: 'left', paddingLeft: '-200px' }}>File Name</GridCell>
					<GridCell colSpan={6} className='docManagerCell docManagerHeaderText' style={{ textAlign: 'left' }}>Error</GridCell>
				</Grid>
			</div>
			<div className='docManagerFileList' style={{ height: 'auto', maxHeight: '400px', overflowY: 'auto' }}>
				{allFileErrors.map((file, index) => (
					<Grid>
						{!isLaptopOrDesktop && index === 0 && <GridCell colSpan={12}><Divider /></GridCell>}
						<GridCell className='docManagerFileName' colSpan={6} style={{ overflowWrap: 'break-word', textAlign: 'left', paddingLeft: '15px' }}>{file.fileName}</GridCell>
						<GridCell className='docManagerFileMessage' colSpan={6} style={{ color: '#E78460', textAlign: 'left', paddingLeft: '3px' }}>{file.errorMessage}</GridCell>
						<GridCell colSpan={12}>
							<Divider />
						</GridCell>
					</Grid>
				))}
			</div>
		</>
	);
}

export default MultipleFileErrorsMessage;