export const INITIAL_AB = 'AB';
export const INITIAL_BC = 'BC';
export const INITIAL_MB = 'MB';
export const INITIAL_NB = 'NB';
export const INITIAL_NL = 'NL';
export const INITIAL_NS = 'NS';
export const INITIAL_NT = 'NT';
export const INITIAL_NU = 'NU';
export const INITIAL_ON = 'ON';
export const INITIAL_PE = 'PE';
export const INITIAL_QC = 'QC';
export const INITIAL_SK = 'SK';
export const INITIAL_YT = 'YT';

export const AXCESS_KEY_X = 'X';
export const AXCESS_KEY_YES = 'Yes';
export const AXCESS_KEY_NO = 'No';
export const AXCESS_KEY_BOTH = 'Both (default)';
export const AXCESS_KEY_Y = 'Y';
export const AXCESS_KEY_N = 'N';
export const AXCESS_KEY_TRADITIONAL = 'Traditional';
export const AXCESS_KEY_ROTH = 'Roth';
export const AXCESS_KEY_SIMPLE = 'Simple';
export const AXCESS_KEY_SEP = 'SEP IRA';
export const AXCESS_KEY_BENEFIT = 'Defined Benefit Plan';
export const AXCESS_KEY_CHECKING = 'Checking (Default)';
export const AXCESS_KEY_SAVINGS = 'Traditional savings account';

export const AXCESS_KEY_TRUE = true;
export const AXCESS_KEY_FALSE = false;

export const TAXPAYER_KEY = 'T';
export const SPOUSE_KEY = 'S';
export const JOINT_KEY = 'J';

export const EXCHANGE_KEY_CHECKING = 'Checking';
export const EXCHANGE_KEY_SAVINGS = 'Savings';
export const EXCHANGE_KEY_BOTH = 'Both';

export const FOREIGN_ACC_TYPE_OTHER = 'Other (Default)';
export const FOREIGN_ACC_TYPE_BANK = 'Bank account';
export const FOREIGN_ACC_TYPE_SECURITIES = 'Securities account';

export const FOREIGN_ASSETS_ID_TYPE_FOREIGN = 'Foreign';
export const FOREIGN_ASSETS_ID_TYPE_EIN = 'Employer Identification Number (EIN)';
export const FOREIGN_ASSETS_ID_TYPE_SSN = 'Social Security No (SSN) / Individual Taxpayer ID No (ITIN)';

export const FOREIGN_ASSETS_JOINT_OWNERSHIP_OTHER = 'Joint ownership - other joint owner';

export const PASS_DATA_TO_CHILD = 'PassDataToChild';

export const MS_CODE_1 = 'MS1';
export const MS_CODE_2 = 'MS2';

export const PS_CODE_1 = 'PS1';
export const PS_CODE_2 = 'PS2';
export const PS_CODE_3 = 'PS3';

export const GOV_CODE_5 = 'Line 6 explanation';
export const GOV_CODE_6 = 'Line 7a explanation';
export const GOV_CODE_7 = 'Line 7b explanation';
export const GOV_CODE_8 = 'Line 8a explanation';
export const GOV_CODE_9 = 'Line 8b explanation';
export const GOV_CODE_10 = 'Line 10b explanation';

export const CASH = 'Cash';
export const ACCRUAL = 'Accrual';
export const OTHER = 'Other';

export const EXPLANATION_CODE_24 = '24';

export const DIAGNOSTIC_REFERENCE_CODE_A1 = 'A1'; // Section A, line 1
export const DIAGNOSTIC_REFERENCE_CODE_A2 = 'A2'; // Section A, line 2
export const DIAGNOSTIC_REFERENCE_CODE_A3B = 'A3B'; // Section A, line 3b
export const DIAGNOSTIC_REFERENCE_CODE_A3C = 'A3C'; // Section A, line 3c
export const DIAGNOSTIC_REFERENCE_CODE_A4B = 'A4B'; // Section A, line 4b
export const DIAGNOSTIC_REFERENCE_CODE_A4C = 'A4C'; // Section A, line 4c
export const DIAGNOSTIC_REFERENCE_CODE_A5A = 'A5A'; // Section A, line 5a
export const DIAGNOSTIC_REFERENCE_CODE_A6 = 'A6'; // Section A, line 6
export const DIAGNOSTIC_REFERENCE_CODE_A9A = 'A9A'; // Section A, line 9a
export const DIAGNOSTIC_REFERENCE_CODE_A9B = 'A9B'; // Section A, line 9b
export const DIAGNOSTIC_REFERENCE_CODE_A9C = 'A9C'; // Section A, line 9c
export const DIAGNOSTIC_REFERENCE_CODE_A11 = 'A11'; // Section A, line 11
export const DIAGNOSTIC_REFERENCE_CODE_B1 = 'B1'; // Section B, line 1
export const DIAGNOSTIC_REFERENCE_CODE_B2 = 'B2'; // Section B, line 2
export const DIAGNOSTIC_REFERENCE_CODE_C1 = 'C1'; // Section C, line 1
export const DIAGNOSTIC_REFERENCE_CODE_D2 = 'D2'; // Section D, line 2
export const DIAGNOSTIC_REFERENCE_CODE_D3 = 'D3'; // Section D, line 3
export const DIAGNOSTIC_REFERENCE_CODE_E1C = 'E1C'; // Section E, line 1c
export const DIAGNOSTIC_REFERENCE_CODE_E2A = 'E2A'; // Section E, line 2a
export const DIAGNOSTIC_REFERENCE_CODE_E2B = 'E2B'; // Section E, line 2b
export const DIAGNOSTIC_REFERENCE_CODE_E3A = 'E3A'; // Section E, line 3a
export const DIAGNOSTIC_REFERENCE_CODE_E3B = 'E3B'; // Section E, line 3b
export const DIAGNOSTIC_REFERENCE_CODE_VB1E = 'VB1E'; // Part V, Section B, line 1e
export const DIAGNOSTIC_REFERENCE_CODE_SY = 'SY'; // Public Support Short Year

//from 1040
export const VALUE_AB = 'Alberta';
export const VALUE_BC = 'British Columbia';
export const VALUE_MB = 'Manitoba';
export const VALUE_NB = 'New Brunswick';
export const VALUE_NL = 'Newfoundland and Labrador';
export const VALUE_NL_TRUNC = 'Newfoundland/Labr';
export const VALUE_NS = 'Nova Scotia';
export const VALUE_NT = 'Northwest Territories';
export const VALUE_NT_TRUNC = 'Northwest Territo';
export const VALUE_NU = 'Nunavut';
export const VALUE_ON = 'Ontario';
export const VALUE_PE = 'Prince Edward Island';
export const VALUE_PE_TRUNC = 'Prince Edward Isl';
export const VALUE_QC = 'Quebec';
export const VALUE_SK = 'Saskatchewan';
export const VALUE_YT = 'Yukon';