import { frameworkSetter } from '../../../hooks/usePageFramework';
import { useState } from 'react';
import UploadDrawer from '../uploadDrawer/index';

function LineItemDrawer(props) {
    const { sectionName, fns, group, index, isFormLocked } = props;
    const { onFileUpload, selectState, REDUX } = frameworkSetter.usePageFramework();
    const uploadList = selectState(REDUX.UPLOAD_LIST);
    const onUpload = (event) => onFileUpload(event, null, sectionName, uploadList);
    const [ isDrawerClose, setIsDrawerClose ] = useState(false);
    const sectionUploadList = uploadList ? uploadList.filter(x => x?.section?.toLowerCase().trim() === sectionName?.toLowerCase().trim()) : [];
    const isLineItemNA = group?.lineItems[index][0].notApplicable;
    const isLineDisabled = isFormLocked || isLineItemNA;
    
    if (!uploadList  || !group.lineItems[index][0].drawerVisible || isDrawerClose) {
        if (isDrawerClose) {
            setIsDrawerClose(false);
        }
        return null;
    }

    if (sectionUploadList.length < 1) return null;
    
    return (
        <UploadDrawer 
            sectionName={sectionName} 
            uploadList={uploadList} 
            onUpload={onUpload} 
            drawerStyle='lineUploadDrawerContainer' 
            fns={fns} 
            group={group} 
            index={index} 
            isFormLocked={isLineDisabled} 
            setIsDrawerClose={setIsDrawerClose}
        />
    );
    
}

export default LineItemDrawer;