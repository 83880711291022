import { Validators } from 'cla-formrenderer';
import {
    MORTGAGE_INTEREST_FIN_INST_UPLOAD,
    MORTGAGE_INTEREST_OTHER_MORTGAGE_UPLOAD,
    MORTGAGE_INTEREST_INVESTMENT_INT_UPLOAD,
} from '@utilities/constants/strings';

const mortgageFn = (year) => {

    const sections = [
        {
            title: 'General Mortgage Information',
            shortTitle: 'General Mortgage',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    fields: [
                        // TODO: This question needs to change and reflect the current tax year
                        { label: 'Did you refinance your home mortgage during 2021?', name: 'generalMortgage', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'genMortgageSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },

                        { label: 'Is the outstanding mortgage balance on your first and/or second residences greater than $750,000?', name: 'generalMortgageLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'generalMortgageSelect', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, triggered: 'setMortgageBalanceLabels' },
                        { label: `Balance of mortgage on January 1st, 2021 `, name: 'generalMortgageLabel1', type: 'label', col: 9, isVisible: false },
                        { label: 'Amount', name: 'genMortgageAmount', type: 'money', isTotal: true, col: 3, isVisible: false },
                        { label: `Balance of mortgage on December 31st, 2021 `, name: 'generalMortgageLabel2', type: 'label', col: 9, isVisible: false },
                        { label: 'Amount', name: 'genMortgageAmount2', type: 'money', isTotal: true, col: 3, isVisible: false },

                        { label: 'Do you have a home equity loan?', name: 'generalMortgageQuestion', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'generalMortgageSelect1', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, triggered: 'setHomeEquityLoanLabels' },
                        { label: `Balance of home equity loan on January 1st, 2021`, name: 'generalMortgageLabel3', type: 'label', col: 9, isVisible: false },
                        { label: 'Amount', name: 'genEquityAmount1', type: 'money', isTotal: true, col: 3, isVisible: false },
                        { label: `Balance of home equity loan on December 31st, 2021 `, name: 'generalMortgageLabel4', type: 'label', col: 9, isVisible: false },
                        { label: 'Amount', name: 'genEquityAmount2', type: 'money', isTotal: true, col: 3, isVisible: false },
                    ],
                },
            ],
        },
        {
            title: 'Home Mortgage Interest Paid to Financial Institutions',
            shortTitle: 'Home Mortgage Interest',
            isVisible: true,
            collapseClass: 'collapsibleForm',
            sectionId: 2,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupId: 1,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    axcessGroup: {
                        pull: {
                            fromSections: [
                                'Mortgage Interest (IRS 1098-MIS)',
                                'Home Mortgage Interest Paid to a Financial Institution and Deductible Points'
                            ],
                            fieldPrefixes: ['IFDS1098', 'IFDSIDHI'],
                            lineFilterType: 'omitAny',
                            lineFilters: [
                                { key: 'IFDSIDHI.10', filterType: 'containsKey' },
                            ],
                        },
                    },
                    fields: [
                        {
                            label: 'Mortgage Lender', name: 'IFDS1098.5', type: 'freeText', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDS1098.5'],
                                fn: 'anyValidPull',
                                defaultIfEmpty: 'Other Lender',
                            }
                        },
                        { label: 'Yes/No', name: 'mortgageInterestHomeEquityLine', type: 'select', lookup: 'YESNO', wrapLabel: 'Home Equity Line?' },
                        {
                            label: '$ 0', name: 'IFDS1098.14', type: 'label', isTotal: true, wrapLabel: '2019 Amount', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDS1098.14', 'IFDSIDHI.0'],
                                fn: 'anyValidPull',
                            }
                        },
                        { label: 'Amount', name: 'C-IFDS1098.14', type: 'money', isTotal: true, wrapLabel: '2020 Amount' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Mortgage Lender', smallScreenLabel: 'Home Mortgage Details', col: 5, colTablet: 2 },
                            { label: 'Home Equity Line?', col: 2, colTablet: 1 },
                            { label: '2020 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: 'Form 1098', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Home Mortgage Interest Paid to Financial Institutions', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3, },
                            { type: 'totalMoney', name: 'IFDS1098.14', year: 'prior', col: 2, label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDS1098.14', year: 'current', col: 2, label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: MORTGAGE_INTEREST_FIN_INST_UPLOAD, viewSectionId: 0 },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Other Mortgage Interest Paid (Not to a Financial Institution)',
            shortTitle: 'Other Mortgage Interest Paid',
            isVisible: true,
            sectionId: 3,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { 
                            name: 'IFDSIDHM.0', 
                            type: 'freeText', 
                            prior: true, 
                            label: 'Mortgage Lender', 
                            maxLength: 27, 
                            isPriorEditable: true
                        },
                        { name: 'IFDSIDHM.2', type: 'freeText', prior: true, label: 'Address', maxLength: 36 },
                        { label: 'City', name: 'IFDSIDHM.12', type: 'freeText', placeholder: 'Enter City', prior: true, maxLength: 35 },
                        { label: 'State', name: 'IFDSIDHM.13', type: 'select', lookup: 'STATES_CODE', placeholder: 'Select State', prior: true },
                        { label: 'Zip', wrapLabel: 'Zip Code', name: 'IFDSIDHM.14', type: 'zippostal', placeholder: 'Enter Zip Code', prior: true },
                        {
                            name: 'IFDSIDHM.1', type: 'number', prior: true, label: '#', wrapLabel: 'EIN/SSN', overRideText: 'On File', maxLength: 9, axcessTranslate: 'omitOnOverride', isMaskOnBlur: true,
                            validations: { ...Validators.fixLength(9) },
                        },
                        { name: 'IFDSIDHM.4', type: 'label', isTotal: true, label: '$ 0', wrapLabel: '2019 Amount', prior: true },
                        { name: 'C-IFDSIDHM.4', type: 'money', isTotal: true, label: 'Amount', wrapLabel: '2020 Amount' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Mortgage Lender', smallScreenLabel: 'Other Mortgage Details', col: 2, colTablet: 1 },
                            { label: 'Street Address', col: 1, colTablet: 1 },
                            { label: 'City', col: 1, colTablet: 1, },
                            { label: 'State', col: 1, colTablet: 1, },
                            { label: 'Zip', col: 1, colTablet: 1, },
                            { label: 'EIN/SSN', col: 2, colTablet: 1 },
                            { label: '2020 Amount', col: 1, colTablet: 1, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Mortgage Interest Paid (Not to a Financial Institution)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 5, },
                            { type: 'totalMoney', name: 'IFDSIDHM.4', col: 2, colTablet: 1, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSIDHM.4', col: 2, colTablet: 1, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: MORTGAGE_INTEREST_OTHER_MORTGAGE_UPLOAD, viewSectionId: 0 },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Investment Interest (Interest Paid on Funds Used to Purchase Taxable Investments)',
            shortTitle: 'Investment Interest',
            isVisible: true,
            sectionId: 4,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Investment and Other Interest'],
                            fieldPrefixes: ['IFDSIDII'],
                            lineFilterType: 'omitAny',
                            lineFilters: [
                                { key: 'IFDSIDII.2', filterType: 'containsAny', filterData: ['Investment interest carryover'] },
                            ],
                        },
                    },
                    fields: [
                        { name: 'IFDSIDII.1', type: 'freeText', prior: true, label: 'Paid To' },
                        { name: 'IFDSIDII.3', type: 'label', isTotal: true, label: '$ 0', wrapLabel: '2019 Amount', prior: true },
                        { name: 'C-IFDSIDII.3', type: 'money', isTotal: true, label: 'Amount', wrapLabel: '2020 Amount' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Paid To', smallScreenLabel: 'Investment Interest Details', col: 6, colTablet: 3, },
                            { label: '2020 Amount', col: 3, colTablet: 2, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Investment Interest (Interest Paid on Funds Used to Purchase Taxable Investments)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 6, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDSIDII.3', col: 3, colTablet: 2, year: 'prior', label: '$ 0', isTotal: true },
                            { type: 'totalMoney', name: 'C-IFDSIDII.3', col: 2, colTablet: 2, year: 'current', label: '$ 0', isTotal: true },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: MORTGAGE_INTEREST_INVESTMENT_INT_UPLOAD, viewSectionId: 0 },
                        ],
                    },
                },
            ],
        },
    ];

    return sections;
};

export default mortgageFn;