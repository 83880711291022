import { useState, useEffect } from 'react';
import { Card, Link as ReactMDLink, Divider } from 'react-md';
import { Link as ReactRouterLink } from 'react-router-dom';
import { getCardProp } from '@utilities/helpers/getCardProp';
import './dashboardCard.css';
import './dashboardCard.scss';

function DashboardCard(props) {
    const { card } = props;
    const { customProgressText } = card;
    const [statusId, setStatusId] = useState(card.statusId);

    useEffect(() => {
        setStatusId(card.statusId);
    }, [card.statusId]);

    const Link = (props) => (
        <ReactMDLink component={ReactRouterLink} {...props} style={{ textDecoration: 'none', color: 'black' }} />
    );

    let statusTitle = 'Not Started';
    card.statusTitle = statusTitle;
    let barStyle = {};
    let cardStyle = {};

    const cardProgressLabelText = customProgressText ?? (card.isRequired ? 'Required' : card.isRecommended ? 'Recommended' : 'Optional');

    switch (statusId) {
        case 2:
            statusTitle = 'In Progress';
            card.statusTitle = statusTitle;
            barStyle.backgroundColor = '#2e334e';
            break;
        case 3:
            statusTitle = 'Error';
            card.statusTitle = statusTitle;
            barStyle.backgroundColor = '#ef0606';
            break;
        case 4:
            statusTitle = 'Completed';
            card.statusTitle = statusTitle;
            barStyle.backgroundColor = '#439818';
            break;
        case 5:
            statusTitle = 'Does Not Apply';
            card.statusTitle = statusTitle;
            cardStyle.opacity = 0.4;
            break;
        default:
            statusTitle = 'Not Started';
            card.statusTitle = statusTitle;
            break;
    }

    return (
        <Card className={'cardContainer'}>
            <div className="cardContent">
                <Link to={card.route} onClick={() => window.scrollTo(0, 0)} className="cardTitle">
                    <div className="cardIconContainer" style={cardStyle}>
                        <Link to={card.route} onClick={() => window.scrollTo(0, 0)}>
                            <img className="overHangImg" alt={`Icon for ${getCardProp(card.formName, 'title')} dashboard card`} src={getCardProp(card.formName, 'dashBoardIcon')} />
                        </Link>
                    </div>
                    <div className="cardTitleContainer" style={cardStyle}>
                        <Link to={card.route} onClick={() => window.scrollTo(0, 0)}>
                            <p>{getCardProp(card.formName, 'title')}</p>
                            <div className='cardSubtitle'>{getCardProp(card.formName, 'subtitle')}</div>
                        </Link>
                    </div>
                </Link>
            </div>
            {
                <div className="cardStatusContainer" style={cardStyle}>
                    <Link to={card.route} onClick={() => window.scrollTo(0, 0)}>
                        <Divider />
                        <div className="cardProgressLabelContainer">
                            <div>
                                <p>{cardProgressLabelText}</p>
                            </div>
                            <div>
                                <p className='last'>{statusTitle}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            }
        </Card>
    );
}

export default DashboardCard;
