const vehicleFn = (isFormExpandable, isLineSection, year, barcodeName) => {
    const vehicleFormSections = [
        {
            title: 'Vehicle Expenses',
            isDefaultExpanded: isFormExpandable,
            isVisible: true,
            sectionId: 1,
            barcodeName: barcodeName,
            subSections: [
                { subSectionId: 2 },
                { subSectionId: 3 }
            ],
            groups: [
                {
                    groupType: 'entityScrollTab',
                    isSingleColumn: true,
                    groupId: 1,
                    fields: []
                },
                {
                    groupId: 2,
                    hasDivider: true,
                    groupType: isLineSection ? 'lineSection' : 'lineItem',
                    fields: [
                        {
                            label: 'Vehicle Name', maxLength: 76, name: 'vehicleName', isEntityName: true, type: 'freeText', isSharedEntityValue: true, prior: true,
                            logicFunction: [
                                { trigger: 'setConditionalFieldValidation', requiredFields: ['vehicleCost',] },
                                { trigger: 'setCyMilesRequired', isDifferentGroup: true },
                                { trigger: 'setMilesHaveAnotherVehicleRequired', isDifferentGroup: true },
                                { trigger: 'setMilesAvailableOffDutyRequired', isDifferentGroup: true },
                            ]
                        },
                        { label: 'Vehicle Cost', name: 'vehicleCost', type: 'money', isTotal: true, hideFooter: true, isSharedEntityValue: true, logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['vehicleName',] }], prior: true, axcessTranslate: 'parseMoneyValue', maxLength: 14 },
                        { label: '', name: '', type: 'label' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Vehicle Information', col: 5, colTablet: 3 },
                            { label: 'Vehicle Cost', col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1 },
                        ],
                        footerLabels: [],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                },//1
                {
                    groupId: 10,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Is this vehicle leased?', name: 'vehLeaseLabel', type: 'label', col: 9 },
                        {
                            label: 'Yes/No', name: 'vehLeaseDropdown', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3,
                            logicFunction: [{ trigger: 'showVehFairMarketValue' }]
                        },
                    ]

                },//2
                {
                    groupType: isLineSection ? 'lineSection' : 'lineItem',
                    groupId: 3,
                    hasDivider: true,
                    isVisible: false,
                    fields: [
                        // { label: 'Fair market value of leased vehicle  question', name: 'fairMarketValuequestonLabel', type: 'label' }
                        { label: 'Fair market value of leased vehicle', name: 'fairMarketValueLabel', type: 'label' },
                        { label: '$ 0', name: 'vehicleFairMarket', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', name: 'C-vehicleFairMarket', type: 'money', hideFooter: true, isSharedEntityValue: true },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            // { label: '', col: 12, colTablet: 8 },   
                            { label: '', col: 7, colTablet: 3 },
                            { label: `${year?.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year?.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1 },
                        ],
                        footerLabels: [],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                },
                {
                    groupId: 6,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Do you have evidence to support your deduction?', name: 'vehExpEvidenceDropdownLabel', type: 'label', col: 9 },
                        {
                            label: 'Yes/No', name: 'vehExpEvidenceDropdown', type: 'select', lookup: 'YESNO', col: 3, uniqueName: 'vehSupportEvidenceUnique', hasUniqueGroupTrigger: true,
                            logicFunction: [{ trigger: 'showVehExpSupEvidence' }]
                        },
                    ]

                },
                {
                    groupId: 7,
                    isVisible: false,
                    uniqueVisibilityName: 'isVehExpSupEvidence',
                    hasDivider: true,
                    fields: [
                        { label: 'Is the evidence written?', name: 'vehExpSupEvidenceLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'vehExpSupEvidence', type: 'select', lookup: 'YESNO', col: 3, uniqueName: 'vehSupportEvidWrittenUnique' },
                    ]

                },
                {
                    groupId: 8,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: ' Do you have evidence to support the business use claimed on the listed property?', name: 'vehExpBusEvidenceDropdownLabel', type: 'label', col: 9 },
                        {
                            label: 'Yes/No', name: 'vehExpBusEvidenceDropdown', type: 'select', lookup: 'YESNO', col: 3, uniqueName: 'vehBusUseEvidenceUnique', hasUniqueGroupTrigger: true,
                            logicFunction: [{ trigger: 'showVehExpBusEvidence' }]
                        }
                    ]

                },
                {
                    groupId: 9,
                    isVisible: false,
                    uniqueVisibilityName: 'isVehExpBusEvidence',
                    hasDivider: true,
                    fields: [
                        { label: 'Is the evidence written?', name: 'vehExpBusEvidenceLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'vehExpBusEvidence', type: 'select', lookup: 'YESNO', col: 3, uniqueName: 'vehBusUseEvidWrittenUnique', },
                    ]

                },
                {
                    groupId: 4,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Do you or your spouse have another vehicle available for personal use?', name: 'vehicleMilesDropDown1Label', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'vehicleMilesDropDown.1', isSharedEntityValue: true, type: 'select', lookup: 'YESNO', col: 3, prior: true, isPriorEditable: true },
                    ]
                },
                {
                    groupId: 5,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Was your vehicle available for use during off-duty hours?', name: 'vehicleMilesDropDown2Label', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'vehicleMilesDropDown.2', isSharedEntityValue: true, type: 'select', lookup: 'YESNO', col: 3, prior: true, isPriorEditable: true },
                    ]
                },
            ]
        },
        {
            title: 'Vehicle Miles',
            isVisible: true,
            isSubSection: true,
            isExpanded: true,
            sectionId: 2,
            groups: [
                // {
                //     groupId: 1,
                //     isVisible: true,
                //     hasDivider: true,
                //     fields: [
                //         { label: 'Do you or your spouse have another vehicle available for personal use?', name: 'vehicleMilesDropDown1Label', type: 'label', col: 9 },
                //         { label: '', name: 'vehicleMilesDropDown.1', isSharedEntityValue: true, type: 'select', lookup: 'YESNO', col: 3, prior: true, isPriorEditable: true },
                //     ]
                // },
                // {
                //     groupId: 2,
                //     isVisible: true,
                //     hasDivider: false,
                //     fields: [
                //         { label: 'Was your vehicle available for use during off-duty hours?', name: 'vehicleMilesDropDown2Label', type: 'label', col: 9 },
                //         { label: '', name: 'vehicleMilesDropDown.2', isSharedEntityValue: true, type: 'select', lookup: 'YESNO', col: 3, prior: true, isPriorEditable: true },
                //     ]
                // },
                {
                    groupType: isLineSection ? 'lineSection' : 'lineItem',
                    subSectionTotalType: 'miles',
                    groupId: 3,
                    fields: [
                        { label: 'Vehicle Miles', name: 'vehicleMiles', type: 'alphanumeric', class: 'labelRequired', },
                        { label: '0', name: 'pyMiles', type: 'label', isTotal: true, isMoney: false, prior: true, maxLength: 10 },
                        { label: 'Miles', name: 'cyMiles', type: 'total', isMoney: false, isTotal: true, placeholder: '0', maxLength: 10 }, //required if there is Vehicle Name & 0 is valid amount
                        { type: 'lineButtons' }
                    ],
                    prePopulate: [
                        {
                            label: 'Total business miles for this vehicle (for this entity)',
                            prePopulateAugments: [
                                { name: 'vehicleMiles.1', priorModifiers: { uniqueName: 'priorTotalMilesForThisBusiness' }, currentModifiers: { uniqueName: 'totalMilesForThisBusiness' } },
                            ],
                        },
                        {
                            label: 'Total commuting miles for this vehicle (across all entities)',
                            prePopulateAugments: [
                                { name: 'vehicleMiles.2', priorModifiers: { uniqueName: 'priorCommutingMiles' }, currentModifiers: { isSharedEntityValue: true } },
                            ],
                        },
                        // {
                        //     label: 'Total other miles for this vehicle (across all entities)',
                        //     prePopulateAugments: [
                        //         { name: 'vehicleMiles.3', priorModifiers: {uniqueName: 'priorOtherMiles'}, currentModifiers: {isSharedEntityValue: true} },
                        //     ],
                        // },
                        {
                            label: 'Total miles driven for this vehicle (across all entities)',
                            prePopulateAugments: [
                                { name: 'vehicleMiles.4', priorModifiers: { uniqueName: 'priorTotalMiles' }, currentModifiers: { isSharedEntityValue: true } },
                            ],
                        },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Miles', col: 7, colTablet: 3 },
                            { label: `${year?.prior} Miles`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year?.current} Miles`, col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1 },
                        ],
                        footerLabels: [],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Actual Vehicle Expenses',
            isVisible: true,
            isSubSection: true,
            isExpanded: true,
            hasSubSectionTotalDisplay: true,
            sectionId: 3,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        {
                            label: 'Actual expenses may not be applicable or beneficial with your particular tax situation. Please contact your CLA tax professional if you are unsure if you need to provide this information.',
                            type: 'label',
                            class: 'subSeCDetails'
                        },
                    ]
                },
                {
                    groupType: isLineSection ? 'lineSection' : 'lineItem',
                    groupId: 2,
                    fields: [
                        { label: 'Type of Expense', name: 'vehicleExpenses', type: 'alphanumeric', placeholder: 'Enter Vehicle', prior: true },
                        { label: '$ 0', name: 'otherVehicleExpenses', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', name: 'C-otherVehicleExpenses', type: 'money', isTotal: true, placeholder: 'Amount', isSharedEntityValue: true },
                        { type: 'lineButtons' }
                    ],
                    prePopulate: [
                        {
                            label: 'Gasoline, oil, repair, etc.',
                            prePopulateAugments: [
                                { name: 'vehicleExpenses.0', currentModifiers: { isSharedEntityValue: true } },
                            ],
                        },
                        {
                            label: 'Interest',
                            prePopulateAugments: [
                                { name: 'vehicleExpenses.1', currentModifiers: { isSharedEntityValue: true } },
                            ],
                        },
                        {
                            label: 'Taxes',
                            prePopulateAugments: [
                                { name: 'vehicleExpenses.2', currentModifiers: { isSharedEntityValue: true } },
                            ],
                        },
                        {
                            label: 'Lease payments',
                            prePopulateAugments: [
                                { name: 'vehicleExpenses.3', currentModifiers: { isSharedEntityValue: true } },
                            ],
                        },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Expenses', col: 7, colTablet: 3 },
                            { label: `${year?.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year?.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1 },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Add Vehicle Expense', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3 },
                            { type: 'totalMoney', name: 'otherVehicleExpenses', col: 2, colTablet: 2, label: '$ 0', year: 'prior' },
                            { type: 'totalMoney', name: 'C-otherVehicleExpenses', col: 2, colTablet: 2, label: '$ 0', year: 'current' },
                            { label: '', col: 1, align: 'left' }
                        ],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                }
            ]
        },
    ];

    return vehicleFormSections;
};

export default vehicleFn;