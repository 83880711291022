import { MenuItem } from 'react-md';

import * as STRING from '@utilities/constants/strings';
import { taxReturnStatusIsValid } from '@utilities/services/taxReturnService';

function EsignatureStatusMenuItem(props) {
    const { taxReturnStatus, callback, organizerId } = props;

    const handleEsignatureStatusToggleClick = () => {
        callback(organizerId);
    }

    return taxReturnStatusIsValid(taxReturnStatus)
        ? (
            <MenuItem
                key={`email-checklist`}
                className={`email-checklist`}
                onClick={handleEsignatureStatusToggleClick}
                data-testid={`email-checklist`}
            >
                {STRING.ESIGNATURE_STATUS_DETAILS}
            </MenuItem>
        )
        : <></>;
}

export default EsignatureStatusMenuItem;