export const clientDetailsSetter = {
  val: null,
  /**
     * @param {null} value
     */
  set clientDetails(value) {
    this.val = value;
  },
  get clientDetails() {
    if (this.val) {
      return this.val;
    }
    return null;
  }
}

 export const getClientDetails = () => {
  let clientName = '';
  let clientNumber = '';
  if (clientDetailsSetter.clientDetails) {
    if (clientDetailsSetter.clientDetails.clientName.includes(',')) {
      clientName = clientDetailsSetter.clientDetails.clientName.split(',')
      clientName = `${clientName[clientName.length - 1]} ${clientName[0]}`
    } else {
      clientName = clientDetailsSetter.clientDetails.clientName;
    }
    
    clientNumber = clientDetailsSetter.clientDetails.clientNumber;
  }

  return { clientName, clientNumber }
}