import {
	Button,
	Dialog,
	useToggle,
	FontIcon
} from 'react-md';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { useEffect, useState } from 'react';

function BlobFileViewer(props) {
	const { onFilePreview, downloadFile } = usePageFramework();
	const { blobFile, className, parentElement, fileName, from, setIsShow, isShow } = props;
	const [visible, show, hide] = useToggle(false);
	const [previewVisible, showPreview, hidePreview] = useToggle(false);
	const [source, setSource] = useState('');

	const handleDownload = (evt) => {
		evt.preventDefault();
		return downloadFile(blobFile);
	};

	useEffect(() => {
		// load preview source
		onFilePreview(blobFile).then((source) => {
			setSource(source);
			showPreview();
		});
	}, []);

	if (from === 'docManager') {
		return (
			<Dialog
				id={`dialogViewer-${blobFile.upload_id}`}
				visible={isShow}
				onRequestClose={() => { }}
				modal={true}
				aria-labelledby="blob-file-viewer"
				className='viewerContainer'
			>
				<div >
					<Button
						className='fileViewerCLose'
						id="dialog-close"
						onClick={() => setIsShow(!isShow)}
						first="true"
						last="true"
						buttonType='icon'
					>
						<FontIcon >close</FontIcon>
					</Button>
				</div>
				<h3 className='fileViewerFileName'>{blobFile.name}</h3>
				<a className="fileViewerDownload" href="#" target="_blank" rel="noreferrer" onClick={handleDownload}>Download</a>
				{
					previewVisible ? <iframe title={`${blobFile.name} Preview`} width="100%" height="100%" src={source}></iframe> :
						<div className='fileViewerNotAvail'> (No preview available) </div>
				}
				<div className='uploadedByInfo'>Uploaded by: {blobFile.uploaded_by}</div>
			</Dialog>
		);
	}

	return (
		<>
			{parentElement ? parentElement :
				<div data-testid='showFileLink' className={className} onClick={show} first="true" last="true">{fileName}</div>}
			<Dialog
				id={`dialogViewer-${blobFile.upload_id}`}
				visible={visible}
				onRequestClose={() => { }}
				modal={true}
				aria-labelledby="blob-file-viewer"
				className='viewerContainer'
			>
				<div >
					<Button className='fileViewerCLose' id="dialog-close" onClick={hide} first="true" last="true" buttonType='icon'>
						<FontIcon >close</FontIcon>
					</Button>
				</div>
				<h3 className='fileViewerFileName'>{blobFile.name}</h3>
				<a className="fileViewerDownload" href="#" target="_blank" rel="noreferrer" onClick={handleDownload}>Download</a>
				{
					previewVisible ? <iframe title={`${blobFile.name} Preview`} width="100%" height="100%" src={source}></iframe> :
						<div className='fileViewerNotAvail'> (No preview available) </div>
				}

			</Dialog>
		</>
	);
}

export default BlobFileViewer;
