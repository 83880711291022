import { useEffect, useState } from 'react';
import { MenuItem } from 'react-md';

import * as STRING from '@utilities/constants/strings';
import { getChecklist } from '@utilities/services/checklistService';

function EmailChecklistMenuItem(props) {
    const { organizerId, callback } = props;

    const [checklistData, setChecklistData] = useState(null);

    useEffect(() => {
        const getChecklistData = async () => {
            const checklist = await getChecklist(organizerId);
            setChecklistData(checklist);
        }
        getChecklistData();
    }, []);

    const handleChecklistMenuItemClick = () => {
        if (checklistData) {
            callback(organizerId);
        }
    }

    return checklistData
        ? (
            <MenuItem
                key={`email-checklist`}
                className={`email-checklist`}
                onClick={handleChecklistMenuItemClick}
                data-testid={`email-checklist`}
                disabled={!checklistData}
            >
                {STRING.EMAIL_CHECKLIST}
            </MenuItem>
        )
        : <></>;
}

export default EmailChecklistMenuItem;