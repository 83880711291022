import _ from 'lodash';
import moneyFormatter, { noDollarFormatter, parseMoneyToNumber } from '@utilities/moneyFormatter';

const calculateTotalFooter = (group) => {
    const footerLabels = group?.lineItemDetails?.footerLabels;

    if (!footerLabels || !_.isArray(footerLabels)) return;

    footerLabels.forEach((footerLabel) => {
        const associatedField = group.fields.some(field => field.name === footerLabel.name && field.isTotal);

        if (footerLabel.isTotal || associatedField) {
            let totalAmt = 0;

            group?.lineItems?.forEach((row) => {
                const foundField = row?.find(field => field.name === footerLabel.name);

                if (foundField) {
                    totalAmt += parseMoneyToNumber(foundField?.default);
                }
            });

            footerLabel.label = footerLabel.type === 'totalMoney' ? moneyFormatter(totalAmt) : noDollarFormatter(totalAmt);
        }
    });
};

export {
    calculateTotalFooter
};