import _ from 'lodash';
import { calculateTotalFooter } from '@utilities/populatePriorData/calculateTotalFooter.js';
import populatePriorDataModule from '@utilities/populatePriorData/populatePriorData.js';
import populateSections from '@utilities/populatePriorData/populateSections.js';
import prePopulateLineItems from '@utilities/populatePriorData/prePopulateLineItems.js';

const buildAndPopulateForms = (sections, priorYearData) => {
    const validatedSections = _.cloneDeep(sections);
    const priorYearDataFiltered = priorYearData;

    // Associative mapping/matching of data
    validatedSections.forEach((section) => {
        section?.groups?.forEach((group) => {
            if (group.entityIdentifiers) {
                populateSections(priorYearDataFiltered, priorYearData)(group);
            }
        });
    });

    // Regular mapping of PY data to form definition
    priorYearDataFiltered.forEach((priorYearDataSheet) => {
        if (priorYearDataSheet) {
            populatePriorDataModule(priorYearData).buildFromSheetSection(priorYearDataSheet.data, validatedSections);
        }
    });

    // Post-mapping form data building
    validatedSections.forEach((section) => {
        section?.groups?.forEach((group) => {
            if (group.prePopulate && group.prePopulate.length) {
                prePopulateLineItems(group);
                group.prePopulate = [];
            }

            if (group?.axcessGroup?.pull?.postPopulateFn) {
                const { postPopulateFn, postPopulateMetadata } = group.axcessGroup.pull;
                postPopulateFn(group, postPopulateMetadata);
                calculateTotalFooter(group);
            }
        });
    });

    return validatedSections;
};

export default buildAndPopulateForms;