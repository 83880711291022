import moneyFormatter, { noDollarFormatter, parseMoneyToNumber } from "@utilities/moneyFormatter";


const recalculateTotalFooter = (lineItemTotals, group) => {
	lineItemTotals?.forEach((columnTotal) => {
		const { totalName, totals } = columnTotal;

		if (!totalName || !totals) return;

		const foundFooterTotal = group?.lineItemDetails?.footerLabels?.find(x => x.name === totalName);

		if (foundFooterTotal) {
			const totalAmt = parseMoneyToNumber(foundFooterTotal.label) + (Number(totals));
			foundFooterTotal.label = foundFooterTotal.type === 'totalMoney' ? moneyFormatter(totalAmt) : noDollarFormatter(totalAmt);
		}
	});
};

export default recalculateTotalFooter;