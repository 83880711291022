import { Grid } from 'react-md';
import DetailsLabel from './DetailLabel/index';
import MedicalDetailsLabel from './MedicalDetailsLabel/index';

function DetailsGroup(props) {
    const { group } = props;
    const hasFields = group.fields && group.fields.length > 0;
    const labelComponents = {
        label: DetailsLabel,
        medicalLabel: MedicalDetailsLabel,
        // TODO: define other detail labels with unique calculations or behaviours
    };

    const labels = group.fields.map((item, index) => {
        const Component = labelComponents[item.type] || DetailsLabel;

        return (<Component key={`details-lbl-${index}`} item={item} index={index}/>);
    });

    return (
        hasFields ? 
            <Grid 
                key={`details-${group.groupId}`} 
                className={group.class || 'groupDetailsContainer'}
            >
                {labels}
            </Grid> : null
    );
}

export default DetailsGroup;