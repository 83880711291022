export const AXCESS_KEY_X = 'X';
export const AXCESS_KEY_YES = 'Yes';
export const AXCESS_KEY_NO = 'No';
export const AXCESS_KEY_BOTH = 'Both (default)';
export const EXCHANGE_KEY_BOTH = 'Both';
export const AXCESS_KEY_Y = 'Y';
export const AXCESS_KEY_N = 'N';

export const AXCESS_KEY_TRADITIONAL = 'Traditional';
export const AXCESS_KEY_ROTH = 'Roth';

export const AXCESS_KEY_SIMPLE = 'Simple';
export const AXCESS_KEY_SEP = 'SEP IRA';
export const AXCESS_KEY_BENEFIT = 'Defined Benefit Plan';

export const VALUE_AB = 'Alberta';
export const VALUE_BC = 'British Columbia';
export const VALUE_MB = 'Manitoba';
export const VALUE_NB = 'New Brunswick';
export const VALUE_NL = 'Newfoundland and Labrador';
export const VALUE_NS = 'Nova Scotia';
export const VALUE_NT = 'Northwest Territories';
export const VALUE_NU = 'Nunavut';
export const VALUE_ON = 'Ontario';
export const VALUE_PE = 'Prince Edward Island';
export const VALUE_QC = 'Quebec';
export const VALUE_SK = 'Saskatchewan';
export const VALUE_YT = 'Yukon';

export const INITIAL_AB = 'AB';
export const INITIAL_BC = 'BC';
export const INITIAL_MB = 'MB';
export const INITIAL_NB = 'NB';
export const INITIAL_NL = 'NL';
export const INITIAL_NS = 'NS';
export const INITIAL_NT = 'NT';
export const INITIAL_NU = 'NU';
export const INITIAL_ON = 'ON';
export const INITIAL_PE = 'PE';
export const INITIAL_QC = 'QC';
export const INITIAL_SK = 'SK';
export const INITIAL_YT = 'YT';

export const AXCESS_KEY_CHECKING = 'Checking (Default)';
export const AXCESS_KEY_SAVINGS = 'Traditional savings account';

export const EXCHANGE_KEY_CHECKING = 'Checking';
export const EXCHANGE_KEY_SAVINGS = 'Savings';

export const TAXPAYER_KEY = 'T';
export const SPOUSE_KEY = 'S';
export const JOINT_KEY = 'J';

export const FOREIGN_ACC_TYPE_OTHER = 'Other (Default)';
export const FOREIGN_ACC_TYPE_BANK = 'Bank account';
export const FOREIGN_ACC_TYPE_SECURITIES = 'Securities account';

export const FOREIGN_ASSETS_ID_TYPE_FOREIGN = 'Foreign';
export const FOREIGN_ASSETS_ID_TYPE_EIN = 'Employer Identification Number (EIN)';
export const FOREIGN_ASSETS_ID_TYPE_SSN = 'Social Security No (SSN) / Individual Taxpayer ID No (ITIN)';

export const FOREIGN_ASSETS_JOINT_OWNERSHIP_OTHER = 'Joint ownership - other joint owner';

export const PASS_DATA_TO_CHILD = 'PassDataToChild';