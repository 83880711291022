import { Validators } from 'cla-formrenderer';

const aboutYourFn = (year) => {
  const sections = [
    {
      title: 'Taxpayer Information',
      isVisible: true,
      sectionId: 1,
      collapseClass: 'collapsibleForm'
    },
    {
      title: 'Address',
      isVisible: true,
      sectionId: 2,
      groups: [
        {
          groupId: 1,
          isVisible: true,
          fields: [
            {
              label: 'Street Address',
              name: 'IFDSGEN.19',
              type: 'freeText',
              placeholder: 'Enter Address',
              prior: true,
              maxLength: 45,
              col: 6,
              colPhone: 12,
              colTablet: 6,
              colLaptop: 4,
              isPriorEditable: true
            },
            {
              label: 'Apt #',
              name: 'IFDSGEN.20',
              type: 'freeText',
              placeholder: '###',
              prior: true,
              maxLength: 6,
              col: 3,
              colPhone: 12,
              colTablet: 6,
              colLaptop: 4,
              isPriorEditable: true
            },
            {
              label: 'City',
              name: 'IFDSGEN.21',
              type: 'freeText',
              placeholder: 'Enter City',
              prior: true,
              maxLength: 40,
              col: 3,
              colPhone: 12,
              colTablet: 6,
              colLaptop: 4,
              isPriorEditable: true
            },
            {
              label: 'Country',
              name: 'IFDSGEN.24',
              type: 'select',
              lookup: 'FOREIGN_COUNTRIES',
              triggered: 'setChangeCountry',
              placeholder: 'Select Country',
              prior: true,
              axcessTranslate: 'foreignCountry',
              isPriorEditable: true,
              default: 'US',
              col: 6,
              colPhone: 12,
              colTablet: 6,
              colLaptop: 4,
              axcess: {
                pull: {
                  fields: ['IFDSGEN.24'],
                  fn: 'foreignCountryPull',
                }
              }
            },
            {
              label: 'State',
              name: 'IFDSGEN.22',
              type: 'select',
              lookup: 'STATES',
              placeholder: 'Select State',
              isVisible: true,
              prior: true,
              maxLength: 2,
              col: 3,
              colPhone: 12,
              colTablet: 6,
              colLaptop: 4,
              isPriorEditable: true
            },
            {
              label: 'State/Prov',
              name: 'IFDSGEN.39',
              type: 'freeText',
              placeholder: 'Enter State/Prov/County',
              isVisible: false,
              prior: true,
              maxLength: 17,
              col: 3,
              colPhone: 12,
              colTablet: 6,
              colLaptop: 4,
              isPriorEditable: true
            },
            {
              label: 'Province',
              name: 'IFDSGEN.39.1',
              type: 'select',
              lookup: 'PROVINCES',
              placeholder: 'Enter Province',
              prior: true,
              isVisible: false,
              col: 3,
              colPhone: 12,
              colTablet: 6,
              colLaptop: 4,
              validations: {...Validators.required()}
            },
            {
              label: 'Zip/Postal',
              name: 'IFDSGEN.23',
              type: 'zippostal',
              placeholder: ' Zip/Postal',
              prior: true,
              isPriorEditable: true,
              col: 3,
              colPhone: 12,
              colTablet: 6,
              colLaptop: 4,
              axcess: {
                pull: {
                  fields: ['IFDSGEN.24', 'IFDSGEN.23', 'IFDSGEN.40'],
                  fn: 'zipPostalPull'
                }
              }
            }
          ],
        },
      ]
    },
    {
      title: 'Spouse Information',
      isVisible: false,
      sectionId: 3,
      collapseClass: 'collapsibleForm',
      worksheet: 'Federal\\General\\Basic Data'
    }
  ];

  const indvidualDetails = (prefix) => {
    return [
      {
        groupId: 1,
        groupType: 'details',
        fields: [
          {
            label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.',
            type: 'label',
            isVisible: false,
          },
        ]
      },
      {
        groupId: 2,
        groupType: 'lineItem',
        class: 'aboutYouFieldsRow',
        fields: [
          {
            label: 'First Name',
            name: prefix === 'payer' ? 'IFDSGEN.41' : 'IFDSGEN.45',
            type: 'freeText',
            placeholder: 'Enter Name',
            prior: true,
            required: true,
            maxLength: 25,
            validations: {
              ...Validators.required()
            },
            isPriorEditable: true,
            logicFunction: prefix !== 'spouse' ? [
              {trigger: 'setIFDSGEN19Required', isDifferentGroup: true},
              {trigger: 'setIFDSGEN21Required', isDifferentGroup: true},
              {trigger: 'setIFDSGEN22Required', isDifferentGroup: true},
              {trigger: 'setIFDSGEN23Required', isDifferentGroup: true}
            ] : []
          },
          {
            label: 'Initial',
            name: prefix === 'payer' ? 'IFDSGEN.42' : 'IFDSGEN.46',
            type: 'freeText',
            placeholder: 'Enter Name',
            prior: true,
            maxLength: 1,
            isPriorEditable: true
          },
          {
            label: 'Last Name',
            name: prefix === 'payer' ? 'IFDSGEN.43' : 'IFDSGEN.47',
            type: 'freeText',
            placeholder: 'Enter Name',
            prior: true,
            maxLength: 25,
            validations: {...Validators.required()},
            isPriorEditable: true
          },
          {
            label: 'Suffix',
            name: prefix === 'payer' ? 'IFDSGEN.44' : 'IFDSGEN.48',
            type: 'select',
            prior: true,
            lookup: 'SUFFIX',
            isPriorEditable: true
          },
          {
            label: 'Date of Birth',
            name: prefix === 'payer' ? 'IFDSGEN.6' : 'IFDSGEN.15',
            type: 'date',
            placeholder: 'Select Date',
            prior: true,
            maxDate: 'today',
            validations: {
              ...Validators.required(),
            },
            axcessTranslate: 'date',
            isPriorEditable: true,
          },
          {
            label: 'Date of Death (If Applicable)',
            name: prefix === 'payer' ? 'IFDSGEN.7' : 'IFDSGEN.16',
            type: 'date',
            placeholder: 'Select Date',
            maxDate: `12-31-${year.next}`,
            minDate: `01-01-${year.prior}`,
            message: `Year can only be ${year.prior}, ${year.current} or ${year.next}`,
            validations: {
              ...Validators.maxDate('today'),
              ...Validators.minDate(),
            },
            axcessTranslate: 'date',
          }
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [
            {label: 'Details', col: 2, colPhone: 12, colTablet: 6, colLaptop: 4, align: 'noPaddingLeft'},
            {label: '', col: 1, colPhone: 12, colTablet: 6, colLaptop: 4,},
            {label: '', col: 2, colPhone: 12, colTablet: 6, colLaptop: 4,},
            {label: '', col: 1, colPhone: 12, colTablet: 6, colLaptop: 4,},
            {label: '', col: 3, colPhone: 12, colTablet: 6, colLaptop: 4,},
            {label: '', col: 3, colPhone: 12, colTablet: 6, colLaptop: 4,},
          ],
          footerLabels: [],
        }
      },
      {
        groupId: 3,
        groupType: 'lineItem',
        class: 'aboutYouFieldsRow',
        isShowHeader: false,
        fields: [
          {
            label: 'Contact Phone #',
            name: prefix === 'payer' ? 'IFDSGEN.27' : 'IFDSGEN.35',
            type: 'telephone',
            placeholder: 'Enter Number',
            prior: true,
            isPriorEditable: true
          },
          {
            label: 'Email',
            maxLength: 50,
            name: prefix === 'payer' ? 'IFDSGEN.25' : 'IFDSGEN.26',
            type: 'email',
            placeholder: 'Enter Email Address',
            prior: true,
            validations: {...Validators.required(), ...Validators.email()},
            isPriorEditable: true
          },
          {
            label: 'Occupation',
            name: prefix === 'payer' ? 'IFDSGEN.8' : 'IFDSGEN.29',
            type: 'freeText',
            placeholder: 'Enter Occupation',
            prior: true,
            maxLength: 23,
            isPriorEditable: true
          },
          {
            label: 'SSN / ITIN',
            name: prefix === 'payer' ? 'IFDSGEN.5' : 'IFDSGEN.14',
            type: 'ssn',
            placeholder: 'Enter SSN/ITN',
            prior: true,
            overRideText: 'ON FILE',
            isTextFieldWithPrior: true,
            validations: {
              ...Validators.required(),
              ...Validators.ssn(),
            },
            axcessTranslate: 'omitOnOverride',
            isMaskOnBlur: true,
          },
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [
            {label: '', col: 3, colPhone: 12, colTablet: 6, colLaptop: 4,},
            {label: '', col: 3, colPhone: 12, colTablet: 6, colLaptop: 4,},
            {label: '', col: 3, colPhone: 12, colTablet: 6, colLaptop: 4,},
            {label: '', col: 3, colPhone: 12, colTablet: 6, colLaptop: 4,},
          ],
          footerLabels: [],
        }
      },
      {
        groupId: 4,
        groupType: 'lineItem',
        class: 'aboutYouFieldsRow',
        isShowHeader: false,
        hasDivider: true,
        fields: [
          {
            label: 'Driver\'s License # / State ID',
            name: prefix === 'payer' ? 'IFDSGEN.53' : 'IFDSGEN.54',
            type: 'driversLicense',
            placeholder: 'Enter License Number',
            prior: true,
            maxLength: 40,
            isMaskOnBlur: true,
            // overRideText: 'ON FILE',
            axcessTranslate: 'omitOnOverride',
            isPriorEditable: true
          },
          {
            label: 'Issuing State',
            name: prefix === 'payer' ? 'IFDSGEN.55' : 'IFDSGEN.56',
            type: 'select',
            lookup: 'STATES',
            placeholder: 'Select State',
            prior: true,
            isPriorEditable: true
          },
          {
            label: 'Expiration Date',
            name: prefix === 'payer' ? 'IFDSGEN.57' : 'IFDSGEN.58',
            type: 'date',
            placeholder: 'Select Date',
            prior: true,
            minDate: `01-01-${year.prior}`,
            maxDate: `12-31-${year.current + 50}`,
            message: `Year must be between 01/01/${year.prior} and 12/31/${year.current + 50}`,
            axcessTranslate: 'date',
            isPriorEditable: true
          },
          //Year Calculation - Year must be between 01/01/YYYY-1 and 12/31/YYYY+50 (YYYY - Current Year). Otherwise, display Error message.
          {
            label: 'Issue Date',
            name: prefix === 'payer' ? 'IFDSGEN.59' : 'IFDSGEN.60',
            type: 'date',
            placeholder: 'Select Date',
            prior: true,
            maxDate: 'today',
            axcessTranslate: 'date',
            isPriorEditable: true
          },
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [
            {label: '', col: 3, colPhone: 12, colTablet: 6, colLaptop: 4,},
            {label: '', col: 3, colPhone: 12, colTablet: 6, colLaptop: 4,},
            {label: '', col: 3, colPhone: 12, colTablet: 6, colLaptop: 4,},
            {label: '', col: 3, colPhone: 12, colTablet: 6, colLaptop: 4,},
          ],
          footerLabels: [],
        }
      },
      {
        groupId: 5,
        fields: [
          {
            label: `Did the IRS assign you an Identity Protection PIN (IP PIN) for ${year.current}?`,
            col: 10,
            colPhone: 12,
            colTablet: 9,
            colLaptop: 9,
            name: 'irsIdentityProtectionLabel',
            type: 'label'
          },
          {
            label: 'Yes/No',
            name: `${prefix}HasIpp`,
            hasDivider: true,
            type: 'select',
            col: 2,
            colPhone: 12,
            colTablet: 3,
            colLaptop: 3,
            lookup: 'YESNO_DONTKNOW',
            placeholder: 'I dont know',
            triggered: prefix === 'payer' ? 'setHasIrsPinPayer' : 'setHasIrsPinSpouse',
            validations: {...Validators.required()}
          },
          {
            label: 'Please enter this year\'s IP PIN:',
            name: 'irsEnterPinLabel',
            col: 10,
            colPhone: 12,
            colTablet: 9,
            colLaptop: 9,
            type: 'label',
            isVisible: false
          },
          // TODO: make year dynamic
          {
            label: 'IP PIN',
            name: prefix === 'payer' ? 'IFDSGEN.12' : 'IFDSGEN.13',
            col: 2,
            colPhone: 12,
            colTablet: 3,
            colLaptop: 3,
            hasDivider: true,
            type: 'irspin',
            placeholder: 'Enter 6 Digit PIN',
            isVisible: false,
            validations: {...Validators.required()}
          } // required if Identity Protection PIN is marked 'yes'
        ],
      },
      {
        groupId: 6,
        fields: [
          {
            label: 'Current Marital Status',
            name: 'maritalStatusLabel',
            type: 'label',
            col: 9,
            colPhone: 12,
            colTablet: 9,
            colLaptop: 9
          },
          {
            label: 'Marital Status',
            name: prefix === 'payer' ? 'IFDSGEN.2' : '',
            col: 3,
            colPhone: 12,
            colTablet: 3,
            colLaptop: 3,
            type: 'select',
            lookup: 'MARITIAL_STATUS',
            triggered: 'setIsMarried',
            prior: true,
            validations: {...Validators.required()},
            isPriorEditable: true,
            axcessTranslate: 'maritalStatus',
            logicFunction: [
              {trigger: 'setMaritalStatus'},
            ]
          },
        ]
      },
      {
        groupId: 8,
        groupType: 'blueBox',
        isSingleColumn: true,
        fields: []
      },
      {
        groupId: 7,
        hasDivider: true,
        fields: [
          ...(prefix === 'spouse' ? [] : [
            {
              label: `1. I can be claimed as a dependent on someone else\'s return.`,
              name: 'claimedDependentLabel',
              type: 'label',
              col: 9,
              colPhone: 12,
              colTablet: 9,
              colLaptop: 9
            },
            {
              label: 'Yes/No',
              name: prefix === 'payer' ? 'IFDSGEN.11' : '',
              type: 'select',
              lookup: 'YESNO_DONTKNOW',
              placeholder: 'I dont know',
              col: 3,
              colPhone: 12,
              colTablet: 3,
              colLaptop: 3,
              axcessTranslate: 'YNDKtoXCheckbox',
              prior: true,
              isPriorEditable: true,
              hasDivider: true,
              wasCheckbox: true,
              axcess: {
                pull: {
                  fields: ['IFDSGEN.11'],
                  fn: 'checkboxPullToYesNoDontKnow',
                }
              }
            },
          ]),

          {
            label: `${prefix === 'spouse' ? 1 : 2}. I am legally blind.`,
            name: 'legallyBlindLabel',
            type: 'label',
            col: 9,
            colPhone: 12,
            colTablet: 9,
            colLaptop: 9
          },
          {
            label: 'Yes/No',
            name: prefix === 'payer' ? 'IFDSGEN.10' : 'IFDSGEN.18',
            type: 'select',
            lookup: 'YESNO_DONTKNOW',
            placeholder: 'I dont know',
            col: 3,
            colPhone: 12,
            colTablet: 3,
            colLaptop: 3,
            wasCheckbox: true,
            axcessTranslate: 'YNDKtoXCheckbox',
            prior: true,
            isPriorEditable: true,
            hasDivider: true,
            axcess: {
              pull: {
                fields: prefix === 'payer' ? ['IFDSGEN.10'] : ['IFDSGEN.18'],
                fn: 'checkboxPullToYesNoDontKnow',
              }
            }
          },

          {
            label: `${prefix === 'spouse' ? 2 : 3}. I want to contribute to the Presidential Election Campaign Fund.`,
            name: 'campaignFundLabel',
            type: 'label',
            col: 9,
            colPhone: 12,
            colTablet: 9,
            colLaptop: 9
          },
          {
            label: 'Yes/No',
            name: prefix === 'payer' ? 'IFDSGEN.9' : 'IFDSGEN.17',
            type: 'select',
            lookup: 'YESNO_DONTKNOW',
            placeholder: 'I dont know',
            col: 3,
            colPhone: 12,
            colTablet: 3,
            colLaptop: 3,
            axcessTranslate: 'YNDKDropdown',
            prior: true,
            isPriorEditable: true,
            hasDivider: true,
            wasCheckbox: true,
          },

          {
            label: `${prefix === 'spouse' ? 3 : 4}. I am NOT a US Citizen or Green Card Holder.`,
            name: 'citizenGreenCardLabel',
            type: 'label',
            col: 9,
            colPhone: 12,
            colTablet: 9,
            colLaptop: 9,
          },
          {
            label: 'Yes/No',
            name: `${prefix}USCitizenOrGreenCardHolder`,
            type: 'select',
            lookup: 'YESNO_DONTKNOW',
            placeholder: 'I dont know',
            col: 3,
            colPhone: 12,
            colTablet: 3,
            colLaptop: 3,
            prior: true,
            isPriorEditable: true,
            hasDivider: true,
            wasCheckbox: true
          },

          {
            label: `${prefix === 'spouse' ? 4 : 5}. Applied for ITIN.`,
            name: 'itinLabel',
            type: 'label',
            col: 9,
            colPhone: 12,
            colTablet: 9,
            colLaptop: 9,
          },
          {
            label: 'Yes/No',
            name: prefix === 'payer' ? 'IFDSGEN.31' : 'IFDSGEN.32',
            type: 'select',
            lookup: 'YESNO_DONTKNOW',
            placeholder: 'I dont know',
            col: 3,
            colPhone: 12,
            colTablet: 3,
            colLaptop: 3,
            axcessTranslate: 'YNDKtoXCheckbox',
            wasCheckbox: true,
            isPriorEditable: true,
          },
        ]
      },
    ];
  };

  sections.filter((section) => section.sectionId === 1)[0].groups = indvidualDetails('payer');
  sections.filter((section) => section.sectionId === 3)[0].groups = indvidualDetails('spouse');
  const spouseGroups = sections.filter((section) => section.sectionId === 3)[0].groups;
  spouseGroups.splice(5, 1);
  spouseGroups[5].groupId = 6;

  return sections;
};

export default aboutYourFn;


