import React, { useEffect } from 'react'
import { Grid, GridCell } from 'react-md';
import { getCardProp } from '@utilities/helpers/getCardProp';
import usePageFramework from '@utilities/hooks/usePageFramework';


import './mobileLimitedExperience.scss';

const MobileLimitedExperience = (props) => {
    const {formName, formNav} = props?.customProp;
    const { updateCard} = usePageFramework(formNav);

    useEffect(() => { 
        updateCard()
    }, []);

    return (
        <Grid className='mobileLimitedExperienceCard' data-testid="mobileLimitedExperienceCard">
            <GridCell className='limitedExperienceImgContainer'>
                <img  data-testid="mobileLimitedExperienceImg"className="overHangImg" alt={`Icon for ${getCardProp(formName, 'title')} dashboard card`} src={getCardProp(formName, 'dashBoardIcon')} />
            </GridCell>
            <GridCell>
                <h2>To complete this card, please visit this website on your laptop or desktop device.</h2>
            </GridCell>
            <GridCell>
                <p>
                    1040 Mobile offers a limited experience. You can upload any relevant documents using the Upload feature in the main dashboard.
                    However, due to the detailed information requested in this card, it will not fully display in the mobile version of the application.
                </p>
            </GridCell>
        </Grid>
    )
}

export default MobileLimitedExperience