import * as EVENT from '@utilities/constants/triggerKeys';
import { formatSsn, TransformEin } from '@utilities/helpers/formatSsn';
import { setConditionalFieldValidation } from '@components/formRenderer/triggers';

const setMortgageBalanceLabels = (event, options) => {
    const { sections, formSections, setFormSections, inputState } = options;
    const name = formSections ? formSections : sections;
    
    name[0].groups[0].fields[4].isVisible = event === EVENT.KEY_YES;
    name[0].groups[0].fields[5].isVisible = event === EVENT.KEY_YES;
    name[0].groups[0].fields[6].isVisible = event === EVENT.KEY_YES;
    name[0].groups[0].fields[7].isVisible = event === EVENT.KEY_YES;
    
    name[0].groups[0].fields[3].default = event;
    const prevState = name[0].groups[0].fields[3].inputState || 0;
    name[0].groups[0].fields[3].inputState = (prevState & inputState);
   
    setFormSections(name);
};

const setHomeEquityLoanLabels = (event, options) => {
    const { sections, formSections, setFormSections, inputState } = options;
    const name = formSections ? formSections : sections;
    
    name[0].groups[0].fields[10].isVisible = event === EVENT.KEY_YES;
    name[0].groups[0].fields[11].isVisible = event === EVENT.KEY_YES;
    name[0].groups[0].fields[12].isVisible = event === EVENT.KEY_YES;
    name[0].groups[0].fields[13].isVisible = event === EVENT.KEY_YES;
    
    name[0].groups[0].fields[9].default = event;
    const prevState = name[0].groups[0].fields[9].inputState || 0;
    name[0].groups[0].fields[9].inputState = (prevState & inputState);
   
    setFormSections(name);
};

const setMortgageLenderIdField = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name[0].groups[0].fields[2].isVisible = event === EVENT.EVENT_KEY_SSN;
    name[0].groups[0].fields[3].isVisible = event === EVENT.EVENT_KEY_EIN;

    name[0].groups[0].fields[1].default = event;

    setFormSections(name);
};

const setMortgageLenderCountry = (event, options) => {
    const { sections, formSections, setFormSections, } = options;
    const name = formSections ? formSections : sections;

    name[0].groups[1].fields[3].isVisible = event === EVENT.KEY_UNITED_STATES;
    name[0].groups[1].fields[4].isVisible = event !== EVENT.KEY_UNITED_STATES ;

    name[0].groups[1].fields[0].default = event;
    setFormSections(name);
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'setMortgageBalanceLabels':
            setMortgageBalanceLabels(event, options);
        break;
        case 'setHomeEquityLoanLabels':
            setHomeEquityLoanLabels(event, options);
        break;
        case 'setMortgageLenderIdField':
            setMortgageLenderIdField(event, options)
        break;
    case 'setMortgageLenderCountry':
            setMortgageLenderCountry(event, options)
        break;
    default:
        break;
    }
};

const setMortgageInfoLabel = (options) => {
    const { group, parentGroup, parentIndex } = options;
    let lenderName = '';
    let lenderIdType = '';
    let lenderId = '';

    group.fields.forEach((groupField) => {
        if (groupField.name === 'IFDSIDHM.0') {
            lenderName = groupField.default || '';
        }
        if (groupField.name === 'mortgageLenderIdType') {
            lenderIdType = groupField.default || '';
        }
        if ((groupField.name === 'IFDSIDHM.1SSN' && lenderIdType === EVENT.EVENT_KEY_SSN) || (groupField.name === 'IFDSIDHM.1EIN' && lenderIdType === EVENT.EVENT_KEY_EIN)) {
            const maskedLenderId = groupField.prioryearValue ? groupField.overRideText : groupField.default?.length === 9 ? ('' + groupField.default).slice(0, -4).replace(/./g, 'X') + ('' + groupField.default).slice(-4) : groupField.default;
            lenderId = groupField.default && groupField.default.trim() !== '' ? `${lenderIdType}: ${groupField.type === 'ein' ? TransformEin(maskedLenderId) : formatSsn(maskedLenderId)}` : '';
        }
    });

    if (lenderName.length > 23) {
        lenderName = `${lenderName.substring(0, 20)}...`;
    }

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === 'lenderSummary') {
            lineItemField.default = `${lenderName}\n${lenderId}`;
        }
    });
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) { 
        case 'setMortgageInfoLabel': 
            setMortgageInfoLabel(options);
        break;
        case 'setConditionalFieldValidation':
            setConditionalFieldValidation(options);
        break;
    default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};