import { useEffect, useState } from 'react';
import { Button, FontIcon, TextIconSpacing } from 'react-md';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import SearchField from '../searchField';
import Scroll, { scroller } from 'react-scroll';
const Link = Scroll.Link

function TableManagerTabs(props) {
    const { group, isFormLocked, fns, section, setCurrentEntity, setCurrentH2, setIsAddingNewEntity, addNewLineItem } = props;
    const initialEntities = group.groupType !== 'bondsItem' ? group?.entities.filter(entity => entity?.sections[0]?.groups[0]?.fields[1]?.default !== "PLEASE DELETE") : group?.bonds;
    const totalRowTitles = [{title: group.isH2TabsHidden ? group?.lineSections[0]?.title : 'Summary'}];
    const summarySection = {
        title: 'Summary',
        isVisible: true, 
        isCollapsed: true,
    };
    const expandAll = {
        title: 'expandAll',
    };
    const [ currentIndex, setCurrentIndex ] = useState((group?.currentEntity >= 0) ? group?.currentEntity : (group?.currentEntity === null ? 0 : -1));
    const [ currentH2Index, setCurrentH2Index ] = useState(1);
    const [ isHandleH2Click, setIsHandleClick ] = useState(false);
    if (group.groupType === 'bondsItem') group.bondsSections
        .forEach(section => totalRowTitles.push({
            title: section.title, 
            isTitleHidden: section.isTitleHidden !== undefined ? section.isTitleHidden : false
        }));
    else if (group.lineSections && !group.isH2TabsHidden) group.lineSections
        .forEach(section => totalRowTitles.push({
            title: section.title, 
            isTitleHidden: section.isTitleHidden !== undefined ? section.isTitleHidden : false
        }));

    const h2Entities = currentIndex < 0 ? null : group.groupType === 'bondsItem' ? group.bonds[currentIndex]?.sections : group.entities[currentIndex]?.sections;
    const h2Titles = h2Entities ? [summarySection, ...h2Entities] : totalRowTitles;
    
    const summaryAllType = group.summaryAllType ? `Summary - \nAll ${group.summaryAllType}` : 'Summary - All';
    const entityPlaceHolder = group.entityPlaceHolder || 'Person';
    const searchOptions = initialEntities.map((entity, index) => {
        let entityName = '';
        let entityTitle = '';
        if (group.summaryAllType === 'Bonds') {
            entityName = `${index + 1}`;
        } else {
            entity.sections.filter(sec => sec.isVisible).forEach(section => section.groups.forEach(group1 => group1.fields.forEach(field => {
                if (group.entityNameField && field.name === group.entityNameField) entityName = field.default;
                if (group.entityNameFields && group.entityNameFields.some(nameField => field.name === nameField)) entityName = field.default;
                if (group.entityTitleField && field.name === group.entityTitleField) entityTitle = field.default;
            })));
        }
        
        const name = group.entityNameField || group.entityNameFields ? entityName : entity?.sections[0]?.groups[0]?.fields[0]?.default;
        const title = group.entityTitleField ? entityTitle : entity?.sections[0]?.groups[0]?.fields[1]?.default;
        return name ? { 
            label: <div><span className='searchName'>{name}</span>{group.entityTitleField ? <span className='searchName searchTitle'> - {title}</span> : null}</div>, 
            value: name,
            entityindex: index,
        } : {};
    });

    useEffect(() => {
        setCurrentIndex(group.currentEntity);
        handleH2Click(currentH2Index, h2Titles[currentH2Index]);
        if (group.currentEntity !== group.entities?.length - 1) {
            setIsAddingNewEntity(false);
        }
    },[group.currentEntity, currentIndex]);

    useEffect(() => {
        if(!isHandleH2Click){
            if (!group.isAddingNewEntity){
                handleH2Click(h2Titles.findIndex(h2 => h2.title === group.currentH2), h2Titles.find(h2 => h2.title === group.currentH2));
            }else{
                handleH2Click(1, h2Titles[1]);
            }
        }
        setIsHandleClick(false);
    },[group.currentH2, group.isAddingNewEntity]);

    const changeTab = (index) => {
        setCurrentIndex(index);
        group.currentEntity = index;
        group.currentViewField = 100;
        setCurrentEntity(group.currentEntity);
        
        const el = document.getElementById(`denseLineitem-${currentIndex}`);
        if (el) {
           el.scrollIntoView({behavior: "smooth", block: "start"});
        }
    };

    const handleH2Click = (index, h2, isHandleH2Click) => {
        setIsHandleClick(isHandleH2Click);
        if (h2 && h2.title) {
            const el = document.getElementById(h2.title);
            if (el) {
                el.scroll({top: 0})
                if (
                    h2.title === 'Benefits Details' || 
                    h2.title === 'Fiscal Year Details' ||
                    h2.title === 'Average Hours/Week'
                ) {
                    el.scrollIntoView({behavior: "smooth", block: "center"});
                } else {
                    if (h2.title === 'Compensation Details') {
                        el.scrollIntoView({behavior: "smooth", block: "nearest"});
                    } else if (h2.bondID) {
                        el.scrollIntoView({behavior: "smooth", block: "center"});
                        if (h2.title === 'Bond Details') {
                            el.scrollIntoView({behavior: "smooth", block: "start"});
                        }
                    } else {
                        el.scrollIntoView({behavior: "smooth", block: "start"});
                    }
                }
            }
        }

        if (index === -1) {
            setCurrentH2Index(index);
        } else {
            setCurrentH2Index(index);
            if(group.summaryAllType !== 'Bonds' ||  (isHandleH2Click && group.summaryAllType === 'Bonds')){
                h2Titles.forEach((h2Entity) => {
                    if (h2Entity.title === h2?.title && h2Entity.title === group.currentH2){
                        h2Entity.isCollapsed = !h2Entity.isCollapsed;
                    }
                });
            }
        }
        group.currentH2 = group.isH2TabsHidden ? group?.lineSections[0]?.title : h2?.title;
        setCurrentH2(group.currentH2);
    };

    const onSearchSelect = (autoCompleteData) => {
        group.currentEntity = autoCompleteData.result.entityindex;
        setCurrentEntity(group.currentEntity);
    };

    const getEntityName = (entity) => {
        let entityName = '';
        entity.sections.filter(sec => sec.isVisible).forEach(section => section.groups.forEach(group1 => group1.fields.forEach(field => {
            if (group.entityNameField && field.name === group.entityNameField && field.type !== 'label') entityName = field.default || field.value;
            if (group.entityNameFields && group.entityNameFields.some(nameField => field.name === nameField && field.type !== 'label')) entityName = field.default || field.value;
        })));
        return entityName;
    };

    const entityTabs = initialEntities?.map((entity, index) => {
        const entityName = group.entityNameField || group.entityNameFields ? getEntityName(entity) : entity?.sections[0]?.groups[0]?.fields[0]?.default; 
        let name = entityName && entityName.trim() !== '' ? entityName : `${entityPlaceHolder} ${index + 1}`;

        return (
            <a key={`entityTab-${index}`} className={currentIndex === index ? 'selectedTab' : 'entityTab'} style={{width: '140px'}} onClick={ () => {changeTab(index)}} >{name}</a>
        );
    });
    
    const h2TitlesTab = h2Titles?.map((h2, index) => {
        const isSelected = (currentH2Index === -1 && h2.title !== 'Summary') || currentH2Index === index;
        let section = '';
        
        if (group.lineSections) {
            section = group.lineSections
                .find(section => h2.title === section.title && !section.isTitleHidden);
        } else if (group.bondsSections) {
            section = group.bondsSections
                .find(section => h2.title === section.title && !section.isTitleHidden);
        }
        
        return (h2.title === 'Summary' || section) && (
            <Link 
                key={`a-${index}`} 
                className={ isSelected ? 'h2Tab selectedH2' : 'h2Tab'}
                to={h2.title ? h2.title : ''}
                spy={true} 
                smooth={true} 
                duration={500} 
                onClick={ () => {handleH2Click(index, h2, true)} }
                containerId="containerElement" 
            >
                {h2.title}
            </Link>
        );
    });
    const hideSummaryAllTab = Boolean(group.hideSummaryAll) ;
    return (
        <div className='tableManagerTabsContainer'>  
            <div className= {'scrollTabGroup'}>
                <div className={'scrollTabContainer'}>
                  {!hideSummaryAllTab &&  <a key={'summary-tab'} className={currentIndex == -2 ? 'selectedTab summaryTabSelected' : 'entityTab summaryTab'} style={{width: '140px', whiteSpace: 'pre-line' }} onClick={ () => {changeTab(-2)}} >{summaryAllType}</a>}
                    <ScrollMenu
                        arrowLeft={<FontIcon className='menuArrow'>chevron_left</FontIcon>}
                        arrowRight={<FontIcon className='menuArrow'>chevron_right</FontIcon>}
                        clickWhenDrag={false}
                        data={entityTabs}
                        dragging={true}
                        hideArrows={true}
                        hideSingleArrow={true}
                        scrollToSelected={true}
                        selected={`entityTab-${currentIndex}`}
                        // transition={0.3}
                        // translate={0}
                        alignCenter={false}
                    />
                </div>
                <div className='scrollMenuBtnContainer'>
                    {((group.groupType === 'bondsItem') || (group.groupType === 'lineItem' && group.isRestrictAdd)) ? null :
                    <Button 
                        className='addEntityIcon' 
                        data-testid='add-new-entity'
                        buttonType='icon' 
                        onClick={isFormLocked ? () => {} : () => {setCurrentH2Index(1); addNewLineItem(group);}} 
                        disabled={isFormLocked}
                    >   
                        <TextIconSpacing className='addNewText' icon={<FontIcon>add</FontIcon>}></TextIconSpacing>
                        {`Add ${entityPlaceHolder}`}
                    </Button> }
                    <div className='verticalLine'> | </div>
                    <SearchField placeholder="Search:" options={searchOptions} onSearchSelect={onSearchSelect}/>
                </div>
            </div>
            {group.isH2TabsHidden ? null : <div className="h2TabSection">
                <div className="h2TabsContainer">{h2TitlesTab}</div>
            </div>}
        </div>
    );
};

export default TableManagerTabs;