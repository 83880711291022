import {
    RETIREMENT_INCOME_SOCIAL_SECURITY_UPLOAD,
    RETIREMENT_INCOME_RETIREMENT_PLAN_UPLOAD,
} from '@utilities/constants/strings';

const retirementFn = (year) => {
    const sections = [
        {
            title: 'Social Security Benefits',
            isVisible: true,
            collapseClass: 'collapsibleForm',
            sectionId: 1,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSSSA.0', type: 'select', lookup: 'PAYER_TS', placeholder: 'Select', prior: true },
                        { label: 'Social Security Administration', name: 'IFDSSSA.4', type: 'label', placeholder: 'Enter Name' },
                        { label: '', name: '', type: 'label' },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSSSA.8', type: 'label', isTotal: true, prior: true },
                        { label: '', name: '', type: 'label' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Benefits Details', col: 2, colTablet: 2 },
                            { label: 'Payer', col: 4, colTablet: 3 },
                            { col: 1, colTablet: 0, align: 'right' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { col: 2, colTablet: 0, align: 'right' },
                            { label: 'SSA-1099', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Social Security Benefits', icon: 'add' },
                            { type: 'label', label: 'Total', col: 5, colTablet: 5 },
                            { type: 'totalMoney', name: 'IFDSSSA.8', col: 4, colTablet: 2, label: '$ 0', year: 'prior', isTotal: true, },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: RETIREMENT_INCOME_SOCIAL_SECURITY_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                },
            ]
        },
        {
            title: 'Retirement Plan Distributions (Form 1099-R)',
            isVisible: true,
            collapseClass: 'collapsibleForm',
            sectionId: 2,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDS099R.0', type: 'select', lookup: 'PAYER_TS', placeholder: 'Select', prior: true },
                        { label: 'Payer', name: 'IFDS099R.4', type: 'freeText', placeholder: 'Enter Name', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Dist. Amount', name: 'IFDS099R.5', type: 'label', isTotal: true, prior: true },
                        { label: '$ 0', wrapLabel: '2019 Qualified Charitable Dist.', name: 'IFDSPENO.14', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Qualified Charitable Dist.', name: 'C-IFDSPENO.14', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', col: 2, colTablet: 2 },
                            { label: 'Payer', col: 4, colTablet: 2 },
                            { label: `${year.prior} Dist. Amount`, col: 1, colTablet: 1, align: 'right' },
                            { label: `${year.prior} Qualified Charitable Dist.`, col: 2, colTablet: 1, align: 'right' },
                            { label: `${year.current} Qualified Charitable Dist.`, col: 2, colTablet: 1, align: 'right' },
                            { label: 'Form 1099-R / Form 5498', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Retirement Plan Distributions (Form 1099-R)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 5, colTablet: 4 },
                            { type: 'totalMoney', name: 'IFDS099R.5', col: 2, colTablet: 1, year: 'prior', labelText: 'Dist. Amount', label: '$ 0', },
                            { type: 'totalMoney', name: 'IFDSPENO.14', col: 2, colTablet: 1, year: 'prior', label: '$ 0', },
                            { type: 'totalMoney', name: 'C-IFDSPENO.14', col: 2, colTablet: 1, year: 'current', label: '$ 0', },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: RETIREMENT_INCOME_RETIREMENT_PLAN_UPLOAD, viewSectionId: 0 },
                        ]
                    },
                }
            ]
        }
    ];

    return sections;
};

export default retirementFn;