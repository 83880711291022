import { PRIOR, CURRENT } from '../../src/constants/years';

export const yearSetter = {
  val: null,
  /**
     * @param {null} value
     */
  set year(value) {
    this.val = value;
  },
  get year() {
    if (this.val) {
      return this.val;
    }
  }
}

export const getYear = (year) => {
    // const currentDate = new Date();
    // let currentYear = currentDate.getFullYear();
    // console.log('year: ', yearSetter.year)
    // console.log('year text: ', year)
    // return year === 'prior' ? 2020 : 
    //     year === 'current' ? 2021 : '';
    if (yearSetter.year?.[year]) return yearSetter.year[year];
    else if (year === 'prior') return PRIOR;
    else if (year === 'current') return CURRENT;
    else return '';
};

const labelReducer = (accumulator, currentValue) => {
    if (typeof accumulator === 'number' && 
        typeof currentValue === 'number') 
        return accumulator + currentValue;
    else if (typeof accumulator === 'object' && 
        typeof currentValue === 'object') 
        return {
            year : accumulator.year, 
            label: accumulator.label + currentValue.label
        };
};

export const getTotalByYear = (amounts, year) => {
    let amount;
    if(amounts && amounts.length > 0){
        let filteredAmount = amounts.map(footerLabel => {
            amount = footerLabel.find(item => item.year === year);
            if (amount) 
                return {
                    year: getYear(amount.year), 
                    label: amount.label ? parseInt(amount.label?.replace(/[^a-zA-Z0-9]/g, '').trim()) : 0
                };
        })
            .filter(item => item !== undefined);
            
        return filteredAmount.length > 0 ? filteredAmount.reduce(labelReducer) : null;
    }else{
        return {
            year: getYear(amounts.year), 
            label: amounts.label ? parseInt(amounts.label?.replace(/[^a-zA-Z0-9]/g, '').trim()) : 0
        };
    }
    
};