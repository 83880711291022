export function isFieldRequired(field) {
    return field.validations && field.validations.required;
}

export const renderLabel = (field) => {
    let label = '';
    const isRequired = isFieldRequired(field);
    const isError = { color: field.error ? 'red' : '' };
    if (isRequired || field.required) {
        label = (
            <>
                <span style={isError}>{field.label}</span>&nbsp;&nbsp;
                <span className='requiredFieldLabel'>*</span>
            </>
        );
    } else {
        label = <span style={isError}>{field.label}</span>;
    }

    return label;
}