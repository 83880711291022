import * as STRINGS from './strings';
import * as AXCESS_KEYS from './axcessKeys';
import * as TRIGGER_KEYS from './triggerKeys';

/* eslint-disable linebreak-style */
function sortByName(itemA, itemB) {
    return itemA.name.toLowerCase().localeCompare(itemB.name.toLowerCase());
}

const DATE_FILTER_TYPES = [
    { value: 'Select', days: -1},
    { value: 'Today', days: 0 },
    { value: 'Yesterday', days: 1 },
    { value: 'Last 2 Days', days: 1 },
    { value: 'Last 7 Days', days: 7 },
    { value: 'Last 2 Weeks', days: 14 },
    { value: 'Last 30 Days', days: 30 },
    { value: 'Last 90 Days', days: 90 },
    { value: 'All', days: -1 },
];

const ADDRESS_TYPES = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'US', name: 'US' },
    { value: 'Foreign', name: 'Foreign' }
];

const YESNO = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Yes', name: 'Yes' },
    { value: 'No', name: 'No'},
];

const YESNO_RADIO = [
    { value: 'No', name: 'No'},
    { value: 'Yes', name: 'Yes' },
];

const MARITIAL_STATUS = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Single', name: 'Single' },
    { value: 'Married', name: 'Married' },
    { value: 'Widowed', name: 'Widowed' },
    { value: 'Separated', name: 'Separated' },
    { value: 'Divorced', name: 'Divorced' },
];

const DEP_STATUS = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Disabled', name: 'Disabled' },
    { value: 'Full time student', name: 'Full time student' },
    { value: 'No longer my dependent', name: 'No longer my dependent' },
];

const PAYER = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'T', name: 'Taxpayer' },
    { value: 'S', name: 'Spouse' },
    { value: 'J', name: 'Joint' },
];

const PAYER_TS = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'T', name: 'Taxpayer' },
    { value: 'S', name: 'Spouse' },
];

const PAYER_TSO = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'T', name: 'Taxpayer' },
    { value: 'S', name: 'Spouse' },
    { value: 'O', name: 'Other' },
];


const IRA = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Traditional', name: 'Traditional' },
    { value: 'Roth', name: 'Roth' },
];

const SELF_EMPLOYED_RETIREMENT_PLANS = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: AXCESS_KEYS.AXCESS_KEY_SIMPLE, name: STRINGS.RETIREMENT_TYPE_SIMPLE },
    { value: AXCESS_KEYS.AXCESS_KEY_SEP, name: STRINGS.RETIREMENT_TYPE_SEP },
    { value: AXCESS_KEYS.AXCESS_KEY_BENEFIT, name: STRINGS.RETIREMENT_TYPE_BENEFIT },
];

// Foreign Country codes (on About You page) are different than other country sections
const FOREIGN_COUNTRIES = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'US', name: 'United States' },
    { value: 'CA', name: 'Canada' },
    { value: 'AF', name: 'Afghanistan' },
    { value: 'AX', name: 'Akrotiri' },
    { value: 'AL', name: 'Albania' },
    { value: 'AG', name: 'Algeria' },
    { value: 'AQ', name: 'American Samoa' },
    { value: 'AN', name: 'Andorra' },
    { value: 'AO', name: 'Angola' },
    { value: 'AV', name: 'Anguilla' },
    { value: 'AY', name: 'Antarctica' },
    { value: 'AC', name: 'Antigua and Barbuda' },
    { value: 'AR', name: 'Argentina' },
    { value: 'AM', name: 'Armenia' },
    { value: 'AA', name: 'Aruba' },
    { value: 'AT', name: 'Ashmore and Cartier Islands' },
    { value: 'AS', name: 'Australia' },
    { value: 'AU', name: 'Austria' },
    { value: 'AJ', name: 'Azerbaijan' },
    { value: 'BF', name: 'Bahamas' },
    { value: 'BA', name: 'Bahrain' },
    { value: 'FQ', name: 'Baker Island' },
    { value: 'BG', name: 'Bangladesh' },
    { value: 'BB', name: 'Barbados' },
    { value: 'BO', name: 'Belarus' },
    { value: 'BE', name: 'Belgium' },
    { value: 'BH', name: 'Belize' },
    { value: 'BN', name: 'Benin' },
    { value: 'BD', name: 'Bermuda' },
    { value: 'BT', name: 'Bhutan' },
    { value: 'BL', name: 'Bolivia' },
    { value: 'BK', name: 'Bosnia-Herzegovina' },
    { value: 'BC', name: 'Botswana' },
    { value: 'BV', name: 'Bouvet Island' },
    { value: 'BR', name: 'Brazil' },
    { value: 'IO', name: 'British Indian Ocean Territory' },
    { value: 'VI', name: 'British Virgin Islands' },
    { value: 'BX', name: 'Brunei' },
    { value: 'BU', name: 'Bulgaria' },
    { value: 'UV', name: 'Burkina Faso' },
    { value: 'BM', name: 'Burma' },
    { value: 'BY', name: 'Burundi' },
    { value: 'CB', name: 'Cambodia' },
    { value: 'CM', name: 'Cameroon' },
    { value: 'CV', name: 'Cape Verde' },
    { value: 'CJ', name: 'Cayman Islands' },
    { value: 'CT', name: 'Central African Republic' },
    { value: 'CD', name: 'Chad' },
    { value: 'CI', name: 'Chile' },
    { value: 'CH', name: 'China' },
    { value: 'KT', name: 'Christmas Islands' },
    { value: 'IP', name: 'Clipperton Islands' },
    { value: 'CK', name: 'Cocos (Keeling) Islands' },
    { value: 'CO', name: 'Colombia' },
    { value: 'CN', name: 'Comoros' },
    { value: 'CF', name: 'Congo (Brazzaville)' },
    { value: 'CG', name: 'Congo (Kinshasa)' },
    { value: 'CW', name: 'Cook Islands' },
    { value: 'CR', name: 'Coral Sea Islands' },
    { value: 'CS', name: 'Costa Rica' },
    { value: 'IV', name: 'Cote D\'Ivoire (Ivory Coast)' },
    { value: 'HR', name: 'Croatia' },
    { value: 'CU', name: 'Cuba' },
    { value: 'UC', name: 'Curacao' },
    { value: 'CY', name: 'Cyprus' },
    { value: 'EZ', name: 'Czech Republic' },
    { value: 'DA', name: 'Denmark' },
    { value: 'DX', name: 'Dhekelia' },
    { value: 'DJ', name: 'Djibouti' },
    { value: 'DO', name: 'Dominica' },
    { value: 'DR', name: 'Dominican Republic' },
    { value: 'TT', name: 'East Timor' },
    { value: 'EC', name: 'Ecuador' },
    { value: 'EG', name: 'Egypt' },
    { value: 'ES', name: 'El Salvador' },
    { value: 'EK', name: 'Equatorial Guinea' },
    { value: 'ER', name: 'Eritrea' },
    { value: 'EN', name: 'Estonia' },
    { value: 'ET', name: 'Ethiopia' },
    { value: 'FK', name: 'Falkland Islands (Islas Malvinas)' },
    { value: 'FO', name: 'Faroe Islands' },
    { value: 'FM', name: 'Federated States of Micronesia' },
    { value: 'FJ', name: 'Fiji' },
    { value: 'FI', name: 'Finland' },
    { value: 'FR', name: 'France' },
    { value: 'FP', name: 'French Polynesia' },
    { value: 'FS', name: 'French Southern and Antarctic Lands' },
    { value: 'GB', name: 'Gabon' },
    { value: 'GA', name: 'The Gambia' },
    { value: 'GG', name: 'Georgia' },
    { value: 'GM', name: 'Germany' },
    { value: 'GH', name: 'Ghana' },
    { value: 'GI', name: 'Gibraltar' },
    { value: 'GR', name: 'Greece' },
    { value: 'GL', name: 'Greenland' },
    { value: 'GJ', name: 'Grenada' },
    { value: 'GQ', name: 'Guam' },
    { value: 'GT', name: 'Guatemala' },
    { value: 'GK', name: 'Guernsey' },
    { value: 'GV', name: 'Guinea' },
    { value: 'PU', name: 'Guinea-Bissau' },
    { value: 'GY', name: 'Guyana' },
    { value: 'HA', name: 'Haiti' },
    { value: 'HM', name: 'Heard Island and McDonald Islands' },
    { value: 'HO', name: 'Honduras' },
    { value: 'HK', name: 'Hong Kong' },
    { value: 'HQ', name: 'Howland Island' },
    { value: 'HU', name: 'Hungary' },
    { value: 'IC', name: 'Iceland' },
    { value: 'IN', name: 'India' },
    { value: 'ID', name: 'Indonesia' },
    { value: 'IR', name: 'Iran' },
    { value: 'IZ', name: 'Iraq' },
    { value: 'EI', name: 'Ireland' },
    { value: 'IS', name: 'Israel' },
    { value: 'IT', name: 'Italy' },
    { value: 'JM', name: 'Jamaica' },
    { value: 'JN', name: 'Jan Mayen' },
    { value: 'JA', name: 'Japan' },
    { value: 'DQ', name: 'Jarvis Island' },
    { value: 'JE', name: 'Jersey' },
    { value: 'JQ', name: 'Johnston Atoll' },
    { value: 'JO', name: 'Jordan' },
    { value: 'KZ', name: 'Kazakhstan' },
    { value: 'KE', name: 'Kenya' },
    { value: 'KQ', name: 'Kingman Reef' },
    { value: 'KR', name: 'Kiribati' },
    { value: 'KN', name: 'North Korea' },
    { value: 'KS', name: 'South Korea' },
    { value: 'KV', name: 'Kosovo' },
    { value: 'KU', name: 'Kuwait' },
    { value: 'KG', name: 'Kyrgyzstan' },
    { value: 'LA', name: 'Laos' },
    { value: 'LG', name: 'Latvia' },
    { value: 'LE', name: 'Lebanon' },
    { value: 'LT', name: 'Lesotho' },
    { value: 'LI', name: 'Liberia' },
    { value: 'LY', name: 'Libya' },
    { value: 'LS', name: 'Liechtenstein' },
    { value: 'LH', name: 'Lithuania' },
    { value: 'LU', name: 'Luxembourg' },
    { value: 'MC', name: 'Macau' },
    { value: 'MK', name: 'Macedonia' },
    { value: 'MA', name: 'Madagascar' },
    { value: 'MI', name: 'Malawi' },
    { value: 'MY', name: 'Malaysia' },
    { value: 'MV', name: 'Maldives' },
    { value: 'ML', name: 'Mali' },
    { value: 'MT', name: 'Malta' },
    { value: 'IM', name: 'Isle of Man' },
    { value: 'RM', name: 'Marshall Islands' },
    { value: 'MR', name: 'Mauritania' },
    { value: 'MP', name: 'Mauritius' },
    { value: 'MX', name: 'Mexico' },
    { value: 'MQ', name: 'Midway Islands' },
    { value: 'MD', name: 'Moldova' },
    { value: 'MN', name: 'Monaco' },
    { value: 'MG', name: 'Mongolia' },
    { value: 'MJ', name: 'Montenegro' },
    { value: 'MH', name: 'Montserrat' },
    { value: 'MO', name: 'Morocco' },
    { value: 'MZ', name: 'Mozambique' },
    { value: 'WA', name: 'Namibia' },
    { value: 'NR', name: 'Nauru' },
    { value: 'BQ', name: 'Navassa Island' },
    { value: 'NP', name: 'Nepal' },
    { value: 'NL', name: 'Netherlands' },
    { value: 'NC', name: 'New Caledonia' },
    { value: 'NZ', name: 'New Zealand' },
    { value: 'NU', name: 'Nicaragua' },
    { value: 'NG', name: 'Niger' },
    { value: 'NI', name: 'Nigeria' },
    { value: 'NE', name: 'Niue' },
    { value: 'NF', name: 'Norfolk Island' },
    { value: 'CQ', name: 'Northern Mariana Islands' },
    { value: 'NO', name: 'Norway' },
    { value: 'MU', name: 'Oman' },
    { value: 'PK', name: 'Pakistan' },
    { value: 'PS', name: 'Palau' },
    { value: 'LQ', name: 'Palmyra Atoll' },
    { value: 'PM', name: 'Panama' },
    { value: 'PP', name: 'Papua-New Guinea' },
    { value: 'PF', name: 'Paracel Islands' },
    { value: 'PA', name: 'Paraguay' },
    { value: 'PE', name: 'Peru' },
    { value: 'RP', name: 'Philippines' },
    { value: 'PC', name: 'Pitcairn Islands' },
    { value: 'PL', name: 'Poland' },
    { value: 'PO', name: 'Portugal' },
    { value: 'RQ', name: 'Puerto Rico' },
    { value: 'QA', name: 'Qatar' },
    { value: 'RO', name: 'Romania' },
    { value: 'RS', name: 'Russia' },
    { value: 'RW', name: 'Rwanda' },
    { value: 'TB', name: 'St. Barthelemy' },
    { value: 'SH', name: 'St. Helena' },
    { value: 'SC', name: 'St. Kitts and Nevis' },
    { value: 'ST', name: 'St. Lucia Island' },
    { value: 'RN', name: 'St. Martin' },
    { value: 'NN', name: 'Sint Maarten' },
    { value: 'SB', name: 'St. Pierre and Miquelon' },
    { value: 'VC', name: 'St. Vincent and the Grenadines' },
    { value: 'WS', name: 'Samoa' },
    { value: 'SM', name: 'San Marino' },
    { value: 'TP', name: 'Sao Tome and Principe' },
    { value: 'SA', name: 'Saudi Arabia' },
    { value: 'SG', name: 'Senegal' },
    { value: 'RI', name: 'Serbia' },
    { value: 'SE', name: 'Seychelles' },
    { value: 'SL', name: 'Sierra Leone' },
    { value: 'SN', name: 'Singapore' },
    { value: 'LO', name: 'Slovakia' },
    { value: 'SI', name: 'Slovenia' },
    { value: 'BP', name: 'Solomon Islands' },
    { value: 'SO', name: 'Somalia' },
    { value: 'SF', name: 'South Africa' },
    { value: 'SX', name: 'South Georgia and South Sandwich Island' },
    { value: 'OD', name: 'South Sudan' },
    { value: 'SP', name: 'Spain' },
    { value: 'PG', name: 'Spratly Islands' },
    { value: 'CE', name: 'Sri Lanka' },
    { value: 'SU', name: 'Sudan' },
    { value: 'NS', name: 'Suriname' },
    { value: 'SV', name: 'Svalbard' },
    { value: 'WZ', name: 'Swaziland' },
    { value: 'SW', name: 'Sweden' },
    { value: 'SZ', name: 'Switzerland' },
    { value: 'SY', name: 'Syria' },
    { value: 'TW', name: 'Taiwan' },
    { value: 'TI', name: 'Tajikistan' },
    { value: 'TZ', name: 'Tanzania' },
    { value: 'TH', name: 'Thailand' },
    { value: 'TO', name: 'Togo' },
    { value: 'TL', name: 'Tokelau' },
    { value: 'TN', name: 'Tonga' },
    { value: 'TD', name: 'Trinidad and Tobago' },
    { value: 'TS', name: 'Tunisia' },
    { value: 'TU', name: 'Turkey' },
    { value: 'TX', name: 'Turkmenistan' },
    { value: 'TK', name: 'Turks and Caicos Islands' },
    { value: 'TV', name: 'Tuvalu' },
    { value: 'UG', name: 'Uganda' },
    { value: 'UP', name: 'Ukraine' },
    { value: 'AE', name: 'United Arab Emirates' },
    { value: 'UK', name: 'United Kingdom' },
    { value: 'UY', name: 'Uruguay' },
    { value: 'UZ', name: 'Uzbekistan' },
    { value: 'NH', name: 'Vanuatu' },
    { value: 'VT', name: 'Holy See' },
    { value: 'VE', name: 'Venezuela' },
    { value: 'VM', name: 'Vietnam' },
    { value: 'VQ', name: 'Virgin Islands' },
    { value: 'WQ', name: 'Wake Island' },
    { value: 'WF', name: 'Wallis and Futuna' },
    { value: 'WI', name: 'Western Sahara' },
    { value: 'YM', name: 'Yemen (Aden)' },
    { value: 'ZA', name: 'Zambia' },
    { value: 'ZI', name: 'Zimbabwe' },
    { value: 'OC', name: 'Other Country' },
];

const COUNTRIES = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'US', name: 'United States' },
    { value: 'CA', name: 'Canada' },
    { value: 'AL', name: 'Albania' },
    { value: 'AX', name: 'Åland Islands' },
    { value: 'DZ', name: 'Algeria' },
    { value: 'AS', name: 'American Samoa' },
    { value: 'AD', name: 'Andorra' },
    { value: 'AO', name: 'Angola' },
    { value: 'AI', name: 'Anguilla' },
    { value: 'AQ', name: 'Antarctica' },
    { value: 'AG', name: 'Antigua and Barbuda' },
    { value: 'AR', name: 'Argentina' },
    { value: 'AM', name: 'Armenia' },
    { value: 'AW', name: 'Aruba' },
    { value: 'AU', name: 'Australia' },
    { value: 'AT', name: 'Austria' },
    { value: 'AZ', name: 'Azerbaijan' },
    { value: 'BS', name: 'Bahamas (the)' },
    { value: 'BH', name: 'Bahrain' },
    { value: 'BD', name: 'Bangladesh' },
    { value: 'BB', name: 'Barbados' },
    { value: 'BY', name: 'Belarus' },
    { value: 'BE', name: 'Belgium' },
    { value: 'BZ', name: 'Belize' },
    { value: 'BJ', name: 'Benin' },
    { value: 'BM', name: 'Bermuda' },
    { value: 'BT', name: 'Bhutan' },
    { value: 'BO', name: 'Bolivia (Plurinational State of)' },
    { value: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
    { value: 'BA', name: 'Bosnia and Herzegovina' },
    { value: 'BW', name: 'Botswana' },
    { value: 'BV', name: 'Bouvet Island' },
    { value: 'BR', name: 'Brazil' },
    { value: 'IO', name: 'British Indian Ocean Territory (the)' },
    { value: 'BN', name: 'Brunei Darussalam' },
    { value: 'BG', name: 'Bulgaria' },
    { value: 'BF', name: 'Burkina Faso' },
    { value: 'BI', name: 'Burundi' },
    { value: 'CV', name: 'Cabo Verde' },
    { value: 'KH', name: 'Cambodia' },
    { value: 'CM', name: 'Cameroon' },
    { value: 'KY', name: 'Cayman Islands (the)' },
    { value: 'CF', name: 'Central African Republic (the)' },
    { value: 'TD', name: 'Chad' },
    { value: 'CL', name: 'Chile' },
    { value: 'CN', name: 'China' },
    { value: 'CX', name: 'Christmas Island' },
    { value: 'CC', name: 'Cocos (Keeling) Islands (the)' },
    { value: 'CO', name: 'Colombia' },
    { value: 'KM', name: 'Comoros (the)' },
    { value: 'CD', name: 'Congo (the Democratic Republic of the)' },
    { value: 'CG', name: 'Congo (the)' },
    { value: 'CK', name: 'Cook Islands (the)' },
    { value: 'CR', name: 'Costa Rica' },
    { value: 'HR', name: 'Croatia' },
    { value: 'CU', name: 'Cuba' },
    { value: 'CW', name: 'Curaçao' },
    { value: 'CY', name: 'Cyprus' },
    { value: 'CZ', name: 'Czechia' },
    { value: 'CI', name: 'Côte d\'Ivoire' },
    { value: 'DK', name: 'Denmark' },
    { value: 'DJ', name: 'Djibouti' },
    { value: 'DM', name: 'Dominica' },
    { value: 'DO', name: 'Dominican Republic (the)' },
    { value: 'EC', name: 'Ecuador' },
    { value: 'EG', name: 'Egypt' },
    { value: 'SV', name: 'El Salvador' },
    { value: 'GQ', name: 'Equatorial Guinea' },
    { value: 'ER', name: 'Eritrea' },
    { value: 'EE', name: 'Estonia' },
    { value: 'SZ', name: 'Eswatini' },
    { value: 'ET', name: 'Ethiopia' },
    { value: 'FK', name: 'Falkland Islands (the) [Malvinas]' },
    { value: 'FO', name: 'Faroe Islands (the)' },
    { value: 'FJ', name: 'Fiji' },
    { value: 'FI', name: 'Finland' },
    { value: 'FR', name: 'France' },
    { value: 'GF', name: 'French Guiana' },
    { value: 'PF', name: 'French Polynesia' },
    { value: 'TF', name: 'French Southern Territories (the)' },
    { value: 'GA', name: 'Gabon' },
    { value: 'GM', name: 'Gambia (the)' },
    { value: 'GE', name: 'Georgia' },
    { value: 'DE', name: 'Germany' },
    { value: 'GH', name: 'Ghana' },
    { value: 'GI', name: 'Gibraltar' },
    { value: 'GR', name: 'Greece' },
    { value: 'GL', name: 'Greenland' },
    { value: 'GD', name: 'Grenada' },
    { value: 'GP', name: 'Guadeloupe' },
    { value: 'GU', name: 'Guam' },
    { value: 'GT', name: 'Guatemala' },
    { value: 'GG', name: 'Guernsey' },
    { value: 'GN', name: 'Guinea' },
    { value: 'GW', name: 'Guinea-Bissau' },
    { value: 'GY', name: 'Guyana' },
    { value: 'HT', name: 'Haiti' },
    { value: 'HM', name: 'Heard Island and McDonald Islands' },
    { value: 'VA', name: 'Holy See (the)' },
    { value: 'HN', name: 'Honduras' },
    { value: 'HK', name: 'Hong Kong' },
    { value: 'HU', name: 'Hungary' },
    { value: 'IS', name: 'Iceland' },
    { value: 'IN', name: 'India' },
    { value: 'ID', name: 'Indonesia' },
    { value: 'IR', name: 'Iran (Islamic Republic of)' },
    { value: 'IQ', name: 'Iraq' },
    { value: 'IE', name: 'Ireland' },
    { value: 'IM', name: 'Isle of Man' },
    { value: 'IL', name: 'Israel' },
    { value: 'IT', name: 'Italy' },
    { value: 'JM', name: 'Jamaica' },
    { value: 'JP', name: 'Japan' },
    { value: 'JE', name: 'Jersey' },
    { value: 'JO', name: 'Jordan' },
    { value: 'KZ', name: 'Kazakhstan' },
    { value: 'KE', name: 'Kenya' },
    { value: 'KI', name: 'Kiribati' },
    { value: 'KP', name: 'Korea (the Democratic People\'s Republic of)' },
    { value: 'KR', name: 'Korea (the Republic of)' },
    { value: 'KW', name: 'Kuwait' },
    { value: 'KG', name: 'Kyrgyzstan' },
    { value: 'LA', name: 'Lao People\'s Democratic Republic (the)' },
    { value: 'LV', name: 'Latvia' },
    { value: 'LB', name: 'Lebanon' },
    { value: 'LS', name: 'Lesotho' },
    { value: 'LR', name: 'Liberia' },
    { value: 'LY', name: 'Libya' },
    { value: 'LI', name: 'Liechtenstein' },
    { value: 'LT', name: 'Lithuania' },
    { value: 'LU', name: 'Luxembourg' },
    { value: 'MO', name: 'Macao' },
    { value: 'MG', name: 'Madagascar' },
    { value: 'MW', name: 'Malawi' },
    { value: 'MY', name: 'Malaysia' },
    { value: 'MV', name: 'Maldives' },
    { value: 'ML', name: 'Mali' },
    { value: 'MT', name: 'Malta' },
    { value: 'MH', name: 'Marshall Islands (the)' },
    { value: 'MQ', name: 'Martinique' },
    { value: 'MR', name: 'Mauritania' },
    { value: 'MU', name: 'Mauritius' },
    { value: 'YT', name: 'Mayotte' },
    { value: 'MX', name: 'Mexico' },
    { value: 'FM', name: 'Micronesia (Federated States of)' },
    { value: 'MD', name: 'Moldova (the Republic of)' },
    { value: 'MC', name: 'Monaco' },
    { value: 'MN', name: 'Mongolia' },
    { value: 'ME', name: 'Montenegro' },
    { value: 'MS', name: 'Montserrat' },
    { value: 'MA', name: 'Morocco' },
    { value: 'MZ', name: 'Mozambique' },
    { value: 'MM', name: 'Myanmar' },
    { value: 'NA', name: 'Namibia' },
    { value: 'NR', name: 'Nauru' },
    { value: 'NP', name: 'Nepal' },
    { value: 'NL', name: 'Netherlands (the)' },
    { value: 'NC', name: 'New Caledonia' },
    { value: 'NZ', name: 'New Zealand' },
    { value: 'NI', name: 'Nicaragua' },
    { value: 'NE', name: 'Niger (the)' },
    { value: 'NG', name: 'Nigeria' },
    { value: 'NU', name: 'Niue' },
    { value: 'NF', name: 'Norfolk Island' },
    { value: 'MP', name: 'Northern Mariana Islands (the)' },
    { value: 'NO', name: 'Norway' },
    { value: 'OM', name: 'Oman' },
    { value: 'PK', name: 'Pakistan' },
    { value: 'PW', name: 'Palau' },
    { value: 'PS', name: 'Palestine, State of' },
    { value: 'PA', name: 'Panama' },
    { value: 'PG', name: 'Papua New Guinea' },
    { value: 'PY', name: 'Paraguay' },
    { value: 'PE', name: 'Peru' },
    { value: 'PH', name: 'Philippines (the)' },
    { value: 'PN', name: 'Pitcairn' },
    { value: 'PL', name: 'Poland' },
    { value: 'PT', name: 'Portugal' },
    { value: 'PR', name: 'Puerto Rico' },
    { value: 'QA', name: 'Qatar' },
    { value: 'MK', name: 'Republic of North Macedonia' },
    { value: 'RO', name: 'Romania' },
    { value: 'RU', name: 'Russian Federation (the)' },
    { value: 'RW', name: 'Rwanda' },
    { value: 'RE', name: 'Réunion' },
    { value: 'BL', name: 'Saint Barthélemy' },
    { value: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
    { value: 'KN', name: 'Saint Kitts and Nevis' },
    { value: 'LC', name: 'Saint Lucia' },
    { value: 'MF', name: 'Saint Martin (French part)' },
    { value: 'PM', name: 'Saint Pierre and Miquelon' },
    { value: 'VC', name: 'Saint Vincent and the Grenadines' },
    { value: 'WS', name: 'Samoa' },
    { value: 'SM', name: 'San Marino' },
    { value: 'ST', name: 'Sao Tome and Principe' },
    { value: 'SA', name: 'Saudi Arabia' },
    { value: 'SN', name: 'Senegal' },
    { value: 'RS', name: 'Serbia' },
    { value: 'SC', name: 'Seychelles' },
    { value: 'SL', name: 'Sierra Leone' },
    { value: 'SG', name: 'Singapore' },
    { value: 'SX', name: 'Sint Maarten (Dutch part)' },
    { value: 'SK', name: 'Slovakia' },
    { value: 'SI', name: 'Slovenia' },
    { value: 'SB', name: 'Solomon Islands' },
    { value: 'SO', name: 'Somalia' },
    { value: 'ZA', name: 'South Africa' },
    { value: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', name: 'South Sudan' },
    { value: 'ES', name: 'Spain' },
    { value: 'LK', name: 'Sri Lanka' },
    { value: 'SD', name: 'Sudan (the)' },
    { value: 'SR', name: 'Suriname' },
    { value: 'SJ', name: 'Svalbard and Jan Mayen' },
    { value: 'SE', name: 'Sweden' },
    { value: 'CH', name: 'Switzerland' },
    { value: 'SY', name: 'Syrian Arab Republic' },
    { value: 'TW', name: 'Taiwan (Province of China)' },
    { value: 'TJ', name: 'Tajikistan' },
    { value: 'TZ', name: 'Tanzania, United Republic of' },
    { value: 'TH', name: 'Thailand' },
    { value: 'TL', name: 'Timor-Leste' },
    { value: 'TG', name: 'Togo' },
    { value: 'TK', name: 'Tokelau' },
    { value: 'TO', name: 'Tonga' },
    { value: 'TT', name: 'Trinidad and Tobago' },
    { value: 'TN', name: 'Tunisia' },
    { value: 'TR', name: 'Turkey' },
    { value: 'TM', name: 'Turkmenistan' },
    { value: 'TC', name: 'Turks and Caicos Islands (the)' },
    { value: 'TV', name: 'Tuvalu' },
    { value: 'UG', name: 'Uganda' },
    { value: 'UA', name: 'Ukraine' },
    { value: 'AE', name: 'United Arab Emirates (the)' },
    { value: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland (the)' },
    { value: 'UM', name: 'United States Minor Outlying Islands (the)' },
    { value: 'UY', name: 'Uruguay' },
    { value: 'UZ', name: 'Uzbekistan' },
    { value: 'VU', name: 'Vanuatu' },
    { value: 'VE', name: 'Venezuela (Bolivarian Republic of)' },
    { value: 'VN', name: 'Viet Nam' },
    { value: 'VG', name: 'Virgin Islands (British)' },
    { value: 'VI', name: 'Virgin Islands (U.S.)' },
    { value: 'WF', name: 'Wallis and Futuna' },
    { value: 'EH', name: 'Western Sahara' },
    { value: 'YE', name: 'Yemen' },
    { value: 'ZM', name: 'Zambia' },
    { value: 'ZW', name: 'Zimbabwe' },
];

const STATES = [
    { value: 'AL', name: 'Alabama' },
    { value: 'AK', name: 'Alaska' },
    { value: 'AZ', name: 'Arizona' },
    { value: 'AR', name: 'Arkansas' },
    { value: 'AA', name: 'Overseas Military - AA' },
    { value: 'AE', name: 'Overseas Military - AE' },
    { value: 'AP', name: 'Overseas Military - AP' },
    { value: 'AS', name: 'American Samoa' },
    { value: 'CA', name: 'California' },
    { value: 'CO', name: 'Colorado' },
    { value: 'CT', name: 'Connecticut' },
    { value: 'DE', name: 'Delaware' },
    { value: 'DC', name: 'District of Columbia' },
    { value: 'FM', name: 'Fed. States of Micronesia' },
    { value: 'FL', name: 'Florida' },
    { value: 'GA', name: 'Georgia' },
    { value: 'GU', name: 'Guam' },
    { value: 'HI', name: 'Hawaii' },
    { value: 'ID', name: 'Idaho' },
    { value: 'IL', name: 'Illinois' },
    { value: 'IN', name: 'Indiana' },
    { value: 'IA', name: 'Iowa' },
    { value: 'KS', name: 'Kansas' },
    { value: 'KY', name: 'Kentucky' },
    { value: 'LA', name: 'Louisiana' },
    { value: 'ME', name: 'Maine' },
    { value: 'MH', name: 'Marshall Islands' },
    { value: 'MD', name: 'Maryland' },
    { value: 'MA', name: 'Massachusetts' },
    { value: 'MI', name: 'Michigan' },
    { value: 'MN', name: 'Minnesota' },
    { value: 'MS', name: 'Mississippi' },
    { value: 'MO', name: 'Missouri' },
    { value: 'MP', name: 'Commonwealth of Northern Mariana Islands' },
    { value: 'MT', name: 'Montana' },
    { value: 'NE', name: 'Nebraska' },
    { value: 'NV', name: 'Nevada' },
    { value: 'NH', name: 'New Hampshire' },
    { value: 'NJ', name: 'New Jersey' },
    { value: 'NM', name: 'New Mexico' },
    { value: 'NY', name: 'New York' },
    { value: 'NC', name: 'North Carolina' },
    { value: 'ND', name: 'North Dakota' },
    { value: 'OH', name: 'Ohio' },
    { value: 'OK', name: 'Oklahoma' },
    { value: 'OR', name: 'Oregon' },
    { value: 'PW', name: 'Palau' },
    { value: 'PA', name: 'Pennsylvania' },
    { value: 'PR', name: 'Puerto Rico' },
    { value: 'RI', name: 'Rhode Island' },
    { value: 'SC', name: 'South Carolina' },
    { value: 'SD', name: 'South Dakota' },
    { value: 'TN', name: 'Tennessee' },
    { value: 'TX', name: 'Texas' },
    { value: 'UT', name: 'Utah' },
    { value: 'VT', name: 'Vermont' },
    { value: 'VA', name: 'Virginia' },
    { value: 'VI', name: 'Virgin Islands' },
    { value: 'WA', name: 'Washington' },
    { value: 'WV', name: 'West Virginia' },
    { value: 'WI', name: 'Wisconsin' },
    { value: 'WY', name: 'Wyoming' },
];

// AxcessTax has different dropdown/selections for States selections
const STATES_CODE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'AL', name: 'Alabama' },
    { value: 'AK', name: 'Alaska' },
    { value: 'AZ', name: 'Arizona' },
    { value: 'AR', name: 'Arkansas' },
    { value: 'AA', name: 'Overseas Military - AA' },
    { value: 'AE', name: 'Overseas Military - AE' },
    { value: 'AP', name: 'Overseas Military - AP' },
    { value: 'AS', name: 'American Samoa' },
    { value: 'CA', name: 'California' },
    { value: 'CO', name: 'Colorado' },
    { value: 'CT', name: 'Connecticut' },
    { value: 'DE', name: 'Delaware' },
    { value: 'DC', name: 'District of Columbia' },
    { value: 'FM', name: 'Fed. States of Micronesia' },
    { value: 'FL', name: 'Florida' },
    { value: 'GA', name: 'Georgia' },
    { value: 'GU', name: 'Guam' },
    { value: 'HI', name: 'Hawaii' },
    { value: 'ID', name: 'Idaho' },
    { value: 'IL', name: 'Illinois' },
    { value: 'IN', name: 'Indiana' },
    { value: 'IA', name: 'Iowa' },
    { value: 'KS', name: 'Kansas' },
    { value: 'KY', name: 'Kentucky' },
    { value: 'LA', name: 'Louisiana' },
    { value: 'ME', name: 'Maine' },
    { value: 'MH', name: 'Marshall Islands' },
    { value: 'MD', name: 'Maryland' },
    { value: 'MA', name: 'Massachusetts' },
    { value: 'MI', name: 'Michigan' },
    { value: 'MN', name: 'Minnesota' },
    { value: 'MS', name: 'Mississippi' },
    { value: 'MO', name: 'Missouri' },
    { value: 'MP', name: 'Commonwealth of Northern Mariana Islands' },
    { value: 'MT', name: 'Montana' },
    { value: 'NE', name: 'Nebraska' },
    { value: 'NV', name: 'Nevada' },
    { value: 'NH', name: 'New Hampshire' },
    { value: 'NJ', name: 'New Jersey' },
    { value: 'NM', name: 'New Mexico' },
    { value: 'NY', name: 'New York' },
    { value: 'NC', name: 'North Carolina' },
    { value: 'ND', name: 'North Dakota' },
    { value: 'OH', name: 'Ohio' },
    { value: 'OK', name: 'Oklahoma' },
    { value: 'OR', name: 'Oregon' },
    { value: 'PW', name: 'Palau' },
    { value: 'PA', name: 'Pennsylvania' },
    { value: 'PR', name: 'Puerto Rico' },
    { value: 'RI', name: 'Rhode Island' },
    { value: 'SC', name: 'South Carolina' },
    { value: 'SD', name: 'South Dakota' },
    { value: 'TN', name: 'Tennessee' },
    { value: 'TX', name: 'Texas' },
    { value: 'UT', name: 'Utah' },
    { value: 'VT', name: 'Vermont' },
    { value: 'VA', name: 'Virginia' },
    { value: 'VI', name: 'Virgin Islands' },
    { value: 'WA', name: 'Washington' },
    { value: 'WV', name: 'West Virginia' },
    { value: 'WI', name: 'Wisconsin' },
    { value: 'WY', name: 'Wyoming' },
];

// AxcessTax has different dropdown/selections for States selections
// DCU, NHL, and TNL are omitted selections for now, since they have issues on Push
const STATES_TAX_PAYMENT_CODES = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'AL', name: 'Alabama' },
    { value: 'AK', name: 'Alaska' },
    { value: 'AZ', name: 'Arizona' },
    { value: 'AR', name: 'Arkansas' },
    { value: 'AA', name: 'Overseas Military - AA' },
    { value: 'AE', name: 'Overseas Military - AE' },
    { value: 'AP', name: 'Overseas Military - AP' },
    { value: 'AS', name: 'American Samoa' },
    { value: 'CA', name: 'California' },
    { value: 'CZ', name: 'Canal Zone' },
    { value: 'CO', name: 'Colorado' },
    { value: 'CT', name: 'Connecticut' },
    { value: 'DE', name: 'Delaware' },
    { value: 'DC', name: 'District of Columbia' },
    // { value: 'DCU', name: 'District of Columbia - D30' },
    { value: 'FL', name: 'Florida' },
    { value: 'GA', name: 'Georgia' },
    { value: 'GU', name: 'Guam' },
    { value: 'HI', name: 'Hawaii' },
    { value: 'ID', name: 'Idaho' },
    { value: 'IL', name: 'Illinois' },
    { value: 'IN', name: 'Indiana' },
    { value: 'IA', name: 'Iowa' },
    { value: 'KS', name: 'Kansas' },
    { value: 'KY', name: 'Kentucky' },
    { value: 'LA', name: 'Louisiana' },
    { value: 'ME', name: 'Maine' },
    { value: 'MD', name: 'Maryland' },
    { value: 'MA', name: 'Massachusetts' },
    { value: 'MI', name: 'Michigan' },
    { value: 'MBT', name: 'Michigan Business Tax Return' },
    { value: 'MN', name: 'Minnesota' },
    { value: 'MS', name: 'Mississippi' },
    { value: 'MO', name: 'Missouri' },
    { value: 'MP', name: 'Commonwealth of Northern Mariana Islands' },
    { value: 'MT', name: 'Montana' },
    { value: 'NE', name: 'Nebraska' },
    { value: 'NV', name: 'Nevada' },
    { value: 'NHD', name: 'New Hampshire' },
    // { value: 'NHL', name: 'New Hampshire - SMLLC' },
    { value: 'NJ', name: 'New Jersey' },
    { value: 'NM', name: 'New Mexico' },
    { value: 'NY', name: 'New York' },
    { value: 'NC', name: 'North Carolina' },
    { value: 'ND', name: 'North Dakota' },
    { value: 'OH', name: 'Ohio' },
    { value: 'OK', name: 'Oklahoma' },
    { value: 'ON', name: 'Ontario' },
    { value: 'OR', name: 'Oregon' },
    { value: 'OT', name: 'Other Territories' },
    { value: 'PA', name: 'Pennsylvania' },
    { value: 'PR', name: 'Puerto Rico' },
    { value: 'RI', name: 'Rhode Island' },
    { value: 'SC', name: 'South Carolina' },
    { value: 'SD', name: 'South Dakota' },
    { value: 'TN', name: 'Tennessee' },
    // { value: 'TNL', name: 'Tennessee - LLC' },
    { value: 'TX', name: 'Texas' },
    { value: 'UT', name: 'Utah' },
    { value: 'VT', name: 'Vermont' },
    { value: 'VA', name: 'Virginia' },
    { value: 'VI', name: 'Virgin Islands' },
    { value: 'WA', name: 'Washington' },
    { value: 'WV', name: 'West Virginia' },
    { value: 'WI', name: 'Wisconsin' },
    { value: 'WY', name: 'Wyoming' },
    { value: 'FC', name: 'Foreign Countries' },
    { value: 'XX', name: 'Various' },
];

const ACCOUNT_TYPE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: AXCESS_KEYS.AXCESS_KEY_CHECKING, name: STRINGS.ACCOUNT_TYPE_CHECKING },
    { value: AXCESS_KEYS.AXCESS_KEY_SAVINGS, name: STRINGS.ACCOUNT_TYPE_SAVINGS },
];

const PROVINCES = [
    { value: AXCESS_KEYS.VALUE_AB, name: STRINGS.NAME_AB, alias: AXCESS_KEYS.INITIAL_AB },
    { value: AXCESS_KEYS.VALUE_BC, name: STRINGS.NAME_BC, alias: AXCESS_KEYS.INITIAL_BC },
    { value: AXCESS_KEYS.VALUE_MB, name: STRINGS.NAME_MB, alias: AXCESS_KEYS.INITIAL_MB },
    { value: AXCESS_KEYS.VALUE_NB, name: STRINGS.NAME_NB, alias: AXCESS_KEYS.INITIAL_NB },
    { value: AXCESS_KEYS.VALUE_NL, name: STRINGS.NAME_NL, alias: AXCESS_KEYS.INITIAL_NL },
    { value: AXCESS_KEYS.VALUE_NS, name: STRINGS.NAME_NS, alias: AXCESS_KEYS.INITIAL_NS },
    { value: AXCESS_KEYS.VALUE_NT, name: STRINGS.NAME_NT, alias: AXCESS_KEYS.INITIAL_NT },
    { value: AXCESS_KEYS.VALUE_NU, name: STRINGS.NAME_NU, alias: AXCESS_KEYS.INITIAL_NU },
    { value: AXCESS_KEYS.VALUE_ON, name: STRINGS.NAME_ON, alias: AXCESS_KEYS.INITIAL_ON },
    { value: AXCESS_KEYS.VALUE_PE, name: STRINGS.NAME_PE, alias: AXCESS_KEYS.INITIAL_PE },
    { value: AXCESS_KEYS.VALUE_QC, name: STRINGS.NAME_QC, alias: AXCESS_KEYS.INITIAL_QC },
    { value: AXCESS_KEYS.VALUE_SK, name: STRINGS.NAME_SK, alias: AXCESS_KEYS.INITIAL_SK },
    { value: AXCESS_KEYS.VALUE_YT, name: STRINGS.NAME_YT, alias: AXCESS_KEYS.INITIAL_YT },
];

const PROVINCES_TRUNC = [
    { value: AXCESS_KEYS.VALUE_AB, name: STRINGS.NAME_AB, alias: AXCESS_KEYS.INITIAL_AB },
    { value: AXCESS_KEYS.VALUE_BC, name: STRINGS.NAME_BC, alias: AXCESS_KEYS.INITIAL_BC },
    { value: AXCESS_KEYS.VALUE_MB, name: STRINGS.NAME_MB, alias: AXCESS_KEYS.INITIAL_MB },
    { value: AXCESS_KEYS.VALUE_NB, name: STRINGS.NAME_NB, alias: AXCESS_KEYS.INITIAL_NB },
    { value: AXCESS_KEYS.VALUE_NL_TRUNC, name: STRINGS.NAME_NL, alias: AXCESS_KEYS.INITIAL_NL },
    { value: AXCESS_KEYS.VALUE_NS, name: STRINGS.NAME_NS, alias: AXCESS_KEYS.INITIAL_NS },
    { value: AXCESS_KEYS.VALUE_NT_TRUNC, name: STRINGS.NAME_NT, alias: AXCESS_KEYS.INITIAL_NT },
    { value: AXCESS_KEYS.VALUE_NU, name: STRINGS.NAME_NU, alias: AXCESS_KEYS.INITIAL_NU },
    { value: AXCESS_KEYS.VALUE_ON, name: STRINGS.NAME_ON, alias: AXCESS_KEYS.INITIAL_ON },
    { value: AXCESS_KEYS.VALUE_PE_TRUNC, name: STRINGS.NAME_PE, alias: AXCESS_KEYS.INITIAL_PE },
    { value: AXCESS_KEYS.VALUE_QC, name: STRINGS.NAME_QC, alias: AXCESS_KEYS.INITIAL_QC },
    { value: AXCESS_KEYS.VALUE_SK, name: STRINGS.NAME_SK, alias: AXCESS_KEYS.INITIAL_SK },
    { value: AXCESS_KEYS.VALUE_YT, name: STRINGS.NAME_YT, alias: AXCESS_KEYS.INITIAL_YT },
];

// Canadian Provinces as codes
const PROVINCES_CODE = [
    { value: AXCESS_KEYS.INITIAL_AB, name: STRINGS.NAME_AB },
    { value: AXCESS_KEYS.INITIAL_BC, name: STRINGS.NAME_BC },
    { value: AXCESS_KEYS.INITIAL_MB, name: STRINGS.NAME_MB },
    { value: AXCESS_KEYS.INITIAL_NB, name: STRINGS.NAME_NB },
    { value: AXCESS_KEYS.INITIAL_NL, name: STRINGS.NAME_NL },
    { value: AXCESS_KEYS.INITIAL_NS, name: STRINGS.NAME_NS },
    { value: AXCESS_KEYS.INITIAL_NT, name: STRINGS.NAME_NT },
    { value: AXCESS_KEYS.INITIAL_NU, name: STRINGS.NAME_NU },
    { value: AXCESS_KEYS.INITIAL_ON, name: STRINGS.NAME_ON },
    { value: AXCESS_KEYS.INITIAL_PE, name: STRINGS.NAME_PE },
    { value: AXCESS_KEYS.INITIAL_QC, name: STRINGS.NAME_QC },
    { value: AXCESS_KEYS.INITIAL_SK, name: STRINGS.NAME_SK },
    { value: AXCESS_KEYS.INITIAL_YT, name: STRINGS.NAME_YT },
];

const ENERGY_IMPROVEMENTS = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Air Source Heat Pump', name: 'Air Source Heat Pump' },
    { value: 'Central Air Conditioning', name: 'Central Air Conditioning' },
    { value: 'Gas, propane, or oil furnaces/Fans', name: 'Gas, propane, or oil furnaces/Fans' },
    { value: 'Water heaters (non-solar)', name: 'Water heaters (non-solar)' },
    { value: 'Advanced main air circulating fan', name: 'Advanced main air circulating fan' },
    { value: 'Biomass stoves', name: 'Biomass stoves' },
    { value: 'Other', name: 'Other' },
];

const ENERGY_RENEWABLE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Geothermal heat pump', name: 'Geothermal heat pump' },
    { value: 'Residential small wind turbine', name: 'Residential small wind turbine' },
    { value: 'Solar Water Heater', name: 'Solar Water Heater' },
    { value: 'Solar Panels', name: 'Solar Panels' },
    { value: 'Residential fuel cell and microturbine system', name: 'Residential fuel cell and microturbine system' },
];

const TUITION_PAYER =[
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'T', name: 'Taxpayer' },
    { value: 'S', name: 'Spouse' },
    { value: 'D', name: 'Dependent' },
];

const ENERGY_ALTERNATIVES = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Alternative Motor', name: 'Alternative Motor' },
    { value: 'Plug-in Electric', name: 'Plug-in Electric' },
];

const TYPE_OF_USE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Personal', name: 'Personal' },
    { value: 'Business', name: 'Business' },
    { value: 'Both', name: 'Both' },
];

const TIMEZONES = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Hawaii', name: '(UTC-10:00) Hawaii' },
    { value: 'Alaska', name: '(UTC-09:00) Alaska' },
    { value: 'Pacific', name: '(UTC-08:00) Pacific' },
    { value: 'Mountain Standard', name: '(UTC-07:00) Mountain Standard' },
    { value: 'Mountain', name: '(UTC-07:00) Mountain' },
    { value: 'Central Standard', name: '(UTC-06:00) Central Standard' },
    { value: 'Central', name: '(UTC-06:00) Central' },
    { value: 'Eastern', name: '(UTC-05:00) Eastern' },
    { value: 'Atlantic', name: '(UTC-04:00) Atlantic' },
    { value: 'Newfoundland', name: '(UTC-03:30) Newfoundland' },
    { value: 'UTC', name: '(UTC-00:00) UTC' },
];

const PROPERTY_TYPE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Rental', name: 'Rental' },
    { value: 'Royalty', name: 'Royalty'}
];

const BUSINESS_INCOME_FORM_TYPE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: '1099K', name: '1099K' },
    { value: '1099-Misc', name: '1099-Misc' },
    { value: 'Other', name: 'Other' },
];

const YESNO_DONTKNOW = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Yes', name: 'Yes' },
    { value: 'No', name: 'No' },
    { value: 'dontKnow', name: 'I don\'t know' },
];

const CONFIRMED_REQUIRESCHANGE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Confirmed', name: 'Confirmed' },
    { value: 'Requires change', name: 'Requires change' },
]

const RELATIONSHIP = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Son', name: 'Son' },
    { value: 'Daughter', name: 'Daughter' },
    { value: 'Stepchild', name: 'Stepchild' },
    { value: 'Foster Child', name: 'Foster Child' },
    { value: 'Brother', name: 'Brother' },
    { value: 'Sister', name: 'Sister' },
    { value: 'Stepbrother', name: 'Stepbrother' },
    { value: 'Stepsister', name: 'Stepsister' },
    { value: 'Half Brother', name: 'Half Brother' },
    { value: 'Half Sister', name: 'Half Sister' },
    { value: 'Grandchild', name: 'Grandchild' },
    { value: 'Niece', name: 'Niece' },
    { value: 'Nephew', name: 'Nephew' },
    { value: 'Parent', name: 'Parent' },
    { value: 'Grandparent', name: 'Grandparent' },
    { value: 'Aunt', name: 'Aunt' },
    { value: 'Uncle', name: 'Uncle' },
    { value: 'Other', name: 'Other' },
    { value: 'None', name: 'None' },
];

const FOREIGN_SEPERATE_HOME_RELATIONSHIP = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Child (default)', name: 'Child' },
    { value: 'Fosterchild', name: 'Fosterchild' },
    { value: 'Grandchild', name: 'Grandchild' },
    { value: 'Grandparent', name: 'Grandparent' },
    { value: 'Parent', name: 'Parent' },
    { value: 'Brother', name: 'Brother' },
    { value: 'Sister', name: 'Sister' },
    { value: 'Aunt', name: 'Aunt' },
    { value: 'Uncle', name: 'Uncle' },
    { value: 'Nephew', name: 'Nephew' },
    { value: 'Niece', name: 'Niece' },
    { value: 'Son', name: 'Son' },
    { value: 'Daughter', name: 'Daughter' },
    { value: 'Spouse', name: 'Spouse' },
    { value: 'None', name: 'None' },
    { value: 'Other', name: 'Other' },
];

// Numerical values will line up with values from AxcessTax
const TAX_TYPE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_NAME, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Other taxes', name: 'Other taxes' },
    { value: '2', name: 'Real estate taxes' },
    { value: '3', name: 'Personal property taxes' },
    { value: '4', name: 'Foreign taxes' },
    { value: '6', name: 'Local taxes' },
];
    
const QUARTER = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: '1st Quarter', name: '1st Quarter (Q1)' },
    { value: '2nd Quarter', name: '2nd Quarter (Q2)' },
    { value: '3rd Quarter', name: '3rd Quarter (Q3)' },
    { value: '4th Quarter', name: '4th Quarter (Q4)' },
];

const ELECTRONIC_WITHDRAWAL = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Return Only', name: 'Return Only' },
    { value: 'Estimated Payments Only', name: 'Estimated Payments Only' },
    { value: 'Both', name: 'Both' },
];

const SUFFIX = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'JR', name: 'JR' },
    { value: 'SR', name: 'SR' },
    { value: 'MD', name: 'MD' },
    { value: 'PHD', name: 'PHD' },
    { value: 'CPA', name: 'CPA' },
    { value: 'ESQ', name: 'ESQ' },
    { value: 'I', name: 'I' },
    { value: 'II', name: 'II' },
    { value: 'III', name: 'III' },
    { value: 'IV', name: 'IV' },
    { value: 'V', name: 'V' },
    { value: 'VI', name: 'VI' },
    { value: 'VII', name: 'VII' },
    { value: 'VIII', name: 'VIII' },
    { value: 'IX', name: 'IX' },
    { value: 'X', name: 'X' },
    { value: 'XI', name: 'XI' },
    { value: 'XII', name: 'XII' },
    { value: 'XIII', name: 'XIII' },
    { value: 'XIV', name: 'XIV' },
    { value: 'XV', name: 'XV' },
];

const AUTHORIZED_TRANSACTIONS = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Financial account', name: 'Financial account' },
    { value: 'Directly-held investment or mutual fund', name: 'Directly-held investment or mutual fund' },
    { value: 'Entity', name: 'Entity' },
    { value: 'Pension', name: 'Pension' },
    { value: 'Life insurance policy', name: 'Life insurance policy' },
    { value: 'Trust', name: 'Trust' },
];

const FOREIGN_ACCOUNT_TYPE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: AXCESS_KEYS.FOREIGN_ACC_TYPE_BANK, name: STRINGS.FOREIGN_ACC_TYPE_BANK },
    { value: AXCESS_KEYS.FOREIGN_ACC_TYPE_SECURITIES, name: STRINGS.FOREIGN_ACC_TYPE_SECURITIES },
    { value: AXCESS_KEYS.FOREIGN_ACC_TYPE_OTHER, name: STRINGS.FOREIGN_ACC_TYPE_OTHER },
];

const FOREIGN_CURRENCIES = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'AFA', name: 'Afghanistan, Afghani', symbol: '؋' }, //AFN
    { value: 'ALL', name: 'Albania, Lek', symbol: 'L' },
    { value: 'DZD', name: 'Algeria, Dinar', symbol: 'د.ج' },
    { value: 'ADP', name: 'Andorra, Peseta', symbol: 'ADP' },
    { value: 'AOK', name: 'Angola, Kwanza', symbol: 'Kz' }, //AOA
    { value: 'ARS', name: 'Argentina, Peso', symbol: '$' },
    { value: 'AMD', name: 'Armenia, Dram', symbol: '֏' },
    { value: 'AWG', name: 'Aruba, Florin', symbol: 'ƒ' },
    { value: 'AUD', name: 'Australia, Dollar', symbol: '$' },
    { value: 'ATS', name: 'Austria, Schilling', symbol: 'öS' }, //obsolete??
    { value: 'AZM', name: 'Azerbaijan, Manat', symbol: '₼' }, //AZN
    { value: 'BSD', name: 'Bahamas, Dollar', symbol: '$' },
    { value: 'BHD', name: 'Bahrain, Dinar', symbol: 'ب.د' },
    { value: 'BDT', name: 'Bangladesh, Taka', symbol: '৳' },
    { value: 'BBD', name: 'Barbados, Dollar', symbol: '$' },
    { value: 'BYB', name: 'Belarus, Ruble', symbol: 'Br' }, //BYN
    { value: 'BEF', name: 'Belgium, Franc', symbol: '₣' },
    { value: 'BZD', name: 'Belize, Dollar', symbol: '$' },
    { value: 'BMD', name: 'Bermuda, Dollar', symbol: '$' },
    { value: 'BTN', name: 'Bhutan, Ngultrum', symbol: 'Nu' },
    { value: 'BOB', name: 'Bolivia, Boliviano', symbol: 'Bs.' },
    { value: 'BAM', name: 'Bosnia-Herzeg, Mark', symbol: 'КМ' },
    { value: 'BWP', name: 'Botswana, Pula', symbol: 'P' },
    { value: 'BRL', name: 'Brazil, Real', symbol: 'R$' },
    { value: 'BND', name: 'Brunei Daru., Dollar', symbol: '$' },
    { value: 'BGL', name: 'Bulgaria, Lev', symbol: 'лв' }, //BGN
    { value: 'BIF', name: 'Burundi, Franc', symbol: '₣' },
    { value: 'KHR', name: 'Cambodia, Riel', symbol: '៛' },
    { value: 'CAD', name: 'Canada, Dollar', symbol: '$' },
    { value: 'CVE', name: 'Cape Verde, Escudo', symbol: '$' },
    { value: 'KYD', name: 'Cayman Isl, Dollar', symbol: '$' },
    { value: 'CLP', name: 'Chile, Peso', symbol: '$' },
    { value: 'CNY', name: 'China, Yuan', symbol: '¥' },
    { value: 'COP', name: 'Colombia, Peso', symbol: '$' },
    { value: 'XOF', name: 'CFA BCEAO, Franc', symbol: '₣' },
    { value: 'XAF', name: 'CFA BEAC, Franc', symbol: '₣' },
    { value: 'XPF', name: 'CFP, Pacific Franc', symbol: '₣' },
    { value: 'KMF', name: 'Comoros, Franc', symbol: '₣' },
    { value: 'CRC', name: 'Costa Rica, Colon', symbol: '₡' },
    { value: 'HRK', name: 'Croatia, Kuna', symbol: 'Kn' },
    { value: 'CUP', name: 'Cuba, Peso', symbol: '$' },
    { value: 'CYP', name: 'Cyprus, Pound', symbol: '£' },
    { value: 'CZK', name: 'Czech Rep, Koruna', symbol: 'Kč' },
    { value: 'DKK', name: 'Denmark, Krone', symbol: 'kr' },
    { value: 'DJF', name: 'Djibouti, Franc', symbol: '₣' },
    { value: 'DOP', name: 'Dominican Rep, Peso', symbol: '$' },
    { value: 'XCD', name: 'E. Caribbean, Dollar', symbol: '$' },
    { value: 'ECS', name: 'Ecuador, Sucre', symbol: 'S/.' }, //obsolete
    { value: 'EGP', name: 'Egypt, Pound', symbol: '£' },
    { value: 'SVC', name: 'El Salvador, Colon', symbol: '₡' }, //obsolete
    { value: 'ERN', name: 'Eritrea, Nakfa', symbol: 'Nfk' },
    { value: 'EEK', name: 'Estonia, Kroon', symbol: 'kr' },
    { value: 'ETB', name: 'Ethiopia, Birr', symbol: 'Br' },
    { value: 'EUR', name: 'E.U., Euro', symbol: '€' },
    { value: 'FKP', name: 'Falkland Isl., Pound', symbol: '£' },
    { value: 'FJD', name: 'Fiji, Dollar', symbol: '$' },
    { value: 'FIM', name: 'Finland, Markka', symbol: 'mk' }, //obsolete
    { value: 'FRF', name: 'France, Franc', symbol: '₣' },
    { value: 'GMD', name: 'Gambia, Dalasi', symbol: 'D' },
    { value: 'GEL', name: 'Georgia, Lari', symbol: 'ლ' },
    { value: 'DEM', name: 'Germany, Mark', symbol: 'DM' }, //obsolete
    { value: 'GHC', name: 'Ghana, Cedi', symbol: 'GH₵' },
    { value: 'GIP', name: 'Gibraltar, Pound', symbol: '£' },
    { value: 'GRD', name: 'Greece, Drachma', symbol: '₯' }, //obsolete
    { value: 'GTQ', name: 'Guatemala, Quetzal', symbol: 'Q' },
    { value: 'GNF', name: 'Guinea, Franc', symbol: '₣' },
    { value: 'GYD', name: 'Guyana, Dollar', symbol: '$' },
    { value: 'HTG', name: 'Haiti, Gourde', symbol: 'G' },
    { value: 'HNL', name: 'Honduras, Lempira', symbol: 'L' },
    { value: 'HKD', name: 'Hong Kong, Dollar', symbol: '$' },
    { value: 'HUF', name: 'Hungary, Forint', symbol: 'Ft' },
    { value: 'ISK', name: 'Iceland, Krona', symbol: 'Kr' },
    { value: 'INR', name: 'India, Rupee', symbol: '₹' },
    { value: 'IDR', name: 'Indonesia, Rupiah', symbol: 'Rp' },
    { value: 'XDR', name: 'Intl Money Fund, SDR', symbol: 'SDR' }, 
    { value: 'IRR', name: 'Iran, Rial', symbol: '﷼' },
    { value: 'IQD', name: 'Iraq, Dinar', symbol: 'ع.د' },
    { value: 'IEP', name: 'Ireland, Pound', symbol: '£' }, //obsolete
    { value: 'ILS', name: 'Israel, Sheqel', symbol: '₪' },
    { value: 'ITL', name: 'Italy, Lira', symbol: 'L.' },
    { value: 'JMD', name: 'Jamaica, Dollar', symbol: '$' },
    { value: 'JPY', name: 'Japan, Yen', symbol: '¥' },
    { value: 'JOD', name: 'Jordan, Dinar', symbol: 'د.ا' },
    { value: 'KZT', name: 'Kazakhstan, Tenge', symbol: '〒' },
    { value: 'KES', name: 'Kenya, Shilling', symbol: 'Sh' },
    { value: 'KPW', name: 'Korea (North), Won', symbol: '₩' },
    { value: 'KRW', name: 'Korea (South), Won', symbol: '₩' },
    { value: 'KWD', name: 'Kuwait, Dinar', symbol: 'د.ك' },
    { value: 'KGS', name: 'Kyrgyzstan, Som', symbol: 'Лв'},
    { value: 'LAK', name: 'Lao, Kip', symbol: '₭' },
    { value: 'LVL', name: 'Latvia, Lats', symbol: 'Ls' },
    { value: 'LBP', name: 'Lebanon, Pound', symbol: 'ل.ل' },
    { value: 'LSL', name: 'Lesotho, Loti', symbol: '' },
    { value: 'LRD', name: 'Liberia, Dollar', symbol: '$' },
    { value: 'LYD', name: 'Libya, Dinar', symbol: 'L' },
    { value: 'LTL', name: 'Lithuania, Litas', symbol: 'Lt' },
    { value: 'LUF', name: 'Luxembourg, Franc', symbol: 'F' },
    { value: 'MKD', name: 'Macedonia, Dinar', symbol: 'ден' },
    { value: 'MOP', name: 'Macau, Pataca', symbol: 'P' },
    { value: 'MGF', name: 'Madagascar, Franc', symbol: 'MF' }, //{ value: 'MGA', name: 'Madagascar, Ariary', symbol: 'Ar' },
    { value: 'MWK', name: 'Malawi, Kwacha', symbol: 'MK' },
    { value: 'MYR', name: 'Malaysia, Ringgit', symbol: 'RM' },
    { value: 'MVR', name: 'Maldives, Rufiyaa', symbol: 'ރ.' },
    { value: 'MTL', name: 'Malta, Lira', symbol: 'Lm' }, //obsolete
    { value: 'MRO', name: 'Mauritania, Ouguiya', symbol: 'UM' },
    { value: 'MUR', name: 'Mauritius, Rupee', symbol: '₨' },
    { value: 'MXN', name: 'Mexico, Peso', symbol: '$' },
    { value: 'MDL', name: 'Moldova, Leu', symbol: 'L' },
    { value: 'MNT', name: 'Mongolia, Tugrik', symbol: '₮' },
    { value: 'MAD', name: 'Morocco, Dirham', symbol: 'د.م.' },
    { value: 'MZM', name: 'Mozambique, Metical', symbol: 'MT' },
    { value: 'MMK', name: 'Myanmar, Kyat', symbol: 'K' },
    { value: 'NAD', name: 'Namibia, Dollar', symbol: '$' },
    { value: 'NPR', name: 'Nepal, Rupee', symbol: '₨' },
    { value: 'NLG', name: 'Netherlands, Guilder', symbol: 'ƒ' }, //obsolete
    { value: 'ANG', name: 'Net Antilles,Guilder', symbol: 'ƒ' },
    { value: 'NZD', name: 'New Zealand, Dollar', symbol: '$' },
    { value: 'NIO', name: 'Nicaragua, Oro', symbol: 'C$' },
    { value: 'NGN', name: 'Nigeria, Naira', symbol: '₦' },
    { value: 'NOK', name: 'Norway, Krone', symbol: 'kr' },
    { value: 'OMR', name: 'Oman, Rial', symbol: 'ر.ع.' },
    { value: 'PKR', name: 'Pakistan, Rupee', symbol: '₨' },
    { value: 'PAB', name: 'Panama, Balboa', symbol: 'B/.' },
    { value: 'PGK', name: 'Papua New Gui., Kina', symbol: 'K' },
    { value: 'PYG', name: 'Paraguay, Guarani', symbol: '₲' },
    { value: 'PEN', name: 'Peru, Sol', symbol: 'S/.' },
    { value: 'PHP', name: 'Philippines, Peso', symbol: '₱' },
    { value: 'PLN', name: 'Poland, Zloty', symbol: 'zł' },
    { value: 'PTE', name: 'Portugal, Escudo', symbol: '$' },
    { value: 'QAR', name: 'Qatar, Rial', symbol: 'ر.ق' },
    { value: 'ROL', name: 'Romania, Leu', symbol: 'L' }, 
    { value: 'RON', name: 'Romania, New Leu', symbol: 'L' },
    { value: 'RUB', name: 'Russia, Ruble', symbol: 'р.' },
    { value: 'RWF', name: 'Rwanda, Franc', symbol: '₣' },
    { value: 'WST', name: 'Samoa, Tala', symbol: 'T' },
    { value: 'STD', name: 'Sao Tome/Prin, Dobra', symbol: 'Db' },
    { value: 'SAR', name: 'Saudi Arabia, Riyal', symbol: 'ر.س' },
    { value: 'YUM', name: 'Serbia, Yugoslav Dinar', symbol: 'din.' },
    { value: 'SCR', name: 'Seychelles, Rupee', symbol: '₨' },
    { value: 'SLL', name: 'Sierra Leone, Leone', symbol: 'Le' },
    { value: 'SGD', name: 'Singapore, Dollar', symbol: '$' },
    { value: 'SKK', name: 'Slovkia, Koruna', symbol: 'Sk' }, //obsolete
    { value: 'SIT', name: 'Slovenia, Tolar', symbol: 'SIT' }, //obsolete
    { value: 'SBD', name: 'Solomon Isl., Dollar', symbol: '$' },
    { value: 'SOS', name: 'Somalia, Shilling', symbol: 'Sh' },
    { value: 'ZAR', name: 'South Africa, Rand', symbol: 'R' },
    { value: 'SSP', name: 'South Sudan, Pound', symbol: '£' },
    { value: 'ESP', name: 'Spain, Peseta', symbol: '₧' }, //obsolete
    { value: 'LKR', name: 'Sri Lanka, Rupee', symbol: 'Rs' },
    { value: 'SHP', name: 'St. Helena, Pound', symbol: '£' },
    { value: 'SDD', name: 'Sudan, Dinar', symbol: 'LSd' }, //obsolete
    { value: 'SRG', name: 'Suriname, Guilder', symbol: 'ƒ' },
    { value: 'SZL', name: 'Swaziland, Lilangeni', symbol: 'L' },
    { value: 'SEK', name: 'Sweden, Krona', symbol: 'kr' },
    { value: 'CHF', name: 'Switzerland, Franc', symbol: '₣' },
    { value: 'SYP', name: 'Syria, Pound', symbol: 'ل.س' },
    { value: 'TWD', name: 'Taiwan, Dollar', symbol: '$' },
    { value: 'TJR', name: 'Tajikistan, Ruble', symbol: 'TJR' }, //obsolete
    { value: 'TZS', name: 'Tanzania, Shilling', symbol: 'Sh' },
    { value: 'THB', name: 'Thailand, Baht', symbol: '฿' },
    { value: 'TOP', name: 'Tonga, Pa\'anga', symbol: 'T$' },
    { value: 'TTD', name: 'Trinidad & Tob, Dollar', symbol: '$' },
    { value: 'TND', name: 'Tunisia, Dinar', symbol: 'د.ت' },
    { value: 'TRY', name: 'Turkey, New Lira', symbol: '	₤' },
    { value: 'TMM', name: 'Turkmenistan, Manat', symbol: 'm' }, //TMT
    { value: 'AED', name: 'U.A.E., Dirham', symbol: 'د.' },
    { value: 'UGX', name: 'Uganda, Shilling', symbol: 'Sh' },
    { value: 'UAH', name: 'Ukraine, Hryvnia', symbol: '₴' },
    { value: 'GBP', name: 'United Kingdom,Pound', symbol: '	£' },
    { value: 'USD', name: 'U.S.,Dollar', symbol: '$' },
    { value: 'UYU', name: 'Uruguay, Peso', symbol: '$' },
    { value: 'UZS', name: 'Uzbekistan, Sum', symbol: 'лв' },
    { value: 'VUV', name: 'Vanuatu, Vatu', symbol: 'Vt' },
    { value: 'VEB', name: 'Venezuela, Bolivar', symbol: 'Bs.' },
    { value: 'VND', name: 'Vietnam, Dong', symbol: '₫' },
    { value: 'YER', name: 'Yemen, Rial', symbol: '﷼' },
    { value: 'ZMK', name: 'Zambia, Kwacha', symbol: 'ZK' }, //ZMW
    { value: 'ZWD', name: 'Zimbabwe, Dollar', symbol: '$' },
    // { value: '', name: '' },
    // { value: '', name: 'AUD is also used by Christmas Island,' },
    // { value: '', name: 'Cocos/Keeling Islands, Heard ; McDonald' },
    // { value: '', name: 'Islands, Kiribati, Nauru, Norfolk' },
    // { value: '', name: 'Island, and Tuvalu' },
    // { value: '', name: '' },
    // { value: '', name: 'XCD is also used by Anguilla,' },
    // { value: '', name: 'Antigua ; Barbuda, Dominica, Grenada,' },
    // { value: '', name: 'Montserrat, St. Kitts-Nevis, St. Lucia,' },
    // { value: '', name: 'and St. Vincent ; Grenadines' },
    // { value: '', name: '' },
    // { value: '', name: 'XAF if also used by  Cameroon,' },
    // { value: '', name: 'Central African Republic, Chad, Congo,' },
    // { value: '', name: 'Equatorial Guinea, and Gabon' },
    // { value: '', name: '' },
    // { value: '', name: 'XOF is also used by Benin,' },
    // { value: '', name: 'Burkina Faso, Cote D'Ivoire,' },
    // { value: '', name: 'Guinea-Bissau, Mali, Niger, Senegal,' },
    // { value: '', name: 'and Togo' },
    // { value: '', name: '' },
    // { value: '', name: 'XPF is also used by French Polynesia,' },
    // { value: '', name: 'New Caledonia, Wallis ; Futuna Islands' },
    // { value: '', name: '' },
    // { value: '', name: 'DKK is also used by Faeroe Islands' },
    // { value: '', name: 'and Greenland' },
    // { value: '', name: '' },
    // { value: '', name: 'FRF is also used by Andorra,' },
    // { value: '', name: 'French Guiana, French Southern Terr.,' },
    // { value: '', name: 'Guadeloupe, Martinique, Monaco,' },
    // { value: '', name: 'Reunion, and St. Pierre ; Miquelon' },
    // { value: '', name: '' },
    // { value: '', name: 'INR is also used by Bhutan' },
    // { value: '', name: '' },
    // { value: '', name: 'IDR is also used by East Timor' },
    // { value: '', name: '' },
    // { value: '', name: 'ITL is also used by San Marino and' },
    // { value: '', name: 'Vatican City' },
    // { value: '', name: '' },
    // { value: '', name: 'MAD is also used by Western Sahara' },
    // { value: '', name: '' },
    // { value: '', name: 'ANG is also used by Antilles' },
    // { value: '', name: '' },
    // { value: '', name: 'NZD is also used by Cook Islands, Niue,' },
    // { value: '', name: 'Pitcairn Island, and Tokelau' },
    // { value: '', name: '' },
    // { value: '', name: 'NOK is also used by Bouvet Island and' },
    // { value: '', name: 'Svalbard ; Jan Mayen Islands' },
    // { value: '', name: '' },
    // { value: '', name: 'ZAR is also used by Lesotho and Namibia' },
    // { value: '', name: '' },
    // { value: '', name: 'CHF is also used by Liechtenstein' },
    // { value: '', name: '' },
    // { value: '', name: 'GBP is also used by Northern Ireland' },
    // { value: '', name: '' },
    // { value: '', name: 'USD is also used by American Samoa,' },
    // { value: '', name: 'British Indian Ocean Territory, Ecuador,' },
    // { value: '', name: 'Guam, Haiti, Marshall Islands,' },
    // { value: '', name: 'Micronesia, Northern Mariana Islands,' },
    // { value: '', name: 'Palau, Panama, Puerto Rico,' },
    // { value: '', name: 'Turks ; Caicos Islands,' },
    // { value: '', name: 'Virgin Islands (British), and' },
    // { value: '', name: 'Virgin Islands (U.S.)' },
];

const ID_TYPE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: TRIGGER_KEYS.EVENT_KEY_SSN_ITIN, name: 'SSN / ITIN' },
    { value: TRIGGER_KEYS.EVENT_KEY_EIN, name: 'EIN' },
    { value: TRIGGER_KEYS.EVENT_KEY_FOREIGN, name: 'Foreign' },
];

const PROVIDER_DEPENDENT_TYPE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: TRIGGER_KEYS.EVENT_KEY_EIN, name: 'EIN (Business)' },
    { value: TRIGGER_KEYS.EVENT_KEY_SSN, name: 'SSN (Individual)'},
];

const FOREIGN_INSURANCE_TYPE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Non-US Life Insurance Policy', name: 'Non-US Life Insurance Policy' },
    { value: 'Non-US Trust', name: 'Non-US Trust' },
];

const FOREIGN_ENTITY_CLASSIFICATION = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Corporation', name: 'Corporation' },
    { value: 'Disregarded Entity', name: 'Disregarded Entity' },
    { value: 'Partnership', name: 'Partnership' },
];

const FOREIGN_LIVING_QUARTERS = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'Purchased house', name: 'Purchased house' },
    { value: 'Rented house or apartment', name: 'Rented house or apartment' },
    { value: 'Rented room', name: 'Rented room' },
    { value: 'Quarters furnished by employer', name: 'Quarters furnished by employer' },
];

const FOREIGN_ASSETS_COUNTRIES = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'AF', name: 'Afghanistan' },
    { value: 'AX', name: 'Aland Islands' },
    { value: 'AL', name: 'Albania' },
    { value: 'DZ', name: 'Algeria' },
    { value: 'AS', name: 'American Samoa' },
    { value: 'AD', name: 'Andorra' },
    { value: 'AO', name: 'Angola' },
    { value: 'AI', name: 'Anguilla' },
    { value: 'AQ', name: 'Antarctica' },
    { value: 'AG', name: 'Antigua and Barbuda' },
    { value: 'AR', name: 'Argentina' },
    { value: 'AM', name: 'Armenia' },
    { value: 'AW', name: 'Aruba' },
    { value: 'AU', name: 'Australia' },
    { value: 'AT', name: 'Austria' },
    { value: 'AZ', name: 'Azerbaijan' },
    { value: 'BS', name: 'Bahamas' },
    { value: 'BH', name: 'Bahrain' },
    { value: 'BD', name: 'Bangladesh' },
    { value: 'BB', name: 'Barbados' },
    { value: 'BY', name: 'Belarus' },
    { value: 'BE', name: 'Belgium' },
    { value: 'BZ', name: 'Belize' },
    { value: 'BJ', name: 'Benin' },
    { value: 'BM', name: 'Bermuda' },
    { value: 'BT', name: 'Bhutan' },
    { value: 'BO', name: 'Bolivia, Plurinational State of' },
    { value: 'BQ', name: 'Bonaire, Saint Eustatius and Saba' },
    { value: 'BA', name: 'Bosnia and Herzegovina' },
    { value: 'BW', name: 'Botswana' },
    { value: 'BV', name: 'Bouvet Island' },
    { value: 'BR', name: 'Brazil' },
    { value: 'IO', name: 'British Indian Ocean Territory' },
    { value: 'BN', name: 'Brunei Darussalam' },
    { value: 'BG', name: 'Bulgaria' },
    { value: 'BF', name: 'Burkina Faso' },
    { value: 'BI', name: 'Burundi' },
    { value: 'KH', name: 'Cambodia' },
    { value: 'CM', name: 'Cameroon' },
    { value: 'CA', name: 'Canada' },
    { value: 'CV', name: 'Cape Verde' },
    { value: 'KY', name: 'Cayman Islands' },
    { value: 'CF', name: 'Central African Republic' },
    { value: 'TD', name: 'Chad' },
    { value: 'CL', name: 'Chile' },
    { value: 'CN', name: 'China' },
    { value: 'CX', name: 'Christmas Island' },
    { value: 'CC', name: 'Cocos (Keeling) Islands' },
    { value: 'CO', name: 'Colombia' },
    { value: 'KM', name: 'Comoros' },
    { value: 'CG', name: 'Congo' },
    { value: 'CD', name: 'Congo (Democratic Republic)' },
    { value: 'CK', name: 'Cook Islands' },
    { value: 'CR', name: 'Costa Rica' },
    { value: 'CI', name: 'Cote D\'Ivoire' },
    { value: 'HR', name: 'Croatia' },
    { value: 'CU', name: 'Cuba' },
    { value: 'CW', name: 'Curacao' },
    { value: 'CY', name: 'Cyprus' },
    { value: 'CZ', name: 'Czech Republic' },
    { value: 'DK', name: 'Denmark' },
    { value: 'DJ', name: 'Djibouti' },
    { value: 'DM', name: 'Dominica' },
    { value: 'DO', name: 'Dominican Republic' },
    { value: 'EC', name: 'Ecuador' },
    { value: 'EG', name: 'Egypt' },
    { value: 'SV', name: 'El Salvador' },
    { value: 'GQ', name: 'Equatorial Guinea' },
    { value: 'ER', name: 'Eritrea' },
    { value: 'EE', name: 'Estonia' },
    { value: 'ET', name: 'Ethiopia' },
    { value: 'FK', name: 'Falkland Islands (Malvinas)' },
    { value: 'FO', name: 'Faroe Islands' },
    { value: 'FM', name: 'Micronesia, Federated States of' },
    { value: 'FJ', name: 'Fiji' },
    { value: 'FI', name: 'Finland' },
    { value: 'FR', name: 'France' },
    { value: 'GF', name: 'French Guiana' },
    { value: 'PF', name: 'French Polynesia' },
    { value: 'TF', name: 'French Southern Territories' },
    { value: 'GA', name: 'Gabon' },
    { value: 'GM', name: 'Gambia' },
    { value: 'GE', name: 'Georgia' },
    { value: 'DE', name: 'Germany' },
    { value: 'GH', name: 'Ghana' },
    { value: 'GI', name: 'Gibraltar' },
    { value: 'GR', name: 'Greece' },
    { value: 'GL', name: 'Greenland' },
    { value: 'GD', name: 'Grenada' },
    { value: 'GP', name: 'Guadeloupe' },
    { value: 'GU', name: 'Guam' },
    { value: 'GT', name: 'Guatemala' },
    { value: 'GG', name: 'Guernsey' },
    { value: 'GN', name: 'Guinea' },
    { value: 'GW', name: 'Guinea-Bissau' },
    { value: 'GY', name: 'Guyana' },
    { value: 'HT', name: 'Haiti' },
    { value: 'HM', name: 'Heard Island and McDonald Islands' },
    { value: 'VA', name: 'Holy See (Vatican City State)' },
    { value: 'HN', name: 'Honduras' },
    { value: 'HK', name: 'Hong Kong' },
    { value: 'HU', name: 'Hungary' },
    { value: 'IS', name: 'Iceland' },
    { value: 'IN', name: 'India' },
    { value: 'ID', name: 'Indonesia' },
    { value: 'IR', name: 'Iran, Islamic Republic of' },
    { value: 'IQ', name: 'Iraq' },
    { value: 'IE', name: 'Ireland' },
    { value: 'IM', name: 'Isle of Man' },
    { value: 'IL', name: 'Israel' },
    { value: 'IT', name: 'Italy' },
    { value: 'JM', name: 'Jamaica' },
    { value: 'JP', name: 'Japan' },
    { value: 'JE', name: 'Jersey' },
    { value: 'JO', name: 'Jordan' },
    { value: 'KZ', name: 'Kazakhstan' },
    { value: 'KE', name: 'Kenya' },
    { value: 'KI', name: 'Kiribati' },
    { value: 'KP', name: 'Korea, Democratic People\'s Republic of' },
    { value: 'KR', name: 'Korea, Republic of' },
    { value: 'KW', name: 'Kuwait' },
    { value: 'KG', name: 'Kyrgyzstan' },
    { value: 'LA', name: 'Laos People\'s Democratic Republic' },
    { value: 'LV', name: 'Latvia' },
    { value: 'LB', name: 'Lebanon' },
    { value: 'LS', name: 'Lesotho' },
    { value: 'LR', name: 'Liberia' },
    { value: 'LY', name: 'Libya' },
    { value: 'LI', name: 'Liechtenstein' },
    { value: 'LT', name: 'Lithuania' },
    { value: 'LU', name: 'Luxembourg' },
    { value: 'MO', name: 'Macao' },
    { value: 'MK', name: 'Macedonia, The Former Yugoslav Republic of' },
    { value: 'MG', name: 'Madagascar' },
    { value: 'MW', name: 'Malawi' },
    { value: 'MY', name: 'Malaysia' },
    { value: 'MV', name: 'Maldives' },
    { value: 'ML', name: 'Mali' },
    { value: 'MT', name: 'Malta' },
    { value: 'MH', name: 'Marshall Islands' },
    { value: 'MQ', name: 'Martinique' },
    { value: 'MR', name: 'Mauritania' },
    { value: 'MU', name: 'Mauritius' },
    { value: 'YT', name: 'Mayotte' },
    { value: 'MX', name: 'Mexico' },
    { value: 'MD', name: 'Moldova, Republic of' },
    { value: 'MC', name: 'Monaco' },
    { value: 'MN', name: 'Mongolia' },
    { value: 'ME', name: 'Montenegro' },
    { value: 'MS', name: 'Montserrat' },
    { value: 'MA', name: 'Morocco' },
    { value: 'MZ', name: 'Mozambique' },
    { value: 'MM', name: 'Myanmar' },
    { value: 'NA', name: 'Namibia' },
    { value: 'NR', name: 'Nauru' },
    { value: 'NP', name: 'Nepal' },
    { value: 'NL', name: 'Netherlands' },
    { value: 'NC', name: 'New Caledonia' },
    { value: 'NZ', name: 'New Zealand' },
    { value: 'NI', name: 'Nicaragua' },
    { value: 'NE', name: 'Niger' },
    { value: 'NG', name: 'Nigeria' },
    { value: 'NU', name: 'Niue' },
    { value: 'NF', name: 'Norfolk Island' },
    { value: 'MP', name: 'Northern Mariana Islands' },
    { value: 'NO', name: 'Norway' },
    { value: 'OM', name: 'Oman' },
    { value: 'PK', name: 'Pakistan' },
    { value: 'PW', name: 'Palau' },
    { value: 'PS', name: 'Palestine, State of' },
    { value: 'PA', name: 'Panama' },
    { value: 'PG', name: 'Papua-New Guinea' },
    { value: 'PY', name: 'Paraguay' },
    { value: 'PE', name: 'Peru' },
    { value: 'PH', name: 'Philippines' },
    { value: 'PN', name: 'Pitcairn' },
    { value: 'PL', name: 'Poland' },
    { value: 'PT', name: 'Portugal' },
    { value: 'PR', name: 'Puerto Rico' },
    { value: 'QA', name: 'Qatar' },
    { value: 'RE', name: 'Reunion' },
    { value: 'RO', name: 'Romania' },
    { value: 'RU', name: 'Russian Federation' },
    { value: 'RW', name: 'Rwanda' },
    { value: 'BL', name: 'Saint Barthelemy' },
    { value: 'SH', name: 'Saint Helena, Ascension and Tristan Da Cunha' },
    { value: 'KN', name: 'Saint Kitts and Nevis' },
    { value: 'LC', name: 'Saint Lucia' },
    { value: 'MF', name: 'Saint Martin (French Part)' },
    { value: 'PM', name: 'Saint Pierre and Miquelon' },
    { value: 'VC', name: 'Saint Vincent and the Grenadines' },
    { value: 'WS', name: 'Samoa' },
    { value: 'SM', name: 'San Marino' },
    { value: 'ST', name: 'Sao Tome and Principe' },
    { value: 'SA', name: 'Saudi Arabia' },
    { value: 'SN', name: 'Senegal' },
    { value: 'RS', name: 'Serbia' },
    { value: 'SC', name: 'Seychelles' },
    { value: 'SL', name: 'Sierra Leone' },
    { value: 'SG', name: 'Singapore' },
    { value: 'SX', name: 'Saint Maarten (Dutch Part)' },
    { value: 'SK', name: 'Slovakia' },
    { value: 'SI', name: 'Slovenia' },
    { value: 'SB', name: 'Solomon Islands' },
    { value: 'SO', name: 'Somalia' },
    { value: 'ZA', name: 'South Africa' },
    { value: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', name: 'South Sudan' },
    { value: 'ES', name: 'Spain' },
    { value: 'LK', name: 'Sri Lanka' },
    { value: 'SD', name: 'Sudan' },
    { value: 'SR', name: 'Suriname' },
    { value: 'SJ', name: 'Svalbard and Jan Mayen' },
    { value: 'SZ', name: 'Swaziland' },
    { value: 'SE', name: 'Sweden' },
    { value: 'CH', name: 'Switzerland' },
    { value: 'SY', name: 'Syrian Arab Republic' },
    { value: 'TW', name: 'Taiwan' },
    { value: 'TJ', name: 'Tajikistan' },
    { value: 'TZ', name: 'Tanzania, United Republic of' },
    { value: 'TH', name: 'Thailand' },
    { value: 'TL', name: 'Timor-Leste' },
    { value: 'TG', name: 'Togo' },
    { value: 'TK', name: 'Tokelau' },
    { value: 'TO', name: 'Tonga' },
    { value: 'TT', name: 'Trinidad and Tobago' },
    { value: 'TN', name: 'Tunisia' },
    { value: 'TR', name: 'Turkey' },
    { value: 'TM', name: 'Turkmenistan' },
    { value: 'TC', name: 'Turks and Caicos Islands' },
    { value: 'TV', name: 'Tuvalu' },
    { value: 'UG', name: 'Uganda' },
    { value: 'UA', name: 'Ukraine' },
    { value: 'AE', name: 'United Arab Emirates' },
    { value: 'GB', name: 'United Kingdom' },
    { value: 'US', name: 'United States' },
    { value: 'UM', name: 'United States Minor Outlying Islands' },
    { value: 'UY', name: 'Uruguay' },
    { value: 'UZ', name: 'Uzbekistan' },
    { value: 'VU', name: 'Vanuatu' },
    { value: 'VE', name: 'Venezuela, Bolivarian Republic of' },
    { value: 'VN', name: 'Viet Nam' },
    { value: 'VG', name: 'Virgin Islands, British' },
    { value: 'VI', name: 'Virgin Islands, U.S.' },
    { value: 'WF', name: 'Wallis and Futuna' },
    { value: 'EH', name: 'Western Sahara' },
    { value: 'YE', name: 'Yemen' },
    { value: 'ZM', name: 'Zambia' },
    { value: 'ZW', name: 'Zimbabwe' },
];

const FOREIGN_ASSETS_STATES_CODES = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'AL', name: 'Alabama' },
    { value: 'AK', name: 'Alaska' },
    { value: 'AS', name: 'American Samoa' },
    { value: 'AZ', name: 'Arizona' },
    { value: 'AR', name: 'Arkansas' },
    { value: 'CA', name: 'California' },
    { value: 'CO', name: 'Colorado' },
    { value: 'CT', name: 'Connecticut' },
    { value: 'DE', name: 'Delaware' },
    { value: 'DC', name: 'District of Columbia' },
    { value: 'FM', name: 'Fed. States of Micronesia' },
    { value: 'FL', name: 'Florida' },
    { value: 'GA', name: 'Georgia' },
    { value: 'GU', name: 'Guam' },
    { value: 'HI', name: 'Hawaii' },
    { value: 'ID', name: 'Idaho' },
    { value: 'IL', name: 'Illinois' },
    { value: 'IN', name: 'Indiana' },
    { value: 'IA', name: 'Iowa' },
    { value: 'KS', name: 'Kansas' },
    { value: 'KY', name: 'Kentucky' },
    { value: 'LA', name: 'Louisiana' },
    { value: 'ME', name: 'Maine' },
    { value: 'MH', name: 'Marshall Islands' },
    { value: 'MD', name: 'Maryland' },
    { value: 'MA', name: 'Massachusetts' },
    { value: 'MI', name: 'Michigan' },
    { value: 'MN', name: 'Minnesota' },
    { value: 'MS', name: 'Mississippi' },
    { value: 'MO', name: 'Missouri' },
    { value: 'MT', name: 'Montana' },
    { value: 'NE', name: 'Nebraska' },
    { value: 'NV', name: 'Nevada' },
    { value: 'NH', name: 'New Hampshire' },
    { value: 'NJ', name: 'New Jersey' },
    { value: 'NM', name: 'New Mexico' },
    { value: 'NY', name: 'New York' },
    { value: 'NC', name: 'North Carolina' },
    { value: 'ND', name: 'North Dakota' },
    { value: 'MP', name: 'Northern Mariana Islands' },
    { value: 'OH', name: 'Ohio' },
    { value: 'OK', name: 'Oklahoma' },
    { value: 'OR', name: 'Oregon' },
    { value: 'PW', name: 'Palau' },
    { value: 'PA', name: 'Pennsylvania' },
    { value: 'PR', name: 'Puerto Rico' },
    { value: 'RI', name: 'Rhode Island' },
    { value: 'SC', name: 'South Carolina' },
    { value: 'SD', name: 'South Dakota' },
    { value: 'TN', name: 'Tennessee' },
    { value: 'TX', name: 'Texas' },
    { value: 'UT', name: 'Utah' },
    { value: 'VT', name: 'Vermont' },
    { value: 'VI', name: 'Virgin Islands' },
    { value: 'VA', name: 'Virginia' },
    { value: 'WA', name: 'Washington' },
    { value: 'WV', name: 'West Virginia' },
    { value: 'WI', name: 'Wisconsin' },
    { value: 'WY', name: 'Wyoming' },
    { value: 'AE', name: 'Armed Forces Africa, Canada, Europe, Middle East' },
    { value: 'AA', name: 'Armed Forces Americas (Except Canada)' },
    { value: 'AP', name: 'Armed Forces Pacific' },
];

const MEX_STATES_CODE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: 'AGU', name: 'Aguascalientes' },
    { value: 'BCN', name: 'Baja California' },
    { value: 'BCS', name: 'Baja California Sur' },
    { value: 'CAM', name: 'Campeche' },
    { value: 'CHP', name: 'Chiapas' },
    { value: 'CHH', name: 'Chihuahua' },
    { value: 'CMX', name: 'Cuidad de Mexico' },
    { value: 'COA', name: 'Coahuila de Zaragoza' },
    { value: 'COL', name: 'Colima' },
    { value: 'DUR', name: 'Durango' },
    { value: 'GUA', name: 'Guanajuato' },
    { value: 'GRO', name: 'Guerrero' },
    { value: 'HID', name: 'Hidalgo' },
    { value: 'JAL', name: 'Jalisco' },
    { value: 'MIC', name: 'Michoacan de Ocampo' },
    { value: 'MOR', name: 'Morelos' },
    { value: 'MEX', name: 'Mexico' },
    { value: 'NAY', name: 'Nayarit' },
    { value: 'NLE', name: 'Nuevo Leon' },
    { value: 'OAX', name: 'Oaxaca' },
    { value: 'PUE', name: 'Puebla' },
    { value: 'QUE', name: 'Queretaro' },
    { value: 'ROO', name: 'Quintana Roo' },
    { value: 'SLP', name: 'San Luis Potosi' },
    { value: 'SIN', name: 'Sinaloa' },
    { value: 'SON', name: 'Sonora' },
    { value: 'TAB', name: 'Tabasco' },
    { value: 'TAM', name: 'Tamaulipas' },
    { value: 'TLA', name: 'Tlaxcala' },
    { value: 'VER', name: 'Veracruz de Ignacio de la Llave' },
    { value: 'YUC', name: 'Yucatan' },
    { value: 'ZAC', name: 'Zacatecas' },
];

const STATES_ID_TYPE = [
    { value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },
    { value: TRIGGER_KEYS.EVENT_KEY_SSN, name: 'SSN' },
    { value: TRIGGER_KEYS.EVENT_KEY_EIN, name: 'EIN' },
];

const ALL_DIVISIONS = PROVINCES.concat(STATES);

PROVINCES.sort(sortByName);
STATES.sort(sortByName);
PROVINCES.sort(sortByName);
ALL_DIVISIONS.sort(sortByName);
STATES.unshift({ value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },);
PROVINCES.unshift({ value: STRINGS.DROP_DOWN_DEFAULT_VALUE, name: STRINGS.DROP_DOWN_DEFAULT_NAME },);

export {
    ADDRESS_TYPES,
    ALL_DIVISIONS,
    PROVINCES,
    PROVINCES_TRUNC,
    PROVINCES_CODE,
    STATES,
    STATES_CODE,
    STATES_TAX_PAYMENT_CODES,
    FOREIGN_COUNTRIES,
    COUNTRIES,
    DATE_FILTER_TYPES,
    DEP_STATUS,
    TIMEZONES,
    MARITIAL_STATUS,
    YESNO,
    YESNO_RADIO,
    PAYER,
    PAYER_TS,
    PAYER_TSO,
    IRA,
    SELF_EMPLOYED_RETIREMENT_PLANS,
    ENERGY_IMPROVEMENTS,
    ENERGY_RENEWABLE,
    ENERGY_ALTERNATIVES,
    TYPE_OF_USE,
    ACCOUNT_TYPE,
    TUITION_PAYER,
    PROPERTY_TYPE,
    BUSINESS_INCOME_FORM_TYPE,
    YESNO_DONTKNOW,
    CONFIRMED_REQUIRESCHANGE,
    TAX_TYPE,
    QUARTER,
    RELATIONSHIP,
    FOREIGN_SEPERATE_HOME_RELATIONSHIP,
    ELECTRONIC_WITHDRAWAL,
    SUFFIX,
    AUTHORIZED_TRANSACTIONS,
    FOREIGN_ACCOUNT_TYPE,
    FOREIGN_CURRENCIES,
    ID_TYPE,
    PROVIDER_DEPENDENT_TYPE,
    FOREIGN_INSURANCE_TYPE,
    FOREIGN_ENTITY_CLASSIFICATION,
    FOREIGN_LIVING_QUARTERS,
    FOREIGN_ASSETS_COUNTRIES,
    FOREIGN_ASSETS_STATES_CODES,
    MEX_STATES_CODE,
    STATES_ID_TYPE,
};
