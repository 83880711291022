// common modules
import React from 'react';
import { Link } from 'react-router-dom';

// custom modules
import '@components/BreadcrumbItem.css';

function BreadcrumbItem(props) {
	const { to, label, current } = props;

	return (
		<li className='breadcrumb-item'>
			<Link key={to} to={to} className={current ? 'breadcrumb-item-link-current' : 'breadcrumb-item-link'}>{label}</Link>
		</li>
	)
};

export default BreadcrumbItem;