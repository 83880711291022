const getStateTaxSpreadSections = () => {
    const stateSections = [
        { section: 'Alabama', id: 'Alabama Individual (Form 50)' },
        { section: 'Arizona', id: 'State Worksheets' },
        { section: 'Arkansas', id: 'State Worksheets' },
        { section: 'California', id: 'California Individual (Form 540/540NR)' },
        { section: 'Colorado', id: 'State Worksheets' },
        { section: 'Connecticut', id: 'State Worksheets' },
        { section: 'Delaware', id: 'State Worksheets' },
        { section: 'District of Columbia', id: 'District of Columbia Individual (Form D-40)' },
        { section: 'Georgia', id: 'State Worksheets' },
        { section: 'Hawaii', id: 'State Worksheets' },
        { section: 'Idaho', id: 'State Worksheets' },
        { section: 'Illinois', id: 'State Worksheets' },
        { section: 'Indiana', id: 'State Worksheets' },
        { section: 'Iowa', id: 'State Worksheets' },
        { section: 'Kansas', id: 'Kansas Income Tax (K-40)' },
        { section: 'Kentucky', id: 'Kentucky (Form 740 / 740NP)' },
        { section: 'Louisiana', id: 'State Worksheets' },
        { section: 'Maine', id: 'State Worksheets' },
        { section: 'Maryland', id: 'State Worksheets' },
        { section: 'Massachusetts', id: 'State Worksheets' },
        { section: 'Michigan', id: 'Michigan Income Tax (MI 1040)' },
        { section: 'Minnesota', id: 'Minnesota Income Tax Return (M1)' },
        { section: 'Mississippi', id: 'State Worksheets' },
        { section: 'Missouri', id: 'State Worksheets' },
        { section: 'Montana', id: 'State Worksheets' },
        { section: 'Nebraska', id: 'State Worksheets' },
        { section: 'New Hampshire', id: 'Interest and Dividends Tax Return (DP-10)' },
        { section: 'New Jersey', id: 'State Worksheets' },
        { section: 'New Mexico', id: 'State Worksheets' },
        { section: 'New York', id: 'State Worksheets' },
        { section: 'North Carolina', id: 'North Carolina Income Tax (Form D-400)' },
        { section: 'North Dakota', id: 'State Worksheets' },
        { section: 'Ohio', id: 'Ohio Income Tax (IT 1040)' },
        { section: 'Oklahoma', id: 'State Worksheets' },
        { section: 'Oregon', id: 'State Worksheets' },
        { section: 'Pennsylvania', id: 'State Worksheets' },
        { section: 'Rhode Island', id: 'State Worksheets' },
        { section: 'South Carolina', id: 'State Worksheets' },
        { section: 'Tennessee', id: 'State Worksheets' },
        { section: 'Utah', id: 'State Worksheets' },
        { section: 'Vermont', id: 'State Worksheets' },
        { section: 'Virginia', id: 'State Worksheets' },
        { section: 'West Virginia', id: 'State Worksheets' },
        { section: 'Wisconsin', id: 'State Worksheets' },
    ]

    return stateSections
}

// Please not that the values below are ordered and corresponds with the values in /src/views/personalAndOther/stateTaxEntity/index.js If a new key is added here, kindly updated the keys in /src/utilities/helpers/getStateTaxSections.js.
const stateTaxMaps = {
    Alabama: [2, 3],
    Arizona: [4],
    Arkansas: [5, 6],
    California: [7],
    Colorado: [8],
    Connecticut: [9],
    Delaware: [10, 11],
    'District of Columbia': [12],
    Georgia: [13],
    Hawaii: [14],
    Idaho: [15],
    Illinois: [16],
    Indiana: [17],
    Iowa: [18],
    Kansas: [19],
    Kentucky: [20, 21],
    Louisiana: [22],
    Maine: [23],
    Maryland: [24],
    Massachusetts : [25],
    Michigan: [26],
    Minnesota: [27],
    Mississippi: [28],
    Missouri: [29],
    Montana: [30],
    Nebraska: [31],
    'New Hampshire': [32],
    'New Jersey': [33],
    'New Mexico': [34],
    'New York': [35],
    'North Carolina': [36],
    'North Dakota': [37],
    Ohio: [38],
    Oklahoma: [39],
    Oregon: [40],
    Pennsylvania: [41],
    'Rhode Island': [42],
    'South Carolina': [43],
    Utah: [44],
    Vermont: [45],
    Virginia: [46, 47],
    'West Virginia': [48],
    Wisconsin: [49]
}

export {
    getStateTaxSpreadSections,
    stateTaxMaps
}