import { Validators } from 'cla-formrenderer';

import * as EVENT from '@utilities/constants/triggerKeys';
import { setConditionalFieldValidation, setEntityNotApplicable } from '@components/formRenderer/triggers';

const setIFDSFGEN8Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[2].fields[1].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[2].fields[1].validations = null;
        name[0].groups[2].fields[1].error = false;
        name[0].groups[2].fields[1].errorMessage = null;
    } 

    setFormSections(name);
};

const setFileAllFormsSchedF = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name[0].groups[3].isVisible = event === EVENT.KEY_YES;
   
    setFormSections(name);
};

const showGovernmentGrantsDetailsSchedF = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    name[0].groups[5].isVisible = event === EVENT.KEY_YES;
    setFormSections(name);
};

const setIFDSFGEN9Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[3].fields[1].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[3].fields[1].validations = null;
        name[0].groups[3].fields[1].error = false;
        name[0].groups[3].fields[1].errorMessage = null;
    } 

    setFormSections(name);
};

const setBusUseHomeRequired = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '' || event?.trim() !== '0') {
        name[5].groups[0].fields[1].validations = { ...Validators.required() }; 
    } 

    if (event?.trim() === '' || event?.trim() === '0') {
        name[5].groups[0].fields[1].validations = null;
        name[5].groups[0].fields[1].error = false;
        name[5].groups[0].fields[1].errorMessage = null;
    } 

    setFormSections(name);
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'setIFDSFGEN8Required':
            setIFDSFGEN8Required(event, options);
        break;
        case 'setFileAllFormsSchedF':
            setFileAllFormsSchedF(event, options);
        break;
        case 'setIFDSFGEN9Required':
            setIFDSFGEN9Required(event, options);
        break;
        case 'setBusUseHomeRequired':
            setBusUseHomeRequired(event, options);
        break;
        case 'setEntityNotApplicable':
            setEntityNotApplicable(event, options)
        break;
        case 'showGovernmentGrantsDetailsSchedF': 
            showGovernmentGrantsDetailsSchedF(event, options);
            break;
    default:
        break;
    }
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) { 
        case 'setConditionalFieldValidation':
            setConditionalFieldValidation(options);
        break;
    default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};