import { Validators } from 'cla-formrenderer';
import { DEPENDENT_CARE_UPLOAD } from '@utilities/constants/strings';

const childCareFn = (year) => {
    const sections = [
        {
            title: 'Dependent Care Expenses',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: `Please provide any dependent and child care expenses paid or incurred in ${year.current}:`, type: 'label', class: 'groupDetails2' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    fields: [
                        {
                            label: 'Name', wrapLabel: 'Provider Name', name: 'IFDSCARE.0', type: 'freeText', placeholder: 'Enter Provider Name', prior: true,
                            logicFunction: [
                                { trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSCARE.4'] },
                            ]
                        }, //First Name?
                        //TODO  Provider last name IFDSCARE.18 ?
                        //TODO Emplyer ID Access Field Key: IFDSCARE.3
                        {
                            label: '#', wrapLabel: 'SSN/EIN', name: 'IFDSCARE.4', type: 'ssn', placeholder: 'Enter SSN / EIN',
                            overRideText: 'On File', axcessTranslate: 'omitOnOverride', isMaskOnBlur: true,
                            prior: true, validations: {
                                ...Validators.ssn(),
                            }
                        },
                        { label: 'Address', wrapLabel: 'Street Address', name: 'IFDSCARE.1', type: 'freeText', maxLength: 29, placeholder: 'Enter Street Address', prior: true },
                        { label: 'City', name: 'IFDSCARE.2', type: 'freeText', maxLength: 20, placeholder: 'Enter City', prior: true },
                        { label: 'State', name: 'IFDSCARE.14', type: 'select', lookup: 'STATES', placeholder: 'Select State', prior: true },
                        { label: 'Zip', wrapLabel: 'Zip Code', name: 'IFDSCARE.15', type: 'zippostal', placeholder: 'Enter Zip Code', prior: true },
                        { label: 'Name', wrapLabel: 'Dependent Name', name: 'childCareChildName', type: 'freeText', placeholder: 'Enter Dependent Name' },
                        { label: 'Amount', name: 'C-IFDSCARE.5', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Provider Name', smallScreenLabel: 'Dependent Care Information', col: 2, colTablet: 1, align: 'left' },
                            { label: 'SSN/EIN', col: 2, colTablet: 1, },
                            { label: 'Street Address', col: 2, colTablet: 1, },
                            { label: 'City', col: 1, colTablet: 1, },
                            { label: 'State', col: 1, colTablet: 1, },
                            { label: 'Zip', col: 1, colTablet: 1, },
                            { label: 'Dependent Name', col: 1, colTablet: 1, },
                            { label: `${year.current} Amount`, col: 1, colTablet: 1, align: 'right' },
                            { label: 'Receipts', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Dependent Care Expense', icon: 'add' },
                            { type: 'label', label: 'Total', col: 9, colTablet: 6, },
                            { type: 'totalMoney', name: 'C-IFDSCARE.5', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: DEPENDENT_CARE_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                },
            ]
        },
    ];

    return sections;
};

export default childCareFn;