// common modules
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

// setup application insights configuration
const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const instrumentationKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY || '';
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});

// initialize application insights
appInsights.loadAppInsights();

// disable application insights if development mode or invalid key
if (!process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY
    || process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY === '00000000-0000-0000-0000-000000000000') {
  appInsights.addTelemetryInitializer(() => false);
}

// add custom role name
appInsights.addTelemetryInitializer((envelope) => {
  envelope.tags['ai.cloud.role'] = process.env.REACT_APP_APPINSIGHTS_ROLENAME;
})

export { reactPlugin, appInsights };