// import moneyFormatter, { noDollarFormatter, parseMoneyToNumber } from './moneyFormatter';
// import moneyFormatter, { noDollarFormatter, parseMoneyToNumber } from 'src/utilities/moneyFormatter';
import moneyFormatter, { noDollarFormatter, parseMoneyToNumber } from '@utilities/moneyFormatter';
import _ from 'lodash';
import React from 'react';

const updateTotals = (sections, setFooterTotals = null) => {
    const totals = [];
    
    let defaultValue = 0.0;

    if (sections) {
        const filtered = sections.filter(section => section?.footers);
        
        filtered && filtered.forEach(section => {
            let groupItem = section.groups.find(group => group.groupType === 'groupItem');
            let filteredGroups = groupItem ? groupItem.groupItems.flat() : section.groups;
            if (section.title === 'Compensation Details' && groupItem) {
                const filingOrgs = filteredGroups.filter(group => group.title === 'Filing Organization');
                compDetailsSummaryViewHeaders(filingOrgs, section, 'filing');

                const relatedOrgs = filteredGroups.filter(group => group.title === 'Related Organization');
                compDetailsSummaryViewHeaders(relatedOrgs, section, 'related');

                const unrelatedOrgs = filteredGroups.filter(group => group.title === 'Unrelated Organization');
                compDetailsSummaryViewHeaders(unrelatedOrgs, section, 'unrelated');
            }
            
            filteredGroups.forEach(group => {
                let fields = group.fields.filter(x => 
                x.type === 'money' || 
                x.totalOrg === 'compBase' || 
                x.totalOrg === 'benefitsDetailsOrgBenefits'
                );
                
                if (group && group.isVisible) { // condition might change depending on future dense row with totals
                    fields.forEach((field) => {
                        const total = totals.find(x => x.totalOrg === field.totalOrg);
                        
                        // will fields.NumberValue affects anything??
                        if (field.totalOrg !== 'compBase' && field.totalOrg !== 'benefitsDetailsOrgBenefits') {
                        let value = field.amount || field.default;
                        let numberValue = value ? value.replace(/[^0-9.]/g,'') : 0.0;
                        defaultValue = Number(numberValue) || 0.0;
                        } else {
                        let value = field.amount >= 0 ? field.amount : 0.0;
                        defaultValue = value;
                        }
                        
                        if (section.title === 'Compensation Details' && !groupItem) {
                        const header = section.headers.find(x => x.name === field.name);
                        assignTotalsValue(header, { default: defaultValue }, 'compensationSummary');
                        }
                        
                        if (total) {
                        total.default += defaultValue;
                        } else {
                        totals.push({ totalOrg: field.totalOrg, default: defaultValue });
                        }
                    });
                }
            });

            if (setFooterTotals) setFooterTotals(totals);
            const footers = section.footers;
            const headers = section.headers;
            
            totals.forEach((total) => {
                const footer = footers && footers.find(x => x.totalOrg === total.totalOrg);
                assignTotalsValue(footer, total, 'footer');
            });

            totals.forEach((total) => {
                const header = headers && headers.find(x => x.totalOrg === total.totalOrg);
                assignTotalsValue(header, total, 'header');
            });
            
            const header = headers && _.find(headers, {isCombinedTotal: true}); //To Combine header values
            if(header){
                let combinedTotal = {};
                let allTotal = 0;
                //eslint-disable-next-line
                headers.map(total => {
                    let totalAmount = total?.amount ? parseMoneyToNumber(total.amount) : 0;
                    if(!total.isCombinedTotal && !isNaN(totalAmount)){
                        allTotal += totalAmount;
                    }
                });
                combinedTotal.amount = allTotal;
                combinedTotal.default = allTotal;
                combinedTotal.totalOrg = header.totalOrg;
                assignTotalsValue(header, combinedTotal, 'header');
            }
        });
    }
};

export const updateMasterTotals = (group, fields) => {
    const copyFields = _.cloneDeep(group.fields);
    const totalMembers = group.summaryList.sections[0].groups[0].groupItemsLength;
    copyFields[0].label = <React.Fragment>
        Totals 
        <p id='membersCount'>
            Members <span>({totalMembers})</span>
        </p>
    </React.Fragment>;
    copyFields[0].styles = {color: '#2d3c59', fontSize: '12px', paddingLeft: '10px'};
    copyFields[1].label = '';

    let totalNontaxableAmt = 0, totalRetirementAmt = 0;
    let fyGross = 0, fyPaidBenefits = 0, fyPaidCont = 0;
    let compIndvCompOrg = 0, compIndvCompRelOrg = 0, compIndvCompUnrelOrg = 0;
    let benefitLabels = '';
    let fiscalYearLabels = '';
    let compensationLabels = '';

    fields.compensationSummaryFields.forEach(fields => {
        fields && fields.forEach(field => {
            if (field.name === 'compIndvCompOrg') {
                let value = field.amount || field.default;
                let numberValue = value ? value.replace(/[^0-9.]/g,'') : 0.0;
                compIndvCompOrg += Number(numberValue) || 0.0;
            } else if (field.name === 'compIndvCompRelOrg') {
                let value = field.amount || field.default;
                let numberValue = value ? value.replace(/[^0-9.]/g,'') : 0.0;
                compIndvCompRelOrg += Number(numberValue) || 0.0;
            } else if (field.name === 'compIndvCompUnrelOrg') {
                let value = field.amount || field.default;
                let numberValue = value ? value.replace(/[^0-9.]/g,'') : 0.0;
                compIndvCompUnrelOrg += Number(numberValue) || 0.0;
            }
        });
    });

    fields.benefitsDetailsSummaryFields.forEach(fields => {
        fields && fields.forEach(field => {
            if (field.totalOrg === 'benefitsDetailsOrgBenefits' && field.amount) {
                totalNontaxableAmt += field.amount;
            } else if (field.totalOrg === 'benefitsDetailsOrgDeferred') {
                let value = field.amount || field.default;
                let numberValue = value ? value.replace(/[^0-9.]/g,'') : 0.0;
                totalRetirementAmt += Number(numberValue) || 0.0;
            }
        });
    });

    fields.fiscalYearSummaryFields.forEach(fields => {
        fields && fields.forEach(field => {
            if (field.totalOrg === 'fyGross') {
                let value = field.amount || field.default;
                let numberValue = value ? value.replace(/[^0-9.]/g,'') : 0.0;
                fyGross += Number(numberValue) || 0.0;
            } else if (field.totalOrg === 'fyPaidBenefits') {
                let value = field.amount || field.default;
                let numberValue = value ? value.replace(/[^0-9.]/g,'') : 0.0;
                fyPaidBenefits += Number(numberValue) || 0.0;
            } else if (field.totalOrg === 'fyPaidCont') {
                let value = field.amount || field.default;
                let numberValue = value ? value.replace(/[^0-9.]/g,'') : 0.0;
                fyPaidCont += Number(numberValue) || 0.0;
            }
        });
    });

    // Compensation Details
    if (compIndvCompOrg) {
        compensationLabels += 'Filing Org - W-2/1099: '+ moneyFormatter(compIndvCompOrg) + '\n';
    } else {
        compensationLabels += 'Filing Org - W-2/1099: $\n';
    }

    if (compIndvCompRelOrg) {
        compensationLabels += 'Related Org - W-2/1099: '+ moneyFormatter(compIndvCompRelOrg) + '\n';
    } else {
        compensationLabels += 'Related Org - W-2/1099: $\n';
    }

     if (compIndvCompUnrelOrg) {
        compensationLabels += 'Unrelated Org - W-2/1099: '+ moneyFormatter(compIndvCompUnrelOrg) + '\n';
    } else {
        compensationLabels += 'Unrelated Org - W-2/1099: $\n';
    }

    // Benefit Details
    if (totalNontaxableAmt) {
        benefitLabels += 'Total Nontaxable Benefits: '+ moneyFormatter(totalNontaxableAmt) + '\n';
    } else {
        benefitLabels += 'Total Nontaxable Benefits: $\n';
    }

    if (totalRetirementAmt) {
        benefitLabels += 'Total Retirement Benefits: '+ moneyFormatter(totalRetirementAmt) + '\n';
    } else {
        benefitLabels += 'Total Retirement Benefits: $\n';
    }

    const total = totalNontaxableAmt + totalRetirementAmt;
    if (total) {
        benefitLabels += 'Total: '+ moneyFormatter(total) + '\n';
    } else {
        benefitLabels += 'Total: $\n';
    } 

    // Fiscal Year Details
    if (fyGross) {
        fiscalYearLabels += 'Gross Salaries/Wages: '+ moneyFormatter(fyGross) + '\n';
    } else {
        fiscalYearLabels += 'Gross Salaries/Wages: $\n';
    }

    if (fyPaidCont) {
        fiscalYearLabels += 'Total Retirement: '+ moneyFormatter(fyPaidCont) + '\n';
    } else {
        fiscalYearLabels += 'Total Retirement: $\n';
    }

    if (fyPaidBenefits) {
        fiscalYearLabels += 'Total Benefits: '+ moneyFormatter(fyPaidBenefits) + '\n';
    } else {
        fiscalYearLabels += 'Total Benefits: $\n';
    } 

    copyFields[2].label = compensationLabels;
    copyFields[3].label = benefitLabels;
    copyFields[4].label = fiscalYearLabels;

    return copyFields;
}

const compDetailsSummaryViewHeaders = (filteredOrgs, section, orgType = '') => {
    const filteredFields = filteredOrgs
        .map(group => group.isVisible && group.fields.filter(field => field.totalOrg === 'compW2Total'))
        .flat();
    const visibleOrgs = filteredOrgs.filter(org => org.isVisible);
    if (orgType === 'filing') {
        let filingW2Amount = 0.0;
        const header = section.headers[0];
        filingW2Amount = calculateCompSummaryViewHeaders(filteredFields);
        if (visibleOrgs.length > 0) header.isVisible = true;
        else header.isVisible = false;
        assignTotalsValue(header, { default: filingW2Amount }, 'compensationSummary');
    } 
    else if (orgType === 'related') {
        let relatedW2Amount = 0.0;
        const header = section.headers[1];
        if (visibleOrgs.length > 0) header.isVisible = true;
        else header.isVisible = false;
        relatedW2Amount = calculateCompSummaryViewHeaders(filteredFields);

        assignTotalsValue(header, { default: relatedW2Amount }, 'compensationSummary');
    } else if (orgType === 'unrelated') {
        let unrelatedW2Amount = 0.0;
        const header = section.headers[2];
        unrelatedW2Amount = calculateCompSummaryViewHeaders(filteredFields);
        if (visibleOrgs.length > 0) header.isVisible = true;
        else header.isVisible = false;
        assignTotalsValue(header, { default: unrelatedW2Amount }, 'compensationSummary');
    }
    }

    const calculateCompSummaryViewHeaders = (filteredFields) => {
    let accumulator = 0.0;
    filteredFields.forEach(field => {
        let value = field.amount || field.default;
        let numberValue = value ? value.replace(/[^0-9.]/g,'') : 0.0;
        accumulator += Number(numberValue) || 0.0;
    });

    return accumulator;
}

const assignTotalsValue = (data, total, type) => {
    if (data) {
        if (type === 'footer') {
        data.amount = total.default;
        data.label = formatTotals(data, total);
        } else if (type === 'header') {
        data.amount = total.default;
        if (data.amount) {
            data.amount = formatTotals(data, total);
        }
        } else if (type === 'compensationSummary') {
        data.amount = total.default;
        if (data.amount) {
            data.amount = formatTotals(data, total);
        }
        }
    }
}

const formatTotals = (data, total) => {
    return data.type === 'totalMoney' ? 
        moneyFormatter(Number(total.default), total.currencySymbol) : 
            noDollarFormatter(Number(total.default));
}

export default updateTotals;