/* eslint-disable no-unused-vars */
import { convertDate } from '../utils/convertDate';
import { useField } from '../useField';
import { FormMessage, TextField, Tooltipped, HoverModeProvider, } from 'react-md';
import moment from 'moment';
import { getHintText } from '../utils/getHintText';
import { isFieldDisabled } from '../utils/isDisabled';
import { MM_DD_YYYY } from '@utilities/constants/dateFormats';
import { parseToISODate } from '../utils/formatDate';
import { adjustLabels } from '../utils/adjustLabels';
import { useEffect, useState, useRef } from 'react';
import { renderLabel } from '../utils/isRequired';
import { isError } from '../utils/isError';

function DateField(props) {
    const { field, index, group, isFormLocked, section, parentGroup , parentIndex, grandParentGroup} = props;
    const { state, handleBlur, handleChange } = useField(props);

    const dateStart = field.minDate ? field.minDate === 'today' ? convertDate(String(new Date())) : convertDate(field.minDate) : convertDate('01-01-1900');
    const dateEnd = field.maxDate ? field.maxDate === 'today' ? convertDate(String(new Date())) : convertDate(field.maxDate) : convertDate('12-31-2099');
    
    const valued = state.value && state.value.length > 0 ? true : false;
    const hintText = getHintText(state, valued, field);

    const getDateTooltip = () => {
        return valued ? moment(new Date(state.value)).add(1, 'days').format(MM_DD_YYYY) : 'mm/dd/yyyy';
    };
    const priorYearChanged = parseToISODate(field.priorYearValue) !== state.value;
    const [labelClassName, setLabelClassName] = useState('');
    const ref = useRef(null);
    
    useEffect(() => {
        adjustLabels(ref, section, setLabelClassName);
        //eslint-disable-next-line
    }, []);

    return (
        <div>
            <HoverModeProvider>
                <Tooltipped id={`hover-${section.title}-${field.name}-${index}`} tooltip={getDateTooltip()} position='above' key={`hover-${field.name}-${index}`} className='pracDashOnHover'>
                    <div className={labelClassName}>
                        {isError(field)}
                        <TextField 
                            ref={ref}
                            className={field.priorYearValue && priorYearChanged ? 'cla-prior-year-data-edited' : !field.priorYearValue && state.value.trim() !== '' ? 'cla-prior-year-data-edited' : null}
                            inputClassName={!priorYearChanged ? 'cla-prior-year-data' : null}
                            id={`date-${section.title}-${field.name}-${index}`}
                            name={field.name}
                            key={`date-${field.name}-${index}`}
                            type='date'
                            label={renderLabel(field)}
                            error={state.error}
                            value={state.value}
                            placeholder={field.placeholder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            min={dateStart}
                            max={dateEnd}
                            disabled={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section)}
                            required={field.required ? true : false}
                            data-testid='date-field'
                        />
                    </div>
                </Tooltipped>
            </HoverModeProvider>
            <FormMessage 
                id={`${field.id}-error-tooltip`}
                error={state.error}
                data-testid='error-message'
            >
                {hintText}
            </FormMessage>
        </div>
    );
}

export default DateField;