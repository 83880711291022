import {
    INVESTMENT_INCOME_1099INT_UPLOAD,
    INVESTMENT_INCOME_NO1099INT_UPLOAD,
    INVESTMENT_INCOME_1099DIV_UPLOAD,
    INVESTMENT_INCOME_BROKERAGE_UPLOAD,
    INVESTMENT_INCOME_OTHERS_UPLOAD,
} from '@utilities/constants/strings';

const investmentFn = (year) => {
    const sections = [
        {
            title: 'Interest Income (Form 1099-INT, 1099-OID)',
            shortTitle: 'Interest Income',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSINT.0', type: 'select', lookup: 'PAYER', placeholder: 'Select', prior: true, axcessTranslate: 'omit' },
                        { label: 'Payer', name: 'IFDSINT.5', type: 'freeText', placeholder: 'Enter Name', prior: true, axcessTranslate: 'omit' },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSINT.10', type: 'label', isTotal: true, prior: true, axcessTranslate: 'omit' },
                        { label: '', name: '', type: 'label' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Interest Income Details', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Payer', col: 5, colTablet: 3, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { col: 2, colTablet: 0 },
                            { label: '1099-INT / 1099-OID', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Interest Income (Form 1099-INT, 1099-OID)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 6, colTablet: 5 },
                            { type: 'totalMoney', name: 'IFDSINT.10', col: 3, colTablet: 2, year: 'prior', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: INVESTMENT_INCOME_1099INT_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Interest Income - No 1099 Received',
            shortTitle: 'No 1099 Received',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 2,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSINT.0', type: 'select', lookup: 'PAYER', placeholder: 'Select' },
                        { label: 'Payer', name: 'IFDSINT.5', type: 'freeText', placeholder: 'Enter Name' },
                        { label: '', name: '', type: 'label' },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSINT.10', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Interest Income Details', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Payer', col: 5, colTablet: 3, align: 'left' },
                            { col: 2, colTablet: 0 },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: 'Documentation (Optional)', col: 21, colTablet: 1, align: 'noPaddingLeft' },

                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Interest Income - No 1099 Received', icon: 'add' },
                            { type: 'label', label: 'Total', col: 8, colTablet: 5, },
                            { type: 'totalMoney', name: 'C-IFDSINT.10', col: 3, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: INVESTMENT_INCOME_NO1099INT_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Dividend Income (Form 1099-DIV)',
            shortTitle: 'Dividend Income',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 3,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSDIV.0', type: 'select', lookup: 'PAYER', placeholder: 'Select', prior: true },
                        { label: 'Payer', name: 'IFDSDIV.5', type: 'freeText', placeholder: 'Enter Name', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSDIV.4', type: 'label', isTotal: true, prior: true },
                        { label: '', name: '', type: 'label' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Dividend Income Details', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Payer', col: 5, colTablet: 3, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { col: 2, colTablet: 0 },
                            { label: '1099-DIV', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Dividend Income (Form 1099-DIV)', icon: 'add' },
                            { type: 'label', label: 'Total', col: 6, colTablet: 5 },
                            { type: 'totalMoney', name: 'IFDSDIV.4', col: 3, colTablet: 2, year: 'prior', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: INVESTMENT_INCOME_1099DIV_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Brokerage Statement (Consolidated Form 1099)',
            shortTitle: 'Brokerage Statement',
            isVisible: true,
            collapseClass: 'collapsibleForm',
            sectionId: 4,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    axcessGroup: {
                        pull: {
                            fromSections: ['General'],
                            fieldIdentifiers: ['IFDSCB.1', 'IFDSCB.0', 'IFDSCB.5'],
                        }
                    },
                    fields: [
                        {
                            label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSCB.1', type: 'select', lookup: 'PAYER', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSCB.1'],
                            }
                        },
                        {
                            label: 'Payer', name: 'IFDSCB.0', type: 'freeText', placeholder: 'Enter Name', prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSCB.0', 'IFDSCB.5'],
                                fn: 'accNameWithNumberPull',
                            }
                        },
                        { label: '', name: '', type: 'label' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Brokerage Statement Details', col: 2, colTablet: 2 },
                            { label: 'Payer', col: 5, colTablet: 3 },
                            { col: 4, colTablet: 2 },
                            { label: 'Consolidated 1099', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Brokerage Statement (Consolidated Form 1099)', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: INVESTMENT_INCOME_BROKERAGE_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Other Investment Income / Sales of Assets',
            shortTitle: 'Other Investment',
            isVisible: true,
            sectionId: 5,
            groups: [
                {
                    groupId: 1,
                    isSingleColumn: true,
                    groupType: 'blueBox',
                    fields: [
                        { label: 'The following items are less common forms of investment income or sales of stock and capital assets.', type: 'checkboxLabel', col: 12 },
                        { label: 'Please select all that apply and upload any applicable documents for the year, or complete the table below:', type: 'checkboxLabel', col: 12 },
                        { label: 'I was granted or exercised stock options or restricted stock ', name: 'incomeOptionsOrRestricted', type: 'checkbox', col: 12 },
                        { label: 'I sold property for which I will receive payments in the future (Installment sale) ', name: 'incomeSoldProperty', type: 'checkbox', col: 12 },
                        { label: 'I sold property which was previously inherited ', name: 'incomeSoldInheritedProperty', type: 'checkbox', col: 12 },
                        { label: 'I acquired or sold “qualified small business stock” ', name: 'incomeSoldQualBusinessStock', type: 'checkbox', col: 12 },
                        { label: 'I invested in a Qualified Opportunity Zone (QOZ) fund ', name: 'incomeQQZFund', type: 'checkbox', col: 12 },
                        { label: 'I received a 1099-S (Proceeds From Real Estate Transaction)', name: 'incomeRcvd1099S', type: 'checkbox', col: 12 },
                        { label: 'I received interest or dividends reportable by someone else (nominee interest/dividends)', name: 'incomeNomineeDiv', type: 'checkbox', col: 12 },
                        { label: 'I had securities that became worthless or loans that became uncollectable', name: 'incomeSecUncollectable', type: 'checkbox', col: 12 },
                        { label: 'I provided seller financed mortgage', name: 'incomeSellerFinanceMort', type: 'checkbox', col: 12 },
                        { label: 'Other', name: 'incomeOtherComment', type: 'checkbox', col: 12 },

                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 4,
                    fields: [
                        { label: 'Type of Income', name: 'incomeTypeName', type: 'freeText', placeholder: 'Enter Type', prior: true },
                        { label: 'Amount', name: 'incomeAmount', type: 'money', placeholder: '0', isTotal: true, prior: true },
                        { label: 'Comments', name: 'incomeComments', type: 'freeText', placeholder: ' Enter Comments' },
                        { type: 'lineButtons' }

                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Type of Income', smallScreenLabel: 'Other Income Details', col: 3, colTablet: 2 },
                            { label: 'Amount', col: 2, colTablet: 3, align: 'right' },
                            { label: 'Comments', col: 6, colTablet: 2 },
                            { label: 'Actions', col: 1, align: 'noPaddingLeft', colTablet: 1 },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Investment Income / Sales of Assets', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: INVESTMENT_INCOME_OTHERS_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
    ];

    return sections;
};

export default investmentFn;