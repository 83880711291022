import * as REDUX from '@utilities/constants/redux';
import buildAndPopulateEntityForm from '@utilities/populatePriorData/buildFormData/buildAndPopulateEntityForm.js';
import buildAndPopulateForms from '@utilities/populatePriorData/buildFormData/buildAndPopulateForms.js';
import { parseFormData } from '@utilities/formData/formDataParser.js';
import preProcessVehicleWorksheets from '@utilities/populatePriorData/vehicles/parseVehicleData.js';

const vehicleIdentifiers = [
    { section: 'Vehicle', id: 'Vehicle' },
];

const prepareVehicleForm = (vehicleEntity) => {
    const { identifer, section } = vehicleEntity;
    const vehicleForm = buildAndPopulateForms(section, identifer);
    
    return parseFormData(vehicleForm);
};

const loadVehicleData = (priorYearData, year) => {
    const loadedVehicleData = {};

    // Process and parse prior year data for vehicle worksheets
    const processedVehicles = preProcessVehicleWorksheets(priorYearData);
    const vehicleEntityForm = buildAndPopulateEntityForm(vehicleIdentifiers, processedVehicles, year);

    if (!vehicleEntityForm.length) return loadedVehicleData;
    // Generate vehicle-#-# forms
    loadedVehicleData[REDUX.VEHICLE] = vehicleEntityForm;

    Array.from(vehicleEntityForm).map(prepareVehicleForm).forEach((vehicleForm, index) => {
        const vehicleKey = `${REDUX.VEHICLE}-${index}-0`;
        loadedVehicleData[vehicleKey] = vehicleForm;
    });

    return loadedVehicleData;
};


export default loadVehicleData;

export {
    loadVehicleData,
    // Exported for testing
    prepareVehicleForm,
};