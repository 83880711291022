import _ from 'lodash';
import { findFromPYData, hasMatchingKeyInField, hasMatchingKeyInLineItems } from '@utilities/axcessTaxPull';

// return true/false based on match

const filterTypeContainsAnyValue = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;
	const { key } = lineFilter;

	const foundKey = lineData.find(x => x && x.key === key);

	if (!foundKey) return false;

	return !_.isEmpty(foundKey.value) ? true : false;
};

const filterTypeContainsAny = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;

	const { key, filterData } = lineFilter;
	const foundKey = lineData.find(x => x && x.key === key);

	if (!foundKey || !_.isArray(filterData)) return false;
	return filterData.includes(foundKey.value);
};

const filterTypeContainsKey = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;
	const { key } = lineFilter;

	const foundKey = lineData && lineData.find(x => x && x.key === key);
	return foundKey ? true : false;
};

const filterTypeLinesNotContainsKey = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return true;
	const { key } = lineFilter;

	const foundLine = lineData && lineData.find(x => x && x.data?.find(y => hasMatchingKeyInField(y, key) || hasMatchingKeyInLineItems(y, key)));
	return foundLine ? false : true;
};

const filterTypeLineMatchingOnly = (lineData, lineFilter) => {
	// Line data is not an array, but an object with a data array
	if (!lineData || !lineFilter) return false;
	const { key } = lineFilter;

	const foundLine = lineData && [lineData].find(x => x && x.data?.find(y => hasMatchingKeyInField(y, key) || hasMatchingKeyInLineItems(y, key)));
	return foundLine ? true : false;
};

const filterTypeContainsKeyGroup = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;
	const { key } = lineFilter;

	const foundKeyGroup = lineData && lineData.find(x => x && x.key?.split('.')[0] === key?.split('.')[0]);
	return foundKeyGroup ? true : false;
};

const filterTypeNotInKeyGroup = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;
	const { key, filterData } = lineFilter;

	const lineFromGroup = filterData.some(x => lineData.some(y => y?.key?.startsWith(x)));
	const foundKey = lineData.find(x => x && x.key === key);

	return lineFromGroup && !foundKey ? true : false;
};

const filterTypeNotZero = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;
	const { keys } = lineFilter;

	const isZeroOrMissing = keys.some((key) => {
		const foundKey = lineData.find(x => x && x.key === key);

		return foundKey && _.toSafeInteger(foundKey?.value) !== 0;
	});

	return isZeroOrMissing ? true : false;
};

const filterTypeEntityNotZero = (pyData, lineFilter) => {
	if (!pyData || !_.isArray(pyData) || !lineFilter) return false;
	const { keys } = lineFilter;

	const isZeroOrMissing = keys.some((key) => {
		const foundKey = findFromPYData(pyData, { name: key });

		return foundKey && _.toSafeInteger(foundKey) !== 0;
	});

	return isZeroOrMissing ? true : false;
};

const filterTypeKeysWithMatchingValue = (lineData, lineFilter) => {
    if (!lineData || !_.isArray(lineData) || !lineFilter) return false;
    const { keys, includesFields } = lineFilter;
    
    const foundFields = keys.map((key) => lineData.find(x => x && x.key === key));
    const foundValues = foundFields.map((pyData) => pyData?.value);
    const hasMatchingValues = foundValues.every((element, index, array) => array[0] === element && !_.isNil(element));
    
    if (!hasMatchingValues) return false;

    let hasFields = true;

    if (includesFields) {
        hasFields = includesFields.some((x) => lineData.find((y) => y.key === x));
    }

    return hasFields ? true : false;
};

const FILTER_TYPE_FUNCTIONS = {
	// Filter Types for PR row data
	containsAny: filterTypeContainsAny,
	containsKey: filterTypeContainsKey,
	linesNotContainsKey: filterTypeLinesNotContainsKey,
	containsKeyGroup: filterTypeContainsKeyGroup,
	notInKeyGroup: filterTypeNotInKeyGroup,
	containsAnyValue: filterTypeContainsAnyValue,
	inGroupNotZero: filterTypeNotZero,
	keysWithMatchingValue: filterTypeKeysWithMatchingValue,
	// Filter Types for PY Entities
	inEntityNotZero: filterTypeEntityNotZero,
	lineMatchingOnly: filterTypeLineMatchingOnly,
};

const filterLine = (lineData) => (lineFilter) => {
	const { filterType } = lineFilter;
	const filterTypeFunction = FILTER_TYPE_FUNCTIONS[filterType];

	if (filterTypeFunction) {
		return filterTypeFunction(lineData, lineFilter);
	}

	return false;
};

const lineFilterAny = (curr, lineFilters) => {
	const lineFilterResult = lineFilters.map(filterLine(curr)).reduce((prevRes, currRes) => prevRes || currRes, false);
	return lineFilterResult ? true : false;
};

const lineFilterOmitAny = (curr, lineFilters) => {
	const lineFilterResult = lineFilters.map(filterLine(curr)).reduce((prevRes, currRes) => prevRes || currRes, false);
	return !lineFilterResult ? true : false;
};

const LINE_FILTER_FUNCTIONS = {
	any: lineFilterAny,
	omitAny: lineFilterOmitAny,
};

const filterGroupLines = (dataLines, lineFilterType, lineFilters) => {
	const lineFilterFunction = LINE_FILTER_FUNCTIONS[lineFilterType];
	if (!lineFilterType || !lineFilterFunction) return dataLines;

	const filteredList = dataLines.reduce((prev, curr) => {

		if (lineFilterFunction(curr, lineFilters)) {
			prev.push(curr);
		}

		return prev;
	}, []);

	return filteredList;
};

const matchesCondition = (dataLine, lineFilterType, lineFilters) => {
	const lineFilterFunction = LINE_FILTER_FUNCTIONS[lineFilterType];
	if (!lineFilterType || !lineFilterFunction) return false;

	return lineFilterFunction(dataLine, lineFilters);
};

export {
	filterTypeContainsAny,
	filterTypeContainsKey,
	filterTypeLinesNotContainsKey,
	filterTypeContainsKeyGroup,
	filterTypeContainsAnyValue,
	filterTypeNotZero,
	filterTypeKeysWithMatchingValue,
	filterLine,
	lineFilterAny,
	lineFilterOmitAny,
	filterGroupLines,
	matchesCondition,
	filterTypeLineMatchingOnly,
};