import { GridCell } from 'react-md';

const LineItemHeader = ({ item, width, index }) => {
    if (width < 1025 && item.colTablet === 0) return <></>;
            
    // const headerCol = width < 768 && item.colPhone ? item.colPhone : 
    //     width < 1025 && item.colTablet ? item.colTablet : item.col;
    const headerCol = item.col;
    const headerLabel = item?.label ?? ''; 
    
    if (item.styles) {
        item.styles.whiteSpace = 'normal';
    }

    return (
        headerCol !== 0 &&
        <GridCell 
            key={`lbl-${index}-d`} 
            colSpan={headerCol}
            className={
                item.align==='right' ? 'headerLabelsRight' : 
                    item.align==='left' ? 'headerLabelsLeft' : 
                        item.align==='noPaddingLeft' ? 'headerLabelsButtonLeft' :
                            'headerLabelsLeft'
            }
            style={item.styles}
        >
            {headerLabel}
        </GridCell>
    );
};

export default LineItemHeader;