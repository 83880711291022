/* eslint-disable no-unused-vars */
import React from 'react';
import { GridCell } from 'react-md';
import LabelText from '../labelText';
import MultiSelect from '../multiSelect';
import OptionButton from '../optionButton';
import TextAreaBox from '../textArea';
import MultiSelectBlue from '../multiSelectBlue/index';
import DropDown from '../dropDown';
import MultiSelectDropdown from '../multiSelectDropdown';
import ViewButton from '../viewButton';
import DateField from '../fields/DateField';
import UploadField from '../fields/uploadField'
import DriversLicenseField from '../fields/DriversLicenseField';
import EinField from '../fields/EinField';
import EmailField from '../fields/EmailField';
import IrsPinField from '../fields/IrsPinField';
import MoneyField from '../fields/MoneyField';
import SsnField from '../fields/SsnField';
import TelephoneField from '../fields/TelephoneField';
import TotalField from '../fields/TotalField';
import ZipField from '../fields/ZipField';
import ZipPostalField from '../fields/ZipPostalField';
import AlphaNumericField from '../fields/AlphaNumericField';
import NumberField from '../fields/NumberField';
import ReadOnlyField from '../fields/ReadOnlyField';
import FreeTextField from '../fields/FreeTextField';
import NameField from '../fields/NameField';
import VinField from '../fields/VinField';
import YearField from '../fields/YearField';
import DetailsGroup from '../detailsGroup';
import DropDownGroup from '../dropdownGroup';
import YearPicker from '../fields/YearPicker';
import PercentField from '../fields/PercentField';

const fieldMap = {
    'name': NameField, //letters, space, hyphen -, apostrophe'
    'accountNumber': NumberField,
    'alphanumeric': AlphaNumericField, //letters and numbers and space
    'checkbox': MultiSelect,
    'checkboxLabel': MultiSelect,
    'date': DateField,
    'driversLicense': DriversLicenseField,
    'ein': EinField,
    'email': EmailField,
    'freeText': FreeTextField, // letters, numbers and symbols
    'irspin': IrsPinField,
    'label': LabelText,
    'money': MoneyField, // $ sign and right aligned 
    'number': NumberField, //numbers that are left aligned
    'percent': PercentField, //right aligned with % symbol, max of 100, 2 decimal places
    'readOnly': ReadOnlyField, //disabled, uneditable PY amount 
    'radio': OptionButton,
    'select': DropDown,
    'multiSelectDropdown': MultiSelectDropdown,
    'ssn': SsnField,
    'telephone': TelephoneField,
    'textarea': TextAreaBox,
    'total': TotalField, //amount that are not money: miles, sq ft etc. no $ sign but right aligned
    'vin': VinField,
    'year': YearField, //year that is accepting from 1950 to current year + 1
    'yearPicker': YearPicker,
    'zip': ZipField,
    'zippostal': ZipPostalField,
    'upload': UploadField
};

const buildField = (field, colspan, index, group, isFormLocked, fns, section, parentGroup, parentIndex, grandParentGroup, allSections) => {
    if (typeof fieldMap[field.type] !== 'undefined') {
        return <GridCell colSpan={colspan} key={`cell-${field.name}`}>
            {React.createElement(
                fieldMap[field.type],
                { key: `tb-${field.name}-${index}`, field, colspan, index, group, isFormLocked, fns, section, parentGroup, parentIndex, grandParentGroup, allSections }, [])
            }
        </GridCell>;
    } else {
        return null;
    }
};

const buildTextArea = (field, colspan, index, group, isFormLocked, fns, section, parentGroup, parentIndex) => (<TextAreaBox key={`tb-${field.name}-${index}`} field={field} colspan={colspan} index={index} group={group} isFormLocked={isFormLocked} fns={fns} section={section} parentGroup={parentGroup} parentIndex={parentIndex} />);

const buildSelect = (field, colspan, index, group, isFormLocked, fns, section, parentGroup, parentIndex, grandParentGroup) => (<DropDown key={`db-${field.name}-${index}`} field={field} colspan={colspan} index={index} group={group} isFormLocked={isFormLocked} fns={fns} section={section} parentGroup={parentGroup} parentIndex={parentIndex} grandParentGroup={grandParentGroup} />);

const buildMultiSelectDropdown = (field, colspan, index, group, isFormLocked, fns, section, parentGroup, parentIndex, grandParentGroup) => (<MultiSelectDropdown key={`multiSelectdb-${field.name}-${index}`} field={field} colspan={colspan} index={index} group={group} isFormLocked={isFormLocked} fns={fns} section={section} parentGroup={parentGroup} parentIndex={parentIndex} grandParentGroup={grandParentGroup} />);

const buildCheckbox = (field, colspan, index, group, isFormLocked, fns, section, parentGroup, parentIndex, grandParentGroup) => (<MultiSelect key={`ms-${field.name}-${index}`} field={field} colspan={colspan} index={index} group={group} isFormLocked={isFormLocked} fns={fns} section={section} parentGroup={parentGroup} parentIndex={parentIndex} grandParentGroup={grandParentGroup} />);

const buildLabel = (field, colspan, index, group, fns) => (<LabelText key={`lbl-${field.name}`} field={field} colspan={colspan} index={index} group={group} fns={fns} />);

const buildRadio = (field, colspan, index, group, isFormLocked, fns, section, parentGroup, parentIndex, grandParentGroup) => (<OptionButton key={`ob-${field.name}`} field={field} colspan={colspan} index={index} group={group} isFormLocked={isFormLocked} fns={fns} section={section} parentGroup={parentGroup} parentIndex={parentIndex} grandParentGroup={grandParentGroup} />);

const buildViewButton = (field, colspan, index, group, isFormLocked, fns) => (<ViewButton key={`vb-${field.name}`} field={field} fns={fns} group={group} index={index} viewSectionId={field.viewSectionId} buttonType={field.buttonType} isFormLocked={isFormLocked} />);

const buildBlueCheckboxCtrl = (fields, index) => (<MultiSelectBlue key={`multi-select-blue-${index}`} field={fields} />);

const buildDropdownGroup = (fields, index) => (<DropDownGroup key={`dropdown-group-${index}`} field={fields} />);

const buildDetailsCtrl = (group) => (<DetailsGroup key={`details-group-${group.groupId}`} group={group} />);

const buildBlueDivider = () => (<div className='formBlueDivider'></div>);
const buildGroupDivider = () => (<div className='formGroupDivider'></div>);

export {
    buildTextArea,
    buildLabel,
    buildSelect,
    buildMultiSelectDropdown,
    buildCheckbox,
    buildRadio,
    buildBlueCheckboxCtrl,
    buildDetailsCtrl,
    buildBlueDivider,
    buildGroupDivider,
    buildViewButton,
    buildField,
    buildDropdownGroup
};