import { Validators } from 'cla-formrenderer';

const refundPaymentFn = (year) => {

    const sections = [
        {
            title: 'Refund / Payment Preferences',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    groupType: 'blueBox',
                    isSingleColumn: true,
                    fields: [
                    ]
                },
                {
                    groupId: 2,
                    fields: [
                        { label: 'REFUND:', name: 'refundLabel', type: 'label', col: 12 },
                        { label: 'I want to use direct deposit for refunds, if applicable.', name: 'IFDSDIRD.2Label', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'IFDSDIRD.2', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, axcessTranslate: 'YNDKtoXCheckbox' },
                        { label: 'I want to apply my 2021 overpayment to my 2022 estimated tax liability, if applicable.', name: 'taxLiabilityLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'taxLiability', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },
                        { label: 'TAX PAYMENT:', name: 'taxPaymentLabel', type: 'label', col: 12 },
                        { label: 'I want to discuss with my tax preparer the option to use electronic withdrawal (direct debit) for any balance(s) due.', name: 'taxPreparerOptionLabel', type: 'label', col: 9 },
                        { label: 'Yes/No', name: 'taxPreparerOption', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3 },
                    ],
                },
                {
                    groupId: 10,
                    fields: [
                        { label: 'Please confirm the accuracy of the banking information below for your 2021 tax return.', name: 'bankingInfoLabel', type: 'label', prior: true, col: 9 },
                        { label: 'Confirmed/Requires Change', name: 'bankingInfo', type: 'select', lookup: 'CONFIRMED_REQUIRESCHANGE', col: 3 }
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 11,
                    axcessGroup: {
                        pull: {
                            fromSections: [
                                'Direct Deposit/Electronic Funds Withdrawal',
                            ],
                            fieldPrefixes: ['IFDSDIRD'],
                        },
                    },
                    fields: [
                        {
                            label: 'Bank Name',
                            wrapLabel: 'Bank / Financial Institution Name',
                            name: 'IFDSDIRD.3',
                            type: 'freeText',
                            placeholder: 'Enter Bank Name',
                            prior: true,
                            maxLength: 35,
                            isPriorEditable: true
                        },
                        {
                            label: 'Routing No',
                            name: 'IFDSDIRD.4',
                            type: 'number',
                            placeholder: 'Enter Number',
                            prior: true,
                            maxLength: 9,
                            canPaste: false,
                            validations: {
                                ...Validators.isRoutingNumber('Invalid check digit, or only 9 digits starting with 01-12 or 21-32')
                            },
                            isPriorEditable: true,
                            logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSDIRD.5'] }]
                        },
                        {
                            label: 'Account Number',
                            name: 'IFDSDIRD.5',
                            type: 'accountNumber',
                            placeholder: 'Enter Number',
                            prior: true,
                            maxLength: 17,
                            canPaste: false,
                            isMaskOnBlur: true,
                        },
                        {
                            label: 'Account Type',
                            name: 'IFDSDIRD.13',
                            type: 'select',
                            lookup: 'ACCOUNT_TYPE',
                            placeholder: 'Select',
                            prior: true,
                            //axcessTranslate: 'accountType',
                            isPriorEditable: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSDIRD.13'],
                                fn: 'accountTypePull',
                                defaultIfEmpty: 'Checking (Default)',
                            }
                        },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Bank / Financial Institution Name', smallScreenLabel: 'Bank Details', col: 4, colTablet: 2, },
                            { label: 'Routing No', col: 2, colTablet: 1, },
                            { label: 'Account Number', col: 3, colTablet: 2, },
                            { label: 'Account Type', col: 2, colTablet: 2, },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'New Bank / Financial Institution', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'na', },
                        ]
                    }
                }
            ]
        },
    ];

    return sections;
};

export default refundPaymentFn;