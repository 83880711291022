import { setConditionalFieldValidation } from '@components/formRenderer/triggers';

const setDependentsSummaryLabel = (options) => {
    const { group, parentGroup, parentIndex } = options;
    let name = '';
    let relationship = '';
    const details = group.fields.filter(field => field.type !== 'ssn' && field.type !== 'date');
    const inputState = [];
    details.forEach((item)=>{
        if(item.inputState){
            inputState.push(item.inputState);
        }
    });
    const relation = details.pop();
    const filteredNames = details.map((item) => item.default && item.default !== ' ' && item.default).filter(item => item);
    
    if (filteredNames) name = filteredNames.join(' '); 
    if (relation && relation.default) relationship = relation.default; 

    if (name.length > 20) {
        name = `${name.substring(0, 17)}...`;
    }
    
    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === 'dependentSummary') {
            lineItemField.default = `${name}\n${relationship}`;
            if(inputState.length > 0){
                lineItemField.inputState = inputState[0];
            }
        }
    });
}

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
    case 'setConditionalFieldValidation':
        setConditionalFieldValidation(options);
        break;
    case 'setDependentsSummaryLabel': 
        setDependentsSummaryLabel(options);
        break;
    default:
        break;
    }
};

export {
    sectionFieldLogic
};