'use strict';

// common modules
import axios from 'axios';
import { ManagedIdentityCredential } from '@azure/identity';

// global
let token;
let tokenExpiry = Date.now();

// =============================================================================
// API client for Client HQ Todo API
// =============================================================================
const client = axios.create({
  baseURL: process.env.REACT_APP_CHQ_TODO_API_BASE_URL,
  timeout: 60000,
  headers: {
    Accept: 'application/json'
  }
});

const todoPrefix = `/todos`;
const errorSchema = '1040API.ClientHqTodoApi';

/* istanbul ignore next */
client.interceptors.request.use(async (config) => {
  try {
    if (!token || Date.now() > tokenExpiry) {
      if (
        process.env.NODE_ENV === 'development' ||
        process.env.NODE_ENV === 'test'
      ) {
        token = {
          token:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvaWQiOiIwMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAiLCJzY3AiOiJVc2Vycy5SZWFkIFVzZXJzLldyaXRlIFVzZXJzLk1hbmFnZSBEb2N1bWVudHMuUmVhZCBEb2N1bWVudHMuV3JpdGUgRG9jdW1lbnRzLk1hbmFnZSBQdWxsUmVxdWVzdHMuUmVhZCBSZXBvcnRzLkdlbmVyYXRlIFRvZG9zLk1hbmFnZSBUb2Rvcy5SZWFkIiwicm9sZXMiOlsiQWRtaW5pc3RyYXRvciIsIkFwcGxpY2F0aW9uIiwiQ2xpZW50Il19.WOPHneEyI93HZ9gxR4DVqrD4uCh8yT7PxoSKu9t-CLM',
          expiresOnTimestamp: Date.now() + 3600000,
        };
      } else {
        const credential = new ManagedIdentityCredential();
        token = await credential.getToken(
          `${process.env.REACT_API_CHQ_TODO_API_BASE_URL_ID_URI}.default`
        );
      }
    }

    config.headers.Authorization = `Bearer ${token.token}`;

    return config;
  } catch (error) {
    console.error(`${errorSchema}.interceptors`, `Error message ${error.message} Error stack ${error.stack}`);
  }
});

const getTodos = async () => {
  return await client.get(`${todoPrefix}`);
}

export default {
    getTodos,
};
