import _ from 'lodash';
import { filterPYByIdentifier, findFromData, findSection } from '@utilities/axcessTaxPull';
import { isMatchingEntity } from '@utilities/populatePriorData/parsePriorEntityData';

const CHECKBOX_DETAIL_BUTTON = 'checkboxAndDetails';
const MATCH_TYPE_FIRST = 'first';

const setMatchedSubWorksheetData = (matchingData, setSectionAsSubSection, subWorkSheet) => {
    const matchedSection = matchingData?.data?.find(x => !_.isNil(setSectionAsSubSection) && x?.name === setSectionAsSubSection);
    subWorkSheet?.push(matchedSection || null);
};

const setNoMatchSubWorksheetData = (subWorkSheet) => {
    subWorkSheet.push(null);
};

const setCheckboxWithMatch = (group, primarySectionData, checkboxButtonName, matchingData, checkWithSection) => {
    if (!checkboxButtonName) return;
    const { fields } = group;

    if (!checkboxButtonName) return;
    if (checkWithSection && !matchingData?.data?.find(x => x?.name === checkWithSection)) return;

    const subSectionField = fields?.find(x => x?.name === checkboxButtonName);

    if (subSectionField?.name && subSectionField?.prior && subSectionField?.buttonType === CHECKBOX_DETAIL_BUTTON) {
        primarySectionData?.fields?.push({ key: subSectionField.name, value: true });
    }
};

const populateSections = (primaryPriorYearData, priorYearData) => (group) => {
    const { entityIdentifiers, subWorkSheets } = group;
    
    if (!entityIdentifiers || !_.isArray(entityIdentifiers)) return;

    if (!subWorkSheets.length) {
        entityIdentifiers.forEach(() => subWorkSheets.push([])); //Prep data array
    }

    entityIdentifiers.forEach((entityIdentifier, entityIndex) => {
        const { checkboxButtonName, identifiers: matchIdentifiers } = entityIdentifier;

        if (!matchIdentifiers || !_.isArray(matchIdentifiers)) return;

        subWorkSheets[entityIndex].push([]);
        const entitySSIndex = subWorkSheets[entityIndex].length - 1;
        const entitySubSectionSheet = subWorkSheets[entityIndex][entitySSIndex];

        primaryPriorYearData.forEach((pyEntityData) => {
            // Push at least one object per row, per entity
            let hasPushedData = false;

            matchIdentifiers.forEach((matchIdentifier) => {
                if (hasPushedData) return;

                const {
                    matchToKey,
                    matchToSection,
                    matchToType,
                    matchType,
                    isEntity,
                    setSectionAsSubSection,
                    checkWithSection,
                    ...matchOnProps
                } = matchIdentifier;

                const matcherPYEntities = priorYearData.filter(filterPYByIdentifier(matchOnProps));

                const primarySection = findSection(pyEntityData?.data, matchToSection);
                const primaryDataSetsToMatch = findFromData(primarySection, matchToKey, matchToType);

                primaryDataSetsToMatch?.forEach((primaryDataSetToMatch) =>  {
                    if (!primaryDataSetToMatch) return;
                    const { value: primaryValueToMatch } = primaryDataSetToMatch;

                    if (!primaryValueToMatch) return;

                    if (matchType === MATCH_TYPE_FIRST) {
                        const matchingEntity = matcherPYEntities.find(isMatchingEntity(primaryValueToMatch, matchOnProps));

                        if (matchingEntity) {
                            setMatchedSubWorksheetData(matchingEntity, setSectionAsSubSection, entitySubSectionSheet);
                            setCheckboxWithMatch(group, primarySection, checkboxButtonName, matchingEntity, checkWithSection);
                            hasPushedData = true;
                        } else {
                            setNoMatchSubWorksheetData(entitySubSectionSheet);
                            hasPushedData = true;
                        }
                    }
                });
            });

            if (!hasPushedData) {
                setNoMatchSubWorksheetData(entitySubSectionSheet);
            }
        });
    });
};

export default populateSections;

export {
    populateSections,
    // Exported for testing
    setMatchedSubWorksheetData,
    setNoMatchSubWorksheetData,
    setCheckboxWithMatch,
};