export const EVENT_KEY_CANADA = 'CA';
export const EVENT_KEY_UNITED_STATES = 'US';
export const EVENT_KEY_MEXICO = 'MX';
export const EVENT_KEY_PROVINCES = 'PROVINCES';
export const EVENT_KEY_STATES = 'STATES';
export const EVENT_KEY_1 = 'Single';
export const EVENT_KEY_2 = 'Married filing jointly (even if only one had income)';
export const EVENT_KEY_3 = 'Head of household';
export const EVENT_KEY_4 = 'Qualifying widow(er)';
export const EVENT_KEY_5 = 'Married filing separately - itemized';
export const EVENT_KEY_6 = 'Married filing separately - not itemized';
export const EVENT_KEY_7 = 'Married filing separately - spouse not filing';
export const EVENT_KEY_YES = 'Yes';
export const EVENT_KEY_NO = 'No';
export const EVENT_KEY_EMPTY = '';
export const EVENT_KEY_NA = 'n/a';
export const EVENT_KEY_MARRIED = 'Married';
export const EVENT_KEY_SINGLE = 'Single';
export const EVENT_KEY_WIDOWED = 'Widowed';
export const EVENT_KEY_FOREIGN = 'Foreign';
export const EVENT_KEY_SSN = 'SSN / ITIN';
export const EVENT_KEY_EIN = 'EIN';