// common modules
import React, { useEffect, useState } from 'react';
import { CircularProgress } from "@react-md/progress";
import { useParams, useHistory } from "react-router-dom";

// custom modules
import { handleDownloadClick, getChecklist } from '@utilities/services/checklistService';
import { DEFAULT } from '@utilities/constants/navigation';

function ChqChecklistDownload(props) {
    const { orgId } = useParams();
    const history = useHistory();
    const [hasDownloaded, setHasDownloaded] = useState(false);

    const downloadChecklist = async (orgId, filename) => {
        if (hasDownloaded) return;
        setHasDownloaded(true);
        handleDownloadClick(orgId, filename)
            .then(() => {
                window.location.href = process.env.REACT_APP_CHQ_WEB_TODO_URL;
            });
    }

    useEffect(() => {
        if (orgId) {
            getChecklist(orgId).then((checklistData) => {
                if (checklistData) {
                    downloadChecklist(orgId, checklistData.name);
                } else {
                    history.push(DEFAULT);
                }
            })
        }
        else {
            console.error(`ORGANIZATION ID IS REQUIRED.`)
            history.push(DEFAULT);
        }
    }, [orgId]);

    return (
        hasDownloaded
            ? <></>
            : <CircularProgress id="loading-progress" />
    );
}

export default ChqChecklistDownload;