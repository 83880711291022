import { WAGES_W2_UPLOAD } from '@utilities/constants/strings';

const wagesFn = (year) => {
    const sections = [
        {
            title: 'Wages',
            isVisible: true,
            collapseClass: 'collapsibleForm',
            sectionId: 1,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label' },
                    ]
                },
                {
                    groupId: 1,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSW2.0', type: 'select', lookup: 'PAYER_TS', placeholder: 'Select', prior: true },
                        {
                            label: 'Payer',
                            name: 'IFDSW2.6',
                            type: 'freeText',
                            maxLength: 76,
                            placeholder: 'Enter Payer',
                            prior: true,
                        },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSW2.4', type: 'label', isTotal: true, prior: true },
                        { label: '', name: '', type: 'label', },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'W-2 Information', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Payer', col: 5, colTablet: 3, align: 'left' },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { col: 2, colTablet: 0, align: 'right' },
                            { label: 'W-2', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Wages', icon: 'add' },
                            { type: 'label', label: 'Total', col: 6, colTablet: 5, },
                            { type: 'totalMoney', name: 'IFDSW2.4', col: 3, colTablet: 2, year: 'prior', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: WAGES_W2_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
    ];

    return sections;
};

export default wagesFn;