// common modules
import React, { useEffect, useState, useReducer, useRef } from 'react';
import _ from 'lodash';
import api from '@utilities/claApi';
// MUI imports
import { Box, TextField } from '@mui/material';
import { useToggle } from 'react-md';
import './emailInvites.css';
import {
  DataGridPro, gridClasses, useGridApiRef
} from '@mui/x-data-grid-pro';
//MUI pagination
import CustomToolbar from '@components/organizerList/customToolbar';
// custom modules
import * as NAV from '@utilities/constants/navigation';
import Breadcrumb from '@components/Breadcrumb.js';
import { MAX_PREFETCH_LIMIT, DEFAULT_FILTER_QUERY, DEFAULT_SORT_QUERY, OPERATORS_MAP, DEFAULT_PAGE_LIMIT, SORT_TO_DATA_MAP, FILTER_TO_DATA_MAP } from '@utilities/constants/welcomeEmailInvites.js';
import useServerSideMUI from '@utilities/hooks/useServerSideMUI';
import moment from 'moment-timezone';
import ActivateDeactivateWelcomeEmail from '@components/welcomeEmail/activateDeactivate';

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function reducer(state, action) {
  switch (action.type) {
    case 'ACTIVATE_EMAIL_INVITES':
      return { ...state, activateEmailInvites: action.payload };
    case 'DEACTIVATE_EMAIL_INVITES':
      return { ...state, deactivateEmailInvites: action.payload };
    case 'CONFIRMED_EMAIL_INVITES':
      return { ...state, confirmedEmailInvites: action.payload };
    case 'SELECTED_CONFIRMED_EMAIL_INVITE_ITEMS':
      return { ...state, selectedConfirmedEmailInviteItems: action.payload };
  }
}
function CountInputValue(props) {
  const { item, applyValue, focusElementRef } = props;


  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >
      <TextField
        name="custom-text-filter-operator"
        placeholder="Filter value"
        label="Filter value"
        variant="standard"
        value={Number(item.value)}
        onChange={handleFilterChange}
        type="number"
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
    </Box>
  );
}

function TextInputValue(props) {
  const { item, applyValue, focusElementRef } = props;


  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >
      <TextField
        name="custom-text-filter-operator"
        placeholder="Filter value"
        label="Filter value"
        variant="standard"
        value={item.value}
        onChange={handleFilterChange}
        type="text"
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
    </Box>
  );
}

function ManageEmailInvites(props) {
  const [filterButtonEl, setFilterButtonEl] = React.useState(null);
  const [isLoaded, enable, disable] = useToggle(false);
  const [visibleSuccessDialog, showSuccessDialog, hideSuccessDialog] = useToggle(false);
  const [paginatedLoad, setPaginatedLoad] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [cslStatus, setCslStatus] = useState('');
  const previousOrganizerQuery = useRef(null);
  const [prefetchedData, setPrefetchedData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [buttonAction, setButtonAction] = useState('');
  const [state, dispatch] = useReducer(reducer, { activateEmailInvites: false, deactivateEmailInvites: false, confirmedEmailInvites: true, selectedConfirmedEmailInviteItems: null });
  const [selectedConfirmedEmailInviteItems, setSelectedConfirmedEmailInviteItems] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const apiRef = useGridApiRef();
  const payload = {
    defaultFilter: DEFAULT_FILTER_QUERY,
    defaultSort: DEFAULT_SORT_QUERY,
    defaultPageSize: DEFAULT_PAGE_LIMIT,
    filterDataMap: FILTER_TO_DATA_MAP,
    sortDataMap: SORT_TO_DATA_MAP,
    operatorsMap: OPERATORS_MAP,
    defaultPrefetchSize: MAX_PREFETCH_LIMIT,
  };
  const onSelectionEmailInviteModalChange = (item) => {
    const selectedItems = new Set(item);
    setSelectedConfirmedEmailInviteItems(selectedItems);
    const rows = data.filter((row) => selectedItems.has(row.id));
    dispatch({ type: 'SELECTED_CONFIRMED_EMAIL_INVITE_ITEMS', payload: selectedItems });
    dispatch({ type: 'CONFIRMED_EMAIL_INVITES', payload: item.length > 0 });
  };
  const {
    handleFilterModelChange,
    handleSortModelChange,
    handlePageModelChange,
    handlePageSizeChange,
    currentPageNumber,
    filterQueryParameters,
    filterMethodParameters,
    sortQueryParameters,
    prefetchSize,
    currentFilterModel,
    currentSortModel,
  } = useServerSideMUI(payload);
  const filterHighlight = currentFilterModel?.items?.[0]?.value ? 'filterButtonHighlight' : 'filterButton';

  const pageSize = 15;

  const columnsGrid = [
    { field: 'name', headerName: 'CSL', type: 'text', width: 550, editable: false, canEdit: false, required: true, },
    {
      field: 'activeUserCount',
      headerName: 'User Count at Activation',
      type: 'text',
      width: 150,
      editable: false,
      canEdit: false,
      required: true,
      sortComparator: (a, b) => {
        return Number(a.replaceAll(',', '')) - Number(b.replaceAll(',', ''));
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'text',
      width: 300,
      editable: false,
      canEdit: false,
      // valueFormatter: (params) => formatStatus(params.value),
      sortComparator: (a, b) => {
        const x = a.replaceAll('Inactive — ', '').replaceAll('Active — ', '').replace(' at', '')
          .replaceAll('/', '-').replace(' CST', '').replace('PM', ' PM').replace('AM', ' AM');
        const y = b.replaceAll('Inactive — ', '').replaceAll('Active — ', '').replace(' at', '')
          .replaceAll('/', '-').replace(' CST', '').replace('PM', ' PM').replace('AM', ' AM');

        // return (new Date(moment(x, 'MM-DD-YYYY LT')).getTime() - new Date(moment(y, 'MM-DD-YYYY LT')).getTime()) && 
        return a[0].toLocaleLowerCase() < b[0].toLocaleLowerCase() ? -1 : a[0].toLocaleLowerCase() > b[0].toLocaleLowerCase() ? 1 : 0;
      },
    },
  ];

  const navItems = [
    { to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
    { to: NAV.WELCOME_EMAIL_INVITES, label: 'Welcome Email Invites', current: true }
  ];

  const buildOrganizerQuery = (limit, offset, sorting, filterMethod, filtering) => {
    const filterQuery = filtering?.length ? filtering.map(x => `&filter=${x}`).join('') : '';
    const filterMethodQuery = filterMethod ? `&filterMethod=${filterMethod}` : '';
    const sortQuery = sorting && Object.entries(sorting).length ? Object.entries(sorting).map(([param, query]) => `&${param}=${query}`).join('') : '';
    return `${sortQuery}${filterMethodQuery}${filterQuery}&limit=${limit}&offset=${offset}`;
  };
  useEffect(() => {
    if (selectionModel.length === 0) {
      dispatch({ type: 'ACTIVATE_EMAIL_INVITES', payload: false });
      dispatch({ type: 'DEACTIVATE_EMAIL_INVITES', payload: false });
    }
  }, [selectionModel]);

  const syncSelectedRows = (filtered) => {
    const updatedSelectedRows = selectedRows.filter(row => filtered.some(filteredRow => filteredRow.id === row.id));
    setSelectedRows(updatedSelectedRows);
  };

  // Update filteredRows whenever currentFilterModel changes
  useEffect(() => {
    // This effect will trigger when currentFilterModel changes via handleFilterModelChange
    if (prefetchedData.length) {
      const updatedFilteredRows = prefetchedData.filter(row => {
        return currentFilterModel.items.every(filterItem => {
          if (!filterItem.value) return true;
         
          switch (filterItem.operator) {
            
            case 'contains':
              return row[filterItem.field]?.toString().includes(filterItem.value);
            case 'equals':
              return row[filterItem.field]?.toString() === filterItem.value;
            case 'greaterThan':
              return Number(row[filterItem.field]) > Number(filterItem.value);
            case 'lessThan':
              return Number(row[filterItem.field]) < Number(filterItem.value);
            default:
              return true;
          }
        });
      });
      setFilteredRows(updatedFilteredRows);
      syncSelectedRows(updatedFilteredRows);
      setTotalResults(updatedFilteredRows.length);
    }
  }, [currentFilterModel, prefetchedData]);

  useEffect(() => {
    if (!isLoaded) {
      setPaginatedLoad(true);
      const totalOffset = currentPageNumber * pageSize;
      const prefetchOffset = Math.floor(totalOffset / prefetchSize) * prefetchSize;
      const prefetchPageOffset = totalOffset % prefetchSize;
      const organizerQuery = buildOrganizerQuery(prefetchSize, prefetchOffset, sortQueryParameters, filterMethodParameters, filterQueryParameters);
      // If the resulting queries are the same, grab organizer data from the prefetched set
      if (previousOrganizerQuery.current !== null && organizerQuery === previousOrganizerQuery.current) {
        const resultSlice = prefetchedData.slice(prefetchPageOffset, prefetchPageOffset + pageSize);
        setData(resultSlice);
        syncSelectedRows(resultSlice);
        setPaginatedLoad(false);
        enable();
        return;
      }
      // Call API here to get Clients and Invite status
      api.get(`/service/locations`).then((response) => {
        return response.data;
      }).then(response => {
        const { results, total } = response;

        results.forEach((csl, x) => {
          csl.activeUserCount = formatNumberWithCommas(csl.activeUserCount);
          csl.status = `${csl.status} ${csl.updatedAt ? '— ' + moment(csl.updatedAt).tz('America/Chicago').format('MM/DD/YYYY h:mm A') + ' CST' :
            csl.createdAt ? '— ' + moment(csl.createdAt).tz('America/Chicago').format('MM/DD/YYYY h:mm A') + ' CST' : ''}`;
         csl.status = csl.status.trim();
            csl.id = x;
        });

        const resultSlice = results.slice(prefetchPageOffset, prefetchPageOffset + pageSize);
        setPrefetchedData(results);
        setData(resultSlice);
        syncSelectedRows(resultSlice);
        setTotalResults(total);
      }).finally(() => {
        enable();
        setPaginatedLoad(false);
      });
    }
  }, [sortQueryParameters, filterMethodParameters, filterQueryParameters, isLoaded, pageSize, prefetchSize, currentPageNumber]);

  useEffect(() => {
    syncSelectedRows(filteredRows);
  }, [filteredRows]);

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 30 : 6,
      bottom: params.isLastVisible ? 9 : 6,
    };
  }, []);

  const extractDate = (text) => {
    const pattern = /\b\d{2}\/\d{2}\/\d{4}\b/;

    const match = text.match(pattern);

    if (match) {
      return match[0];
    } else {
      return null;
    }
  };

  const countOnlyOperators = [
    {
      label: 'contains',
      value: 'contains',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return value.value.replace(',', '').includes(filterItem.value);
        };
      },
      InputComponent: TextInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'equals',
      value: 'equals',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return Number(value.value.replace(',', '')) === Number(filterItem.value);
        };
      },
      InputComponent: CountInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'is greater than',
      value: 'greaterThan',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return Number(value.value.replace(',', '')) >= Number(filterItem.value);
        };
      },
      InputComponent: CountInputValue,
      InputComponentProps: { type: 'number' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'is less than',
      value: 'lessThan',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return Number(value.value.replace(',', '')) <= Number(filterItem.value);
        };
      },
      InputComponent: CountInputValue,
      InputComponentProps: { type: 'number' },
      getValueAsString: (value) => `${value}`,
    },
  ];

  const statusOnlyOperators = [
    {
      label: 'contains',
      value: 'contains',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return value.value.includes(filterItem.value);
        };
      },
      InputComponent: TextInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'equals',
      value: 'equals',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return value.value === filterItem.value;
        };
      },
      InputComponent: TextInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'is before',
      value: 'before',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return moment(extractDate(value.value)).isBefore(moment(filterItem.value));
        };
      },
      InputComponent: TextInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'is after',
      value: 'after',
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (value) => {
          return moment(extractDate(value.value)).isAfter(moment(filterItem.value));
        };
      },
      InputComponent: TextInputValue,
      InputComponentProps: { type: 'text' },
      getValueAsString: (value) => `${value}`,
    },
  ];

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'status',
      sort: 'asc'
    }
  ]);

  const columns = React.useMemo(
    () =>
      columnsGrid.map((col) => {
        if (col.field === 'count') {
          return {
            ...col,
            filterOperators: countOnlyOperators,
          };
        } else if (col.field === 'status') {
          return {
            ...col,
            filterOperators: statusOnlyOperators,
          };
        } else {
          return col;
        }
      }),
    [columnsGrid],
  );

  const activateSelectedRows = async () => {
    const cslList = selectedRows.map((row) => ({ _id: row._id }));
    const payload = { cslList: cslList, status: 'Active' };
    api.post(`/service/locations/status`, payload)
      .then(() => {
        // save was successful
        setCslStatus('success');
        showSuccessDialog();
        disable();
      })
      .catch((error) => {
        showSuccessDialog();
        setCslStatus('failure');
        console.log(error);
      });
  };
  const deactivateSelectedRows = async () => {
    const cslList = selectedRows.map((row) => ({ _id: row._id }));
    const payload = { cslList: cslList, status: 'Inactive' };
    api.post(`/service/locations/status`, payload).then(() => {
      // save was successful
      setCslStatus('success');
      showSuccessDialog();
      disable();
    })
      .catch((error) => {
        showSuccessDialog();
        setCslStatus('failure');
        console.log(error);
      });
  };

  const onSelectionModelChange = (item) => {
    const selectedItems = new Set(item);
    setSelectedConfirmedEmailInviteItems(selectedItems);
    const rows = filteredRows.filter((row) => selectedItems.has(row.id));
    if (rows.length > 0) {
      let allActive = true, allInactive = true;
      for (let i = 0; i < rows.length; i++) {
        let status = rows[i].status;
        if (status.includes('Inactive')) {
          allActive = false;
        }
        else {
          allInactive = false;
        }
      }
      dispatch({ type: 'DEACTIVATE_EMAIL_INVITES', payload: allInactive });
      dispatch({ type: 'ACTIVATE_EMAIL_INVITES', payload: allActive });
    }
    else {
      dispatch({ type: 'DEACTIVATE_EMAIL_INVITES', payload: false });
      dispatch({ type: 'ACTIVATE_EMAIL_INVITES', payload: false });
    }
    setSelectedRows(rows);
    setSelectionModel(item);
  };

  // GROWTH: Refactor the DataGridPro component to a generic/reusable component
  return (
    <div className="pracDashboardSize">
      <Breadcrumb items={navItems} />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '95%', margin: '0 auto' }}>
        <h1 style={{ color: '#2D3C59' }}>Welcome Email Invites</h1>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <button
            disabled={selectionModel.length === 0 || !state.activateEmailInvites}
            class={selectionModel.length !== 0 && state.activateEmailInvites ? 'emailInvites-deactivate' : 'emailInvites-disable-deactivate'}
            style={{ margin: '0 5px' }} onClick={() => {
              setButtonAction('deactivate');
              setDialogVisible(true);
            }}>
            Deactivate
          </button>
          <button
            disabled={selectionModel.length === 0 || !state.deactivateEmailInvites}
            class={selectionModel.length !== 0 && state.deactivateEmailInvites ? 'emailInvites-activate' : 'emailInvites-disable-activate'}
            style={{ margin: '0 5px' }} onClick={() => {
              setButtonAction('activate');
              setDialogVisible(true);
            }}>
            Activate
          </button>
        </div>
      </div>
      <Box sx={{ height: 'auto', width: '100%' }} data-testid='pracdash-columnheader'>
        <DataGridPro
          apiRef={apiRef}
          checkboxSelection
          selectionModel={selectionModel}
          onRowSelectionModelChange={onSelectionModelChange}
          rowSelectionModel={selectionModel}
          loading={paginatedLoad}
          filterMode={'client'}
          disableMultipleColumnsFiltering={false}
          filterModel={currentFilterModel}
          onFilterModelChange={(filterModel) => {
            handleFilterModelChange(filterModel);
            disable();
          }}
          disableColumnPinning
          filterDebounceMs={300}
          getRowSpacing={getRowSpacing}
          rows={filteredRows}
          columns={columns}
          pageSizeOptions={[pageSize, 30]}
          columnVisibilityModel={{}}
          disableColumnResize={true}
          disableColumnSelector
          disableMultipleRowSelection={false}
          pageSize={pageSize}
          currentPageNumber={currentPageNumber}
          paginationMode={'client'}
          rowCount={totalResults}
          onPaginationModelChange={(props) => {
            handlePageSizeChange(props.pageSize);
            handlePageModelChange(props.page);
            disable();
          }}
          sortingMode={'client'}
          paginationModel={{ pageSize: pageSize, page: currentPageNumber }}
          sortModel={sortModel}
          initialState={{
            ...data.initialState,
            sorting: {
              ...data.initialState?.sorting,
              sortModel: sortModel
            }
          }}
          onSortModelChange={(sortModel) => {
            setSortModel(sortModel);
            disable();
          }}
          localeText={{ toolbarFilters: "" }}
          autoHeight={true}
          rowHeight={98}
          pagination
          disableVirtualization
          getDetailPanelHeight={({ row }) => 'auto'}
          slots={{
            toolbar: CustomToolbar,
          }}

          slotProps={{
            panel: {
              anchorEl: filterButtonEl,
            },
            toolbar: {
              setFilterButtonEl,
              filterHighlight
            },

          }}
          sx={{
            [`& .MuiDataGrid-columnsPanel > div:first-child`]: { display: "none" },
            [`& .${gridClasses.row}`]: {
              bgcolor: '#ffffff',
              borderRadius: 1,
              margin: 4,
              width: '91%',
              border: 0.25,
              borderColor: '#E5E5E5'
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .MuiDataGrid-columnHeadersInner': {
              margin: 4
            },
            '& .MuiDataGrid-detailPanelToggleCell': {
              padding: 3
            },
            '& .MuiDataGrid-detailPanel': {
              marginLeft: 4,
              marginRight: 5,
              width: '95.2%',
            },
            '& .MuiDataGrid-columnSeparator--sideRight': {
              display: 'none'
            },
            '& .MuiDataGrid-columnHeaderCheckbox, & .MuiDataGrid-cellCheckbox': {
              marginLeft: '25px'
            },
            '& .MuiDataGrid-columnHeadersInner .Mui-checked': {
              color: '#fff',
            },
            '& .MuiDataGrid-columnHeadersInner .MuiSvgIcon-root': {
              color: '#fff'
            },
          }}
        />
      </Box>
      <ActivateDeactivateWelcomeEmail
        dialogVisible={dialogVisible}
        setDialogVisible={setDialogVisible}
        cslData={selectedRows}
        handleDeactivation={deactivateSelectedRows}
        handleActivation={activateSelectedRows}
        action={buttonAction}
        setSelectedRows={setSelectedRows}
        setSelectionModel={setSelectionModel}
        deactivateSelectedRows={deactivateSelectedRows}
        activateSelectedRows={activateSelectedRows}
        selectedConfirmedEmailInviteItems={selectedConfirmedEmailInviteItems}
        onSelectionEmailInviteModalChange={onSelectionEmailInviteModalChange}
        hideSuccessDialog={hideSuccessDialog}
        cslStatus={cslStatus}
        clsStatusDialog={visibleSuccessDialog} />
    </div>
  );
}
export default ManageEmailInvites;