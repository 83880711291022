// TO BE DISCUSS IF THIS WILL BE PART OF FORM RENDERER LIBRARY
import {
	Grid,
	GridCell,
	FileInput,
	Form,
	FontIcon,
	DropdownMenu,
	TextIconSpacing,
	// Button, 
	Divider,
	LinearProgress
} from 'react-md';
import { MoreVertSVGIcon } from '@react-md/material-icons';
import nextId from 'react-id-generator';
import moment from 'moment';
import { frameworkSetter } from '../../../hooks/usePageFramework';
import BlobFileViewer from '../../dialogBox/blobFileViewer/index';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import React from 'react';
import { ACCEPTED_FILE_TYPES } from './../../../constants/document';

function UploadDrawer(props) {
	const { dispatch, ACTION, currentCardKey, onFileRemoval, selectState, REDUX } = frameworkSetter.usePageFramework();
	const isProgressVisible = selectState(REDUX.UPLOAD_PROGRESS_VISIBLE);
	const { sectionName, uploadList, drawerStyle, onUpload, filteredUploadList, isFormLocked } = props;
	const sectionUploadList = filteredUploadList ? filteredUploadList : uploadList ? uploadList.filter(x => x?.section?.toLowerCase().trim() === sectionName?.toLowerCase().trim()).sort((a,b) => new Date(b.createdOn) - new Date(a.createdOn)) : [];
	const { width } = useWindowDimensions();
	const dashKey = 'clientDashboard';
	const formName = currentCardKey ? currentCardKey : dashKey;
	const lineUploadItems = (file) => {
		return [
			{
				children: 'Remove File',
				onClick: (event) => onFileRemoval(event, file),
                className: 'removeUpload'
			}
		];
	};

	const onChange = (event) => onUpload(event, sectionName);

	const getScreenCol = (col) => {
		const colTab = col === 10 ? 6 : col === 6 ? 3 : col === 3 ? 2 : col === 2 ? 1 : col;
		return width < 1025 ? colTab : col;
	};

	const style = drawerStyle ? drawerStyle : 'uploadDrawerContainer';
	const isLineUploadDrawer = style === 'lineUploadDrawerContainer';

	const uploadedFileList = sectionUploadList.map((file, idx) =>
		<React.Fragment key={idx}>
			<Grid key={`${sectionName}-${idx}`} id={`${sectionName}-${nextId()}`} className='uploadDrawerRow'>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
				<GridCell className='fileName' colSpan={getScreenCol(6)}>
					<BlobFileViewer
						key={`blobFileViewer-${idx}`}
						id={nextId()}
						className="fileViewDetails"
						blobFile={file}
						fileName={file.name}
					/>
				</GridCell>
				<GridCell className='fileName' colSpan={getScreenCol(isLineUploadDrawer ? 2 : 3)}>
					{file.uploaded_by || 'None'}
				</GridCell>
				<GridCell className='fileName' colSpan={getScreenCol(isLineUploadDrawer ? 1 : 2)}>
					{moment(new Date(file.createdOn)).format('MMM DD, YYYY') || 'None'}
				</GridCell>
				<GridCell colSpan={1} className="fileList">
					<DropdownMenu
						disabled={isFormLocked}
						key={`dpmenu-${idx}`}
						id={nextId()}
						items={lineUploadItems(file)}
						buttonType="icon"
					>
						<MoreVertSVGIcon />
					</DropdownMenu>
				</GridCell>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
			</Grid>
			<Grid>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
				<GridCell colSpan={getScreenCol(isLineUploadDrawer ? 10 : 12)}>
					{isProgressVisible && idx === sectionUploadList.length - 1 ?
						<LinearProgress
							id={`upload-linear-progress-${idx}`}
							barStyle={{ backgroundColor: '#76d2d3' }}
							className='uploadLinearProgress'
							min={0}
							max={100}
							value={null}
						/> : <Divider />}
					{/* { isProgressVisible ?  <LinearProgress id='upload-linear-progress' barStyle={{backgroundColor:'#d8d8d8'}}/> : <LinearProgress id='upload-linear-progress' barStyle={{backgroundColor:'#76d2d3'}} className="cardLinearProgress"/>} */}
				</GridCell>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
			</Grid>
		</React.Fragment>
	);

	// const closeButton =  !drawerStyle ? null : (
	//     <GridCell colSpan={6} className='alignRight'>
	//         <Button className={'viewButtonCloseLabel'} onClick={() => { setIsDrawerClose(true); group.lineItems[index][0].drawerVisible = !group.lineItems[index][0].drawerVisible; fns.saveForm();}}>Close Uploads</Button>
	//     </GridCell>
	// );

	return (
		<div className={style}>
			<Grid className='uploadDrawerHeaderContainer'>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
				<GridCell colSpan={getScreenCol(isLineUploadDrawer ? 10 : 12)}><Divider /></GridCell>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
				<GridCell colSpan={getScreenCol(6)} className="headerLabelsLeft">
					File Name
				</GridCell>
				<GridCell colSpan={getScreenCol(isLineUploadDrawer ? 2 : 3)} className="uploadHeaderheaderLabelsLeft">
					Uploaded By
				</GridCell>
				<GridCell colSpan={getScreenCol(isLineUploadDrawer ? 1 : 2)} className="uploadHeaderheaderLabelsLeft">
					Date Uploaded
				</GridCell>
				<GridCell colSpan={1}></GridCell>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
				<GridCell colSpan={getScreenCol(isLineUploadDrawer ? 10 : 12)}><Divider /></GridCell>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
			</Grid>
			<div className={!isLineUploadDrawer ? 'uploadedListContainer' : null}>{uploadedFileList}</div>
			{/* remove the upload more and close details buttons on upload drawer as per reBranding designs? */}
			<Grid>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
				<GridCell colSpan={6}>
					<Form>
						<FileInput
							disabled={isFormLocked}
                            style={isFormLocked ? {cursor: 'default'} : {}}
							theme='clear'
							themeType='flat'
							id={sectionName}
							onChange={onChange}
							buttonType={'text'}
							disableIconSpacing={true}
							multiple={true}
							icon={<TextIconSpacing
								icon={
									<FontIcon className="addIcon">
										add
									</FontIcon>
								}>
							</TextIconSpacing>}
							className={'addMoreFile'}
							accept={ACCEPTED_FILE_TYPES}
						>
							Upload More Files
						</FileInput>
					</Form>
				</GridCell>
				{/* {closeButton} */}
				{/* <GridCell colSpan={12}>
                    <Divider />
                </GridCell>  */}
			</Grid>
		</div>
	);
}

export default UploadDrawer;