import { Validators } from 'cla-formrenderer';
import { SCHEDF_EQUIPMENT_UPLOAD } from '@utilities/constants/strings';

const farmIncomeFn = (year) => {
    const sections = [
        {
            title: 'Farm Information',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    isVisible: true,
                    fields: [
                        {
                            label: 'Name of Business', name: 'IFDSFHDR.4', type: 'freeText', col: 6, isEntityName: true, placeholder: 'Enter Business Name', prior: true, isPriorEditable: true,
                            logicFunction: [
                                { trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSFGEN.0', 'IFDSFHDR.1'] }
                            ],
                        },
                        {
                            label: 'Principal Crop/Activity', name: 'IFDSFGEN.0', type: 'freeText', col: 6, placeholder: 'Enter crop/activity', prior: true, isPriorEditable: true,
                            logicFunction: [
                                { trigger: 'setIFDSFGEN8Required', isDifferentGroup: true },
                                { trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSFHDR.4'] }
                            ]
                        },
                        { label: 'Taxpayer/Spouse/Joint', name: 'IFDSFHDR.1', type: 'select', lookup: 'PAYER', col: 6, placeholder: ' Select', prior: true, isPriorEditable: true },
                        {
                            label: 'Employer ID', name: 'IFDSFGEN.10', type: 'ein', col: 6, placeholder: 'Enter Employer ID#', axcessTranslate: 'employerId', isPriorEditable: true,
                            prior: true,
                            validations: {
                                ...Validators.ein(),
                            }
                        }
                    ]
                },
                {
                    groupId: 2,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        //TODO: should be current date year 
                        { label: 'Did you pay any non-employee individuals or business more than $600 during 2021?', name: 'payNonEmployeeLabel', type: 'label', col: 10 },
                        {
                            label: 'Yes/No', name: 'IFDSFGEN.8', type: 'select', lookup: 'YESNO', col: 2, axcessTranslate: 'validStatementBX', logicFunction: [
                                { trigger: 'setFileAllFormsSchedF', isDifferentGroup: true },
                                { trigger: 'setIFDSFGEN9Required', isDifferentGroup: true },
                            ]
                        },
                        // Axcess ID: IFDSCGEN.31. Required field if 'Payments made during tax...' is marked Yes
                    ]
                },
                {
                    groupId: 3,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'If you answered \'Yes\' to the above question, did you file all required information tax forms (i.e. 1099-MISC)?', name: 'payNonEmployee2Label', type: 'label', col: 10 },
                        { label: '', name: 'IFDSFGEN.9', type: 'select', lookup: 'YESNO', col: 2, placeholder: 'Select', axcessTranslate: 'validStatementBX', },
                    ]
                },
                {
                    groupId: 4,
                    groupType: 'details',
                    fields: [
                        { label: 'To provide this farm\'s income and expenses, please either upload files above or enter information below.  If you uploaded files above, you do not need to complete the farm income and expenses sections below.', type: 'label', class: 'groupDetails3' },
                    ]
                },
            ]
        },
        {
            title: 'Farm Income',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 2,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Please provide any 1099-MISC/NEC/PATR/K/G forms that you received. If you received other income not reported to you on these forms, please include the payer and income below.', type: 'label' }, // required field - but only for 1 of these 3 lines
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'If you would like to provide additional income information by source or payer, please do so.  Otherwise, go ahead and enter your total income on one line.', type: 'label' }, // required field - but only for 1 of these 3 lines
                    ]
                },
                {
                    groupId: 3,
                    groupType: 'details',
                    fields: [
                        { label: 'Sales of Livestock and Other Items Bought for Resale (Cash Method Only)', type: 'label', class: 'labelTextSubTitle' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 4,
                    fields: [
                        { label: 'Description', name: 'IFDSFINC.0', type: 'freeText', placeholder: ' Enter Description', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Cost or Other Basis', name: 'IFDSFINC.2', type: 'label', isTotal: true, prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount Received', name: 'IFDSFINC.1', type: 'label', isTotal: true, prior: true },
                        { label: 'Cost or other Basis', wrapLabel: '2020 Cost or Other Basis', name: 'C-IFDSFINC.2', type: 'money', isTotal: true, placeholder: '0' },
                        { label: 'Amount Received', wrapLabel: '2020 Amount Received', name: 'C-IFDSFINC.1', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Description', col: 3, colTablet: 2, align: 'left' },
                            { label: '2020 Cost or other Basis', col: 2, colTablet: 1, align: 'right' },
                            { label: '2020 Amount Received', col: 2, colTablet: 1, align: 'right' },
                            { label: '2021 Cost or other Basis', col: 2, colTablet: 1, align: 'right' },
                            { label: '2021 Amount Received', col: 2, colTablet: 2, align: 'right' },
                            { col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Sales of Livestock and other items bought', icon: 'add' },
                            { type: 'label', label: 'Total', col: 3, colTablet: 2 },
                            { type: 'totalMoney', name: 'IFDSFINC.2', col: 2, colTablet: 1, year: 'prior', labelText: 'Cost or other Basis', label: '$ 0' },
                            { type: 'totalMoney', name: 'IFDSFINC.1', col: 2, colTablet: 1, year: 'prior', labelText: 'Amount Received', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSFINC.2', col: 2, colTablet: 1, year: 'current', labelText: 'Cost or other Basis', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSFINC.1', col: 2, colTablet: 2, year: 'current', labelText: 'Amount Received', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                },
                {
                    groupId: 5,
                    groupType: 'details',
                    fields: [
                        { label: '', type: 'label', class: 'groupDetails2' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 6,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Income', 'Miscellaneous Income (IRS 1099-MISC)', 'Government'],
                            fieldPrefixes: ['IFDSFINC', 'IFDSMISC'],
                            mergeWithOtherData: [
                                { section: 'Income', id: 'Sch F / 4835 - Farm', worksheet: 'General', key: 'IFDSFHDR.4' },
                            ],
                            lineFilterType: 'any',
                            lineFilters: [
                                { key: 'IFDSFINC.24', filterType: 'containsKey' },
                                { key: 'IFDSFINC.25', filterType: 'containsKey' },
                                {
                                    keys: ['IFDSMISC.42', 'IFDSFHDR.4'],
                                    includesFields: ['IFDSMISC.5', 'IFDSMISC.4', 'IFDSMISC.9', 'IFDSMISC.20'],
                                    filterType: 'keysWithMatchingValue'
                                },
                            ],
                        }
                    },
                    fields: [
                        {
                            label: 'Type of Income', name: 'IFDSFINC.24', type: 'freeText', class: 'labelRequired',
                            prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSFINC.24', 'IFDSMISC.5'],
                                fn: 'anyValidPull',
                            }
                        },
                        {
                            label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSFINC.25', type: 'label', isTotal: true,
                            prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSFINC.25', 'IFDSMISC.4', 'IFDSMISC.9', 'IFDSMISC.20'],
                                fn: 'sumAllPull',
                            }
                        },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSFINC.25', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' },
                    ],
                    prePopulate: [
                        {
                            label: 'Sales of livestock, produce, grains, etc. that you raised',
                            prePopulateAugments: [{ name: 'IFDSFINC.10' }, { name: 'IFDSFINC.10' },],
                        },
                        {
                            label: 'Co-operative distributions (Form 1099-PATR)',
                            prePopulateAugments: [{ name: 'IFDSFINC.11' }, { name: 'IFDSFINC.11' },],
                        },
                        {
                            label: 'Agricultural program payments (Form 1099-G)',
                            prePopulateAugments: [{ name: 'IFDSFINC.13' }, { name: 'IFDSFINC.13' },],
                        },
                        {
                            label: 'CCC loans elected as income',
                            prePopulateAugments: [{ name: 'IFDSFINC.15' }, { name: 'IFDSFINC.15' },],
                        },
                        {
                            label: 'Crop insurance proceeds and certain disaster payments received in 2020',
                            prePopulateAugments: [{ name: 'IFDSFINC.18' }, { name: 'IFDSFINC.18' },],
                        },
                        {
                            label: 'Custom hire (machine work) income',
                            prePopulateAugments: [{ name: 'IFDSFINC.21' }, { name: 'IFDSFINC.21' },],
                        },
                        {
                            label: 'Crop insurance proceeds deferred from prior year',
                            prePopulateAugments: [
                                { name: 'IFDSFINC.20' },
                                { name: 'IFDSFINC.20', sameValue: true, calculateKeys: ['IFDSCHF.94', 'IFDSCHF.25'], calculateFn: 'farmCropInsurancePull', currentModifiers: { axcessTranslate: 'parseMoneyValue' } }
                            ],
                            lineOptions: { staticLine: true }
                        },
                        {
                            label: 'Federal fuel tax credit from the prior year',
                            prePopulateAugments: [
                                { name: 'IFDSFINC.22', calculateKeys: ['IFDSFINC.22', 'IFDSFINC.23'], calculateFn: 'farmGasFuelPull', currentModifiers: { axcessTranslate: 'parseMoneyValue' } },
                                { name: 'IFDSFINC.22', sameValue: true, calculateKeys: ['GFD4136.30'], calculateFn: 'farmGasFuelPull', currentModifiers: { axcessTranslate: 'parseMoneyValue' } }
                            ],
                            lineOptions: { staticLine: true }
                        },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    prePopulateIndexes: [{ priorIndex: 1 }, { currentIndex: 2 }],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Type of Income', smallScreenLabel: 'Income Details', col: 7, colTablet: 3, align: 'left' },
                            { label: '2020 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Income Type', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDSFINC.25', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSFINC.25', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                },
            ]
        },
        {
            title: 'Farm Expenses',
            isVisible: true,
            sectionId: 3,
            subSections: [
                { subSectionId: 4, isShowSubSecTotalOnMainSec: false },
            ],
            groups: [
                {
                    groupType: 'lineItem',
                    groupId: 1,
                    fields: [
                        { label: 'Type of Expense', name: 'IFDSFEXP.29', type: 'freeText', class: 'labelRequired', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSFEXP.30', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSFEXP.30', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    prePopulate: [
                        { label: 'Business meals', prePopulateAugments: [{ name: 'IFDSFEXP.25' }] },
                        { label: 'Entertainment (deductible only on some state returns)', prePopulateAugments: [{ name: 'IFDSFEXP.65' }] },
                        { label: 'Car and truck expenses', prePopulateAugments: [{ name: 'IFDSFEXP.0' }] },
                        { label: 'Chemicals', prePopulateAugments: [{ name: 'IFDSFEXP.1' }] },
                        { label: 'Conservation expenses', prePopulateAugments: [{ name: 'IFDSFEXP.63' }] },
                        { label: 'Custom hire (machine work)', prePopulateAugments: [{ name: 'IFDSFEXP.4' }] },
                        { label: 'Employee benefit programs', prePopulateAugments: [{ name: 'IFDSFEXP.6' }] },
                        { label: 'Feed purchased', prePopulateAugments: [{ name: 'IFDSFEXP.7' }] },
                        { label: 'Fertilizers and lime', prePopulateAugments: [{ name: 'IFDSFEXP.8' }] },
                        { label: 'Freight and trucking', prePopulateAugments: [{ name: 'IFDSFEXP.9' }] },
                        { label: 'Gasoline, fuel and oil', prePopulateAugments: [{ name: 'IFDSFEXP.10' }] },
                        { label: 'Insurance (other than health)', prePopulateAugments: [{ name: 'IFDSFEXP.11' }] },
                        { label: 'Interest - mortgage', prePopulateAugments: [{ name: 'IFDSFEXP.12' }] },
                        { label: 'Interest - other', prePopulateAugments: [{ name: 'IFDSFEXP.13' }] },
                        { label: 'Labor hired', prePopulateAugments: [{ name: 'IFDSFEXP.14' }] },
                        { label: 'Pension and profit-sharing plans', prePopulateAugments: [{ name: 'IFDSFEXP.15' }] },
                        { label: 'Rent or lease - vehicle, machines and equipment', prePopulateAugments: [{ name: 'IFDSFEXP.16' }] },
                        { label: 'Rent or lease - other (land, animals, etc)', prePopulateAugments: [{ name: 'IFDSFEXP.17' }] },
                        { label: 'Repairs and maintenance', prePopulateAugments: [{ name: 'IFDSFEXP.18' }] },
                        { label: 'Seeds and plants purchased', prePopulateAugments: [{ name: 'IFDSFEXP.19' }] },
                        { label: 'Storage and warehousing', prePopulateAugments: [{ name: 'IFDSFEXP.20' }] },
                        { label: 'Supplies purchased', prePopulateAugments: [{ name: 'IFDSFEXP.21' }] },
                        { label: 'Taxes', prePopulateAugments: [{ name: 'IFDSFEXP.22' }] },
                        { label: 'Utilities', prePopulateAugments: [{ name: 'IFDSFEXP.23' }] },
                        { label: 'Veterinary, breeding and medicine', prePopulateAugments: [{ name: 'IFDSFEXP.24' }] },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Expense Type', smallScreenLabel: 'Expenses Details', col: 7, colTablet: 3, align: 'left' },
                            { label: '2020 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Add Farm Expense', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDSFEXP.30', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSFEXP.30', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                },
            ]
        },
        {
            title: 'Farm Equipment and Property (See Next Section for Farm Vehicles)',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            isSubSection: true,
            sectionId: 4,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'For new assets or assets that you no longer have, you can upload supporting documentation here.', type: 'label', class: 'subSeCDetails' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Depreciation and Amortization (Form 4562)'],
                            fieldPrefixes: ['IFDSDEPR'],
                            lineFilterType: 'omitAny',
                            lineFilters: [
                                { key: 'IFDSDEPR.48', filterType: 'containsAnyValue' }
                            ],
                        },
                    },
                    fields: [
                        { label: 'Description', name: 'IFDSDEPR.1', type: 'freeText', placeholder: 'Enter Description', prior: true, logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['IFDSDEPR.18', 'IFDSDEPR.25', 'haveTheItem'] }] },
                        { label: 'Date Acquired', name: 'IFDSDEPR.18', type: 'date', prior: true, axcessTranslate: 'date', labelFormat: 'date' },
                        { label: 'Cost', name: 'IFDSDEPR.25', type: 'money', isTotal: true, hideFooter: true, prior: true, axcessTranslate: 'parseMoneyValue', }, // showToFooter used to get total cost with prior field
                        { label: 'Y/N', wrapLabel: 'Do you still have the asset?', name: 'haveTheItem', type: 'select', lookup: 'YESNO', logicFunction: [{ trigger: 'setConditionalFieldValidation', comparisonValue: 'No', requiredFields: ['IFDSDEPR.48', 'IFDSBPCT.24'] }] },
                        { label: 'Date Sold', name: 'IFDSDEPR.48', type: 'date', axcessTranslate: 'shortDate' },
                        { label: 'Sale Price', name: 'IFDSBPCT.24', type: 'money', isTotal: true, hideFooter: true, placeholder: '0', },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Description', smallScreenLabel: 'Farm Equipment and Property Details', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Date Acquired', col: 2, colTablet: 1, align: 'left' },
                            { label: 'Cost', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Do you still have the asset?', col: 1, colTablet: 1, align: 'left' },
                            { label: 'Date Sold', col: 2, colTablet: 1, align: 'left' },
                            { label: 'Sale Price', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Invoice / Receipt', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, colTablet: 2, buttonTitle: 'Additional Farm Equipment and Property', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'upload', uploadSectionName: SCHEDF_EQUIPMENT_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        // {  
        //     title: 'Vehicle Expenses',
        //     sectionId: 5,
        //     sectionType: 'vehicleEntity',
        //     isVisible: true,
        //     entities: [],
        //     colMapping: [
        //         { name: 'b', entityName: 'IFDSFDPR.6'},
        //         { name: 'c', entityName: 'IFDSFDPR.1'},
        //         { name: 'd', entityName: 'IFDSFDPH.0'},
        //         { name: 'e', entityName: 'IFDSFDAE.8'},
        //         { name: 'f', entityName: 'IFDSFDAE.1'},
        //         { name: 'i', entityName: 'IFDSFDOI.2'},
        //         { name: 'j', entityName: 'IFDSFDOI.0'},
        //         { name: 'k', entityName: 'IFDSFDAE.5'},
        //         { name: 'l', entityName: 'IFDSFDAE.7'},
        //         { name: 'm', entityName: 'IFDSFDAE.4'},
        //         { name: 'n', entityName: 'IFDSFDAE.3'},
        //         { name: 'o', entityName: 'IFDSFDAE.0'},
        //         { name: 'q', entityName: 'IFDSFLPQ.1'}, // required field -  if they have autos
        //         { name: 'r', entityName: 'IFDSFLPQ.2'} // required field -  if they have autos
        //     ]
        // },
        {
            title: 'Vehicle Expenses',
            sectionId: 5,
            sectionType: 'newVehicleEntity',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            entities: [],
        },
        {
            title: 'Business Use of Home',
            isVisible: true,
            sectionId: 6,
            subSections: [
                { subSectionId: 7 },
            ],
            groups: [
                {
                    groupId: 1,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'During the current year, did you begin using part of your home exclusively for use in this business?', name: 'payNonEmployeeLabel', type: 'label', col: 10 },
                        { label: 'Yes/No', name: 'beginUsingHomeForFarmBusiness', type: 'select', lookup: 'YESNO', col: 2 }, // required field - (Only if did not exist in PY)
                    ]
                },
                {
                    groupId: 2,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'I would like to use the simplified method in 2021 ($5 deduction per square foot of the home that is used exclusively for business purposes)', name: 'payNonEmployee1Label', type: 'label', col: 10 },
                        { label: 'Yes/No', name: 'beginUsingHomeForBusiness', type: 'select', lookup: 'YESNO_DONTKNOW', col: 2, placeholder: 'Select' },
                    ]
                },
                {
                    groupId: 3,
                    isVisible: true,
                    hasDivider: false,
                    fields: [
                        { label: 'In 2021, were improvements made to the home and/or home office?', name: 'payNonEmployee2Label', type: 'label', col: 10 },
                        { label: 'Yes/No', name: 'madeImprovementsToFarmHome', type: 'select', lookup: 'YESNO', col: 2 }
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 4,
                    fields: [
                        { label: '', name: 'expenses', type: 'label', class: 'labelRequired' },
                        { label: '0', wrapLabel: '2019 SQ FT', name: 'PySqft', type: 'label', isTotal: true, isMoney: false },
                        { label: 'SQ FT', wrapLabel: '2020 SQ FT', name: 'sqft', type: 'total', isTotal: true, placeholder: '0', isMoney: false, },
                        { type: 'lineButtons' }
                    ],
                    prePopulate: [
                        { label: 'Total square footage of home', prePopulateAugments: [{ name: 'IFDS8829.9' }], isMoney: false }, // Axcess ID IFDS8829.9
                        { label: 'Square footage of home used exclusively for business', prePopulateAugments: [{ name: 'IFDS8829.8', currentModifiers: { logicFunction: [{ trigger: 'setBusUseHomeRequired', isDifferentGroup: true },] } }], isMoney: false, },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { col: 7, colTablet: 3, smallScreenLabel: 'Square Footage of Home Details', align: 'left' },
                            { label: '2020 Sq Footage', col: 2, colTablet: 2, align: 'right' },
                            { label: '2021 Sq Footage', col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [],
                        lineButtons: [
                            { button: 'more' }
                        ]
                    }
                },
            ]
        },
        {
            title: 'Home Expenses',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            isSubSection: true,
            sectionId: 7,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Please provide the full amount of the following expense types associated with operating your home that is used in your business (do not apply a business use % to your amounts provided).', type: 'label', class: 'subSeCDetails' },
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 2,
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        { label: 'EXPENSE TYPE', name: 'IFDS8829.45', type: 'freeText', class: 'labelRequired', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDS8829.48', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDS8829.48', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' },
                    ],
                    prePopulate: [
                        { label: 'Mortgage interest', prePopulateAugments: [{ name: 'IFDS8829.32' }] },
                        { label: 'Real estate taxes', prePopulateAugments: [{ name: 'IFDS8829.36' }] },
                        { label: 'Insurance', prePopulateAugments: [{ name: 'IFDS8829.40' }] },
                        { label: 'Qualified mortgage insurance premiums', prePopulateAugments: [{ name: 'IFDS8829.92' }] },
                        { label: 'Repairs and maintenance', prePopulateAugments: [{ name: 'IFDS8829.42' }] },
                        { label: 'Utilities', prePopulateAugments: [{ name: 'IFDS8829.44' }] },
                        { label: 'Rent', prePopulateAugments: [{ name: 'IFDS8829.78' }] },
                    ],
                    prePopulateControls: ['label', 'label', 'number'],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'EXPENSE TYPE', smallScreenLabel: 'Home Expenses Details', col: 7, colTablet: 3, align: 'left' },
                            { label: '2020 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: '2021 Amount', col: 2, colTablet: 2, align: 'right' },
                            { label: '', col: 1, colTablet: 1, align: 'left' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Home Expenses', icon: 'add' },
                            { type: 'label', label: 'Total for this Home:', col: 7, colTablet: 3 },
                            { type: 'totalMoney', name: 'IFDS8829.48', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDS8829.48', col: 2, colTablet: 2, year: 'current', label: '$ 0', isSubSectionTotal: true },
                        ],
                        lineButtons: [
                            { button: 'more' },
                        ]
                    }
                }
            ]
        },
    ];

    return sections;
};

export default farmIncomeFn;