// common modules
import {
	Button, Form, FileInput,
	Grid,
	Divider,
	GridCell,
} from 'react-md';
import { useState, useEffect } from 'react';
import { AddSVGIcon, RemoveSVGIcon } from '@react-md/material-icons';

// custom modules
import usePageFramework from '@utilities/hooks/usePageFramework';
import DocumentManagerUploadList from '@components/documentManager/DocumentManagerUploadList';
import { ACCEPTED_FILE_TYPES_1040 } from '@utilities/constants/document';
const DocumentManagerEntity = ({ entity, formKey, isLast }) => {
	const {
		selectState,
		REDUX,
		onFileUpload,
	} = usePageFramework();
	const uploadList = selectState(REDUX.UPLOAD_LIST) || [];
	const [fileCount, setFileCount] = useState(0);
	const [isCollapsed, setIsCollapsed] = useState(true);

	useEffect(() => {
		if (entity.uploads.length === 0) {
			setIsCollapsed(true);
		}

		setFileCount(entity.uploads.length);
    // eslint-disable-next-line
	}, [uploadList]);

	const onUpload = (event) => onFileUpload(event, null, entity.uploadSectionName, uploadList, { updateState: () => { } }, formKey);

	return (
		<Grid className='denseLineItemRow' style={{ marginTop: '-20px' }}>
			<GridCell colSpan={12} className='denseRowContainer'>
				<div className='denseRowBtnContainer' style={{ margin: '5px 13px 0 0' }} id={`doc-manager-entity-collapse-btn-container-${entity.uploadSectionName}`}>
					<Button
						className='denseRowBtn'
						buttonType='icon'
						id={`doc-manager-entity-collapse-btn-${entity.uploadSectionName}`}
						onClick={() => setIsCollapsed(!isCollapsed)}
						disabled={!entity.uploads.length > 0}
						disableRipple
					>
						{
							isCollapsed ? <AddSVGIcon className='denseRowBtn' /> :
								<RemoveSVGIcon className='denseRowBtn' />
						}
					</Button>

				</div>
				<Grid style={{ width: '100%', marginLeft: '-20px' }}>
					<GridCell colSpan={3} className='docManagerCell formName'>{entity.title}</GridCell>
					<GridCell colSpan={2} className='fileCountCellText'>
						{fileCount > 0 && fileCount}
					</GridCell>
					<GridCell colSpan={2} className='docManagerCell'></GridCell>
					<GridCell colSpan={3} className='docManagerCell'></GridCell>
					<GridCell
						colSpan={2}
						className='docManagerCell docManagerActionBtns'
						style={{ marginLeft: '9px' }}
					>
						<div className='docManagerActionBtns' style={{ marginTop: '-10px' }}>
							<Form>
								<FileInput
									className="expandFormBtn docManagerActionsPadding"
									id={`documentManager-${entity.uploadSectionName}`}
									onChange={onUpload}
									buttonType={'text'}
									theme='clear'
									themeType='flat'
									icon={null}
									multiple={true}
									accept={ACCEPTED_FILE_TYPES_1040}>
									Add Files
								</FileInput>
							</Form>
						</div>
					</GridCell>
					{<DocumentManagerUploadList
						key={entity.uploadSectionName}
						uploadList={entity.uploads}
						formKey={formKey}
						sectionKey={entity.uploadSectionName}
						isUnassigned={false}
						isCollapsed={isCollapsed} />}
					    {entity.isFormEntity ? <></> :
						<GridCell colSpan={12}>
							<Divider className='lineItemRowDivider' />
						</GridCell>}
					<Grid style={{ width: '100%' }}>
					</Grid>
				</Grid>
			</GridCell>
		</Grid>
	);
};

export default DocumentManagerEntity;