import { Grid } from 'react-md';

function MultiSelectBlue (props) {
    const { field } = props;
    
    if (!field) return (null);

    return (
        <>
            <div >
                <p className="multiSelectText1">Select all that apply:</p>
            </div>
            {
                field.length ? 
                <Grid className="multiSelectBlue" columns={1}>
                    {field}
                </Grid> : <></>
            }
        </>
    );
}

export default MultiSelectBlue;