import { useState, useEffect } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '@components/formRenderer';
import { sectionFieldLogic, triggeredEvent } from './higherEducationLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import * as NAV from '@utilities/constants/navigation';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import education2021 from './2021';
import education2022 from './2022';
import education2023 from './2023';
import education2024 from './2024';

let sections = [];

export const getHigherEducationSections = () => {
    return sections;
};

function Tuition(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.TUITION);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm)updateCard();

        const formTemplatesByYear = {
            2021: education2021(year),
            2022: education2022(year),
            2023: education2023(year),
            2024: education2024(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);
        sections = formSections;
        setSectionState(formSections);
        // eslint-disable-next-line
    }, []);

    const axcessIdentifiers = [
        { section: 'Credits', id: '8863' },
        { section: 'Income', id: 'Payments from Qualified Education' },
    ];

    return (
        <FormRenderer
            sections={sectionState}
            key="tuitionForm"
            formName={REDUX.HIGHER_EDUCATION}
            identifiers={axcessIdentifiers}
            sectionFieldLogic={sectionFieldLogic}
            triggeredEvent={triggeredEvent}
            isExportForm={isExportForm}
        />
    );
}

export default Tuition;
