import { Button, Collapse as ReactMDCollapse, FontIcon, TextIconSpacing } from 'react-md';
import { AddSVGIcon, ExpandLessSVGIcon, ExpandMoreSVGIcon, RemoveSVGIcon } from '@react-md/material-icons';
import React, { useEffect, useRef, useState } from 'react';
import { frameworkSetter } from '../../../../hooks/usePageFramework';
import { Element } from 'react-scroll';
import BondIDFixedDialog from '../../../dialogBox/bondIdFixedDialog';
import { useToggle } from '@react-md/utils';
import { SCREEN_BREAKPOINTS } from '../../../../constants/screenSizes.js';

const useStyles = (
    sectionType,
    title,
    hasLineItemSection,
    isFormExpandable,
    isCollapsed,
    isDenseRow,
    hasOrgs,
    appType,
    filteredGroups,
    filteredGroupItems,
    isEntryInterview,
    bondID
    ) => {
    const styles = { sectionHeaderTitle: 'sectionHeaderTitle' };

    if (sectionType === 'section') {
        styles.form = hasLineItemSection === undefined && !isEntryInterview ? 'formSize' :
            hasLineItemSection === undefined && isEntryInterview ? '' : 'lineItemSectionFormSize';
        if(hasLineItemSection === undefined && isFormExpandable){
            styles.form = 'lineItemSectionFormSize';
        }

        const withPadding = hasOrgs &&
            (filteredGroups.length === 0 && filteredGroupItems.length === 0) ? 'withPadding' : '';
        styles.denseRowSectionContainer = title === 'Occupant Details' ? 'noTopPaddingSectionContainer' :
        styles.denseRowSectionContainer = hasOrgs ? `compDetailsdenseRowSectionContainer ${withPadding}` :
            'denseRowSectionContainer';
        styles.helpIcon = 'helpIcon';
        if (isDenseRow) {
            styles.formTitle = bondID ? 'denseRowFormTitleBasicBond' : 'denseRowFormTitle';
            styles.denseRowBackground = 'denseRowBackground';
        }
        else styles.formTitle = 'formTitle';

        if (isCollapsed) styles.sectionHeaderContainer = appType !== '990' ?
            'denseRowSectionHeaderContainer' : 'denseRowSectionHeaderWithSummaryContainer';
        else styles.sectionHeaderContainer = 'sectionHeaderContainer';
    } else if (sectionType === 'subSection') {
        styles.form = 'formSubSection';
        styles.formTitle = 'formSubSectionTitle';
        styles.helpIcon = 'subSectionhelpIcon';
        styles.sectionHeaderContainer = 'subSectionHeaderContainer';
    }

    if (title.length > 50) {
        styles.sectionHeaderTitle = 'wideSectionHeaderTitle';
        styles.sectionHeaderTotalLabel = 'centeredSectionHeaderTotalLabel';
    } else {
        styles.sectionHeaderTotalLabel = !hasOrgs ? 'sectionHeaderTotalLabel' : 'sectionHeaderTotalsSummary';
    }

    return styles;
};

const FormBody = ({
    title,
    collapsed,
    collapseClass,
    renderHeaderTotal,
    handleClick,
    denseRowCollapsed,
    renderSections,
    sectionType,
    marginBottom,
    hasLineItemSection,
    width,
    isFormExpandable,
    setDenseRowCollapsed,
    lastDenseRowSection,
    isDenseRow,
    isFormNA,
    hasOrgs,
    groups,
    denseRowHeaders,
    isSectionHeaderHidden,
    isEntryInterview,
    bondID,
    parentGroup,
    parentIndex,
    hasNoLineItem,
    allSections
}) => {
    const { appType } = frameworkSetter.usePageFramework();
    const isCollapsed = isDenseRow ? denseRowCollapsed : isFormNA ? isFormNA : collapsed;
    const isDenseRowCollapsed = isDenseRow && denseRowCollapsed;
    const filteredGroups = groups?.filter(group => group.isVisible);
    const isLaptopOrDesktop = width >= SCREEN_BREAKPOINTS.LAPTOP;

    let filteredGroupItems = groups?.filter(group => group.groupType === 'groupItem');
    filteredGroupItems = filteredGroupItems
        ?.map(group => group.groupItems
            .map(item => item
                ?.filter(group => group.isVisible))
                .filter(items => items?.length > 0))
        .filter(items => items?.length > 0);

    const classes = useStyles(
        sectionType,
        title,
        hasLineItemSection,
        isFormExpandable,
        isDenseRowCollapsed,
        isDenseRow,
        hasOrgs,
        appType,
        filteredGroups,
        filteredGroupItems,
        isEntryInterview,
        bondID
    );
    const [showDenseRowIcon, setShowDenseRowIcon] = useState(true);
    const [visible, show, hide] = useToggle(false);
    const fixedTo = useRef(null);
    const denseRowBG = isDenseRow && {
        borderRadius: '4px',
        border: 'solid 1px rgba(151, 151, 151, 0.25)',
        margin: 0,
        position: 'relative'
    };
    const margin = denseRowCollapsed ? '0 30px 0 15px' : '-7px 30px 0 15px';
    const basicBondFlex = bondID ? 'basicBond' : '';
    const isDenseRowHeaderHidden = isDenseRow && allSections?.filter(sec => sec.isVisible).length === 1;

    useEffect(() =>
        setTimeout(
            () => setShowDenseRowIcon(denseRowCollapsed), 200),
    [denseRowCollapsed]);

    const renderDenseRowExpandCircleIcon = () => {
        const isTrue = isDenseRow && hasLineItemSection &&
            classes.form === 'lineItemSectionFormSize' &&
            !showDenseRowIcon && !hasNoLineItem;
        return isTrue && <div className="denseRowExpandCircleIcon"></div>;
    };

    const renderDenseRowBtn = () => {
        const denseRowBtnContainerClass = !isDenseRowHeaderHidden ? 'denseRowBtnContainer' : 'noDenseRowBtnContainer';
        const style = { marginTop: '13px', zIndex: 2 };

        return (
            isDenseRow &&
            <div className={denseRowBtnContainerClass} style={style}>
                {
                    !isDenseRowHeaderHidden &&
                    <>
                        {
                            showDenseRowIcon ? <AddSVGIcon className="denseRowBtn" onClick={() => setDenseRowCollapsed(false)}/> :
                                <RemoveSVGIcon className="denseRowBtn" onClick={() => setDenseRowCollapsed(true)}/>
                        }
                    </>
                }

            </div>
        );
    };

    const renderBondIdDialog = () => {
        let issuerName = '';
        let issuedDate = '';

        if (parentGroup) {
            if (parentGroup?.lineItems?.[parentIndex]) {
                parentGroup.lineItems[parentIndex]?.forEach(item => {
                    if (item.name === 'aboutIssuerSummary') {
                        issuerName = item.issuerName;
                    } else if (item.name === 'issueDetailsSummary') {
                        issuedDate = item.issuedDate;
                    }
                });
            } else if (parentGroup.groupType === 'bondsItem' && parentGroup?.bonds?.[parentIndex]) {
                let bond = parentGroup.bonds[parentIndex];
                if (bond.bondDetails) {
                    issuerName = bond.bondDetails.issuerName;
                    issuedDate = bond.bondDetails.issuedDate;
                }
            }
        }

        return (
            <BondIDFixedDialog
                fixedTo={fixedTo}
                visible={visible}
                hide={hide}
                issuerName={issuerName}
                issuedDate={issuedDate}
            />
        );
    }

    return (
        <Element id="containerElement">
            <Element name={title}>
                <div className={isDenseRow && 'formContainer'} id={title}>
                    {renderDenseRowBtn()}
                    <div
                        className={
                            !hasLineItemSection && !isFormExpandable ? width < 1390 && width > 1250 ? 'mediumDesktopFormSize' :
                                width < 1250 && width > 1025 ? 'smallDesktopFormSize' :
                                    width < 1025 ? 'tabletFormSize' : classes.form : classes.form}
                        style={isDenseRow ? {margin, backgroundColor: 'transparent'} : {}}
                    >
                        {isSectionHeaderHidden || isDenseRowHeaderHidden ? null : <div
                            className={classes.sectionHeaderContainer}
                            onClick={!isFormNA ? handleClick : null}
                            style={isDenseRow ? {padding: 0} : {}}
                        >
                            <div className={classes.sectionHeaderTitle}>
                                <div className='sectionHeaderTitleContainer'>
                                    <div>
                                        <p className={classes.formTitle}>{title}</p>
                                    </div>
                                    {
                                        !denseRowCollapsed && bondID &&
                                        <div className={basicBondFlex}>
                                            <Button
                                                id="text-button-1"
                                                theme="clear"
                                                className={classes.formTitle}
                                                ref={fixedTo}
                                                onClick={show}
                                                >
                                                <span
                                                    style={visible ? {color: '#7dd2d3'} : {color: 'inherit'}}
                                                >
                                                    {bondID}
                                                </span>
                                            </Button>
                                            {renderBondIdDialog()}
                                        </div>
                                    }
                                    <div>
                                        <FontIcon className={classes.helpIcon}>help</FontIcon>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.sectionHeaderTotalLabel}>
                                {!isDenseRow && collapsed ? renderHeaderTotal() : denseRowHeaders ? denseRowHeaders() : null}
                            </div>
                            {
                                !isDenseRow &&
                                <div className="expandFormContainer">
                                    <Button data-testid="collapsibleBtnId" className="expandFormBtn" onClick={handleClick} disabled={isFormNA}>
                                        {
                                            isLaptopOrDesktop
                                                ? <TextIconSpacing
                                                    icon={
                                                        collapsed ?
                                                            <ExpandMoreSVGIcon /> :
                                                            <ExpandLessSVGIcon />}
                                                    iconAfter
                                                >
                                                    {collapsed ? 'Expand' : 'Collapse'}
                                                </TextIconSpacing>
                                                : collapsed ?
                                                    <ExpandMoreSVGIcon /> :
                                                    <ExpandLessSVGIcon />
                                        }
                                    </Button>
                                </div>
                            }
                        </div>}
                        {
                            !isDenseRow ?
                                <ReactMDCollapse collapsed={isCollapsed} className={collapseClass}>
                                    <div style={marginBottom}>
                                        {renderSections()}
                                    </div>
                                </ReactMDCollapse> :
                                <ReactMDCollapse collapsed={isCollapsed} className={classes.denseRowBackground}>
                                    <div style={denseRowBG}>
                                        <div className={classes.denseRowSectionContainer}>
                                            {renderSections()}
                                        </div>
                                    </div>
                                </ReactMDCollapse>
                        }
                        {lastDenseRowSection && lastDenseRowSection.title === title && renderDenseRowExpandCircleIcon()}
                    </div>
                </div>
            </Element>
        </Element>
    );
};

export default FormBody;