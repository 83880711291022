import * as EVENT from '@utilities/constants/triggerKeys';

const setChildCareCountry = (options) => {
    const { section, field } = options;

    section.groups[5].fields[1].isVisible = field.default === EVENT.KEY_UNITED_STATES;
    section.groups[5].fields[2].isVisible = field.default !== EVENT.KEY_UNITED_STATES;
};

const setProviderTabName = (options) => {
    const { section } = options;

    // set the First and Last names as the Provider tab name
    var entityValue = section.groups[3].fields[0].default + ' ' + section.groups[3].fields[1].default;
    section.groups[2].fields[2].default = entityValue;
};

const setChildCareID = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name[0].groups[3].fields[3].isVisible = event === EVENT.EVENT_KEY_SSN;
    name[0].groups[3].fields[4].isVisible = event === EVENT.EVENT_KEY_EIN;

    setFormSections(name);
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'setChildCareID':
            setChildCareID(event, options);
            break;
    default:
        break;
    }
};


const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) { 
        case 'setChildCareCountry':
            setChildCareCountry(options);
        break;
        case 'setProviderTabName':
            setProviderTabName(options);
            break;
    default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};