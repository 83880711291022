import { EVENT_KEY_NO } from '@utilities/constants/triggerKeys';
import { formatSsn } from '@utilities/helpers/formatSsn';
import { setConditionalFieldValidation } from '@components/formRenderer/triggers';

const setIsDistributionsFullyQualifiedExpenses = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name[2].groups[2].isVisible = event === EVENT_KEY_NO;
    name[2].groups[1].fields[1].default = event;
    setFormSections(name);
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
    case 'setIsDistributionsFullyQualifiedExpenses':
        setIsDistributionsFullyQualifiedExpenses(event, options);
        break;
    default:
        break;
    }
};

const setBeneficiarySummary = (options) => {
    const { group, parentGroup, parentIndex } = options;
    let name = group.fields.find(field => field.name === 'designatedBeneficiary');
    let ssn = group.fields.find(field => field.type === 'ssn');
    const inputState = [];
    group.fields.forEach((item)=>{
        if(item.inputState && (item.name === 'designatedBeneficiary' || item.type === 'ssn')){
            inputState.push(item.inputState);
        }
    });

    if (name) name = name.default?.length > 18 ? `${name.default?.substring(0, 15)}...` : name.default;
    else name = '';

    if (ssn && ssn.default !== ' ') ssn = `SSN: ${ssn.prioryearValue ? ssn.overRideText : formatSsn(ssn.default)}`;
    else ssn = '';

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === 'beneficiarySummary') {
            lineItemField.default = `${name}\n${ssn}`;
            if(inputState.length > 0){
                lineItemField.inputState = inputState[0];
            }
        }
    });
}

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
    case 'setConditionalFieldValidation':
        setConditionalFieldValidation(options);
        break;
    case 'setBeneficiarySummary': 
        setBeneficiarySummary(options);
        break;
    default:
        break;
    }
};

export {
    sectionFieldLogic,
    triggeredEvent
};