const ErrorMessage = (props) => {
  const { text } = props;

  return (
    <div
      id='error-message'
      data-testid='error-message'
    >
      {text ?? ''}
    </div>
  );
};

export default ErrorMessage;