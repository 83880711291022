import { PROPERTY_TAXES_PAID_UPLOAD } from '@utilities/constants/strings';

const propertyTaxFn = (year) => {
    const sections = [
        {
            title: 'Taxes Paid',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Other Taxes Paid', 'Taxes Paid', 'State and Local Sales Tax Information'],
                            fieldPrefixes: ['IFDSIDTO', 'IFDSIDTP', 'IFDSIDLT'],
                            lineFilterType: 'any',
                            lineFilters: [
                                { key: 'IFDSIDTO.41', filterType: 'containsKey' },
                                { key: 'IFDSIDTO.40', filterType: 'containsKey' },
                                { key: 'IFDSIDTO.43', filterType: 'containsKey' },
                            ],
                        },
                    },
                    fields: [
                        { label: 'Type', name: 'IFDSIDTO.41', type: 'select', lookup: 'TAX_TYPE', prior: true, pullCalculation: { fn: 'propertyTaxPull', defaultIfEmpty: ' ' } },
                        { label: 'Description', name: 'IFDSIDTO.40', type: 'freeText', prior: true },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSIDTO.43', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSIDTO.43', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }

                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    prePopulate: [
                        { label: 'Personal property taxes', prePopulateAugments: [{ name: 'IFDSIDTP.31' }] },
                        { label: 'Real estate taxes', prePopulateAugments: [{ name: 'IFDSIDTP.24' }] },
                        { label: 'Sales tax', prePopulateAugments: [{ name: 'IFDSIDLT.6' }] },
                    ],
                    prePopulateIndexes: [{ priorIndex: 2, currentIndex: 3 }],
                    prePopulateControls: ['label', 'label', 'priorLabel', 'number'],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Type', smallScreenLabel: 'Tax Details', col: 3, colTablet: 2, },
                            { label: 'Description', col: 4, colTablet: 2, },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 1, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Taxes Paid', icon: 'add' },
                            { type: 'label', col: 7, colTablet: 4, label: 'Total' },
                            { type: 'totalMoney', name: 'IFDSIDTO.43', col: 2, colTablet: 1, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSIDTO.43', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: PROPERTY_TAXES_PAID_UPLOAD, viewSectionId: 0 }
                        ]
                    },
                },
            ]
        },
    ];

    return sections;
};

export default propertyTaxFn;