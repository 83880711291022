/* eslint-disable no-unused-vars */
import { useField } from '../useField';
import { FormMessage, TextField, Tooltipped, HoverModeProvider, } from 'react-md';
import { getHintText } from '../utils/getHintText';
import { useEffect, useState, useRef } from 'react';
import { isFieldDisabled } from '../utils/isDisabled';
import { validateFieldValue, validateTooltipValue } from '../utils/validateValue';
import { adjustLabels } from '../utils/adjustLabels';
import { renderLabel } from '../utils/isRequired';
import { isError } from '../utils/isError';

function NumberField(props) {
    // eslint-disable-next-line no-unused-vars
    const { field, index, group, isFormLocked, section, parentGroup, parentIndex, grandParentGroup} = props;
    const { state, handleBlur, handleChange, handleFocus } = useField(props);
    const [cursorPosition, setCursorPosition] = useState(null);
    const valued = validateFieldValue(state);
    const tooltipValue = validateTooltipValue(state);
    const hintText = getHintText(state, valued, field);
    const priorYearChanged = field.priorYearValue !== state.value;
    const [labelClassName, setLabelClassName] = useState('');
    const ref = useRef(null);
    
    useEffect(() => {
        adjustLabels(ref, section, setLabelClassName);
        //eslint-disable-next-line
    }, []);

    return (
        <div>
            <HoverModeProvider>
                <Tooltipped id={`hover-${section.title}-${field.name}-${index}`} tooltip={tooltipValue} position='above' key={`hover-${field.name}-${index}`} className='pracDashOnHover'>
                    <div className={labelClassName}>
                        {isError(field)}
                        <TextField 
                            ref={ref}
                            className={field.priorYearValue && priorYearChanged ? 'cla-prior-year-data-edited' : !field.priorYearValue && state.value.trim() !== '' ? 'cla-prior-year-data-edited' : null}
                            inputClassName={!priorYearChanged ? 'cla-prior-year-data' : null}
                            id={`number-${section.title}-${field.name}-${index}`}
                            name={field.name}
                            key={`number-${field.name}-${index}`}
                            type="text" 
                            label={renderLabel(field)}
                            error={state.error}
                            value={state.value}
                            placeholder={field.placeholder}
                            onChange={(e) => {
                                setCursorPosition(e.target.selectionStart);
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            minLength={props.minLength}
                            maxLength={field.maxLength ? field.maxLength : 14}
                            disabled={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section)}
                            readOnly={field.isReadOnly || false}
                            required={props.required ? true : false}
                            data-testid='number-field'
                        />
                    </div>
                </Tooltipped>
            </HoverModeProvider>
            <FormMessage 
                id={`${field.id}-error-tooltip`}
                error={state.error}
                messageStyle={{lineHeight: 1.34}}
                data-testid='error-message'
            >
                {hintText}
            </FormMessage>
        </div>
    );
}

export default NumberField;