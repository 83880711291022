import './newFiles.css';

const NewFileStatus = ({ className, onClick }) => {
    return (
        <div className={className}>
            <a className="downloadLabel downloadButton" onClick={onClick}>
                <span className="newFilesLabel">New</span>
            </a>
        </div>
    )
}
export default NewFileStatus;