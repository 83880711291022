// common modules
import React from 'react';

// custom modules
import '@components/Breadcrumb.css';
import BreadcrumbItem from '@components/BreadcrumbItem';

function Breadcrumb(props) {
	const { separator = '/', items } = props;

	let children = items.map((item, index) => (
		<BreadcrumbItem
			key={`breadcrumb-item-${index}`}
			to={item.to}
			label={item.label}
			current={item.current} />
	));

	const lastIndex = children.length - 1;

	children = children.reduce((acc, child, index) => {
		const notLast = index < lastIndex;

		if (notLast) {
			acc.push(
				child,
				<BreadcrumbSeparator key={`breadcrumb-separator-${index}`}>
					{separator}
				</BreadcrumbSeparator>,
			)
		} else {
			acc.push(child)
		}

		return acc
	}, [])

	return (
		<ol className='breadcrumb'>{children}</ol>
	)
};

const BreadcrumbSeparator = ({ children, ...props }) => (
	<li className='breadcrumb-separator' {...props}>
		{children}
	</li>
)

export default Breadcrumb;