import { Validators } from 'cla-formrenderer';
import {
    FOREIGN_ASSETS_PENSION_UPLOAD,
    FOREIGN_ASSETS_ACCOUNTS_UPLOAD,
    FOREIGN_ASSETS_INVESTMENTS_UPLOAD,
    FOREIGN_ASSETS_LIFE_INSURANCE_UPLOAD,
    FOREIGN_ASSETS_ENTITY_OWNERSHIP_UPLOAD
} from '@utilities/constants/strings';

const foreignAssetsFn = (year) => {
    const sections = [
        [
            {
                title: 'Foreign Account Questions',
                sectionId: 1,
                isVisible: true,
                isCollapsed: true,
                isDenseRow: true,
                groups: [
                    {
                        groupId: 1,
                        groupType: 'details',
                        fields: [
                            { label: 'Please Select All That Apply:', name: 'foreignAccountQuestionsLabel', type: 'label', class: 'labelTextDenseRow' },
                        ]
                    },
                    {
                        groupId: 2,
                        class: 'denseRowCheckboxGroup',
                        fields: [
                            { label: 'Account opened in the current year.', name: 'IFDSFBAR.26', type: 'checkbox', col: 12, axcessTranslate: 'checkbox' },
                            { label: 'Account closed in the current year.', name: 'IFDSFBAR.27', type: 'checkbox', col: 12, axcessTranslate: 'checkbox' },
                            { label: 'Income earned from the account in the current year.', name: 'foreignAccountQuestion3', type: 'checkbox', col: 12 },
                            { label: 'Taxes paid on income.', name: 'foreignAccountQuestion4', type: 'checkbox', col: 12 },
                            {
                                label: 'Account owner is held jointly with someone other than spouse.', name: 'IFDSFBAR.22', type: 'checkbox', col: 12, prior: true, axcessTranslate: 'otherJointOwner',
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.22'],
                                        fn: 'foreignAssetsAccountHeldJointlyOther',
                                    }
                                }
                            },
                        ]
                    },
                ]
            },
            {
                title: 'Foreign Account Information',
                sectionId: 2,
                isVisible: true,
                isCollapsed: true,
                isDenseRow: true,
                groups: [
                    {
                        groupId: 1,
                        isVisible: true,
                        fields: [
                            {
                                label: 'Bank / Financial Institution Name', name: 'IFDSFBAR.3', type: 'freeText', col: 6,
                                logicFunction: [{ trigger: 'setBankInfoLabel', }], prior: true, isPriorEditable: true, 
                                maxLength: 72, required: true,
                                validations: {
                                    ...Validators.required()
                                },
                            },
                            {
                                label: 'Account #', name: 'IFDSFBAR.2', type: 'accountNumber', col: 3,
                                logicFunction: [{ trigger: 'setBankInfoLabel', }], prior: true, isPriorEditable: true, 
                                maxLength: 40, isMaskOnBlur: true, required: true,
                                validations: {
                                    ...Validators.required()
                                },
                            },
                            {
                                label: 'Owner', name: 'IFDSFBAR.33', type: 'select', lookup: 'PAYER', col: 3, isSyncFieldValue: true, logicFunction: [{ trigger: 'syncUpDenseRowFieldValue', }], prior: true,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.33'],
                                        default: 'J',
                                    }
                                }
                            },
                            {
                                label: 'Country', name: 'IFDSFBAR.8', type: 'select', lookup: 'FOREIGN_ASSETS_COUNTRIES', col: 4,
                                triggered: 'setChangeCountryForeignAssetsInformation', prior: true, default: 'US', isPriorEditable: true,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.8'],
                                        fn: 'foreignCountryPull',
                                    }
                                }
                            },
                            { label: 'Street address', name: 'IFDSFBAR.4', type: 'freeText', col: 8, prior: true, maxLength: 50, isPriorEditable: true, },
                            { label: 'City', name: 'IFDSFBAR.5', type: 'freeText', col: 5, prior: true, maxLength: 19, isPriorEditable: true, },
                            {
                                label: 'State', name: 'IFDSFBAR.6.1', type: 'select', lookup: 'FOREIGN_ASSETS_STATES_CODES', col: 4,
                                placeholder: 'Select State', isVisible: true, prior: true, maxLength: 2, isPriorEditable: true,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.8', 'IFDSFBAR.6'],
                                        fn: 'americanStatesPull',
                                    }
                                }
                            },
                            {
                                label: 'State/Prov', name: 'IFDSFBAR.6.2', type: 'freeText', col: 4,
                                placeholder: 'Enter State/Prov/County', isVisible: false, maxLength: 17, isPriorEditable: true,
                            },
                            {
                                label: 'Province', name: 'IFDSFBAR.6.3', type: 'select', lookup: 'PROVINCES_CODE', col: 4,
                                placeholder: 'Enter Province', prior: true, isVisible: false, isPriorEditable: true,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.8', 'IFDSFBAR.6'],
                                        fn: 'canadianProvincePull',
                                    },
                                },
                            },
                            {
                                label: 'Province', name: 'IFDSFBAR.6.4', type: 'select', lookup: 'MEX_STATES_CODE', col: 4,
                                placeholder: 'Select State', prior: true, isVisible: false, isPriorEditable: true,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.8', 'IFDSFBAR.6'],
                                        fn: 'mexicanStatesPull',
                                    },
                                },
                            },
                            {
                                label: 'Zip / Postal Code', name: 'IFDSFBAR.7', type: 'zippostal',
                                col: 3, prior: true, maxLength: 16, isPriorEditable: true,
                            },
                        ]
                    },
                    {
                        groupId: 2,
                        groupType: 'details',
                        fields: [
                            { label: 'Account Details', name: 'foreignAccountDetailsLabel', type: 'label', class: 'labelTextDenseRow' },
                        ]
                    },
                    {
                        groupId: 3,
                        groupType: 'details',
                        fields: [
                            {
                                label: `Note: ${year.prior} amount is presented in USD. Please provide the ${year.current} maximum value in the functional currency (the currency the account is held in).`,
                                type: 'label',
                                class: 'groupDenseRowDetails'
                            },
                        ]
                    },
                    {
                        groupId: 4,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: `Maximum Amount in Account for ${year.prior} (USD)`, name: 'priorMaxValueLabel', type: 'label', col: 9, class: 'labelTextNoLeftPadding' },
                            { label: '$ 0', name: 'IFDSFBAR.37', type: 'label', col: 3, class: 'labelTextPriorAmount', isTotal: true, prior: true, maxLength: 11 },
                        ]
                    },
                    {
                        groupId: 6,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Foreign Currency Type', name: 'currencyLabel', type: 'label', col: 9, class: 'labelTextNoLeftPadding' },
                            {
                                label: 'Currency', name: 'IFDSFBAR.30', type: 'select', lookup: 'FOREIGN_CURRENCIES',
                                col: 3, default: 'USD', isSyncFieldValue: true,
                                logicFunction: [
                                    { trigger: 'setForeignCurrencySymbolLis', },
                                    { trigger: 'syncUpDenseRowFieldValue', },
                                ],
                                prior: true,
                                isPriorEditable: true,
                            },
                        ]
                    },
                    {
                        groupId: 5,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: `Maximum Amount in Account for ${year.current}`, name: 'currentMaxValueLabel', type: 'label', col: 9, class: 'labelTextNoLeftPadding' },
                            { label: 'Amount', name: 'C-IFDSFBAR.37', type: 'money', col: 3, isSyncFieldValue: true, logicFunction: [{ trigger: 'syncUpDenseRowFieldValue', }] },
                        ]
                    },
                    {
                        groupId: 7,
                        isVisible: true,
                        hasDivider: false,
                        fields: [
                            { label: 'Account Type', name: 'accountTypeLabel', type: 'label', col: 9, class: 'labelTextNoLeftPadding' },
                            {
                                label: 'Account Type', name: 'IFDSFBAR.0', type: 'select',
                                lookup: 'FOREIGN_ACCOUNT_TYPE', col: 3, prior: true, isPriorEditable: true,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.0'],
                                        fn: 'foreignAccountTypePull',
                                    }
                                }
                            },
                        ]
                    },
                ],
            },
            {
                title: 'Information If No Financial Interest Or Account Is Jointly Owned By Someone Other Than The Spouse',
                sectionId: 3,
                isVisible: true,
                isCollapsed: true,
                isDenseRow: true,
                groups: [
                    {
                        groupId: 1,
                        isVisible: true,
                        fields: [
                            { 
                                label: 'Last Name / Organization Name', 
                                name: 'IFDSFBAR.11', 
                                type: 'freeText', 
                                col: 3, 
                                maxLength: 47, 
                                prior: true, 
                                isPriorEditable: true,
                            },
                            { 
                                label: 'First Name', 
                                name: 'IFDSFBAR.12', 
                                type: 'freeText', 
                                col: 3, 
                                maxLength: 14, 
                                prior: true, 
                                isPriorEditable: true,
                            },
                            {
                                label: 'Identification Type', 
                                name: 'IFDSFBAR.15', 
                                type: 'select', 
                                lookup: 'ID_TYPE', 
                                triggered: 'setForeignAccountJointOwnerID', 
                                col: 3, 
                                prior: true, 
                                axcessTranslate: 'foreignAssetsIdType',
                                isPriorEditable: true,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.15'],
                                        fn: 'foreignAssetsIdTypePull',
                                    }
                                }
                            },
                            {
                                label: 'Taxpayer ID #', 
                                name: 'IFDSFBAR.16.1', 
                                type: 'freeText', 
                                isVisible: true, 
                                col: 3, 
                                maxLength: 30, 
                                prior: true,
                                isPriorEditable: true,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.16'],
                                        fn: 'anyValidPull',
                                    }
                                }
                            },
                            {
                                label: 'SSN / ITIN', 
                                name: 'IFDSFBAR.16.2', 
                                type: 'ssn', 
                                isVisible: false, 
                                col: 3, 
                                overRideText: 'ON FILE', 
                                validations: { ...Validators.ssn(), }, 
                                prior: true, 
                                isPriorEditable: true,
                                axcessTranslate: 'omitOnOverride', 
                                isMaskOnBlur: true,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.16'],
                                        fn: 'anyValidPull',
                                    }
                                }
                            },
                            {
                                
                                label: 'EIN', 
                                name: 'IFDSFBAR.16.3', 
                                type: 'freeText', 
                                isVisible: false, 
                                col: 3, 
                                maxLength: 30, 
                                prior: true,
                                isPriorEditable: true,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.16'],
                                        fn: 'anyValidPull',
                                    }
                                }
                            },
                        ]
                    },
                    {
                        groupId: 2,
                        groupType: 'details',
                        fields: [
                            { label: 'Other Owner’s Address', name: 'jointOwnerAddressLabel', type: 'label', class: 'labelTextDenseRow' },
                        ]
                    },
                    {
                        groupId: 3,
                        isVisible: true,
                        fields: [
                            {
                                label: 'Country', 
                                name: 'IFDSFBAR.21', 
                                type: 'select', 
                                lookup: 'FOREIGN_ASSETS_COUNTRIES', 
                                col: 4,
                                triggered: 'setChangeCountryForeignAssets', 
                                placeholder: 'Select Country', 
                                prior: true, 
                                isPriorEditable: true,
                                default: 'US',
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.21'],
                                        fn: 'foreignCountryPull',
                                    }
                                }
                            },
                            { 
                                label: 'Street address', 
                                name: 'IFDSFBAR.17', 
                                type: 'freeText', 
                                col: 8, 
                                maxLength: 72, 
                                prior: true, 
                                isPriorEditable: true,
                            },
                            { 
                                label: 'City', 
                                name: 'IFDSFBAR.18', 
                                type: 'freeText', 
                                col: 5, 
                                maxLength: 19, 
                                prior: true, 
                                isPriorEditable: true,
                            },
                            {
                                label: 'State', 
                                name: 'IFDSFBAR.19.1', 
                                type: 'select', 
                                lookup: 'FOREIGN_ASSETS_STATES_CODES', 
                                col: 4,
                                placeholder: 'Select State', 
                                isVisible: true, 
                                prior: true, 
                                isPriorEditable: true,
                                maxLength: 2,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.21', 'IFDSFBAR.19'],
                                        fn: 'americanStatesPull',
                                    }
                                },
                            },
                            {
                                label: 'State/Prov', name: 'IFDSFBAR.19.2', type: 'freeText', col: 4, placeholder: 'Enter State/Prov/County',
                                isVisible: false, maxLength: 17,
                            },
                            {
                                label: 'Province', 
                                name: 'IFDSFBAR.19.3', 
                                type: 'select', 
                                lookup: 'PROVINCES_CODE', 
                                col: 4, 
                                placeholder: 'Enter Province',
                                prior: true, 
                                isPriorEditable: true,
                                isVisible: false,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.21', 'IFDSFBAR.19'],
                                        fn: 'canadianProvincePull',
                                    },
                                },
                            },
                            {
                                label: 'State', 
                                name: 'IFDSFBAR.19.4', 
                                type: 'select', 
                                lookup: 'MEX_STATES_CODE', 
                                col: 4, 
                                placeholder: 'Select State',
                                prior: true, 
                                isPriorEditable: true,
                                isVisible: false,
                                axcess: {
                                    pull: {
                                        fields: ['IFDSFBAR.21', 'IFDSFBAR.19'],
                                        fn: 'mexicanStatesPull',
                                    },
                                },
                            },
                            { 
                                label: 'Zip / Postal Code', 
                                name: 'IFDSFBAR.20', 
                                type: 'zippostal', 
                                col: 3, 
                                maxLength: 15, 
                                prior: true, 
                                isPriorEditable: true,
                            },
                        ]
                    },
                ],
            },
        ],
        [
            {
                title: 'Pension Information',
                isVisible: true,
                sectionId: 4,
                isCollapsed: true,
                isDenseRow: true,
                groups: [
                    {
                        groupId: 1,
                        isVisible: true,
                        fields: [
                            { label: 'Pension Name', name: 'pensionName', type: 'freeText', col: 5, isSyncFieldValue: true, logicFunction: [{ trigger: 'syncUpDenseRowFieldValue', }] },
                            { label: 'Employer', name: 'employer', type: 'freeText', col: 5, isSyncFieldValue: true, logicFunction: [{ trigger: 'syncUpDenseRowFieldValue', }] },
                            { label: 'Owner', name: 'pensionOwner', type: 'select', lookup: 'PAYER', col: 2, isSyncFieldValue: true, logicFunction: [{ trigger: 'syncUpDenseRowFieldValue', }] },
                            { label: 'Year Opened', name: 'yearOpened', type: 'yearPicker', col: 3 },
                            { label: 'Country', name: 'pensionCountry', type: 'select', lookup: 'FOREIGN_COUNTRIES', col: 5, },
                            { label: 'Mandatory for Employment', name: 'employmentMandatory', type: 'select', lookup: 'YESNO', col: 4, },
                        ]
                    }
                ],
            },
        ]

    ];

    const lineItemGroup = [
        {
            title: 'Report of Foreign Bank and Financial Accounts',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 1,
            shortTitle: 'Foreign Accounts',
            hasDenseRow: true,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: '', type: 'label' },
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    isUniqueCurrencyPerLine: true,
                    axcessGroup: {
                        pull: {
                            fromSections: ['Report of Foreign Bank and Financial Accounts'],
                            fieldPrefixes: ['IFDSFBAR'],
                        },
                    },
                    fields: [
                        {
                            label: 'Owner', name: 'IFDSFBAR.33', type: 'select', lookup: 'PAYER', isSyncFieldValue: true, logicFunction: [{ trigger: 'syncDownDenseRowFieldValue', }], prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSFBAR.33'],
                                defaultIfEmpty: 'J',
                            }
                        },
                        {
                            label: '', name: 'foreignAccountInfo', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, }, prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSFBAR.3', 'IFDSFBAR.2'],
                                fn: 'foreignAssetsBankInfoLabelPull'
                            }
                        },
                        { label: 'Details', name: 'additionalInfo', type: 'viewButton', viewSectionId: 100, },
                        { label: '$ 0', name: 'IFDSFBAR.37', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', name: 'C-IFDSFBAR.37', type: 'money', isTotal: true, hideFooter: true, isSyncFieldValue: true, logicFunction: [{ trigger: 'syncDownDenseRowFieldValue', }] },
                        {
                            label: 'Currency', name: 'IFDSFBAR.30', type: 'select', lookup: 'FOREIGN_CURRENCIES', default: 'USD', isSyncFieldValue: true, logicFunction: [
                                { trigger: 'syncDownDenseRowFieldValue', },
                                { trigger: 'setForeignCurrencySymbol', }
                            ], prior: true
                        },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    lineSections: sections[0],
                    currentEntity: -1,
                    subWorkSheets: [],
                    entityIdentifiers: [
                        {
                            axcessDataType: 'PassDataToChild',
                        },
                        {
                            axcessDataType: 'PassDataToChild',
                        },
                        {
                            axcessDataType: 'PassDataToChild',
                        }
                    ],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Owner', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '48px' } },
                            { label: 'Bank/Institution Info', col: 2, colTablet: 2, align: 'left', styles: { paddingLeft: '44px' } },
                            { label: 'Additional Info', col: 1, colTablet: 1, align: 'left', styles: { paddingLeft: '21px' } },
                            { label: `${year.prior} Max Value (USD)`, col: 2, colTablet: 1, align: 'right', styles: { paddingRight: '4px' } },
                            { label: `${year.current} Max Value`, col: 2, colTablet: 1, align: 'right', styles: { paddingRight: '11px' } },
                            { label: 'Currency', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '25px' } },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'left', styles: { paddingLeft: '11px' } }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Foreign Account', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: FOREIGN_ASSETS_ACCOUNTS_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Non-US Directly-Held Investments or Mutual Funds',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 2,
            shortTitle: 'Investments or Mutual Funds',
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Note: This section does not apply to foreign investments held by U.S. mutual funds.', type: 'label' },
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    fields: [
                        { label: 'Owner', name: 'investmentOwner', type: 'select', lookup: 'PAYER', },
                        { label: 'Account Name', name: 'investmentName', type: 'freeText', },
                        { label: 'Account Number', name: 'investmentNumber', type: 'accountNumber', maxLength: 40, isMaskOnBlur: true, },
                        { label: '', name: '', type: 'label', },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Owner', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Account Name', col: 4, colTablet: 3, align: 'left' },
                            { label: 'Account Number', col: 4, colTablet: 2, align: 'left' },
                            { col: 1, colTablet: 0, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Non-US Directly-Held Investments or Mutual Funds', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: FOREIGN_ASSETS_INVESTMENTS_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Ownership in Foreign Entity',
            isVisible: true,
            sectionId: 3,
            groups: [
                {
                    groupId: 1,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    fields: [
                        { label: 'Name', name: 'foreignEntityName', type: 'freeText', },
                        { label: 'Country', name: 'foreignEntityCountry', type: 'select', lookup: 'FOREIGN_COUNTRIES', },
                        { label: 'Entity', name: 'foreignEntityType', type: 'select', lookup: 'FOREIGN_ENTITY_CLASSIFICATION', },
                        { label: 'Voting %', name: 'votingPercent', type: 'percent', validations: { ...Validators.maxValue(100), } },
                        { label: 'Y/N ', name: 'otherShareholders', type: 'select', lookup: 'YESNO', },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Entity Name', col: 3, colTablet: 2, align: 'left' },
                            { label: 'Country of Organization', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Entity Classification', col: 2, colTablet: 1, align: 'left' },
                            { label: 'Voting % Owned', col: 2, colTablet: 1, align: 'left' },
                            { label: 'Other US Shareholders?', col: 2, colTablet: 1, align: 'left' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Ownership in a Foreign Entity', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: FOREIGN_ASSETS_ENTITY_OWNERSHIP_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Non-US Pension',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            hasDenseRow: true,
            sectionId: 4,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Note: This section does not apply to foreign investments held by U.S. mutual funds.', type: 'label' },
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    fields: [
                        { label: 'Owner', name: 'pensionOwner', type: 'select', lookup: 'PAYER', isSyncFieldValue: true, logicFunction: [{ trigger: 'syncDownDenseRowFieldValue', }] },
                        { label: 'Pension Name', name: 'pensionName', type: 'freeText', isSyncFieldValue: true, logicFunction: [{ trigger: 'syncDownDenseRowFieldValue', }] },
                        { label: 'Employer', name: 'employer', type: 'freeText', isSyncFieldValue: true, logicFunction: [{ trigger: 'syncDownDenseRowFieldValue', }] },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    lineSections: sections[1],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Owner', col: 2, colTablet: 2, align: 'left', styles: { paddingLeft: '48px' } },
                            { label: 'Pension Name', col: 4, colTablet: 2, align: 'left', styles: { paddingLeft: '44px' } },
                            { label: 'Employer', col: 5, colTablet: 3, align: 'left', styles: { paddingLeft: '32px' } },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'left', styles: { paddingLeft: '11px' } }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Non-US Pension', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: FOREIGN_ASSETS_PENSION_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Non-US Life Insurance Policy Or Trust',
            isVisible: true,
            sectionId: 5,
            shortTitle: 'Life Insurance',
            groups: [
                {
                    groupId: 1,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    fields: [
                        { label: 'Owner', name: 'insuranceOwner', type: 'select', lookup: 'PAYER', },
                        { label: 'Type', name: 'insuranceType', type: 'select', lookup: 'FOREIGN_INSURANCE_TYPE', },
                        { label: 'Account Name', name: 'insuranceName', type: 'freeText', },
                        { label: 'Account Number', name: 'insuranceNumber', type: 'accountNumber', maxLength: 40, isMaskOnBlur: true, },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Owner', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Type', col: 3, colTablet: 2, align: 'left' },
                            { label: 'Account Name', col: 4, colTablet: 2, align: 'left' },
                            { label: 'Account Number', col: 2, colTablet: 1, align: 'left' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Non-US Life Insurance Policy Or Trust', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: FOREIGN_ASSETS_LIFE_INSURANCE_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
    ];

    return lineItemGroup;
};

export default foreignAssetsFn;