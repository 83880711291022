import { useState, useRef, useEffect } from 'react';
import { useToggle, TextArea } from 'react-md';
import _ from 'lodash';
import moment from 'moment';

import usePageFramework from '@utilities/hooks/usePageFramework';
import WarningDialog from '@components/dialog/warningDialog';
import SuccessDialog from '@components/dialog/successDialog';
import * as STRING from '@utilities/constants/strings'
import api from '@utilities/claApi';
import NotesFooterMobile from '../notesFooter/NotesFooterMobile';
import { CloseSVGIcon } from '@react-md/material-icons';
import CloseIcon from '@components/closeIcon';


function NotesDialogContentMobile(props) {

    const { setNotesVisible, currentformNoteText, setCurrentformNoteText, notesList, activeNote } = props;
    const { REDUX, selectState, dispatch, ACTION } = usePageFramework();
    const isFormLocked = selectState(REDUX.LOCK_FORMS);

    const textAreaRef = useRef(null);
    const [isNotesChangedAndUnsaved, setIsNotesChangedAndUnsaved] = useState(false)
    const [currnetUnsavedNotesText, setCurrnetUnsavedNotesText] = useState(currentformNoteText)
    const [savingTextSuccessDialog, setSavingTextSuccessDialog] = useState(STRING.NOTES_UPDATING);

    const [visibleSuccessDialog, showSuccessDialog, hideSuccessDialog] = useToggle(false);
    const [visibleWarningDialog, showWarningDialog, hideWarningDialog] = useToggle(false);
    const organizerId = selectState(REDUX.ORGANIZER_ID);

    useEffect(() => {

        if (currnetUnsavedNotesText === currentformNoteText) {
            isNotesChangedAndUnsaved && setIsNotesChangedAndUnsaved(false)
            return;
        }
        !isNotesChangedAndUnsaved && setIsNotesChangedAndUnsaved(true)
    // eslint-disable-next-line
    }, [currnetUnsavedNotesText])


    const updateNotesList = async (noteId) => {
        try {
            const response = await api.get(`/organizers/${organizerId}/notes/${noteId}`)
            const updatedNote = response.data;
            const clonedNotes = _.cloneDeep(notesList);
            const targetNote = clonedNotes.find(x => x.id === noteId);
            //update noteslist with the updated note
            if (targetNote) {
                clonedNotes.forEach((item, index) => {
                    if (item.id === noteId) {
                        clonedNotes[index] = updatedNote;
                    }
                });
            } else {
                clonedNotes.push(updatedNote);
            }
            dispatch(ACTION.setFormNotes(clonedNotes));
        } catch (error) {
            console.error(error);
        }

    };


    const saveFormNotes = async () => {
        setSavingTextSuccessDialog(STRING.NOTES_UPDATING);
        showSuccessDialog();

        const value = textAreaRef.current.value;
        const noteData = { value, key: activeNote.key };

        try {
            let noteIdToUpdate;
            if (!activeNote.id) {
                const response = await api.post(`/organizers/${organizerId}/notes`, { ...noteData })
                noteIdToUpdate = response.data.id
            } else {
                await api.put(`/organizers/${organizerId}/notes/${activeNote.id}`, noteData)
                noteIdToUpdate = activeNote.id
            }
            setCurrentformNoteText(value);
            setSavingTextSuccessDialog(STRING.NOTES_SUCCESS);
            updateNotesList(noteIdToUpdate)
        } catch (error) {
            setSavingTextSuccessDialog(STRING.NOTES_ERROR_SAVING);
            console.error(error);
        }

    };

    const closeFormNotes = () => {
        hideSuccessDialog();
        hideWarningDialog();
        setNotesVisible(false)
    };

    const handleOnCancel = () => {
        if (isNotesChangedAndUnsaved) {
            showWarningDialog()
        } else {
            closeFormNotes()
        }
    }


    const NotesHeader = () => {
        return (
            <>
                <div className='notesTitleMobile'>
                    {
                        activeNote?.version ? `Version ${activeNote.version}` : 'Version 1'
                    }
                    <p className='notesTitleSubtext'>
                        {activeNote?.createdOn ? `Last Edited on ${moment(new Date(activeNote.createdOn)).format('MM/DD/YY')}` : ''}
                    </p>
                </div>
            </>)
    };


    return (
        <>
            <div className='notesHeaderContainerMobile'>
                <NotesHeader />
                <CloseIcon className='notesCloseButtonMobile' onClick={() => handleOnCancel()} dataTestid='close-icon' />
            </div>
            <div className='notesNotificationText'>
                Please be aware that this is a note or instruction only. You will not receive a response from your CLA preparer in the Notes feature.
            </div>
            <TextArea
                className={'notesTextAreaMobile'}
                id={'notes-textArea'}
                key={'notes-textArea'}
                rows={10}
                maxRows={10}
                maxLength={998}
                defaultValue={currentformNoteText}
                onChange={(e) => setCurrnetUnsavedNotesText(e.target.value)}
                onBlur={(event) => setCurrnetUnsavedNotesText(event.target.value)}
                placeholder={'Please type to add a note or instruction for your CLA preparer.'}
                data-testid='notes-textbox'
                disabled={isFormLocked}
                ref={textAreaRef}
            />
            <NotesFooterMobile characterCount={currnetUnsavedNotesText.length} isNotesChangedAndUnsaved={isNotesChangedAndUnsaved}
                handleOnCancel={handleOnCancel} saveFunction={saveFormNotes} showWarningDialog={showWarningDialog}
                isNewNote={!(activeNote?.version >= 1)}
            />
            <WarningDialog subtitleText={STRING.NOTES_SUBTITLE} questionText={STRING.NOTES_QUESTION} confirmText={STRING.NOTES_CONFIRM} onConfirmClick={closeFormNotes}
                visible={visibleWarningDialog} onCancelWarning={hideWarningDialog} />
            <SuccessDialog className='formNotesSaveActiveMobile' subtitleText={savingTextSuccessDialog} onClick={saveFormNotes} onCloseClick={savingTextSuccessDialog === STRING.NOTES_ERROR_SAVING ? hideSuccessDialog : closeFormNotes}
                visible={visibleSuccessDialog} />
        </>
    );
}

export default NotesDialogContentMobile