function ExtensionErrorFilesMessage(props) {
    const { extensionErrorFiles} = props;
    return (
        <>
            <h4>
                {
                    'The following file(s) could not be uploaded because the file type is not supported.  Please upload again as a different file type.'
                }
            </h4>
            <div className='extensionErrorFiles'
                style={{
                    border: "solid",
                    borderWidth: "1px",
                    borderRadius: "2px",
                    backgroundColor: "#F7F7F7",
                    maxHeight: "20vh",
                    overflow: "auto",
                    textAlign: "left",
                    paddingLeft: "10px",
                    wordBreak: "break-all"
                }}>
                {
                    extensionErrorFiles.map((file, idx) => <li key={idx} id={idx}>{file}</li>)
                }
            </div>
        </>
    );
}

export default ExtensionErrorFilesMessage;