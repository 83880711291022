import { TextIconSpacing, FontIcon } from '@react-md/icon';
import { useIntercom } from 'react-use-intercom';

import './topBar.css';
import { authenticated } from '@utilities/authentication.js';
import { useMsal } from '@azure/msal-react';
import { injectLocalDevelopmentTokens } from '@utilities/authentication.js';
import { scopes } from '@utilities/claApi.js';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStorage } from '@utilities/hooks/useStorage';

function TopBar() {
  const { instance } = useMsal();
  const { shutdown } = useIntercom();
  const location = useLocation();
  const storage = useStorage('session');
  const isAuthenticated = authenticated();
  const [ historyChanges, setHistoryChanges ] = useState(0);
  const [ isHistoryEmpty, setIsHistoryEmpty ] = useState(false);

  const { selectState, REDUX } = usePageFramework();
  const isClient = selectState(REDUX.IS_CLIENT);
  const todos = selectState(REDUX.CLIENT_HQ_TODOS);

  const handleSignin = (e) => {
    if (
      !process.env.NODE_ENV ||
      (process.env.NODE_ENV === 'development' &&
        !process.env.REACT_APP_DANGER_BYPASS_INJECT_LOCAL_TOKENS)
    ) {
      // inject development tokens into cache then refresh entire page
      injectLocalDevelopmentTokens();
      window.location.reload();
    } else {
      // redirect to MSAL login
      instance.loginRedirect({
        scopes: scopes,
      });
    }
  };

  const navigateToUrl = (url) => {
    try {
      const sanitizedUrl = url.replace(/^"(.*)"$/, '$1').trim();
      const validatedUrl = new URL(sanitizedUrl);
      window.location.replace(validatedUrl.toString());
    } catch (error) {
      console.error('Invalid URL provided:', url);
    }
  };

  const navigateToCHQURL = () => {
    if (process.env.NODE_ENV === 'production') {
      window.location.href = 'http://www.claconnect.com/clienthq';
    } else {
      window.location.href = 'http://cla.admin.onistaged.com/clienthq';
    }
  };

  const handleSignout = (e) => {
    // GROWTH: Handle for automatic sign outs
    shutdown();

    instance.logoutRedirect({
      postLogoutRedirectUri: 'https://www.claconnect.com/',
    });
  };

  useEffect(() => {
    let isHistoryEmpty_ = storage.get('isHistoryEmpty');

    if (isHistoryEmpty_ === null) {
      isHistoryEmpty_ = window.history.length <= 1;
      storage.set('isHistoryEmpty', isHistoryEmpty_);
    }

    setIsHistoryEmpty(isHistoryEmpty_);

    return () => storage.remove('isHistoryEmpty');
  }, [])

  useEffect(() => {
    const hasHistoryChanges_ = storage.has('historyChanges');

    if (!hasHistoryChanges_ || hasHistoryChanges_ && historyChanges > 0) {
      setHistoryChanges((value) => {
        storage.set('historyChanges', value + 1);
        return value + 1
      });
    }
  }, [location]);

  useEffect(() => {
    let historyChanges_ = storage.get('historyChanges');

    if (historyChanges_ !== null) {
      setHistoryChanges(historyChanges_);
    }

    return () => storage.remove('historyChanges');
  }, []);

  const goBackToPreviousPage = () => {
    if (!isHistoryEmpty) {
      storage.remove('historyChanges');
      storage.remove('isHistoryEmpty');
      window.history.go(-historyChanges); // Navigate to the last external URL in history
    } else {
      console.log("No previous page in history."); // Fallback if no previous page exists
    }
  };

  return (
    <div className="notification-bar" data-testid="top-bar">
      {isAuthenticated && isClient && <div className='appBarGlobalNav'>
        <ul>
          <li onClick={goBackToPreviousPage}>
            <TextIconSpacing
              icon={
                <FontIcon className="appBarFontIconColor" style={{ margin: '0px', fontSize: '14px' }}>
                  chevron_left
                </FontIcon>
              }
            >
              Back
            </TextIconSpacing>
          </li>
          <li onClick={navigateToCHQURL}>
            Home
          </li>
          <li onClick={() => navigateToUrl(process.env.REACT_APP_CHQ_WEB_TODO_URL)}>
            To dos
            {todos > 0 && <div data-testid="todo-notification-dot" style={{ width: '8px', height: '8px', backgroundColor: 'red', borderRadius: '50%', marginLeft: '4px' }}></div>}
          </li>
        </ul>
      </div>}
      <div className="appBarHelpText">
        {/* TODO: Temporarily hiding until functionality is built */}
        <ul>
          {/* <li>
                        <TextIconSpacing
                            icon={
                                <FontIcon className="appBarFontIconColor">
                                    comment
                                </FontIcon>
                            }
                        >
                                Ask CLA
                        </TextIconSpacing>
                    </li> */}
          <li onClick={isAuthenticated ? handleSignout : handleSignin}>
            <TextIconSpacing
              icon={
                <FontIcon className="appBarFontIconColor">
                  {isAuthenticated ? 'logout' : 'login'}
                </FontIcon>
              }
            >
              {isAuthenticated ? 'Sign Out' : 'Sign In'}
            </TextIconSpacing>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TopBar;
