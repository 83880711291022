// common modules
import { useMemo, useContext, useCallback, useState, useEffect } from 'react';

// custom modules
import './engagementMessenger.scss';
import initialize from './initialize';
import EngagementMessengerContext from './context.js';

export const EngagementMessengerProvider = (
  { 
    url, 
    moduleID,
    tokenCallBack,
    children, 
    initializeDelay 
  }) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const init = useCallback(() => {
    if (!window.SN_CSM_EC && !isScriptLoaded) {
      console.error(
        'Init called before Engagement Messenger has been initialized'
      );
      return;
    }

    // initialize engagment messenger
    window.SN_CSM_EC.init({
      moduleID,
      loadFeature: window.SN_CSM_EC.loadEMFeature(),
      IdpIframeEmbeddingEnabled: true,
      tokenCallBack,
    });

    window.SN_CSM_EC.onLogin();

    setIsInitialized(true);
  }, [setIsInitialized, isScriptLoaded, tokenCallBack, moduleID]);

  const onScriptLoad = (e) => {
    setIsScriptLoaded(true);
  };

  useEffect(() => {
    initialize(url, onScriptLoad, initializeDelay);
  }, [initializeDelay, url]);

  useEffect(() => {
    if (window.SN_CSM_EC && isScriptLoaded) {
      init();
    }
  }, [isScriptLoaded, init]);

  const ensureEngagementMessenger = useCallback((functionName, callback) => {
    if (!window.SN_CSM_EC || !isInitialized) {
      console.warn(
        `${functionName} was called but Engagement Messenger has not `
        + `initialized yet. Please call 'boot' before calling '${functionName}'`);
        return setTimeout(callback, 1000);
    }

    return callback();
  }, [isInitialized]);

  const close = useCallback(() => {
    ensureEngagementMessenger('close', () => {
      window.SN_CSM_EC.close();
    });
  }, [ensureEngagementMessenger]);

  const destroy = useCallback(() => {
    ensureEngagementMessenger('destroy', () => {
      window.SN_CSM_EC.destroy();
    });
  }, [ensureEngagementMessenger]);

  const dockIn = useCallback(() => {
    ensureEngagementMessenger('dockIn', () => {
      window.SN_CSM_EC.dockIn();
    });
  }, [ensureEngagementMessenger]);

  const dockOut = useCallback(() => {
    ensureEngagementMessenger('dockOut', () => {
      window.SN_CSM_EC.dockOut();
    });
  }, [ensureEngagementMessenger]);

  const hide = useCallback(() => {
    ensureEngagementMessenger('hide', () => {
      window.SN_CSM_EC.hide();
    });
  }, [ensureEngagementMessenger]);

  const onLogin = useCallback(() => {
    ensureEngagementMessenger('onLogin', () => {
      window.SN_CSM_EC.onLogin();
    });
  }, [ensureEngagementMessenger]);

  const onLogout = useCallback(() => {
    ensureEngagementMessenger('onLogout', () => {
      window.SN_CSM_EC.onLogout();
    });
  }, [ensureEngagementMessenger]);

  const open = useCallback(() => {
    ensureEngagementMessenger('open', () => {
      window.SN_CSM_EC.open();
    });
  }, [ensureEngagementMessenger]);

  const show = useCallback(() => {
    ensureEngagementMessenger('show', () => {
      window.SN_CSM_EC.show();
    });
  }, [ensureEngagementMessenger]);

  const providerValue = useMemo(() => {
    return { 
      close, // close widget
      destroy, // destroy widget
      dockIn, // dock in messenger
      dockOut, // dock out messenger
      hide, // hide widget
      onLogin, // start the user session in Engagement Messenger
      onLogout, // Terminate the user session in Engagement Messenger
      open, // open widget
      show // show widget
    };
  }, [
    close, 
    destroy, 
    dockIn, 
    dockOut, 
    hide, 
    onLogin, 
    onLogout, 
    open, 
    show
  ]);
  
  return (
    <EngagementMessengerContext.Provider value={providerValue}>
      {children}
    </EngagementMessengerContext.Provider>
  );
};

export const useEngagementMessengerContext = () => {
  const context = useContext(EngagementMessengerContext);

  if (context === undefined) {
    throw new Error('"useEngagementMessenger" must be used within `EngagementMessengerProvider`.');
  }

  return context;
};