import { useState, useEffect } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent, sectionFieldLogic } from './foreignAssetsLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import * as NAV from '@utilities/constants/navigation';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import foreignAsset2021 from './2021';
import foreignAsset2022 from './2022';
import foreignAsset2023 from './2023';
import foreignAsset2024 from './2024';

function ForeignAssets(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.FOREIGN_ASSETS);
    const [lineItemGroupState, setLineItemGroupState] = useState('');
    const year = getYear(selectState);
    
    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: foreignAsset2021(year),
            2022: foreignAsset2022(year),
            2023: foreignAsset2023(year),
            2024: foreignAsset2024(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setLineItemGroupState(formSections);
        // eslint-disable-next-line
    }, []);

    const axcessIdentifiers = [
        { section: 'Foreign', id: '114 (FBAR) / 8938 - Foreign Assets' },
    ];

    return (
        <FormRendererHelper sections={lineItemGroupState} key="foreignAssetsForm" formName={REDUX.FOREIGN_ASSETS} identifiers={axcessIdentifiers} triggeredEvent={triggeredEvent} sectionFieldLogic={sectionFieldLogic}
            isExportForm={isExportForm}
        />
    );

}
export default ForeignAssets;