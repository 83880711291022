// This logic is custom logic to set Web1040 organizer status.
import api from '@utilities/claApi';
import { deriveActivity, deriveStatus } from './deriveOrganizerData';

// Build custom status string from organizer data
const deriveOrganizerActivity = (organizer, defaultDate) => {
    const payload = {
        customStatus: deriveStatus(organizer, defaultDate),
        customDate: deriveActivity(organizer, defaultDate),
    };
    return payload;
};

const setDerivedOrganizerData = async (organizerId) => {
    if (!organizerId) return;

    await api.get(`/organizers/${organizerId}`).then((response) => {
        return response.data;
    }).then((organizer) => {
        const payload = deriveOrganizerActivity(organizer);

        if (organizer.customStatus === payload.customStatus
            && organizer.customDate === payload.customDate) {
            // Do not set if the Organizer already has the same custom status and date
            return;
        }

        return api.put(`/organizers/${organizerId}`, payload);
    }).catch((err) => {
        // console.error(err);
        console.error('Unable to update organizer status');
    });
};

export default setDerivedOrganizerData;

export {
    setDerivedOrganizerData,
    deriveOrganizerActivity,
};