export const ABOUT_YOU = ['About You', 'Address'];
export const DEPENDENTS = ['Dependents'];
export const ABOUT_YOUR_YEAR = ['Tell Us', 'Additional Information'];
export const REFUND_TAX_PAYMENT = ['Refund / Payment Preferences'];
export const TAX_PAYMENTS = ['Tax Payments Made in 2021 for 2020 Taxes', '2021 Federal Tax Payments Made', '2021 State Tax Payments Made'];
export const FOREIGN_ASSETS = ['Report of Foreign Bank and Financial Accounts', 'Non-US Directly-Held Investments or Mutual Funds', 'Ownership in Foreign Entity', 'Non-US Pension', 'Non-US Life Insurance Policy Or Trust'];

export const WAGES = ['Wages'];
export const INVESTMENT_INCOME = ['Interest Income (Form 1099-INT, 1099-OID)', 'Interest Income - No 1099 Received', 'Dividend Income (Form 1099-DIV)', 'Brokerage Statement (Consolidated Form 1099)', 'Other Investment Income / Sales of Assets'];
export const RETIREMENT_INCOME = ['Social Security Benefits', 'Retirement Plan Distributions'];
export const MISC_INCOME = ['State And Local Income Tax Refunds (IRS 1099-G)', 'Other Income / Unemployment Compensation', 'Gambling Winnings (Form W-2G)', 'Gambling Losses', 'Alimony Received',];
export const BUSINESS_INCOME = ['Business Information', 'Business Income', 'Business Expenses', 'Vehicle Expenses', 'Business Use Of Home'];
export const RENTAL_INCOME = ['Rental Property Information', 'Rental Property Income', 'Rental Property Expenses', 'Vehicle Expenses'];
export const FARM_INCOME = ['Farm Information', 'Farm Income', 'Farm Expenses', 'Vehicle Expenses', 'Business Use Of Home'];
export const PASSTHRU_INCOME = ['Passthrough Information'];
export const FOREIGN_INCOME = ['Travel and Workday History', 'Separate Home Information', 'Foreign Taxes'];

export const MEDICAL_EXPENSES = ['Deductions', 'Medical Insurance Premiums', 'Other Medical Expenses',];
export const PROPERTY_STATE_TAXES = ['Taxes Paid'];
export const MORTGAGE_INVESTMENT = ['Home Mortgage Interest Paid To Financial Institutions', 'Other Mortgage Interest Paid', 'Investment Interest',];
export const RETIREMENT_CONTRIBUTIONS = ['Individual Retirement Accounts', 'Self-Employed Retirement Plans', 'Other Retirement Contributions',];
export const CHARITABLE_CONTRIBUTIONS = ['Cash Contributions', 'Non-Cash Contributions', 'Stock Contributions', 'Charitable / Volunteer Miles'];
export const DEPENDENT_CARE = ['Dependent Care Expenses',];
export const HIGHER_EDUCATION = ['Tuition, Fees, and Qualified Expenses for Higher Education Credit', 'Qualified Tuition Plan Contributions (529 Plans, Not Prepaid Tuition Plans)', 'Payments From Qualified Tuition Education Programs (Form 1099-Q)'];
export const ENERGY_EFFICIENT = ['Residential Energy Property Credit', 'Residential Renewable Energy Tax Credits', 'Alternative Motor Vehicle Credit'];
export const HOUSEHOLD_EMPLOYMENT = ['Household Employment Taxes', 'State Unemployment / Insurance Contributions'];
export const OTHER_DEDUCTIONS = ['Adjustments To Income', 'Student Loan Interest Deduction', 'Additional Adjustments To Income',];