import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import createReducer from '@utilities/reducers';
import createHelperMiddleware from '@utilities/store';

export default function configureStore(initialState = {}, history) {

    if (history) {
        console.log(history);
    }
    
    const middlewares = [
        createHelperMiddleware
    ];

    const enhancers = [
        applyMiddleware(...middlewares),
    ];

    /* eslint-disable no-underscore-dangle */
    const composeEnhancers = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
    /* eslint-enable */
    const store = createStore(
        createReducer(),
        fromJS(initialState),
        composeEnhancers(...enhancers)
    );

    // Extensions
    store.asyncReducers = {}; // Async reducer registry

    return store;
}

