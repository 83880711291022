import { useState, useEffect } from 'react';
import { NestedDialogContextProvider, Dialog } from 'react-md';
import DeactivateDialogContent from '../DialogContent/DeactivateDialogContent';
import ActivateDialogContent from '../DialogContent/ActivateDialogContent';

function ActivateDeactivateWelcomeEmail({clsStatusDialog,cslStatus, setDialogVisible, selectedConfirmedEmailInviteItems, visibleSuccessDialog, hideSuccessDialog, dialogVisible, cslData, handleActivation, handleDeactivation, action, setSelectedRows, setSelectionModel,onSelectionEmailInviteModalChange }) {
    const [currentCslData, setCurrentCslData] = useState(cslData);
    useEffect(() => {
        setCurrentCslData(cslData);
    }, [cslData]);

    return (
        <NestedDialogContextProvider>
            <Dialog
                id={'welcomeEmailDialog'}
                data-testid="welcomeEmailDialog"
                visible={dialogVisible}
                onRequestClose={() => { }}
                modal={true}
                aria-labelledby="card-welcome-email-dialog"
                className="welcomeDialogContainer"
                style={{ width: '100%', maxWidth: '740px', maxHeight: '504px', borderRadius: '4px' }}
                overlayHidden={false}
            >
                { action === 'deactivate' ?
                 <DeactivateDialogContent {...{ clsStatusDialog,cslStatus, setDialogVisible, visibleSuccessDialog, hideSuccessDialog, currentCslData, handleDeactivation, setSelectedRows, setSelectionModel, onSelectionEmailInviteModalChange, selectedConfirmedEmailInviteItems }} /> :
                  <ActivateDialogContent {...{ clsStatusDialog, cslStatus,setDialogVisible,visibleSuccessDialog, hideSuccessDialog, currentCslData, handleActivation, setSelectedRows, setSelectionModel, onSelectionEmailInviteModalChange, selectedConfirmedEmailInviteItems}} /> }
            </Dialog>
        </NestedDialogContextProvider>
    );
}

export default ActivateDeactivateWelcomeEmail;