import './newFiles.css';
import { FontIcon } from 'react-md';

const DownloadNewFiles = ({ className, total, onClick = () => { } }) => {
  const handleClick = (event) => {
    onClick(event);
  };

  return (
    <div className={className} onClick={handleClick} data-testid="download-new-files">
      <a className="downloadLabel downloadButton">
        <FontIcon className="download">download</FontIcon>
        Download New Files
        <span>{total ? total : 0}</span>
      </a>
    </div>
  )
}
export default DownloadNewFiles;