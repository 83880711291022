import './headerMenu.css';
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as IMG from '@utilities/constants/images';
import * as STRING from '@utilities/constants/strings';
import ShowLastFormSave from '@components/showLastFormSave';
import { matchPath } from 'react-router-dom';
import { isEsignaturePath } from '@utilities/services/taxReturnService';
import { DropDownMenuComponent } from '@components/entryExperience/entryComponents/dropdownMenuComponent';

function HeaderMenu() {
	const { history, dispatch, selectState, REDUX, NAV, location, ACTION, validateCurrentCard } = usePageFramework();
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
	const isAdmin = selectState(REDUX.IS_ADMIN);
	const isClient = selectState(REDUX.IS_CLIENT);
	const isAuthenticated = selectState(REDUX.IS_AUTHENTICATED);
	const activeReturn = selectState(REDUX.ACTIVE_RETURN);
	const toolbarTitle = selectState(REDUX.TOOLBAR_TITLE);
	const organizerId = selectState(REDUX.ORGANIZER_ID);
	const isPracDash = toolbarTitle === STRING.EXCHANGE_MANAGER_1040 || toolbarTitle === 'Document Manager';
	const clientOrganizers = selectState(REDUX.CLIENT_ORGANIZERS);
	const entryModalOpen = selectState(REDUX.ENTRY_MODAL_OPEN);
	const hasMultipleReturns = selectState(REDUX.HAS_MULTIPLE_RETURNS);

	// Check if we are on any dashboard route
	const isDashboard = matchPath(location?.pathname, { path: '/dashboard/:orgId' }) || location?.pathname?.startsWith('/dashboard') || location?.pathname === '/';

	// Check if we are on the document manager route
	const isDocumentManager = location.pathname === '/documentManager';

	const isESignature = isEsignaturePath(location.pathname);
	const active = 'headerMenuTextSelected headerMenuCustomMargin';
	const inactive = 'headerMenuText headerMenuCustomMargin';

	const goBack = () => {
		validateCurrentCard();
		dispatch(ACTION.setCurrentCardKey(STRING.DASHBOARD_KEY));
		dispatch(ACTION.setDashboardFilterStatus(-1));
		dispatch(ACTION.setDashboardSortRequirements('traditional'));
		history.push(`${NAV.DASHBOARD}/${organizerId}`);
	};

	const tempLoadData = () => {
		if (isPractitioner || isAdmin) {
			validateCurrentCard();
			dispatch(ACTION.setCurrentCardKey(null));
			history.push(NAV.PRACTITIONERS_DASHBOARD);
		}
	};

	const handleDocManager = () => {
		validateCurrentCard();
		dispatch(ACTION.setCurrentCardKey(null));
		history.push(`${NAV.DOCUMENT_MANAGER}`);
	};

	const buildActiveReturn = () => {
		let activeReturnClassName = '';
		if (isESignature) {
			activeReturnClassName = inactive;
		} else if (activeReturn) {
			activeReturnClassName = isPracDash
				? 'headerMenuText headerMenuCustomMargin'
				: 'headerMenuTextSelected headerMenuCustomMargin';
		} else {
			activeReturnClassName = `inactiveHeaderMenuText headerMenuCustomMargin`;
		}
		return (
			<div className="activeReturnContainer">
				<div
					className={activeReturnClassName}
					id="dashboard"
					onClick={activeReturn ? () => goBack() : () => { }}
					style={{ marginRight: '55px' }}
				>
					Active Client
				</div>
				{activeReturn ?
					<div onClick={() => goBack()} className="navClientInfoContainer">
						<div className='activeReturnName'>{activeReturn?.displayName}</div>
						<div className='activeReturnNumber'>{`CLIENT ID #${activeReturn.clientNumber}`}</div>
					</div>
					: <> </>}
			</div>
		);
	};

	return (
		<div className="navbar" data-testid="header-menu">
			<div className="navbarMenu">
				<img className="site-logo" alt="CLA Navy colored logo" src={IMG.CLA_NAVY_LOGO} />
				{
					(isPractitioner || isAdmin) && (isPracDash || isESignature) ?
						buildActiveReturn() :
						isAuthenticated && (
							<div
								className={
									isDashboard && !isDocumentManager ? active : inactive
								}
								id="dashboard"
								onClick={() => !isDashboard && goBack()}
							>
								{STRING.DASHBOARD}
							</div>
						)
				}

				{isPractitioner || isAdmin ?
					<div
						className={
							location.pathname === '/practitionersDashboard' || (location.pathname === '/' && (isPractitioner || isAdmin)) ?
								active : inactive
						}
						onClick={() => tempLoadData()}
						id="practitionersDashboard"
					>
						1040 Exchange Manager
					</div> : <></>
				}
				{
					toolbarTitle !== '1040 Exchange Manager' && isAuthenticated &&
					<div
						className={isDocumentManager ? active : inactive}
						onClick={() => handleDocManager()}
					>
						Document Manager
					</div>
				}
				{isClient && !entryModalOpen && <ShowLastFormSave className={(isPractitioner || isAdmin) ? 'navbarLastSaveMsg' : 'navbarClientLastSaveMsg'} />}
				{(isPractitioner || isAdmin) && <ShowLastFormSave className={(isPractitioner || isAdmin) ? 'navbarLastSaveMsg' : 'navbarClientLastSaveMsg'} />}

			</div>
			{isClient && hasMultipleReturns && (isDashboard || isDocumentManager) && <div className="dashboardSubMenuDropdownDash" style={{
				marginRight: '40px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginLeft: '0px',
			}}>
				<DropDownMenuComponent organizers={clientOrganizers} userAccWidth={'41px'} userAccHeight={'41px'} userAccFontSize={'16px'} userAccFontWeight={400} userAccbcgColor={'#2E334E'} userAccTextColor={'#FFFFFF'} avatarWidth={'41px'} avatarHeight={'41px'} avatarFontSize={'16px'} dropdownFontSize={'14px'} />
			</div>}
		</div>
	);
}

export default HeaderMenu;
