import { IS_CLIENT, IS_PRACTITIONER, IS_ADMIN } from '@utilities/constants/redux';

const lastUserActivityDate = (selectState, locked = '') => {
  const isPractitioner = selectState(IS_PRACTITIONER);
	const isClient = selectState(IS_CLIENT);
  const isAdmin = selectState(IS_ADMIN);
  const isCLAEmployee = isPractitioner || isAdmin;
  if (isClient || (isCLAEmployee && locked)) return new Date();
  else return '';
}

export const lastUserActivityDateSetter = {
  val: null,
  /**
     * @param {null} value
     */
  set lastUserActivityDate(value) {
    this.val = value;
  },
  get lastUserActivityDate() {
    if (this.val) {
      return this.val;
    }
    return null;
  }
}

export default lastUserActivityDate;