/**
 * Updates document metadata both locally and in the backend.
 *
 * @param {Array} uploads
 * @param {Object} file
 * @param {string} targetOrganizerId
 * @param {string} authUserEmail
 * @param {Function} api
 * @param {Function} dispatch
 * @param {Object} ACTION
 * @returns {Promise<Array>}
 */
export const updateDocumentMetadata = async (uploads, file, targetOrganizerId, authUserEmail, api, dispatch, ACTION) => {
    const currentDateTime = new Date().toJSON();
    const payload = {
        downloadedBy: authUserEmail,
    };

    try {
        // Update backend with downloadedBy info
        await api.put(`/organizers/${targetOrganizerId}/documents/${file.id}`, payload);

        // Update the file metadata in the uploads array immutably
        const updatedUploads = uploads.map(upload => {
            if (upload.id === file.id) {
                return {
                    ...upload,
                    isNew: false,
                    downloaded_on: currentDateTime,
                    downloadedBy: authUserEmail,
                };
            }
            return upload;
        });

        return updatedUploads;
    } catch (error) {
        console.error("Error updating document metadata:", error.message);
        throw error;
    }
};
