import {FormMessage, TextField, Tooltipped, HoverModeProvider, } from 'react-md';
import NumberFormat from 'react-number-format';
import { useField } from '../useField';
import { getHintText } from '../utils/getHintText';
import { isFieldDisabled } from '../utils/isDisabled';
import { adjustLabels } from '../utils/adjustLabels';
import { useEffect, useState, useRef } from 'react';
import { renderLabel } from '../utils/isRequired';
import { isError } from '../utils/isError';

function MoneyField (props) {
    // eslint-disable-next-line no-unused-vars
    const { field, index, group, isFormLocked, section, parentGroup, parentIndex, grandParentGroup} = props;
    const { state, handleBlur, updateState, handleNumberValueChange } = useField(props);
    const valued = state.value && state.value.length > 0 ? true : false;
    const hintText = getHintText(field);
    const [isDisabled, setIsDisabled] = useState(false);
    const items = section.groups?.find(item => item.lineItems && item.lineItems.length > 0);
    const priorYearChanged = field.priorYearValue !== state.value;
    const currencyGroup = group.lineItems && group.isUniqueCurrencyPerLine ? group.lineItems[index][0] : group;
    const currencySymbol = currencyGroup.currencySymbol ? currencyGroup.currencySymbol : '$';
    const [labelClassName, setLabelClassName] = useState('');
    const ref = useRef(null); // to access DOM element
    // Conditional field disabling in HSA distributions money field functionality
    useEffect(() => {
        if (items) {
            const lineItems = items.lineItems[index];
            
            if (lineItems) {
                const dropdownValue = items.lineItems[index]
                    .find(item => item.name === 'isAllHsaDistForUnreimBursedMedicalExpenses');
                const hsaDistCyAmount = items.lineItems[index]
                    .find(item => item.name === 'hsaDistCyAmount');
                const hsaMedExpensesAmt = items.lineItems[index]
                    .find(item => item.name === 'amountNotUsedForUnreimbursedMedicalExpenses');

                // hsa med expense unreimbursed amt must be less than or equal hsa 2020 amount
                if (hsaDistCyAmount && hsaMedExpensesAmt) {
                    if (hsaDistCyAmount.default !== undefined && hsaMedExpensesAmt.default !== undefined) {
                        if (hsaMedExpensesAmt.default > hsaDistCyAmount.default) {
                            hsaMedExpensesAmt.default = hsaDistCyAmount.default;
                            updateState(hsaMedExpensesAmt.default, false, '');
                        }
                    }
                }  

                if (field.name === 'amountNotUsedForUnreimbursedMedicalExpenses' && 
                    dropdownValue && dropdownValue.default === 'Yes') setIsDisabled(true);
                else setIsDisabled(false);
            }
        }
    }, [section]);

    useEffect(() => {
        adjustLabels(ref, section, setLabelClassName);
    }, []);

    const handleFocus = (event) => {
        if (event.target.value === '0') {
            updateState('', false, '');
        }
    };

    //calculates number of thousand seperator commas in field.maxLength
    function calculateCommas(totalLength) {
        for (let commas = Math.floor((totalLength - 1) / 4); commas >= 0; commas--) {
          if (totalLength - commas > 3 * commas) {
            return commas;
          }
        }
        return 0;
    }
    
    //replaces pasted value with truncated value of only digits and decimal points with a max length of field.maxLength minus commas
    const handlePaste = (event) => {
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData('text/plain');
        const pasteMaxLength = field.maxLength !== undefined ? field.maxLength - calculateCommas(field.maxLength) : 14;
        const truncatedText = pastedText.replace(/[^0-9.]/g, '').substring(0, pasteMaxLength);
        updateState(truncatedText, false, '');
      };

    return (
        <div>
            <HoverModeProvider>
                <Tooltipped id={`hover-${section.title}-${field.name}-${index}`} tooltip={`${currencySymbol} ${state.value}`} position='above' key={`hover-${field.name}-${index}`} className='pracDashOnHover'>
                    <div className={labelClassName}>
                        {isError(field)}
                        <NumberFormat 
                            customInput={TextField}
                            //react-md props
                            getInputRef={ref}
                            className={`cla-money-field ${field.priorYearValue && priorYearChanged ? 'cla-prior-year-data-edited' : !field.priorYearValue && state.value.trim() !== '' ? 'cla-prior-year-data-edited' : null}`}
                            inputClassName={!priorYearChanged ? 'cla-prior-year-data' : null}
                            id={`money-${section.title}-${field.name}-${index}`}
                            name={field.name}
                            key={`money-${field.name}-${index}`}
                            type='text' 
                            label={renderLabel(field)}
                            error={field.error}
                            value={state.value}
                            onFocus={(event) => handleFocus(event)}
                            onBlur={handleBlur}
                            minLength={field.minLength}
                            maxLength={field.maxLength || 18} // 14 digits + 4 commas
                            disabled={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section) || isDisabled}
                            required={field.required ? true : false}
                            leftChildren={valued && <div className={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section) || isDisabled ? 'dollarMoneyField disabledDollar' : 'dollarMoneyField'}>{currencySymbol}</div>}
                            isLeftAddon={false}
                            //react-number-format props:
                            allowNegative={!!field.allowNegative}
                            thousandSeparator={true}
                            decimalScale={2}
                            isNumericString={true}
                            onValueChange={(values) => {
                                const { value } = values;
                                handleNumberValueChange(value);
                            }}
                            onPaste={handlePaste}
                            data-testid='money-field'
                        />
                    </div>
                </Tooltipped>
            </HoverModeProvider>
            <FormMessage 
                id={`${field.id}-error-tooltip`}
                error={field.error}
                messageStyle={section.isDenseRow ? null : {whiteSpace: 'nowrap'}}
                data-testid='error-message'
            >
                {hintText}
            </FormMessage>
        </div>
    );
}

export default MoneyField;