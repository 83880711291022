function FileContentAndExtensionMismatchMessage(props) {
    const { contentAndTypeMismatchFiles } = props;
    return (
        <>
            <h4>
                The file content doesn't match the file type. Please upload again as a different file type.
            </h4>
            <div className='extensionErrorFiles'
                style={{
                    border: "solid",
                    borderWidth: "1px",
                    borderRadius: "2px",
                    backgroundColor: "#F7F7F7",
                    maxHeight: "20vh",
                    overflow: "auto",
                    textAlign: "left",
                    paddingLeft: "10px",
                    wordBreak: "break-all"
                }}>
                {
                    contentAndTypeMismatchFiles.map((file, idx) => <li key={idx} id={idx}>{file}</li>)
                }
            </div>
        </>
    );
}

export default FileContentAndExtensionMismatchMessage;