export const NOT_STARTED = 'NOT STARTED';
export const IN_PROGRESS = 'IN PROGRESS';
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';

export const PUSH_STATUSES = [
  NOT_STARTED,
  IN_PROGRESS,
  SUCCESS,
  FAIL,
];