import { Validators } from 'cla-formrenderer';
import {
    FOREIGN_INCOME_TRAVEL_WORKDAY_UPLOAD,
    FOREIGN_EMPLOYMENT_UPLOAD,
    FOREIGN_SEPARATE_HOME_INFO_UPLOAD,
    FOREIGN_OCCUPANT_DETAILS_UPLOAD,
    FOREIGN_HOUSING_UPLOAD,
    FOREIGN_INCOME_ADD_ANOTHER_ACCOUNT,
    FOREIGN_INCOME_TAXES_UPLOAD
} from '@utilities/constants/strings';

const foreignIncomeFn = (year) => {
    const sections = [
        [
            {
                title: 'Travel and Workday Details',
                sectionId: 1,
                isVisible: true,
                isDenseRow: true,
                isCollapsed: false,
                groups: [
                    {
                        groupId: 1,
                        isVisible: true,
                        fields: [
                            {
                                label: 'Date of Arrival', name: 'arrivalDate', type: 'date', maxDate: "today", minDate: "1/1/2000",
                                col: 3, axcessTranslate: 'date',
                                logicFunction: [
                                    { trigger: 'updateArrivalDateAndDepartureDate', otherFieldName: 'departureDate' },
                                    { trigger: 'calculateFullDaysInCountry', }
                                ]
                            },
                            {
                                label: 'Date of Departure', name: 'departureDate', type: 'date', maxDate: "today", minDate: "1/1/2000",
                                col: 3, axcessTranslate: 'date', 
                                logicFunction: [
                                    { trigger: 'updateArrivalDateAndDepartureDate', otherFieldName: 'arrivalDate' },
                                    { trigger: 'calculateFullDaysInCountry', },
                                ]
                            },
                            {
                                label: 'Number of Workdays', name: 'workDays', type: 'number', maxLength: 3, col: 2, isSyncFieldValue: true, logicFunction: [
                                    { trigger: 'validateWorkDays', },
                                    { trigger: 'setWorkDaySummaryLabel', }
                                ]
                            },
                            { label: 'Full Days in Country', name: 'fullDays', type: 'readOnly', col: 2, logicFunction: [{ trigger: 'setWorkDaySummaryLabel', }] },
                            { label: 'Visa Type', name: 'visaType', type: 'freeText', isSyncFieldValue: true, logicFunction: [{ trigger: 'syncVisaType', isDifferentGroup: true }], col: 2, },
                        ]
                    },
                ],
            },
            {
                title: 'Foreign Employment Information',
                sectionId: 2,
                isVisible: true,
                isDenseRow: true,
                isCollapsed: true,
                groups: [
                    {
                        groupId: 1,
                        groupType: 'details',
                        fields: [
                            { label: 'Please Select All That Apply:', name: 'foreignIncomeQuestionsLabel', type: 'label', class: 'labelTextDenseRow' },
                        ]
                    },
                    {
                        groupId: 2,
                        class: 'denseRowCheckboxGroup',
                        fields: [
                            { label: `Started a foreign assignment during ${year.current}.`, name: 'foreignIncomeQuestion1', type: 'checkbox', col: 12 },
                            { label: `Ended a foreign assignment during ${year.current}.`, name: 'foreignIncomeQuestion2', type: 'checkbox', col: 12 },
                            { label: 'Paid qualified housing expenses during the year (rent, foreign real estate tax, utilities, repairs and maintenance).', name: 'foreignIncomeQuestion3', type: 'checkbox', col: 12 },
                            { label: 'A separate home for your family was maintained in U.S. while residing abroad.', name: 'foreignIncomeQuestion4', type: 'checkbox', col: 12 },
                        ]
                    },
                    {
                        groupId: 3,
                        groupType: 'details',
                        fields: [
                            { label: 'Foreign Employment Details', name: 'employmentDetailsLabel', type: 'label', class: 'labelTextDenseRow' },
                        ]
                    },
                    {
                        groupId: 4,
                        groupType: 'details',
                        fields: [
                            { label: 'Please provide monthly pay-slips for the calendar year (January 1-December 31).', type: 'label', class: 'groupDenseRowDetails' },
                        ]
                    },
                    {
                        groupId: 5,
                        groupType: 'lineSection',
                        uploadType: 'drawer',
                        fields: [
                            { label: 'Employer', name: 'foreignIncomeEmployer', type: 'freeText', },
                            { label: ' ', name: 'foreignIncomeOwner', type: 'label', isSyncFieldValue: true, },
                            { label: 'Assignment Start Date', name: 'assignmentStartDate', type: 'date', maxDate: 'today', validations: { ...Validators.maxDate(), }, logicFunction: [{ trigger: 'setLineItemMinEndDate', endDateName: 'assignmentEndDate' }] },
                            { label: 'Assignment End Date', name: 'assignmentEndDate', type: 'date', validations: { ...Validators.minDate(), }, },
                            { label: '', name: 'visaType', type: 'label', isSyncFieldValue: true, },
                            { type: 'lineButtons' }
                        ],
                        lineItems: [],
                        lineItemDetails: {
                            headerLabels: [
                                { label: 'Employer', col: 3, colTablet: 2, align: 'left', styles: { paddingLeft: 0 } },
                                { label: 'Taxpayer / Spouse', col: 2, colTablet: 2, align: 'left', styles: { paddingLeft: '10px' } },
                                { label: 'Assignment Start Date', col: 2, colTablet: 1, align: 'left' },
                                { label: 'Assignment End Date', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '21px' } },
                                { label: 'Visa type used to enter Country', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '28px' } },
                                { label: 'Monthly Pay Slips (Jan 1 - Dec 31)', col: 1, colTablet: 1, align: 'noPaddingLeft' }
                            ],
                            footerLabels: [
                                { type: 'buttons', col: 12, buttonTitle: 'Additional Foreign Employment Details', icon: 'add' },
                            ],
                            lineButtons: [
                                { button: 'upload', uploadSectionName: FOREIGN_EMPLOYMENT_UPLOAD, viewSectionId: 0 },
                            ]
                        },
                    },

                ]
            },
            {
                title: 'Foreign Housing',
                sectionId: 3,
                isVisible: true,
                isDenseRow: true,
                isCollapsed: true,
                groups: [
                    {
                        groupId: 1,
                        groupType: 'details',
                        fields: [
                            { label: 'Please enter the foreign living expenses below', type: 'label', class: 'groupDenseRowDetails' },
                        ]
                    },
                    {
                        groupId: 2,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Select foreign living quarters', name: 'foreignQuartersLabel', type: 'label', col: 9, class: 'labelTextNoLeftPadding' },
                            {
                                label: 'Foreign Living Quarters', name: 'foreignLivingQuarter', type: 'select', lookup: 'FOREIGN_LIVING_QUARTERS', col: 3,
                                triggered: 'setForeignIncomeCurrencyRequired',
                            },
                        ]
                    },
                    {
                        groupId: 3,
                        isVisible: true,
                        hasDivider: false,
                        fields: [
                            { label: 'Please select the foreign currency for the expenses below:', name: 'foreignCurrencyLabel', type: 'label', col: 9, class: 'labelTextNoLeftPadding' },
                            {
                                label: 'Foreign Currency Type', name: 'foreignIncomeCurrency', type: 'select', lookup: 'FOREIGN_CURRENCIES', col: 3, logicFunction: [
                                    { trigger: 'setForeignIncomeCurrencySymbol' },
                                ]
                            },
                        ]
                    },
                    {
                        groupId: 4,
                        isVisible: false,
                        groupType: 'details',
                        class: 'denseRowGroup',
                        fields: [
                            { label: 'Foreign Housing Expenses', name: 'foreignHousingExpenseLabel', type: 'label', class: 'labelTextDenseRow' },
                        ]
                    },
                    {
                        groupId: 5,
                        isVisible: false,
                        groupType: 'lineSection',
                        uploadType: 'drawer',
                        fields: [
                            { label: 'Type of Expense', name: 'foreignHousingExpenseType', type: 'freeText', class: 'labelRequired', },
                            { label: 'Amount', name: 'amountReimbursed', type: 'money', isTotal: true, logicFunction: [{ trigger: 'setAmountReimbursedAndPaidTotal', }] },
                            { label: 'Amount', name: 'amountPaid', type: 'money', isTotal: true, logicFunction: [{ trigger: 'setAmountReimbursedAndPaidTotal', }] },
                            { label: '', name: 'totalReimbursedAndPaid', type: 'label', isTotal: true, },
                            { type: 'lineButtons' }
                        ],
                        prePopulate: [
                            { label: 'Rent', prePopulateAugments: [{ name: 'rentReimbursed' }, { name: 'rentPaid' }] },
                            { label: 'Real estate, occupancy and television taxes', prePopulateAugments: [{ name: 'realEstateReimbursed' }, { name: 'realEstatePaid' }] },
                            { label: 'Utilities not including telephone', prePopulateAugments: [{ name: 'utilitiesReimbursed' }, { name: 'utilitiesPaid' }] },
                            { label: 'Real and personal property insurance', prePopulateAugments: [{ name: 'insuranceReimbursed' }, { name: 'insurancePaid' }] },
                            { label: 'Non refundable deposits paid to secure a lease', prePopulateAugments: [{ name: 'depositReimbursed' }, { name: 'depositPaid' }] },
                            { label: 'Temporary living expenses', prePopulateAugments: [{ name: 'temporaryExpensesReimbursed' }, { name: 'temporaryExpensesPaid' }] },
                        ],
                        prePopulateIndexes: [{ currentIndex: 1 }, { currentIndex: 2 }],
                        prePopulateControls: ['label', 'money', 'money', 'label'],
                        lineItems: [],
                        lineItemDetails: {
                            headerLabels: [
                                { label: 'Expense Type', col: 5, colTablet: 2, align: 'left', styles: { paddingLeft: 0 } },
                                { label: 'Amount reimbursed to you or paid on your behalf by your employer', col: 2, colTablet: 2, align: 'right', styles: { paddingRight: '13px' } },
                                { label: 'Amount Paid by you which was not reimbursed by your employer', col: 2, colTablet: 2, align: 'right', styles: { paddingRight: '10px' } },
                                { label: 'Total', col: 2, colTablet: 1, align: 'right', styles: { paddingRight: 0 } },
                                { label: 'Actions', col: 1, colTablet: 1, align: 'left' },
                            ],
                            footerLabels: [
                                { type: 'buttons', col: 12, buttonTitle: 'Additional Foreign Housing Expense', icon: 'add' },
                                { type: 'label', label: 'Total', col: 5, colTablet: 2 },
                                { type: 'totalMoney', name: 'amountReimbursed', col: 2, colTablet: 2, label: '$ 0', },
                                { type: 'totalMoney', name: 'amountPaid', col: 2, colTablet: 2, label: '$ 0', },
                                { type: 'totalMoney', name: 'totalReimbursedAndPaid', col: 2, colTablet: 1, label: '$ 0', logicFunction: [{ trigger: 'setCurrentHousingExpense', }] },

                            ],
                            lineButtons: [
                                { button: 'upload', uploadSectionName: FOREIGN_HOUSING_UPLOAD, viewSectionId: 0 },
                            ]
                        },
                    },
                ],
            },
        ],
        [
            {
                title: 'Address Details',
                isVisible: true,
                sectionId: 4,
                isCollapsed: true,
                isDenseRow: true,
                groups: [
                    {
                        groupId: 1,
                        groupType: 'details',
                        fields: [
                            { label: 'Enter details only if a U.S. property.', type: 'label' },
                        ]
                    },
                    {
                        groupId: 2,
                        isVisible: true,
                        fields: [
                            { 
                                label: 'Street Address', 
                                name: 'IFDSREST.23', 
                                type: 'freeText', 
                                logicFunction: [{ trigger: 'setAddressSummaryLabel', }], 
                                col: 12, 
                                prior: true,
                                isPriorEditable: true
                            },
                            { 
                                label: 'City', 
                                name: 'IFDSREST.24', 
                                type: 'freeText', 
                                logicFunction: [{ trigger: 'setAddressSummaryLabel', }], 
                                col: 5, 
                                prior: true,
                                isPriorEditable: true 
                            },
                            { 
                                label: 'State', 
                                name: 'IFDSREST.25', 
                                type: 'select', 
                                lookup: 'STATES', 
                                logicFunction: [{ trigger: 'setAddressSummaryLabel', }], 
                                col: 5, 
                                prior: true,
                                isPriorEditable: true 
                            },
                            { 
                                label: 'Zip', 
                                name: 'IFDSREST.26', 
                                type: 'zippostal', 
                                logicFunction: [{ trigger: 'setAddressSummaryLabel', }], 
                                col: 2, 
                                prior: true,
                                isPriorEditable: true
                            },
                        ]
                    }
                ],
            },
            {
                title: 'Occupant Details',
                collapseClass: 'collapsibleForm',
                isVisible: true,
                sectionId: 5,
                isCollapsed: true,
                isDenseRow: true,
                groups: [
                    {
                        groupId: 1,
                        groupType: 'lineSection',
                        uploadType: 'drawer',
                        axcessGroup: {
                            pull: {
                                fromSections: ['Bona Fide Residence Test'],
                                fieldPrefixes: ['IFDSREST'],
                                lineSplitterType: 'splitOnIndex',
                                lineSplitters: [],
                                lineFilterType: 'any',
                                lineFilters: [
                                    { key: 'IFDSREST.28', filterType: 'containsAnyValue' },
                                    { key: 'IFDSREST.29', filterType: 'containsAnyValue' },
                                    { key: 'IFDSREST.30', filterType: 'containsAnyValue' },
                                    { key: 'IFDSREST.31', filterType: 'containsAnyValue' },
                                ],
                            },
                        },
                        fields: [
                            { label: 'First Name', name: 'IFDSREST.28', type: 'freeText', maxLength: 20, allowSpecialCharacters: false, logicFunction: [{ trigger: 'setOccupantSummaryLabel', }], prior: true },
                            { label: 'Middle Initial', name: 'IFDSREST.29', type: 'freeText', maxLength: 1, allowSpecialCharacters: false, logicFunction: [{ trigger: 'setOccupantSummaryLabel', }], prior: true },
                            { label: 'Last Name', name: 'IFDSREST.30', type: 'freeText', maxLength: 20, allowSpecialCharacters: false, logicFunction: [{ trigger: 'setOccupantSummaryLabel', }], prior: true },
                            { label: 'Relationship', name: 'IFDSREST.31', type: 'select', lookup: 'FOREIGN_SEPERATE_HOME_RELATIONSHIP', prior: true },
                            { type: 'lineButtons' }
                        ],
                        lineItems: [],
                        lineItemDetails: {
                            headerLabels: [
                                { label: 'First Name', col: 3, colTablet: 2, align: 'left', },
                                { label: 'Middle Initial', col: 3, colTablet: 1, align: 'left', },
                                { label: 'Last Name', col: 3, colTablet: 2, align: 'left', },
                                { label: 'Relationship', col: 2, colTablet: 2, align: 'left', },
                                { label: 'Actions', col: 1, colTablet: 1, align: 'left', }
                            ],
                            footerLabels: [
                                { type: 'buttons', col: 12, buttonTitle: 'Additional Occupant Details', icon: 'add' },
                            ],
                            lineButtons: [
                                { button: 'upload', uploadSectionName: FOREIGN_OCCUPANT_DETAILS_UPLOAD, viewSectionId: 0 },
                            ]
                        }
                    },
                ],
            },
        ]
    ];

    const lineItemGroup = [
        {
            title: 'Travel and Workday History',
            isVisible: true,
            sectionId: 1,
            hasDenseRow: true,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Please provide your travel and workday history.', type: 'label' },
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    isUniqueCurrencyPerLine: true,
                    fields: [
                        { label: 'T/S', name: 'foreignIncomeOwner', type: 'select', lookup: 'PAYER_TS', isVisible: true, isSyncFieldValue: true, logicFunction: [{ trigger: 'syncDownForeignIncomeOwner', }] },
                        {
                            label: 'Country', name: 'foreignIncomeCountry', type: 'select', isVisible: true, lookup: 'FOREIGN_COUNTRIES',
                            logicFunction: [{ trigger: 'setTravelStateProvince', }]
                        },
                        { label: 'State/Province', name: 'foreignIncomeStateProv', type: 'freeText', isVisible: true, },
                        { label: 'State', name: 'foreignIncomeState', type: 'select', lookup: 'STATES', isVisible: false },
                        { label: 'Province', name: 'foreignIncomeProv', type: 'select', lookup: 'PROVINCES', isVisible: false },
                        { label: 'Total Days: 0\nTotal Workdays: 0', name: 'foreignIncomeSummary', type: 'label', isVisible: true, styles: { whiteSpace: 'pre-line', paddingTop: 0 } },
                        { label: 'Details', name: 'additionalInfo', type: 'viewButton', viewSectionId: 100, isVisible: true, },
                        { label: '$0', name: 'priorHousingExpense', type: 'label', isTotal: true, isVisible: true, },
                        { label: '', name: 'currentHousingExpense', type: 'label', isSyncFieldValue: true, isVisible: true, },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    lineSections: sections[0],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '48px' } },
                            { label: 'Country', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '44px' } },
                            { label: 'State/Province', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '40px' } },
                            { label: 'Summary Info', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '34px' } },
                            { label: 'Additional Info', col: 1, colTablet: 1, align: 'left', styles: { paddingLeft: '11px' } },
                            { label: `${year.prior} Housing Expenses (USD)`, col: 1, colTablet: 1, align: 'right', styles: { paddingRight: '11px' } },
                            { label: `${year.current} Housing Expenses`, col: 1, colTablet: 1, align: 'right', styles: { paddingRight: '11px' } },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft', styles: { paddingLeft: '11px' } }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Travel and Workday History', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: FOREIGN_INCOME_TRAVEL_WORKDAY_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Separate Home Information',
            isVisible: true,
            sectionId: 2,
            hasDenseRow: true,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'Enter details only if a U.S. property.', type: 'label' },
                    ]
                },
                {
                    groupId: 2,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    axcessGroup: {
                        pull: {
                            fromSections: ['Bona Fide Residence Test'],
                            fieldPrefixes: ['IFDSREST'],
                        },
                    },
                    fields: [
                        { label: 'Home', name: 'home', type: 'freeText', },
                        {
                            label: '',
                            name: 'addressSummary',
                            type: 'label',
                            styles: { whiteSpace: 'pre-line', paddingTop: 0, wordWrap: 'break-word' },
                            prior: true,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSREST.23', 'IFDSREST.24', 'IFDSREST.25', 'IFDSREST.26'],
                                fn: 'foreignIncomeHomeAddressPull',
                            }
                        },
                        {
                            label: '',
                            name: 'occupantSummary',
                            type: 'label',
                            styles: { whiteSpace: 'pre-line', paddingTop: 0, },
                            prior: true,
                            pullCalculation: {
                                lineSplitterType: 'splitOnIndex',
                                lineSplitters: [],
                                lineFilterType: 'any',
                                lineFilters: [
                                    { key: 'IFDSREST.28', filterType: 'containsAnyValue' },
                                    { key: 'IFDSREST.29', filterType: 'containsAnyValue' },
                                    { key: 'IFDSREST.30', filterType: 'containsAnyValue' },
                                    { key: 'IFDSREST.31', filterType: 'containsAnyValue' },
                                ],
                                pullLines: true,
                                keyIdentifiers: ['IFDSREST.28', 'IFDSREST.29', 'IFDSREST.30'],
                                fn: 'foreignIncomeHomeOccupantPull',
                            }
                        },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    lineSections: sections[1],
                    currentEntity: -1,
                    subWorkSheets: [],
                    entityIdentifiers: [
                        {
                            axcessDataType: 'PassDataToChild',
                        },
                        {
                            axcessDataType: 'PassDataToChild',
                        },
                    ],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Home', col: 3, colTablet: 2, align: 'left', styles: { paddingLeft: '48px' } },
                            { label: 'Address Summary', col: 3, colTablet: 2, align: 'left', styles: { paddingLeft: '44px' } },
                            { label: 'Occupant Summary', col: 5, colTablet: 3, align: 'left', styles: { paddingLeft: '21px' } },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'left', styles: { paddingLeft: '11px' } }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Separate Home Information', icon: 'add' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: FOREIGN_SEPARATE_HOME_INFO_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'Foreign Taxes',
            isVisible: true,
            sectionId: 3,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: '', type: 'label' },
                    ]
                }, {
                    groupId: 2,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    fields: [
                        { label: 'Country', name: 'IFDSGEN.24', type: 'select', lookup: 'FOREIGN_COUNTRIES', col: 6, placeholder: 'Select Country', prior: true, isPriorEditable: true, default: 'US' },
                        { label: '', name: 'IsFilledForeignTax', type: 'select', lookup: 'YESNO' },
                        { label: '', name: 'IsPaidForeignTax', type: 'select', lookup: 'YESNO' },
                        { label: '', name: '', type: 'label', },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    lineSections: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Country of Residence', col: 3, colTablet: 3, align: 'left' },
                            { label: 'Filed or will file a foreign tax return?', col: 2, colTablet: 2, align: 'left' },
                            { label: 'Paid or will make additional foreign tax payments?', col: 2, colTablet: 2, align: 'left' },
                            { col: 4, colTablet: 0, align: 'right' },
                            { label: 'Previously filed tax return or foreign tax payment copy', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: FOREIGN_INCOME_ADD_ANOTHER_ACCOUNT, icon: 'add' },
                        ],
                        lineButtons: [
                            // { button: 'uploadNa', uploadSectionName: FOREIGN_INCOME_TAXES_UPLOAD, viewSectionId: 0, styles:{float: 'right'} },
                            { button: 'uploadNa', uploadSectionName: FOREIGN_INCOME_TAXES_UPLOAD, viewSectionId: 0, },
                        ]
                    }
                }

            ],
        },
    ];

    return lineItemGroup;
};

export default foreignIncomeFn;