import React from "react";
import { Page } from 'react-pdf';

const SinglePagePDF = (props) => {
    const { mainState, pageNumber } = props;

    return (
        <div style={{ padding: '20px' }}>
            <Page
                pageNumber={pageNumber}
                scale={1}
                height={(mainState?.rendererRect?.height || 100) - 100}
                width={(mainState?.rendererRect?.width || 100) - 100}
                loading={<></>}
                renderTextLayer={false}
                renderAnnotationLayer={false}
            />
        </div>
    );
};

export default SinglePagePDF;