import {
    HOUSEHOLD_EMPLOYMENT_TAXES_UPLOAD,
    STATE_UNEMPLOYMENT_UPLOAD,
} from '@utilities/constants/strings';

const householdFn = (year) => {
    const sections = [
        {
            title: 'Household Employment Taxes',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    class: 'householdEmploymentUpload',
                    fields: [
                        {
                            label: 'If you have filed a W-3/W-2 or paid state unemployment taxes for a household employee, please upload your records here. Alternatively, please complete the section below.',
                            name: 'w3w2FiledDomesticEmployeesLabel',
                            type: 'label',
                            col: 9
                        },
                        { type: 'lineButtons', col: 3 }
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [],
                        footerLabels: [],
                        lineButtons: [
                            { button: 'uploadOnly', uploadSectionName: HOUSEHOLD_EMPLOYMENT_TAXES_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    isShowFooter: false,
                    groupId: 2,
                    fields: [
                        //TODO Cash wages Medicare Taxes Access Field: IFDSSCHS.1
                        //TODO Cash wages Social Security Taxes Access: IFDSSCHS.0
                        { label: 'Household employee wages/taxes', name: '', type: 'label' },
                        { label: '$ 0', wrapLabel: '2019 Total Wages Paid', name: 'IFDSSCHS.0', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2021 Total Wages Paid', name: 'C-IFDSSCHS.0', type: 'money', isTotal: true, placeholder: '0' },
                        { label: 'Amount', wrapLabel: '2021 Federal Taxes Withheld', name: 'IFDSSCHS.2', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { col: 5, colTablet: 1 },
                            { label: `${year.prior} Total Wages Paid`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Total Wages Paid`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Federal Taxes Withheld`, col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [],
                        lineButtons: [
                            { button: 'na', uploadSectionName: HOUSEHOLD_EMPLOYMENT_TAXES_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
        {
            title: 'State Unemployment / Insurance Contributions',
            isVisible: true,
            sectionId: 2,
            groups: [
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { label: 'State', name: 'IFDSFUTA.4', type: 'select', lookup: 'STATES_CODE', placeholder: 'Select', prior: true },
                        { label: ' ', name: '', type: 'label' },
                        { label: '$ 0', wrapLabel: '2019 Amount Withheld', name: 'IFDSFUTA.10', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount Withheld', name: 'C-IFDSFUTA.10', type: 'money', isTotal: true, placeholder: '0' },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'State', col: 2, colTablet: 2, },
                            { col: 5, colTablet: 1, align: 'right' },
                            { label: `${year.prior} Amount Paid`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount Paid`, col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional State Unemployment / Insurance Contributions', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3, },
                            { type: 'totalMoney', name: 'IFDSFUTA.10', col: 2, colTablet: 2, year: 'prior', label: '$ 0' },
                            { type: 'totalMoney', name: 'C-IFDSFUTA.10', col: 2, colTablet: 2, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: STATE_UNEMPLOYMENT_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ]
        },
    ];

    return sections;
};

export default householdFn;