import React, { useState } from "react";
import { Document } from "react-pdf";
import SinglePagePDF from './components/SinglePagePDF';

// GROWTH: styling on the preview contents
const PDFDocumentPreview = (props) => {
    const { mainState } = props;
    const [numPages, setNumPages] = useState(0);

    if (mainState?.currentDocument?.fileData === undefined) {
        return null;
    }

    const handlePDFLoad = (metadata) => {
        const { numPages } = metadata;
        setNumPages(numPages);
    };

    const renderPages = () => {
        const renderedPages = [];

        for (let index = 0; index < numPages; index++) {
            renderedPages.push(
                <SinglePagePDF mainState={mainState} pageNumber={index + 1} />
            );
        }

        return (<>{renderedPages}</>);
    };

    return (
        <div
            id='pdf-content-previewer'
            data-testid='pdf-content-previewer'
        >
            <Document
                file={mainState.currentDocument.fileData}
                onLoadSuccess={handlePDFLoad}
                loading={<></>}
            >
                {renderPages()}
            </Document>

        </div>
    );
};

PDFDocumentPreview.fileTypes = ['pdf', 'application/pdf'];
PDFDocumentPreview.weight = 0;

export default PDFDocumentPreview;