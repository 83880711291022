import { Validators } from 'cla-formrenderer';
import {
    OTHER_DEDUCTIONS_EDUCATOR_UPLOAD,
    OTHER_DEDUCTIONS_HSA_CONT_UPLOAD,
    OTHER_DEDUCTIONS_HSA_DIST_UPLOAD,
    OTHER_DEDUCTIONS_STUDENT_LOAN_UPLOAD,
    OTHER_DEDUCTIONS_ALIMONY_PAID_UPLOAD,
    OTHER_DEDUCTIONS_AF_MOVING_UPLOAD,
    OTHER_DEDUCTIONS_EMPLOYEE_BUSINESS_EXPENSE_UPLOAD,
    OTHER_DEDUCTIONS_AF_MILES_UPLOAD,
} from '@utilities/constants/strings';

const otherDeductionsFn = (year) => {
    const sections = [
        [
            {  
                title: 'Divorce/Separation Details',
                isVisible: true,
                sectionId: 1,
                isCollapsed: true,
                isDenseRow: true,
                groups: [                   
                    {
                        groupId: 1,
                        fields: [
                            { 
                                label: 'Taxpayer / Spouse', 
                                wrapLabel: 'Taxpayer / Spouse', 
                                name: 'IFDSALPD.0', 
                                type: 'select', 
                                lookup: 'PAYER_TS', 
                                prior: true, 
                                isPriorEditable: true,
                                logicFunction: [
                                    { trigger: 'setOtherDeductionsLabel' }
                                ] 
                            }, 
                            { 
                                label: 'Date of divorce or separation', 
                                name: 'IFDSALPD.17', 
                                type: 'date', 
                                placeholder: 'Select Date', 
                                prior: true, 
                                isPriorEditable: true,
                                axcessTranslate: 'date', 
                                labelFormat: 'date',
                                maxDate: `12/31/${year.current}`,
                                minDate: '01/01/1950',
                                logicFunction: [{
                                    trigger: 'setDateValidation'
                                }] 
                            },  
                            { 
                                label: 'Recipient\'s Name', 
                                name: 'IFDSALPD.5', 
                                type: 'freeText', 
                                prior: true, 
                                isPriorEditable: true,
                                logicFunction: [{
                                    trigger: 'setConditionalFieldValidation', 
                                    requiredFields: ['IFDSALPD.17', 'IFDSALPD.6']
                                }] 
                            },           
                            { label: 'Recipient\'s SSN', wrapLabel: 'Recipient\'s SSN', name: 'IFDSALPD.6', type: 'ssn', prior: true, overRideText: 'ON FILE', axcessTranslate: 'omitOnOverride', isMaskOnBlur: true, validations: {
                                ...Validators.ssn(),
                            },
                            }, 
                        ],
                    }
                ]
            },
        ]
    ];
    
    const lineItemGroup = [
        {
            title: 'Adjustments to Income',
            isVisible: true,
            sectionId: 1,
            barcodeName: 'barcodeAdjustmentsToIncome',
            groups: [],
            subSections: [
                { subSectionId: 2 }, 
                { subSectionId: 3 }, 
                { subSectionId: 4 }
            ]
        },
        {  
            title: 'Educator Expenses',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 2,
            isSubSection: true,
            isSubSectionExpanded: true,
            groups: [          
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: 'If you were an eligible K-12 teacher, instructor, counselor, principal, or aide, you may deduct up to $300 ($600 if filing jointly and both spouses were eligible educators) for qualified expenses used in the classroom.', type: 'label', class:'subSeCDetails'},
                    ]
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        { label: 'T/S', wrapLabel: 'Taxpayer/Spouse', name: 'IFDSODEE.3', type: 'select', lookup: 'PAYER_TS', prior: true  },
                        { label: ' ', name: '', type: 'label' },
                        { label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSODEE.0', type: 'label', isTotal: true, prior: true },
                        { label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSODEE.0', type: 'money', isTotal: true, placeholder: '0', validations: {
                            ...Validators.maxValue(300),
                        } },    
                        { type: 'lineButtons' }
                                            
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Expense Details', col: 2, colTablet: 3 },
                            { col: 5, colTablet: 0, },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2,align: 'right' },  
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },                          
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Educator Expense', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 3  },
                            { type: 'totalMoney', name: 'IFDSODEE.0', col: 2, colTablet: 2, year: 'prior', label: '$ 0', isTotal: true, },
                            { type: 'totalMoney', name: 'C-IFDSODEE.0', col: 2, colTablet: 2, year: 'current', label: '$ 0', isTotal: true, isSubSectionTotal: true },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_EDUCATOR_UPLOAD, viewSectionId: 0}
                        ]
                    }
                }
            ]
        },
        {  
            title: 'Health Savings Account - Contributions',
            isVisible: true,
            sectionId: 3,
            isSubSection: true,
            isSubSectionExpanded: false,
            groups: [               
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    hasSubSectionTotalDisplay: true,
                    logicFunction: [{trigger: 'setHSASpouseRow'}],
                    axcessGroup: {
                        pull: {
                            fromSections: [ 'General' ],
                            fieldPrefixes: [ 'IFDS889G', 'IFDS889C' ],
                            lineFilterType: 'any',
                            lineFilters: [
                                { key: 'IFDS889G.4', filterType: 'containsAny', filterData: [ 'T', 'S' ] },
                            ],
                            postPopulateFn: 'hsaTablePopulate',
                            postPopulateMetadata: {
                                sumOnColumns: [ 'IFDS889C.4' ],
                            },
                        },
                    },
                    fields: [
                        {  label: 'Select', wrapLabel: 'Taxpayer / Spouse', name: 'IFDS889G.4', type: 'select', lookup: 'PAYER', prior: true  },
                        { label: 'Contributions made during year (excluding any amounts contributed via payroll through your employer)',type: 'label'},
                        { label: '$ 0', name: 'IFDS889C.4', type: 'label',  isTotal: true, prior: true },
                        { label: 'Amount', name: 'C-IFDS889C.4', type: 'money', isTotal: true, placeholder: '0' },   
                        { type: 'lineButtons' },
                                             
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Distributions Details', col: 2, colTablet: 1 },
                            { label: 'Description', col: 5, colTablet: 1 },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 1, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },  
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft', },  
                                                      
                        ],
                        footerLabels: [
                            { type: 'label', label: 'Total', col: 7, colTablet: 4 },
                            { type: 'totalMoney', name: 'IFDS889C.4', col: 2, colTablet: 1, year: 'prior', label: '$ 0',isTotal: true },
                            { type: 'totalMoney', name: 'C-IFDS889C.4', col: 2, colTablet: 2, year: 'current', label: '$ 0' ,isTotal: true, isSubSectionTotal: true},                           
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_HSA_CONT_UPLOAD, viewSectionId:0 }
                        ]
                    }
                },
            ]
        },
        {  
            title: 'Health Savings Account - Distributions',
            isVisible: true,
            sectionId: 4,
            isSubSection: true,
            isSubSectionExpanded: false,
            groups: [               
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    hasSubSectionTotalDisplay: true,
                    logicFunction: [{trigger: 'setHSASpouseRow'}],
                    axcessGroup: {
                        pull: {
                            fromSections: [ 'General', 'Distributions From an HSA or MSA (IRS 1099-SA)' ],
                            fieldPrefixes: [ 'IFDS889G', 'IFDS889D', 'IFDSDIST' ],
                            lineFilterType: 'any',
                            lineFilters: [
                                { key: 'IFDS889G.4', filterType: 'containsAny', filterData: [ 'T', 'S' ] },
                                { key: 'IFDSDIST.3', filterType: 'containsAny', filterData: [ 'T', 'S' ] },
                            ],
                            postPopulateFn: 'hsaTablePopulate',
                            postPopulateMetadata: {
                                sumOnColumns: [ 'IFDS889D.0' ],
                            },
                        },
                    },
                    fields: [
                        {
                            label: 'Select', wrapLabel: 'Taxpayer / Spouse', name: 'IFDS889G.4', type: 'select', lookup: 'PAYER', prior: true,
                            pullCalculation: {
                                keyIdentifiers: [ 'IFDS889G.4', 'IFDSDIST.3' ],
                                fn: 'anyValidPull',
                            }
                        },
                        { label: 'Y/N', name: 'isAllHsaDistForUnreimBursedMedicalExpenses', type: 'select', lookup: 'YESNO', col: 2, triggered: 'setIsAllHsaDistForUnreimBursedMedicalExpenses' },
                        { label: 'Amount', name: 'amountNotUsedForUnreimbursedMedicalExpenses', type: 'money', isTotal: true, col: 2, isDisabled: undefined }, // isVisible: false
                        {
                            label: '$ 0', name: 'IFDS889D.0', type: 'label',  isTotal: true, prior: true,
                            pullCalculation: {
                                keyIdentifiers: [ 'IFDS889D.0', 'IFDSDIST.39' ],
                                fn: 'anyValidPull',
                            }
                        },
                        { label: 'Amount', name: 'C-IFDS889D.0', type: 'money', isTotal: true, placeholder: '0' },   
                        { type: 'lineButtons' },
                                             
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Distributions Details', col: 2, colTablet: 1 },
                            { label: 'Were all distributions for unreimbursed medical expenses?', col: 3, colTablet: 2,  align: 'left' },
                            { label: 'Amount not used for unreimbursed medical expenses?', col: 2, colTablet: 1, align: 'right' }, 
                            { label: `${year.prior} Amount`, col: 2, colTablet: 1, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },  
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft' },  
                        ],
                        footerLabels: [
                            { type: 'label', label: 'Total', col: 7, colTablet: 4 },
                            { type: 'totalMoney', name: 'IFDS889D.0', col: 2, colTablet: 1, year: 'prior', label: '$ 0',isTotal: true },
                            { type: 'totalMoney', name: 'C-IFDS889D.0', col: 2, colTablet: 2, year: 'current', label: '$ 0' ,isTotal: true, isSubSectionTotal: true},                           
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_HSA_DIST_UPLOAD, viewSectionId:0 }
                        ]
                    }
                },
            ]
        },
        {  
            title: 'Student Loan Interest Deduction (Form 1098-E)',
            shortTitle: 'Student Loan Interest Deduction',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 5,
            barcodeName: 'barcodeStudentLoanInterestDeduction',
            groups: [ 
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { label: 'Please upload applicable documents', type: 'label'},
                    ]
                },              
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        {  label: 'T/S', wrapLabel: 'Taxpayer / Spouse', name: 'IFDSSINT.13', type: 'select', lookup: 'PAYER_TS', prior: true, axcessTranslate: 'jointToTaxpayer' },
                        {  label: 'Lender', name: 'IFDSSINT.17', type: 'freeText', prior: true },
                        {  label: '$ 0', wrapLabel: '2019 Amount', name: 'IFDSSINT.2', type: 'label', isTotal: true, prior: true }, 
                        {  label: 'Amount', wrapLabel: '2020 Amount', name: 'C-IFDSSINT.2', type: 'money', isTotal: true, placeholder: '0' },   
                        {  type: 'lineButtons' }                     
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Taxpayer / Spouse', smallScreenLabel: 'Interest Deduction Details', col: 2, colTablet: 2 },
                            { label: 'Lender', col: 5, colTablet: 2 },
                            { label: `${year.prior} Amount`, col: 2, colTablet: 1, align: 'right' },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },  
                            { label: '1098-E', col: 1, colTablet: 1, align: 'noPaddingLeft' }                          
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Student Loan Deduction', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 4 },
                            { type: 'totalMoney', name: 'IFDSSINT.2', col: 2, colTablet: 1, year: 'prior', label: '$ 0',isTotal: true },
                            { type: 'totalMoney', name: 'C-IFDSSINT.2', col: 2, colTablet: 2, year: 'current', label: '$ 0' ,isTotal: true},  
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_STUDENT_LOAN_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                }
            ]
        },
        {
            title: 'Additional Adjustments To Income',
            isVisible: true,
            sectionId: 6,
            barcodeName: 'barcodeAdditionalAdjustmentsToIncome',
            groups: [],
            subSections: [
                { subSectionId: 7 }, 
                { subSectionId: 8 }, 
                { subSectionId: 9 }
            ]
        },
        {  
            title: 'Alimony Paid',
            isVisible: true,
            sectionId: 7,
            isSubSection: true,
            isSubSectionExpanded: false,
            hasDenseRow: true,
            groups: [
                {
                    groupId: 1,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    hasSubSectionTotalDisplay: true,
                    axcessGroup: {
                        pull: {
                            fromSections: [ 'Alimony Paid' ],
                            fieldPrefixes: [ 'IFDSALPD' ],
                        }
                    },
                    fields: [
                        { 
                            label: '', 
                            name: 'Spouse', 
                            type: 'label', 
                            styles: { whiteSpace: 'pre-line', paddingTop: 0, },
                            prior: true,
                            pullCalculation: {
                                keyIdentifiers: [ 'IFDSALPD.0' ],
                                fn: 'tsNamePull',
                            }
                        },
                        { 
                            label: 'View Details', 
                            name: 'additionalInfo', 
                            type: 'viewButton', 
                            viewSectionId: 100, 
                        },
                        { label: '$ 0', name: 'IFDSALPD.7', type: 'label', isTotal: true, wrapLabel: '2020 Amount',  prior: true },
                        { label: 'Amount', name: 'C-IFDSALPD.7', type: 'money', isTotal: true,  placeholder: '0' },                  
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineSections: sections[0],
                    subWorkSheets: [],
                    entityIdentifiers: [
                        {
                            axcessDataType: 'PassDataToChild',
                        },
                    ],
                    lineItemDetails: {
                        headerLabels: [
                            { 
                                label: 'Divorce/Separation Summary', 
                                smallScreenLabel: 'Dependent Information', 
                                col: 3, 
                                colTablet: 2, 
                                colPhone: 2, 
                                styles: { paddingLeft: '48px' }
                            },
                            { 
                                label: 'Divorce/Separation Details', 
                                col: 4, 
                                colTablet: 1, 
                                colPhone: 2, 
                                styles: { textAlign: 'center', paddingLeft: '40px' }
                            },
                            { 
                                label: `${year.prior} Amount`, 
                                col: 2, 
                                colTablet: 2, 
                                colPhone: 2, 
                                align: 'right'
                            },
                            { 
                                label: `${year.current} Amount`, 
                                col: 2, 
                                colTablet: 2, 
                                colPhone: 2, 
                                align: 'right'
                            },
                            { 
                                label: 'Actions', 
                                col: 1, 
                                colTablet: 1, 
                                colPhone: 2, 
                                align: 'right'
                            },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Alimony Payment', icon: 'add' },
                            { type: 'label', label: 'Total', col: 7, colTablet: 5 },
                            { type: 'totalMoney', name: 'IFDSALPD.7', col: 2, colTablet: 1, year: 'prior', label: '$ 0', isTotal: true },
                            { type: 'totalMoney', name: 'C-IFDSALPD.7', col: 2, colTablet: 1, year: 'current', label: '$ 0' ,isTotal: true, isSubSectionTotal: true},                           
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_ALIMONY_PAID_UPLOAD, viewSectionId: 0 },  
                        ]
                    }
                }
            ]    
        },
        {  
            title: 'Armed Forces Moving Expenses',
            isVisible: true,
            sectionId: 8,
            isSubSection: true,
            isSubSectionExpanded: false,
            groups: [               
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        {  name: 'mileage', type: 'label', label: 'Miles from old home to new workplace' },  
                        { label: ' ', name: '', type: 'label' },
                        // {  label: '0', name: 'armedForcesMovingMileagePy', type: 'label', isTotal: true, isMoney: false },
                        {  label: 'Miles', name: 'armedForcesMovingMileageCy', type: 'total', isTotal: true, isMoney: false, placeholder: '0' }, 
                        {  type: 'lineButtons' },   
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Mileage', smallScreenLabel: 'Moving Expense Details', col: 7, colTablet: 3, },
                            { col: 2, colTablet: 2 },
                            // { label: '2019 Miles', col: 2, colTablet: 2, align: 'right' },
                            { label: `${year.current} Miles`, col: 2, colTablet: 2, align: 'right' },   
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft'}                                                   
                        ],
                        footerLabels: [],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_AF_MILES_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 2,
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        { label: 'Transportation Expense', name: 'transportationExpense', type: 'label', },
                        { label: 'Amount', name: 'armedForcesTranspoExpenseCyAmount', type: 'money', isTotal: true, isMoney:true, placeholder: '0' },
                        {  type: 'lineButtons' },  
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    prePopulate: [
                        {label: 'Cost of transportation of household goods', prePopulateAugments: [ { name: 'transpoCost' } ] },
                        {label: 'Cost of travel and lodging (does not include meals or auto expenses)', prePopulateAugments: [ { name: 'travelLodgingCost' } ] },
                        {label: 'Automobile expenses (gasoline, oil, etc.)', prePopulateAugments: [ { name: 'autoExpense' } ] },
                    ],
                    prePopulateIndexes: [ { currentIndex: 2 } ],
                    prePopulateControls: ['label', 'number'],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Transportation Expense', smallScreenLabel: 'Transportation Expense Details', col: 9, colTablet: 3 },
                            //{ col: 2, colTablet: 2 },
                            { label: `${year.current} Amount`,  col: 2, colTablet: 2},
                            { label: 'Actions', col: 1, colTablet: 1} 
                        ],
                        footerLabels: [
                            { type: 'label', label: 'Total', col: 9, colTablet: 5 },
                            { type: 'totalMoney', name: 'armedForcesTranspoExpenseCyAmount', col: 2, colTablet: 2, year: 'current', label: '$ 0', isSubSectionTotal: true },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_AF_MOVING_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                },
            ]
        },
        {  
            title: 'Employee Business Expense',
            isVisible: true,
            sectionId: 9,
            isSubSection: true,
            isSubSectionExpanded: false,
            groups: [       
                {
                    groupId: 1,
                    fields: [
                        { label: 'Please select an occupation and enter any applicable expense(s) in the table below.', type: 'label', col: 12  },

                        { label: 'Performing artist', name: 'IsPerfArtistLabel', type: 'label', col: 9 },                        
                        { label: 'Yes/No', name: 'IsPerfArtist', type: 'select', col: 3, lookup: 'YESNO_DONTKNOW', wasCheckbox: true }, 

                        { label: 'Free-basis state or local government official', name: 'IslocalgovtOfficialLabel', type: 'label', col: 9 },                     
                        { label: 'Yes/No', name: 'IslocalgovtOfficial', type: 'select', col: 3, lookup: 'YESNO_DONTKNOW', wasCheckbox: true  },  

                        { label: 'Handicapped employee', type: 'label', name: 'IsHandicappedLabel', col: 9 },       
                        { label: 'Yes/No', type: 'select', name: 'IsHandicapped', col: 3, lookup: 'YESNO_DONTKNOW', wasCheckbox: true  },

                        { label: 'Armed forces reservist', type: 'label', name: 'IsArmedForcesLabel', col: 9 },
                        { label: 'Yes/No', type: 'select', name: 'IsArmedForces', col: 3, lookup: 'YESNO_DONTKNOW', wasCheckbox: true  },

                        { label: 'None of the above (deductions applicable in some states)', type: 'label', name: 'IsNoneOfTheAboveLabel', col: 9,}, 
                        { label: 'Yes/No', type: 'select', name: 'IsNoneOfTheAbove', col: 3, lookup: 'YESNO_DONTKNOW', isOddOne: true, wasCheckbox: true  }, 
                    ]
                },               
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 3,
                    hasSubSectionTotalDisplay: true,
                    fields: [
                        {  label: 'Description', name: 'IFDSSBEI.0', type: 'freeText', prior: true },
                        { label: ' ', name: '', type: 'label' },
                        {  label: 'Amount', name: 'C-IFDSSBEI.1', type: 'money', isTotal: true, placeholder: '0' },
                        {  type: 'lineButtons' },
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Description', smallScreenLabel: 'Non-Cash Contributions Details', col: 7, colTablet: 3, },
                            { col: 2, colTablet: 2 },
                            { label: `${year.current} Amount`, col: 2, colTablet: 2, align: 'right' },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'noPaddingLeft'},
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Employee Business Expense', icon: 'add' },
                            { type: 'label', label: 'Total', col: 9, colTablet: 5 },
                            { type: 'totalMoney', name: 'C-IFDSSBEI.1', col: 2, colTablet: 2, year: 'current', label: '$ 0', isTotal: true, isSubSectionTotal: true},
                            
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: OTHER_DEDUCTIONS_EMPLOYEE_BUSINESS_EXPENSE_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                }
            ]
        }
    ];

    return lineItemGroup;
};

export default otherDeductionsFn;