import {
    Button,
    TextIconSpacing
} from 'react-md';
import { ExpandMoreSVGIcon, ExpandLessSVGIcon } from '@react-md/material-icons';

import './dashboardGrid.css';
import './dashboardGrid.scss';

const DashboardViewButton = ({ categoryTitle, index, onViewAllClick, showViewAll }) => {
    return (
        <Button
            id="combined-button-2"
            className="viewAllCards"
            onClick={() => onViewAllClick(categoryTitle, index)}
        >
            <TextIconSpacing
                icon={showViewAll ? <ExpandMoreSVGIcon className="expandCardsButton" /> : <ExpandLessSVGIcon className="expandCardsButton" />}
                iconAfter
            >
                {!showViewAll ? 'View All' : 'View Less'}
            </TextIconSpacing>
        </Button>
    );
};

export default DashboardViewButton;