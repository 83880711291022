// TO BE DISCUSS IF THIS WILL BE PART OF FORM RENDERER LIBRARY
import { useState, useEffect } from 'react';
import { Button, FontIcon, Collapse } from 'react-md';
import { FileInput, Form } from '@react-md/form';
import Dropzone from 'react-dropzone';
import { frameworkSetter } from '../../../hooks/usePageFramework';
import UploadDrawer from '../uploadDrawer/index';
import * as STRING from '../../../constants/strings';
import { ACCEPTED_FILE_TYPES }from './../../../constants/document'
function FormUploads(props) {
    const { card, currentCardKey, REDUX, selectState, onFileUpload } = frameworkSetter.usePageFramework();
    const { isWithinForm, entityIndex, isFormDisabled } = props;
    const showBulkUpload = selectState(REDUX.SHOW_BULK_UPLOAD_CONTROL);
    const uploadList = selectState(REDUX.UPLOAD_LIST);
    const isFormLocked = selectState(REDUX.LOCK_FORMS);
    const isFormNA = selectState(REDUX.IS_CURRENT_FORM_NA);
    const title = selectState(REDUX.TOOLBAR_TITLE);
    const [sectionName, setSectionName] = useState(null);
    const [collapsed, setCollapsed] = useState(true);
    const [style, setStyle] = useState();
    const uploadTitle = card ? STRING.QUICK_UPLOAD_TITLE : STRING.DASHBOARD_FILE_UPLOADS;
    const uploadSubTitle = card ? STRING.QUICK_UPLOAD_SUBTITLE : STRING.DASHBOARD_UPLOAD_SUBTITLE;
    const activeDragStyle = {borderColor: '#76d2d3'};
    const inactiveDragStyle = {borderColor: '#979797'};

    useEffect(() => { 
        setCollapsed(true);

        if (showBulkUpload && card && card.bulkUpload && card.bulkUpload.sectionName) {
            const section = card.bulkUpload.sectionName?.toLowerCase().trim();
            setSectionName(section);
        }

        if (showBulkUpload && !card) {
            setSectionName(STRING.DASHBOARD_SECTION_NAME);
        }

        if (card?.showBulkEntityUpload) {
            const entitySection = `${entityIndex}-${card.bulkUpload.sectionName}`
            setSectionName(entitySection);
        }
           
    }, [currentCardKey, isFormLocked, isFormNA, title]);

    window.addEventListener('drop', (event) => {
        event.preventDefault();
    });

    const onUpload = (event) => onFileUpload(event, null, sectionName, uploadList, { updateState });
    const handleDrop = acceptedFiles => {
        onFileUpload(null, acceptedFiles, sectionName, uploadList, { updateState });
        setStyle(inactiveDragStyle);
    };
    const updateState = () => setCollapsed(false);

    const showBulk =  isWithinForm ? card.showBulkEntityUpload : showBulkUpload;
    const formUploadList = !showBulk || !sectionName || !uploadList || !uploadList.length ? [] : uploadList.filter(x => x.section?.toLowerCase().trim() === sectionName?.toLowerCase().trim());
    const uploadTotal = formUploadList.length;

    const drawer = !showBulk ? null : (
        <UploadDrawer sectionName={sectionName} uploadList={uploadList} onUpload={onUpload} isFormLocked={isFormDisabled}/>
    );
 
    return (
        !showBulk ? <></> : 
            <Dropzone 
                id={`dropzone-${sectionName}`}
                onDrop={handleDrop}
                onDragOver={()=> setStyle(activeDragStyle)}
                onDragEnter={()=> setStyle(activeDragStyle)}
                onDragLeave={()=> setStyle(inactiveDragStyle)}
                noClick={true}
                disabled={isFormDisabled}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ 
                        className: 'formUploadContainer', 
                        onDrop: (event) => event.preventDefault(), //to get rid on opening the dropped file
                        onDragOver: (event) => event.preventDefault(), //to get rid on opening the dropped file
                        style 
                    })}>
                        <input {...getInputProps()} />
                        <div className={isFormNA ? 'formUploadHeaderContainer notApplicableForm' : 'formUploadHeaderContainer'}>
                            <div className="sectionHeaderTitle">
                                <div className="sectionHeaderTitleContainer">
                                    <div>
                                        <p className="formTitle"> {uploadTitle}</p>
                                    </div>
                                    <div>
                                        <FontIcon className="helpIcon">help</FontIcon>
                                    </div>
                                </div>
                                <div className="formUploadSubTitle"> {uploadSubTitle} </div>
                            </div>
                            <div className="expandFormContainer">
                                <Button themeType="flat"
                                    id={`button-${sectionName}`}
                                    onClick={() => {}}
                                    disabled={isFormDisabled}>
                                    <Form>
                                        <FileInput
                                            className="entryBackButton uploadButton"
                                            style={isFormDisabled ? {cursor: 'default'} : {}}
                                            disabled={isFormDisabled}
                                            id={`bulkUpload2-${sectionName}`}
                                            onChange={onUpload} 
                                            buttonType={'text'}
                                            theme='clear'
                                            themeType='flat'
                                            icon={null}
                                            multiple={true}
                                            accept={ACCEPTED_FILE_TYPES}>
                                            Upload
                                        </FileInput>
                                    </Form>
                                </Button>
                                <Button themeType="contained" 
                                    data-testid='setCollapseBtnId'
                                    className={uploadTotal ? 'entryNextButton uploadButton' : 'inactiveButton'}
                                    disabled={!uploadTotal}
                                    onClick={uploadTotal ? () => setCollapsed(!collapsed) : () => {}}>
                                    {uploadTotal ? uploadTotal === 1 ? 'View 1 File' : `View ${uploadTotal} Files` : 'View Files'}
                                </Button>
                            </div>
                        </div>
                        <Collapse collapsed={collapsed} id={`form-upload-collapse-${sectionName}`}>
                            <div>
                                {drawer}
                            </div>
                        </Collapse>
                    </div>
                )}
            </Dropzone>
    );
}

export default FormUploads;