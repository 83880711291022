const _ = require('lodash');

const reduceOr = (prev, curr) => prev || curr;

const setCalculatedValue = (prePopAugment, locatedValues) => (key, index) => {
    const { calculateValues, totalAllRows } = prePopAugment;

    const isExistingTotalValue = !_.isNil(calculateValues[key]) && !_.isNil(locatedValues[index]) && totalAllRows;
    if (isExistingTotalValue) {
        calculateValues[key] = `${_.toSafeInteger(calculateValues[key]) + _.toSafeInteger(locatedValues[index])}`;
        return true;
    }

    const isNewValue = _.isNil(calculateValues[key]);
    if (isNewValue) {
        calculateValues[key] = locatedValues[index];
        return true;
    }

    return false;
};

const calculatePrePop = (fieldData, prePop) => {
    const _prePopCalc = _.cloneDeep(prePop);

    let hasCalculated = false;

    _prePopCalc.prePopulateAugments?.forEach((prePopAugment) => {
        const { calculateKeys, calculateFn } = prePopAugment;

        if (calculateKeys?.length && calculateFn) {
            // initialize
            if (!prePopAugment.calculateValues) prePopAugment.calculateValues = {};

            const locatedValues = calculateKeys.map(x => fieldData?.find(y => y.key === x)?.value);

            hasCalculated = calculateKeys
                ?.map(setCalculatedValue(prePopAugment, locatedValues))
                ?.reduce(reduceOr, hasCalculated);
        }
    });

    return { _prePopCalc, hasCalculated };
};

const processPrePop = (fieldData, prePop) => {
    const _prePop = _.cloneDeep(prePop);

    let hasData = false;

    _prePop.prePopulateAugments?.forEach((prePopAugment) => {
        const { calculateKeys, calculateFn } = prePopAugment;
        
        if (calculateKeys || calculateFn) return;

        const locatedFieldData = fieldData.find(x => x.key === prePopAugment.name);
        
        if (locatedFieldData) {
            const locatedList = Array.isArray(locatedFieldData) ? locatedFieldData : [locatedFieldData];
            locatedList.forEach(item => {
                if (prePopAugment.name === item.key) {              
                    prePopAugment.priorValue = item.value;
                    hasData = true;
                }
            });
        }
    });

    return { _prePop, hasData };
};

const preparePrePopulates = (group, fieldData) => {
    group.prePopulate.forEach(prePop => {
        const { _prePop, hasData } = processPrePop(fieldData, prePop);

        if (prePop.hasData && hasData) {
            if (!prePop.singleRow) {
                group.prePopulate.push(_prePop);
            } else {
                _prePop.prePopulateAugments.forEach((_prePopAugment) => {
                    if (_prePopAugment.totalAllRows) {
                        const matchingAugment = prePop.prePopulateAugments.find((prePopAugment) => prePopAugment.name === _prePopAugment.name);

                        if (matchingAugment) {
                            matchingAugment.priorValue = _.toSafeInteger(matchingAugment.priorValue) + _.toSafeInteger(_prePopAugment.priorValue);
                        }
                    }
                });
            }
        } else if (!prePop.hasData && hasData) {
            prePop.prePopulateAugments = _prePop.prePopulateAugments;
            prePop.hasData = true;
        }

        const { _prePopCalc, hasCalculated } = calculatePrePop(fieldData, prePop);

        if (hasCalculated) {
            prePop.prePopulateAugments = _prePopCalc.prePopulateAugments;
        }
    });
};

module.exports = {
    setCalculatedValue,
    calculatePrePop,
    preparePrePopulates,
}