import { useCallback, useEffect, useState } from "react";
import { BINARY, MAX_PREFETCH_LIMIT, UNARY } from "@utilities/constants/practitionerDashboard";

const useServerSideMUI = (props) => {
  const { defaultFilter, defaultSort, defaultPageSize, defaultPrefetchSize, filterDataMap, sortDataMap, operatorsMap } = props;

  const [currentFilterModel, setCurrentFilterModel] = useState({ items: defaultFilter || null });
  const [filterQueryParameters, setFilterQueryParameters] = useState(defaultFilter || null);
  const [filterMethodParameters, setFilterMethodParameters] = useState(null);

  const [currentSortModel, setCurrentSortModel] = useState([]);
  const [sortQueryParameters, setSortQueryParameters] = useState(defaultSort || null);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [externalFilterParameters1, setExternalFilterParameters1] = useState(null);
  const [externalFilterParameters2, setExternalFilterParameters2] = useState(null);
  const [pageSize, setPageSize] = useState(defaultPageSize || null);
  const [prefetchSize, setPrefetchSize] = useState(defaultPrefetchSize || null);

  const parseToFilterString = (columnToDataMap, operatorsMap) => (filterItem) => {
    const dataField = columnToDataMap[filterItem?.field];
    const operatorValue = operatorsMap[filterItem?.operator];
    const filterValue = filterItem.value;
    if (dataField && operatorValue) {
      if (operatorValue.type === BINARY && filterValue?.trim()) {

        const filterString = `${dataField} ${operatorValue.key} ${filterValue?.trim()}`;
        return filterString;
      }
      if (operatorValue.type === UNARY) {
        const filterString = `${dataField} ${operatorValue.key}`;
        return filterString;
      }

      return null;
    } else {
      return null;
    }
  };

  useEffect(() => {
    /**
     * This useEffect will re-calculate filterQueryParameters and filterMethodParameters
     * based on currentFilterModel
     */
    const filterItems = currentFilterModel?.items;
    const filterMethod = currentFilterModel?.logicOperator;

    if (!filterItems || !filterItems.length) {
      setFilterQueryParameters(defaultFilter || null);
      setFilterMethodParameters(null);
      return;
    }

    // Multi-filtering; iterate through all filterModal.items
    const filters = filterItems.map(parseToFilterString(filterDataMap, operatorsMap)).filter(x => x);
    setFilterQueryParameters(filters);
    setFilterMethodParameters(filterMethod);
  }, [currentFilterModel, defaultFilter, filterDataMap, operatorsMap]);

  useEffect(() => {
    /**
     * GROWTH: Handle parsing multi sort
     * 
     * This useEffect will re-calculate sortQueryParameters
     * based on currentSortModel
     */
    const sortItem = currentSortModel?.[0];
    if (sortItem) {
      const dataField = sortDataMap[sortItem.field];

      if (dataField) {
        setSortQueryParameters({ sort: dataField, order: sortItem.sort });
      }
    } else {
      setSortQueryParameters(defaultSort || null);
    }
  }, [currentSortModel, defaultSort, sortDataMap]);

  const handleFilterModelChange = useCallback((filterModel) => {
    setCurrentPageNumber(0);
    setCurrentFilterModel(filterModel);
  }, []);

  const handleSortModelChange = useCallback((sortModel) => {
    setCurrentSortModel(sortModel);
  }, []);

  const handlePageModelChange = setCurrentPageNumber;
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);

    const prefetchSize = (Math.floor(MAX_PREFETCH_LIMIT / pageSize) * pageSize) || MAX_PREFETCH_LIMIT;

    setPrefetchSize(prefetchSize);
  };

  const handleExternalFilterChange = (externalFilter1, externalFilter2) => {
    handleFilterModelChange({ items: defaultFilter }); // Reset filter
    setExternalFilterParameters1(externalFilter1);
    setExternalFilterParameters2(externalFilter2);
  };

  return {
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageModelChange,
    handleExternalFilterChange,
    currentPageNumber,
    filterQueryParameters,
    filterMethodParameters,
    sortQueryParameters,
    pageSize,
    prefetchSize,
    externalFilterParameters1,
    externalFilterParameters2,
    currentFilterModel,
    currentSortModel,
  };
};

export default useServerSideMUI;