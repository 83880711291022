import { Button, Dialog } from 'react-md';
import nextId from 'react-id-generator';

// custom modules
import * as IMG from '@utilities/constants/images.js';
import '@components/dialog/warningDialog/WarningDialog.css';

function WarningDialog(props){
    const { buttonElement, 
        onCancelWarning,
        onConfirmClick,
        visible,
        subtitleText, questionText, confirmText } = props;

    return(
        <>
            {buttonElement && buttonElement}
            <Dialog
                id={`warning-dialog${nextId()}`}
                role="alertdialog"
                visible={visible}
                onRequestClose={()=> {}}
                aria-labelledby="dialog-title"
                className="WarningDialogContainer"
                data-testid="warning-dialog"
            >
                <div className='WarningDialogInnerContainer' >
                    <div className='WarningSignContainer'>
                        <img className="warningDialogIcon" width="24" height="20" alt="Warning icon" src={IMG.WARNING_ICON}></img>
                        <div className="SuccessDialogTitle"> WARNING </div>
                    </div>
                    <div className="WarningDialogTextContainer">
                        <div className="WarningDialogText1"> {subtitleText ?? ''}</div>
                        <div className="WarningDialogText1"> {questionText ?? ''}</div>
                        <div className="WarningDialogText1"> {confirmText ?? ''}</div>
                    </div>
                    <div className="warningDialogButton">
                        <Button className="warningDialogCancel" data-testid="dialog-cancel" id="dialog-cancel" onClick={onCancelWarning} > CANCEL </Button>
                        <Button className="warningDialogConfirm" data-testid="dialog-confirm" id="dialog-confirm" onClick={onConfirmClick} > CONFIRM </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default WarningDialog;