// TODO: use instead of localStorage/sessionStorage directly

/**
 * Custom hook to use storage (local or session).
 *
 * @param {string} [storage='local'] - The type of storage to use ('local' for localStorage, 'session' for sessionStorage).
 * @returns {Object} - An object with methods to interact with the specified storage.
 */
export const useStorage = (storage = 'local') => {
  const _storage = storage === 'session' ? sessionStorage : localStorage;

  const get = (key) => {
    try {
      const value = JSON.parse(_storage.getItem(key));

      if (!isNaN(parseFloat(value))) {
        return parseFloat(value);
      }

      if (value === 'true' || value === 'false') {
        return (/true/i).test(value);
      }

      return value;
    } catch (e) {
      console.error('Error parse JSON:', e);
      return null
    }
  };

  const has = (key) => {
    return _storage.getItem(key) !== null;
  }

  const set = (key, value) => {
    try {
      _storage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error('Error stringify JSON:', e);
    }
  };

  const remove = (key) => {
    _storage.removeItem(key);
  };

  return {
    get,
    set,
    has,
    remove
  }
}