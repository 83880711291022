import { taxReturnStatuses } from "./taxReturn";

export const BINARY = 'binary';
export const UNARY = 'unary';
// Constant query states below are used for the PractitionerDashboard component
export const MAX_PREFETCH_LIMIT = 100; // This is the max of records returned by the API
export const DEFAULT_PAGE_LIMIT = 25;
export const DEFAULT_FILTER_QUERY = [];
export const DEFAULT_SORT_QUERY = { sort: 'client.name', order: 'asc' };

export const SORT_TO_DATA_MAP = {
  name: 'client.name',
  cslClientNumber: 'client.csl',
  signer: 'signer.displayName',
  inCharge: 'inCharge.displayName',
  exchangeStatus: 'customStatus',
  lastActivity: 'customDate',
  lock: 'locked',
  taxReturnStatus: 'taxReturnStatus',
};

export const FILTER_TO_DATA_MAP = {
  name: 'client.name',
  csl: 'client.csl',
  clientNumber: 'client.number',
  signer: 'signer.displayName',
  inCharge: 'inCharge.displayName',
  exchangeStatus: 'customStatus',
  taxReturnStatus: 'taxReturnStatus',
};

export const OPERATORS_MAP = {
  contains: { key: 'co', type: BINARY },
  equals: { key: 'eq', type: BINARY },
  startsWith: { key: 'sw', type: BINARY },
  endsWith: { key: 'ew', type: BINARY },
  isEmpty: { key: 'ie', type: UNARY },
  isNotEmpty: { key: 'ine', type: UNARY },
};

export const OPERATORS_LIST = Object.keys(OPERATORS_MAP);