import { GridCell } from 'react-md';
import DashboardCard from '@components/dashboard/dashboardCard';
import useAdaptive from "@utilities/hooks/useAdaptive";

function DashboardGridCell(props) {
    const { card, index  } = props;
    const { isLaptop, isMobile, isTablet } = useAdaptive();

    const defaultCellCard = (
        <GridCell key={`category-card-${index}`} className="cardGridCell" colSpan={isMobile ? 12 : isTablet ? 4 : isLaptop ? 3 : 2 }>
            <DashboardCard card={card} />
        </GridCell>
    );

    return defaultCellCard;

}

export default DashboardGridCell;