import './noRenderer.css';

const NoRenderer = () => {
    return (
        <div
            className='fileViewerNotAvail file-preview-not-available'
        >
            No preview available
        </div>
    );
};

export default NoRenderer;