// common modules


// custom modules
import '@components/dialog/customDialog/customDialog.css';
import * as IMG from '@utilities/constants/images.js';


function ErrorDialog(dispatch, ACTION, title, message) {
    dispatch(ACTION.setCustomDialogTitle(
        <div className='exportErrorContainer'>
        <img className="warningDialogIcon"
          width="24"
          height="20"
          alt="Warning icon"
          src={IMG.WARNING_ICON}
        >
        </img>
        <span className="errorHeaderText">{title}</span>
      </div>
    ));			
    dispatch(ACTION.setCustomDialogMsg(
        <>
        <div className='exportErrorMsg'>{message}</div>
      </>
    ));
    dispatch(ACTION.setShowCustomDialog(true));
}
export default ErrorDialog;