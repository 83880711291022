import { Button, DialogContent } from 'react-md';
import { Grid, GridCell } from '@react-md/utils';

import './mobileLimitedExperience.scss';
import * as IMG from '@utilities/constants/images';

function MobileLimitedExperience(props) {
  const { onCloseModal } = props;

  const styles = {
    backgroundImage: `linear-gradient(#2E334E, #2E334E), url(${IMG.CLA_WELCOME_BG})`,
    backgroundSize: 'cover',
    backgroundRepeat: "no-repeat",
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundBlendMode: 'color',
  };

  return (
    <>
      <DialogContent className={'limitedExpContainer'} style={styles}>
        <Grid>
          <GridCell colSpan={12} ><img src={IMG.CLA_LOGO_MOBILE} alt="CLA logo" className={"limitedExpClaLogo"} /></GridCell>
          <GridCell colSpan={12}>
            <div className="limitedExpText">
              1040 Mobile offers a limited experience. For full functionality, please visit our site on a desktop or laptop device.
            </div>
          </GridCell>
          <Button
            data-testid="exploreMobileButtonId"
            themeType="outline"
            className="exploreButton"
            onClick={() => onCloseModal()}
          >
            Explore
          </Button>
        </Grid>
      </DialogContent>
    </>
  );
}

export default MobileLimitedExperience;