import { isFieldRequired } from './isRequired';
import * as STRING from '../../../../constants/strings';

export function getHintText(state, valued, field) {
    // const requiredField = isFieldRequired(field);
    return state.error ? state.errorMessage : ' ';
    // leave the code for condition check for future implementation
    // valued && (field.inputState === 4 || field.inputState === 5)?
    // STRING.CLA_ENTERED : 
    // valued && field.inputState === 1 ?
    //     STRING.PRIOR_YEAR : 
    //     !requiredField ? STRING.OPTIONAL : ' ';
}