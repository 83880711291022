import _ from 'lodash';
import { preparePrePopulates } from '@utilities/populatePriorData/preparePrePopulates';
import populateFields from '@utilities/populatePriorData/populateFields';
import populateLineItem from '@utilities/populatePriorData/populateLineItem';
import recalculateTotalFooter from '@utilities/populatePriorData/recalculateTotalFooter';
import calculateTableGroup from '@utilities/populatePriorData/populateCalculatedTable';
import * as INPUT_STATE from '@utilities/constants/inputState';
import { getPopulateOptions } from './populateOptions';

const triggerBuilder = (sections, triggeredEvent) => (trigger, event) => {
    if (triggeredEvent) {
        triggeredEvent(trigger, event, { sections: sections, setFormSections: () => { }, inputState: INPUT_STATE.PRIOR_INPUT });
    }
};

const populatePriorDataModule = (priorYearData, triggeredEvent) => {
	const populateWithPriorData = (sections, lineItemData, sectionName, sectionArea, formContext) => {
		const fieldTrigger = triggerBuilder(sectionArea || sections, triggeredEvent);

		const lineItemTotals = [];
		const dataList = [];

		if (typeof (lineItemData) === 'object' && lineItemData.identifiers && Array.isArray(lineItemData.identifiers)) {
			const identifiers = Array.isArray(lineItemData.identifiers) ? lineItemData.identifiers : [lineItemData.identifiers];

			identifiers.forEach((identifier) => {
				const items = Array.isArray(identifier) ? identifier : [identifier];
				items.forEach(item => dataList.push(item));
			});
		} else {
			dataList.push(lineItemData);
		}

		sections.filter(x => x.groups).forEach((section) => {
			const calculatedGroup = section.groups.filter(x => x.axcessGroup);
			const regularGroup = section.groups.filter(x => !x.axcessGroup);

			calculatedGroup.forEach(group => calculateTableGroup(group, sectionName, lineItemData, dataList, lineItemTotals, priorYearData, buildFromSheetSection));

			dataList.forEach((fieldData) => {
				regularGroup.forEach((group) => {
					const { fromSections } = group;
					if (fromSections && !fromSections.includes(sectionName)) return;

					if (group.lineItems) {
						/**
						 * If fromSections is being defined on the group and the group is set to prePopulate, the section(s) it is prePopulating from must be included
						 *  in the fromSections property or it will be skipped. 
						 */
						if (group.prePopulate && !group.isNew) {
							preparePrePopulates(group, fieldData);
						}

						const populateResults = populateLineItem(fieldData, fieldTrigger)(group, lineItemTotals);
						const { hasData, newColumns } = populateResults;

						if (hasData) { // {totals: 0, totalName: ''}
							if (lineItemTotals.length) {
								recalculateTotalFooter(lineItemTotals, group);
							}
							group.lineItems.push(newColumns);
						}
					} else if (group.fields) {
						group.fields.forEach(populateFields(fieldData, fieldTrigger, formContext));
					}
				});
			});
		});
	};

	// sectionArea is used for sub-form and entity building
	const buildFromSheetSection = (workSheetSection, focusedSection, sectionArea, formContext) => {
		const sectionToPopulate = focusedSection || [];

		workSheetSection?.forEach((dataSheet) => {
			if (!dataSheet) return;
			const { name, fields, lineItems } = dataSheet;
			const populateOptions = getPopulateOptions(name);
			const { groupField, flattenData } = populateOptions;

			if (lineItems) {
				const priorLineItems = _.cloneDeep(lineItems);

				// PrePopulated values can come from fields or line items (grids) in AxcessTax
				if (fields && _.isArray(fields)) {
					const priorFields = _.cloneDeep(fields);

					const spreadField = (fieldsArray) => {
						return fieldsArray.map(x => [x]);
					};

					const groupedField = (fieldsArray) => {
						return [fieldsArray];
					};

					const includeFieldsFn = groupField ? groupedField : spreadField;
					priorLineItems.push(...includeFieldsFn(priorFields));
				}

				if (flattenData) {
					const pyItemsWithIndexes = priorLineItems.map((x, i) => x.map(y => Object.assign({}, y, { index: i })));
					populateWithPriorData(sectionToPopulate, pyItemsWithIndexes.flat(), name, sectionArea, formContext);
				} else {
					priorLineItems.forEach((row) => {
						if (row && row.length) {
							populateWithPriorData(sectionToPopulate, row, name, sectionArea, formContext);
						}
					});
				}
			} else if (fields) {
				populateWithPriorData(sectionToPopulate, fields, name, sectionArea, formContext);
			}
		});
	};

	return {
		buildFromSheetSection
	};
};

export default populatePriorDataModule;