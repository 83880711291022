import { Select, FormMessage, Tooltipped, HoverModeProvider, Button, TextIconSpacing, FontIcon } from 'react-md';
import { BELOW_CENTER_ANCHOR } from '@react-md/utils';
import { useEffect, useState, useRef } from 'react';
import { LOOKUPS } from './../../../constants/lookupInfo';
import { getHintText } from '../fields/utils/getHintText';
import { isFieldRequired } from '../fields/utils/isRequired';
import { isFieldDisabled } from '../fields/utils/isDisabled';
import { useField } from '../fields/useField';
import { adjustLabels } from '../fields/utils/adjustLabels';
import { renderLabel } from '../fields/utils/isRequired';
import { isError } from '../fields/utils/isError';
// import { sectionFieldLogic } from '../triggers';

function DropDown(props) {
    const { field, fns, group, index, isFormLocked, section, parentGroup, parentIndex, grandParentGroup
        // enableDropdownSelection = false
    } = props;
    // const options = LOOKUPS[field.lookup] || LOOKUPS.YESNO;
    const [options, setOptions] = useState(() => {
        const initialState = field?.fieldOptions ? field?.fieldOptions : LOOKUPS[field.lookup] || LOOKUPS.YESNO;
        return initialState;
    });

    const requiredField = isFieldRequired(field);
    const { state, updateBlurState, handleBlur } = useField(props);
    const [labelClassName, setLabelClassName] = useState('');
    const ref = useRef(null);

    useEffect(() => {
        adjustLabels(ref, section, setLabelClassName, 'select');
    }, []);

    useEffect(() => {
        if (section.entitiesList) {
            const updatedList = options.filter((option) => !section.entitiesList.find(entity => entity.name === option.value && entity.name !== state.value));
            setOptions(updatedList);
        }
    }, [section.entitiesList]);
    // const [isClickedOnAddDropdown, setDropdown] = useState(!enableDropdownSelection);

    const onMouseLeave = (event) => {

        // if(document.getElementById(`select-${field.name}-${index}-listbox`) === null){
        onChange(document.getElementById(`select-${field.name}-${index}-value`).value);
        // }
    }

    const onChange = (event) => {
        const triggeredSections = section.isDenseRow ? null : {};
        if (field.triggered && !parentGroup?.isSummaryRow) {
            if (typeof (field.triggered) === 'string') {
                fns.triggered(field.triggered, event, triggeredSections);
            } else {
                field.triggered(event);
            }
        }

        let fieldPreviousValue = field.default
        const hasValueChanged = fieldPreviousValue !== event;

        if ((event === 'Select' || event === ' ') && requiredField) {
            updateBlurState(event, true, 'Required', hasValueChanged);
        } else if (fieldPreviousValue !== event) {
            updateBlurState(event, false, '', hasValueChanged);
            if (field.isSharedEntityValue) fns.showSharedEntityDialog();
        } else {
            updateBlurState(event, false, '', hasValueChanged);
        }

        if (field.logicFunction && !parentGroup?.isSummaryRow) {
            field.logicFunction.forEach((logic) => {
                logic.isDifferentGroup ? fns.triggered(logic.trigger, field.default, triggeredSections) :
                    fns.sectionFieldLogic(logic?.trigger, { group, field, index, logic, parentGroup, parentIndex, section, fns });
            });
        }

        //for syncing summary view changes to individual view 
        if (parentGroup?.isSummaryRow && grandParentGroup) {
            grandParentGroup.entities[index]?.sections
                ?.find(entitySection => entitySection.title === section.title)
                ?.groups.forEach((entityGroup) => {
                    entityGroup.fields.forEach((entityField) => {
                        if (entityField.name === field.name) {
                            entityField.default = field.default;
                            if (entityField.logicFunction) {
                                entityField.logicFunction.forEach((logic) => {
                                    logic.isDifferentGroup ? fns.triggered(logic.trigger, entityField.default, grandParentGroup.entities[index]?.sections) :
                                        fns.sectionFieldLogic(logic?.trigger, { group, field: entityField, index, logic, parentGroup: grandParentGroup, parentIndex: index, section, fns });
                                });
                            }
                            if (entityField.triggered) {
                                fns.triggered(entityField.triggered, entityField.default, grandParentGroup.entities[index]?.sections);
                            }
                        }
                    })
                });
        }

        fns.saveForm(field);
    };

    // This fixes the bug where the options does not show
    // on first click when navigating from an input field
    // const onMouseEnter = () => fns.saveForm(field);  <-- this doesn't look right (added by Liang on 8/11/2021)

    // bug-7174 related 
    // This causes the re render and causes flicker behaviour (About your year -> Foreign matters section)
    // Seems like updateState is blocking dropdown options if clicking from another field.
    // const onMouseEnter = () => {
    //     (section.title === 'Tell Us' ||  section.title === 'Foreign Matters') ? null : fns.saveForm(field);
    // };

    const valued = state.value && state.value.length > 0 ? true : false;

    const hintText = getHintText(state, valued, field, requiredField);

    // this seems not needed anymore as handleblur from useField works now
    // const selectBlurHandler = () => {
    //     if (requiredField && (state.value === ' ' || state.value === 'Select')) {
    //         updateBlurState(state.value, true, 'Required');
    //         setTimeout(() => {
    //             if (field.default === ' '){
    //                 fns.saveForm(field);
    //             }
    //         }, 3000);
    //     } 
    //     else if (field.logicFunction) {
    //         field.logicFunction.forEach((logic) => {
    //             logic.isDifferentGroup && fns.triggered(logic.trigger, field.default);
    //         });
    //     } else return;
    // };

    const getValueName = () => {
        const lookup = field.lookup ? LOOKUPS[field.lookup] : LOOKUPS.YESNO;
        const lookupValue = lookup.find((option) => option.value === state.value);
        return lookupValue && lookupValue['name'];
    };

    const priorYearChanged = field.priorYearValue !== state.value;

    return (
        <div key={`cell-${field.name}`} style={{ height: '50px' }}>
            {/* {
                !isClickedOnAddDropdown && (
                    <Button
                        className='addEntityIcon'
                        buttonType='icon'
                        onClick={() => addEntity()}
                        disabled={isFormLocked}
                    >
                        <TextIconSpacing className='addNewText' icon={<FontIcon>add</FontIcon>}> ADD NEW </TextIconSpacing>
                    </Button>
                )
            }
            { isClickedOnAddDropdown && (
                <> */}
            <HoverModeProvider>
                <Tooltipped id={`hover-${field.name}-${index}`} tooltip={getValueName()} position='above' key={`hover-${field.name}-${index}`} className='pracDashOnHover'>
                    <div className={labelClassName}>
                        {isError(field)}
                        <Select
                            ref={ref}
                            className={field?.priorYearValue && priorYearChanged ? 'cla-prior-year-data-edited' : !field?.priorYearValue && state?.value?.trim() !== '' ? 'cla-prior-year-data-edited' : null}
                            displayLabelClassName={!priorYearChanged ? 'cla-prior-year-data' : null}
                            key={`select-${field.name}-${index}`}
                            name={field.name}
                            id={`select-${field.name}-${index}`}
                            tabIndex={0}
                            options={options}
                            value={state?.value}
                            label={renderLabel(field)}
                            labelKey={'name'}
                            valueKey={'value'}
                            anchor={BELOW_CENTER_ANCHOR} //still not working
                            trigger={field.trigger || null}
                            onChange={(event) => onChange(event)}
                            onBlur={(event) => onMouseLeave(event)}
                            // onMouseLeave={(event)=> onMouseLeave(event)}
                            // onClick={(event)=> onMouseLeave(event)}
                            // onBlur={(event)=> onMouseLeave(event)}
                            // onMouseEnter={onMouseEnter}
                            listboxWidth={'min'}
                            disabled={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section)}
                            error={state.error}
                            data-testid='drop-down-field'
                        />
                    </div>
                </Tooltipped>
            </HoverModeProvider>
            <FormMessage
                id={`${field.id}-error-tooltip`}
                error={state.error}
                messageStyle={{ whiteSpace: 'nowrap' }}
                data-testid='error-message'
            >
                {hintText}
            </FormMessage >
            {/* </> )}  */}
        </div>
    );
}


export default DropDown;
