import * as LOOKUPS from '@utilities/constants/lookupInfo';

const getDropDownValue = (field) => {
    const lookup = field.lookup ? LOOKUPS[field.lookup] : LOOKUPS.YESNO;
    const lookupValue = lookup.find((option) => option.value === field.default);
    return lookupValue && lookupValue['name'];
};

export {
    getDropDownValue,
};