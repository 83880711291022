import React, { useState, useEffect } from 'react';
import { Grid, GridCell, TextIconSpacing, FontIcon, Button, Divider } from 'react-md';
import _ from 'lodash';
// import { frameworkSetter } from '../../../hooks/usePageFramework';
// import moneyFormatter, { parseMoneyToNumber, noDollarFormatter } from '../../../utils/moneyFormatter';
import { buildField, buildTextArea, buildLabel, buildSelect, buildCheckbox, buildRadio, buildViewButton, buildMultiSelectDropdown } from '../buildControls';
// import LineItemButtons from '../lineItemButtons/index';
// import LineItemSection from '../lineItemSection/index';
// import LineItemDrawer from '../lineItemDrawer/index';
// import DenseRowBtn from '../lineItemDetail/denseRowBtn';
// import { triggeredEvent, sectionFieldLogic } from '../triggers/index';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import prePopulateLineItems from '../../../utils/populatePriorData/prePopulateLineItems';
import ReactPaginate from 'react-paginate';
// import LineItemFooter from '../lineItemFooter/index';
// import LineItemHeader from '../lineItemHeader/index';
// import TableManagerTabs from '../tableManagerTabs/index';

function GroupItemDetail(props) {
    const { group,
        colspan,
        screenSize,
        fns,
        isFormLocked,
        section,
        sectionIndex,
        groupIndex,
        sections,
        entityIndex,
        showSharedEntityDialog,
        parentGroup,
        parentIndex,
        grandParentGroup,
        allSections
    } = props;
    // const { selectState, REDUX, INPUTSTATE } = frameworkSetter.usePageFramework();
    // const uploadList = selectState(REDUX.UPLOAD_LIST);
    const [lineItemCtrls, setLineItemCtrls] = useState([]);
    const [paint, setPaint] = useState(false);
    const { width } = useWindowDimensions();
    const [currentPage, setCurrentPage] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentPageData, setCurrentPageData] = useState([]);
    // const [footerBtns, setFooterBtns] = useState([]);
    // const [footerLabels, setFooterLabels] = useState(false);
    const isLineDisabled = isFormLocked;
    const summaryGroups = section.groups.find(group => group.groupType === 'groupItem');
    const filteredGroups = section.hasOrgs ? group.groupItems
        .map(group => group?.filter(item => item.isVisible))
        .flat()
        .filter(item => item) : group.groupItems.filter(item => item);

    const itemsPerPage = 4;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentPageData(filteredGroups?.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(filteredGroups?.length / itemsPerPage));
        if (!currentPageData.length && pageCount >= 1) {
            setCurrentPage(pageCount - 1);
            //need new offset to set the new current page data properly
            const newOffset = (currentPage - 1) * 4;
            setItemOffset(newOffset);
            setCurrentPageData(filteredGroups?.slice(newOffset, endOffset));
        }
    }, [itemOffset, group, pageCount, currentPage]);

    const handlePageClick = ({ selected: selectedPage }) => {
        const newOffset = selectedPage * itemsPerPage % filteredGroups?.length;
        setCurrentPage(selectedPage);
        setItemOffset(newOffset);
    }

    //dense rows related
    // const [currentEntity, setCurrentEntity] = useState(-1);
    // const [currentH2, setCurrentH2] = useState('Summary');
    // const [isAddingNewEntity, setIsAddingNewEntity] = useState(false);
    // const hasDenseRow = section.hasDenseRow;
    // const isDenseRow = section.isDenseRow;
    // const formHeaderContainer = section.title === 'Occupant Details' ? 'denseRowformHeaderContainer' : 
    //     isDenseRow ?  'formHeaderContainer denseRowHeaderMargin' : 'formHeaderContainer';

    useEffect(() => {
        createGroupItemCtrl(group, colspan);
    }, [group, paint, width, currentPageData]);

    const createGroupItemCtrl = (group, colspan) => {
        const { groupItems } = group;

        if (group.prePopulate && group.prePopulate.length) {
            prePopulateLineItems(group);
            group.prePopulate = [];
        }

        let lineItemCount = 0;
        const items = currentPageData.map((groupItem, lineIndex) => {

            if (!groupItem) return null;

            const isLineItemVisible = typeof (groupItem.isVisible) === 'undefined' ? true : groupItem.isVisible;
            if (!isLineItemVisible) return (null);

            // let fieldCount = -1;
            let fieldsGroups = [];

            if (Array.isArray(groupItem)) {
                fieldsGroups = groupItem.map((fieldsGroup) => {
                    const isGrpVisible = typeof (fieldsGroup.isVisible) === 'undefined' ? true : fieldsGroup.isVisible;

                    if (!isGrpVisible) return (null);

                    const fields = fieldsGroup.fields.map((field, index) => {
                        const isVisible = typeof (field.isVisible) === 'undefined' ? true : field.isVisible;

                        if (!isVisible && !field.hasColSpace) return ('');

                        if (field.uniqueName) fns.setUniqueEntityFieldValue({ field: field });

                        if (!field.isPriorTriggered && field?.priorYearValue && field.logicFunction && !parentGroup?.isSummaryRow) {
                            field.logicFunction.forEach((logic) => {
                                const fieldValue = field.default ? field.default : field.priorYearValue;
                                logic.isDifferentGroup ? fns.triggered(logic.trigger, fieldValue, null) :
                                    fns.sectionFieldLogic(logic?.trigger, { group, field, index: lineIndex, logic, parentGroup, parentIndex })
                            });
                            field.isPriorTriggered = true;
                            if (field.requiredCardsToSet) {
                                setCardsRequired(field.requiredCardsToSet, field.isSetRequiredCards);
                            }
                        }

                        const col = field.col;

                        let ctrl = buildField;

                        switch (field.type) {
                            case 'select':
                                ctrl = buildSelect;
                                break;
                            case 'multiSelectDropdown':
                                ctrl = buildMultiSelectDropdown;
                                break;
                            case 'checkbox':
                                ctrl = buildCheckbox;
                                break;
                            case 'checkboxLabel':
                                ctrl = buildCheckbox;
                                break;
                            case 'radio':
                                ctrl = buildRadio;
                                break;
                            case 'label':
                                ctrl = buildLabel;
                                break;
                            case 'textarea':
                                ctrl = buildTextArea;
                                break;
                            case 'viewButton':
                                ctrl = buildViewButton;
                                break;
                            default:
                                ctrl = buildField;
                                break;
                        }

                        return (
                            <React.Fragment key={`group-item-cell-wrapper-key-${index}`}>
                                <GridCell
                                    colSpan={col}
                                    className={'lineRowField'}
                                >
                                    {ctrl(field, col, fieldsGroup.parentEntityIndex, fieldsGroup, isLineDisabled, { saveForm: fns.saveForm, triggered: fns.triggered, screenSize, showSharedEntityDialog, sectionFieldLogic: fns.sectionFieldLogic }, section, parentGroup, parentIndex, grandParentGroup, allSections)}
                                </GridCell>
                            </React.Fragment>
                        );
                    });

                    return (<React.Fragment key={`form-row-${section.sectionId}-${fieldsGroup.groupId}`}>
                        {
                            fieldsGroup.title &&
                            <GridCell colSpan={12}>
                                <div
                                    className='sectionHeaderContainer'
                                >
                                    <div className='sectionHeaderTitle'>
                                        <div className="sectionHeaderTitleContainer">
                                            <div>
                                                <p className='denseRowFormTitle'>
                                                    {
                                                        fieldsGroup.individualName ?
                                                            <>
                                                                {`${fieldsGroup.individualName} - `}
                                                                <span className='namedOrgTitle'>{fieldsGroup.title}</span>
                                                            </> : fieldsGroup.title
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </GridCell>
                        }
                        {fields}
                        {fieldsGroup.hasDivider && <GridCell colSpan={12}><Divider /></GridCell>}
                    </React.Fragment>);
                });
            } else { // This block operates on Compensation, Benefits, and Fiscal Year Details
                // TODO: fix data syncing when pagination is available
                if (!groupItem.hasDivider) groupItem.hasDivider = true; // set divider for the unrelated org
                const fields = groupItem.isVisible && groupItem.fields.map((field, index) => {
                    const isVisible = typeof (field.isVisible) === 'undefined' ? true : field.isVisible;

                    if (!isVisible) return ('');

                    if (field.uniqueName) fns.setUniqueEntityFieldValue({ field: field });

                    if (!field.isPriorTriggered && field?.priorYearValue && field.logicFunction && !parentGroup?.isSummaryRow) {
                        field.logicFunction.forEach((logic) => {
                            const fieldValue = field.default ? field.default : field.priorYearValue;
                            logic.isDifferentGroup ? fns.triggered(logic.trigger, fieldValue, null) :
                                fns.sectionFieldLogic(logic?.trigger, { group, field, index: lineIndex, logic, parentGroup, parentIndex })
                        });
                        field.isPriorTriggered = true;
                        if (field.requiredCardsToSet) {
                            setCardsRequired(field.requiredCardsToSet, field.isSetRequiredCards);
                        }
                    }

                    const col = field.col;

                    let ctrl = buildField;

                    switch (field.type) {
                        case 'select':
                            ctrl = buildSelect;
                            break;
                        case 'multiSelectDropdown':
                            ctrl = buildMultiSelectDropdown;
                            break;
                        case 'checkbox':
                            ctrl = buildCheckbox;
                            break;
                        case 'checkboxLabel':
                            ctrl = buildCheckbox;
                            break;
                        case 'radio':
                            ctrl = buildRadio;
                            break;
                        case 'label':
                            ctrl = buildLabel;
                            break;
                        case 'textarea':
                            ctrl = buildTextArea;
                            break;
                        case 'viewButton':
                            ctrl = buildViewButton;
                            break;
                        default:
                            ctrl = buildField;
                            break;
                    }

                    return (
                        <React.Fragment key={`group-item-cell-wrapper-key-${index}`}>
                            <GridCell
                                colSpan={col}
                                className={'lineRowField'}
                            >
                                {ctrl(field, col, groupItem.parentEntityIndex, groupItem, isLineDisabled, { saveForm: fns.saveForm, triggered: fns.triggered, screenSize, showSharedEntityDialog, sectionFieldLogic: fns.sectionFieldLogic }, section, parentGroup, parentIndex, grandParentGroup, allSections)}
                            </GridCell>
                        </React.Fragment>
                    );
                });

                fieldsGroups = (<React.Fragment key={`form-row-${section.sectionId}`}>
                    {
                        groupItem.title &&
                        <GridCell colSpan={12}>
                            <div
                                className='sectionHeaderContainer'
                            >
                                <div className='sectionHeaderTitle'>
                                    <div className="sectionHeaderTitleContainer">
                                        <div>
                                            <p className='denseRowFormTitle'>
                                                {
                                                    groupItem.individualName ?
                                                        <>
                                                            {`${groupItem.individualName} - `}
                                                            <span className='namedOrgTitle'>{groupItem.title}</span>
                                                        </> : groupItem.title
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </GridCell>
                    }
                    {fields}
                    {groupItem.hasDivider && <GridCell colSpan={12}><Divider /></GridCell>}
                </React.Fragment>);
            }

            lineItemCount++;

            return (
                <React.Fragment key={`line-item-row-key-${group.groupId}-${lineIndex}`} >
                    <Grid
                        className='lineItemRow formLineRowContainer'
                        style={group.isLastItem ? { marginBottom: '22px' } : null}
                        key={`line-item-row-key-${group.groupId}-${lineIndex}`}
                        id={`line-item-row-id-${group.groupId}-${lineIndex}`}
                    >
                        {fieldsGroups}
                        {
                            group.hasDivider || !section.hasOrgs && groupItems.length > 1 ?
                                <GridCell colSpan={12}>
                                    <Divider className={group.groupType === 'lineSection' ? null : 'lineItemRowDivider'} />
                                </GridCell> : null
                        }

                    </Grid>
                </React.Fragment>
            );
        });

        setLineItemCtrls(items);
    };

    return (
        <>
            <Grid
                className={"fieldsContainer"}
            >
                {lineItemCtrls}
            </Grid>
            {summaryGroups?.groupItemsLength > itemsPerPage ?
                <Grid>
                    <GridCell colSpan={12} className='summaryGroupsPagination'>
                        <ReactPaginate
                            previousLabel='<'
                            nextLabel='>'
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName='pagination'
                            activeClassName='paginationActivePage'
                            breakLabel='...'
                            breakClassName='page-item'
                            breakLinkClassName='page-link'
                            renderOnZeroPageCount={null}
                            forcePage={currentPage}
                        />
                    </GridCell>
                </Grid>
                : <></>}
        </>
    );
}

export default GroupItemDetail;