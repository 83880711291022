// import { useState } from 'react';
import { Button, GridCell, Checkbox } from 'react-md';
import * as IMG from '../../../constants/images';

function ViewButton(props) { 
    const { group, index, field, fns, buttonType, viewSectionId, isFormLocked } = props;
    const defaultChecked = field && field.default ? 'defaultChecked' : null;
    const checked = field?.buttonType === 'checkboxAndDetails' ? field.default : true;
    const isLineDisabled = field?.viewSectionId === 100 ? false : group.lineItems && group.lineItems[index][0].notApplicable;
    
    const onClickedCheckBox = (event) => {
        field.default = event.target.checked;
        group.lineItems[index][0].drawerVisible = false;
        
        // Hide lineItemSection 
        if (!field.default) {
            group.currentEntity = -1;
            group.currentViewField = -1;
        }

        fns.saveForm(field);
    };

    const onClickedButton = () => {
        group.lineItems[index][0].drawerVisible = false;
        group.currentEntity = group.currentEntity == index && group.currentViewField == field.viewSectionId ? -1 : index;
        group.currentViewField = field.viewSectionId;
        fns.saveForm(field);
    };

    const onUploadClicked = () => {
        if (group.lineItems) {
            group.currentEntity = -1;
            group.lineItems[index][0].drawerVisible = !group.lineItems[index][0].drawerVisible;
        }
        else { group.fields[0].drawerVisible = !group.fields[0].drawerVisible; }
        fns.saveForm();
    };

    const viewCloseButton = () => {
        return (
            <Button 
                data-testid='view-close-button'
                style={field?.styles} className={isLineDisabled ? 'viewButtonDisabled' : checked ? 'viewButtonActive' : 'viewButtonInActive'} disabled={ isLineDisabled || !checked}
                onClick={onClickedButton}>{checked && group.currentEntity == index && group.currentViewField == viewSectionId ? 'Close Details' : 
                    checked && group.currentEntity == index && group.currentViewField == -1 ? 'Close Details' : 'View Details'} 
            </Button>
        );
    };

    const buildCheckboxAndDetails = () => {
        return (
            <GridCell colSpan={2} key={`cell-${field.name}${viewSectionId}`}>
                <Checkbox
                    disabled={isFormLocked || isLineDisabled}
                    className='lineItemGroupCheckbox'
                    id={`chk-${field.name}${index}`}
                    key={`chk-${field.name}${index}`}
                    name={field.name}
                    label={viewCloseButton()}
                    onClick={(event) => onClickedCheckBox(event)}
                    defaultChecked={defaultChecked}
                />
            </GridCell>
        );
    };

    const buildUploadListButton = () => {
        return (
            <Button className='lineUploadButton2' onClick={onUploadClicked} ><img alt={group.lineItems[index][0].drawerVisible ? 'Hide uploaded files button' : 'View uploaded files button'} 
                src={group.lineItems[index][0].drawerVisible ? IMG.hideUploadsIcon : IMG.viewUploadsIcon}/>
            </Button>
        );
    };

    let buildViewButton = viewCloseButton();

    switch(buttonType) {
    case 'checkboxAndDetails':
        buildViewButton = buildCheckboxAndDetails();
        break;
    case 'uploadList':
        buildViewButton = buildUploadListButton();
        break;
    default:
        buildViewButton = viewCloseButton();
        break;
    }

    return (buildViewButton);
}

export default ViewButton;