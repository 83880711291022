const clearCache = async () => {
  const cacheNames = await caches.keys();
  await Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)));
};

const removeServiceWorkers = async () => {
  if (navigator?.serviceWorker) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    await Promise.all(registrations.map((registration) => registration.unregister()));
  }
};

const cleanState = async () => {
  try {
    await Promise.all([clearCache(), removeServiceWorkers()])
  } catch (err) {
    console.error('An error has occurred while cleaning cache');
    console.error(err);
  }
  localStorage.setItem('removedServiceWorkers', process.env.REACT_APP_VERSION);
};

export {
  cleanState,
};