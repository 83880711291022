import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import wages2021 from './2021';
import wages2022 from './2022';
import wages2023 from './2023';
import wages2024 from './2024';
import * as NAV from '@utilities/constants/navigation';

function Wages(props) {
    const { isExportForm } = props
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.WAGES);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: wages2021(year),
            2022: wages2022(year),
            2023: wages2023(year),
            2024: wages2024(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);
        setSectionState(formSections);
        // eslint-disable-next-line    
    }, []);

    const axcessIdentifiers = [
        { section: 'Income', id: 'Wages, Salaries and Tips (W-2)' }
    ];

    return (
        <FormRendererHelper
            sections={sectionState}
            key="wagesForm"
            formName={REDUX.WAGES}
            identifiers={axcessIdentifiers}
            isExportForm={isExportForm}
        />
    );
}

export default Wages;
