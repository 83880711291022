import { STATE_2020_TAX_PAYMENTS_UPLOAD } from '@utilities/constants/strings';

const stateTaxFn = (year) => (formState) => {
    const sections = [
        {
            title: '2021 State Tax Payments Made',
            collapseClass: 'collapsibleForm',
            isVisible: true,
            sectionId: 1,
            entitiesList: formState,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        {
                            label: `Your state estimates below only reflect vouchers delivered with your final tax return. Please list any additional amounts
                            paid. If estimates were paid to other taxing authorities, please provide details.`,
                            type: 'label',
                            isMarkup: true
                        },
                    ]
                },
                {
                    groupId: 2,
                    fields: [
                        { label: 'State', name: 'selecedStateLabel', type: 'label', col: 1, class: 'labelState' },
                        {
                            label: 'State', name: 'IFDSSEST.27', type: 'select', col: 2, lookup: 'STATES_TAX_PAYMENT_CODES', triggered: 'setHasSelectedStateTaxPayment', isEntityName: true, prior: true, priorCompareOptions: { compareAllLookup: true },
                            axcess: {
                                pull: {
                                    fields: ['G20632.14'],
                                    fn: 'anyValidPull'
                                }
                            },
                        }
                    ],
                },
                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 3,
                    isVisible: false,
                    fields: [
                        { label: 'Quarter', name: 'stateTaxesQuarter', type: 'label' },
                        { label: '$ 0', wrapLabel: 'Calculated Amount', name: 'stateTaxesCalculatedAmount', type: 'label', isTotal: true },
                        { label: 'Due Date', name: 'stateTaxesDueDate', type: 'date', axcessTranslate: 'date' },
                        { label: 'Amount', wrapLabel: '2020 Amount Paid', name: 'stateTaxesCyAmountPaid', type: 'money', isTotal: true },
                        { label: 'Date Paid', name: 'stateTaxesDatePaid', type: 'date', axcessTranslate: 'date' },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    prePopulate: [
                        { label: 'Carryover from Prior Year', prePopulateAugments: [{ name: 'G20632.66', sameValue: true }, { name: 'G20632.66', sameValue: true }, { name: 'stateCarryOverDate' }], lineOptions: { staticLine: true } },
                        {
                            label: '1st Quarter', prePopulateAugments: [
                                { name: 'G20632.49', priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSSEST.3', 'C-IFDSSEST.8'] },] } },
                                { name: 'IFDSSEST.3' },
                                { name: 'IFDSSEST.8' }
                            ]
                        },
                        {
                            label: '2nd Quarter', prePopulateAugments: [
                                { name: 'G20632.50', priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSSEST.4', 'C-IFDSSEST.9'] },] } },
                                { name: 'IFDSSEST.4' },
                                { name: 'IFDSSEST.9' }
                            ]
                        },
                        {
                            label: '3rd Quarter', prePopulateAugments: [
                                { name: 'G20632.51', priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSSEST.5', 'C-IFDSSEST.10'] },] } },
                                { name: 'IFDSSEST.5' },
                                { name: 'IFDSSEST.10' }
                            ]
                        },
                        {
                            label: '4th Quarter', prePopulateAugments: [
                                { name: 'G20632.52', priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSSEST.6', 'C-IFDSSEST.11'] },] } },
                                { name: 'IFDSSEST.6' },
                                { name: 'IFDSSEST.11' }
                            ]
                        },
                    ],
                    prePopulateIndexes: [{ priorIndex: 1 }, { currentIndex: 3 }, { currentIndex: 4 }],
                    prePopulateControls: ['label', 'label', 'label'],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Quarter', smallScreenLabel: 'Payments Details', col: 3, colTablet: 1, },
                            { label: 'Calculated Amount', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Due Date', col: 2, colTablet: 2, },
                            { label: '2021 Amount Paid', col: 2, colTablet: 1, align: 'right' },
                            { label: 'Date Paid', col: 2, colTablet: 2, },
                            { label: 'Payment Confirmation', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'label', label: 'Total', col: 3, colTablet: 1, },
                            { type: 'totalMoney', name: 'stateTaxesCalculatedAmount', col: 2, colTablet: 1, label: '$ 0' },
                            { type: 'totalMoney', name: 'stateTaxesCyAmountPaid', col: 4, colTablet: 3, year: 'current', label: '$ 0' },
    
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: STATE_2020_TAX_PAYMENTS_UPLOAD, viewSectionId: 0 }
                        ]
                    }
                }
            ]
        }
    ];
    
    return sections;
};

export default stateTaxFn;