import { Validators } from 'cla-formrenderer';

import * as EVENT from '@utilities/constants/triggerKeys';
import * as LOOKUPS from '@utilities/constants/lookupInfo';
import moneyFormatter, { noDollarFormatter } from '@utilities/moneyFormatter';
import { getDropDownValue } from '@utilities/helpers/getDropdownValue';
import { amountCurrencyFormatter } from '@utilities/helpers/formatter';
import { validateArrivalAndDepartureDate } from './helpers/foreignIncomeValidators';
import { currentYear } from '@utilities/hooks/usePageFramework';

const setForeignIncomeCurrencyRequired = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    if (event?.trim() !== '') {
        name[2].groups[2].fields[1].validations = { ...Validators.required() };
    }

    if (event?.trim() === '') {
        name[2].groups[2].fields[1].validations = null;
        name[2].groups[2].fields[1].error = false;
        name[2].groups[2].fields[1].errorMessage = null;
    }
    setFormSections(name);
};

const syncVisaType = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    if (event.length > 20) {
        event = `${event.substring(0, 17)}...`;
    }
    name[1].groups[4].visaType = event;

    name.forEach((lineSection) => {
        lineSection.groups.forEach((lineSectionGroup) => {
            if (lineSectionGroup.groupType === 'lineSection') {
                lineSectionGroup.lineItems?.forEach((lineItem) => {
                    lineItem.forEach((lineSectionGroupField) => {
                        if (lineSectionGroupField.name === 'visaType') {
                            lineSectionGroupField.default = event;
                            lineSectionGroup.visaType = event;
                        }
                    })
                })
            }
        })
    });

    setFormSections(name);
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'setForeignIncomeCurrencyRequired':
            setForeignIncomeCurrencyRequired(event, options);
            break;
        case 'syncVisaType':
            syncVisaType(event, options);
            break;
        default:
            break;
    }
};


const updateArrivalDateAndDepartureDate = (options) =>{
    const { field, group, logic } = options;

    const otherField = group.fields.find((groupField) => {
        return (groupField.name === logic.otherFieldName) 
    })

     validateArrivalAndDepartureDate({
        [field.name]:field, [otherField.name]:otherField
    })
}

const calculateFullDaysInCountry = (options) => {
    const { group, parentGroup, parentIndex } = options;
    const arrival = group.fields[0].default || '';
    const departure = group.fields[1].default || '';
    const fullDays = new Date(departure) - new Date(arrival);

    if (fullDays > 0) {
        group.fields[3].default = Math.round(fullDays / (1000 * 60 * 60 * 24)).toString();
    } else {
        group.fields[3].default = '';
    }

    setWorkDaySummaryLabel({ group, parentGroup, parentIndex });
    validateWorkDays({ group });
};

const setWorkDaySummaryLabel = (options) => {
    const { group, parentGroup, parentIndex } = options;
    let fullDays = 'Total Days: 0';
    let workDays = 'Total Workdays: 0';

    group.fields.forEach((groupField) => {
        if (groupField.name === 'fullDays') {
            fullDays = groupField.default ? `Total Days: ${groupField.default}` : 'Total Days: 0';
        }
        if (groupField.name === 'workDays') {
            workDays = groupField.default ? `Total Workdays: ${groupField.default}` : 'Total Workdays: 0';
        }
    });

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === 'foreignIncomeSummary') {
            lineItemField.default = `${fullDays}\n${workDays}`;
        }
    });
};

const validateWorkDays = (options) => {
    const { group } = options;
    const arrivalDate = group.fields[0].default;
    const departureDate = group.fields[1].default;
    const workDaysField = group.fields[2];
    const workDays = workDaysField.default || 0;
    const fullDays = group.fields[3].default || 0;

    if ((arrivalDate && departureDate) && (Number(workDays) > Number(fullDays))) {
        workDaysField.error = true;
        workDaysField.errorMessage = 'Number of workdays cannot exceed total time spent in that country';
    } else {
        workDaysField.validations = null;
        workDaysField.error = false;
        workDaysField.errorMessage = '';
    }
};

const setLineItemMinEndDate = (options) => {
    const { field, group, index, logic } = options;
    group.lineItems[index].forEach((lineField) => {
        if (lineField.name === logic.endDateName) {
            lineField.minDate = field.default;
        }
    });
};

const setForeignIncomeCurrencySymbol = (options) => {
    const { field, parentGroup, parentIndex, section } = options;
    if (field.lookup === 'FOREIGN_CURRENCIES') {

        if (field.default !== ' ') {
            section.groups[3].isVisible = true;
            section.groups[4].isVisible = true;
        }

        if (field.default === ' ') {
            section.groups[3].isVisible = false;
            section.groups[4].isVisible = false;
        }
        const groupCurrency = getGroupCurrency(field);

        //for lineitem fields
        section.groups[4].currencySymbol = groupCurrency;

        //for lineitem total label
        section.groups[4].lineItems.forEach((lineItem) => {
            lineItem[3].default = `${lineItem[3].amount ? lineItem[3].amount : '0'}`;
        });
        //for footer toals
        const total1 = section.groups[4].lineItemDetails.footerLabels[2];
        const total2 = section.groups[4].lineItemDetails.footerLabels[3];
        const total3 = section.groups[4].lineItemDetails.footerLabels[4];

        total1.label = `${total1.amount ? moneyFormatter(Number(total1.amount), groupCurrency) : '0'}`;
        total2.label = `${total2.amount ? moneyFormatter(Number(total2.amount), groupCurrency) : '0'}`;
        total3.label = `${total3.amount ? moneyFormatter(Number(total3.amount), groupCurrency) : '0'}`;

        //for parent line item current expense 
        parentGroup.lineItems[parentIndex][8].default = total3.label;
    }
};

const getGroupCurrency = (field) => {
    const lookup = LOOKUPS[field.lookup];
    const lookupValue = lookup.find((option) => option.value === field.default);
    return lookupValue ? lookupValue['symbol'] : '$';
};

const setAmountReimbursedAndPaidTotal = (options) => {
    const { group, index, section } = options;

    const field1 = group.lineItems[index][1].default ? group.lineItems[index][1].default.replace(/[^0-9]/g, '') : 0.0;
    const field2 = group.lineItems[index][2].default ? group.lineItems[index][2].default.replace(/[^0-9]/g, '') : 0.0;
    const total = Number(field1) + Number(field2);
    const currencySymbolCode = section?.groups[2]?.fields[1]?.default || "USD";

    group.lineItems[index][3].default = amountCurrencyFormatter(total, currencySymbolCode);
    group.lineItems[index][3].amount = noDollarFormatter(Number(total));
};

const setCurrentHousingExpense = (options) => {
    const { field, parentGroup, parentIndex } = options;
    parentGroup.lineItems[parentIndex][8].default = field.label;
};

const setAddressSummaryLabel = (options) => {
    const { group, parentGroup, parentIndex } = options;
    let address = [];
    group.fields.forEach((groupField) => {
        if (groupField.default && groupField.default !== ' ') address.push(groupField.default)
    })

    const join = address.length > 0 ? address.join(', ') : '';
    address = join;

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === 'addressSummary') {
            lineItemField.default = address;
        }
    });
};

const setOccupantSummaryLabel = (options) => {
    const { group, parentGroup, parentIndex } = options;
    const SEPERATE_HOME_RELATIONSHIP = 'IFDSREST.31';
    let name = [];

    if (group.lineItems.length === 1) {
        name = group.lineItems[0]
            .filter((item) => item.name !== SEPERATE_HOME_RELATIONSHIP && item.type !== 'lineButtons')
            .map((item) => item.default)
            .filter(item => item).join(' ');
    } else {
        group.lineItems.forEach((lineItem) => {
            const filteredNames = lineItem
                .filter((item) => item.name !== SEPERATE_HOME_RELATIONSHIP && item.type !== 'lineButtons')
                .map((item) => item.default && item.default !== ' ' && item.default)
                .filter(item => item)

            if (filteredNames.length > 0) name.push(filteredNames.join(' '))
        });
    }

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === 'occupantSummary') {
            lineItemField.default = name;
        }
    });
};

const syncDownForeignIncomeOwner = (options) => {
    const { group, field, index } = options;

    group.entities[index].sections[1].groups[4].foreignIncomeOwner = getDropDownValue(field);

    group.entities[index].sections.forEach((lineSection) => {
        lineSection.groups.forEach((lineSectionGroup) => {
            if (lineSectionGroup.groupType === 'lineSection') {
                lineSectionGroup.lineItems?.forEach((lineItem) => {
                    lineItem.forEach((lineSectionGroupField) => {
                        if (lineSectionGroupField.name === field.name) {
                            lineSectionGroupField.default = getDropDownValue(field);
                            lineSectionGroup.foreignIncomeOwner = getDropDownValue(field);
                        }
                    })
                })
            }
        })
    });
};

const setTravelStateProvince = (options) => {
    const { group, field, index } = options;

    group.lineItems[index][3].isVisible = field.default === EVENT.KEY_UNITED_STATES;
    group.lineItems[index][4].isVisible = field.default === EVENT.KEY_CANADA;
    group.lineItems[index][2].isVisible = field.default !== EVENT.KEY_CANADA && field.default !== EVENT.KEY_UNITED_STATES;

};

const setStartDateValidation = (options) => {
	const { field } = options;
    if (field.default && field.minDate && field.maxDate) {
        var dateStr = field.default.split('/');
        var maxDateStr = field.maxDate.split('/');
        if (parseInt(dateStr[2]) < 1950 || parseInt(dateStr[2]) > parseInt(maxDateStr[2])) {
            field.error = true;
            field.errorMessage = `Year must be between 01/01/1950 and 12/31/${maxDateStr[2]}`;
        }
        else {
            field.validations = null;
            field.error = false;
            field.errorMessage = null;
        }
    }
};

const setEndDateValidation = (options) => {
	const { field } = options;
    if (field.default && currentYear) {
        var dateStr = field.default.split('/');
        if (parseInt(dateStr[2]) < parseInt(currentYear)) {
            field.error = true;
            field.errorMessage = `Year must be between 12/31/${currentYear} and after.`;
        } 
        else if (parseInt(dateStr[2]) > parseInt(currentYear + 50)) {
            field.error = true;
            field.errorMessage = `Year must be between 12/31/${currentYear} and 12/31/${currentYear + 50}`;
        }
        else {
            field.validations = null;
            field.error = false;
            field.errorMessage = null;
        }
    }
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'updateArrivalDateAndDepartureDate':
            updateArrivalDateAndDepartureDate(options);
            break;
        case 'calculateFullDaysInCountry':
            calculateFullDaysInCountry(options);
            break;
        case 'validateWorkDays':
            validateWorkDays(options);
            break;
        case 'setWorkDaySummaryLabel':
            setWorkDaySummaryLabel(options);
            break;
        case 'setLineItemMinEndDate':
            setLineItemMinEndDate(options);
            break;
        case 'setForeignIncomeCurrencySymbol':
            setForeignIncomeCurrencySymbol(options);
            break;
        case 'setAmountReimbursedAndPaidTotal':
            setAmountReimbursedAndPaidTotal(options);
            break;
        case 'setCurrentHousingExpense':
            setCurrentHousingExpense(options);
            break;
        case 'setAddressSummaryLabel':
            setAddressSummaryLabel(options);
            break;
        case 'setOccupantSummaryLabel':
            setOccupantSummaryLabel(options);
            break;
        case 'syncDownForeignIncomeOwner':
            syncDownForeignIncomeOwner(options);
            break;
        case 'setTravelStateProvince':
            setTravelStateProvince(options);
            break;
        case 'setStartDateValidation':
            setStartDateValidation(options);
            break;
        case 'setEndDateValidation':
            setEndDateValidation(options);
            break;
        default:
            break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};