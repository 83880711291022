import OrganizerViewUploadDialog from '@components/OrganizerViewUploadDialog';
import usePageFramework from '@utilities/hooks/usePageFramework';
import moment from 'moment';
import nextId from 'react-id-generator';
import { Divider, DropdownMenu, Grid, GridCell, LinearProgress, MenuItem, MoreVertSVGIcon } from 'react-md';

const DocumentListItem = ({ file, idx, array, useType, getScreenCol }) => {
	const { selectState, REDUX, onFileRemoval } = usePageFramework();
	const isProgressVisible = selectState(REDUX.UPLOAD_PROGRESS_VISIBLE);
	const dashKey = 'dashboard';
	const isLocked = selectState(REDUX.LOCK_FORMS) || false;

    return (
        <div className="uploadItem">
            <Grid key={`organizer-upload-row-${file.id}`} id={`${dashKey}-${nextId()}`} className='uploadDrawerRow'>
                <GridCell className='fileName' colSpan={getScreenCol(6)}>
                    <OrganizerViewUploadDialog
                        key={`organizer-view-upload-dialog-${file.id}`}
                        id={nextId()}
                        className="fileViewDetails"
                        blobFile={file}
                        fileName={file.name}
                    />
                </GridCell>
                <GridCell className='fileName' colSpan={getScreenCol(3)}>
                    {file.uploaded_by || ''}
                </GridCell>
                <GridCell className='fileName' colSpan={getScreenCol(2)}>
                    {moment(new Date(file.createdOn)).format('MMM DD, YYYY') || 'None'}
                </GridCell>
                {useType && useType === 'readOnly' ? <></> :<GridCell colSpan={1} className="fileList">
                    <DropdownMenu
                        data-testid={'upload-drawer-menu'}
                        disabled={isLocked}
                        key={`organizer-upload-dpmenu-${file.id}`}
                        id={`organizer-upload-dpmenu-${file.id}`}
                        buttonType="icon"
                        buttonChildren={<MoreVertSVGIcon />}
                    >
                        <MenuItem
                            key={`upload-remove-item-${file.upload_id}`}
                            onClick={() => onFileRemoval(null, file)}>
                            {'Remove File'}
                        </MenuItem >
                    </DropdownMenu>
                </GridCell>}
            </Grid>
            <Grid>
                <GridCell colSpan={getScreenCol(12)}>
                    {isProgressVisible && idx === array.length - 1 ?
                        <LinearProgress
                            id={`upload-linear-progress-${file.id}`}
                            barStyle={{ backgroundColor: '#76d2d3' }}
                            className='uploadLinearProgress'
                            min={0}
                            max={100}
                            value={null}
                        /> : <Divider />}
                </GridCell>
            </Grid>
        </div>
    )
};

export default DocumentListItem;