import { Validators } from 'cla-formrenderer';

import * as EVENT from '@utilities/constants/triggerKeys';
import { setConditionalFieldValidation, setEntityNotApplicable } from '@components/formRenderer/triggers';
import { RENTAL_INCOME_RENTAL_DISABLED_TEXT, RENTAL_INCOME_ROYALTY_DISABLED_TEXT } from '@utilities/constants/strings';

const setCountrySchedE = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name[0].groups[3].fields[5].isVisible = event === EVENT.KEY_UNITED_STATES;
    name[0].groups[3].fields[6].isVisible = event === EVENT.KEY_CANADA;
    name[0].groups[3].fields[7].isVisible = event !== EVENT.KEY_CANADA && event !== EVENT.KEY_UNITED_STATES ;

    if (event?.trim() === '') {
        name[0].groups[3].fields[7].validations = null;
        name[0].groups[3].fields[7].error = false;
        name[0].groups[3].fields[7].errorMessage = null;
    } else if (event?.trim() === EVENT.KEY_UNITED_STATES) {
        name[0].groups[3].fields[5].validations = { ...Validators.required() };
    } else if (event?.trim() === EVENT.KEY_CANADA) {
        name[0].groups[3].fields[6].validations = { ...Validators.required() };
    } else {
        name[0].groups[3].fields[7].validations = { ...Validators.required() };
    }

    setFormSections(name);
};

const setFileAllFormsSchedE = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name[0].groups[6].isVisible = event === EVENT.KEY_YES;
   
    setFormSections(name);
};

const showGovernmentGrantsDetailsSchedE = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    name[0].groups[8].isVisible = event === EVENT.KEY_YES;
    setFormSections(name);
};

const setIFDSEGEN1Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[3].fields[0].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[3].fields[0].validations = null;
        name[0].groups[3].fields[0].error = false;
        name[0].groups[3].fields[0].errorMessage = null;
    } 

    setFormSections(name);
};

const setIFDSEGEN15Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[3].fields[2].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[3].fields[2].validations = null;
        name[0].groups[3].fields[2].error = false;
        name[0].groups[3].fields[2].errorMessage = null;
    } 

    setFormSections(name);
};

const setIFDSEGEN17Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[3].fields[3].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[3].fields[3].validations = null;
        name[0].groups[3].fields[3].error = false;
        name[0].groups[3].fields[3].errorMessage = null;
    } 

    setFormSections(name);
};

const setIFDSEGEN21Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[5].fields[1].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[5].fields[1].validations = null;
        name[0].groups[5].fields[1].error = false;
        name[0].groups[5].fields[1].errorMessage = null;
    } 

    setFormSections(name);
};


const setIFDSEGEN19Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[3].fields[4].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[3].fields[4].validations = null;
        name[0].groups[3].fields[4].error = false;
        name[0].groups[3].fields[4].errorMessage = null;
    } 

    setFormSections(name);
};

const setIFDSEHDR0Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() !== '') {
        name[0].groups[0].fields[1].validations = { ...Validators.required() };
    } 
    if (event?.trim() === '') {
        name[0].groups[0].fields[1].validations = null;
        name[0].groups[0].fields[1].error = false;
        name[0].groups[0].fields[1].errorMessage = null;
    } 

    setFormSections(name);
};

const setIFDSEGEN22Required = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event?.trim() === 'Yes') {
        name[0].groups[6].fields[1].validations = { ...Validators.required() };
    } 
    if (event?.trim() !== 'Yes') {
        name[0].groups[6].fields[1].validations = null;
        name[0].groups[6].fields[1].error = false;
        name[0].groups[6].fields[1].errorMessage = null;
    } 

    setFormSections(name);
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'setCountrySchedE':
            setCountrySchedE(event, options);
            break;
        case 'setFileAllFormsSchedE':
            setFileAllFormsSchedE(event, options);
            break;
        case 'setIFDSEGEN1Required':
            setIFDSEGEN1Required(event, options);
            break;
        case 'setIFDSEGEN15Required':
            setIFDSEGEN15Required(event, options);
            break;
        case 'setIFDSEGEN17Required':
            setIFDSEGEN17Required(event, options);
            break;
        case 'setIFDSEGEN21Required':
            setIFDSEGEN21Required(event, options);
            break;
        case 'setIFDSEGEN19Required':
            setIFDSEGEN19Required(event, options);
            break;
        case 'setIFDSEHDR0Required':
            setIFDSEHDR0Required(event, options);
            break;
        case 'setIFDSEGEN22Required':
            setIFDSEGEN22Required(event, options);
            break;
        case 'setEntityNotApplicable':
            setEntityNotApplicable(event, options)
            break;
        case 'showGovernmentGrantsDetailsSchedE': 
            showGovernmentGrantsDetailsSchedE(event, options);
            break;
    default:
        break;
    }
};

const setAvailRentDateValidation = (options) => {
	const { field } = options;
    if (field.default && field.minDate && field.maxDate) {
        var dateStr = field.default.split('/');
        var maxDateStr = field.maxDate.split('/');
        if (parseInt(dateStr[2]) < parseInt(maxDateStr[2] - 1) || parseInt(dateStr[2]) > parseInt(maxDateStr[2])) {
            field.error = true;
            field.errorMessage = `Year can only be ${maxDateStr[2] - 1} or ${maxDateStr[2]}`;
        }
        else {
            field.validations = null;
            field.error = false;
            field.errorMessage = null;
        }
    }
};

const setRentalIncomeType = (options) => {
    const { group, field } = options;

    if (field?.default > 0) {
        group.lineItems[1][0].notApplicable = true;
        group.lineItems[1][0].subText = RENTAL_INCOME_ROYALTY_DISABLED_TEXT;
    } 
    if (field?.default <= 0) {
        group.lineItems[1][0].notApplicable = false;
    } 
};

const setRoyaltyIncomeType = (options) => {
    const { group, field } = options;
    
    if (field?.default > 0) {
        group.lineItems[0][0].notApplicable = true;
        group.lineItems[0][0].subText = RENTAL_INCOME_RENTAL_DISABLED_TEXT;
    } 
    if (field?.default <= 0) {
        group.lineItems[0][0].notApplicable = false;
    } 
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) { 
        case 'setConditionalFieldValidation':
            setConditionalFieldValidation(options);
            break;
        case 'setRentalIncomeType':
            setRentalIncomeType(options);
            break;
        case 'setRoyaltyIncomeType':
            setRoyaltyIncomeType(options);
            break;
        case 'setAvailRentDateValidation':
            setAvailRentDateValidation(options);
            break;
    default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};