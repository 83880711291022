import { TextField, Tooltipped, HoverModeProvider, } from 'react-md';
import { isFieldDisabled } from '../utils/isDisabled';
import moneyFormatter from '@utilities/moneyFormatter';
import { adjustLabels } from '../utils/adjustLabels';
import { useEffect, useState, useRef } from 'react';
import { renderLabel } from '../utils/isRequired';
import { isError } from '../utils/isError';

function ReadOnlyField (props) {
    // eslint-disable-next-line no-unused-vars
    const { field, index, group, isFormLocked, section, parentGroup , parentIndex, grandParentGroup} = props;
    const inputStyle = field.isReadOnlyAmount ? {textAlign: 'right'} : {};
    const value = field.isReadOnlyAmount ? moneyFormatter(Number(field.default.slice(1))) : 
        field.default ? field.default : ' ';
    const [labelClassName, setLabelClassName] = useState('');
    const ref = useRef(null);
    
    useEffect(() => {
        adjustLabels(ref, section, setLabelClassName);
        //eslint-disable-next-line
    }, []);

    return (
        <div key={`cell-${field.name}`}>
            <HoverModeProvider>
                <Tooltipped id={`hover-${section.title}-${field.name}-${index}`} tooltip={value} position='above' key={`hover-${field.name}-${index}`} className='pracDashOnHover'>
                    <div className={labelClassName}>
                        {isError(field)}
                        <TextField 
                            ref={ref}
                            className="readOnlyField"
                            id={`readOnly-${section.title}-${field.name}-${index}`}
                            name={field.name}
                            key={`readOnly-${field.name}-${index}`}
                            type="text" 
                            label={renderLabel(field)}
                            value={value}
                            readOnly={true}
                            isLeftAddon={false}
                            disabled={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section)}
                            inputStyle={inputStyle}
                        />
                    </div>
                </Tooltipped>
            </HoverModeProvider>
        </div>
    );
}

export default ReadOnlyField;