
const validateDate = (date)=>{
    const datePaid = new Date(date?.default);
    const maxDate = new Date(`12/31/${date.year + 1}`);
    const minDate = new Date(`1/1/${date.year}`);
    // validate Arrival Date
    if(date?.default){
        if(datePaid < minDate || (datePaid > maxDate)){
            date.error=true;
            date.errorMessage = `Year can only be current year ${date.year} or future year ${date.year + 1}`
        }
        else{
            date.error=false;
            date.errorMessage = ""
        }
    }   
}

export {
    validateDate
}