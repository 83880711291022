import { FormMessage, TextField, Tooltipped, HoverModeProvider, } from 'react-md';
import NumberFormat from 'react-number-format';
import { useField } from '../useField';
import { getHintText } from '../utils/getHintText';
import { isFieldDisabled } from '../utils/isDisabled';
import { validateFieldValue, validateTooltipValue } from '../utils/validateValue';
import { adjustLabels } from '../utils/adjustLabels';
import { useEffect, useState, useRef } from 'react';
import { renderLabel } from '../utils/isRequired';
import { isError } from '../utils/isError';

function SsnField(props) {
    // eslint-disable-next-line no-unused-vars
    const { field, index, group, isFormLocked, section, parentGroup , parentIndex, grandParentGroup} = props;
    const { state, handleBlur, handleEmptyValueChange, handleFocus } = useField(props);
    const valued = validateFieldValue(state);
    const tooltipValue = validateTooltipValue(state);
    const hintText = getHintText(state, valued, field);
    const priorYearChanged = field.priorYearValue !== state.value;
    const [labelClassName, setLabelClassName] = useState('');
    const ref = useRef(null);
    
    useEffect(() => {
        adjustLabels(ref, section, setLabelClassName);
        //eslint-disable-next-line
    }, []);
    
    return (
        <div>
            <HoverModeProvider>
                <Tooltipped id={`hover-${section.title}-${field.name}-${index}`} tooltip={tooltipValue} position='above' key={`hover-${field.name}-${index}`} className='pracDashOnHover'>
                    <div className={labelClassName}>
                        {isError(field)}
                        <NumberFormat
                            customInput={TextField}
                            //react-md props
                            getInputRef={ref}
                            className={field.priorYearValue && priorYearChanged ? 'cla-prior-year-data-edited' : !field.priorYearValue && state.value?.trim() !== '' ? 'cla-prior-year-data-edited' : null}
                            inputClassName={!priorYearChanged ? 'cla-prior-year-data' : null}
                            id={`ssn-${section.title}-${field.name}-${index}`}
                            name={field.name}
                            key={`ssn-${field.name}-${index}`} 
                            type="text" 
                            label={renderLabel(field)}
                            error={state.error}
                            value={state.value}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            disabled={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section)}
                            required={field.required ? true : false}
                            //react-number-format props:
                            // if state.value !== ' ' dont show the format
                            format={field.default !== field.overRideText && state.value !== ' ' && '###-##-####'}
                            mask={'#'}
                            isNumericString={true}
                            onValueChange={(values) => {
                                const { value } = values;
                                if (state.value.includes('X')) {
                                    handleEmptyValueChange(state.value);
                                } else {
                                    handleEmptyValueChange(value);
                                }
                            }}
                            data-testid='ssn-field'
                        />
                    </div>
                </Tooltipped>
            </HoverModeProvider>
            <FormMessage 
                id={`${field.id}-error-tooltip`}
                error={state.error}
                messageStyle={{whiteSpace: 'nowrap'}}
                data-testid='error-message'
            >
                {hintText}
            </FormMessage>
        </div>

    );
}

export default SsnField;