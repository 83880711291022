import { useState, useEffect } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';

import { triggeredEvent, sectionFieldLogic } from './foreignIncomeLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import * as NAV from '@utilities/constants/navigation';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import foreignIncome2021 from './2021';
import foreignIncome2022 from './2022';
import foreignIncome2023 from './2023';
import foreignIncome2024 from './2024';

function ForeignIncome(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.FOREIGN_INCOME);
    const [lineItemGroupState, setLineItemGroupState] = useState('');
    const year = getYear(selectState);
    
    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: foreignIncome2021(year),
            2022: foreignIncome2022(year),
            2023: foreignIncome2023(year),
            2024: foreignIncome2024(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setLineItemGroupState(formSections);
        // eslint-disable-next-line    
    }, []);

    const axcessIdentifiers = [
        { section: 'Foreign', id: '2555 - Foreign Earned Income Exclusion' },
    ];

    return (
        <FormRendererHelper
            sections={lineItemGroupState}
            key="foreignIncomeForm"
            formName={REDUX.FOREIGN_INCOME}
            identifiers={axcessIdentifiers}
            triggeredEvent={triggeredEvent}
            sectionFieldLogic={sectionFieldLogic}
            isExportForm={isExportForm}
        />
    );

}
export default ForeignIncome;