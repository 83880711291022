import { useState, useEffect } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent, sectionFieldLogic } from './otherDeductionsLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import * as NAV from '@utilities/constants/navigation';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import otherDeductions2021 from './2021';
import otherDeductions2022 from './2022';
import otherDeductions2023 from './2023';
import otherDeductions2024 from './2024';

function OtherDeductions(props) {
    const { isExportForm } = props
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.OTHERDEDUCTIONS);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: otherDeductions2021(year),
            2022: otherDeductions2022(year),
            2023: otherDeductions2023(year),
            2024: otherDeductions2024(year),
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setSectionState(formSections);
        // eslint-disable-next-line
    }, []);

    const axcessIdentifiers = [
        { section: 'Deductions', id: 'Other Adjustments' },
        { section: 'Deductions', id: '8889' },
        { section: 'Deductions', id: 'Distributions' },
        { section: 'Deductions', id: '2106' },
        { section: 'Deductions', id: 'Student Loan Interest Statement' },
    ];

    return (
        <FormRendererHelper
            sections={sectionState}
            key="otherDeductionsForm"
            formName={REDUX.OTHER_DEDUCTIONS}
            identifiers={axcessIdentifiers}
            triggeredEvent={triggeredEvent}
            sectionFieldLogic={sectionFieldLogic}
            isExportForm={isExportForm}
        />
    );
}
export default OtherDeductions;