// TO BE DISCUSS IF THIS WILL BE PART OF FORM RENDERER LIBRARY
import usePageFramework from '@utilities/hooks/usePageFramework';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import moneyFormatter from '@utilities/moneyFormatter';
import DetailsLabel from '../DetailLabel/index';

function MedicalDetailsLabel(props) {
    const { item, index } = props;

    // TODO: Dynamic year value loading from Redux and DB
    // const { selectState, REDUX, getData } = usePageFramework();
    const { getData, SERVER } = usePageFramework();

    const [ percentage, setPercentage ] = useState(0);
    const [ hasLoaded, setHasLoaded ] = useState(false);

    // TODO: get priorYear/year being calculated to
    // const year = selectState(REDUX.YEAR);
    const year = 2021;

    useEffect(() => {
        getData(`medicalExpensePercent/${year}`).then((results) => {
            const { percent, status } = results;

            if (status === SERVER.STATUS_SUCCESS && _.isFinite(percent)) {
                setPercentage(percent);
                setHasLoaded(true);
            }
        });
    //eslint-disable-next-line
    }, []);

    const hasPriorValue = !_.isNil(item.default) ? true : false;
    let medicalLabel = '';

    if (hasPriorValue) {
        const medicalAmount = hasPriorValue ? _.toInteger(item.default) : 0;
        const grossIncomeAmount = moneyFormatter(medicalAmount);
        const totalLabel = `Last year your adjusted gross income was ${grossIncomeAmount}`;
        let limitLabel = '';

        if (hasLoaded) {
            const deductibleAmount = medicalAmount > 0 ? moneyFormatter(medicalAmount * percentage / 100) : moneyFormatter(0);
            limitLabel = ` and the ${year} limit was ${percentage}%. You would have needed ${deductibleAmount} of medical expenses to be deductible`;
        }

        medicalLabel = `${totalLabel}${limitLabel}.`;
    }

    const itemCopy = _.cloneDeep(item);
    itemCopy.label = medicalLabel;

    return (
        <DetailsLabel key={`medical-lbl-${index}`} item={itemCopy} index={index}/>
    );
}

export default MedicalDetailsLabel;