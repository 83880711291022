import _ from 'lodash';
import moneyFormatter, { noDollarFormatter } from '../moneyFormatter';
import recalculateTotalFooter from './recalculateTotalFooter';
import { findMergeData, getPullFieldValues, getPYCalculationFunction } from '@utilities/axcessTaxPull';
import { splitGroupLines } from '@utilities/populatePriorData/axcessGroupSplitter';
import { filterGroupLines, matchesCondition } from '@utilities/populatePriorData/axcessGroupFilter';
import { setFieldValue } from '@utilities/populatePriorData/populateFields';
import { preparePrePopulates } from '@utilities/populatePriorData/preparePrePopulates';
import * as AXCESS_KEYS from '@utilities/constants/axcessKeys';
import { ObjectId } from 'bson';

const includesFieldIdentifier = (lineItemKeys) => (fieldIdentifier) => lineItemKeys?.includes(fieldIdentifier) || false;
const includesfieldPrefix = (lineItemKeys) => (fieldPrefix) => lineItemKeys?.some(key => key?.startsWith(fieldPrefix)) || false;
const getPYOrigin = (values, PYList) => values.map((value, index) => value ? PYList[index] : null);

const calculateTableGroup = (group, sectionName, lineItemData, dataList, lineItemTotals, priorYearData, buildFromSheetSectionFn) => {
	const { axcessGroup, fields, lineItems, subWorkSheets, lineSections, entities, entityIdentifiers } = group;
	const hasSubWorkSheetEntity = subWorkSheets && lineSections && entities;
	const dataListClone = _.cloneDeep(dataList);

	dataListClone.forEach((fieldData) => {
		if (group.prePopulate && !group.isNew) {
			preparePrePopulates(group, fieldData);
		}
	});

	if (!axcessGroup?.pull) return;

	const {
		fromSections, fieldIdentifiers, fieldPrefixes,
		mergeWithOtherData, mergeWithContextData,
		lineSplitterType, lineSplitters,
		lineFilterType, lineFilters
	} = axcessGroup.pull;

	const lineItemKeys = lineItemData.map(x => x.key)

	const matchToFieldIdentifiers = fieldIdentifiers?.some(includesFieldIdentifier(lineItemKeys));
	const matchToFieldPrefixes = fieldPrefixes?.some(includesfieldPrefix(lineItemKeys));

	if (!fromSections?.includes(sectionName)) return;
	if (!matchToFieldIdentifiers && !matchToFieldPrefixes) return;

	const pushToAll = (keyValues) => {
		if (!_.isArray(keyValues)) return;

		if (_.isArray(dataListClone) && dataListClone.every(x => _.isArray(x))) {
			dataListClone.forEach(x => x.push(...keyValues));
		} else if (_.isArray(dataListClone) && dataListClone.every(x => !_.isArray(x))) {
			dataListClone.push(...keyValues);
		};
	};

	// Exceptions to find and throw extra data into the line/row
	if (_.isArray(mergeWithOtherData) && mergeWithOtherData.length) {
		const foundMergingData = findMergeData(priorYearData, mergeWithOtherData);
		pushToAll(foundMergingData);
	}

	if (_.isArray(mergeWithContextData) && mergeWithContextData.length) {
		pushToAll(mergeWithContextData);
	}
	// Apply splitters
	const splitteredList =
		lineSplitters && _.isArray(lineSplitters)
			? splitGroupLines(dataListClone, lineSplitterType, lineSplitters, priorYearData)
			: dataListClone;

	// Apply filters
	const filteredList =
		lineFilterType && _.isArray(lineFilters)
			? filterGroupLines(splitteredList, lineFilterType, lineFilters)
			: splitteredList

	filteredList.forEach((dataRow, rowIndex) => {
		const clonedRow = _.cloneDeep(fields);

		clonedRow.forEach((clonedCell) => {
			let { name, pullCalculation, prior, isTotal, isMoney, PYList } = clonedCell;

			if (!prior) return;

			// Splitting data at the column level
			const splitteredList2 =
				pullCalculation?.lineSplitters && _.isArray(pullCalculation?.lineSplitters)
					? splitGroupLines([dataRow], pullCalculation?.lineSplitterType, pullCalculation?.lineSplitters, priorYearData)
					: [dataRow];

			// Filtering data at the column level
			const filteredList2 =
				pullCalculation?.lineFilterType && _.isArray(pullCalculation?.lineFilters)
					? filterGroupLines(splitteredList2, pullCalculation?.lineFilterType, pullCalculation?.lineFilters)
					: splitteredList2

			// Grab values
			const keyIdentifiers = pullCalculation?.keyIdentifiers || [name];
			const values = pullCalculation?.pullLines
				? filteredList2.map(x => keyIdentifiers.map(getPullFieldValues(x)))
				: keyIdentifiers.map(getPullFieldValues(dataRow));

			const fn = getPYCalculationFunction(pullCalculation?.fn);
			const calculatedValue = fn(values);
			let defaultValue = null;
			

			// Calculate default value on default condition
			const { defaultIfEmpty, defaultOnCondition } = pullCalculation || {};

			if (_.isNil(calculatedValue) && defaultOnCondition && _.isArray(defaultOnCondition)) {
				const resolvedDefaultValue = defaultOnCondition.find((conditionObject) => {
					const { lineFilterType, lineFilters } = conditionObject;
					const hasMatched = matchesCondition(dataRow, lineFilterType, lineFilters);
					return hasMatched;
				})

				defaultValue = resolvedDefaultValue?.defaultValue || null;
			}

			const calculatedColumnValue = !_.isNil(calculatedValue) ? calculatedValue : defaultValue || defaultIfEmpty || null;

			let extraFieldProperties = {};

			if (PYList) {
				const pyValueOrigins = getPYOrigin(values, PYList)
				const pyValueOrigin = pyValueOrigins.find(x => x)
				if (pyValueOrigin) extraFieldProperties = {
					PYOrigin: pyValueOrigin
				}
			}


			if (!_.isNil(calculatedColumnValue)) {
				setFieldValue(clonedCell, calculatedColumnValue, null, null, extraFieldProperties);
			}

			if (isTotal) {
				const foundLineTotalItem = !lineItemTotals.length ? null : lineItemTotals?.find(x => x.totalName === name);
				const columnTotal = _.toSafeInteger(clonedCell.default);

				if (!foundLineTotalItem) {
					const lineTotalItem = { totals: columnTotal, totalName: name };
					lineItemTotals.push(lineTotalItem);
				} else {
					foundLineTotalItem.totals += columnTotal;
				}

				const numericValue = isMoney === false ? noDollarFormatter(columnTotal) : moneyFormatter(columnTotal);
				setFieldValue(clonedCell, numericValue, null, null, extraFieldProperties);
			}
		});

		lineItems.push(clonedRow);

		if (hasSubWorkSheetEntity) {
			const clonedLineSections = _.cloneDeep(lineSections);

			clonedLineSections.forEach((lineSection, sectionIndex, self) => {
				const sectionEntityIdentifier = entityIdentifiers[sectionIndex];
				const { axcessDataType } = sectionEntityIdentifier;

				let passedPriorData = null;
				if (axcessDataType === AXCESS_KEYS.PASS_DATA_TO_CHILD) {
					passedPriorData = { fields: dataRow, name: sectionName };
				} else {
					const pySubData = subWorkSheets?.[sectionIndex]?.[0] || [];
					passedPriorData = pySubData?.[lineItems.length - 1];
				}

				if (passedPriorData) {
					buildFromSheetSectionFn([passedPriorData], [lineSection], self);
				}
			});

			entities.push({ id: new ObjectId().toString(), sections: clonedLineSections });
		}
	});

	if (lineItemTotals.length) {
		recalculateTotalFooter(lineItemTotals, group);
	}
};

export default calculateTableGroup;

export {
	// For testing purpose
	includesFieldIdentifier,
	includesfieldPrefix,
};