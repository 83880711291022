import './footer-mobile.css';
import * as IMG from '@utilities/constants/images';

const AppFooterMobile = () => {
    let year = new Date().getFullYear();

    return (
        <div className="appFooterMobile" data-testid='app-footer-mobile-id'>
            <img className="appFooterMobileLogo" data-testid='app-footer-mobile-logo-id' alt="CLA white logo" src={IMG.CLA_WHITE_LOGO} />
            <div className="appFooterMobileTextSection">
                <a className="appFooterMobileText" data-testid='app-footer-mobile-text-id' href="https://www.claconnect.com/"> {year} CliftonLarsonAllen LLP | CLAConnect.com </a>
                <span className="appFooterMobileVersion" data-testid='app-footer-mobile-version-id'> {`App Version:${process.env.REACT_APP_VERSION}`}</span>
            </div>
        </div>
    );
};

export default AppFooterMobile;
