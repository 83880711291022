import { EVENT_KEY_YES } from '@utilities/constants/triggerKeys';

const setHasPastResidentialEnergyCredit = (event, options) => {
    const { sections, formSections, setFormSections, inputState } = options;
    const name = formSections ? formSections : sections;

    name[0].groups[3].isVisible = event === EVENT_KEY_YES;
    name[0].groups[2].fields[1].default = event;
    const prevState = name[0].groups[2].fields[1].inputState || 0;
    name[0].groups[2].fields[1].inputState = (prevState & inputState);
    setFormSections(name);
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
    case 'setHasPastResidentialEnergyCredit':
        setHasPastResidentialEnergyCredit(event, options);
        break;
    default:
        break;
    }
};

const setVehicleSummaryLabel = (options) => {
    const { group, parentGroup, parentIndex } = options;
    let year = group.fields.find(field => field.type === 'year');
    let make = group.fields.find(field => field.name === 'alternativeMotorMake');
    const inputState = [];
    group.fields.forEach((item)=>{
        if(item.inputState && (item.type === 'year' || item.name === 'alternativeMotorMake')){
            inputState.push(item.inputState);
        }
    });

    if (year) year = year.default;
    else year = '';

    if (make && make.default && make.default !== ' ') make = `Make: ${make.default}`;
    else make = '';

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === 'vehicleSummary') {
            lineItemField.default = `${year}\n${make}`;
            if(inputState.length > 0){
                lineItemField.inputState = inputState[0];
            }
        }
    });
}

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
    case 'setVehicleSummaryLabel':
        setVehicleSummaryLabel(options);
        break;
    default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};