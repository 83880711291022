import usePageFramework from '@utilities/hooks/usePageFramework';
import OrganizerUploadsDrawer from '@components/OrganizerUploadsDrawer';
import Organizer from '@components/organizerExport/organizer';
import { useState, Suspense } from 'react';
import { Button, Collapse } from 'react-md';
import * as STRING from '@utilities/constants/strings';



const UploadList = ({ isGenerating, setIsGenerating, activeOrganizer, addOrgExport, organizerId }) => {
    const {
		REDUX,
		selectState,
	} = usePageFramework();
    const uploadList = selectState(REDUX.UPLOAD_LIST) || [];
    const [collapsed, setCollapsed] = useState(true);

    const filteredList = uploadList.filter((u) => u.form === STRING.DASHBOARD_SECTION_NAME);
	const uploadTotal = filteredList.length;

    return (
        <>
        
            {
                uploadList.length > 0 ? <div 
                    style={{width: '100%'}}>
                    <h3 className='file-export-button'>
                        Available Organizer Files
                    </h3>
                    <div className='file-export-button'>    
                        <Button themeType="contained"
                            data-testid='setCollapseBtnId'
                            className={uploadTotal ? 'entryNextButton uploadButton' : 'inactiveButton'}
                            disabled={!uploadTotal}
                            onClick={uploadTotal ? () => setCollapsed(!collapsed) : () => { }}>
                            {uploadTotal ? uploadTotal === 1 ? 'View 1 File' : `View ${uploadTotal} Files` : 'View Files'}
                        </Button>
                    </div>
                    <Collapse collapsed={collapsed} id={`form-upload-collapse-${organizerId}`}>
                        <OrganizerUploadsDrawer uploadList={filteredList} useType='readOnly'/>
                    </Collapse>
                    
                </div>: 'No Uploaded files available'
            }
            <br />
            <hr></hr>
            <h3 className='file-export-button'>
                        Organizer Exports
            </h3>
            
            <Suspense fallback={<div>Loading...</div>}>
                <Organizer isGenerating={isGenerating} setIsGenerating={setIsGenerating} activeOrganizer={activeOrganizer} addOrgExport={addOrgExport}/>
            </Suspense>
        </>
    )
}

export default UploadList;