// common module
import {
    Button, Form, FileInput,
    Grid,
    GridCell,
    Collapse as ReactMDCollapse
} from 'react-md';
import { useState, useEffect } from 'react';
import { AddSVGIcon, RemoveSVGIcon } from '@react-md/material-icons';

// custom modules
import useWindowDimensions from '@utilities/hooks/useWindowDimensions';
import * as STRING from '@utilities/constants/strings';
import usePageFramework from '@utilities/hooks/usePageFramework';
import DocumentManagerForm from '@components/documentManager/DocumentManagerForm';
import DocumentManagerUploadList from '@components/documentManager/DocumentManagerUploadList';
import { ACCEPTED_FILE_TYPES_1040 } from '@utilities/constants/document';
import DownloadNewFiles from '@components/newFile/downloadNewFiles';
import '@components/documentManager/DocumentManagerCategory.css';

const DocumentManagerCategory = ({ category, dataTestId, categories }) => {
    const {
        REDUX,
        ACTION,
        dispatch,
        selectState,
        onFileUpload,
        downloadNewFiles,
    } = usePageFramework();
    const { width } = useWindowDimensions();
    const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
    const isAdmin = selectState(REDUX.IS_ADMIN);
    const isInternalUser = (isPractitioner || isAdmin) ? true : false;
    const [isCollapsed, setIsCollapsed] = useState(true);
    const unassignedFilesTitle = 'Unassigned Files'; // TODO: should be constant?
    const isUnassigned = category.title === unassignedFilesTitle;
    const allDocumentsTitle = 'All Documents'; // TODO: should be constant?
    const isAllDocuments = category.title === allDocumentsTitle;
    const uploadList = selectState(REDUX.UPLOAD_LIST) || [];
    const fileCategoryCount = selectState(REDUX.FILE_CATEGORY_COUNT) || [];
    const [fileCount, setFileCount] = useState(0);
    const [newFilesCount, setNewFilesCount] = useState(0);

    useEffect(() => {
        let files = 0;
        if (isUnassigned) {
            files = category.uploads?.length;
        } else {
            const docList = category.cards
                .map((card) => card.uploads && card.uploads.length)
                .filter(docListCount => docListCount);
            if (docList.length > 0) {
                files = docList.reduce((previousValue, currentValue) => previousValue + currentValue);
            }
        }

        setFileCount(files);
        // eslint-disable-next-line
    }, [uploadList, fileCategoryCount]);

    useEffect(() => {
        if (!isAllDocuments) {
            return;
        }

        const count = uploadList.filter((upload) => {
            return upload.isNew;
        }).length;

        setNewFilesCount(count);
    }, [isAllDocuments, uploadList]);

    const handleDownloadNewFiles = async () => {
        dispatch(ACTION.setProgressText(STRING.DOWNLOADED_FILES_PROGRESS));
        dispatch(ACTION.setProgressVisible(true));

        downloadNewFiles().then(() => {
            dispatch(ACTION.setCustomDialogMsg(STRING.DOWNLOADED_FILES_COMPLETED));
        }).catch((err) => {
            dispatch(ACTION.setCustomDialogMsg(STRING.DOWNLOADED_FILES_ERRORED));
        }).finally(() => {
            dispatch(ACTION.setProgressVisible(false));
            dispatch(ACTION.setCustomDialogTitle(''));
            dispatch(ACTION.setShowCustomDialog(true));
        });
    };

    return (
        <div
            key={`doc-manager-form-container-${category.title.replace(/\s+/g, '')}`}
            id={`doc-manager-form-container-${category.title.replace(/\s+/g, '')}`}
            data-testid={dataTestId}
            className={
                width > 1250 ? 'mediumDesktopFormSize' :
                    width < 1250 && width > 1025 ? 'smallDesktopFormSize' :
                        width < 1025 ? 'tabletFormSize' : 'formSize'}
        >
            <div
                className='sectionHeaderContainer'
                data-testid={`doc-manager-form-header-container`}
            >
                <div
                    className='sectionHeaderTitle'
                    data-testid={`doc-manager-form-header-title`}>
                    <div className="sectionHeaderTitleContainer">
                        <div className='denseRowBtnContainer' style={{ margin: '18px 13px 0 0' }}>
                            <Button
                                className='denseRowBtn'
                                buttonType='icon'
                                id={`doc-manager-form-collapse-btn-${category.title.replace(/\s+/g, '')}`}
                                data-testid={`doc-manager-form-collapse-btn`}
                                onClick={() => setIsCollapsed(!isCollapsed)}
                                disableRipple
                            >
                                {
                                    isCollapsed ? <AddSVGIcon className="denseRowBtn" /> :
                                        <RemoveSVGIcon className="denseRowBtn" />
                                }
                            </Button>
                        </div>
                        <div onClick={() => setIsCollapsed(!isCollapsed)}>
                            <p className='formTitle'>
                                {category.title}<span className='fileCount'>  -  {(isUnassigned || isAllDocuments) ? category?.uploads?.length : fileCount} {(fileCount === 1 || ((isUnassigned || isAllDocuments) && category?.uploads?.length === 1)) ? 'File' : 'Files'}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="expandDocManagerSectionContainer">
                    {isAllDocuments && isInternalUser && newFilesCount > 0
                        ? <DownloadNewFiles total={newFilesCount} className={'centerContent'} onClick={handleDownloadNewFiles} />
                        : null
                    }
                    {category.title === unassignedFilesTitle ?
                        <Form>
                            <FileInput
                                className="expandFormBtn"
                                id={`doc-manager-unassigned-form-upload-btn`}
                                onChange={(event) => onFileUpload(event, null, STRING.DASHBOARD_SECTION_NAME, uploadList, { updateState: setIsCollapsed(true) }, STRING.DASHBOARD_SECTION_NAME)}
                                buttonType={'text'}
                                theme='clear'
                                themeType='flat'
                                icon={null}
                                multiple={true}
                                accept={ACCEPTED_FILE_TYPES_1040}>
                                Add Files
                            </FileInput>
                        </Form> :
                        <Button className="expandFormBtn" onClick={() => setIsCollapsed(!isCollapsed)}>
                            {isCollapsed ? 'View Details' : 'Hide Details'}
                        </Button>
                    }
                </div>
            </div>
            <ReactMDCollapse
                collapsed={isCollapsed}
            >
                <div className="docManagerGridContainer" style={{ paddingBottom: '20px', position: 'relative' }}>
                    <Grid className={!isUnassigned ? 'formHeaderContainer' : 'docManagerHeaderContainer'}>
                        <GridCell colSpan={(isUnassigned || isAllDocuments) ? 3 : 4} className='docManagerCell docManagerHeaderText' style={{ paddingLeft: '42px' }}>{(!isUnassigned && !isAllDocuments) ? 'Form Section' : 'File Name'}</GridCell>
                        <GridCell colSpan={isAllDocuments ? 3 : 2} className='docManagerCell docManagerHeaderText' style={{ paddingLeft: '27px' }}>{(!isUnassigned && !isAllDocuments) ? 'Files' : (isAllDocuments && 'Section')}</GridCell>
                        <GridCell colSpan={2} className='docManagerCell docManagerHeaderText'>Uploaded On</GridCell>
                        <GridCell colSpan={2} className='docManagerCell docManagerHeaderText'>{isInternalUser ? 'Downloaded On' : ''}</GridCell>
                        <GridCell colSpan={isUnassigned ? 3 : 2} className='docManagerCell docManagerHeaderText' style={{ paddingLeft: '11px' }}>Actions</GridCell>
                    </Grid>
                    {
                        isUnassigned || isAllDocuments ?
                            <Grid className='unassignedUploadsContainer'>
                                <DocumentManagerUploadList
                                    key={`doc-manager-form-${STRING.DASHBOARD_SECTION_NAME}`}
                                    uploadList={category.uploads}
                                    formKey={STRING.DASHBOARD_SECTION_NAME}
                                    sectionKey={STRING.DASHBOARD_SECTION_NAME}
                                    isUnassigned={isUnassigned}
                                    isAllDocuments={isAllDocuments}
                                    categories={categories}
                                    isCollapsed={isCollapsed}
                                />
                            </Grid> : category.cards.map((c, i) => (
                                <DocumentManagerForm
                                    key={`doc-manager-form-${c.key}`}
                                    form={c}
                                    index={i}
                                    isLast={(i + 1 === category.cards.length)} />
                            ))
                    }
                    {!isUnassigned && <div className="docManagerFormsConnectorLine"></div>}
                </div>
            </ReactMDCollapse >
        </div >
    );
};

export default DocumentManagerCategory;