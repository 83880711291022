import * as REDUX from '@utilities/constants/redux';

export const isExportSectionFiltered = (formName) => {
    const exportFormSectionsFiltered = [
        REDUX.BUSINESS_INCOME,
        REDUX.RENTAL_INCOME,
        REDUX.FARM_INCOME,
        REDUX.PASSTHRU_INCOME,
        REDUX.MISC_INCOME,
        REDUX.RETIREMENT_CONTRIBUTIONS,
        REDUX.DEPENDENT_CARE,
        REDUX.HIGHER_EDUCATION,
        REDUX.ENERGY_EFFICIENT,
        REDUX.HOUSEHOLD_EMPLOYMENT,
        REDUX.OTHER_DEDUCTIONS,
        REDUX.VEHICLE,
        REDUX.PROVIDER
    ];

    return exportFormSectionsFiltered.find(form => formName.startsWith(form));
}

const handleSectionVisibility = (entitySection) => {
    entitySection.groups.forEach(entitySectionGroup => {
        if (entitySectionGroup.lineItems && entitySectionGroup.lineItems.length) {
            entitySection.isVisible = true;
        } else if (entitySectionGroup.fields && entitySectionGroup.fields.length) {
            entitySectionGroup.fields.forEach((field) => {
                if (field.type === 'viewButton') return;
                else if (field.type === 'checkbox' && !field.default) 
                    entitySection.isVisible = false;
                else if (
                    field.inputState && typeof field.default === 'string' &&
                    !(field.default?.trim() !== '' || 
                    (field.type === 'money' && field.default?.trim() !== '0'))
                    ) {
                    entitySection.isVisible = false;
                }
            });
        }
    });
}

export const checkValuedFields = (section, formSections) => {
    let valuedFieldsCount = 0;
    if (section.subSections && !section.groups.length) {
        formSections.forEach(formSection => {
            section.subSections.forEach(subsection => {
                if (subsection.subSectionId === formSection.sectionId) {
                    valuedFieldsCount += checkValuedFields(formSection)
                }
            })
        })
        return valuedFieldsCount;
    }
    else if (section.groups && section.groups.length) {
        if (section.title === 'Passthrough Information') {
            section.groups.forEach(group => {
                if (group.entities && group.entities.length) {
                    group.entities.forEach((entity) => {
                        if (group.lineItems && group.lineItems.length) {
                            entity.sections.forEach((entitySection, sectionIdx) => {
                                if (sectionIdx === 0) {
                                    handleSectionVisibility(entitySection);
                                }
                            })
                        }
                    });
                }
            });
            
        } 
        
        if (
            section.isSubSection && 
            (section.title === 'Vehicle Miles' || section.title === 'Actual Vehicle Expenses')
        ) {
            section.groups.forEach(group => {
                if (
                    (group.groupType === 'lineItem' ||  group.groupType === 'lineSection') && 
                    group.lineItems && 
                    group.lineItems.length
                ) {
                    group.lineItems.forEach((lineItem) => {
                        lineItem.forEach((field) => {
                            if (field.name !== 'vehicleMiles' && field.inputState) {
                                if (field.type === 'lineButtons') return;
                                else if (
                                        field.default === undefined || 
                                        field.default?.trim() === '' || 
                                        field.default?.trim() === '0' || 
                                        field.default?.trim() === '$0'
                                    ) {
                                        section.isVisible = false;
                                 } else {
                                    valuedFieldsCount++;
                                 }

                            }
                        }) 
                    })
                        
                    if (valuedFieldsCount) section.isVisible = true;
                }
            })
        }

        section.groups.forEach(group => {
            if (group.lineItems && group.lineItems.length) {
                group.lineItems.forEach((lineItem) => {
                    lineItem.forEach((field, index) => {
                        if ((section.title === 'Health Savings Account - Contributions' || 
                            section.title === 'Health Savings Account - Distributions') && 
                            !index) return;

                        if (field.type === 'viewButton') return;
                        else if (field.type === 'checkbox' && field.default) valuedFieldsCount++;
                        else if (field.inputState && 
                            typeof field.default === 'string' && 
                            (field.default?.trim() !== '' || (field.type === 'money' && field.default?.trim() !== '0'))) {
                            valuedFieldsCount++;
                        }
                    });
                });
            } else if (group.fields && group.fields.length) {
                group.fields.forEach((field) => {
                    if (field.type === 'viewButton') return;
                    else if (field.type === 'checkbox' && field.default) valuedFieldsCount++;
                    else if (((field.prior && field.inputState) || !field.prior) && 
                        typeof field.default === 'string' && 
                        (field.default?.trim() !== '' || (field.type === 'money' && field.default?.trim() !== '0'))) {
                        valuedFieldsCount++;
                    }
                });
            }
            //for dense row line sections / entities
            else if (group.entities && group.entities.length) {
                group.entities.forEach((entity) => {
                    if (entity.sections && entity.sections.length) {
                        entity.sections.forEach((section) => {
                            valuedFieldsCount = checkValuedFields(section);
                        })
                    }
                });
            }
        });
        
        if (section.isSubSection && valuedFieldsCount === 0) section.isVisible = false;
    }

    return valuedFieldsCount;
}

export const formValuesChecker = {
    val: [],
    set hasFormValues(value) {
        this.val.push(value);
    },
    get hasFormValues() {
        if (this.val) {
            // each object of the array is first converted into a JSON string
            let formNames = this.val.map(JSON.stringify); 
            // new Set is created by passing formNames. will remove all the duplicate elements
            const uniqueFormNames = new Set(formNames);
            // the set is then converted to an Array
            formNames = Array.from(uniqueFormNames).map(JSON.parse);
            
            return formNames;
        }

        return null;
    }
}