import * as EVENT from '@utilities/constants/triggerKeys';

const setIsProvidingMedicalExpenses = (event, options) => {
    const { sections, formSections, setFormSections, inputState } = options;
    const name = formSections ? formSections : sections;

    name[1].groups[3].isVisible = event === EVENT.KEY_YES;
    name[1].groups[4].isVisible = event === EVENT.KEY_YES;
    name[1].groups[5].isVisible = event === EVENT.KEY_YES;
    name[1].groups[6].isVisible = event === EVENT.KEY_YES;
    name[1].groups[2].fields[1].default = event;
    const prevState = name[1].groups[2].fields[1].inputState || 0;
    name[1].groups[2].fields[1].inputState = (prevState & inputState);
    setFormSections(name);
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'setIsProvidingMedicalExpenses':
            setIsProvidingMedicalExpenses(event, options);
        break;
    default:
        break;
    }
};


// const sectionFieldLogic = (logicFunction, options) => {
//     switch (logicFunction) { 
//         case 'setOrganizationNameLabel':
//             setOrganizationNameLabel(options);
//         break;
//     default:
//         break;
//     }
// };

export {
    triggeredEvent,
    // sectionFieldLogic
};