import { useState, useEffect } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import * as NAV from '@utilities/constants/navigation';
import { triggeredEvent } from './medicalExpensesLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import medical2021 from './2021';
import medical2022 from './2022';
import medical2023 from './2023';
import medical2024 from './2024';

function MedicalExpenses(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.MEDICALEXPENSES);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: medical2021(year),
            2022: medical2022(year),
            2023: medical2023(year),
            2024: medical2024(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setSectionState(formSections);
        // eslint-disable-next-line
    }, []);

    const axcessIdentifiers = [
        { section: 'Deductions', id: 'Sch A' },
        { section: '1040 - U.S. Individual Income Tax Return', id: 'Government' },
        { section: 'Income', id: 'Sch C' },
        { section: 'Income', id: 'Sch F' },
        { section: 'Deductions', id: 'Other Adjustments' },
        { section: 'Income', id: 'Sch E, p 2 - Partnership' },
    ];

    return (
        <FormRendererHelper sections={sectionState} key="MedicalExpensesForm" formName={REDUX.MEDICAL_EXPENSES} identifiers={axcessIdentifiers} triggeredEvent= {triggeredEvent} 
            isExportForm={isExportForm}
        />
    );

}
export default MedicalExpenses;