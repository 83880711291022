import { YOUR_TAX_RETURN } from "@utilities/constants/strings";
import { taxReturnStatuses } from "@utilities/constants/taxReturn";
import api from '@utilities/claApi';
import { DOCUSIGN_EMBEDDED_SIGNING } from "@utilities/constants/navigation";

const pendingTaxReturnStatuses = [
    taxReturnStatuses.notSent,
    taxReturnStatuses.sent,
    taxReturnStatuses.partial
];

const notApplicableTaxReturnStatus = [null, undefined, '', "undefined", taxReturnStatuses.notApplicable];

const isEsignaturePath = (path) => {
    return path === DOCUSIGN_EMBEDDED_SIGNING;
}

const hasPendingTaxReturnStatus = (taxReturnStatus) => {
    return pendingTaxReturnStatuses.includes(taxReturnStatus);
}

const taxReturnStatusIsValid = (taxReturnStatus) => {
    return !notApplicableTaxReturnStatus.includes(taxReturnStatus);
}

const getSwimlaneDetails = (taxYear, taxReturnStatus, organizerId) => {
    const cards = [
        {
            title: `${taxYear ?? ''} Tax Return`,
            entryType: "TaxReturn",
            key: "dashCard-4-1",
            formName: "taxReturn",
            statusId: 1,
            route: "/taxReturnUrl",
            showBulkUpload: false,
            notes: [],
            statusDefault: 5,
            isRequired: true
        }
    ];

    return {
        cards,
        entryType: 'TaxReturn',
        title: YOUR_TAX_RETURN
    };
}

const getEmbeddedSigningUrl = async (organizerId) => {
    const result = {
        data: '',
        isSuccess: false
    }
    try {
        const resultData = await api.post(`/organizers/${organizerId}/generate-embedded-signing-url`);
        result.data = resultData.data;
        result.isSuccess = true;
    } catch (error) {
        console.error(error);
        result.data = error.message;
    }
    return result;
}

const getUserEsignatureStatus = async (organizerId) => {
    const userStatuses = [];
    try {
        await api.get(`/organizers/${organizerId}/getUserEsignatureStatus`)
            .then((response) => {
                userStatuses.push(...response.data);
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        return error;
    }
    return userStatuses;
}

export {
    hasPendingTaxReturnStatus,
    getSwimlaneDetails,
    taxReturnStatusIsValid,
    getEmbeddedSigningUrl,
    isEsignaturePath,
    getUserEsignatureStatus
}