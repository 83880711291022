import * as INPUTSTATE from '../../../../constants/inputState';

export function isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section){ 
    const lineGroup = section?.isDenseRow ? parentGroup : group;
    const lineIndex = section?.isDenseRow ? parentIndex : index;

    const groupLineItem = lineGroup?.lineItems?.length && lineGroup.lineItems[lineIndex] ? lineGroup.lineItems[lineIndex][0] : null; 
    let disableField = false; 

    // if form is NA or lock
    if (isFormLocked) { 
        return disableField = isFormLocked; 
    }

    if (section?.isEntityNa) {
        disableField = !field.isActiveEntity;
    }

    else if (lineGroup?.lineItems || field.inputState || field.isDisable) { 
        if (lineGroup?.lineItems && groupLineItem?.notApplicable) {
            disableField = groupLineItem.notApplicable;
        }
        else if (field.inputState && field.inputState === INPUTSTATE.PRIOR_INPUT) {
            if (field.isPriorEditable) {
                disableField = !field.isPriorEditable;
            } else {
                disableField = field.inputState === INPUTSTATE.PRIOR_INPUT;
            }
        }
        else if (field.isDisable) {
            disableField = field.isDisable;
        }
    }

    return disableField;
}