import { Radio, GridCell } from 'react-md';
import * as LOOKUPS from '@utilities/constants/lookupInfo';

function OptionButton(props) {
    const { field, fns, isFormLocked, index, group, parentGroup, parentIndex, section, grandParentGroup } = props;
    const lookups = LOOKUPS[field.lookup] || LOOKUPS.YESNO_RADIO;
    const options = lookups.map((lookup) => lookup.value);

    const radioStyle = {
        float: 'right',
        position: 'relative',
        margin: '0px 5px'
    };

    const radioCellStyle = {
        marginTop: '-5px',
    };

    const onChange = (option) => {
        field.default = option;

        const triggeredSections = section.isDenseRow ? null : {};

        if (field.triggered && !parentGroup?.isSummaryRow) {
            if (typeof(field.triggered) === 'string') {
                fns.triggered(field.triggered, option, triggeredSections);
            }  else {
                field.triggered(option);
            }
        }

        if (field.logicFunction && !parentGroup?.isSummaryRow) {
            field.logicFunction.forEach((logic) => {
                logic.isDifferentGroup ? fns.triggered(logic.trigger, field.default, triggeredSections) :
                    fns.sectionFieldLogic(logic?.trigger, {group, field, index, logic, parentGroup, parentIndex, section, fns});
            });
        }

        //for syncing summary view changes to individual view 
        if (parentGroup?.isSummaryRow && grandParentGroup) {
            grandParentGroup.entities[index]?.sections
                ?.find(entitySection => entitySection.title === section.title)
                ?.groups.forEach((entityGroup) => {
                    entityGroup.fields.forEach((entityField) => {
                        if (entityField.name === field.name) {
                            entityField.default = field.default;
                            if (entityField.logicFunction) {
                                entityField.logicFunction.forEach((logic) => {
                                    logic.isDifferentGroup ? fns.triggered(logic.trigger, entityField.default, grandParentGroup.entities[index]?.sections) :
                                        fns.sectionFieldLogic(logic?.trigger, {group, field: entityField, index, logic, parentGroup: grandParentGroup, parentIndex: index, section, fns});
                                });
                            }
                            if (entityField.triggered) {
                                fns.triggered(entityField.triggered, entityField.default, grandParentGroup.entities[index]?.sections);
                            }
                        }
                    })
                });
        }

        fns.saveForm(field);
    };

    const radio = options.map((option) => (
        <Radio
            id={`radio-${field.name}-${option}`}
            key={`radio-${option}`}
            name={field.name}
            trigger={field.trigger || null}
            label={option}
            value={option}
            style= {radioStyle}
            checked= {field.default === option}
            onChange={() => onChange(option)}
            disabled={isFormLocked}
        />
    ));

    return (
        <GridCell colSpan={6} key={`cell-${field.name}`} style={field.styles || radioCellStyle}>
            {/* <Label style={lblAlign} key={`lbl-${field.name}`} htmlFor={field.name}>{field.label}</Label> */}
            {radio}
        </GridCell>
    );
}

export default OptionButton;
