import { isEmpty, isRegExp } from 'lodash';
import { convertDate } from './convertDate';
import * as routingNumberValidator from 'bank-routing-number-validator';

const validators = {
    email: (config) => (value) => {
        const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return pattern.test(value) ? null : config.message;
    },
    required: (config) => (value) => (isEmpty(value) || value === ' ' ? config.message : null),
    maxDate: (config) => (value) => {
        const dateEnd = config.date ? config.date === 'today' ? convertDate(String(new Date())) : convertDate(config.date) : convertDate('12-31-2099');
        const dateValue = new Date(value);
        const end = new Date(dateEnd);

        return dateValue > end ? config.message : null;
    },
    minDate: (config) => (value) => {
        const dateStart = config.date ? config.date === 'today' ? convertDate(String(new Date())) : convertDate(config.date) : convertDate('01-01-1900');
        const dateValue = new Date(value);
        const start = new Date(dateStart);
        
        return dateValue < start ? config.message : null;
    },
    minLength: (config) => (value) => value != '' && value.length < config.length ? config.message : null,
    maxLength: (config) => (value) => value.length > config.length ? config.message : null,
    pattern: (config) => (value) => value === '' ? null : isRegExp(config.pattern) && config.pattern.test(value) ? null : config.message,
    year: (config) => (value) => (value.trim() != '' && Number(value) < 1950) || (value.trim() != '' && Number(value) > new Date().getFullYear() + 1) ? config.message : null,
    ssn: (config) => (value) => value.trim() != '' && value.replace(/[^0-9]/g, '').length < 9 ? config.message : null,
    fixLength: (config) => (value) => value != '' && value.length < config.length ? config.message : null,
    ein: (config) => (value) => value.trim() != '' && value.replace(/[^0-9]/g, '').length < 9 ? config.message : null,
    maxValue: (config) => (value) => {
        const newValue = value.replace(/,/g, '');
        return parseFloat(newValue) > config.value ? config.message : null;
    },
    routingNumber: (config) => (value) => value && !routingNumberValidator.ABARoutingNumberIsValid(value) ? config.message : null,
};

export function validateField(value, field) {
    for (let key in field.validations) {
        const config = field.validations[key];
        const validator = validators[key](config);
        const errorMessage = validator(value);
        if (errorMessage) {
            return errorMessage;
        }
    }
    return null;
}