let createdStore;

const createHelperMiddleware = store => next => action => {
    createdStore = store;
    return next(action);
};

const selectReduxGlobalState = (state, item) => {
    return state.get('global').get(item);
};


export {
    createdStore,
    selectReduxGlobalState,
};

export default createHelperMiddleware;