import { YEAR } from '@utilities/constants/redux';

const getYear = (selectState) => {
  const year = selectState(YEAR) || 0;
  let prior, current, next = 0;

  if (year) {
    current = year;
    prior = year - 1;
    next = year + 1;
  }

  return Object.freeze({
    prior,
    current,
    next
  });
}

export default getYear;