/* eslint-disable no-unused-vars */
import { FormMessage, TextField, Tooltipped, HoverModeProvider, } from 'react-md';
import NumberFormat from 'react-number-format';
import { useField } from '../useField';
import { getHintText } from '../utils/getHintText';
import { isFieldDisabled } from '../utils/isDisabled';
import { adjustLabels } from '../utils/adjustLabels';
import { useEffect, useState, useRef } from 'react';
import { renderLabel } from '../utils/isRequired';
import { isError } from '../utils/isError';

function PercentField(props) {
    // eslint-disable-next-line no-unused-vars
    const { field, index, group, isFormLocked, section, parentGroup, parentIndex, grandParentGroup} = props;
    const { state, handleBlur, updateState, handleNumberValueChange } = useField(props);
    const valued = state.value && state.value.length > 0 ? true : false;
    const hintText = getHintText(state, valued, field);
    const priorYearChanged = field.priorYearValue !== state.value;
    const [labelClassName, setLabelClassName] = useState('');
    const ref = useRef(null);
    
    useEffect(() => {
        adjustLabels(ref, section, setLabelClassName);
    }, []);

    const handleFocus = (event) => {
        event.target.value === '0' && updateState('', false, '');
    };

    const validateTooltipValue = (state) => {
        return state.value && state.value !== ' ' ? state.value : '';
    };

    return (
        <div key={`cell-${field.name}`} style={field.hasColSpace && !field.isVisible ? {visibility: 'hidden'} : null}> 
            <HoverModeProvider>
                <Tooltipped id={`hover-${section.title}-${field.name}-${index}`} tooltip={validateTooltipValue(state)} position='above' key={`hover-${field.name}-${index}`} className='pracDashOnHover'>
                    <div className={labelClassName}>
                        {isError(field)}
                        <NumberFormat
                            customInput={TextField}
                            //react-md props
                            getInputRef={ref}
                            className={`cla-money-field ${field.priorYearValue && priorYearChanged ? 'cla-prior-year-data-edited' : !field.priorYearValue && state.value.trim() !== '' ? 'cla-prior-year-data-edited' : null}`}
                            inputClassName={!priorYearChanged ? 'cla-prior-year-data' : null}
                            id={`total-${section.title}-${field.name}-${index}`}
                            name={field.name}
                            key={`total-${field.name}-${index}`}
                            type="text" 
                            label={renderLabel(field)}
                            error={state.error}
                            value={state.value}
                            placeholder={field.placeholder}
                            onBlur={handleBlur}
                            onFocus={(event) => handleFocus(event)}
                            minLength={field.minLength}
                            maxLength={field.maxLength || 6} // max of 100.00% = 3 whole + period + 2 decimal places 
                            rightChildren={field.hasNoPercentSign ? null : valued && <div className={isFieldDisabled(isFormLocked, field, group, index) ? 'dollarMoneyField percentSymbol disabledDollar' : 'dollarMoneyField percentSymbol'}>%</div>}
                            disabled={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section)}
                            required={props.required ? true : false}
                            data-testid='percent-field'
                            //react-number-format props:
                            allowNegative={false}
                            decimalScale={2}
                            isNumericString={true}
                            onValueChange={(values) => {
                                const { value } = values;
                                handleNumberValueChange(value);
                            }}
                        />
                    </div>
                </Tooltipped>
            </HoverModeProvider>
            <FormMessage 
                id={`${field.id}-error-tooltip`}
                error={state.error}
                messageStyle={{lineHeight: .85}}
                data-testid='error-message'
            >
                {hintText}
            </FormMessage>
        </div>
    );
}

export default PercentField;