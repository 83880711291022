import { FEDERAL_2020_TAX_PAYMENTS_UPLOAD } from '@utilities/constants/strings';

const taxPaymentsFn = (year) => {
    const sections = [
        {
            title: `${year.current} Federal Tax Payments Made`,
            shortTitle: 'Federal Tax Payments',
            isVisible: true,
            sectionId: 1,
            groups: [

                {
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    groupId: 1,
                    fields: [
                        { name: 'federalTaxQuarter', type: 'label', class: 'labelRequired' },
                        { label: '$ 0', wrapLabel: 'Calculated Amount', name: 'federalTaxCalculatedAmount', type: 'label', isTotal: true, },
                        { label: 'Due Date', name: 'federalTaxDueDate', type: 'date', axcessTranslate: 'date', isDefaultDate: true, class: 'labelPriorData' },
                        {
                            label: 'Amount', wrapLabel: '2021 Amount Paid', name: 'federalTaxCyAmountPaid', type: 'money',
                            isTotal: true,
                            logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['federalTaxDatePaid'] },{ trigger: 'setAmountAndDatePaidValidation'}]
                        },
                        {
                            label: 'Date Paid', name: 'federalTaxDatePaid', type: 'date',
                            logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['federalTaxCyAmountPaid'] },{ trigger: 'setAmountAndDatePaidValidation'}],
                            axcessTranslate: 'date', class: 'labelPriorData',
                        },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    prePopulate: [
                        { label: 'Carryover from Prior Year', prePopulateAugments: [{ name: 'G20631.30', sameValue: true }, { name: 'federalTaxDueDate' }, { name: 'G20631.30', sameValue: true }, { name: 'federalCarryOverDate' }], lineOptions: { staticLine: true } },
                        {
                            label: '1st Quarter', prePopulateAugments: [
                                {
                                    name: 'G20631.39',
                                    calculateKeys: ['IFDSESIN.0', 'G20631.39'],
                                    calculateFn: 'conditionalValuePull',
                                    priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSFEST.20', 'C-IFDSFEST.17'] },] }
                                },
                                {
                                    name: 'federalTaxDueDate',
                                    calculateKeys: ['IFDSESIN.0', 'G20631.39'],
                                    calculateFn: 'setQ1DueDatePull',
                                    defaultDueDate: true
                                },
                                { name: 'IFDSFEST.20' },
                                { name: 'IFDSFEST.17' }
                            ]
                        },
                        {
                            label: '2nd Quarter', prePopulateAugments: [
                                {
                                    name: 'G20631.40',
                                    calculateKeys: ['IFDSESIN.2', 'G20631.40'],
                                    calculateFn: 'conditionalValuePull',
                                    priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSFEST.18', 'C-IFDSFEST.11'] },] }
                                },
                                {
                                    name: 'federalTaxDueDate',
                                    calculateKeys: ['IFDSESIN.2', 'G20631.40'],
                                    calculateFn: 'setQ2DueDatePull',
                                    defaultDueDate: true
                                },
                                { name: 'IFDSFEST.18' },
                                { name: 'IFDSFEST.11' }
                            ]
                        },
                        {
                            label: '3rd Quarter', prePopulateAugments: [
                                {
                                    name: 'G20631.41',
                                    calculateKeys: ['IFDSESIN.3', 'G20631.41'],
                                    calculateFn: 'conditionalValuePull',
                                    priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSFEST.12', 'C-IFDSFEST.15'] },] }
                                },
                                {
                                    name: 'federalTaxDueDate',
                                    calculateKeys: ['IFDSESIN.3', 'G20631.41',],
                                    calculateFn: 'setQ3DueDatePull',
                                    defaultDueDate: true
                                },
                                { name: 'IFDSFEST.12' },
                                { name: 'IFDSFEST.15' }
                            ]
                        },
                        {
                            label: '4th Quarter', prePopulateAugments: [
                                {
                                    name: 'G20631.42',
                                    calculateKeys: ['IFDSESIN.4', 'G20631.42'],
                                    calculateFn: 'conditionalValuePull',
                                    priorModifiers: { logicFunction: [{ trigger: 'setConditionalFieldValidation', requiredFields: ['C-IFDSFEST.16', 'C-IFDSFEST.14'] },] }
                                },
                                {
                                    name: 'federalTaxDueDate',
                                    calculateKeys: ['IFDSESIN.4', 'G20631.42'],
                                    calculateFn: 'setQ4DueDatePull',
                                    defaultDueDate: true
                                },
                                { name: 'IFDSFEST.16' },
                                { name: 'IFDSFEST.14' }
                            ]
                        },
                    ],
                    prePopulateIndexes: [{ priorIndex: 1 }, { currentIndex: 2 }, { currentIndex: 3 }, { currentIndex: 4 }],
                    prePopulateControls: ['label', 'label', 'label', 'label'],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Quarter', smallScreenLabel: 'Payments Details', col: 3, colTablet: 1, },
                            { label: 'Calculated Amount', col: 2, align: 'right', colTablet: 1, },
                            { label: 'Due Date', col: 2, colTablet: 2, },
                            { label: `${year.current} Amount Paid`, col: 2, align: 'right', colTablet: 1, },
                            { label: 'Date Paid', col: 2, colTablet: 2, },
                            { label: 'Payment Confirmation', col: 1, align: 'noPaddingLeft', colTablet: 1, },
                        ],
                        footerLabels: [
                            { type: 'label', label: 'Total', col: 3, colTablet: 1, },
                            { type: 'totalMoney', name: 'federalTaxCalculatedAmount', col: 2, colTablet: 1, label: '$ 0' },
                            { type: 'totalMoney', name: 'federalTaxCyAmountPaid', col: 4, colTablet: 3, year: 'current', label: '$ 0' },
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: FEDERAL_2020_TAX_PAYMENTS_UPLOAD, viewSectionId: 0 },
                        ]
                    }
                }
            ],
        },
        {
            title: `${year.current} State Tax Payments Made`,
            shortTitle: 'State Tax Payments',
            collapseClass: 'collapsibleForm',
            sectionId: 2,
            sectionType: 'stateEntity',
            isVisible: true,
            entities: []
        }
    ];

    return sections;
};

export default taxPaymentsFn;