/* eslint-disable no-unused-vars */
import { useField } from '../useField';
import { FormMessage, TextField, Tooltipped, HoverModeProvider, } from 'react-md';
import { getHintText } from '../utils/getHintText';
import { isFieldDisabled } from '../utils/isDisabled';
import { adjustLabels } from '../utils/adjustLabels';
import { useEffect, useState, useRef } from 'react';
import { renderLabel } from '../utils/isRequired';
import { isError } from '../utils/isError';

function ZipPostalField(props) {
    // eslint-disable-next-line no-unused-vars
    const { field, index, group, isFormLocked, section, parentGroup , parentIndex, grandParentGroup} = props;
    const { state, handleBlur, handleChange, handleFocus } = useField(props);
    const valued = state.value && state.value.length > 0 ? true : false;
    const hintText = getHintText(state, valued, field);
    const priorYearChanged = field.priorYearValue !== state.value;
    const [labelClassName, setLabelClassName] = useState('');
    const ref = useRef(null);
    
    useEffect(() => {
        adjustLabels(ref, section, setLabelClassName);
    }, []);

    return (
        <div>
            <HoverModeProvider>
                <Tooltipped id={`hover-${section.title}-${field.name}-${index}`} tooltip={state.value} position='above' key={`hover-${field.name}-${index}`} className='pracDashOnHover'>
                    <div className={labelClassName}>
                        {isError(field)}
                        <TextField 
                            ref={ref}
                            className={field.priorYearValue && priorYearChanged ? 'cla-prior-year-data-edited' : !field.priorYearValue && state.value.trim() !== '' ? 'cla-prior-year-data-edited' : null}
                            inputClassName={!priorYearChanged ? 'cla-prior-year-data' : null}
                            id={`zip-postal-${section.title}-${field.name}-${index}`}
                            name={field.name}
                            key={`zip-postal-${field.name}-${index}`} 
                            type="text" 
                            label={renderLabel(field)}
                            error={state.error}
                            value={state.value}
                            placeholder={field.placeholder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            maxLength={10}
                            disabled={isFieldDisabled(isFormLocked, field, group, index, parentGroup, parentIndex, section)}
                            required={field.required ? true : false}
                            data-testid='zipPostal-field'
                        />
                    </div>
                </Tooltipped>
            </HoverModeProvider>
            <FormMessage 
                id={`${field.id}-error-tooltip`}
                error={state.error}
                data-testid='error-message'
            >
                {hintText}
            </FormMessage>
        </div>
    );
}

export default ZipPostalField;