export const SCREEN_SIZES = {
    MOBILE: 'Mobile',
    MOBILE_480: 'Mobile_480',
    TABLET: 'Tablet',
    LAPTOP: 'Laptop',
    DESKTOP: 'Desktop',
}

export const SCREEN_BREAKPOINTS = {
    MOBILE: 320,
    MOBILE_480: 480,
    TABLET: 768,
    LAPTOP: 1025,
    DESKTOP: 1440,
}