import { Dialog, DialogContent, CircularProgress } from 'react-md';

import './progressDialog.css';

function ProgressDialog(props) {
    const { visible, loadingText } = props;

    return (
        <div >
            <Dialog
                id="progress=dialog"
                visible={visible}
                onRequestClose={() => {}}
                aria-labelledby="dialog-title"
                disableFocusContainer={true}
                disableEscapeClose={true}
                className='progressContainer'
                data-testid='progress-dialog'
            >
                <div className='progressTitle' data-testid='progress-text'>{loadingText} </div>
                <DialogContent>
                    <CircularProgress id='upload-circular-progress' data-testid='circular-progress'/>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ProgressDialog;