export const BINARY = 'binary';
export const UNARY = 'unary';
// Constant query states below are used for the PractitionerDashboard component
export const MAX_PREFETCH_LIMIT = 100; // This is the max of records returned by the API
export const DEFAULT_PAGE_LIMIT = 25;
export const DEFAULT_FILTER_QUERY = [];
export const DEFAULT_SORT_QUERY = { sort: 'title', order: 'asc' };

export const SORT_TO_DATA_MAP = {
  title: 'title',
  description: 'description',
  interval: 'interval',
  status: 'status'
};

export const FILTER_TO_DATA_MAP = {
  title: 'title',
  description: 'description',
  interval: 'interval',
  status: 'status'
};

export const OPERATORS_MAP = {
  contains: { key: 'co', type: BINARY },
  equals: { key: 'eq', type: BINARY },
  startsWith: { key: 'sw', type: BINARY },
  endsWith: { key: 'ew', type: BINARY },
  isEmpty: { key: 'ie', type: UNARY },
  isNotEmpty: { key: 'ine', type: UNARY },
};

export const OPERATORS_LIST = Object.keys(OPERATORS_MAP);