import { 
  BUSINESS_INCOME, 
  RENTAL_INCOME, 
  FARM_INCOME, 
  PASSTHRU_INCOME,
  FOREIGN_INCOME,
  FOREIGN_ASSETS,
  PROVIDER
} from '@utilities/constants/redux';

const getFormName = (formName, parentFormName) => {
  let modifiedFormName = formName.split('-');
	modifiedFormName = modifiedFormName.length > 1 ? modifiedFormName[0] : formName;
  let modifiedParentFormName = parentFormName?.split('-');
	modifiedParentFormName = modifiedParentFormName?.length > 1 ? modifiedParentFormName[0] : parentFormName;

  const isSchedForm = modifiedFormName === BUSINESS_INCOME || 
    modifiedFormName === RENTAL_INCOME || 
    modifiedFormName === FARM_INCOME ||
    modifiedFormName === PASSTHRU_INCOME ||
    modifiedFormName === FOREIGN_INCOME || 
    modifiedFormName === PROVIDER ||
    modifiedFormName === FOREIGN_ASSETS
    ;

  const isVehicleForm = modifiedParentFormName === BUSINESS_INCOME || 
    modifiedParentFormName === RENTAL_INCOME || 
    modifiedParentFormName === FARM_INCOME ||
    modifiedParentFormName === PASSTHRU_INCOME ||
    modifiedFormName === FOREIGN_INCOME ||
    modifiedFormName === PROVIDER ||
    modifiedFormName === FOREIGN_ASSETS;

  modifiedFormName = isSchedForm ? modifiedFormName : isVehicleForm ? modifiedParentFormName : '';

  return {modifiedFormName, isSchedForm};
}

export default getFormName;