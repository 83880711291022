import { NOT_APPLICABLE } from '@utilities/constants/strings';
import { Card } from 'react-md';

function NotApplicableCard(props) {
    const {
        renderCard,
        cardStyle,
        isOnClickEnabled,
        icon,
        onCardClick
    } = props;

    return renderCard
        ? (
            <Card 
                className={`dashboardHeaderIconCard entryNoShadow dashboardHeaderCard notApplicable`}
                style={cardStyle}
                onClick={() => { if (isOnClickEnabled) onCardClick(); }} 
            >
                <div id={'fileUploadCard'} className={`dashboardHeaderIconName headerCardsPadding`}>
                    <div className="fileUploadCard">
                        <img alt={'Not applicable icon'} src={icon} />
                        <p style={{ textAlign: 'center' }}>{NOT_APPLICABLE}</p>
                    </div>
                </div>
            </Card>
        )
        : <></>;
}

export default NotApplicableCard;