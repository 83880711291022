// common modules
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useToggle } from "@react-md/utils";
import {
	useParams,
} from "react-router-dom";
import {
	TableContainer,
	Table,
	TableHeader,
	TableRow,
	TableCell,
	TableBody,
	TableFooter
} from 'react-md';
import { NativeSelect } from "@react-md/form";
import ReactPaginate from 'react-paginate';
import moment from 'moment';

// custom modules
import './OrganizerDetails.css';
import * as NAV from '@utilities/constants/navigation';
import Breadcrumb from '@components/Breadcrumb.js';
import * as IMG from '@utilities/constants/images.js';
import api from '@utilities/claApi';

const sortAndFilter = (data, sort, filters) => {
	const translatedSortOrder = sort.order === 'ascending' ? 'asc' : 'desc';

	const sorted = _.orderBy(data, sort.key, translatedSortOrder);

	const filtered = sorted.filter((log) => {
		return ((filters.createdBy && log.createdBy === filters.createdBy) || (!filters.createdBy))
			&& ((filters.name && log.name === filters.name) || (!filters.name))
	});

	return filtered;
};

function OrganizerDetails(props) {
	const { id } = useParams();
	const [isLoaded, enable, disable] = useToggle(false);
	const [data, setData] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [sort, setSort] = useState({
		key: 'event.timestamp',
		order: 'ascending'
	});
	const [filters, setFilters] = useState({
		createdBy: '',
		name: '',
	});
	const [filteredData, setFilteredData] = useState(sortAndFilter(data, sort, filters));
	const [pageCount, setPageCount] = useState(Math.ceil(filteredData.length / pageSize));
	const [currentPage, setCurrentPage] = useState(0);
	const [currentPageData, setCurrentPageData] = useState(filteredData);

	const sortAndFilterAndPaginate = useCallback(async () => {
		const filtered = sortAndFilter(data, sort, filters);

		const offset = currentPage * pageSize;

		setFilteredData(filtered);
		setCurrentPageData(filtered.slice(offset, offset + pageSize));
		setPageCount(Math.ceil(filtered.length / pageSize));
	}, [data, pageSize, currentPage, sort, filters]);

	useEffect(() => {
		if (!isLoaded) {
			// loading logs via api call and set into data
			api.get(`/organizers/${id}/logs`)?.then((response) => {
				setData(response.data.results);

				enable(); // set isLoaded to true
			}).catch((error) => {
				console.log(error);
				enable(); // set isLoaded to true
			});
		}

		sortAndFilterAndPaginate();
	}, [isLoaded, id, enable, sortAndFilterAndPaginate]);

	const navItems = [
		{ to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
		{ to: NAV.MANAGE_ORGANIZERS, label: 'Organizers' },
		{ to: `${NAV.MANAGE_ORGANIZERS}/${id}`, label: id, current: true }
	];

	const updateFilters = (key, event) => {
		setFilters({
			...filters,
			[key]: event.currentTarget.value
		});
		disable();
	};

	const updateSort = (sortKey) => {
		setSort((prevState) => {
			const prevSortKey = prevState.key;
			const prevSortOrder = prevState.order;

			let sortOrder;
			if (sortKey === prevSortKey) {
				// it's the same column, so toggle the sort order
				sortOrder = prevSortOrder === "ascending" ? "descending" : "ascending";
			} else {
				// it's a new column to sort by, so default to ascending for the name column
				// but descending for all the rest.
				sortOrder = sortKey === "name" ? "ascending" : "descending";
			}

			return {
				key: sortKey,
				order: sortOrder
			};
		});

		disable();
	};

	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage);
	};

	return (
		<div className="pracDashboardSize">
			<Breadcrumb items={navItems} />
			<h2>Logs</h2>
			<TableContainer style={{ maxHeight: "40rem" }}>
				<Table className="log-table" fullWidth>
					<TableHeader sticky>
						<TableRow>
							<TableCell
								key="log-time-filter"
								className="log-table-filter-cell">
							</TableCell>
							<TableCell
								key="log-created-filter"
								className="log-table-filter-cell">
								<NativeSelect
									id="log-created-filter-field"
									data-testid="created-by-filter-field"
									className="header-filter-field"
									name="select"
									value={filters.createdBy}
									onChange={(event) => updateFilters('createdBy', event)}
								>
									<option className="header-filter-option" value="" />
									{filteredData
										.map(log => log.createdBy)
										.filter((value, index, self) => self.indexOf(value) === index)
										.map((createdBy, i) => (
											<option className="header-filter-option" key={i} value={createdBy}>
												{createdBy}
											</option>
										))}
								</NativeSelect>
							</TableCell>
							<TableCell
								key="log-name-filter"
								className="log-table-filter">
								<NativeSelect
									id="log-name-filter-field"
									className="header-filter-field"
									name="select"
									value={filters.name}
									onChange={(event) => updateFilters('name', event)}
								>
									<option className="header-filter-option" value="" />
									{filteredData
										.map(log => log.name)
										.filter((value, index, self) => self.indexOf(value) === index)
										.map((name, i) => (
											<option className="header-filter-option" key={i} value={name}>
												{name}
											</option>
										))}
								</NativeSelect>
							</TableCell>
							<TableCell
								key="log-notes-filter"
								className="log-table-filter-cell">
							</TableCell>
						</TableRow>
						<TableRow className="log-table-header-row">
							<TableCell
								key="log-createdon-header"
								className={'createdOn' === sort.key ? 'log-table-header-selected' : 'log-table-header'}
								sortIconAfter={true}
								sortIcon={<img width={9} alt="White expand icon" src={IMG.WHITE_EXPANDED_ICON}></img>}
								aria-sort={'createdOn' === sort.key ? sort.order : 'none'}
								onClick={() => updateSort('createdOn')}>
								Time
							</TableCell>
							<TableCell
								key="log-createdby-header"
								className={'createdBy' === sort.key ? 'log-table-header-selected' : 'log-table-header'}
								sortIconAfter={true}
								sortIcon={<img width={9} alt="White expand icon" src={IMG.WHITE_EXPANDED_ICON}></img>}
								aria-sort={'createdBy' === sort.key ? sort.order : 'none'}
								onClick={() => updateSort('createdBy')}>
								Actor
							</TableCell>
							<TableCell
								key="log-name-header"
								className={'name' === sort.key ? 'log-table-header-selected' : 'log-table-header'}
								sortIconAfter={true}
								sortIcon={<img width={9} alt="White expand icon" src={IMG.WHITE_EXPANDED_ICON}></img>}
								aria-sort={'name' === sort.key ? sort.order : 'none'}
								onClick={() => updateSort('name')}>
								Event
							</TableCell>
							<TableCell
								key="log-details-header"
								className={'details' === sort.key ? 'log-table-header-selected' : 'log-table-header'}
								sortIconAfter={true}
								sortIcon={<img width={9} alt="White expand icon" src={IMG.WHITE_EXPANDED_ICON}></img>}
								aria-sort={'details' === sort.key ? sort.order : 'none'}
								onClick={() => updateSort('details')}>
								Notes
							</TableCell>
						</TableRow>
					</TableHeader>
					<TableBody className="log-table-body">
						{
							currentPageData.map((log, i) => (
								<TableRow key={`log-row-${i}`}>
									<TableCell
										key={`log-row-${i}-time`}
										className="log-table-cell log-table-cell-text"
										lineWrap={true}>
										{moment(new Date(log.createdOn))
											.format('MM/DD/YYYY h:mm:ss A')}
									</TableCell>
									<TableCell
										key={`log-row-${i}-actor`}
										className="log-table-cell log-table-cell-text"
										lineWrap={true}>
										{log.createdBy}
									</TableCell>
									<TableCell
										key={`log-row-${i}-event`}
										className="log-table-cell log-table-cell-text"
										lineWrap={true}>
										{log.name}
									</TableCell>
									<TableCell
										key={`log-row-${i}-result`}
										className="log-table-cell log-table-cell-text"
										lineWrap={true}>
										{log.details}
									</TableCell>
								</TableRow>
							))
						}
					</TableBody>
					<TableFooter sticky>
						<TableRow className="log-table-footer-row">
							<TableCell>
								<NativeSelect
									id="page-size-field"
									data-testid="page-size-field"
									className="header-filter-field"
									name="select"
									value={pageSize}
									onChange={(event) => {
										setPageSize(parseInt(event.currentTarget.value));
										setCurrentPage(0);
									}}
								>
									<option className="header-filter-option" key="5" value="5">5</option>
									<option className="header-filter-option" key="10" value="10">10</option>
									<option className="header-filter-option" key="25" value="25">25</option>
									<option className="header-filter-option" key="50" value="50">50</option>
									<option className="header-filter-option" key="100" value="100">100</option>
								</NativeSelect>
							</TableCell>
							<TableCell colSpan="4" className="log-table-cell-text">
								<ReactPaginate
									previousLabel={'<'}
									nextLabel={'>'}
									pageCount={pageCount}
									marginPagesDisplayed={pageSize}
									pageRangeDisplayed={10}
									onPageChange={handlePageClick}
									containerClassName={'log-table-pagination'}
									activeClassName={'active'}
								/>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div >
	);
}

export default OrganizerDetails;