import { COMPLETED } from '@utilities/constants/cardStatus';
import { COMPLETE_ICON_ACTIVE, COMPLETE_ICON_NORMAL } from '@utilities/constants/images';
import { COMPLETED_TEXT, SUBMIT_TEXT } from '@utilities/constants/strings';
import { Card } from 'react-md';

function SubmitToClaCard(props) {
    const {
        isDocManager,
        isDashboard,
        formStatus,
        completeCardStyles,
        statusId,
        onCardClick
    } = props;

    const iconText = isDashboard ? SUBMIT_TEXT : COMPLETED_TEXT;
    const isFormCompleted = formStatus === COMPLETED_TEXT;
    const submitToClaCardClass = isDashboard ? 'submitToCla' : '';
    const cardStyle = isDashboard && isFormCompleted ? { border: '3px solid #0BBA46' } : completeCardStyles;
    const nestedDivStyleConditions = (statusId === COMPLETED) || (isDashboard && completeCardStyles) || (isDashboard && isFormCompleted);
    const divIconImgClass = nestedDivStyleConditions ? '' : 'iconImg';
    const imgSrc = nestedDivStyleConditions ? COMPLETE_ICON_ACTIVE : COMPLETE_ICON_NORMAL;

    return !isDocManager
        ? (
            <Card
                style={cardStyle}
                className={`dashboardHeaderIconCard entryNoShadow dashboardHeaderCard completeCard iconCard ${submitToClaCardClass}`}
                onClick={() => onCardClick()}
            >
                <div className={`dashboardHeaderIconName headerCardsPadding ${divIconImgClass}`} >
                    <img alt='complete-button' src={imgSrc} />
                    <div className="iconText">{iconText}</div>
                </div>
            </Card>
        )
        : <></>;
}

export default SubmitToClaCard;