const updateErrorCodeAndGetHintText = (errorMessage, errorCodes = []) => {
    if (errorMessage === "Required") {
        if (errorCodes[0] !== "Required") {
            errorCodes[0] = "Required"
        }
        return "Req";
    }
    if (!errorCodes.includes(errorMessage)) {
        errorCodes.push(errorMessage)
    }
    return `Err${errorCodes.indexOf(errorMessage)}`
}
export {
    updateErrorCodeAndGetHintText
}