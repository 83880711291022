import { useState, useEffect } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent } from './aboutYourYearLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import * as NAV from '@utilities/constants/navigation';

import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import aboutYour2021 from './2021';
import aboutYour2022 from './2022';
import aboutYour2023 from './2023';
import aboutYour2024 from './2024';
import useAdaptive from '@utilities/hooks/useAdaptive';

import './aboutNewYear.css';

function AboutYourYear(props) {
  const { isExportForm } = props
  const { updateCard, REDUX, selectState } = usePageFramework(NAV.ABOUT_YOUR_YEAR);
  const [sectionState, setSectionState] = useState('');
  const year = getYear(selectState);
  const adaptive = useAdaptive();

  useEffect(() => {
    if (!isExportForm) updateCard();

    const formTemplatesByYear = {
      2021: aboutYour2021(year),
      2022: aboutYour2022(year),
      2023: aboutYour2023(year, adaptive),
      2024: aboutYour2024(year, adaptive)
    };

    const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

    setSectionState(formSections);
    // eslint-disable-next-line
  }, []);

  return (
    <FormRendererHelper
      sections={sectionState}
      key="aboutYourYearForm"
      formName={REDUX.ABOUT_YOUR_YEAR}
      triggeredEvent={triggeredEvent}
      isExportForm={isExportForm}
    />
  );
}

export default AboutYourYear;
