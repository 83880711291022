
const validateArrivalAndDepartureDate = ({arrivalDate, departureDate})=>{
    const currentArrivalDate = new Date(arrivalDate.default);
    const currentDepartureDate = new Date(departureDate.default);
    const dateOfToday = new Date();
    const arrivalMinDate = new Date(arrivalDate.minDate)

    // validate Arrival Date
    if(arrivalDate?.default){
        if(currentArrivalDate < arrivalMinDate){
            arrivalDate.error=true;
            arrivalDate.errorMessage = `Date must be ${arrivalDate.minDate} or later`
        }else if(currentArrivalDate > dateOfToday){
            arrivalDate.error=true;
            arrivalDate.errorMessage = `Must be ${dateOfToday.toLocaleDateString()} or earlier.`
        }else{
              arrivalDate.error=false;
            arrivalDate.errorMessage = ""
        }
    }

    // validate Departure Date
    if(departureDate?.default){
        if(currentDepartureDate > dateOfToday){
            departureDate.error=true;
            departureDate.errorMessage = `Must be ${dateOfToday.toLocaleDateString()} or earlier.`
        }else if(currentDepartureDate <= currentArrivalDate){
            departureDate.error=true;
            departureDate.errorMessage = "Date must be after Date of Arrival."
        }else if(currentDepartureDate < new Date(departureDate.minDate)){
            departureDate.error=true;
            departureDate.errorMessage = `Date must be ${departureDate.minDate} or later.`
        }else{
            departureDate.error=false;
            departureDate.errorMessage = ""
        }
    }
        
}

export {
    validateArrivalAndDepartureDate
}