// common modules
import React, { useState } from 'react';

// custom modules
import * as NAV from '@utilities/constants/navigation';
import * as IMG from '@utilities/constants/images';
import Breadcrumb from '@components/Breadcrumb.js';
import usePageFramework, {currentYear} from '@utilities/hooks/usePageFramework';
import api from '@utilities/claApi';
import { Button, TextIconSpacing } from 'react-md';

// GROWTH: Year to come from app config or user input
// GROWTH: This script was moved and optimized to db-1040
const targetYear = currentYear;
const ManageOrganizerStatus = () => {
    const [results, setResults] = useState(null);
    const {
        dispatch,
        ACTION,
    } = usePageFramework();

    const navItems = [
        { to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
        { to: NAV.MANAGE_ORGANIZER_STATUS, label: 'Manage Organization Status', current: true }
    ];

    const getOrganizerSet = async (query, limit) => {
        return api.get(`/organizers?filterStaticYear=${targetYear}&limit=${limit}&filter=locked ne true`).then((response) => {
            return response.data;
        });
    };

    const updateIndividualOrganizerStatus = async(organizers, updateMessage, offset, total) => {
        const body = { locked: true, status: 'Closed' };
        for (let index = 0; index < organizers.length; index++) {
            const { id } = organizers[index];
            try {
                await api.put(`/organizers/${id}`, body);
                console.log(`${offset + index + 1}/${total}; Updated organizer ${id} status to "Closed"`);
                const successStatusMessage = `Organizer (${id}): Successful status update\n`;
                updateMessage = updateMessage.concat(successStatusMessage);
            }
            catch (e) {
                console.log(`${offset + index + 1}/${total}; Failed to update organizer ${id} status to "Closed"`);
                const failStatusMessage = `Organizer (${id}): Failed to update status: ${e}\n`;
                updateMessage = updateMessage.concat(failStatusMessage);
            }
        }
        return updateMessage;
    }

    // Get a list of all organizers
    const getAllOrganizers = async (query) => {
        const organizers = [];
        let updateMessage = '';
        const limit = 250;
        let offset = 0;

        await getOrganizerSet(query, limit).then(async (data) => {
            const { results, total } = data;
            organizers.push(...results);
            if(results.length)
            updateMessage = await updateIndividualOrganizerStatus(results, updateMessage, offset, total);
            while (offset < total) {
                offset += limit;
                const nextPageResults = await getOrganizerSet(query, limit);
                updateMessage = await updateIndividualOrganizerStatus(nextPageResults.results, updateMessage, offset, total);
                organizers.push(...nextPageResults.results);
            }
        });

        return {organizers: organizers, updateMessage: updateMessage};
    };

    const updateOrganizersStatus = () => {
        let updateMessage = '';
        const queryParameters = [];

        dispatch(ACTION.setProgressText('updating organizer status...'));
        dispatch(ACTION.setProgressVisible(true));
        getAllOrganizers(queryParameters.join('&')).then(async (response) => {
            const foundMessage = `Found ${response.organizers.length} organizers (not in a "Closed" status) to migrate\n\n`;
            console.log(foundMessage.trim());
            updateMessage = updateMessage.concat(foundMessage);
            updateMessage = updateMessage.concat(response.updateMessage);
        }).finally(() => {
            dispatch(ACTION.setProgressVisible(false));
            dispatch(ACTION.setShowCustomDialog(true));
            dispatch(ACTION.setCustomDialogTitle('Finished updating organization status'));
            dispatch(ACTION.setCustomDialogMsg('The results will be displayed in-page'));
            setResults(updateMessage);
        });
    }

    const copyResultsToClipboard = (evt) => {
        evt.preventDefault();
        navigator.clipboard.writeText(results);
    };

    return (
        <div className='pracDashboardSize'>
            <Breadcrumb items={navItems} />
            <h1>{targetYear} Organizer status update</h1>
            <p className='page-instructions'>
                Click Update Status button to start updating {targetYear} organizers to the "Closed" status and will lock down the organizer. The results will be displayed in the page below and the log can be copied to your clipboard.
            </p>
            <p style={{ textAlign: 'left', marginLeft: '10px' }}> Target Year: {targetYear}</p>
            <Button themeType='outline' onClick={updateOrganizersStatus}>
                <TextIconSpacing icon={<img height={50} alt="File migration button" src={IMG.NOTES_ICON} />}>
                    Update Status
                </TextIconSpacing>
            </Button>
            <br />
            <Button themeType='outline' disabled={!results ? true : false} onClick={copyResultsToClipboard}>
                <TextIconSpacing icon={<img height={50} alt="Copy results button" src={IMG.DOWNLOAD_ALL_ICON} />}>
                    Copy Results
                </TextIconSpacing>
            </Button>
            {results && <p className='page-result-title'><b>Results:</b></p>}
            {results && <p className='page-result-text'>{results}</p>}
        </div>
    );
};

export default ManageOrganizerStatus;