import React from 'react';
import { DropdownMenu, TextIconSpacing } from "react-md";
import { BELOW_INNER_LEFT_ANCHOR } from '@react-md/utils';
import '../welcomeIntro/welcomeIntro.scss';
import { DropdownMenuItem } from "../welcomeIntro/DropdownMenuItem";
import usePageFramework from '@utilities/hooks/usePageFramework';
import { UserAccount } from './userAccount';

export const DropDownMenuComponent = ({ organizers, isEntry, userAccWidth, userAccHeight, userAccFontSize, userAccFontWeight, userAccbcgColor, userAccTextColor, avatarWidth, avatarHeight, avatarFontSize, dropdownFontSize }) => {
  const { selectState, REDUX } = usePageFramework();

  const taxPayerName = selectState(REDUX.TAXPAYER_NAME);

  const dropdownStyle = {
    maxHeight: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: isEntry && '435px'
  };

  return (
    <DropdownMenu
      id="sortMenu"
      icon={
        <div className='dropDownIcon'>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="17"
            fill="none"
            viewBox="0 0 27 17"
            className='dropDownIcon'
          >
            <path
              stroke={isEntry ? "#fff" : "#2E334E"}
              strokeLinecap="round"
              strokeWidth="2.5"
              d="M2 2l11.502 12.938L25 2"
            ></path>
          </svg>
        </div>
      }
      anchor={BELOW_INNER_LEFT_ANCHOR}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0px',
        padding: '0px',
        width: isEntry ? '392px' : '332px',
        paddingLeft: isEntry ? '27px' : '15px',
      }}
      portal
      buttonChildren={isEntry ?
        <div className='textIcon'>
          <UserAccount width={userAccWidth} height={userAccHeight} fontSize={userAccFontSize} fontWeight={userAccFontWeight} backgroundColor={userAccbcgColor} color={userAccTextColor} />
          <span
            style={{
              fontSize: '18px',
              font: 'museo-sans',
              color: 'white',
              margin: '0px',
              marginTop: '4px',
              textAlign: 'left',
              width: '300px'
            }}
          >
            {taxPayerName ? taxPayerName : 'Select Return'}
          </span>
        </div> :
        <TextIconSpacing
          className="dashboardSubMenuDropdownLabel"
          iconBefore
          icon={<UserAccount width={userAccWidth} height={userAccHeight} fontSize={userAccFontSize} fontWeight={userAccFontWeight} backgroundColor={userAccbcgColor} color={userAccTextColor} />}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', maxHeight: '56px', justifyContent: 'center' }}>
            {/* Current Return text */}
            <span
              style={{
                fontSize: '12px',
                fontWeight: 600,
                color: '#4B4B4B',
                lineHeight: '14.4px',
                marginBottom: '2px',
                marginLeft: '0px',
                letterSpacing: '0.1px',
              }}
            >
              {!isEntry && taxPayerName ? 'Current Return:' : ''}
            </span>
            {/* Taxpayer Name */}
            <span
              style={{
                fontSize: isEntry ? '18px' : '14px',
                fontWeight: 600,
                fontFamily: 'museo-sans',
                color: isEntry ? 'white' : '#2E334E',
                margin: '0px',
                marginLeft: '0px',
                lineHeight: '16.8px',
                letterSpacing: '0.1px',
                textAlign: 'left',
              }}
            >
              {taxPayerName ? taxPayerName : 'Select Return'}
            </span>
          </div>
        </TextIconSpacing>
      }
    >
      <div style={dropdownStyle}>
        {organizers?.map((organizer, index) => (
          <DropdownMenuItem key={organizer.priorYearReturnId}
            taxPayerName={organizer?.taxpayer?.displayName}
            priorYearReturnId={organizer?.priorYearReturnId}
            taxpayerSpouseName={organizer?.spouse?.displayName}
            isLastItem={index === organizers.length - 1}
            organizer={organizer}
            avatarWidth={avatarWidth}
            avatarHeight={avatarHeight}
            avatarFontSize={avatarFontSize}
            isEntry={isEntry}
            dropdownFontSize={dropdownFontSize}
          />
        ))}
      </div>
    </DropdownMenu>
  );
};
