import { DEPENDENT_CARE_UPLOAD } from '@utilities/constants/strings';
import { Validators } from 'cla-formrenderer';
import { pullFilteredFieldValue } from '../../../utilities/pullFieldValue';

const getProviderFormSection = (props) => {
    const { isFormExpandable, isLineSection, year, dependentOptions, isSelect } = props;

    const providerFormSections = [
        {
            title: 'Dependent Care Credit',
            isDefaultExpanded: isFormExpandable,
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    groupType: 'details',
                    fields: [
                        { label: `Please provide any dependent and child care expenses paid or incurred in ${year.current}:`, type: 'label', class: 'groupDetails2' },
                    ]
                },
                {
                    groupType: 'entityScrollTab',
                    isSingleColumn: true,
                    groupId: 2,
                    fields: []
                },
                {
                    groupId: 3,
                    groupType: 'details',
                    fields: [
                        { label: 'Provider and Dependent Information', type: 'label', class: 'labelProvider', name: 'providerHeaderLabel' },
                        {
                            label: 'Provider Information',
                            name: 'providerInfoLabel',
                            type: 'label',
                            class: 'formSingleHeaderContainer',
                        },
                        {
                            name: 'providerFullName', maxLength: 59, type: 'freeText',
                            isEntityName: true,
                            isVisible: false,
                            prior: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCARE.0', 'IFDSCARE.18'],
                                    fn: 'joinTextPull',
                                }
                            }
                        }
                    ]
                },
                {
                    groupId: 4,
                    hasDivider: false,
                    // axcessGroup: {
                    //     pull: {
                    //         postPopulateFn: 'setProviderTypeVisibility',
                    //         fromSections: ['General', 'Persons or Organizations Who Provided the Care'],
                    //         fieldPrefixes: ['IFDSCARE'],
                    //     }
                    // },
                    fields: [
                        {
                            label: 'First Name or Provider Name', name: 'providerFirstName', maxLength: 34, type: 'freeText',
                            isEntityName: true,
                            logicFunction: [{ trigger: 'setProviderTabName' }],
                            prior: true,
                            isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCARE.0'],
                                    fn: 'anyValidPull',
                                }
                            }
                        },
                        {
                            label: 'Last Name', name: 'providerLastName', maxLength: 25, type: 'freeText',
                            isEntityName: true,
                            logicFunction: [{ trigger: 'setProviderTabName' }],
                            prior: true,
                            isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCARE.18'],
                                    fn: 'anyValidPull',
                                }
                            }
                        },
                        {
                            label: 'Type', name: 'providerType', type: 'select', lookup: 'PROVIDER_DEPENDENT_TYPE', triggered: 'setChildCareID',
                            prior: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCARE.4', 'IFDSCARE.3'],
                                    fn: 'providerTypePull',
                                }
                            }
                        },
                        {
                            label: 'SSN', name: 'ssn', type: 'ssn', isVisible: false, overRideText: 'ON FILE', validations: { ...Validators.ssn(), },
                            isMaskOnBlur: true,
                            axcessTranslate: 'omitOnOverride',
                            prior: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCARE.4'],
                                    fn: 'anyValidPull',
                                }
                            },
                        },
                        {
                            label: 'EIN', name: 'ein', type: 'ein', isVisible: false, validations: { ...Validators.ein() },
                            prior: true,
                            ...pullFilteredFieldValue('IFDSCARE.3', true, ["-"])
                        }

                    ],
                },
                {
                    groupId: 5,
                    isVisible: true,
                    hasDivider: false,
                    fields: [
                        {
                            label: 'Country',
                            name: 'country',
                            type: 'select',
                            col: 4,
                            lookup: 'FOREIGN_COUNTRIES',
                            logicFunction: [{ trigger: 'setChildCareCountry' }],
                            default: 'US',
                            prior: true,
                            isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCARE.17'],
                                    fn: 'anyValidPull',
                                }
                            }
                        },
                        {
                            label: 'Street Address', maxLength: 29, name: 'streetAddress', type: 'freeText', col: 8,
                            prior: true,
                            isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCARE.1'],
                                    fn: 'anyValidPull',
                                }

                            }
                        }
                    ],
                },
                {
                    groupId: 6,
                    isVisible: true,
                    hasDivider: false,
                    fields: [
                        {
                            label: 'City', maxLength: 20, name: 'city', type: 'freeText', col: 4,
                            prior: true,
                            isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCARE.2'],
                                    fn: 'anyValidPull',
                                }

                            }
                        },
                        {
                            label: 'State', name: 'state', type: 'select', lookup: 'STATES_CODE', col: 4,
                            prior: true,
                            isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCARE.14'],
                                    fn: 'anyValidPull',
                                }

                            }
                        },
                        {
                            label: 'State / Province', name: 'provinceState', type: 'freeText', col: 4, isVisible: false,
                        },
                        {
                            label: 'Zip / Postal Code', name: 'zipPostalCode', type: 'zippostal', col: 4, maxLength: 15,
                            prior: true,
                            isPriorEditable: true,
                            axcess: {
                                pull: {
                                    fields: ['IFDSCARE.15'],
                                    fn: 'anyValidPull',
                                }

                            }
                        }
                    ],
                },
                {
                    groupId: 7,
                    groupType: 'details',
                    fields: [
                        { label: 'Expense Details', type: 'label', class: 'labelProvider', name: 'expenseLabel' },
                    ]
                },
                {
                    groupId: 8,
                    groupType: 'details',
                    fields: [
                        { label: `Please enter total qualified expenses paid to this provider during ${year.current} by dependent. `, type: 'label', class: 'groupDetails2', linkLabel: `Add or Update Dependents here`, path: '/dependents' }
                    ]
                },
                {
                    groupType: isLineSection ? 'lineSection' : 'lineItem',
                    groupId: 9,
                    uploadType: 'drawer',
                    fields: [
                        { label: 'Dependent Name', name: 'dependentName', type: isSelect ? 'select' : 'freeText', fieldOptions: isSelect ? dependentOptions : '' },
                        { label: 'Amount', name: '2022Amount', type: 'money', isTotal: true },
                        { type: 'lineButtons' }
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Dependent Name', col: 8, colTablet: 4, },
                            { label: `${year.current} Amount`, col: 3, colTablet: 3, align: 'right' },
                            { label: 'Receipts', col: 1, colTablet: 1, align: 'noPaddingLeft' },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Additional Expenses for Another Dependent', icon: 'add' },
                            { type: 'label', label: 'Total', col: 8, colTablet: 4 },
                            { type: 'totalMoney', name: '2022Amount', col: 3, colTablet: 3, label: '$ 0', year: 'current' },
                            { label: '', col: 1, align: 'left' }
                        ],
                        lineButtons: [
                            { button: 'uploadNa', uploadSectionName: DEPENDENT_CARE_UPLOAD, viewSectionId: 0 },
                        ],
                    }
                },

            ]
        },
    ];

    return providerFormSections;
};

export default getProviderFormSection;