const updateCardStatus = (formState, CARDSTATUS, card, errorCount, updateStatus) => {

	if (formState && card.statusId !== CARDSTATUS.COMPLETED) {

		if (errorCount > 0) {
			// ERRORS
			updateStatus(CARDSTATUS.ERRORS);
		}
		if (card.statusId === CARDSTATUS.ERRORS && errorCount === 0) {
			updateStatus(CARDSTATUS.IN_PROGRESS);
		}
	}

}

export default updateCardStatus;