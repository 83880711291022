import * as STRINGS from '@utilities/constants/strings';

export const formatSsn = (newValue) => {
    let display = newValue.length === 9 ? ('' + newValue).slice(0, -4).replace(/./g, 'X') + ('' + newValue).slice(-4) : newValue;
   
    if (display.length >= 4) {
        display = display.slice(0, 3) + '-' + display.slice(3);
    }
    
    if (display.length >= 7) {
        display = display.slice(0, 6) + '-' + display.slice(6);
    }
    return newValue === STRINGS.ON_FILE_OVERRIDE ? newValue : display;
};

export const TransformEin = (newValue) => {
    // let _value = TransformNumber(newValue).substr(0, 9);

    if (newValue.length >= 2 && newValue !== STRINGS.ON_FILE_OVERRIDE) {
        return newValue.slice(0, 2) + '-' + newValue.slice(2);
    }

    return newValue;
};

export const TransformNumber = (newValue) => {
    return newValue.replace(/[^0-9]/g, '');
};