
import { useState, useEffect } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '@components/formRenderer';
import { sectionFieldLogic } from './miscIncomeLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import miscIncome2021 from './2021';
import miscIncome2022 from './2022';
import miscIncome2023 from './2023';
import miscIncome2024 from './2024';
import * as NAV from '@utilities/constants/navigation';

function MiscellaneousIncome(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.MISCELLANEOUS_INCOME);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: miscIncome2021(year),
            2022: miscIncome2022(year),
            2023: miscIncome2023(year),
            2024: miscIncome2024(year),
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setSectionState(formSections);
        // eslint-disable-next-line   
    }, []);

    const axcessIdentifiers = [
        { section: 'Income', id: 'Other Income' },
        { section: 'Deductions', id: 'Sch A' },
        { section: 'State Link Worksheet', id: 'Government' },
        { section: 'General', id: 'Basic Data' },
    ];
    
    return (
        <FormRenderer sections={sectionState} key="miscellaneousIncomeForm" formName={REDUX.MISC_INCOME} 
        identifiers={axcessIdentifiers} 
        sectionFieldLogic={sectionFieldLogic} isExportForm={isExportForm}
        />
    );
}

export default MiscellaneousIncome;
